import React, { useRef, useState } from "react";
import Loading from "react-fullscreen-loading";

// region Imports - External libraries
import * as Yup from "yup";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import { TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
// endregion Imports - External libraries
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { ScheduledReportMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Shared
import { IFilter, IParamQueryValues } from "@shared/interfaces/filter.interface";
import { Filter } from "@shared/entities/reg_filters.entity";
// endregion Imports - Shared
// region Imports - Utils
import utils, { StandardReturnObject } from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Styles
import * as Styled from "./styles";
// endregion Imports - Styles

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  filter: IFilter;
  additionalFilterOptions: IParamQueryValues[];
}

interface IFormData {
  descriptionScheduledReport: string;
}
// endregion Interfaces

const ModalFormScheduledReportDescription: React.FC<IProps> = ({ open, onClose, filter, additionalFilterOptions }) => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const [isLoading, setIsLoading] = useState(false);

  // region Functions

  /**
   * Create scheduled report
   * @param formData
   */
  const createScheduledReport = async (formData: IFormData) => {

    setIsLoading(true);

    try {

      const filterInterface = {
        ...filter,
        filter_options: [
          ...filter.filter_options,
          ...additionalFilterOptions
        ],
        description: formData.descriptionScheduledReport
      } as IFilter;

      const scheduledReportFilterToCreate = new Filter(filterInterface);
      const response = await api.post("/scheduled-reports/create", scheduledReportFilterToCreate);
      const data = response?.data as StandardReturnObject<Filter>;

      if (data?.status === "success") {
        addToast({ type: "success", title: data.message });

        return;
      }

      addToast({ type: "error", title: data.message });

    } catch (error) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (formData: IFormData) => {

    try {
      const schema = Yup.object().shape({
        descriptionScheduledReport: Yup.string()
          .required(t(YupMessages.fieldRequired))
          .max(30, t(YupMessages.fieldMaxLength30))
      });

      await schema.validate(formData, { abortEarly: false });

      await createScheduledReport(formData);

      onClose();

    } catch (error) {
      formRef.current?.setErrors(getValidationErrors(error));
    }
  };
  // endregion Functions

  return (
    <>
      <Loading loading={isLoading} />
      <Styled.ContainerDialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <div className="dialogTitle">
            <span> {t(ScheduledReportMessages.modalDescriptionTitle)} </span>
            <span> {t(ScheduledReportMessages.modalDescriptionTitleQuestion)} </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Form className="form" ref={formRef} onSubmit={handleSubmit}>
            <TextField
              label={t(ScheduledReportMessages.modalScheduleReportName)}
              margin="dense"
              variant="outlined"
              name="descriptionScheduledReport"
              fullWidth
            />
          </Form>
        </DialogContent>
        <Styled.DialogActions>
          <Button
            disableRipple
            onClick={() => onClose()}
            color="primary"
          >
            {t(ScheduledReportMessages.modalButtonCancel)}
          </Button>
          <Button
            disableRipple
            color="primary"
            onClick={() => formRef.current?.submitForm()}
          >
            {t(ScheduledReportMessages.modalButtonCreate)}
          </Button>
        </Styled.DialogActions>

      </Styled.ContainerDialog>
    </>

  );
};

export default ModalFormScheduledReportDescription;
