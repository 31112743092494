/* eslint-disable camelcase */
import {
  Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, ManyToOne, JoinColumn, OneToMany, OneToOne
} from "typeorm";

import { TpeFilterApplication } from "./tpe_filter_application_types.entity";
import { User } from "./reg_users.entity";
import { FilterOption } from "./reg_filter_options.entity";
import { ScheduledReport } from "./reg_scheduled_reports.entity";

import { IFilter, IParamQueryValues } from "../interfaces/filter.interface";

@Entity("reg_filters")
export class Filter {

  constructor(filterInterface?: IFilter) {
    if (filterInterface) {
      this.id_filter = filterInterface.id_filter;
      this.application_type = { id_filter_application_type: filterInterface.application_type_id };
      this.description = filterInterface.description;
      this.SetFilterOptions(filterInterface.filter_options);
    }
  }

  @PrimaryGeneratedColumn("uuid")
    id_filter?: string;

  @ManyToOne(() => TpeFilterApplication, (type) => type.filters, { nullable: false })
  @JoinColumn({ name: "id_filter_application_type_fk" })
    application_type?: TpeFilterApplication;

  @ManyToOne(() => User, (user) => user.filters, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

  @Column({ type: "varchar", nullable: false, length: 30 })
    description?: string;

  @Column({ type: "boolean", nullable: false, default: false })
    scheduled_report?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => FilterOption, (filterOption) => filterOption.filter, { cascade: true })
    filter_options?: FilterOption[];

  @OneToOne(() => ScheduledReport, (scheduledReport) => scheduledReport.filter, { nullable: true })
    scheduled_report_data?: ScheduledReport;

  ToFilterInterface(): IFilter {
    const filterInterface: IFilter = {
      id_filter: this.id_filter,
      application_type_id: this.application_type?.id_filter_application_type || "",
      description: this.description || "",
      filter_options: []
    };

    if (this.filter_options) {

      this.filter_options.forEach((filterOption) => {

        if (!filterOption.param_name || !filterOption.param_value) return;

        const filterOptionInterface = filterInterface.filter_options.find(
          (filterOptionInterface) => filterOptionInterface.paramName === filterOption.param_name
        );

        if (filterOptionInterface) {

          if (Array.isArray(filterOptionInterface.paramValue)) {
            filterOptionInterface.paramValue.push(filterOption.param_value || "");
          } else {
            filterOptionInterface.paramValue = [filterOptionInterface.paramValue, filterOption.param_value || ""];
          }

        } else {

          filterInterface.filter_options.push({
            paramName: filterOption.param_name || "",
            paramValue: filterOption.param_value || ""
          });
        }
      });
    }

    return filterInterface;
  }

  private SetFilterOptions(filterOptions: IParamQueryValues[]): void {
    if (filterOptions) {
      const filterOptionsArray: FilterOption[] = [];

      filterOptions.forEach((filterOption) => {
        if (Array.isArray(filterOption.paramValue)) {
          filterOption.paramValue.forEach((paramValue) => {
            filterOptionsArray.push({
              param_name: filterOption.paramName,
              param_value: paramValue
            });
          });
        } else {
          filterOptionsArray.push({
            param_name: filterOption.paramName,
            param_value: filterOption.paramValue
          });
        }
      });

      this.filter_options = filterOptionsArray;
    }
  }

}
