import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 64px;
  height: 100%;

  .headerList {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    .headerTitle {

      .title {
        font-weight: 200;
        width: 100%;
        font-size: 2rem;
      }
      .subtitle {
        margin-top: 5px;
        font-size: 1.2rem;
        color: rgb(0 0 0 / 60%);
      }
    }
    .action {
      display: inline-flex;
      button {
        margin-left: 16px;
        width: 104px;
      }
    }
  }
`;

export const ContainerModalFormVehicle = styled.div`

.MuiDialog-paper {
    width: 600px;

    .mHeader {

      .MuiTypography-root {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
          font-weight: 200;

          .subtitle {
            font-size: 1rem;
            color: #666666;
          }
        }
        .icon {
          display: flex;

          svg {
            font-size: 1.8em;
          }
        }
        .actions {

          button {
            background: #DDDDDD;

            svg {
              color: #707070;
            }
            &:hover {
              background-color:#FFFFFF;
            }
          }
        }
      }
    }
    .mContent {

      .MuiFormControl-root {
        width: 100%;

        .MuiFormHelperText-root {
          text-align: right;
          margin: 0;
        }
      }
      .checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .r-checkbox {
          display: flex;
          margin-left: 0;
          margin-right: 0;
          justify-content: flex-end;

          .MuiCheckbox-root {
            color: #707070;
          }
          .MuiFormControlLabel-label {
            font-size: 1.2em;
          }
          .MuiIconButton-root {
            position: relative;
            left: -10px;
          }
          .MuiFormControlLabel-label {
            position: relative;
            top: 1px;
          }
        }
      }

    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;
