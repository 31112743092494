/* eslint-disable max-len */

import styled from "styled-components";
import { windowWidth } from "@styles/global";

type ContainerProps = {
  use: string;
  filters?: boolean;
  editColumns?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100%;
  margin-top: -18px;

  .filter-button {
    top: 25px;
    right: 10px;
    position: relative;
    float: right;
    z-index: 1;
    cursor: pointer;

    @media ( max-width: ${windowWidth.mobile.large} ) { // Mobile
      right: 10px;
      top: 32px;
      justify-content: end;
    }

    .filter-icon {
      font-size: 25px;
    }

    .MuiBadge-badge {
      width: 20px;
      height: 20px;
      border-radius: 50%;

      display: flex;
      align-content: center;
      justify-content: center;

      .MuiSvgIcon-root {
        font-size: 16px;
        color: ${(props) => props.theme.colors.primary} !important;
        border-radius: 50%;
      }
    }
  }

  .dataTables_wrapper {
    margin: 10px;
    height: 100%;

    .MuiGrid-container {
      flex-wrap: unset;
    }

    .dataTables_search {
      display: flex;
      justify-content: end;

      .dataTables_filter {

        margin-left: ${(props) => ((props.filters && !props.editColumns) || (!props.filters && props.editColumns)
    ? "40px" : "55px")};

        svg {
          color: ${(props) => props.theme.colors.primary} !important;
        }
      }
    }
  }
  .dataTables_length {
    padding-top: 10px;

    label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin-right: 10px;
        width: 50px;

        option {
          text-align: center;
        }
      }
    }
  }
  .dataTables_search {
    position: relative;
    padding: 10px 10px;
    width: 100%;
    z-index: 0;

    @media ( max-width: ${windowWidth.mobile.large} ) { // Mobile
      justify-content: start !important;
      padding-left: 0;
      padding-top: 0;

      .dataTables_filter {
        margin-left: 0 !important;
      }
    }
    @media ( min-width: ${windowWidth.mobile.large} ) { // Desktop
      padding: 0;

      .dataTables_filter {
        margin-bottom: 10px;
      }
    }

    .dataTables_filter {

      label {
        display: flex;
        align-items: center;

        input {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .dataTables_scroll {

    .dataTables_scrollHead {
      background: ${(props) => props.theme.colors.primary};
      color: white;
      border-radius: 5px 5px 0 0;

      /* Large than any mobile device */
      @media ( min-width: ${windowWidth.mobile.large} ) {
        padding: 5px 0;
      }

      thead tr th {
        border-bottom: none;
      }
    }
    .dataTables_scrollBody {
      max-height: ${(props) => (props.use === "maintenanceScreen" ? "calc(100vh - 395px)" : "calc(100vh - 490px)")} !important;
      overflow-x: hidden !important;
      border-radius: 0 0 10px 10px;
      border: 0.5px solid #ddd;

      table {

        td {
          padding: 5px;

          @media ( max-width: ${windowWidth.laptop.large} ) {
            padding: 2px 5px;
            font-size: 14px;
          }

          @media ( max-width: ${windowWidth.tablet} ) {
            padding: 5px;
          }
        }

        .dataTables_empty {
          height: calc(100vh - 395px);
        }
        .child {

          .dtr-details {
            width: 100%;

            li {
              padding: 5px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .dtr-title {
                min-width: 0;
              }
              .dtr-data {
                text-align: right;
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }
  .dataTables_info {
    padding-top: 15px;
  }
  .dataTables_paginate {
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;

    & > span {
      display: flex;
      align-items: center;
    }
    .paginate_button {
      padding: 5px 8px;
    }

    @media ( max-width: ${windowWidth.mobile.large} ) {  // Mobile
      margin-top: 10px;
    }

  }
  .dataTables_buttons .dt-buttons {
    margin-top: 25px;

    .button:disabled, .button[disabled]{
      opacity: 0.7;
      cursor: auto;
    }
    .button {
      min-width: 100px;
      height: 50px;
      background: #707070;
      border-radius: 10px;
      border: 0;
      padding: 14px 10px;
      color: #fff;
      font-weight: 500;
      margin-left: 16px;
      transition: background-color 0.2s;

      &:hover {
        background: rgb(112 112 112 / 0.6);
      }
    }
    .button.MuiButton-root{
      text-transform: inherit;
      line-height:  0.9;
      box-shadow: none;
    }
  }
  .flex--center {
    display: flex;
    justify-content: center;
  }
  .flex--hidden {
    display: none;
  }
  .fixed--bottom {
    background: ${(props) => (props.use === "maintenanceScreen" ? props.theme.colors.scrollBar : "white")};
    position: fixed;
    margin: ${(props) => (props.use === "maintenanceScreen" ? "0 -10px 0 -20px" : "10px 0")};
    bottom: 0;
    width: -webkit-fill-available;
    padding: 20px 15px 30px 15px;

    @media ( max-width: ${windowWidth.mobile.large} ) { // Mobile
      padding: 0 0 5px 0;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .MuiGrid-grid-xs-6 {
        max-width: 100%;
      }
    }
  }
`;

export const Buttons = styled.div`
  > :first-child {
    padding: 5px;
    bottom: 85px;
    background: white;
    border-radius: 50%;

    > :first-child {
      box-shadow: none;
    }
    > :nth-child(3) {
      bottom: 162px;
      padding-right: 5px;
    }
  }
`;
