import styled from "styled-components";
import { colors } from "@material-ui/core";

export const Container = styled.div`
  width: 100%;
  min-width: -webkit-fill-available;
  padding: 30px 20px 0px 20px;

  .log-text-area  {
    width: 100%;
    height: calc(100vh - 360px);
    min-height: 400px;
    padding: 4px;
    border: 1px solid ${colors.grey[300]};
    overflow-y: scroll;
    background-color: #f9f9f9;

    .log-text-area__log-text {
      padding: 8px 0px;
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: 800px) { // Mobile

    .title, .filterHeader, .filterFooter {
      text-align: center !important;
    }
  }

  .form {
    background: white;

    .filterContent {
      margin: 25px 0 0 0;
    }

    .filterFooter {
      text-align: end;

      button {
        width: fit-content;
        font-weight: bold;
        margin-top: 0px;
      }
    }

    .button-loading {
      margin-top: 0px !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;
