import { VehicleMaintenanceMessages, TranslationVehicleMaintenanceMessages } from "../interfaces";

export const messages: TranslationVehicleMaintenanceMessages = {

  /* region Backend */
  [VehicleMaintenanceMessages.inMaintenance]: "Em manutenção",
  [VehicleMaintenanceMessages.outOfMaintenance]: "Fora de manutenção",
  [VehicleMaintenanceMessages.vehicleIsAlready]: "Veículo já está",
  [VehicleMaintenanceMessages.cannotGetLocation]: "Não foi possível obter a localização do veículo para gerar o"
  + " evento.",
  [VehicleMaintenanceMessages.putInMaintenanceSuccess]: "Veículo colocado em manutenção com sucesso.",
  [VehicleMaintenanceMessages.putOutOfMaintenanceSuccess]: "Veículo retirado de manutenção com sucesso.",
  [VehicleMaintenanceMessages.changeMaintenanceStatusError]: "Falha ao alterar estado de manutenção do veículo",
  /* endregion Backend */

  /* region Frontend */
  [VehicleMaintenanceMessages.checkVehicle]: "Confira o veículo",
  [VehicleMaintenanceMessages.removeFromMaintenance]: "Retirar da manutenção",
  [VehicleMaintenanceMessages.removeFromMaintenanceConfirmation]: "Deseja realmente retirar o veículo da manutenção?",
  [VehicleMaintenanceMessages.justifyOrSelect]: "Selecione ou justifique a ",
  [VehicleMaintenanceMessages.maintenance]: "Manutenção",
  [VehicleMaintenanceMessages.selectMaintenance]: "Selecione a manutenção",
  [VehicleMaintenanceMessages.justifyMaintenance]: "Justifique a manutenção",
  [VehicleMaintenanceMessages.maintenanceOptions]: "Opções de manutenção",
  [VehicleMaintenanceMessages.maintenanceConfirmation]: "Confirmação de manutenção",
  [VehicleMaintenanceMessages.maintenanceConfirmationMessage]: "Deseja realmente colocar o veículo em manutenção?",
  [VehicleMaintenanceMessages.youAreAboutToPutTheVehicle]: "Você está prestes a colocar o veículo ",
  [VehicleMaintenanceMessages.yourActionWillPutTheVehicleInMaintenance]: "Sua ação irá colocar o veículo em"
  + " manutenção.",
  [VehicleMaintenanceMessages.optionHydraulic]: "Hidráulica",
  [VehicleMaintenanceMessages.optionElectrical]: "Elétrica",
  [VehicleMaintenanceMessages.optionTireService]: "Borracharia",
  [VehicleMaintenanceMessages.optionSuspension]: "Moleiro",
  [VehicleMaintenanceMessages.optionBodywork]: "Funilaria",
  [VehicleMaintenanceMessages.optionRockBreaker]: "Quebra Pedra",
  [VehicleMaintenanceMessages.optionOthers]: "Outros (justifique)"
  /* endregion Frontend */
};
