import styled from "styled-components";

export const Container = styled.div`

  .MuiSpeedDial-root {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    align-items: flex-end;

    @media (max-width: 600px) {
      bottom: 6rem;
    }

    .MuiButtonBase-root {
      height: 5rem;
      width: 5rem;

      @media (max-width: 600px) {
        height: 4rem;
        width: 4rem;
      }

      .MuiFab-label {

        .MuiSpeedDialIcon-root {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .MuiSvgIcon-root {
          font-size: 2.5rem;
        }
      }
    }

    .MuiSpeedDial-actions {
      margin-bottom: 0;
      padding-bottom: 0.4rem;

      .MuiButtonBase-root {
        border-radius: 0.3rem;
        height: 2.8rem;
        width: 13.75rem;
        padding: 1rem;
        color: #002951;
        font-size: 1rem;
        text-transform: none;

        @media (max-width: 600px) {
          padding: 0.1rem;
        }

        &:hover {
          background-color: #2968A8;
          color: #fff;
        }
      }
    }
  }

`;
