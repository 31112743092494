/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState } from "react";
import Loading from "react-fullscreen-loading";
import { ReactSVG } from "react-svg";

// region Assets
import IconArrowRight from "@assets/icons/arrow_right.svg";
// region Assets

// region Libraries
import * as _ from "lodash";
// endregion Libraries

// region Interfaces
import { Alert as AlertInterface } from "@shared/interfaces/alert.interface";
// endregion Interfaces

// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages } from "@shared/languages/interfaces";
// endregion Languages

// region Shared
import { TLanguages } from "@shared/languages/languageData";
// endregion Shared

// region Components
import { AlertTypesID } from "@shared/constants/alert-types.enum";
import AlertDialogJustify from "../AlertDialogJustify";
// endregion Components

// region Utils
import utils from "../../../utils/useful-functions";
// endregion Utils

import { Container } from "./styles";

type AlertItemProps = { alerts: AlertInterface[] }
const AlertList: React.FC<AlertItemProps> = (
  { alerts }
) => {

  const [selectedAlert, setSelectedAlert] = useState<AlertInterface>(
    {} as AlertInterface
  );
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const getMaximumSpeed = (alert: AlertInterface) => {
    if (alert?.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM) {
      return (alert.travel?.alert_maximum_speed);
    }

    return (alert.vehicle?.maximum_speed_allowed);
  };

  /**
   * Get state or vehicle type icon, according to the alert type
   */
  const getStateOrVehicleTypeIcon = useCallback((alert: AlertInterface) => {
    if (alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE
      || alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM) {

      return (
        <span className="iconState">
          {utils.getStateIcon(alert?.vehicle_state?.status.id_vehicle_state_type ?? "")}
        </span>
      );
    }

    return (
      <span className="iconVehicleType">
        {utils.getVehicleIconAccordingTypeAndState(alert.vehicle, alert.vehicle_state)}
      </span>
    );
  }, []);

  /**
   * Open dialog confirm alert
   */
  const handleAlertClick = useCallback((alert: AlertInterface) => {
    setSelectedAlert(alert);
  }, []);

  /**
   * Close dialog confirm alert
   */
  const handleAlertClose = useCallback(() => {
    setSelectedAlert({} as AlertInterface);
  }, []);

  return (
    <Container>
      <Loading loading={loading} />
      {alerts.map((alert) => (
        <div className="content" key={alert.id_alert} onClick={() => handleAlertClick(alert)}>
          <div className="boxIcon">
            {utils.getAlertIconAccordingType(alert.type.id_alert_type)}
          </div>
          <div className="boxDescription">
            <div className="heading alertC-title">
              <div className="txt1">
                {getStateOrVehicleTypeIcon(alert)}
                <span className="desc">{alert.vehicle?.code}</span>
              </div>
              {(alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE
                || alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM) && (
                <div className="txt2">
                  {utils.formatNumberToLocaleString(
                    alert.event?.speed,
                    i18n.language as TLanguages,
                    1
                  ) ?? "??"}
                  <ReactSVG src={IconArrowRight} className="" />
                  {utils.formatNumberToLocaleString(
                    getMaximumSpeed(alert),
                    i18n.language as TLanguages,
                    2
                  ) ?? "??"}
                  {" "}
                  km/h
                </div>
              )}
            </div>
            <div className="heading alertC-subtitle">
              {(alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE
                || alert?.type?.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM) ? (
                  <>
                    <div className="txt1">{t(GlobalMessages.exceededBy)}</div>
                    <div className="txt2">
                      {`${utils.formatDateIfHave(alert.occurrence_date, "shortDate")}
                      ${utils.formatDateIfHave(alert.occurrence_date, "timeWithoutSeconds")}`}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="txt1">{t(GlobalMessages.date)}</div>
                    <div className="txt2">{utils.formatDateIfHave(alert.occurrence_date,
                      "fullDate")}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ))}
      {!_.isEmpty(selectedAlert) && (
        <AlertDialogJustify
          selectedAlert={selectedAlert}
          actionType="update"
          closeCallback={handleAlertClose}
          setLoading={setLoading}
        />
      )}
    </Container>
  );
};

export {
  AlertList as default
};
