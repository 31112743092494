import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  &.mobile {
    margin-top: 5rem;
  }

  .list {
    height: 100%;

    .section {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding-top: 0;
      margin-bottom: 20px;

      @media (max-width: 800px) { // Mobile
        height: auto;
      }

      .indicator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #294162;
        border-radius: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 26.5px;
        color: white;
        font-weight: 400;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        position: sticky;
        top: 0;
        z-index: 2;
        font-size: 1.2rem;
        height: 112px;

        .count {
          font-size: 1.9rem;
          font-weight: 500;
          display: flex;
          align-items: center;

          span {
            font-size: 18px;
            font-weight: 400;
            border-right: 2px solid rgba(255, 255, 255, 0.5);
            border-top-right-radius: 1px;
            border-top-left-radius: 1px;
            border-bottom-right-radius: 1px;
            border-bottom-left-radius: 1px;
            height: 53.5px;
            display: flex;
            align-items: center;
            margin-right: 8px;
            padding-right: 8px;

            svg {
              height: 14px;
              width: 14.25px;
              margin-right: 2px;
            }
          }
        }

        .blockedCount {
          display: flex;
          position: absolute;
          left: calc(100% - 85px);
          font-size: 1.2rem;
          font-weight: 400;
          align-items: center;
          gap: 0.4rem;

            svg {
              width: 0.8rem;
              position: relative;
              top: 1px;
            }

          .vertical_line {
            border: 1px solid rgba(255, 255, 255, 0.25);
            height: 3.3rem;

          }
        }

        .topIndicator {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .filterMenu {
          position: absolute;
          bottom: 2px;
          background-color: white;
          height: 25px;
          width: calc(100% - 4px);
          left: 2px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            height: 25px;
            text-transform: none;

            svg {
              width: 10.5px;
              height: 8.81px;
              position: relative;
              top: 0px;
            }

            .MuiTypography-colorTextSecondary {
              color: black;
              font-weight: 400;
            }
          }
        }
      }

      .items {
        padding: 15px 5px;
        justify-content: center;

        .sc-fFSRdu {
          width: 100%;
        }
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #F0F0F7;
      }

      .accordionContainer {
        border-radius: 10px;
        box-shadow: none;
      }

      .accordionSummary {
        background: #294162;
        border-radius: 10px;
        padding: 10px 15px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        width: 100%;
        height: 75px;

        position: sticky;
        top: 0;
        z-index: 0;

        color: white;
        font-weight: 400;
        font-size: 1.2rem;


        .MuiAccordionSummary-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .count {
          font-size: 1.9rem;
          font-weight: 500;
        }

        .blockedCount {
          display: flex;
          font-size: 1.2rem;
          font-weight: 400;
          align-items: center;
          gap: 0.4rem;

            svg {
              width: 0.8rem;
              position: relative;
              top: 1px;
            }

          .vertical_line {
            border: 1px solid rgba(255, 255, 255, 0.25);
            height: 3.3rem;

          }
        }
      }

      .accordionDetails {
      }
    }
  }
`;
