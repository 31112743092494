/* eslint-disable import/prefer-default-export */
import {
  TranslationAggregateTruckTravelDataMessages,
  AggregateTruckTravelDataMessages
} from "../interfaces";

export const messages: TranslationAggregateTruckTravelDataMessages = {
  [AggregateTruckTravelDataMessages.origin]: "Origem",
  [AggregateTruckTravelDataMessages.loadingLocation]: "Local de carregamento",
  [AggregateTruckTravelDataMessages.unloadingLocation]: "Local de descarregamento",
  [AggregateTruckTravelDataMessages.productDocument]: "Documento do produto",
  [AggregateTruckTravelDataMessages.serviceOrderDate]: "Data da ordem de serviço",
  [AggregateTruckTravelDataMessages.travelStartDate]: "Data de início da viagem",
  [AggregateTruckTravelDataMessages.travelDocumentDate]: "Horário de emissão do documento de viagem",
  [AggregateTruckTravelDataMessages.quantity]: "Quantidade"
};
