import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import portugueseBrazilian from "@shared/languages/pt-BR";
import englishUs from "@shared/languages/en-US";
import spanishEs from "@shared/languages/es";

const resources = {
  en: { ...englishUs },
  pt: { ...portugueseBrazilian },
  es: { ...spanishEs }
};

const language = localStorage.getItem("@Fleet:language") ?? navigator.language;

function i18nInit() {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      fallbackLng: "pt-BR",
      interpolation: {
        escapeValue: false
      }
    });
}

export default i18nInit;
