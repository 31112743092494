/* eslint-disable no-param-reassign */
import React, { ButtonHTMLAttributes } from "react";

// region Languages
import { GlobalMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion Languages
// region Styles
import { Container } from "./styles";
// endregion Styles

type ButtonLoadingProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loadingContent?: string;
  disabled?: boolean;
};

const ButtonLoading: React.FC<ButtonLoadingProps> = ({
  children, loading, loadingContent, disabled, ...rest
}) => {
  const { t } = useTranslation();

  loadingContent = loadingContent ?? t(GlobalMessages.loading);

  return (
    <Container type="button" {...rest} disabled={!!loading || disabled}>
      {loading ? loadingContent : children}
    </Container>
  );
};

ButtonLoading.defaultProps = {
  loading: false,
  disabled: false
};

export default ButtonLoading;
