/* eslint-disable camelcase */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { CommandStatusType } from "../types/command_status_type.enum";
import { CommandType } from "../types/command_type";
import { Hardware } from "./reg_hardwares.entity";
import { User } from "./reg_users.entity";

@Entity("reg_config_commands")
export class ConfigCommand {

  @PrimaryGeneratedColumn("uuid")
    id_config_command?: string;

  @ManyToOne(() => Hardware, (tracker) => tracker.ConfigCommands, { nullable: false })
  @JoinColumn({ name: "id_tracker_hardware_fk" })
    tracker?: Hardware;

  @Column({ type: "varchar", nullable: false })
    command?: string;

  @Column({ type: "varchar", nullable: true })
    response?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    sended_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    processed_date?: Date;

  @Column({ type: "enum", enum: CommandStatusType, nullable: false })
    status?: CommandStatusType;

  @Column({ type: "numeric", default: 0, nullable: false })
    attempts?: number;

  @Column({ type: "enum", enum: CommandType, nullable: false, default: CommandType.NORMAL })
    type?: CommandType;

  @Column({ type: "varchar", nullable: true })
    justification?: string;

  @ManyToOne(() => User, (user) => user.config_commands, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

}
