import * as React from "react";
import { useState } from "react";

// region Libraries
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { ServiceOrderMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Components
import ServiceOrderModal from "@components/Modal/ServiceOrder";
// endregion Components
// region Styles
import { Container } from "./styles";
// endregion Styles

const ServiceOrderSpeedDial: React.FC = () => {

  // region States
  const [open, setOpen] = useState(false);
  const [openModalNewServiceOrder, setOpenModalNewServiceOrder] = useState(false);
  // endregion States
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  const actions = [
    { id: 1, name: t(ServiceOrderMessages.newServiceOrder) }
  ];

  const handleClick = () => {
    setOpen(!open);
  };

  const handleModal = (id: number) => {
    if (id === 1) setOpenModalNewServiceOrder(!openModalNewServiceOrder);
  };

  return (
    <Container>
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        open={open}
        onClick={handleClick}
        FabProps={{
          disableRipple: true,
          style: { backgroundColor: open ? "#2968A8" : "#002951" }
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={(
              <>
                {action.name}
              </>
            )}
            FabProps={{ disableRipple: true }}
            onClick={() => handleModal(action.id)}
            tooltipTitle=""
          />
        ))}
      </SpeedDial>
      <ServiceOrderModal open={openModalNewServiceOrder} onClose={() => handleModal(1)} />
    </Container>
  );
};

export default ServiceOrderSpeedDial;
