export enum LoginMessages {

  /* region Frontend messages */
  goToLoginPage = "login.goToLoginPage",
  expiredSession = "login.expiredSession",
  expiredSessionInstructions = "login.expiredSessionInstructions",
  login = "login.login",
  loginWithMicrosoft = "login.loginWithMicrosoft",
  loginOptionsText = "login.loginOptionsText",
  forgotMyPassword = "login.forgotPassword",
  recoverPassword = "login.recoverPassword",
  redirecting = "login.redirecting",
  sending = "login.sending",
  passwordConfirmation = "login.passwordConfirmation",
  resettingPassword = "login.resettingPassword",
  resetPassword = "login.resetPassword",
  resetLinkExpired = "login.resetLinkExpired",
  resetLinkExpiredInstructions = "login.resetLinkExpiredInstructions",
  requestAnotherLink = "login.requestAnotherLink",
  password = "login.password",
  ssoAccessNotEnabled = "login.ssoAccessNotEnabled",
  ssoRequiredForLogin = "login.ssoRequiredForLogin",
  ssoLoginFailed = "login.ssoLoginFailed",
  /* endregion Frontend messages */

  /* region Backend messages */
  accessAllowed = "login.accessAllowed",
  tokenActivationSuccess = "login.tokenActivationSuccess",
  tokenDeactivationSuccess = "login.tokenDeactivationSuccess",
  tokenDeleteSuccess = "login.tokenDeleteSuccess",
  instructionsEmailSent = "login.instructionsEmailSent",
  wrongPassword = "login.wrongPassword",
  onlyAdminCanGenerateIntegrationToken = "login.onlyAdminCanGenerateIntegrationToken",
  tokenNotRegistered = "login.tokenNotRegistered",
  tokenNotLinkedToUserSent = "login.tokenNotLinkedToUserSent",
  wrongIDFormat = "login.wrongIDFormat"
  /* endregion Backend messages */
}

export type TranslationLoginMessages = {

[key in LoginMessages]: string;
}
