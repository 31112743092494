/* eslint-disable no-param-reassign,camelcase */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { RouteComponentProps, Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";

// region Libraries
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, LoginMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Services
import api from "@services/api";
// endregion Services
// region Components
import Logo from "@atoms/FleetLogo";
import InputPassword from "@atoms/Login/InputPassword";
import ButtonLoginFleet from "@atoms/Login/ButtonLoginFleet";
import LanguageIcon from "@components/LanguageIcon";
import DialogConfirmAction from "../../../components/Dialog/ConfirmAction";
// endregion Components
// region Hooks
import { useToast } from "../../../hooks/useToast";
import { useAuth } from "../../../hooks/useAuth";
import getValidationErrors from "../../../hooks/getValidationErrors";
// endregion Hooks
// region Styles
import { Container, Content } from "./styles";
// endregion Styles

interface TokenData { user: { id_user: string, email: string} }
interface ResetPasswordFormData { password: string; passwordConfirm?: string; email: string; idUser: string; }
interface RouteParams { token?: string; }

const ResetPassword: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {

  const { addToast } = useToast();
  const { getTokenData, validateToken, signOut } = useAuth();
  const { t } = useTranslation();

  const [tokenData, setTokenData] = useState<TokenData>({} as TokenData);
  const [loading, setLoading] = useState(false);
  const [openDialogExpired, setOpenDialogExpired] = useState(false);

  const formRef = useRef<FormHandles>(null);

  /** Validate form with new password and redefine
   * @param formData
   */
  const handleSubmit = useCallback(
    async (formData: ResetPasswordFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define form rules
        const schema = Yup.object().shape({
          password: Yup.string().trim().min(6, t(YupMessages.passwordMinLength)),
          passwordConfirm: Yup.string().trim().oneOf([Yup.ref("password"), null], t(YupMessages.passwordsDontMatch))
            .min(6, t(YupMessages.passwordMinLength))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Request reset password on sever
        setLoading(true);

        delete formData.passwordConfirm;

        // Update user password
        signOut();
        api.defaults.headers.authorization = `Bearer ${match.params.token}`;

        const { data } = await api.patch(`users/update/${tokenData.user.id_user}`, formData);

        if (data.status === "success") {

          addToast({ type: "success",
            title: t(GlobalMessages.success),
            description: ReactDOMServer.renderToString(<>{data.message}<br />{t(LoginMessages.redirecting)}</>),
            duration: 3000 });

          setTimeout(() => { window.location.href = "/"; }, 3000);

        } else addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });

      } catch (error: any) {

        // Err of form validation according shape
        if (error instanceof Yup.ValidationError) {

          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      } finally {
        setLoading(false);
      }

    }, [addToast, tokenData, match.params.token, signOut, t]
  );

  // Verify if have token and if token is valid (Always the token url change)
  useEffect(() => {

    const token = match.params.token || "";

    if (!validateToken(token)) setOpenDialogExpired(true);
    else setTokenData(getTokenData(token));

  }, [match.params.token, validateToken, getTokenData]);

  return (
    <Container>
      <Content>
        <Logo />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputPassword name="password" />
          <InputPassword name="passwordConfirm" placeHolder={t(LoginMessages.passwordConfirmation)} />
          <ButtonLoginFleet className="button" loading={loading} loadingContent={t(LoginMessages.resettingPassword)} type="submit">
            {t(LoginMessages.resetPassword)}
          </ButtonLoginFleet>
          <Link to="/"><FiLogIn />{t(LoginMessages.goToLoginPage)}</Link>
        </Form>
      </Content>
      <DialogConfirmAction
        open={openDialogExpired}
        title={t(LoginMessages.resetLinkExpired)}
        onClose={() => ""}
        actions={[
          { text: "OK", action: () => window.location.href = "/forgot-password" }
        ]}
      >
        {t(LoginMessages.resetLinkExpiredInstructions)}<br />
        {t(LoginMessages.requestAnotherLink)}
      </DialogConfirmAction>
      <LanguageIcon />
    </Container>
  );
};

export default ResetPassword;
