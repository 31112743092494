import React, { useEffect, useState } from "react";

// region Libraries
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, MenuMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Store
import { VehicleStatesList } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
// endregion Store
// region Components
import { MapAllVehiclesCoords, MarkersVisualizationTypes } from "@components/Map";
import MapMarkerChangers from "@components/MapMarkerChangers";
// endregion Components
// region Styles
import { ContainerModalMapAllVehicles } from "./styles";
// endregion Styles

type ModalMapAllVehiclesProps = { vehicles: VehicleStatesList, open: boolean, onClose: () => void }
const ModalMapAllVehicles: React.FC<ModalMapAllVehiclesProps> = ({ vehicles, open, onClose }) => {

  const [vehiclesWithLastCoords, setVehiclesWithLastCoords] = useState<VehicleStatesList>(vehicles);
  const [markersVisualizationType, setMarkersVisualizationType] = useState<MarkersVisualizationTypes>(
    { name: false, licensePlate: false }
  );

  const { t } = useTranslation();

  // When vehicles states change, get de last coord of tracker and link in vehicle to load map
  useEffect(() => {

    const vehiclesAux: VehicleStatesList = {};

    Object.keys(vehicles).forEach((key) => {

      vehiclesAux[key] = [];

      vehicles[key].forEach((vehicle) => {
        vehicle.hardwares.forEach((hardware) => {
          if (hardware.type.description === "Rastreador") {
            vehiclesAux[key].push(
              { ...vehicle, last_coord: { latitude: hardware.last_latitude, longitude: hardware.last_longitude } }
            );
          }
        });
      });
    });

    setVehiclesWithLastCoords(vehiclesAux);

  }, [vehicles]);

  return (
    <ContainerModalMapAllVehicles id="modalMapAllVehicles">
      <Dialog
        open={open}
        onClose={() => onClose()}
        scroll="paper"
        container={document.getElementById("modalMapAllVehicles")}
      >
        <DialogTitle className="mHeader">
          {t(MenuMessages.generalLocation)}
        </DialogTitle>
        <DialogContent dividers className="mContent">
          <DialogContentText
            tabIndex={-1}
            component="div"
          />
          {open && (
            <MapAllVehiclesCoords
              vehicles={vehiclesWithLastCoords}
              mapHeight="70vh"
              markersVisualizationType={markersVisualizationType}
            />
          )}
        </DialogContent>
        <DialogActions className="mFooter">
          <MapMarkerChangers
            markersVisualizationType={markersVisualizationType}
            setMarkersVisualizationType={setMarkersVisualizationType}
          />
          <Button disableRipple onClick={() => onClose()} color="primary">{t(GlobalMessages.close)}</Button>
        </DialogActions>
      </Dialog>
    </ContainerModalMapAllVehicles>
  );
};

export default ModalMapAllVehicles;
