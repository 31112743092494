/* eslint-disable import/prefer-default-export */
import { TranslationZonesModuleMessages, ZonesModuleMessages } from "../interfaces";

export const messages: TranslationZonesModuleMessages = {
  [ZonesModuleMessages.columnName]: "Name",
  [ZonesModuleMessages.columnDescription]: "Description",
  [ZonesModuleMessages.newRegister]: "New restriction zone",
  [ZonesModuleMessages.columnActive]: "Active",
  [ZonesModuleMessages.columnInactive]: "Inactive",

  [ZonesModuleMessages.readSuccess]: "Zones read successfully",
  [ZonesModuleMessages.readError]: "Error reading zones",
  [ZonesModuleMessages.createSuccess]: "Zones created successfully",
  [ZonesModuleMessages.createError]: "Error creating zones",
  [ZonesModuleMessages.noZonesFound]: "No zones found"
};
