import { ManagementMessages, TranslationManagementMessages } from "../interfaces";

export const messages: TranslationManagementMessages = {
  [ManagementMessages.allowedChangeParams]: "Acceso a la configuración",
  [ManagementMessages.allowedChangeVehicles]: "Acceso a los vehículos",
  [ManagementMessages.allowedChangeDrivers]: "Acceso a los conductores",
  [ManagementMessages.allowedChangeLocations]: "Acceso a las ubicaciones",
  [ManagementMessages.functionalityGroup]: "Funcionalidades",
  [ManagementMessages.alertVisualizationGroup]: "Visualización de alertas",
  [ManagementMessages.dataTableName]: "Nombre",
  [ManagementMessages.dataTableRegisterDate]: "Fecha de registro",
  [ManagementMessages.dataTableLastAccess]: "Último acceso",
  [ManagementMessages.dataTableEmail]: "Email",
  [ManagementMessages.dataTableCode]: "Código",
  [ManagementMessages.detailsActiveUser]: "Usuario activo",
  [ManagementMessages.detailsDailyReport]: "Recibir reporte diario",
  [ManagementMessages.detailsWeeklyReport]: "Recibir reporte semanal",
  [ManagementMessages.detailsFullname]: "Nombre completo",
  [ManagementMessages.detailsEmail]: "Email",
  [ManagementMessages.detailsAdmin]: "Administrador",
  [ManagementMessages.detailsAuthorizedPlants]: "Plantas autorizadas",
  [ManagementMessages.detailsPassword]: "Contraseña",
  [ManagementMessages.detailsConfirmPassword]: "Confirmar contraseña",
  [ManagementMessages.detailsTelegramId]: "Telegram ID",
  [ManagementMessages.detailsPermissions]: "Permisos",
  [ManagementMessages.detailsResendEmail]: "Reenviar email",
  [ManagementMessages.detailsTelegramIdInfo]: "Recibe alertas vía Telegram. Para obtener más información, haga clic aquí.",
  [ManagementMessages.detailsTelegramError]: "No se pueden enviar las últimas alertas. Consulta las instrucciones"
  + " AQUÍ.",
  [ManagementMessages.detailsHeaderRegister]: "Registro de usuario",
  [ManagementMessages.deleteConfirmationTitle]: "Confirmación de eliminación",
  [ManagementMessages.deleteConfirmationText]: "¿Estás seguro de que deseas eliminar el usuario",
  [ManagementMessages.validationTitle]: "Validación de usuario",
  [ManagementMessages.validationText]: "Se enviará un correo electrónico de confirmación de cuenta y registro de nueva contraseña para",
  [ManagementMessages.validationConfirmation]: "¿Quieres continuar?",
  [ManagementMessages.formEmailRegistered]: "Correo electrónico ya registrado",
  [ManagementMessages.formRequiredName]: "El nombre es obligatorio",
  [ManagementMessages.formRequiredEmail]: "El email es obligatorio.",
  [ManagementMessages.formValidEmail]: "Proporcione un email válido",
  [ManagementMessages.formRequiredPlant]: "Seleccione al menos una planta autorizada",
  [ManagementMessages.formValidPassword]: "La contraseña debe contener al menos 6 caracteres.",
  [ManagementMessages.formValidConfirmPassword]: "La contraseña debe contener al menos 6 caracteres.",
  [ManagementMessages.formEqualsPassword]: "Las contraseñas no coinciden"
};
