/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from "react";

// region Libraries
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, VehicleBlockMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Assets
import CheckGif from "@assets/gifs/checkOk.gif";
// endregion Assets
// region Interfaces
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Interfaces
// region Components
import TimeLineVehiclesItem from "@components/TimeLines/TimeLineVehicles/TimeLineVehiclesItem";
import VehicleBlockHeader from "@components/Modal/VehicleBlock/VehicleBlockHeader";
import VehicleBlockForm from "@components/Modal/VehicleBlock/VehicleBlockForm";
// endregion Components
// region Styles
import * as Styled from "./styles";
// endregion Styles

type ModalVehicleBlockProps = {
  vehicle: Vehicle;
  onClose: () => void;
  open: boolean;
  isBlocked: boolean;
}

const ModalVehicleBlock: React.FC<ModalVehicleBlockProps> = ({
  vehicle,
  onClose,
  open,
  isBlocked
}) => {

  // region States
  const [openDialogSuccessBlock, setOpenDialogSuccessBlock] = useState(false);
  // endregion States
  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region Functions

  // Handlers
  const handleClose = useCallback(() => { onClose(); }, [onClose]);

  /**
   * Action on successful command block / unblock created
   */
  const onSuccessfulCommand = useCallback(() => {
    setOpenDialogSuccessBlock(true);
  }, []);

  // endregion Functions

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
    >
      <Styled.ModalVehicleBlock isMobile={isMobile} isBlocked={isBlocked}>
        <VehicleBlockHeader isBlocked={isBlocked} />
        <DialogContent dividers>
          <Grid container spacing={3} className="vehicle-block-dialog__list">
            <Grid item xs={6} md={6} lg={6} className="vehicle-block-dialog__item-grid">
              <div className="block">
                <div className="vehicle-block-dialog__instruction">
                  <strong className="strong">1</strong>
                  {isBlocked ? t(VehicleBlockMessages.checkVehicleToBeUnblocked) : t(VehicleBlockMessages.checkVehicleToBeBlocked)}
                </div>
                <TimeLineVehiclesItem vehicles={[vehicle]} />
              </div>
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="vehicle-block-dialog__item-grid">
              <div className="block">
                <VehicleBlockForm isBlocked={isBlocked} vehicle={vehicle} onSuccessfulCommand={onSuccessfulCommand} />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="vehicle-block-dialog__footer">
          <Button disableRipple onClick={() => handleClose()} color="primary">
            {t(GlobalMessages.close)}
          </Button>
        </DialogActions>
      </Styled.ModalVehicleBlock>
      <Styled.ModalSuccessBlock id="modalBlockSuccess">
        <Dialog
          open={openDialogSuccessBlock}
          container={() => document.getElementById("modalBlockSuccess")}
        >
          <DialogContent>
            <img src={CheckGif} alt="Sucesso" /><br />
            {isBlocked ? t(VehicleBlockMessages.unblockCommandSent) : t(VehicleBlockMessages.blockCommandSent)}<br />
            {t(VehicleBlockMessages.blockProcessMonitoringInstruction)}.<br /><br />
            {t(VehicleBlockMessages.accessAvailableAt)}:<br /> <b>{t(VehicleBlockMessages.blockTabPath)}.</b>
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={() => onClose()}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Styled.ModalSuccessBlock>
    </Dialog>
  );
};

export default ModalVehicleBlock;
