/* eslint-disable import/prefer-default-export */
import { TimelineVehiclesItemMessages, TranslationTimelineVehiclesItemMessages } from "../interfaces";

export const messages: TranslationTimelineVehiclesItemMessages = {
  [TimelineVehiclesItemMessages.latestUpdate]: "Updated ",
  [TimelineVehiclesItemMessages.rightNow]: "right now",
  [TimelineVehiclesItemMessages.for]: "for",
  [TimelineVehiclesItemMessages.before]: "Previously",
  [TimelineVehiclesItemMessages.during]: "for",
  [TimelineVehiclesItemMessages.ago]: "ago",
  [TimelineVehiclesItemMessages.vehicleRunningFor]: "Vehicle running for",
  [TimelineVehiclesItemMessages.moreInfo]: "More information",
  [TimelineVehiclesItemMessages.driverIbuttonVerified]: "Driver verified by Ibutton",
  [TimelineVehiclesItemMessages.noDriver]: "No driver assigned",
  [TimelineVehiclesItemMessages.noCurrentAllocation]: "No current allocation",
  [TimelineVehiclesItemMessages.distanceTraveledOnTrip]: "Distance traveled on trip"
};
