/* eslint-disable import/prefer-default-export */
import {
  TranslationAggregateTruckTravelDataMessages,
  AggregateTruckTravelDataMessages
} from "../interfaces";

export const messages: TranslationAggregateTruckTravelDataMessages = {
  [AggregateTruckTravelDataMessages.origin]: "Origin",
  [AggregateTruckTravelDataMessages.loadingLocation]: "Loading location",
  [AggregateTruckTravelDataMessages.unloadingLocation]: "Unloading location",
  [AggregateTruckTravelDataMessages.productDocument]: "Product document",
  [AggregateTruckTravelDataMessages.serviceOrderDate]: "Service order date",
  [AggregateTruckTravelDataMessages.travelStartDate]: "Travel start date",
  [AggregateTruckTravelDataMessages.travelDocumentDate]: "Travel document date",
  [AggregateTruckTravelDataMessages.quantity]: "Quantity"
};
