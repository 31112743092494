import { VehiclesHistoryMessages, TranslationVehiclesHistoryMessages } from "../interfaces";

export const messages: TranslationVehiclesHistoryMessages = {
  [VehiclesHistoryMessages.data]: "Data",
  [VehiclesHistoryMessages.equipment]: "Equipment",
  [VehiclesHistoryMessages.travels]: "Trips",
  [VehiclesHistoryMessages.engineRuntime]: "Engine runtime",
  [VehiclesHistoryMessages.kmTraveled]: "Km traveled",
  [VehiclesHistoryMessages.productionVolume]: "Production volume",
  [VehiclesHistoryMessages.volumePerTrip]: "Volume/trip",
  [VehiclesHistoryMessages.durationPerTrip]: "Duration/trip",
  [VehiclesHistoryMessages.engineRuntimePerTrip]: "Engine runtime/trip",
  [VehiclesHistoryMessages.kmTraveledPerTrip]: "Km/trip",
  [VehiclesHistoryMessages.speedingAlerts]: "Speeding occurrences",
  [VehiclesHistoryMessages.improperDischargeAlerts]: "Improper discharges",
  [VehiclesHistoryMessages.improperStopAlerts]: "Improper stops",
  [VehiclesHistoryMessages.licensePlate]: "License plate",
  [VehiclesHistoryMessages.durationInMinutes]: "Duration (min)",
  [VehiclesHistoryMessages.engineRuntimeInMinutes]: "Engine runtime (min)",
  [VehiclesHistoryMessages.distanceInKilometers]: "Distance (km)",
  [VehiclesHistoryMessages.durationPerTripInMinutes]: "Duration/trip (min)",
  [VehiclesHistoryMessages.engineRuntimePerTripInMinutes]: "Engine runtime/trip (min)",
  [VehiclesHistoryMessages.distancePerTripInKilometers]: "Distance/trip (km)",
  [VehiclesHistoryMessages.title]: "Vehicles history",
  [VehiclesHistoryMessages.vehicleType]: "Vehicle type",
  [VehiclesHistoryMessages.generalHistory]: "General history",
  [VehiclesHistoryMessages.historyByEquipment]: "History by equipment"
};
