/* eslint-disable import/prefer-default-export, max-len */
import { LoginMessages, TranslationLoginMessages } from "../interfaces";

export const messages: TranslationLoginMessages = {

  /* region Frontend messages */
  [LoginMessages.goToLoginPage]: "Ir a la página de inicio de sesión",
  [LoginMessages.expiredSession]: "Su sesión ha expirado",
  [LoginMessages.expiredSessionInstructions]: "Inicie sesión nuevamente con su usuario de acceso para continuar utilizando el sistema de forma segura",
  [LoginMessages.login]: "Iniciar sesión",
  [LoginMessages.loginWithMicrosoft]: "Iniciar sesión con Microsoft",
  [LoginMessages.loginOptionsText]: "O inicie sesión con",
  [LoginMessages.forgotMyPassword]: "Olvidé mi contraseña",
  [LoginMessages.recoverPassword]: "Recuperar contraseña",
  [LoginMessages.redirecting]: "Redireccionando a acceso...",
  [LoginMessages.sending]: "Enviando...",
  [LoginMessages.passwordConfirmation]: "Confirmación de contraseña",
  [LoginMessages.resettingPassword]: "Restableciendo...",
  [LoginMessages.resetPassword]: "Restablecer",
  [LoginMessages.resetLinkExpired]: "Link de restablecimiento caducado",
  [LoginMessages.resetLinkExpiredInstructions]: "¡El link de restablecimiento de contraseña ha caducado!",
  [LoginMessages.requestAnotherLink]: "Solicite otro e-mail de restablecimiento e intente nuevamente.",
  [LoginMessages.password]: "Contraseña",
  [LoginMessages.ssoAccessNotEnabled]: "El acceso SSO no está configurado para esta empresa.",
  [LoginMessages.ssoRequiredForLogin]: "SSO es necesario para iniciar sesión en esta empresa. Inicie sesión con la opción de inicio de sesión a continuación.",
  [LoginMessages.ssoLoginFailed]: "Error al iniciar sesión con SSO",
  /* endregion Frontend messages */
  /* region Backend messages */
  [LoginMessages.accessAllowed]: "Acceso permitido",
  [LoginMessages.tokenActivationSuccess]: "Token activado con éxito",
  [LoginMessages.tokenDeactivationSuccess]: "Token desactivado con éxito",
  [LoginMessages.tokenDeleteSuccess]: "Token eliminado con éxito",
  [LoginMessages.instructionsEmailSent]: "¡Email con instrucciones enviado con éxito!",
  [LoginMessages.wrongPassword]: "Contraseña incorrecta",
  [LoginMessages.onlyAdminCanGenerateIntegrationToken]: "Solo los usuarios administradores pueden generar un token"
  + " de integración",
  [LoginMessages.tokenNotRegistered]: "Token no registrado en nuestro sistema",
  [LoginMessages.tokenNotLinkedToUserSent]: "El token en cuestión no pertenece al usuario enviado",
  [LoginMessages.wrongIDFormat]: "ID(s) no se encuentra(n) en el formato correcto"
  /* endregion Backend messages */
};
