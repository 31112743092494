import { Reducer } from "redux";
import { Location } from "@shared/interfaces/location.interface";
import { LocationType } from "@shared/interfaces/location-type.interface";
import { Regional } from "@shared/interfaces/regional.interface";
import { LocationsFormState, LocationsFormTypes, Coord, State } from "./locations-form.type";

const INITIAL_STATE: LocationsFormState = {
  open: false,
  type: "details",
  data: { finish_point: true } as Location,
  fieldsValues: {
    cep: "",
    coord: {} as Coord,
    type: {} as LocationType,
    uf: {} as State,
    regional: {} as Regional
  },
  actions: {
    type: "create",
    response: {} as Location
  },
  googleAddressData: {} as google.maps.GeocoderResult,
  externalComponent: false
};

const reducer: Reducer<LocationsFormState> = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LocationsFormTypes.SET_LOCATIONS_FORM:
      return { ...INITIAL_STATE, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
