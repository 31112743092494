export enum MixerDiagnosticCommandTypes {
  ALIVE = "ALIVE",
  NO_TX_COMMUNICATION = "NO_TX_COMMUNICATION",
  LOW_BATTERY = "LOW_BATTERY",
  POSSIBLE_VIOLATION = "POSSIBLE_VIOLATION",
  NO_RX_COMMUNICATION = "NO_RX_COMMUNICATION"
}

export enum MixerDiagnosticCommandTypesDescription {
  ALIVE = "Funcionando",
  NO_TX_COMMUNICATION = "Sem comunicação com sensor TX por mais de 48 horas",
  NO_RX_COMMUNICATION = "Sem comunicação com sensor RX por mais de 24 horas",
  LOW_BATTERY = "Bateria baixa do sensor",
  POSSIBLE_VIOLATION = "Possível violação do sensor",
  UNKNOWN = "Diagnóstico de status de sensor desconhecido"
}

export const DiagnosticCommandCounterMapping : { [key: number]: string } = {
  2: MixerDiagnosticCommandTypes.ALIVE,
  8: MixerDiagnosticCommandTypes.POSSIBLE_VIOLATION,
  12: MixerDiagnosticCommandTypes.NO_TX_COMMUNICATION,
  18: MixerDiagnosticCommandTypes.LOW_BATTERY
};
