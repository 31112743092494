/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { State } from "./reg_states.entity";
import { Location } from "./reg_locations.entity";

@Entity("reg_zip_codes")
export class ZipCode {

  @PrimaryGeneratedColumn("uuid")
  id_zip_code?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
  zip_code?: string;

  @Column({ type: "varchar", nullable: false })
  name?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToOne(() => State, (state) => state.zip_codes, { nullable: false })
  @JoinColumn({ name: "id_state_fk" })
  state?: State;

  @OneToMany(() => Location, (locations) => locations.zip_code)
  locations?: Location[];

}
