import { SettingsMessages, TranslationSettingsMessages } from "../interfaces";

export const messages: TranslationSettingsMessages = {
  [SettingsMessages.settingsTitle]: "Settings",
  [SettingsMessages.parameters]: "Parameters",
  [SettingsMessages.timeForUndueStopOptionDescription]: "Time needed for a vehicle stop to be considered undue",
  [SettingsMessages.functionalities]: "Functionalities",
  [SettingsMessages.enablePumpTimelineOptionDescription]: "Enable pump map",
  [SettingsMessages.minutes]: "Minutes"
};
