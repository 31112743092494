export enum MailMessages {

  // Password Reset
  passwordReset = "mail.passwordReset",
  looksLikeYouForgotYourPassword = "mail.looksLikeYouForgotYourPassword",
  clickHereToResetYourPassword = "mail.clickHereToResetYourPassword",
  setYourNewPassword = "mail.setYourNewPassword",
  resetMyPassword = "mail.resetMyPassword",
  ignoreEmailIfPasswordNotForgotten = "mail.ignoreEmailIfPasswordNotForgotten",

  // Account confirmation
  accountConfirmation = "mail.accountConfirmation",
  welcomeTo = "mail.welcomeTo",
  accessToPlatform = "mail.accessToPlatform",
  confirmYourAccount = "mail.confirmYourAccount",
  createMyPassword = "mail.createMyPassword",

  // General
  hello = "mail.hello",
  needMoreInformation = "mail.needMoreInformation",
  sendUsAnEmail = "mail.sendUsAnEmail",
  bestRegards = "mail.bestRegards",
  distributedBy = "mail.distributedBy",

  // Report
  dailyReportSubject = "mail.dailyReportSubject",
  weeklyReportSubject = "mail.weeklyReportSubject",
  syntheticReportFileName = "mail.syntheticReportFileName",
  analyticalReportFileName = "mail.analyticalReportFileName",
  belowYouCanSeeTheAlertGraphs = "mail.belowYouCanSeeTheAlertGraphs",
  ofTheLastTwoWeeks = "mail.ofTheLastTwoWeeks",
  ofTheLastTwoDays = "mail.ofTheLastTwoDays",
  moreInformationCanBeFoundAt = "mail.moreInformationCanBeFoundAt"
}

export type TranslationMailMessages = {
  [key in MailMessages]: string;
}
