import React, { useState } from "react";

// region Libraries
import { Language } from "@material-ui/icons";
// endregion Libraries
// region Languages
import { TLanguages } from "@shared/languages/languageData";
// endregion Languages
// region Components
import LanguageMenu from "@components/LanguageMenu";
// endregion Components
// region Styles
import { Container } from "./styles";
// endregion Styles

type LanguageIconProps = {
  inHeader?: boolean;
};

const LanguageIcon: React.FC<LanguageIconProps> = ({ inHeader }) => {

  const [languageElementAnchor, setLanguageElementAnchor] = useState<null | HTMLElement>(null);

  /**
   * Handle language element anchor state on click and close
   */
  const handleLanguageClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setLanguageElementAnchor(event.currentTarget.parentElement);
  };

  const handleLanguageClose = (_language: TLanguages) => {
    setLanguageElementAnchor(null);
  };

  return (
    <Container inHeader={inHeader}>
      <Language onClick={handleLanguageClick} />
      <LanguageMenu anchorElement={languageElementAnchor} onClose={handleLanguageClose} inHeader={inHeader} />
    </Container>
  );
};

export default LanguageIcon;
