/* eslint-disable no-restricted-globals */

import React, { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import init from "@elastic/apm-rum";
import { socket, WebSocketProvider } from "@services/websocketContext";

import { DefaultTheme, ThemeProvider } from "styled-components";

import i18nInit from "src/translations/i18n";
import { useResizeDetector } from "react-resize-detector";
import { TelluriaProvider } from "@npm-telluria-tecnologia/telluria-ui";
import utils from "@utils/useful-functions";
import usePersistedState from "./hooks/usePersistedState";
import AppProvider from "./hooks";

// Themes
import light from "./styles/themes/light.theme";

import store from "./store";
import { ScreenSize, ScreenState } from "./store/ducks/Screen/screen.type";
import { ScreenActions } from "./store/ducks/Screen/screen.action";

import GlobalStyle from "./styles/global";
import Routes from "./routes";

const App: React.FC = () => {

  // Initialize Elastic APM
  init({
    serviceName: `TopconFLEET Frontend - ${location.hostname.split(".")[0]}`,
    serverUrl: "https://elk.topconsuite.io:8200"
  });

  // Initialize i18n
  i18nInit();

  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);

  return (
    <TelluriaProvider components={{ toast: true }}>
      <ThemeProvider theme={theme}>
        <WebSocketProvider value={socket}>
          <Provider store={store}>
            <AppProvider>
              <BrowserRouter>
                <Screen />
                <GlobalStyle />
                <Routes />
              </BrowserRouter>
            </AppProvider>
          </Provider>
        </WebSocketProvider>
      </ThemeProvider>
    </TelluriaProvider>
  );
};

const Screen: React.FC = () => {

  const dispatch = useDispatch();
  const targetRef = useRef<HTMLDivElement>(null);
  const { width, height } = useResizeDetector({ targetRef });

  useEffect(() => {
    utils.cleanCacheData().then();
  }, []);

  useEffect(() => {

    const screen: ScreenState = {
      width,
      height,
      size: undefined,
      platform: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "mobile"
        : "desktop"
    };

    if (width && width <= 600) screen.size = ScreenSize.small;
    else if (width && width > 600 && width <= 800) screen.size = ScreenSize.medium;
    else screen.size = ScreenSize.large;

    dispatch(ScreenActions.setScreenSize(screen));

  }, [width, height, dispatch]);

  return <div ref={targetRef} style={{ height: "100%", width: "100%", position: "absolute", zIndex: -1 }} />;
};

export default App;
