/* eslint-disable camelcase */
import { userInfo } from "os";
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { LogIntegration } from "./log_integrations.entity";
import { User } from "./reg_users.entity";

@Entity("reg_tokens")
export class Token {

  @PrimaryGeneratedColumn("uuid")
  id_token?: string;

  @Column({ type: "varchar", nullable: false })
  token?: string;

  @Column({ type: "boolean", nullable: false, default: true })
  active?: boolean;

  @ManyToOne(() => User, (user) => user.tokens, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
  user?: User;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => LogIntegration, (log_integrations) => log_integrations.request_token)
  log_integrations?: LogIntegration[];

}
