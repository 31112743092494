export enum SettingsMessages {
  settingsTitle = "settings.settingsTitle",
  parameters = "settings.parameters",
  timeForUndueStopOptionDescription = "settings.timeForUndueStopOptionDescription",
  functionalities = "settings.functionalities",
  enablePumpTimelineOptionDescription = "settings.enablePumpTimelineOptionDescription",
  minutes = "settings.minutes"
}

export type TranslationSettingsMessages = {
  [key in SettingsMessages]: string;
}
