/* eslint-disable camelcase */

import React from "react";
import { useSelector } from "react-redux";
import TimeLineVehiclesItem from "@components/TimeLines/TimeLineVehicles/TimeLineVehiclesItem";
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { ScreenSize } from "@store/ducks/Screen/screen.type";

import TravelSpeedHeader from "@components/Modal/SpeedTravelAlert/TravelSpeedHeader";
import { TravelAlertFullSpeedMessage } from "@shared/languages/interfaces/travelAlertFullSpeedMessage";
import { t } from "i18next";
import TravelSpeedAlertForm from "@components/Modal/SpeedTravelAlert/TravelSpeedAlertForm";
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import * as Styled from "./styles";

interface ModalSpeedTravelProps {
  travel: Travel;
  vehicle:Vehicle;
  onClose: () => void;
  open: boolean;
}
const ModalSpeedTravel: React.FC<ModalSpeedTravelProps> = ({
  travel,
  vehicle,
  onClose,
  open
}) => {
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
    >
      <Styled.ModalSpeedTravel isMobile={isMobile}>
        <TravelSpeedHeader hasTravel={!!travel?.alert_maximum_speed} />
        { open && (
          <DialogContent dividers>
            <Grid container spacing={3} className="travel-speed-dialog__list">
              <Grid item xs={6} md={6} lg={6} className="travel-speed-dialog__item-grid">
                <div className="speed">
                  { !travel.alert_maximum_speed && (
                    <p>
                      <strong className="strong" style={{ color: "red" }}> 1 </strong>
                      {t(TravelAlertFullSpeedMessage.checkVehicle)}
                    </p>
                  )}
                  { travel.alert_maximum_speed && (
                    <p>
                      <strong className="strong" style={{ color: "red" }}> 1 </strong>
                      {t(TravelAlertFullSpeedMessage.vehicleWithAlert)}
                    </p>
                  )}
                  <TimeLineVehiclesItem vehicles={[vehicle]} />
                </div>
              </Grid>
              <Grid item xs={6} md={6} lg={6} className="travel-speed-dialog__item-grid">
                <div className="speed">
                  <TravelSpeedAlertForm travel={travel} vehicle={vehicle} onClose={() => onClose()} />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        )};
      </Styled.ModalSpeedTravel>
    </Dialog>
  );
};

export default ModalSpeedTravel;
