import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";
import { User } from "./reg_users.entity";
import { Alert } from "./reg_alerts.entity";
import { Status } from "../types/status.enum";

@Entity("rel_user_sent_alerts")
export class SentAlerts {

  @PrimaryGeneratedColumn("uuid")
    id_sent_alert?: string;

  @Column({ type: "enum", enum: Status, nullable: false, default: Status.SUCCESS })
    status?: Status;

  @Column({ type: "json", nullable: true })
    response?: string;

  @ManyToOne(() => User, (user) => user.sent_alerts_history, { primary: true })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

  @ManyToOne(() => Alert, (alert) => alert.sent_alerts_history, { primary: true, onDelete: "CASCADE" })
  @JoinColumn({ name: "id_alert_fk" })
    alert?: Alert;

  @Column({ type: "timestamp", nullable: false, default: () => "timezone('UTC', now())" })
    sent_date?: Date;

}
