import { VehiclesHistoryMessages, TranslationVehiclesHistoryMessages } from "../interfaces";

export const messages: TranslationVehiclesHistoryMessages = {
  [VehiclesHistoryMessages.data]: "Dado",
  [VehiclesHistoryMessages.equipment]: "Equipamento",
  [VehiclesHistoryMessages.travels]: "Viagens",
  [VehiclesHistoryMessages.engineRuntime]: "Tempo ligado",
  [VehiclesHistoryMessages.kmTraveled]: "Km rodados",
  [VehiclesHistoryMessages.productionVolume]: "Volume de produção",
  [VehiclesHistoryMessages.volumePerTrip]: "Volume/viagem",
  [VehiclesHistoryMessages.durationPerTrip]: "Duração/viagem",
  [VehiclesHistoryMessages.engineRuntimePerTrip]: "Tempo ligado/viagem",
  [VehiclesHistoryMessages.kmTraveledPerTrip]: "Km/viagem",
  [VehiclesHistoryMessages.speedingAlerts]: "Excessos de velocidade",
  [VehiclesHistoryMessages.improperDischargeAlerts]: "Descargas indevidas",
  [VehiclesHistoryMessages.improperStopAlerts]: "Paradas indevidas",
  [VehiclesHistoryMessages.licensePlate]: "Placa",
  [VehiclesHistoryMessages.durationInMinutes]: "Duração (min)",
  [VehiclesHistoryMessages.engineRuntimeInMinutes]: "Tempo ligado (min)",
  [VehiclesHistoryMessages.distanceInKilometers]: "Distância (km)",
  [VehiclesHistoryMessages.durationPerTripInMinutes]: "Duração/viagem (min)",
  [VehiclesHistoryMessages.engineRuntimePerTripInMinutes]: "Tempo ligado/viagem (min)",
  [VehiclesHistoryMessages.distancePerTripInKilometers]: "Distância/viagem (km)",
  [VehiclesHistoryMessages.title]: "Histórico de veículos",
  [VehiclesHistoryMessages.vehicleType]: "Tipo de veículo",
  [VehiclesHistoryMessages.generalHistory]: "Histórico geral",
  [VehiclesHistoryMessages.historyByEquipment]: "Histórico por equipamento"
};
