import styled from "styled-components";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding: 0.7rem 0;

  .filter-details__first {
    font-weight: 500;
  }
  .filter-details__second {
    color: rgb(0 0 0 / 60%);

    ul {
      padding: 0;
    }
  }

  .list-item {
    padding: 0.2rem;
  }

`;

const FiberManualRecordIcon = styled(FiberManualRecord)`
  margin-right: 0.3rem;
  padding-bottom: 0.2rem;
  width: 0.6rem;
`;

export { Container, DetailsItem, FiberManualRecordIcon };
