import { ScheduledReportMessages, TranslationScheduledReportMessages } from "../interfaces";

export const messages: TranslationScheduledReportMessages = {
  [ScheduledReportMessages.columnDescription]: "Description",
  [ScheduledReportMessages.columnStatus]: "Status",
  [ScheduledReportMessages.columnRegistrationDate]: "Creation date",
  [ScheduledReportMessages.columnProcessingDate]: "Processing date",
  [ScheduledReportMessages.columnReportType]: "Report",
  [ScheduledReportMessages.columnFileSize]: "File size",

  [ScheduledReportMessages.statusInQueue]: "In queue",
  [ScheduledReportMessages.statusProcessing]: "Processing...",
  [ScheduledReportMessages.statusProcessed]: "Processed",
  [ScheduledReportMessages.statusError]: "Error",
  [ScheduledReportMessages.statusCanceled]: "Canceled",

  [ScheduledReportMessages.emptyReport]: "Empty report",

  [ScheduledReportMessages.modalDescriptionTitle]: "The selected filter options would result in a very large report.",
  [ScheduledReportMessages.modalDescriptionTitleQuestion]: "Would you like to create a scheduled report for this?",
  [ScheduledReportMessages.modalScheduleReportName]: "Scheduled report name",
  [ScheduledReportMessages.modalButtonCancel]: "Cancel",
  [ScheduledReportMessages.modalButtonClose]: "Close",
  [ScheduledReportMessages.modalButtonCreate]: "Create",

  [ScheduledReportMessages.filterDetailsTitle]: "Report filter: ",

  [ScheduledReportMessages.readSuccess]: "Scheduled report read successfully",
  [ScheduledReportMessages.readError]: "Unable to read scheduled report",
  [ScheduledReportMessages.noScheduledReports]: "No scheduled reports found",
  [ScheduledReportMessages.updateSuccess]: "Scheduled report updated successfully",
  [ScheduledReportMessages.updateError]: "Unable to update scheduled report",
  [ScheduledReportMessages.createSuccess]: "Scheduled report created successfully",
  [ScheduledReportMessages.createError]: "Unable to create scheduled report",
  [ScheduledReportMessages.cancelSuccess]: "Scheduled report canceled successfully",
  [ScheduledReportMessages.cancelError]: "Unable to cancel scheduled report",
  [ScheduledReportMessages.scheduledReportNotFound]: "Scheduled report not found",
  [ScheduledReportMessages.renewLinkSuccess]: "Scheduled report link renewed successfully",
  [ScheduledReportMessages.renewLinkError]: "Unable to renew scheduled report link"
};
