import { ManagementMessages, TranslationManagementMessages } from "../interfaces";

export const messages: TranslationManagementMessages = {
  [ManagementMessages.allowedChangeParams]: "Acesso as configurações",
  [ManagementMessages.allowedChangeVehicles]: "Acesso aos veículos",
  [ManagementMessages.allowedChangeDrivers]: "Acesso aos motoristas",
  [ManagementMessages.allowedChangeLocations]: "Acesso as localizações",
  [ManagementMessages.functionalityGroup]: "Funcionalidades",
  [ManagementMessages.alertVisualizationGroup]: "Visualização de alertas",
  [ManagementMessages.dataTableName]: "Nome",
  [ManagementMessages.dataTableRegisterDate]: "Data de cadastro",
  [ManagementMessages.dataTableLastAccess]: "Último acesso",
  [ManagementMessages.dataTableEmail]: "Email",
  [ManagementMessages.dataTableCode]: "Código",
  [ManagementMessages.detailsActiveUser]: "Usuário ativo",
  [ManagementMessages.detailsDailyReport]: "Receber relatório diário",
  [ManagementMessages.detailsWeeklyReport]: "Receber relatório semanal",
  [ManagementMessages.detailsFullname]: "Nome completo",
  [ManagementMessages.detailsEmail]: "Email",
  [ManagementMessages.detailsAdmin]: "Administrador",
  [ManagementMessages.detailsAuthorizedPlants]: "Plantas autorizadas",
  [ManagementMessages.detailsPassword]: "Senha",
  [ManagementMessages.detailsConfirmPassword]: "Confirmar senha",
  [ManagementMessages.detailsTelegramId]: "Telegram ID",
  [ManagementMessages.detailsPermissions]: "Permissões",
  [ManagementMessages.detailsResendEmail]: "Reenviar email",
  [ManagementMessages.detailsTelegramIdInfo]: "Receba alertas pelo Telegram. Para saber mais clique aqui.",
  [ManagementMessages.detailsTelegramError]: "Não foi possível enviar os últimos alertas. Verifique as instruções"
  + " AQUI.",
  [ManagementMessages.detailsHeaderRegister]: "Cadastro de usuário",
  [ManagementMessages.deleteConfirmationTitle]: "Confirmação de exclusão",
  [ManagementMessages.deleteConfirmationText]: "Tem certeza que deseja excluir o usuário",
  [ManagementMessages.validationTitle]: "Validação de usuário",
  [ManagementMessages.validationText]: "Será enviado um email de confirmação de conta e cadastro de nova senha para",
  [ManagementMessages.validationConfirmation]: "Deseja prosseguir?",
  [ManagementMessages.formEmailRegistered]: "Email já cadastrado",
  [ManagementMessages.formRequiredName]: "O nome é obrigatório",
  [ManagementMessages.formRequiredEmail]: "O email é obrigatório",
  [ManagementMessages.formValidEmail]: "Informe um email válido",
  [ManagementMessages.formRequiredPlant]: "Selecione pelo menos uma usina autorizada",
  [ManagementMessages.formValidPassword]: "A senha deve conter no mínimo 6 caracteres",
  [ManagementMessages.formValidConfirmPassword]: "A senha deve conter no mínimo 6 caracteres",
  [ManagementMessages.formEqualsPassword]: "Senhas não conferem"
};
