import React, { useCallback, useEffect, useRef, useState } from "react";

// region Libraries
import { FormHandles } from "@unform/core";
import Popper from "@material-ui/core/Popper";
import classNames from "classnames";
import {
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid, makeStyles,
  Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Form } from "@unform/web";
import { Checkbox, TextField } from "unform-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as _ from "lodash";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, GlobalMessages, MenuMessages, VehicleTypesMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Hooks
import { useToast } from "@hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
// endregion Hooks
// region Store
import {
  FiltersDashboardState,
  LocationWithClient
} from "@store/ducks/Filters/FiltersDashboard/filters-dashboard.type";
import { FiltersDashboardActions } from "@store/ducks/Filters/FiltersDashboard/filters-dashboard.action";
import { INITIAL_STATE as filtersInitialState } from "@store/ducks/Filters/FiltersDashboard/filters-dashboard.reducer";
import { VehiclesStatesObj, VehicleStatesList } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
import { AlertsStateObj } from "@store/ducks/Alerts/alerts.type";
import { VehiclesStatesActions } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.action";
import { AlertsActions } from "@store/ducks/Alerts/alerts.action";
// endregion Store
// region Interfaces
import { Location } from "@shared/interfaces/location.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { Alert } from "@shared/interfaces/alert.interface";
import { Regional } from "@shared/interfaces/regional.interface";
import { Group } from "@shared/interfaces/group.interface";
// endregion Interfaces
// region Atoms
import LimitedChipsField from "@atoms/LimitedChipsField";
// endregion Atoms
// region Components
import ButtonLoading from "@components/Button/ButtonLoading";
// endregion Components
// region Constants
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
// endregion Constants
// region Styles
import { ContainerPopperFilterVehiclesDashboard } from "./styles";
// endregion Styles

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1101,
    "&[x-placement*=\"bottom\"] $arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    "&[x-placement*=\"top\"] $arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    "&[x-placement*=\"right\"] $arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    "&[x-placement*=\"left\"] $arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 12,
    width: "3em",
    height: "3em",
    "&::before": {
      content: "\"\"",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  }
}));

type PopperFilterVehiclesDashboardProps = { open: boolean, onClose: () => void, anchorEl: any }

interface OptionsFieldsFilter {
  vehicles: Vehicle[],
  constructions: LocationWithClient[],
  travelDocs: string[],
  plants: Location[],
  regionals: Regional[],
  groups: Group[]
}

interface OpenOptionsFieldsFilter {
  vehicles: boolean,
  constructions: boolean,
  travelDocs: boolean,
  plants: boolean,
  regionals: boolean,
  groups: boolean
}

interface LoadingOptionsFieldsFilter {
  vehicles: boolean,
  constructions: boolean,
  travelDocs: boolean,
  plants: boolean,
  regionals: boolean,
  groups: boolean
}

const PopperFilterVehiclesDashboard: React.FC<PopperFilterVehiclesDashboardProps> = (
  { open, onClose: closePopper, anchorEl }
) => {

  const { addToast } = useToast();
  const style = useStyles();

  const filtersDashboard = useSelector(({ filtersDashboard: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const alerts = useSelector(({ alerts: state }) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [arrowRef, setArrowRef] = useState<any>(null);
  const [arrow, setArrow] = useState(false);

  const formRef = useRef<FormHandles>(null);

  // State of options list on filter
  const [optionsFieldsFilter, setOptionsFieldsFilter] = useState<OptionsFieldsFilter>({
    constructions: [] as LocationWithClient[],
    travelDocs: [] as string[],
    plants: [] as Location[],
    vehicles: [] as Vehicle[],
    regionals: [] as Regional[],
    groups: [] as Group[]
  });

  // State to control if some option is open or not
  const [openOptionsFieldsFilter, setOpenOptionsFieldsFilter] = useState<OpenOptionsFieldsFilter>({
    constructions: false,
    travelDocs: false,
    plants: false,
    vehicles: false,
    regionals: false,
    groups: false
  });

  // State to show loading while input is filled with updated data
  const loadingOptionsFieldsFilter: LoadingOptionsFieldsFilter = {
    constructions: openOptionsFieldsFilter.constructions && optionsFieldsFilter.constructions.length === 0,
    travelDocs: openOptionsFieldsFilter.travelDocs && optionsFieldsFilter.travelDocs.length === 0,
    plants: openOptionsFieldsFilter.plants && optionsFieldsFilter.plants.length === 0,
    vehicles: openOptionsFieldsFilter.vehicles && optionsFieldsFilter.vehicles.length === 0,
    regionals: openOptionsFieldsFilter.regionals && optionsFieldsFilter.regionals.length === 0,
    groups: openOptionsFieldsFilter.groups && optionsFieldsFilter.groups.length === 0
  };

  // Active arrow style in popper when popper is open and clear ou fill the form
  useEffect(() => {

    setArrow(open);

    // If active is false, when opened, open with filtersTitle cleared
    if (!filtersDashboard.active) {
      setTimeout(() => {
        dispatch(FiltersDashboardActions.setFiltersDashboard({} as FiltersDashboardState));
      }, 350);
    }

  }, [open, dispatch, filtersDashboard.active]);

  // Vehicles filter options
  useEffect(() => {
    if (!openOptionsFieldsFilter.vehicles) setOptionsFieldsFilter({ ...optionsFieldsFilter, vehicles: [] });
  },
  // eslint-disable-next-line
    [openOptionsFieldsFilter.vehicles]);
  useEffect(() => {

    let active = true;
    const vehicles: Vehicle[] = [];

    if (!loadingOptionsFieldsFilter.vehicles) return undefined;

    // Get vehicles in global state store vehiclesStates
    Object.keys(vehiclesStates.all.list).forEach((key) => {
      // eslint-disable-next-line prefer-spread
      vehicles.push.apply(vehicles, vehiclesStates.all.list[key]);
    });

    // Verify if not have some vehicles
    if (_.isEmpty(vehicles)) {
      addToast({ type: "info", title: t(FilterMessages.noVehicles) });
      setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, vehicles: false });
    }

    if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, vehicles });

    return () => { active = false; };

  },
  // eslint-disable-next-line
    [loadingOptionsFieldsFilter.vehicles]);

  // Plants filter options
  useEffect(() => {
    if (!openOptionsFieldsFilter.plants) setOptionsFieldsFilter({ ...optionsFieldsFilter, plants: [] });
  },
  // eslint-disable-next-line
    [openOptionsFieldsFilter.plants]);

  // Plants filter options
  useEffect(() => {
    if (!openOptionsFieldsFilter.regionals) setOptionsFieldsFilter({ ...optionsFieldsFilter, regionals: [] });
  },
  // eslint-disable-next-line
    [openOptionsFieldsFilter.regionals]);

  // Plants filter options
  useEffect(() => {
    if (!openOptionsFieldsFilter.groups) setOptionsFieldsFilter({ ...optionsFieldsFilter, groups: [] });
  },
  // eslint-disable-next-line
    [openOptionsFieldsFilter.groups]);
  useEffect(() => {

    let active = true;
    let plants: Location[] = [];

    if (!loadingOptionsFieldsFilter.plants) return undefined;

    // Get plants in global state store vehiclesStates
    Object.keys(vehiclesStates.all.list).forEach((key) => {
      vehiclesStates.all.list[key].forEach((vehicle) => {
        if (vehicle.location) plants.push(vehicle.location);
      });
    });

    // Remove repeat values
    plants = _.uniqBy(plants, "id_location");

    // Verify if not have some plants
    if (_.isEmpty(plants)) {
      addToast({ type: "info", title: t(FilterMessages.noVehiclesLinkedToAPlant) });
      setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, plants: false });
    }

    if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, plants });

    return () => { active = false; };

  },
  // eslint-disable-next-line
    [loadingOptionsFieldsFilter.plants]);

  useEffect(() => {
    let active = true;
    let regionals: Regional[] = [];

    if (!loadingOptionsFieldsFilter.regionals) return undefined;

    // Get regionals in global state store vehiclesStates
    Object.keys(vehiclesStates.all.list).forEach((key) => {
      vehiclesStates.all.list[key].forEach((vehicle) => {
        if (vehicle?.location?.regional) regionals.push(vehicle.location.regional);
      });
    });

    // Remove repeat values
    regionals = _.uniqBy(regionals, "id_regional");

    // Verify if not have some regionals
    if (_.isEmpty(regionals)) {
      addToast({ type: "info", title: t(FilterMessages.noVehiclesLinkedToARegional) });
      setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, regionals: false });
    }

    if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, regionals });

    return () => { active = false; };
  },
  // eslint-disable-next-line
  [loadingOptionsFieldsFilter.regionals]);

  useEffect(() => {
    let active = true;
    let groups: Group[] = [];

    if (!loadingOptionsFieldsFilter.groups) return undefined;

    // Get groups in global state store vehiclesStates
    Object.keys(vehiclesStates.all.list).forEach((key) => {
      vehiclesStates.all.list[key].forEach((vehicle) => {
        if (vehicle?.location?.regional?.group) groups.push(vehicle.location.regional.group);
      });
    });

    // Remove repeat values
    groups = _.uniqBy(groups, "id_group");

    // Verify if not have some groups
    if (_.isEmpty(groups)) {
      addToast({ type: "info", title: t(FilterMessages.noVehiclesLinkedToAGroup) });
      setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, groups: false });
    }

    if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, groups });

    return () => { active = false; };
  },
  // eslint-disable-next-line
    [loadingOptionsFieldsFilter.groups]);

  // Constructions filter options
  useEffect(() => {
    if (!openOptionsFieldsFilter.constructions) setOptionsFieldsFilter({ ...optionsFieldsFilter, constructions: [] });
  },
  // eslint-disable-next-line
    [openOptionsFieldsFilter.constructions]);
  useEffect(() => {

    let active = true;
    let constructions: LocationWithClient[] = [];

    if (!loadingOptionsFieldsFilter.constructions) return undefined;

    // Get constructions in global state store vehiclesStates
    Object.keys(vehiclesStates.all.list).forEach((key) => {
      vehiclesStates.all.list[key].forEach((vehicle) => {
        if (vehicle.current_travel && vehicle.current_travel.destination) {
          constructions.push(
              { ...vehicle.current_travel.destination, client: vehicle.current_travel.client } as LocationWithClient
          );
        }
      });
    });

    // Remove repeat values
    constructions = _.uniqBy(constructions, (v) => [v.county, v.address, v.district, v.number].join());

    // Verify if not have some construction
    if (_.isEmpty(constructions)) {
      addToast({ type: "info", title: t(FilterMessages.noConstruction) });
      setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, constructions: false });
    }

    if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, constructions });

    return () => { active = false; };

  },
  // eslint-disable-next-line
    [loadingOptionsFieldsFilter.constructions, addToast]);

  // Travel docs filter options
  useEffect(() => {
      if (!openOptionsFieldsFilter.travelDocs) setOptionsFieldsFilter({ ...optionsFieldsFilter, travelDocs: [] });
    },
    // eslint-disable-next-line
    [openOptionsFieldsFilter.travelDocs]);
  useEffect(() => {

      let active = true;
      let travelDocs: string[] = [];

      if (!loadingOptionsFieldsFilter.travelDocs) return undefined;

      // Get travel docs in global state store vehiclesStates
      Object.keys(vehiclesStates.all.list).forEach((key) => {
        vehiclesStates.all.list[key].forEach((vehicle) => {
          if (vehicle.current_travel && vehicle.current_travel.num_doc) {
            travelDocs.push(vehicle.current_travel.num_doc);
          }
        });
      });

      // Remove repeat values
      travelDocs = _.uniq(travelDocs);

      // Verify if not have some travel docs
      if (_.isEmpty(travelDocs)) {
        addToast({ type: "info", title: t(FilterMessages.noTravelDocs) });
        setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, travelDocs: false });
      }

      if (active) setOptionsFieldsFilter({ ...optionsFieldsFilter, travelDocs });

      return () => { active = false; };

    },
    // eslint-disable-next-line
    [loadingOptionsFieldsFilter.travelDocs, addToast]);

  // Actions on filter (Apply and Clear)
  const handleApplyFilters = useCallback(() => {

    // Verify if filter not equal a default form no filter
    if (_.isEqual(filtersDashboard, filtersInitialState)) {
      addToast({ type: "info", title: t(FilterMessages.selectSomethingToFilter) });

      return;
    }

    // Verify if we have some type vehicle checked
    if (Object.keys(filtersDashboard.vehiclesTypes).every((k) => !filtersDashboard.vehiclesTypes[k])) {
      addToast({ type: "info", title: t(FilterMessages.selectAtLeastOneVehicleType) });

      return;
    }

    dispatch(FiltersDashboardActions.setFiltersDashboard({ ...filtersDashboard, active: true }));

    const filteredVehiclesTypes: VehiclesStatesObj = { list: {} } as VehiclesStatesObj;
    const filteredVehiclesComplete: VehiclesStatesObj = { loading: true, list: {} as VehicleStatesList };

    // Filter all vehicles according vehicle types checks
    Object.keys(vehiclesStates.all.list).forEach((key) => {

      filteredVehiclesTypes.list[key] = [];

      vehiclesStates.all.list[key].forEach((vehicle) => {

        const optionsVehicleTypes = filtersDashboard.vehiclesTypes;
        const vehicleTypeID = vehicle.type.id_vehicle_type;

        // Type vehicle checks
        if (optionsVehicleTypes.betoneiras && vehicleTypeID === VehicleTypesID.BETONEIRA) {
          filteredVehiclesTypes.list[key].push(vehicle);
        } else if (optionsVehicleTypes.bombas && vehicleTypeID === VehicleTypesID.CAMINHAO_BOMBA) {
          filteredVehiclesTypes.list[key].push(vehicle);
        } else if (optionsVehicleTypes.paCarregadeira && vehicleTypeID === VehicleTypesID.PA_CARREGADEIRA) {
          filteredVehiclesTypes.list[key].push(vehicle);
        } else if (optionsVehicleTypes.veiculoApoio && vehicleTypeID === VehicleTypesID.VEICULO_DE_APOIO) {
          filteredVehiclesTypes.list[key].push(vehicle);
        }

      });
    });

    // Search in filtered type vehicles with another options (Vehicle, Plant, Construction)
    Object.keys(filteredVehiclesTypes.list).forEach((key) => {

      let equalValues: Vehicle[] = [];
      let filters = "";

      /* eslint-disable max-len */
      if (!_.isEmpty(filtersDashboard.vehicles)) {
        filters
          += "filtersDashboard.vehicles.find((o2) => o1.id_vehicle === o2.id_vehicle) &&";
      }
      if (!_.isEmpty(filtersDashboard.plants)) {
        filters
          += "filtersDashboard.plants.find((o3) => o1.location?.id_location === o3.id_location || o1.current_travel?.origin.id_location === o3.id_location) &&";
      }
      if (!_.isEmpty(filtersDashboard.constructions)) {
        filters
          += "filtersDashboard.constructions.find((o4) => o1.current_travel?.destination?.id_location === o4.id_location) &&";
      }
      if (!_.isEmpty(filtersDashboard.regionals)) {
        filters
          += "filtersDashboard.regionals.find((o5) => o1.location?.regional?.id_regional === o5.id_regional ||"
          + " o1.current_travel?.origin?.regional?.id_regional === o5.id_regional) &&";
      }
      if (!_.isEmpty(filtersDashboard.groups)) {
        filters
          += "filtersDashboard.groups.find((o6) => o1.location?.regional?.group?.id_group === o6.id_group ||"
          + " o1.current_travel?.origin?.regional?.group?.id_group === o6.id_group) &&";
      }
      if (!_.isEmpty(filtersDashboard.travelDocs)) {
        filters
          += "filtersDashboard.travelDocs.find((o7) => o1.current_travel?.num_doc === o7) &&";
      }

      filters = filters.slice(0, -2);

      // eslint-disable-next-line no-eval
      equalValues = filteredVehiclesTypes.list[key].filter((o1) => eval(filters));

      // If not selected anything in options vehicles, plants and constructions, show all vehicles of types checked
      if (_.isEmpty(filtersDashboard.vehicles) && _.isEmpty(filtersDashboard.plants) && _.isEmpty(filtersDashboard.constructions)
        && _.isEmpty(filtersDashboard.travelDocs) && _.isEmpty(filtersDashboard.regionals) && _.isEmpty(filtersDashboard.groups)) {
        filteredVehiclesComplete.list[key] = filteredVehiclesTypes.list[key];
      } else filteredVehiclesComplete.list[key] = equalValues;
    });

    const filteredAlerts: AlertsStateObj = { loading: true, list: [] as Alert[] };

    // Filter alerts according to vehicles filtered
    Object.keys(filteredVehiclesComplete.list).forEach((key) => {

      filteredVehiclesComplete.list[key].forEach((vehicle) => {
        const idVehicle = vehicle.id_vehicle;
        const allAlertsList = alerts.all.list;

        allAlertsList.forEach((alert) => {
          if (alert?.vehicle?.id_vehicle === idVehicle) {
            filteredAlerts.list.push(alert);
          }
        });
      });
    });

    filteredVehiclesComplete.loading = false;
    filteredAlerts.loading = false;

    dispatch(VehiclesStatesActions.setVehiclesStatesFiltered(filteredVehiclesComplete));
    dispatch(AlertsActions.setAlertsFiltered(filteredAlerts));

  }, [filtersDashboard, vehiclesStates, dispatch, addToast, alerts.all.list, t]);

  const handleClearFilters = useCallback(() => {

    // Clear filtersTitle and inactive
    dispatch(FiltersDashboardActions.setFiltersDashboard({} as FiltersDashboardState));

  }, [dispatch]);

  // Verify if all vehicle or alert list have changed, if so, with filter active, force filter again
  useEffect(() => {
    if (filtersDashboard.active) handleApplyFilters();
  },
  // eslint-disable-next-line
    [vehiclesStates.all.list, alerts.all.list]);

  return (
    <ContainerPopperFilterVehiclesDashboard id="popperFilterVehiclesDashboard">
      <Popper
        className={classNames(style.popper, "popper")}
        open={open}
        anchorEl={anchorEl}
        container={document.getElementById("popperFilterVehiclesDashboard")}
        placement="bottom-start"
        transition
        modifiers={{
          arrow: {
            enabled: arrow,
            element: arrowRef
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ appear: 350, enter: 350, exit: 0 }}>
            <>
              {arrow ? <span className={style.arrow} ref={setArrowRef} /> : null}
              <Paper className="popperContent">
                <div className="title">{t(FilterMessages.filtersTitle)}</div>
                <Typography component="div">
                  <Form
                    className="form"
                    ref={formRef}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSubmit={() => {}}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12} className="vehiclesTypes">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">{t(FilterMessages.optionVehicleTypes)}</FormLabel>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={filtersDashboard.vehiclesTypes.betoneiras}
                                  onChange={(event) => dispatch(FiltersDashboardActions.setFiltersDashboard({
                                    ...filtersDashboard,
                                    vehiclesTypes: {
                                      ...filtersDashboard.vehiclesTypes,
                                      betoneiras: event.target.checked
                                    }
                                  }))}
                                  name="vehiclesTypes.betoneiras"
                                />
                              )}
                              label={t(FilterMessages.concreteMixers)}
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={filtersDashboard.vehiclesTypes.bombas}
                                  onChange={(event) => dispatch(FiltersDashboardActions.setFiltersDashboard({
                                    ...filtersDashboard,
                                    vehiclesTypes: { ...filtersDashboard.vehiclesTypes, bombas: event.target.checked }
                                  }))}
                                  name="vehiclesTypes.bombas"
                                />
                              )}
                              label={t(FilterMessages.pumpTrucks)}
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={filtersDashboard.vehiclesTypes.veiculoApoio}
                                  onChange={(event) => dispatch(FiltersDashboardActions.setFiltersDashboard({
                                    ...filtersDashboard,
                                    vehiclesTypes: {
                                      ...filtersDashboard.vehiclesTypes,
                                      veiculoApoio: event.target.checked
                                    }
                                  }))}
                                  name="vehiclesTypes.veiculoApoio"
                                />
                              )}
                              label={t(FilterMessages.supportVehicles)}
                            />
                            <FormControlLabel
                              className="last"
                              control={(
                                <Checkbox
                                  checked={filtersDashboard.vehiclesTypes.paCarregadeira}
                                  onChange={(event) => dispatch(FiltersDashboardActions.setFiltersDashboard({
                                    ...filtersDashboard,
                                    vehiclesTypes: {
                                      ...filtersDashboard.vehiclesTypes,
                                      paCarregadeira: event.target.checked
                                    }
                                  }))}
                                  name="vehiclesTypes.paCarregadeira"
                                />
                              )}
                              label={t(FilterMessages.loaders)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(vehicle: Vehicle) => `${vehicle.code} - ${vehicle.license_plate}`}
                              keysFormat={(vehicle: Vehicle) => vehicle.id_vehicle}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={_.uniqBy(optionsFieldsFilter.vehicles, "code")
                            .sort((a, b) => -b.type.description.localeCompare(a.type.description)
                              || -b.code.localeCompare(a.code))}
                          getOptionSelected={(option, value) => option.code === value.code}
                          getOptionLabel={(option) => `${option.code} - ${option.license_plate}`}
                          groupBy={(option) => t(VehicleTypesMessages[`${option.type.id_vehicle_type}-plural`])}
                          loading={loadingOptionsFieldsFilter.vehicles}
                          defaultValue={filtersDashboard.vehicles}
                          value={filtersDashboard.vehicles}
                          onOpen={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, vehicles: true })}
                          onClose={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, vehicles: false })}
                          onChange={(event, value) => dispatch(
                            FiltersDashboardActions.setFiltersDashboard({ ...filtersDashboard, vehicles: value })
                          )}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option.code} - {option.license_plate}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(FilterMessages.optionVehicles)}
                              InputLabelProps={{ shrink: !_.isEmpty(filtersDashboard.vehicles) ? true : undefined }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.vehicles ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    )
                                      : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(location: LocationWithClient) => (
                                `${location.client ? location.client.toUpperCase() : t(GlobalMessages.noRegisteredClients)} -
                                  ${location.county.toUpperCase()} - ${location.address} - ${location.district},&nbsp;
                                  ${location.number || " S/N"}&nbsp; | &nbsp;`
                              )}
                              keysFormat={(location: LocationWithClient) => location.id_location}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={optionsFieldsFilter.constructions.sort(
                            (a, b) => -b.county.localeCompare(a.county)
                          )}
                          getOptionSelected={(option, value) => option.id_location === value.id_location}
                          getOptionLabel={(option) => (
                            `${option.client ? option.client.toUpperCase() : t(GlobalMessages.noRegisteredClients)} -
                              ${option.county.toUpperCase()} - ${option.address} - ${option.district},&nbsp;
                              ${option.number || " S/N"}&nbsp; | &nbsp;`
                          )}
                          loading={loadingOptionsFieldsFilter.constructions}
                          defaultValue={filtersDashboard.constructions}
                          value={filtersDashboard.constructions}
                          onOpen={() => setOpenOptionsFieldsFilter(
                            { ...openOptionsFieldsFilter, constructions: true }
                          )}
                          onClose={() => setOpenOptionsFieldsFilter(
                            { ...openOptionsFieldsFilter, constructions: false }
                          )}
                          onChange={(event, value) => {
                            dispatch(FiltersDashboardActions.setFiltersDashboard(
                              { ...filtersDashboard, constructions: value }
                            ));
                          }}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option.client ? option.client.toUpperCase() : t(GlobalMessages.noRegisteredClients)}
                              <br />
                              {option.county.toUpperCase()} - {option.address} - {option.district},&nbsp;
                              {option.number || " S/N"}&nbsp; | &nbsp;
                              {option.name ? option.name.toUpperCase() : t(GlobalMessages.noRegisteredClients)}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(FilterMessages.optionConstructions)}
                              InputLabelProps={{
                                shrink: !_.isEmpty(filtersDashboard.constructions) ? true : undefined
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.constructions ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(numDoc: string) => numDoc}
                              keysFormat={(numDoc: string) => numDoc}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={optionsFieldsFilter.travelDocs.sort()}
                          getOptionSelected={(option, value) => option === value}
                          getOptionLabel={(option) => (option)}
                          loading={loadingOptionsFieldsFilter.travelDocs}
                          defaultValue={filtersDashboard.travelDocs}
                          value={filtersDashboard.travelDocs}
                          onOpen={() => setOpenOptionsFieldsFilter(
                            { ...openOptionsFieldsFilter, travelDocs: true }
                          )}
                          onClose={() => setOpenOptionsFieldsFilter(
                            { ...openOptionsFieldsFilter, travelDocs: false }
                          )}
                          onChange={(event, value) => {
                            dispatch(FiltersDashboardActions.setFiltersDashboard(
                              { ...filtersDashboard, travelDocs: value }
                            ));
                          }}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(FilterMessages.optionTravelDocs)}
                              InputLabelProps={{
                                shrink: !_.isEmpty(filtersDashboard.travelDocs) ? true : undefined
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.travelDocs ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(location: Location) => `${location.name} - ${location.county}`}
                              keysFormat={(location: Location) => location.id_location}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={optionsFieldsFilter.plants.sort((a, b) => -b.name.localeCompare(a.name))}
                          getOptionSelected={(option, value) => option.id_location === value.id_location}
                          getOptionLabel={(option) => `${option.name} - ${option.county}`}
                          loading={loadingOptionsFieldsFilter.plants}
                          defaultValue={filtersDashboard.plants}
                          value={filtersDashboard.plants}
                          onOpen={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, plants: true })}
                          onClose={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, plants: false })}
                          onChange={(event, value) => dispatch(
                            FiltersDashboardActions.setFiltersDashboard({ ...filtersDashboard, plants: value })
                          )}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option.name} - {option.county}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(FilterMessages.optionPlants)}
                              InputLabelProps={{ shrink: !_.isEmpty(filtersDashboard.plants) ? true : undefined }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.plants ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    )
                                      : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(regional: Regional) => `${regional.code} - ${regional.name}`}
                              keysFormat={(regional: Regional) => regional.id_regional}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={optionsFieldsFilter.regionals}
                          getOptionSelected={(option, value) => option.id_regional === value.id_regional}
                          getOptionLabel={(option) => `${option.code} - ${option.name}`}
                          loading={loadingOptionsFieldsFilter.regionals}
                          defaultValue={filtersDashboard.regionals}
                          value={filtersDashboard.regionals}
                          onOpen={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, regionals: true })}
                          onClose={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, regionals: false })}
                          onChange={(event, value) => dispatch(
                            FiltersDashboardActions.setFiltersDashboard({ ...filtersDashboard, regionals: value })
                          )}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option.code} - {option.name}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(MenuMessages.regionals)}
                              InputLabelProps={{ shrink: !_.isEmpty(filtersDashboard.regionals) ? true : undefined }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.regionals ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Autocomplete
                          multiple
                          fullWidth
                          renderTags={(value, getTagProps) => (
                            <LimitedChipsField
                              data={value}
                              limit={1}
                              getTagProps={getTagProps}
                              labelsFormat={(group: Group) => `${group.code} - ${group.name}`}
                              keysFormat={(group: Group) => group.id_group}
                            />
                          )}
                          limitTags={1}
                          disableCloseOnSelect
                          options={optionsFieldsFilter.groups}
                          getOptionSelected={(option, value) => option.id_group === value.id_group}
                          getOptionLabel={(option) => `${option.code} - ${option.name}`}
                          loading={loadingOptionsFieldsFilter.groups}
                          defaultValue={filtersDashboard.groups}
                          value={filtersDashboard.groups}
                          onOpen={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, groups: true })}
                          onClose={() => setOpenOptionsFieldsFilter({ ...openOptionsFieldsFilter, groups: false })}
                          onChange={(event, value) => dispatch(
                            FiltersDashboardActions.setFiltersDashboard({ ...filtersDashboard, groups: value })
                          )}
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                name="undefined"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={selected}
                              />
                              {option.code} - {option.name}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="undefined"
                              rowsMax={1}
                              multiline
                              variant="outlined"
                              label={t(MenuMessages.groups)}
                              InputLabelProps={{ shrink: !_.isEmpty(filtersDashboard.groups) ? true : undefined }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptionsFieldsFilter.groups ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <div className="actions">
                      <Button
                        disableRipple
                        className="clearFilterButton"
                        onClick={() => handleClearFilters()}
                      >
                        {t(FilterMessages.clearFilters)}
                      </Button>
                      <ButtonLoading
                        className="applyFilterButton"
                        type="submit"
                        onClick={() => {
                          handleApplyFilters();
                          closePopper();
                        }}
                      >
                        {t(FilterMessages.applyFilters)}
                      </ButtonLoading>
                    </div>
                  </Form>
                </Typography>
              </Paper>
            </>
          </Fade>
        )}
      </Popper>
    </ContainerPopperFilterVehiclesDashboard>
  );
};

export default PopperFilterVehiclesDashboard;
