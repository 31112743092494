/* eslint-disable camelcase, max-classes-per-file */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinTable,
  ManyToMany,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { Exclude } from "class-transformer";
import { Token } from "./reg_tokens.entity";
import { Alert } from "./reg_alerts.entity";
import { Plant } from "./reg_plants.entity";
import { Role } from "./reg_roles.entity";
import { UserClaim } from "./reg_user_claims.entity";
import { ConfigCommand } from "./reg_config_commands.entity";
import { TpeAlert } from "./tpe_alerts_types.entity";
import { RegSurvey } from "./telemetry/reg_surveys.entity";
import { Param } from "./reg_params.entity";
import { SentAlerts } from "./rel_users_sent_alerts.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { Filter } from "./reg_filters.entity";
import { PumpProgrammingHistory } from "./hty_pump_programmings.entity";
import { PumpProgrammingImport } from "./reg_pump_programming_imports.entity";

@Entity("reg_users")
export class User {

  @PrimaryGeneratedColumn("uuid")
    id_user?: string;

  @Column({ type: "varchar", nullable: false, length: 200 })
    name?: string;

  @Column({ type: "varchar", nullable: false, length: 150, unique: true })
    email?: string;

  @Exclude()
  @Column({ type: "varchar", nullable: true })
    password?: string;

  @Column({ type: "varchar", nullable: true })
    salt?: string;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    admin?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    super_admin?: boolean;

  @Column({ type: "timestamp", nullable: true })
    last_access?: Date;

  @Column({ type: "timestamp", nullable: true })
    activation_date?: Date;

  @Column({ type: "boolean", nullable: false, default: false })
    allowed_vehicle_block?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    receive_daily_report?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    receive_weekly_report?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    allowed_change_params?: boolean;

  @Column({ type: "boolean", nullable: false, default: true })
    allowed_change_vehicles?: boolean;

  @Column({ type: "boolean", nullable: false, default: true })
    allowed_change_drivers?: boolean;

  @Column({ type: "boolean", nullable: false, default: true })
    allowed_change_locations?: boolean;

  @Column({ type: "varchar", nullable: true })
    telegram_id?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Token, (tokens) => tokens.user)
    tokens?: Token[];

  @OneToMany(() => Alert, (alerts) => alerts.user)
    alerts?: Alert[];

  @OneToMany(() => Filter, (filter) => filter.user)
    filters?: Filter[];

  @OneToMany(() => PumpProgrammingHistory, (pumpProgrammingHistory) => pumpProgrammingHistory.user)
    pumpProgrammingHistoryList?: PumpProgrammingHistory[];

  @OneToMany(() => PumpProgrammingImport, (pumpProgrammingImport) => pumpProgrammingImport.user)
    pumpProgrammingImports?: PumpProgrammingImport[];

  @OneToMany(() => UserClaim, (userClaims) => userClaims.user)
    claims?: UserClaim[];

  @OneToMany(
    () => ConfigCommand,
    (userConfigCommands) => userConfigCommands.user
  )
    config_commands?: ConfigCommand[];

  @OneToMany(() => SentAlerts, (sentAlerts) => sentAlerts.user)
    sent_alerts_history?: SentAlerts[];

  @ManyToMany(() => Plant, (plants) => plants.users)
    plants?: Plant[];

  @ManyToMany(() => Role, (roles) => roles.users)
    roles?: Role[];

  @ManyToMany(() => TpeAlert, (tpeAlerts) => tpeAlerts.users)
    alerts_permissions?: TpeAlert[];

  @ManyToMany(() => Alert, (alerts) => alerts.sent_users)
  @JoinTable({
    name: "rel_user_sent_alerts",
    joinColumn: { name: "id_user_fk", referencedColumnName: "id_user" },
    inverseJoinColumn: { name: "id_alert_fk", referencedColumnName: "id_alert" }
  })
    sent_alerts?: Alert[];

  @ManyToMany(() => Vehicle, (vehicle) => vehicle.restricted_users, { nullable: true })
  @JoinTable({
    name: "rel_users_vehicles_restrictions",
    joinColumn: {
      name: "id_user_fk",
      referencedColumnName: "id_user"
    },
    inverseJoinColumn: {
      name: "id_vehicle_fk",
      referencedColumnName: "id_vehicle"
    }
  })
    restricted_vehicles?: Vehicle[];

  // Virtual prop
  current_survey?: RegSurvey;

  // Virtual prop
  params?: Param[];

}

export class UserErrors {

  static readonly EMAIL_ALREADY_REGISTERED = "3.9.1 (TCON333931)";

}
