import styled, { css } from "styled-components";
import MicrosoftLogo from "@assets/icons/microsoft-logo.svg";

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  color: #333;

  ${({ disabled, theme }) => css`

    ${!disabled && `
      :hover {
        opacity: 0.8;
      }
    `}
    ${disabled && `
      opacity: 0.8;
      cursor: auto;
    `}
  `}
`;
const MicrosoftIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-image: url(${MicrosoftLogo});
  background-size: cover;
`;

export { Container, MicrosoftIcon };
