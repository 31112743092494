import styled from "styled-components";

export const Container = styled.div`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .minWidthAddressColumn {
    @media (min-width: 512px) {
      min-width: 380px;
    }
  }
`;
