import React, { LinkHTMLAttributes } from "react";

import { Container } from "./styles";

const ButtonTable: React.FC<LinkHTMLAttributes<HTMLAnchorElement>> = ({ children, ...rest }) => (
  <Container>
    <a {...rest}>{children}</a>
  </Container>
);

export default ButtonTable;
