import { VehicleMaintenanceMessages, TranslationVehicleMaintenanceMessages } from "../interfaces";

export const messages: TranslationVehicleMaintenanceMessages = {

  /* region Backend */
  [VehicleMaintenanceMessages.inMaintenance]: "En mantenimiento",
  [VehicleMaintenanceMessages.outOfMaintenance]: "Fuera de mantenimiento",
  [VehicleMaintenanceMessages.vehicleIsAlready]: "El vehículo ya está",
  [VehicleMaintenanceMessages.cannotGetLocation]: "No se pudo obtener la ubicación del vehículo para generar el"
  + " evento.",
  [VehicleMaintenanceMessages.putInMaintenanceSuccess]: "Vehículo puesto en mantenimiento con éxito.",
  [VehicleMaintenanceMessages.putOutOfMaintenanceSuccess]: "Vehículo retirado del mantenimiento con éxito.",
  [VehicleMaintenanceMessages.changeMaintenanceStatusError]: "Falla al cambiar el estado de mantenimiento del vehículo",
  /* endregion Backend */

  /* region Frontend */
  [VehicleMaintenanceMessages.checkVehicle]: "Verifique el vehículo",
  [VehicleMaintenanceMessages.removeFromMaintenance]: "Retirar del mantenimiento",
  [VehicleMaintenanceMessages.removeFromMaintenanceConfirmation]: "¿Realmente quieres sacar el vehículo del"
  + " mantenimiento?",
  [VehicleMaintenanceMessages.justifyOrSelect]: "Justifique o seleccione ",
  [VehicleMaintenanceMessages.maintenance]: "Mantenimiento",
  [VehicleMaintenanceMessages.selectMaintenance]: "Seleccione el mantenimiento",
  [VehicleMaintenanceMessages.justifyMaintenance]: "Justifique el mantenimiento",
  [VehicleMaintenanceMessages.maintenanceOptions]: "Opciones de mantenimiento",
  [VehicleMaintenanceMessages.maintenanceConfirmation]: "Confirmación de mantenimiento",
  [VehicleMaintenanceMessages.maintenanceConfirmationMessage]: "¿Realmente quieres poner el vehículo en mantenimiento?",
  [VehicleMaintenanceMessages.youAreAboutToPutTheVehicle]: "Estás a punto de poner el vehículo ",
  [VehicleMaintenanceMessages.yourActionWillPutTheVehicleInMaintenance]: "Tu acción pondrá el vehículo en"
  + " mantenimiento.",
  [VehicleMaintenanceMessages.optionHydraulic]: "Hidráulica",
  [VehicleMaintenanceMessages.optionElectrical]: "Eléctrica",
  [VehicleMaintenanceMessages.optionTireService]: "Llantas",
  [VehicleMaintenanceMessages.optionSuspension]: "Muellero",
  [VehicleMaintenanceMessages.optionBodywork]: "Chapa y pintura",
  [VehicleMaintenanceMessages.optionRockBreaker]: "Rompe Piedras",
  [VehicleMaintenanceMessages.optionOthers]: "Otros (justifique)"
  /* endregion Frontend */

};
