export enum SurveyMessages {
  /* region Backend messages */
  createSurveySuccess = "surveysModule.createSurveySuccess",
  createSurveyError = "surveysModule.createSurveyError"
  /* endregion Backend messages */
}

export type TranslationSurveyMessages = {
  [key in SurveyMessages]: string;
}
