/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";
import { LogIntegration } from "./log_integrations.entity";

@Entity("tpe_public_endpoints_types")
export class TpePublicEndpoint {

  @PrimaryGeneratedColumn("uuid")
  id_public_endpoint?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => LogIntegration, (log_integrations) => log_integrations.public_endpoint_type)
  log_integrations?: LogIntegration[];

}
