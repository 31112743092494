import styled from "styled-components";

export const ContainerPopperSpeedingAlerts = styled.div`

  .popper {

    .popperContent {
      min-width: 300px;
      max-width: 400px;
      overflow-y: scroll;
      max-height: 85vh;
      overflow-x: hidden;
      padding: 15px;

      .title {
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 300;
      }
    }
  }
`;
