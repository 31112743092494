/* eslint-disable import/prefer-default-export */
import { TranslationVehicleAndTravelDetailsMessages, VehicleAndTravelDetailsMessages } from "../interfaces";

export const messages: TranslationVehicleAndTravelDetailsMessages = {
  [VehicleAndTravelDetailsMessages.noActiveTravel]: "Nenhuma viagem ativa no veículo!",
  [VehicleAndTravelDetailsMessages.noActiveTravelDescription]: "Necessário viagem ativa para busca de coordenadas",
  [VehicleAndTravelDetailsMessages.noDescriptionRegistered]: "Sem descrição cadastrada",
  [VehicleAndTravelDetailsMessages.construction]: "Obra",
  [VehicleAndTravelDetailsMessages.alertsTitle]: "Alertas",
  [VehicleAndTravelDetailsMessages.noAlertsOnTravel]: "Nenhum alerta referente à viagem",
  [VehicleAndTravelDetailsMessages.currentLoad]: "Carga atual",
  [VehicleAndTravelDetailsMessages.waterAdded]: "Água adicionada",
  [VehicleAndTravelDetailsMessages.litersAdded]: "Litros adicionados",
  [VehicleAndTravelDetailsMessages.noWaterAdded]: "Nenhuma adição de água",
  [VehicleAndTravelDetailsMessages.travelStatus]: "Status da viagem",
  [VehicleAndTravelDetailsMessages.mapPosition]: "Posição no mapa",
  [VehicleAndTravelDetailsMessages.allocation]: "Alocação",
  [VehicleAndTravelDetailsMessages.lockVehicle]: "Bloquear veículo",
  [VehicleAndTravelDetailsMessages.unlockVehicle]: "Desbloquear veículo",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsSuccess]: "Adições de água retornadas com sucesso",
  [VehicleAndTravelDetailsMessages.noWaterAdditionsFound]: "Nenhuma adição de água encontrada",
  [VehicleAndTravelDetailsMessages.readWaterAdditionsError]: "Falha ao buscar adições de água",
  [VehicleAndTravelDetailsMessages.waterAdditionPostDischarge]: "Pós-descarga",
  [VehicleAndTravelDetailsMessages.waterAdditionPreDischarge]: "Pré-descarga"
};
