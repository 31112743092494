import { Reducer } from "redux";
import { ParamsState, ParamsTypes } from "./params.type";

const INITIAL_STATE: ParamsState = {
  googleApiKey: ""
};

const reducer: Reducer<ParamsState> = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case ParamsTypes.SET_PARAMS:
      return { ...INITIAL_STATE, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
