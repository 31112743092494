import styled from "styled-components";

interface LoadingContainerProps {
  height?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  svg {
    display: block;
  }

  .timelineText {
    color: black;
    font-size: 10px;
    line-height: 1rem;

    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
    }
  }

  .spanContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 18px;
  }

  .summaryItems {
    color: white;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-overflow: ellipsis;
  }

  .customHeader {
    height: 100%;

    color: #666666;
    font-size: 14px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    &.solidLine {
      border-left: 1px solid #bbb;
    }

    &.dashedLine {
      border-left: 1px dashed #ddd;
    }
  }

  .customVerticalLine {

    border-left: 1px dashed #d9d9d9;

    height: 100%;
  }

  @media (max-width: 900px) {

    .customHeader {
      font-size: 10px;
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;

  padding: 0 10px 80px 20px;

  overflow-y: scroll;

  @media (max-width: 900px) {
    // Mobile
    width: 30%;
  }
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => !props.height && "padding: 24px 0;"}
`;

export const ChartContainer = styled.div`
  width: 75%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 80px;

  @media (max-width: 900px) {
    // Mobile
    width: 65%;
  }
`;

export const InfoLabel = styled.div<{ stateColor: string }>`
  .container {
    padding: 6px 18px 6px 8px;
    position: absolute;
    width: 120px;
    border-radius: 5px;
    background-color: ${(props) => props.stateColor};
    z-index: 90;
  }

  .itemIndicator {
    svg {
      fill: ${(props) => props.stateColor};
      position: absolute;
      z-index: 90
    }
  }
`;

export const BombHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 50;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 102%;
  margin: 0 -1%;
  margin-bottom: 19px;
  font-weight: 400;
  line-height: 24px;
  font-size: 20px;
  color: #666666;

  div {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .form {
    display: flex;
    align-items: center;
  }

  .dayContainer {
    span {
      color: #000000;
      font-weight: 500;
    }
  }

  .dateInput {
    input {
      width: 115px;
      font-size: 16px;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
  }

  .bombCountContainer {
    color: #000000;
    font-weight: 500;
  }

  @media (max-width: 900px) {
    // Mobile
    line-height: 22.5px;
    font-size: 16px;
    justify-content: space-around;

    margin-bottom: 24px;

    div {
      gap: 2px;
    }

    svg {
      width: 24px;
    }

    .dateInput {
      input {
        font-size: 12px;
        font-weight: bold;
        width: 108px;
      }
    }
  }

`;
