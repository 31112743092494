import { TranslationZonesValidityMessages, ZonesValidityMessages } from "../interfaces";

export const messages: TranslationZonesValidityMessages = {
  [ZonesValidityMessages.columnStartTime]: "Hora de Início",
  [ZonesValidityMessages.columnEndTime]: "Hora de Término",
  [ZonesValidityMessages.columnLicensePlateEnd]: "Final da Placa do Veículo",
  [ZonesValidityMessages.columnDayOfWeek]: "Dia da Semana",

  [ZonesValidityMessages.sunday]: "Domingo",
  [ZonesValidityMessages.monday]: "Segunda",
  [ZonesValidityMessages.tuesday]: "Terça",
  [ZonesValidityMessages.wednesday]: "Quarta",
  [ZonesValidityMessages.thursday]: "Quinta",
  [ZonesValidityMessages.friday]: "Sexta",
  [ZonesValidityMessages.saturday]: "Sábado",
  [ZonesValidityMessages.zoneRuleEdit]: "Editar",
  [ZonesValidityMessages.zoneRuleTitle]: "Regras da zona",
  [ZonesValidityMessages.newZoneRule]: "Nova Regra",
  [ZonesValidityMessages.zoneRuleDeleteConfirmationText]: "Tem certeza que deseja excluir a regra?",
  [ZonesValidityMessages.zoneRuleDeleteConfirmation]: "Confirmação de Exclusão",


  [ZonesValidityMessages.readSuccess]: "Regras da zona lidas com sucesso",
  [ZonesValidityMessages.readError]: "Erro ao ler regras da zona",
  [ZonesValidityMessages.updateSuccess]: "Regras da zona atualizadas com sucesso",
  [ZonesValidityMessages.updateError]: "Não foi possível atualizar a regra",
  [ZonesValidityMessages.createSuccess]: "Regras da zona criadas com sucesso",
  [ZonesValidityMessages.createError]: "Erro ao criar regras da zona",
  [ZonesValidityMessages.noZonesFound]: "Nenhuma regra da zona encontrada",
  [ZonesValidityMessages.deleteSuccess]: "Regras da zona excluídas com sucesso",
  [ZonesValidityMessages.deleteError]: "Erro ao excluir regras da zona",

}
