import { AverageSpeedMessages, TranslationAverageSpeedMessages } from "../interfaces"

export const messages: TranslationAverageSpeedMessages = {
  [AverageSpeedMessages.averageSpeedHistoryTitle]: "Historial de Velocidad",
  [AverageSpeedMessages.averageSpeedByDriver]: "Velocidad Promedio por Conductor",
  [AverageSpeedMessages.averageSpeedByVehicle]: "Velocidad Promedio por Vehículo",
  [AverageSpeedMessages.startDate]: "Fecha de Inicio",
  [AverageSpeedMessages.finishDate]: "Fecha de Fin",
  [AverageSpeedMessages.distance]: "Distancia",
  [AverageSpeedMessages.averageSpeedColumn]: "Velocidad Promedio",
  [AverageSpeedMessages.Vehicles]: "Vehículo",
  [AverageSpeedMessages.Drivers]: "Conductor",
  [AverageSpeedMessages.VehicleType]: "vehículos del tipo",

  [AverageSpeedMessages.noAverageFound]: "No se encontró velocidad promedio",
  [AverageSpeedMessages.readAverageSuccess]: "Velocidad promedio leída con éxito",
  [AverageSpeedMessages.readAverageError]: "Error al leer la velocidad promedio"
}
