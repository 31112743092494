import React from "react";

// region Libraries
import { Tab, Tabs } from "@material-ui/core";
// endregion
// region Languages
import useTranslation from "src/translations/useTranslation";
import { MenuMessages } from "@shared/languages/interfaces";
// endregion
// region Components
import Header from "@components/Header";
import TabPanel from "@components/TabPanel";
// endregion
// region Hooks
import { useSelector } from "react-redux";
import usePersistedState from "@hooks/usePersistedState";
// endregion
// region Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion
// region Pages
import Zones from "@pages/Modules/Locations/Zones";
import LocationData from "@pages/Modules/Locations/LocationData";
// endregion
// region Styles
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { Container } from "./styles";
// endregion

const LocationMain: React.FC = () => {

  // region States
  const [selectedTab, setSelectedTab] = React.useState(0);
  // endregion
  // region Hooks
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  // endregion
  // region Methods
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChangeTab = (_event:React.ChangeEvent<{}>, newValue: number) => setSelectedTab(newValue);
  // endregion

  return (
    <>
      <Header title={t(MenuMessages.locations)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          className="tabContainer"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          <Tab label={t(MenuMessages.locations)} disableRipple />
          <Tab label={t(MenuMessages.zones)} disableRipple />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <LocationData />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Zones />
        </TabPanel>
      </Container>
    </>
  );
};

export default LocationMain;
