export enum ToastMessages {
  success = "toast.success",
  error = "toast.error",
  alert = "toast.alert",
  connectionNotEstablished = "toast.connectionNotEstablished",
}

export type TranslationToastMessages = {
  [key in ToastMessages]: string;
}
