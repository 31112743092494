import { SIMcardsModuleMessages, TranslationSIMCardsModuleMessages } from "../interfaces";

export const messages: TranslationSIMCardsModuleMessages = {

  /* region Backend messages */
  [SIMcardsModuleMessages.createSIMCardSuccess]: "SIM card cadastrado com sucesso",
  [SIMcardsModuleMessages.createSIMCardError]: "Falha ao cadastrar SIM card",
  [SIMcardsModuleMessages.readSIMCardSuccess]: "SIM card retornado com sucesso",
  [SIMcardsModuleMessages.noSIMCardFound]: "Nenhum SIM card encontrado",
  [SIMcardsModuleMessages.readSIMCardError]: "Falha ao buscar SIM card",
  [SIMcardsModuleMessages.updateSIMCardSuccess]: "SIM card atualizado com sucesso",
  [SIMcardsModuleMessages.SIMCardNotFound]: "SIM card não encontrado",
  [SIMcardsModuleMessages.updateSIMCardError]: "Falha ao atualizar SIM card",
  [SIMcardsModuleMessages.deleteSIMCardSuccess]: "SIM card excluído com sucesso",
  [SIMcardsModuleMessages.deleteSIMCardError]: "Falha ao excluir SIM card",
  /* endregion Backend messages */
  /* region Frontend messages */
  [SIMcardsModuleMessages.company]: "Empresa",
  [SIMcardsModuleMessages.status]: "Status",
  [SIMcardsModuleMessages.active]: "Ativo",
  [SIMcardsModuleMessages.inactive]: "Inativo",
  [SIMcardsModuleMessages.deleteConfirmationTitle]: "Confirmação de exclusão",
  [SIMcardsModuleMessages.deleteConfirmationMessage]: "Tem certeza que deseja excluir este SIM card?",
  [SIMcardsModuleMessages.SIMCardRegistration]: "Cadastro de SIM card",
  [SIMcardsModuleMessages.activeSIMCard]: "SIM card ativo",
  [SIMcardsModuleMessages.plan]: "Plano",
  [SIMcardsModuleMessages.purchaseDate]: "Data de aquisição",
  [SIMcardsModuleMessages.activationDate]: "Data de ativação"
  /* endregion Frontend messages */
};
