import { AutocompleteGetTagProps } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import React from "react";
import { ChipsContainer } from "@atoms/LimitedChipsField/styles";
import { useSelector } from "react-redux";
import { windowWidth } from "@styles/global";

interface LimitedChipsFieldProps {
  data: any[];
  getTagProps: AutocompleteGetTagProps;
  /*
  * @description Function returning a string using a element of data array to use as label of the chips.
  */
  labelsFormat: (item: any) => string;
  /*
   * @description Function returning a string using a element of data array to use as key in a map method.
   */
  keysFormat: (item: any) => string;
  limit?: number;
}

const LimitedChipsField: React.FC<LimitedChipsFieldProps> = ({ data, getTagProps, labelsFormat, keysFormat, limit = 1 }) => {

  const screen = useSelector(({ screen: state }) => state);
  const activeLimit = (screen.width ?? 0) >= Number(windowWidth.mobile.large.slice(0, -2)) ? limit : 1;

  return (
    <ChipsContainer>
      {
        data.slice(0, activeLimit).map((item, index) => (
          <Chip
            {...getTagProps({ index })}
            className="chip"
            label={labelsFormat(item)}
            key={keysFormat(item)}
          />
        ))
      }
      {
        data.length > activeLimit && (
          <span className="hiddenChipsCount">+{ data.length - activeLimit }</span>
        )
      }
    </ChipsContainer>
  );
};

LimitedChipsField.defaultProps = {
  limit: 1
};

export default LimitedChipsField;
