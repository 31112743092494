import { ScheduledReportMessages, TranslationScheduledReportMessages } from "../interfaces";

export const messages: TranslationScheduledReportMessages = {
  [ScheduledReportMessages.columnDescription]: "Descripción",
  [ScheduledReportMessages.columnStatus]: "Estado",
  [ScheduledReportMessages.columnRegistrationDate]: "Fecha de registro",
  [ScheduledReportMessages.columnProcessingDate]: "Fecha de procesamiento",
  [ScheduledReportMessages.columnReportType]: "Tipo",
  [ScheduledReportMessages.columnFileSize]: "Tamaño del archivo",

  [ScheduledReportMessages.statusInQueue]: "En cola",
  [ScheduledReportMessages.statusProcessing]: "Procesando...",
  [ScheduledReportMessages.statusProcessed]: "Procesado",
  [ScheduledReportMessages.statusError]: "Error",
  [ScheduledReportMessages.statusCanceled]: "Cancelado",

  [ScheduledReportMessages.emptyReport]: "Informe vacío",

  [ScheduledReportMessages.modalDescriptionTitle]: "Las opciones de filtro seleccionadas resultarían en un informe muy grande.",
  [ScheduledReportMessages.modalDescriptionTitleQuestion]: "¿Desea crear un informe programado para esto?",
  [ScheduledReportMessages.modalScheduleReportName]: "Nombre del informe programado",
  [ScheduledReportMessages.modalButtonCancel]: "Cancelar",
  [ScheduledReportMessages.modalButtonClose]: "Cerrar",
  [ScheduledReportMessages.modalButtonCreate]: "Crear",

  [ScheduledReportMessages.filterDetailsTitle]: "Filtro del informe: ",

  [ScheduledReportMessages.readSuccess]: "Informe programado leído con éxito",
  [ScheduledReportMessages.readError]: "No se pudo leer el informe programado",
  [ScheduledReportMessages.noScheduledReports]: "No se encontraron informes programados",
  [ScheduledReportMessages.updateSuccess]: "Informe programado actualizado con éxito",
  [ScheduledReportMessages.updateError]: "No se pudo actualizar el informe programado",
  [ScheduledReportMessages.createSuccess]: "Informe programado creado con éxito",
  [ScheduledReportMessages.createError]: "No se pudo crear el informe programado",
  [ScheduledReportMessages.cancelSuccess]: "Informe programado cancelado con éxito",
  [ScheduledReportMessages.cancelError]: "No se pudo cancelar el informe programado",
  [ScheduledReportMessages.scheduledReportNotFound]: "Informe programado no encontrado",
  [ScheduledReportMessages.renewLinkSuccess]: "Enlace de informe programado renovado con éxito",
  [ScheduledReportMessages.renewLinkError]: "No se pudo renovar el enlace del informe programado"
};
