import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { socket } from "@services/websocketContext";

import { VehiclesStatesActions } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.action";
import { AlertsActions } from "@store/ducks/Alerts/alerts.action";

import { StandardReturnObject } from "@utils/useful-functions";
import {
  AggregateTrucksStatesActions
} from "@store/ducks/Vehicles/AggregateTrucksStates/aggregate-trucks-states.action";

const DataFetcher: React.FC = () => {

  const dispatch = useDispatch();

  useEffect(() => {

    // Listen to alert data event from server and update the global states alerts
    socket.on("alertData", (data: StandardReturnObject<any>) => {
      dispatch(AlertsActions.setAlerts({ all: { list: data.result, loading: false } }));
    });

    // Listen to vehicle data event from server and update the global states (production vehicles and aggregate trucks)
    socket.on("vehicleData", (data: StandardReturnObject<any>) => {
      dispatch(VehiclesStatesActions.setVehiclesStatesAll({ list: data.result.productionVehiclesData, loading: false }));

      dispatch(AggregateTrucksStatesActions.setAggregateTrucksStatesAll({ list: data.result.aggregateTrucksData, loading: false }));
    });

    // Unsubscribe from events when component unmounts
    return () => {
      socket.off("alertData");
      socket.off("vehicleData");
    };
  }, [dispatch]);

  return (<></>);
};

export { DataFetcher };
