/* eslint-disable camelcase */
/* eslint-disable no-shadow */

// Action types
export enum ScreenTypes {
  SET_SCREEN_SIZE = "@screen/SET_SCREEN_SIZE",
}

export enum ScreenSize {
  small,
  medium,
  large
}

export enum ScreenPlatform {
  DESKTOP = "desktop",
  MOBILE = "mobile"
}

// State type
export interface ScreenState {
  width?: number,
  height?: number,
  size?: ScreenSize,
  platform?: "desktop" | "mobile"
}
