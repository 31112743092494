import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Regional } from "@shared/entities/reg_regionals.entity";
import { Group } from "@shared/entities/reg_groups.entity";
import { Plant } from "@shared/entities/reg_plants.entity";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
import { ParamNameTypes } from "@shared/constants/param-name-types.enum";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";
import { VehicleAnalyticReport as IVehicleAnalyticReport } from "@shared/interfaces/vehicle-analytic-report.interface";
// endregion Imports - Organisms

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    returnedData: IVehicleAnalyticReport[],
  ) => void;
}
// endregion Interfaces

const QueryFilterVehicleAnalyticReport: React.FC<IProps> = ({ open, onClose, onFilter }) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region useState
  const [optionsVehicleTypeFilter, setOptionsVehicleTypeFilter] = useState<VehicleType[]>([] as Array<VehicleType>);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsPlantFilter, setOptionsPlantFilter] = useState<Plant[]>([] as Array<Plant>);
  const [optionsRegionalFilter, setOptionsRegionalFilter] = useState<Regional[]>([] as Array<Regional>);
  const [optionsGroupFilter, setOptionsGroupFilter] = useState<Group[]>([] as Array<Group>);

  const [loadingVehicleTypeFilter, setLoadingVehicleTypeFilter] = useState(true);
  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingPlantFilter, setLoadingPlantFilter] = useState(true);
  const [loadingRegionalFilter, setLoadingRegionalFilter] = useState(true);
  const [loadingGroupFilter, setLoadingGroupFilter] = useState(true);

  const [loading, setLoading] = useState(false);
  // endregion useState
  // region Constants
  const paramNameVehicleType = ParamNameTypes.ID_VEHICLE_TYPES;
  const paramNameVehicle = ParamNameTypes.ID_VEHICLES;
  const paramNamePlant = ParamNameTypes.ID_PLANTS;
  const paramNameGroups = ParamNameTypes.ID_GROUPS;
  const paramNameRegional = ParamNameTypes.ID_REGIONALS;

  const vehicleTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleTypeFilter,
    labelName: t(FilterMessages.optionVehicleTypes),
    paramName: paramNameVehicleType,
    values: optionsVehicleTypeFilter.map((vehicleType) => ({ value: vehicleType.id_vehicle_type!, label: vehicleType.description }))
  }), [paramNameVehicleType, loadingVehicleTypeFilter, optionsVehicleTypeFilter, t]);

  const plantFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingPlantFilter,
    labelName: t(FilterMessages.optionPlants),
    paramName: paramNamePlant,
    values: optionsPlantFilter.map(
      (plant) => ({
        value: plant!.location!.id_location!,
        label: plant.name!
      })
    )
  }), [paramNamePlant, loadingPlantFilter, optionsPlantFilter, t]);

  const regionalFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingRegionalFilter,
    labelName: t(FilterMessages.optionRegionals),
    paramName: paramNameRegional,
    values: optionsRegionalFilter.map(
      (regional) => ({
        value: regional.id_regional!,
        label: `${regional.code} - ${regional.name}`
      })
    )
  }), [paramNameRegional, loadingRegionalFilter, optionsRegionalFilter, t]);

  const groupFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingGroupFilter,
    labelName: t(FilterMessages.optionGroups),
    paramName: paramNameGroups,
    values: optionsGroupFilter.map(
      (group) => ({
        value: group.id_group!,
        label: `${group.code} - ${group.name}`
      })
    )
  }), [paramNameGroups, loadingGroupFilter, optionsGroupFilter, t]);

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [paramNameVehicle, loadingVehicleFilter, optionsVehicleFilter, t]);

  const initialParamsFilter: IParamQueryValues[] = [];
  // endregion Constants
  // region Refs
  const paramsFilterRef = useRef<IParamQueryValues[]>(initialParamsFilter);
  // endregion Refs
  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get(
        "vehicles/read",
        { params: { linkedHardware: true, onlyLabel: true } }
      );

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  /**
   * Load plant options from the API.
   */
  const loadPlantOptions = async () => {
    try {

      // Get all plants
      const { data } = await api.get("plants/read", { params: { linkedLocation: true } });

      if (data.status === "success") setOptionsPlantFilter(data.result);
      else setOptionsPlantFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingPlantFilter(false);
    }
  };

  /**
   * Load regional options from the API.
   */
  const loadRegionalOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setOptionsRegionalFilter(data.result as Regional[]);
      else setOptionsRegionalFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingRegionalFilter(false);
    }
  };

  /**
   * Load group options from the API.
   */
  const loadGroupOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("groups/read");

      if (data.status === "success") setOptionsGroupFilter(data.result as Group[]);
      else setOptionsGroupFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingGroupFilter(false);
    }
  };

  /**
   * Load vehicle type options from the API.
   */
  const loadVehicleTypeOptions = async () => {
    try {

      // Get all alert types
      const { data } = await api.get("vehicles/types/read", { params: { translate: true } });

      if (data.status === "success") {
        setOptionsVehicleTypeFilter(data.result);

        return;
      }

      setOptionsVehicleTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleTypeFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[]) => {

    setLoading(true);

    paramsFilterRef.current = paramsData;

    try {

      const mappedParams = paramsData.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("vehicles/read/analytic-report", mappedParams);

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      onFilter(
        validParamsData.length,
        data.result ?? [] as IVehicleAnalyticReport[]
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoading(false);
    }
  };
  // endregion Functions
  // region Effects

  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions(),
      loadPlantOptions(),
      loadRegionalOptions(),
      loadGroupOptions(),
      loadVehicleTypeOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFilter(paramsFilterRef.current).then();

    // eslint-disable-next-line
  }, [t]);
  // endregion Effects

  return (
    <>
      <Loading loading={loading} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.VEHICLE_ANALYTIC_REPORT}
        onFilter={(paramsData) => handleFilter(paramsData)}
        multipleSelectionOptions={[
          vehicleFilterOptions,
          vehicleTypeFilterOptions,
          plantFilterOptions,
          regionalFilterOptions,
          groupFilterOptions
        ]}
      />
    </>
  );
};

export default QueryFilterVehicleAnalyticReport;
