import React, { useCallback, useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";

// region Imports - Libraries
import * as dateFns from "date-fns";
import * as Yup from "yup";
// endregion Imports - Libraries
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import { CircularProgress, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  FilterMessages,
  GlobalMessages,
  HardwareModuleMessages,
  ToastMessages,
  VehicleTypesMessages,
  YupMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { HardwareInstallationHistory } from "@shared/entities/hty_hardware_installations.entity";
import { HardwareType } from "@shared/interfaces/hardware.interface";
// endregion Imports - Shared
// region Imports - Components
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import ButtonLoading from "@components/Button/ButtonLoading";
// endregion Imports - Components
// region Imports - Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Unform
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
// endregion Imports - Unform
// region Imports - Styles
import { Container, ContainerFilters } from "./styles";
// endregion Imports - Styles

interface InstallationHistoryFormData {
  selectedVehicle: Vehicle;
  vehicleDescription: string;
  selectedHardwareType: HardwareType;
  hardwareTypeDescription: string;
  startDate: Date;
  endDate: Date;
}

const InstallationHistoryList: React.FC = () => {

  // region States
  const [vehicles, setVehicles] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [hardwareTypes, setHardwareTypes] = useState<HardwareType[]>([] as Array<HardwareType>);
  const [installationHistoryList, setInstallationHistoryList] = useState<
    HardwareInstallationHistory[]>([] as Array<HardwareInstallationHistory>);

  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);

  const [openTypesHardwareFilter, setOpenTypesHardwareFilter] = useState(false);
  const [optionsTypesHardwareFilter, setOptionsTypesHardwareFilter] = useState<HardwareType[]>([] as HardwareType[]);

  const [startDate, setStartDate] = useState(utils.convertDateToISOWithTimezone(dateFns.subDays(new Date(), 1)));
  const [finishDate, setFinishDate] = useState(utils.convertDateToISOWithTimezone(new Date()));

  const [loadingInstallationHistory, setLoadingInstallationHistory] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  /* endregion States */
  /* region Hooks */
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t, i18n } = useTranslation();
  /* endregion Hooks */
  /* region Forms */
  const formRef = useRef<FormHandles>(null);

  /** Validations form
   */
  const validations = {

    validateForm: async (formData: InstallationHistoryFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define the validation types
        const schema = Yup.object().shape({
          startDate: Yup.date(),
          endDate: Yup.date().min(Yup.ref("startDate"), t(YupMessages.finishDateGreaterThanStartDate))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Return parameters to main Component
        await readInstallationHistory(formData, vehicles, hardwareTypes);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Forms */
  /* region Constants */
  const loadingVehicleFilter = openVehicleFilter && optionsVehicleFilter.length === 0;
  const loadingOptionsTypesHardware = openTypesHardwareFilter && optionsTypesHardwareFilter.length === 0;

  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };

  const dataTableColumns: DataTableColumns[] = [
    { // Vehicle
      title: t(GlobalMessages.vehicle),
      className: "print csv",
      data: (installationHistory: HardwareInstallationHistory) => (installationHistory?.vehicle?.code),
      defaultContent: "",
      filterable: true,
      propertyName: "vehicle.code"
    },
    { // Hardware dev_id
      title: t(HardwareModuleMessages.hardwareDevId),
      className: "print csv",
      data: (installationHistory: HardwareInstallationHistory) => (installationHistory?.hardware?.dev_id),
      defaultContent: "",
      filterable: true,
      propertyName: "hardware.dev_id"
    },
    { // Hardware description type
      title: t(HardwareModuleMessages.hardwareType),
      className: "print csv",
      data: (installationHistory: HardwareInstallationHistory) => (installationHistory?.hardware?.type?.description),
      defaultContent: "",
      filterable: true,
      propertyName: "hardware.type.description"
    },
    { // Occurrence date
      title: t(HardwareModuleMessages.occurrenceDate),
      className: "print csv",
      data: (installationHistory: HardwareInstallationHistory) => (
        installationHistory?.occurrence_date ? utils.formatDateIfHave(installationHistory?.occurrence_date, "fullDate") : ""),
      defaultContent: "",
      filterable: true,
      propertyName: "occurrence_date"
    },
    { // Imported data
      title: t(HardwareModuleMessages.importedData),
      className: "print csv",
      data: (installationHistory: HardwareInstallationHistory) => {

        const importedData = installationHistory?.imported_data;
        const importedDataColor = importedData ? "green" : "red";
        const importedDataText = importedData ? t(GlobalMessages.yes) : t(GlobalMessages.no);

        return `<span style="color:${importedDataColor}">${importedDataText}</span>`;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "imported_data"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print"
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `historico_instalacoes_${startDate}__${finishDate}`
    }
  ];
  /* endregion Constants */

  /* region Functions */
  /** Search installation history according the filters
   * @param formData Data of form filter (Send to request)
   */
  const readInstallationHistory = useCallback(async (
    formData: InstallationHistoryFormData,
    vehicles: Vehicle[],
    hardwareTypes: HardwareType[]
  ) => {

    try {

      setLoadingInstallationHistory(true);

      // Search the selected vehicle
      const selectedVehicle = formData.vehicleDescription.length > 0
        ? vehicles.find((vehicle) => `${vehicle.code} - ${vehicle.description}` === formData.vehicleDescription)
        : undefined;

      // Search the selected hardware type
      const selectedHardwareType = formData.hardwareTypeDescription.length > 0
        ? hardwareTypes.find((hardwareType) => hardwareType.description === formData.hardwareTypeDescription)
        : undefined;

      // Get the installation history
      const { data } = await api.get("hardwares/installation-history/get-filtered-by", {
        params: {
          idVehicle: selectedVehicle?.id_vehicle,
          startDate: utils.convertDateToISOWithTimezone(new Date(formData.startDate)),
          finishDate: utils.convertDateToISOWithTimezone(new Date(formData.endDate)),
          idHardwareType: selectedHardwareType?.id_hardware_type
        }
      });

      // Save date to fill csv file name
      setStartDate(utils.convertDateToISOWithTimezone(new Date(formData.startDate)));
      setFinishDate(utils.convertDateToISOWithTimezone(new Date(formData.endDate)));

      if (data.status === "success") {

        setInstallationHistoryList([...data.result]);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        setInstallationHistoryList([]);
        if (table.length) table.clear().draw();
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingInstallationHistory(false);
    }

  }, [addToast, table, t]);

  /* endregion Functions */
  /* region Effects */

  // Type hardware options filter
  useEffect(() => {
    if (!openTypesHardwareFilter) setOptionsTypesHardwareFilter([]);
  }, [openTypesHardwareFilter]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsTypesHardware) return undefined;

    (async () => {

      try {

        // Get all hardware types
        const { data } = await api.get("hardwares/types/read");

        if (data.status === "success") {
          setHardwareTypes(data.result);
          if (active) setOptionsTypesHardwareFilter(data.result);
        } else {
          setOpenTypesHardwareFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenTypesHardwareFilter(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsTypesHardware, addToast, t]);

  // Vehicle options filter
  useEffect(() => {

    let active = true;

    if (!loadingVehicleFilter) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read", { params: { linkedHardware: true } });

        if (data.status === "success") {

          setVehicles(data.result);
          if (active) setOptionsVehicleFilter(data.result);

        } else {

          setOpenVehicleFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {
        setOpenVehicleFilter(false);
        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingVehicleFilter, addToast, t]);
  useEffect(() => {
    if (!openVehicleFilter) setOptionsVehicleFilter([]);
  }, [openVehicleFilter]);

  // Refresh the installation history list when the language is changed
  useEffect(() => {

    const getSelectedVehicle = () => vehicles.find((vehicle) => `${vehicle.code} - ${vehicle.description}`
        === formRef.current?.getFieldValue("vehicleDescription"));

    const getSelectedHardwareType = () => optionsTypesHardwareFilter.find((hardwareType) => hardwareType
      .description === formRef.current?.getFieldValue("hardwareTypeDescription"));

    const queryParams: InstallationHistoryFormData = {
      // eslint-disable-next-line max-len
      startDate: formRef.current?.getFieldValue("startDate") ?? new Date(dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00")),
      endDate: formRef.current?.getFieldValue("endDate") ?? new Date(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")),
      selectedVehicle: getSelectedVehicle() ?? {} as Vehicle,
      vehicleDescription: formRef.current?.getFieldValue("vehicleDescription") ?? "",
      selectedHardwareType: getSelectedHardwareType() ?? {} as HardwareType,
      hardwareTypeDescription: formRef.current?.getFieldValue("hardwareTypeDescription") ?? ""
    };
    const vehiclesForQuery = vehicles.length > 0 ? vehicles : [];
    const hardwareTypesForQuery = optionsTypesHardwareFilter.length > 0 ? optionsTypesHardwareFilter : [];

    readInstallationHistory(queryParams, vehiclesForQuery, hardwareTypesForQuery).then();
  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  /* endregion Effects */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingInstallationHistory} />
        <ContainerFilters>
          <Form className="form" ref={formRef} onSubmit={validations.validateForm}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Autocomplete
                    open={openTypesHardwareFilter}
                    onOpen={() => setOpenTypesHardwareFilter(true)}
                    onClose={() => setOpenTypesHardwareFilter(false)}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => option.description || ""}
                    options={optionsTypesHardwareFilter.sort((a, b) => -b.description.localeCompare(a.description))}
                    loading={loadingOptionsTypesHardware}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="hardwareTypeDescription"
                        label={t(HardwareModuleMessages.hardwareDetailsType)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("type").length > 0 ? true : undefined
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingOptionsTypesHardware ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Autocomplete
                    id="vehicleFilter"
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    options={optionsVehicleFilter.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                      || -b.code.localeCompare(a.code))}
                    groupBy={(option) => t(VehicleTypesMessages[`${option.type.id_vehicle_type}-plural`])}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicleDescription"
                        label={t(GlobalMessages.vehicle)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="startDate"
                    label={t(FilterMessages.optionStartDate)}
                    onChange={(event) => setStartDate(event.target.value)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(dateFns.subYears(new Date(), 1), "yyyy-MM-dd").concat("T00:00")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="endDate"
                    label={t(FilterMessages.optionEndDate)}
                    onChange={(event) => setFinishDate(event.target.value)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <ButtonLoading
                    className="button-loading"
                    type="submit"
                    loading={loadingInstallationHistory}
                  >{t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
        {
          !loadingInstallationHistory && (
            <NewDataTable
              title={t(HardwareModuleMessages.tabsHardwareInstallationHistory)}
              filters
              data={installationHistoryList}
              columns={dataTableColumns}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
            />
          )
        }
      </Container>
    </>
  );
};

export default InstallationHistoryList;
