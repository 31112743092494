/* eslint-disable import/prefer-default-export */
import { TravelSwapMessages, TranslationTravelSwapMessages } from "../interfaces";

export const messages: TranslationTravelSwapMessages = {
  [TravelSwapMessages.swapCompleted]: "Swap completed!",
  [TravelSwapMessages.swapTravelData]: "Swap travel data",
  [TravelSwapMessages.originalTravel]: "Original travel",
  [TravelSwapMessages.replaceTravel]: "Travel to swap",
  [TravelSwapMessages.swap]: "Swap",
  [TravelSwapMessages.swapConfirmationTitle]: "Swap confirmation",
  [TravelSwapMessages.swapConfirmationDescription]: "Are you sure you want to swap the data of the following travels?"
};
