import React from "react";

// region Libraries
import { Dialog, DialogContent } from "@material-ui/core";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
// endregion Libraries
// region Languages
import { GlobalMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion Languages
// region Styles
import { Container } from "./styles";
// endregion Styles

interface DialogLandscapeProps {
    isOpen: boolean;
}

const DialogLandscape: React.FC<DialogLandscapeProps> = ({ isOpen }) => {

  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Container>
          <ScreenRotationIcon fontSize="large" />
          <h3>{t(GlobalMessages.dialogLandscape)}</h3>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DialogLandscape;
