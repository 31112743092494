import styled, { css } from "styled-components";

export const Container = styled.button`
  background: ${(props) => props.theme.colors.primary};
  height: 48px;
  border-radius: 4px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 160px;
  font-size: 20px;
  font-weight: 500;
  transition: background-color 0.2s;

  ${({ disabled, theme }) => css`
    ${!disabled
    && `
      :hover {
        background: ${theme.colors.secondary};
      }
    `}
    ${disabled
    && `
      opacity: 0.8;
      cursor: auto;
      background: ${(props) => props.theme.colors.secondary};
    `}
  `}
`;
