/* eslint-disable max-len */
import { LocationsModuleMessages, TranslationLocationsModuleMessages } from "../interfaces";

export const messages: TranslationLocationsModuleMessages = {

  /* region Frontend messages */
  [LocationsModuleMessages.name]: "Name",
  [LocationsModuleMessages.type]: "Type",
  [LocationsModuleMessages.address]: "Street",
  [LocationsModuleMessages.number]: "Number",
  [LocationsModuleMessages.district]: "District",
  [LocationsModuleMessages.county]: "City",
  [LocationsModuleMessages.state]: "State",
  [LocationsModuleMessages.zipCode]: "ZIP Code",
  [LocationsModuleMessages.regional]: "Regional",
  [LocationsModuleMessages.noNumber]: "No number",
  [LocationsModuleMessages.noNameRegistered]: "No name registered",
  [LocationsModuleMessages.dataTableTitle]: "locations",
  [LocationsModuleMessages.deleteConfirmationTitle]: "Deletion confirmation",
  [LocationsModuleMessages.deleteConfirmationText]: "Do you really want to delete the location",
  [LocationsModuleMessages.searching]: "Searching locations...",
  [LocationsModuleMessages.formZipCodeIncomplete]: "Incomplete ZIP Code",
  [LocationsModuleMessages.formExternalIdAlreadyRegistered]: "External ID already registered",
  [LocationsModuleMessages.formTypeRequired]: "Type is required",
  [LocationsModuleMessages.formAddressRequired]: "Street is required",
  [LocationsModuleMessages.formDistrictRequired]: "District is required",
  [LocationsModuleMessages.formCountyRequired]: "City is required",
  [LocationsModuleMessages.formNameRequired]: "Name is required",
  [LocationsModuleMessages.formStateRequired]: "State is required",
  [LocationsModuleMessages.toastCoordsNotDefinedTitle]: "Coordinates not defined",
  [LocationsModuleMessages.toastCoordsNotDefinedDescription]: "Define the coordinates by setting them on the map or searching by ZIP Code",
  [LocationsModuleMessages.toastNoZipCodeDataFoundDescription]: "No data found for the ZIP Code",
  [LocationsModuleMessages.toastSelectLocationInMapDescription]: "Select a location on the map",
  [LocationsModuleMessages.toastDataLoadedDescription]: "Data loaded successfully!",
  [LocationsModuleMessages.updateConfirmationTitle]: "Confirmation to update address data",
  [LocationsModuleMessages.updateConfirmationText]: "Do you want to update the following location data?",
  [LocationsModuleMessages.detailsLocationRegisterTitle]: "Location registration",
  [LocationsModuleMessages.noNameRegistered]: "No name registered",
  [LocationsModuleMessages.detailsMapPosition]: "Map position",
  [LocationsModuleMessages.detailsEditLabel]: "Edit",
  [LocationsModuleMessages.externalId]: "External ID",
  [LocationsModuleMessages.detailsExternalIdHelper]: "Ref. to integrations",
  [LocationsModuleMessages.detailsType]: "Location type",
  [LocationsModuleMessages.detailsRadius]: "Fence (Radius)",
  [LocationsModuleMessages.detailsRadiusHelper]: "In meters (m)",
  [LocationsModuleMessages.searching]: "Searching...",
  [LocationsModuleMessages.detailsSearchZipCode]: "Search ZIP Code",
  [LocationsModuleMessages.complement]: "Complement",
  [LocationsModuleMessages.detailsAllowTravelClosure]: "Allows trip closure",
  [LocationsModuleMessages.mapButtonConfirmLocation]: "Confirm location",
  [LocationsModuleMessages.active]: "Active",
  [LocationsModuleMessages.inactive]: "Inactive",
  [LocationsModuleMessages.activeLocation]: "Active location",

  /* endregion Frontend messages */
  /* region Backend messages */
  [LocationsModuleMessages.getLocationDataByGoogleSuccess]: "Data returned successfully",
  [LocationsModuleMessages.getLocationDataByGoogleError]: "Error fetching location data",
  [LocationsModuleMessages.readLocationTypeSuccess]: "Location type(s) returned successfully",
  [LocationsModuleMessages.noLocationTypeFound]: "No location type found",
  [LocationsModuleMessages.readLocationTypeError]: "Error fetching location type(s)",
  [LocationsModuleMessages.createLocationSuccess]: "Location created successfully",
  [LocationsModuleMessages.readLocationSuccess]: "Location(s) returned successfully",
  [LocationsModuleMessages.updateLocationSuccess]: "Location updated successfully",
  [LocationsModuleMessages.deleteLocationSuccess]: "Location deleted successfully",
  [LocationsModuleMessages.getPlantsLocationsSuccess]: "Plants returned successfully",
  [LocationsModuleMessages.createLocationError]: "Error creating location",
  [LocationsModuleMessages.readLocationError]: "Error fetching location(s)",
  [LocationsModuleMessages.noLocationFound]: "No location found",
  [LocationsModuleMessages.locationNotFound]: "Location not found in our system",
  [LocationsModuleMessages.updateLocationError]: "Error updating location",
  [LocationsModuleMessages.deleteLocationError]: "Error deleting location",
  [LocationsModuleMessages.noPlantFound]: "No plant found",
  [LocationsModuleMessages.getPlantsLocationsError]: "Error fetching plants",
  [LocationsModuleMessages.externalID]: "External ID",
  [LocationsModuleMessages.createPlantSuccess]: "Plant created successfully",
  [LocationsModuleMessages.createPlantError]: "Error creating plant",
  [LocationsModuleMessages.updatePlantSuccess]: "Plant updated successfully",
  [LocationsModuleMessages.updatePlantError]: "Error updating plant",
  [LocationsModuleMessages.plantNotFound]: "Plant not found in our system",
  [LocationsModuleMessages.deletePlantSuccess]: "Plant deleted successfully",
  [LocationsModuleMessages.deletePlantError]: "Error deleting plant",

  /* region Integration messages */
  [LocationsModuleMessages.addressNotFound]: "Address(es) not found",
  [LocationsModuleMessages.externalIDAlreadyRegistered]: "External ID(s) already registered",
  [LocationsModuleMessages.needToInformLocationData]: "You must inform valid latitude and longitude or address information (county, uf, district, ...)",
  [LocationsModuleMessages.noAddressChanges]: "Location has not had address changes",
  [LocationsModuleMessages.locationLinkedToAVehicleState]: "Location linked to a vehicle state",
  /* endregion Integration messages */

  /* endregion Backend messages */
  /* region Location Types */
  [LocationsModuleMessages.plant]: "Plant",
  [LocationsModuleMessages.construction]: "Construction",
  [LocationsModuleMessages.autoElectricalWorkshop]: "Auto Electrical Workshop",
  [LocationsModuleMessages.tireRepairShop]: "Tire Repair Shop",
  [LocationsModuleMessages.gasStation]: "Gas Station",
  [LocationsModuleMessages.workshop]: "Workshop",
  [LocationsModuleMessages.parkingLot]: "Parking Lot",
  [LocationsModuleMessages.others]: "Others",
  [LocationsModuleMessages.supplier]: "Supplier",
  [LocationsModuleMessages.client]: "Client"
  /* endregion Location Types */
};
