import { HardwareTypesID } from "../../constants/hardware-types.enum";

export const enum HardwareModuleMessages {

  /* region Frontend messages */
  tabsHardware = "hardwareModule.tabsHardware",
  tabsHardwareInstallationHistory = "hardwareModule.tabsHardwareInstallationHistory",
  tabsCommands = "hardwareModule.tabsCommands",
  tabsLogs = "hardwareModule.tabsLogs",
  /* region Commands tab */
  commandStatusPending = "hardwareModule.commandStatusPending",
  commandStatusSent = "hardwareModule.commandStatusSent",
  commandStatusProcessed = "hardwareModule.commandStatusProcessed",
  commandStatusCancelled = "hardwareModule.commandStatusCancelled",
  commandFilterCreation = "hardwareModule.commandFilterCreation",
  commandFilterSend = "hardwareModule.commandFilterSend",
  commandFilterProcessing = "hardwareModule.commandFilterProcessing",
  command = "hardwareModule.command",
  commandRegistrationDate = "hardwareModule.commandRegistrationDate",
  commandSendDate = "hardwareModule.commandSendDate",
  commandProcessedDate = "hardwareModule.commandProcessedDate",
  commandAttempts = "hardwareModule.commandAttempts",
  commandResponse = "hardwareModule.commandResponse",
  commandFormSelectVehicle = "hardwareModule.commandFormSelectVehicle",
  commandFormSelectCommand = "hardwareModule.commandFormSelectCommand",
  commandFormRequiredCommand = "hardwareModule.commandFormRequiredCommand",
  commandFilterNoTracker = "hardwareModule.commandFilterNoTracker",
  commandFilterDateRange = "hardwareModule.commandFilterDateRange",
  commandFilterNoLinkedTracker = "hardwareModule.commandFilterNoLinkedTracker",
  commandTitle = "hardwareModule.commandTitle",
  vehicle = "hardwareModule.vehicle",
  commandStatus = "hardwareModule.commandStatus",
  commandFilterDateBy = "hardwareModule.commandFilterDateBy",
  startDate = "hardwareModule.startDate",
  finishDate = "hardwareModule.finishDate",
  dataTableTitle = "hardwareModule.dataTableTitle",
  commandDeleteTitle = "hardwareModule.commandDeleteTitle",
  commandDeleteText = "hardwareModule.commandDeleteText",
  devId = "hardwareModule.devId",
  commandCreateTitle = "hardwareModule.commandCreateTitle",
  commandCreateDefinedCommand = "hardwareModule.commandCreateDefinedCommand",
  commandCreateToSendCommand = "hardwareModule.commandCreateToSendCommand",
  commandCreateNoVehicle = "hardwareModule.commandCreateNoVehicle",
  commandCopyAlert = "hardwareModule.commandCopyAlert",
  /* endregion Commands tab */
  /* region Hardware tab */
  hardwareFormTypeRequired = "hardwareModule.hardwareFormTypeRequired",
  hardwareFormBrandRequired = "hardwareModule.hardwareFormBrandRequired",
  hardwareFormModelRequired = "hardwareModule.hardwareFormModelRequired",
  hardwareFormDevIdRequired = "hardwareModule.hardwareFormDevIdRequired",
  hardwareFormPurchaseDateRequired = "hardwareModule.hardwareFormPurchaseDateRequired",
  hardwareFormDevIdAlreadyExists = "hardwareModule.hardwareFormDevIdAlreadyExists",
  iButton = "hardwareModule.iButton",
  type = "hardwareModule.type",
  brand = "hardwareModule.brand",
  model = "hardwareModule.model",
  doBlock = "hardwareModule.doBlock",
  registrationDate = "hardwareModule.registrationDate",
  hardwareDataTableNoLinkedVehicle = "hardwareModule.hardwareDataTableNoLinkedVehicle",
  simCard = "hardwareModule.simCard",
  hardwareDataTableNoLinkedSimCard = "hardwareModule.hardwareDataTableNoLinkedSimCard",
  driver = "hardwareModule.driver",
  hardwareDataTableNoLinkedDriver = "hardwareModule.hardwareDataTableNoLinkedDriver",
  hardwareDataTableTitle = "hardwareModule.dataTableHardwareTitle",
  hardwareDeleteTitle = "hardwareModule.hardwareDeleteTitle",
  hardwareDeleteText = "hardwareModule.hardwareDeleteText",
  hardwareCreateTitle = "hardwareModule.hardwareCreateTitle",
  hardwareDetailsActive = "hardwareModule.hardwareDetailsActive",
  hardwareDetailsType = "hardwareModule.hardwareDetailsType",
  hardwareDetailsPurchaseDate = "hardwareModule.hardwareDetailsPurchaseDate",
  /* endregion Hardware tab */
  // region Hardware Installation History tab
  hardwareDevId = "hardwareModule.hardwareDevId",
  hardwareType = "hardwareModule.hardwareType",
  occurrenceDate = "hardwareModule.occurrenceDate",
  importedData = "hardwareModule.importedData",
  /* region Logs tab */
  logFormModelRequired = "hardwareModule.logFormModelRequired",
  logNoTracker = "hardwareModule.logNoTracker",
  logTitle = "hardwareModule.logTitle",
  logAutoRefresh = "hardwareModule.logAutoRefresh",
  logSearching = "hardwareModule.logSearching",
  /* endregion Logs tab */
  /* endregion Frontend messages */

  /* region Backend messages */
  /* region Hardware Config Commands */
  createHardwareConfigCommandsSuccess = "hardwareModule.createHardwareConfigCommandsSuccess",
  createHardwareConfigCommandsError = "hardwareModule.createHardwareConfigCommandsError",
  readHardwareConfigCommandsSuccess = "hardwareModule.readHardwareConfigCommandsSuccess",
  readHardwareConfigCommandsError = "hardwareModule.readHardwareConfigCommandsError",
  updateHardwareConfigCommandsSuccess = "hardwareModule.updateHardwareConfigCommandsSuccess",
  updateHardwareConfigCommandsError = "hardwareModule.updateHardwareConfigCommandsError",
  readHardwareBlockCommandsSuccess = "hardwareModule.readHardwareBlockCommandsSuccess",
  readHardwareConfigCommandsContentSuccess = "hardwareModule.readHardwareConfigCommandsContentSuccess",
  readHardwareConfigCommandsContentError = "hardwareModule.readHardwareConfigCommandsContentError",
  noCommandFound = "hardwareModule.noCommandFound",
  commandNotFound = "hardwareModule.commandNotFound",
  noBlockFound = "hardwareModule.noBlockFound",
  readHardwareBlockCommandsError = "hardwareModule.readHardwareBlockCommandsError",
  /* endregion Hardware Config Commands */
  /* region Hardware logs */
  readHardwareLogsSuccess = "hardwareModule.readHardwareLogsSuccess",
  noLogFound = "hardwareModule.noLogFound",
  readHardwareLogsError = "hardwareModule.readHardwareLogsError",
  /* endregion Hardware logs */
  /* region Hardware types */
  typeTracker = "hardwareModule.typeTracker",
  typeSensor = "hardwareModule.typeSensor",
  typeIButton = "hardwareModule.typeIButton",
  createHardwareTypesSuccess = "hardwareModule.createHardwareTypesSuccess",
  readHardwareTypesSuccess = "hardwareModule.readHardwareTypesSuccess",
  updateHardwareTypesSuccess = "hardwareModule.updateHardwareTypesSuccess",
  deleteHardwareTypesSuccess = "hardwareModule.deleteHardwareTypesSuccess",
  noHardwareTypeFound = "hardwareModule.noHardwareTypeFound",
  readHardwareTypesError = "hardwareModule.readHardwareTypesError",
  createHardwareTypeError = "hardwareModule.createHardwareTypeError",
  updateHardwareTypeError = "hardwareModule.updateHardwareTypeError",
  hardwareTypeNotFound = "hardwareModule.hardwareTypeNotFound",
  deleteHardwareTypeError = "hardwareModule.deleteHardwareTypeError",
  /* endregion Hardware types */
  /* region Hardware */
  createHardwareSuccess = "hardwareModule.createHardwareSuccess",
  readHardwareSuccess = "hardwareModule.readHardwareSuccess",
  updateHardwareSuccess = "hardwareModule.updateHardwareSuccess",
  deleteHardwareSuccess = "hardwareModule.deleteHardwareSuccess",
  createHardwareError = "hardwareModule.createHardwareError",
  readHardwareError = "hardwareModule.readHardwareError",
  noHardwareFound = "hardwareModule.noHardwareFound",
  updateHardwareError = "hardwareModule.updateHardwareError",
  hardwareNotFound = "hardwareModule.hardwareNotFound",
  deleteHardwareError = "hardwareModule.deleteHardwareError",
  /* endregion Hardware */
  // region Hardware Installation History
  readHardwareInstallationHistorySuccess = "hardwareModule.readHardwareInstallationHistorySuccess",
  noHardwareInstallationHistoryFound = "hardwareModule.noHardwareInstallationHistoryFound",
  readHardwareInstallationHistoryError = "hardwareModule.readHardwareInstallationHistoryError"
  // endregion Hardware Installation History
  /* endregion Backend messages */
}

export type TranslationHardwareModuleMessage = {
  [key in HardwareModuleMessages]: string;
}

export const HardwareTypeMessages = {
  [HardwareTypesID.RASTREADOR]: HardwareModuleMessages.typeTracker,
  [HardwareTypesID.SENSOR_DE_BALAO]: HardwareModuleMessages.typeSensor,
  [HardwareTypesID.IBUTTON]: HardwareModuleMessages.typeIButton
};
