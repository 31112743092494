/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";

// region Libraries
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem
} from "@material-ui/core";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Select, TextField } from "unform-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, ServiceOrderMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";
// endregion Hooks
// region Entities
import { ServiceOrder } from "@shared/entities/reg_service_orders.entity";
import { Vehicle } from "@shared/entities/reg_vehicles.entity";
import { Driver } from "@shared/entities/reg_drivers.entity";
import { Location } from "@shared/entities/reg_locations.entity";
import { Travel } from "@shared/entities/reg_travels.entity";
// endregion Entities
// region Types
import { VehicleTypes } from "@shared/constants/vehicle-types.enum";
import { VehicleUsageType } from "@shared/types/vehicle_usage_type.enum";
import { ProductType } from "@shared/types/product_type";
import { UnitType } from "@shared/types/unit_type.enum";
import { ServiceOrderStatusType } from "@shared/types/service_order_status_type.enum";
// endregion Types
// region Services
import api from "@services/api";
// endregion Services
// region Styles
import { Container } from "./styles";
// endregion Styles

type ServiceOrderModalProps = { onClose: () => void, open: boolean }

const ServiceOrderModal: React.FC<ServiceOrderModalProps> = ({ onClose, open }) => {

  const { addToast } = useToast();
  const { t } = useTranslation();
  const formRef = useRef<FormHandles>(null);

  // region Options lists states
  const [optionsVehicles, setOptionsVehicles] = useState<Vehicle[]>([] as Vehicle[]);
  const [optionsDrivers, setOptionsDrivers] = useState<Driver[]>([] as Driver[]);
  const [optionsOriginLocations, setOptionsOriginLocations] = useState<Location[]>([] as Location[]);
  const [optionsLoadingLocations, setOptionsLoadingLocations] = useState<Location[]>([] as Location[]);
  const [optionsUnloadingLocations, setOptionsUnloadingLocations] = useState<Location[]>([] as Location[]);
  // endregion Options lists states
  // region Active option states
  const [activeOptionVehicle, setActiveOptionVehicle] = useState<Vehicle>({} as Vehicle);
  const [activeOptionDriver, setActiveOptionDriver] = useState<Driver>({} as Driver);
  const [activeOptionOriginLocation, setActiveOptionOriginLocation] = useState<Location>({} as Location);
  const [activeOptionLoadingLocation, setActiveOptionLoadingLocation] = useState<Location>({} as Location);
  const [activeOptionUnloadingLocation, setActiveOptionUnloadingLocation] = useState<Location>({} as Location);
  // endregion Active option states
  // region Open options states
  const [openOptionsVehicles, setOpenOptionsVehicles] = useState(false);
  const [openOptionsDrivers, setOpenOptionsDrivers] = useState(false);
  const [openOptionsOriginLocations, setOpenOptionsOriginLocations] = useState(false);
  const [openOptionsLoadingLocations, setOpenOptionsLoadingLocations] = useState(false);
  const [openOptionsUnloadingLocations, setOpenOptionsUnloadingLocations] = useState(false);
  // endregion Open options states
  // region Loading options states
  const loadingOptionsVehicles = openOptionsVehicles && optionsVehicles.length === 0;
  const loadingOptionsDrivers = openOptionsDrivers && optionsDrivers.length === 0;
  const loadingOptionsOriginLocations = openOptionsOriginLocations && optionsOriginLocations.length === 0;
  const loadingOptionsLoadingLocations = openOptionsLoadingLocations && optionsLoadingLocations.length === 0;
  const loadingOptionsUnloadingLocations = openOptionsUnloadingLocations && optionsUnloadingLocations.length === 0;
  const [loading, setLoading] = useState(false);
  // endregion Loading options states

  // region Handle functions
  const sortOptions = (options: any[], field: string) => {

    if (field === "description") {
      return options.sort((a, b) => {
        if (a?.description < b?.description) return -1;
        if (a?.description > b?.description) return 1;

        return 0;
      });
    }

    if (field === "name") {
      return options.sort((a, b) => {
        if (a?.name < b?.name) return -1;
        if (a?.name > b?.name) return 1;

        return 0;
      });
    }
    if (field === "descriptionAndName") {
      return options.sort((a, b) => {

        const compareDescription = a.type?.description.localeCompare(b.type?.description);
        const compareName = a.name.localeCompare(b.name);

        return compareDescription || compareName;
      });
    }

    return options;
  };
  // endregion Handle functions

  // Vehicle options
  useEffect(() => {
    if (!openOptionsVehicles) setOptionsVehicles([]);
  }, [openOptionsVehicles]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsVehicles) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read");

        if (data.status === "success") {
          if (active) {
            setOptionsVehicles(
              data.result.filter(
                (vehicle: Vehicle) => vehicle?.type?.description === VehicleTypes.CARRETA_AGREGADOS
                  || vehicle?.type?.description === VehicleTypes.CARRETA_SILO
              )
            );
          }
        } else {
          setOpenOptionsVehicles(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsVehicles(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsVehicles, addToast]);

  // Driver options
  useEffect(() => {
    if (!openOptionsDrivers) setOptionsDrivers([]);
  }, [openOptionsDrivers]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsDrivers) return undefined;

    (async () => {

      try {

        // Get all drivers
        const { data } = await api.get("drivers/read");

        if (data.status === "success") {
          if (active) setOptionsDrivers(data.result);
        } else {
          setOpenOptionsDrivers(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsDrivers(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsDrivers, addToast]);

  // Origin location options
  useEffect(() => {
    if (!openOptionsOriginLocations) setOptionsOriginLocations([]);
  }, [openOptionsOriginLocations]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsOriginLocations) return undefined;

    (async () => {

      try {

        // Get all locations that are not of type construction
        const { data } = await api.get("locations/get-without-constructions",
          { params: { onlyLocationsWithName: true } });

        if (data.status === "success") {
          if (active) setOptionsOriginLocations(data.result);
        } else {
          setOpenOptionsOriginLocations(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsOriginLocations(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsOriginLocations, addToast]);

  // Loading location options
  useEffect(() => {
    if (!openOptionsLoadingLocations) setOptionsLoadingLocations([]);
  }, [openOptionsLoadingLocations]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsLoadingLocations) return undefined;

    (async () => {

      try {

        // Get all locations that are not of type construction
        const { data } = await api.get("locations/get-without-constructions",
          { params: { onlyLocationsWithName: true } });

        if (data.status === "success") {
          if (active) setOptionsLoadingLocations(data.result);
        } else {
          setOpenOptionsLoadingLocations(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsLoadingLocations(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsLoadingLocations, addToast]);

  // Unloading location options
  useEffect(() => {
    if (!openOptionsUnloadingLocations) setOptionsUnloadingLocations([]);
  }, [openOptionsUnloadingLocations]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsUnloadingLocations) return undefined;

    (async () => {

      try {

        // Get all locations that are not of type construction
        const { data } = await api.get("locations/get-without-constructions",
          { params: { onlyLocationsWithName: true } });

        if (data.status === "success") {
          if (active) setOptionsUnloadingLocations(data.result);
        } else {
          setOpenOptionsUnloadingLocations(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsUnloadingLocations(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsUnloadingLocations, addToast]);

  /*
   * Create service order function
   */
  const createServiceOrder = useCallback(async (formData) => {

    const insertServiceOrder: ServiceOrder = { ...formData, travel: {} as Travel };

    // Set the status
    insertServiceOrder.status = ServiceOrderStatusType.NOT_STARTED;

    // Set travel data
    if (insertServiceOrder.travel) {
      insertServiceOrder.travel.vehicle = activeOptionVehicle;
      insertServiceOrder.travel.origin = activeOptionOriginLocation;
      insertServiceOrder.travel.destination = activeOptionLoadingLocation;
      insertServiceOrder.travel.end = activeOptionUnloadingLocation;
      insertServiceOrder.travel.driver = activeOptionDriver;

      // Remove travel properties without value (driver is optional)
      for (const [key, value] of Object.entries(insertServiceOrder.travel)) {
        if (_.isEmpty(value)) delete insertServiceOrder.travel[key];
      }
    }

    // Remove properties without value
    for (const [key, value] of Object.entries(insertServiceOrder)) {
      if (_.isEmpty(value)) delete insertServiceOrder[key];
    }

    try {

      setLoading(true);

      // Insert the service order
      const { data } = await api.post("travels/service-orders/create", insertServiceOrder);

      if (data.status === "success") {
        addToast({ type: "success", title: t(GlobalMessages.success), description: data.message });

        // Close modal
        onClose();
      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }
  }, [
    addToast, activeOptionVehicle, activeOptionDriver, activeOptionOriginLocation, activeOptionLoadingLocation,
    activeOptionUnloadingLocation, onClose
  ]);

  // Form validation
  const validations = {

    validateForm: async (formData: ServiceOrder) => {

      try {

        // Define the validation types
        const schema = Yup.object().shape({
          expected_start_date: Yup.string().required(t(YupMessages.expectedDateRequired))
            .test("is-future-date", t(YupMessages.expectedDateGreaterThanCurrentDate), (value) => {
              if (!value) return false;

              const date = new Date(value);
              const currentDate = new Date();

              return date > currentDate;
            }),
          origin: Yup.string().required(t(YupMessages.originLocationRequired)),
          loading_location: Yup.string().required(t(YupMessages.loadingLocationRequired)),
          unloading_location: Yup.string().required(t(YupMessages.unloadingLocationRequired)),
          vehicle: Yup.string().required(t(YupMessages.vehicleRequired)),
          product: Yup.string().required(t(YupMessages.productRequired)),
          type: Yup.string().required(t(YupMessages.serviceOrderTypeRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Create the service order
        await createServiceOrder(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Container>
        <Dialog
          open={open}
          onClose={() => onClose()}
          scroll="paper"
          container={document.getElementById("modalFormVehicle")}
        >
          <DialogTitle className="mHeader">
            <div className="content">
              <div className="title">{t(ServiceOrderMessages.newServiceOrder)}</div>
            </div>
          </DialogTitle>
          <DialogContent dividers className="mContent">
            <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData)}>
              <DialogContentText tabIndex={-1} component="div">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label={t(ServiceOrderMessages.expectedDate)}
                      name="expected_start_date"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Select
                      label={t(ServiceOrderMessages.serviceOrderType)}
                      name="type"
                      margin="dense"
                      variant="outlined"
                      style={{ width: "100%", paddingTop: "8px" }}
                    >
                      <MenuItem value={VehicleUsageType.DELIVERY}>{t(ServiceOrderMessages.delivery)}</MenuItem>
                      <MenuItem value={VehicleUsageType.PICKUP}>{t(ServiceOrderMessages.pickup)}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                      open={openOptionsVehicles}
                      onOpen={() => setOpenOptionsVehicles(true)}
                      onClose={() => setOpenOptionsVehicles(false)}
                      getOptionSelected={(option, value) => option.id_vehicle === value.id_vehicle}
                      getOptionLabel={(option) => option.description ?? ""}
                      options={sortOptions(optionsVehicles, "description")}
                      groupBy={(option) => option?.type?.description ?? ""}
                      loading={loadingOptionsVehicles}
                      onChange={(event, value: any) => setActiveOptionVehicle(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="vehicle"
                          label={t(GlobalMessages.vehicle)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("vehicle").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                { loadingOptionsVehicles ? <CircularProgress color="inherit" size={20} /> : null }
                                { params.InputProps.endAdornment }
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                      open={openOptionsDrivers}
                      onOpen={() => setOpenOptionsDrivers(true)}
                      onClose={() => setOpenOptionsDrivers(false)}
                      getOptionSelected={(option, value) => option.id_driver === value.id_driver}
                      getOptionLabel={(option) => option.name ?? ""}
                      options={sortOptions(optionsDrivers, "name")}
                      loading={loadingOptionsDrivers}
                      onChange={(event, value: any) => setActiveOptionDriver(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="driver"
                          label={t(GlobalMessages.driver)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("driver").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                { loadingOptionsDrivers ? <CircularProgress color="inherit" size={20} /> : null }
                                { params.InputProps.endAdornment }
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Select
                      label={t(ServiceOrderMessages.product)}
                      name="product"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      style={{ width: "100%", paddingTop: "8px" }}
                      type="text"
                    >
                      <MenuItem value={ProductType.BINDER}>{t(ServiceOrderMessages.binder)}</MenuItem>
                      <MenuItem value={ProductType.SMALL_AGGREGATE}>{t(ServiceOrderMessages.smallAggregate)}</MenuItem>
                      <MenuItem value={ProductType.COARSE_AGGREGATE}>{t(ServiceOrderMessages.coarseAggregate)}</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={8} md={3} lg={3}>
                    <TextField
                      label={t(ServiceOrderMessages.quantity)}
                      name="quantity"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3} lg={3}>
                    <Select
                      label={t(ServiceOrderMessages.unit)}
                      name="unit"
                      margin="dense"
                      variant="outlined"
                      style={{ minWidth: "100%", paddingTop: "8px" }}
                    >
                      <MenuItem value={UnitType.KG}>kg</MenuItem>
                      <MenuItem value={UnitType.M3}>m³</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label={t(ServiceOrderMessages.transportDocument)}
                      name="transport_document"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label={t(ServiceOrderMessages.productDocument)}
                      name="product_document"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsOriginLocations}
                      onOpen={() => setOpenOptionsOriginLocations(true)}
                      onClose={() => setOpenOptionsOriginLocations(false)}
                      getOptionSelected={(option, value) => option.id_location === value.id_location}
                      getOptionLabel={(option) => option.name ?? ""}
                      options={sortOptions(optionsOriginLocations, "descriptionAndName")}
                      groupBy={(option) => option?.type?.description ?? ""}
                      loading={loadingOptionsOriginLocations}
                      onChange={(event, value: any) => setActiveOptionOriginLocation(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="origin"
                          label={t(ServiceOrderMessages.originLocation)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("origin_location").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                { loadingOptionsOriginLocations ? <CircularProgress color="inherit" size={20} />
                                  : null }
                                { params.InputProps.endAdornment }
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsLoadingLocations}
                      onOpen={() => setOpenOptionsLoadingLocations(true)}
                      onClose={() => setOpenOptionsLoadingLocations(false)}
                      getOptionSelected={(option, value) => option.id_location === value.id_location}
                      getOptionLabel={(option) => option.name ?? ""}
                      options={sortOptions(optionsLoadingLocations, "descriptionAndName")}
                      groupBy={(option) => option?.type?.description ?? ""}
                      loading={loadingOptionsLoadingLocations}
                      onChange={(event, value: any) => setActiveOptionLoadingLocation(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="loading_location"
                          label={t(ServiceOrderMessages.loadingLocation)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("loading_location").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                { loadingOptionsLoadingLocations ? <CircularProgress color="inherit" size={20} />
                                  : null }
                                { params.InputProps.endAdornment }
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                      open={openOptionsUnloadingLocations}
                      onOpen={() => setOpenOptionsUnloadingLocations(true)}
                      onClose={() => setOpenOptionsUnloadingLocations(false)}
                      getOptionSelected={(option, value) => option.id_location === value.id_location}
                      getOptionLabel={(option) => option.name ?? ""}
                      options={sortOptions(optionsUnloadingLocations, "descriptionAndName")}
                      groupBy={(option) => option?.type?.description ?? ""}
                      loading={loadingOptionsUnloadingLocations}
                      onChange={(event, value: any) => setActiveOptionUnloadingLocation(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="unloading_location"
                          label={t(ServiceOrderMessages.unloadingLocation)}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("unloading_location").length > 0 ? true : undefined
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                { loadingOptionsUnloadingLocations ? <CircularProgress color="inherit" size={20} />
                                  : null }
                                { params.InputProps.endAdornment }
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      label={t(GlobalMessages.observations)}
                      name="observation"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      type="text"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </Form>
          </DialogContent>
          <DialogActions className="mFooter" style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              disableRipple
              type="submit"
              color="primary"
              onClick={() => formRef.current?.submitForm()}
            >{t(GlobalMessages.register)}
            </Button>
            <Button disableRipple onClick={() => onClose()} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default ServiceOrderModal;
