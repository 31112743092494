import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 10px;

  .status {
    padding: 10px 0 0 0;

    .content {
      padding: 0;
      align-items: flex-start !important;

      @media (max-width: 800px) { // Mobile
        display: flex;
      }

      .boxContent {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .title {
          padding-left: 10px;
          font-size: 1.15rem;
        }
        .text {
          padding-left: 10px;
          font-weight: bold;
          font-size: 1.15rem;
          text-transform: uppercase;
          text-align: right;

          .devId {
            font-size: 0.9rem;
          }
        }
      }
      .icon {
        height: 25px;

        .smallIcon{

          svg {
            width: 18px;
          }
        }
        svg {
          width: 20px;

          path, rect {
            stroke: none;
          }
        }
      }
    }
    .currentSpeed, .ignition, .odometer {

      .boxContent {

        .text {
          display: flex;
          justify-content: right;
        }
      }
    }

    .odometer {
      margin-top: 5px;
      margin-bottom: 4px;
    }

    .hour-meter {

      .boxContent {

        .title {
          padding-top: 1px;
        }

        .text {
          text-transform: none;
        }
      }
    }

  }
  .lastCommunicate {

    .MuiAccordion-root:before {
      top: unset;
      left: unset;
      right: unset;
      height: unset;
      content: unset;
      opacity: unset;
      position: unset;
      transition: unset;
      background-color: unset;
    }

    .MuiAccordion-root.Mui-expanded {
      margin-top: unset;
    }

    .MuiAccordion-root {
      box-shadow: none;
      width: 100% !important;

      .Mui-expanded {
        margin-top: unset;
      }

      .MuiAccordionSummary-root {
        padding: 0;
        height: 10px;
        min-height: 30px;

        .MuiAccordionSummary-content {
          align-items: center;
          margin: 0;

          .icon {

            svg {
              width: 20px;
              fill: #64DD17;
            }
          }
          .MuiTypography-root {
            padding-left: 10px;
            font-size: 1.15rem;
            color: #666666;
          }
        }
      }
      .MuiCollapse-container {

        .MuiAccordionDetails-root {

          .MuiTypography-root {
            width: 100%;

            .content {
              display: block;

              .boxContent {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-left: 5px !important;
                align-items: center;

                .title, .text {
                  font-size: 1rem !important;
                  padding-left: 0 !important;
                  max-width: 55%;
                }
                .text {
                  text-align: right;
                  /* padding-left: 25px !important; */
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
