/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */

import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";

// region Imports - Material-UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Fab,
  makeStyles, TextField,
  Typography
} from "@material-ui/core";
import { Check } from "@material-ui/icons/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { SettingsMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Param } from "@shared/entities/reg_params.entity";
import { ParamsKeys, ParamsNames } from "@shared/constants/params-names.enum";
// endregion Imports - Shared
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Components
import Header from "@components/Header";
import NumberField from "@atoms/NumberField";
// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
import { useAuth } from "@hooks/useAuth";
// endregion Imports - Hooks
// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store
// region Imports - Styles
import { Container, OptionContainer } from "./styles";
// endregion Imports - Styles

// region Child components
type OptionProps = {
  description: string
};

const Option: React.FC<OptionProps> = ({ children, description }) => (
  <OptionContainer>
    <div className="description">
      {description}
    </div>
    <div className="availableOptions">
      {children}
    </div>
  </OptionContainer>
);
// endregion Child components

const Settings: React.FC = () => {

  // region States
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [params, setParams] = useState<Param[]>([]);
  const [minutesForUndueStop, setMinutesForUndueStop] = useState(0);
  const [pumpTimelineEnabled, setPumpTimelineEnabled] = useState(false);
  const [blockDate, setBlockDate] = useState("");
  // endregion States
  // region Hooks
  const screen = useSelector(({ screen: state }) => state);
  const { addToast } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();
  // endregion Hooks
  // region Styles
  const useStyles = makeStyles((theme) => ({
    fab: {
      backgroundColor: "#002951",
      color: "#FFF",
      position: "fixed",
      bottom: screen.platform === ScreenPlatform.MOBILE ? theme.spacing(10) : theme.spacing(8),
      right: 15,
      zIndex: 1000,
      transform: screen.platform === ScreenPlatform.MOBILE ? "" : "scale(1.4)"
    },
    accordion: {
      boxShadow: "none",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    title: {
      margin: theme.spacing(2),
      fontWeight: "bold",
      fontSize: 16,
      alignItems: "left",
      flexDirection: "column",
      display: "flex"
    }
  }));

  const classes = useStyles();
  // endregion Styles

  useEffect(() => {
    const loadParams = async () => {
      try {

        // Load params
        setLoading(true);
        const { data } = await api.get("params/read");

        if (data.status === "success") {
          const loadedParams = data.result as Param[];

          setParams(loadedParams);

          loadedParams.forEach((param) => {

            let minutes;

            switch (param.name) {
              case ParamsNames.SECONDS_FOR_UNDUE_STOP:
                minutes = Math.floor(Number(param.key) / 60);

                setMinutesForUndueStop(minutes);
                break;
              case ParamsNames.PUMP_TIMELINE_ENABLED:
                setPumpTimelineEnabled(param.key === ParamsKeys.PUMP_TIMELINE_ENABLED_TRUE);
                break;
              case ParamsNames.BLOCK_DATE:
                setBlockDate(param.key ?? "");
                break;

              default:
                break;
            }
          });

        } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      } catch (error) {

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      } finally {
        setLoading(false);
      }
    };

    loadParams();
  }, [addToast, t]);

  /**
   * Update params
   * @param params Params to update
   */
  const HandleSave = useCallback(async () => {
    try {

      setSaving(true);

      const seconds_for_undue_stop = (minutesForUndueStop * 60).toString();

      // Get only if parameter changed
      const changedParams = params.map((param) => {
        let changed = false;

        switch (param.name) {
          case ParamsNames.SECONDS_FOR_UNDUE_STOP:
            if (param.key !== seconds_for_undue_stop) {
              param.key = seconds_for_undue_stop;
              changed = true;
            }
            break;
          case ParamsNames.PUMP_TIMELINE_ENABLED:
            if ((param.key === ParamsKeys.PUMP_TIMELINE_ENABLED_TRUE && !pumpTimelineEnabled)
              || (param.key === ParamsKeys.PUMP_TIMELINE_ENABLED_FALSE && pumpTimelineEnabled)) {

              // Update param
              param.key = pumpTimelineEnabled ? ParamsKeys.PUMP_TIMELINE_ENABLED_TRUE : ParamsKeys.PUMP_TIMELINE_ENABLED_FALSE;

              // Update local storage with new key value
              changed = true;
            }
            break;

          case ParamsNames.BLOCK_DATE:
            if (param.key !== blockDate) {
              param.key = blockDate === "" ? "1" : blockDate;
              changed = true;
            }
            break;

          default:
            break;
        }

        return changed ? { "name": param.name, "key": param.key } : undefined;
      }).filter((param) => param);

      // Update params
      if (changedParams && changedParams.length > 0) {
        const { data } = await api.patch("params/update", { "params": changedParams });

        if (data.status === "success") {
          addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
        } else {
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }
      }
    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setSaving(false);
    }

  }, [addToast, params, minutesForUndueStop, pumpTimelineEnabled, blockDate, t]);

  const handleDateChange = async (event: React.ChangeEvent) => {
    const date = (event.target as HTMLInputElement).value.toString();

    setBlockDate(date);

  };

  return (
    <>
      <Loading loading={loading} />
      <Header title={t(SettingsMessages.settingsTitle)} />
      <Container className="page">
        {screen.platform === ScreenPlatform.MOBILE && (
          <div className={classes.title}>{t(SettingsMessages.settingsTitle)}</div>
        )}
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t(SettingsMessages.parameters)}</Typography>
          </AccordionSummary>
          <AccordionDetails className="optionsAccordion">
            <Option
              description={t(SettingsMessages.timeForUndueStopOptionDescription)}
            >
              <NumberField
                label={t(SettingsMessages.minutes)}
                number={minutesForUndueStop}
                setNumber={setMinutesForUndueStop}
                min={1}
                max={999}
              />
            </Option>
            {user.super_admin && (
              <Option
                description="Data de Bloqueio"
              >
                <TextField
                  className="dateInput"
                  name="date"
                  type="date"
                  value={blockDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleDateChange}
                />
              </Option>
            )}
          </AccordionDetails>
        </Accordion>
        {user.super_admin && (
          <Accordion className={classes.accordion} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t(SettingsMessages.functionalities)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Option
                description={t(SettingsMessages.enablePumpTimelineOptionDescription)}
              >
                <Checkbox
                  checked={pumpTimelineEnabled}
                  onChange={() => setPumpTimelineEnabled((state) => !state)}
                />
              </Option>
            </AccordionDetails>
          </Accordion>
        )}
        <Fab
          className={classes.fab}
          aria-label="save"
          onClick={HandleSave}
          disabled={saving}
        >
          <Check />
        </Fab>
      </Container>
    </>
  );
};

export default Settings;
