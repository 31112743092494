import { EventTypesID } from "../../constants/event-types.enum";

export enum EventsMessages {

  // Backend messages
  notFound = "event.notFound",
  updateSuccess = "event.updateSuccess",
  updateError = "event.updateError",

  stop = "event.stop",
  improperDischarge = "event.improperDischarge",
  speeding = "event.speeding",
  speedingTravel = "event.speedingTravel",
  vehicleBlock = "event.vehicleBlock",
  concreteExpiration = "event.concreteExpiration"
}

export type TranslationEventsMessages = {
  [key in EventsMessages]: string;
}

export const EventTypesMessages = {
  [EventTypesID.PARADA]: EventsMessages.stop,
  [EventTypesID.DESCARREGAMENTO_INDEVIDO]: EventsMessages.improperDischarge,
  [EventTypesID.EXCESSO_DE_VELOCIDADE]: EventsMessages.speeding,
  [EventTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM]: EventsMessages.speedingTravel,
  [EventTypesID.BLOQUEIO]: EventsMessages.vehicleBlock,
  [EventTypesID.VENCIMENTO_DO_CONCRETO]: EventsMessages.concreteExpiration
};
