import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;

  &.page {
    padding-top: 1rem;
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: 800px) { // Mobile
    height: auto;

    .title, .filterHeader, .filterFooter {
      text-align: center !important;
    }
  }

  .form {
    background: white;
    padding: 0 20px;

    .filterContent {
      margin: 25px 0;
    }
    .button-loading {
      margin-top: 0 !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;
