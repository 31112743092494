import React from "react";

// region Styles
import * as Styled from "./styles";
// endregion Styles

interface DividerWithTextProps {
  children?: React.ReactNode;
}

const DividerWithText: React.FC<DividerWithTextProps> = ({
  children
}) => (
  <Styled.Container>
    <Styled.Line />
    <Styled.Text>{children}</Styled.Text>
    <Styled.Line />
  </Styled.Container>
);

export default DividerWithText;
