import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Loading = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<StylesheetProps>`
  padding: ${(props) => (props.platform === ScreenPlatform.MOBILE ? "0" : "90px")} 15px;

  margin-top: ${(props) => (props.platform === ScreenPlatform.MOBILE ? "5rem" : "0")};
`;

export const ContainerMapVehicles = styled.div`
  .gm-style {
    .gm-style-iw { // Google info window
      padding: 0 !important;
      max-width: 250px !important;

      .gm-style-iw-c {
        padding: 0 !important;
      }

      .gm-style-iw-d {

        .vehicle-summary__accordion {
          margin: 0;
          width: 100%;

          .gm-style-iw {
            padding: 20px !important;
          }
        }
      }
    }

  }
`;

export const ContainerToggleSwitch = styled.div`
  height: 40px;
  z-index: 100;
  background-color: #FFF;
  margin-top: 10px;
`;
