/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { Travel } from "./reg_travels.entity";

@Entity("reg_coords")
export class Coord {

  @PrimaryGeneratedColumn("uuid")
  id_coord?: string;

  @Column({ type: "varchar", nullable: false })
  latitude?: string;

  @Column({ type: "varchar", nullable: false })
  longitude?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToOne(() => Travel, (travel) => travel.coords, { nullable: true, onDelete: "CASCADE" })
  @JoinColumn({ name: "id_travel_fk" })
  travel?: Travel;

  @Column({ type: "timestamp", default: "(now() at time zone 'utc')", nullable: false })
  occurrence_date?: Date;

}
