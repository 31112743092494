/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import { Vehicle } from "@shared/interfaces/vehicle.interface";

// Action types
export enum AggregateTrucksStatesTypes {
  SET_AGGREGATE_TRUCKS_STATES = "@aggregateTrucksStates/SET_AGGREGATE_TRUCKS_STATES",
  SET_AGGREGATE_TRUCKS_STATES_ALL = "@aggregateTrucksStates/SET_AGGREGATE_TRUCKS_STATES_ALL",
  SET_AGGREGATE_TRUCKS_STATES_FILTERED = "@aggregateTrucksStates/SET_AGGREGATE_TRUCKS_STATES_FILTERED"
}

// Data Types
export interface AggregateTruckStatesList {
  [key: string]: Vehicle[];
}
export interface AggregateTrucksStatesObj {
  list: AggregateTruckStatesList;
  loading: boolean;
}

// State type
export interface AggregateTrucksStatesState {
  all: AggregateTrucksStatesObj;
  filtered?: AggregateTrucksStatesObj;
}
