import React, { useRef, useState } from "react";

import { Controls, SegmentDiv } from "./styles";

// region Interfaces
interface Segment {
  value: string;
  label: string;
}

interface ISegmentedControlProps {
  className?: string;
  name: string;
  segments: Segment[];
  onChange: (value: string, index: number) => void;
  defaultIndex?: number;
}
// endregion Interfaces

const SegmentedControl: React.FC<ISegmentedControlProps> = ({
  className,
  name,
  segments,
  onChange,
  defaultIndex = 0
}) => {
  // region States
  const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);
  // endregion States
  // region Refs
  const componentReady = useRef(false);
  // endregion Refs
  // region Handlers

  const handleChange = (value: string, index: number) => {
    setActiveIndex(index);
    onChange(value, index);
  };
  // endregion Handlers

  return (
    <Controls className={`${className} ${componentReady.current ? "ready" : "idle"}`}>
      {segments.map((item, i) => (
        <SegmentDiv
          key={item.value}
          className={i === activeIndex ? "active" : "inactive"}
        >
          <input
            type="radio"
            value={item.value}
            id={item.label}
            name={name}
            onChange={() => handleChange(item.value, i)}
            checked={i === activeIndex}
          />
          <label htmlFor={item.label}>{item.label}</label>
        </SegmentDiv>
      ))}
    </Controls>
  );
};

export default SegmentedControl;
