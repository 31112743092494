/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { State } from "./reg_states.entity";

@Entity("reg_countries")
export class Country {

  @PrimaryGeneratedColumn("uuid")
  id_country?: string;

  @Column({ type: "varchar", nullable: false })
  name?: string;

  @Column({ type: "varchar", nullable: false })
  abbreviation?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => State, (states) => states.country)
  states?: State[];

}
