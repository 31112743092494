import { DriverModuleMessages, TranslationDriverModuleMessages } from "../interfaces";

export const messages: TranslationDriverModuleMessages = {

  /* region Frontend messages */
  [DriverModuleMessages.deleteConfirmation]: "Delete confirmation",
  [DriverModuleMessages.deleteConfirmationText]: "Do you really want to delete the driver",
  [DriverModuleMessages.deleteConfirmationHolder]: "Holder of",
  [DriverModuleMessages.detailsTitle]: "Driver registration",
  [DriverModuleMessages.detailsActive]: "Active driver",
  [DriverModuleMessages.document]: "CPF",
  [DriverModuleMessages.re]: "RE",
  [DriverModuleMessages.licenseNumber]: "CNH",
  [DriverModuleMessages.licenseExpiredDate]: "License expiration date",
  [DriverModuleMessages.detailsDocument]: "Driver's license",
  [DriverModuleMessages.fullname]: "Full name",
  [DriverModuleMessages.detailsAvaiableGroup]: "Available",
  [DriverModuleMessages.detailsUsedGroup]: "Used",
  [DriverModuleMessages.detailsIButtonCode]: "IButton code",
  [DriverModuleMessages.iButton]: "IButton",
  [DriverModuleMessages.dataTableExpiredDocument]: "CNH",
  [DriverModuleMessages.invalidCPF]: "Invalid CPF",
  [DriverModuleMessages.invalidUniqueCPF]: "CPF already registered",
  [DriverModuleMessages.invalidUniqueRE]: "RE already registered",
  [DriverModuleMessages.formRequiredName]: "Name is required",
  [DriverModuleMessages.formIncompleteDocument]: "Incomplete CPF",
  [DriverModuleMessages.formRequiredDocument]: "CPF is required",
  /* endregion Frontend messages */
  /* region Backend messages */
  [DriverModuleMessages.createDriverSuccess]: "Driver registered successfully",
  [DriverModuleMessages.readDriverSuccess]: "Driver(s) returned successfully",
  [DriverModuleMessages.updateDriverSuccess]: "Driver edited successfully",
  [DriverModuleMessages.deleteDriverSuccess]: "Driver deleted successfully",
  [DriverModuleMessages.createDriverError]: "Failed to register driver",
  [DriverModuleMessages.readDriverError]: "Failed to fetch driver(s)",
  [DriverModuleMessages.noDriverFound]: "No driver registered",
  [DriverModuleMessages.driverNotFound]: "Driver not registered in our system",
  [DriverModuleMessages.updateDriverError]: "Failed to edit driver",
  [DriverModuleMessages.deleteDriverError]: "Failed to delete driver",

  /* region Integration messages */
  [DriverModuleMessages.externalIDAlreadyRegistered]: "External ID(s) already registered",
  [DriverModuleMessages.documentAlreadyRegistered]: "Document(s) already registered",
  [DriverModuleMessages.REAlreadyRegistered]: "RE(s) already registered",
  [DriverModuleMessages.driverLinkedToATravel]: "Driver linked to a travel"
  /* endregion */

  /* endregion Backend messages */
};
