/* eslint-disable import/prefer-default-export */
import { TravelSwapMessages, TranslationTravelSwapMessages } from "../interfaces";

export const messages: TranslationTravelSwapMessages = {
  [TravelSwapMessages.swapCompleted]: "Intercambio completado!",
  [TravelSwapMessages.swapTravelData]: "Cambiar datos de viaje",
  [TravelSwapMessages.originalTravel]: "Viaje original",
  [TravelSwapMessages.replaceTravel]: "Viaje para cambiar",
  [TravelSwapMessages.swap]: "Cambiar",
  [TravelSwapMessages.swapConfirmationTitle]: "Confirmación de cambio",
  [TravelSwapMessages.swapConfirmationDescription]: "¿Está seguro de que desea cambiar los datos de los siguientes viajes?"
};
