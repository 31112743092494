/* eslint-disable no-param-reassign, no-underscore-dangle */

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";

// region Imports - Libraries
import * as dateFns from "date-fns";
import * as _ from "lodash";
import * as Yup from "yup";
// endregion Imports - Libraries
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormatListBulleted, Map } from "@material-ui/icons";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  CoordinateAnalysisMessages,
  DataTableMessages,
  FilterMessages,
  GlobalMessages,
  ToastMessages, TravelHistoryMessages,
  VehicleTypesMessages,
  YupMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Imports - Shared
// region Imports - Components
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import ButtonLoading from "@components/Button/ButtonLoading";
import ButtonTable from "@components/Button/ButtonTable";
import { MapFixedPointCoord } from "@components/Map";
// endregion Imports - Components
// region Imports - Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Unform
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
// endregion Imports - Unform
// region Imports - Styles
import CoordinateAnalysisCommandDetails from "@atoms/CoordinateAnalysisCommandDetails";
import { PrepareDataContent } from "@shared/interfaces/socket.interface";
import { TravelAnalytics } from "@shared/interfaces/travel-analytics.interface";
import { Container, ContainerFilters, ContainerModal } from "./styles";
// endregion Imports - Styles

// Form filter interface
interface CoordinateHistoryFormData {
  vehicle: Vehicle;
  travel: Travel;
  startDate: string;
  endDate: string;
}

const CoordinateAnalysisDataTable: React.FC = () => {

  // region Hooks
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  // endregion Hooks

  // region States
  const [loading, setLoading] = useState(false);
  const [dateHourPeriod, setDateHourPeriod] = useState(0);
  const [travelAnalyticRecords, setTravelAnalyticRecords] = useState<TravelAnalytics[]>([]);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  // Vehicle options
  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [selectedVehicleFilter, setSelectedVehicleFilter] = useState<Vehicle>({} as Vehicle);

  // Travel options
  const [openTravelFilter, setOpenTravelFilter] = useState(false);
  const [optionsTravelFilter, setOptionsTravelFilter] = useState<Travel[]>([] as Array<Travel>);
  const [selectedTravelFilter, setSelectedTravelFilter] = useState<Travel>({} as Travel);

  // Command details states
  const [openCommandDetailsModal, setOpenCommandDetailsModal] = useState(false);
  const [commandDetails, setCommandDetails] = useState<PrepareDataContent>({} as PrepareDataContent);

  // endregion States
  // region Constants
  const loadingVehicleFilter = openVehicleFilter && optionsVehicleFilter.length === 0;
  const loadingTravelFilter = openTravelFilter && optionsTravelFilter.length === 0;
  // endregion Constants
  // region Forms
  const formRef = useRef<FormHandles>(null);

  // Validations form
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: CoordinateHistoryFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define the validation types
        const schema = Yup.object().shape({
          vehicle: Yup.string().required(t(YupMessages.vehicleRequired)),
          startDate: Yup.date(),
          endDate: Yup.date().min(Yup.ref("startDate"), t(YupMessages.finishDateGreaterThanStartDate))
            .test("validateRangeDays", t(YupMessages.maxDateInterval24h),
              (val) => dateFns.differenceInMinutes(val, new Date(formData.startDate)) <= 1440 || !_.isEmpty(formData.travel))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Set values of autocomplete fields
        formData.vehicle = selectedVehicleFilter;
        if (!_.isEmpty(selectedTravelFilter)) formData.travel = selectedTravelFilter;

        // Pass date to string utc
        formData.startDate = new Date(formData.startDate).toISOString();
        formData.endDate = new Date(formData.endDate).toISOString();

        // Return parameters to main Component
        await readCoordinateAnalysis(formData);

      } catch (error: any) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  // endregion Forms

  // region Functions

  /**
   * Search travels according the filters
   * @param formData Data of form filter (Send to request)
   */
  const readCoordinateAnalysis = useCallback(async (formData: CoordinateHistoryFormData) => {

    setLoading(true);

    try {

      const { data } = await api.get("travels/get-travel-analytics", { params: {
        idVehicle: formData.vehicle.id_vehicle,
        idTravel: formData.travel.id_travel,
        fullAnalytics: true,
        startDate: formData.startDate,
        endDate: formData.endDate
      } });

      if (data.status === "success") {
        setTravelAnalyticRecords(data.result);
      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoading(false);
    }

  }, [addToast, t]);

  /**
   * Handle open command tracker details modal
   */
  const handleOpenDetails = useCallback((rowData: TravelAnalytics) => {

    setOpenCommandDetailsModal(true);

    setCommandDetails(rowData.content);

  }, []);

  /**
   * Open map modal, using latitude and longitude from the selected coordinate
   */
  const handleOpenMap = useCallback((data: TravelAnalytics) => {

    setOpenMapModal(true);
    setLatitude(Number(data.content["LAT"].content));
    setLongitude(Number(data.content["LON"].content));

  }, []);

  /**
   * Close map modal and reset latitude and longitude
   */
  const handleCloseMap = useCallback(() => {
    setLatitude(0);
    setLongitude(0);
    setOpenMapModal(false);
  }, []);

  /**
   * Close map modal and reset latitude and longitude
   */
  const handleCloseCommandDetails = useCallback(() => {
    setCommandDetails({} as PrepareDataContent);
    setOpenCommandDetailsModal(false);
  }, []);

  // endregion Functions

  // region DataTable
  const dataTableSettings: DataTables.Settings = {
    order: [[1, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    { ref: ".map", callback: (rowData: TravelAnalytics) => handleOpenMap(rowData) },
    { ref: ".travel-history-details", callback: (rowData: TravelAnalytics) => handleOpenDetails(rowData) }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // HDR (Command type)
      title: t(CoordinateAnalysisMessages.command),
      className: "print csv",
      data: (record: TravelAnalytics) => (record.command),
      filterable: true,
      propertyName: "command",
      defaultContent: "",
      toFilterValue: (record: TravelAnalytics) => (record.command)
    },
    { // Occurrence date
      title: t(TravelHistoryMessages.occurrenceDate),
      className: "print csv",
      data: (record: TravelAnalytics) => (utils.formatDateIfHave(record.occurrence_date, "fullDate")),
      filterable: false,
      propertyName: "occurrence_date"
    },
    { // I/O
      title: "I/O",
      className: "print csv",
      data: (record: TravelAnalytics) => (record.content["I/O"].content),
      filterable: true,
      defaultContent: "",
      propertyName: "I/O",
      toFilterValue: (record: TravelAnalytics) => (record.content["I/O"].content)
    },
    { // SATT (Active satellites)
      title: t(CoordinateAnalysisMessages.activeSatellites),
      className: "print csv",
      data: (record: TravelAnalytics) => (record.content["SATT"]?.content) ?? "-",
      filterable: true,
      defaultContent: "-",
      propertyName: "SATT",
      toFilterValue: (record: TravelAnalytics) => (record.content["SATT"]?.content) ?? "-"
    },
    { // Speed
      title: t(TravelHistoryMessages.speed),
      className: "print csv",
      data: (record: TravelAnalytics) => (`${parseFloat(record.content["SPD"].content).toFixed(1)} km/h`),
      filterable: true,
      propertyName: "SPD",
      defaultContent: "",
      toFilterValue: (record: TravelAnalytics) => parseFloat(record.content["SPD"].content).toFixed(1)
    },
    { // PWR_VOLT (Power voltage)
      title: "PWR_VOLT",
      className: "print csv",
      data: (record: TravelAnalytics) => (`${parseFloat(record.content["PWR_VOLT"].content).toFixed(1)} V`),
      filterable: true,
      propertyName: "PWR_VOLT",
      defaultContent: "",
      toFilterValue: (record: TravelAnalytics) => parseFloat(record.content["PWR_VOLT"].content).toFixed(1)
    },
    { // BCK_VOLT (Backup power voltage)
      title: "BCK_VOLT",
      className: "print csv",
      data: (record: TravelAnalytics) => (`${parseFloat(record.content["BCK_VOLT"].content).toFixed(1)} V`),
      filterable: true,
      defaultContent: "",
      toFilterValue: (record: TravelAnalytics) => parseFloat(record.content["BCK_VOLT"].content).toFixed(1)
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      width: "130px",
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1} justify="space-between">
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button map"><Map /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button travel-history-details"><FormatListBulleted /></ButtonTable>
          </Grid>
        </Grid>
      ),
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: "relatorio_viagens",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  // endregion DataTable

  // region Effects

  // Read Vehicles data (Options)
  useEffect(() => {

    let active = true;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read");

        if (data.status === "success") {
          if (active) setOptionsVehicleFilter(data.result);
        } else {
          setOpenVehicleFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenVehicleFilter(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [addToast, t]);

  // Read Travels data (Options)
  useEffect(() => {

    let active = true;

    // Search travels only click on travel filter
    if (!loadingTravelFilter) return undefined;

    (async () => {

      try {

        // Get travels from vehicle selected
        const { data } = await api.post("travels/get-filtered-by", {
          idVehicles: [selectedVehicleFilter?.id_vehicle],
          startDate: utils.convertDateToISOWithTimezone(new Date(formRef?.current?.getFieldValue("startDate"))),
          finishDate: utils.convertDateToISOWithTimezone(new Date(formRef?.current?.getFieldValue("endDate"))),
          finished: true
        });

        if (data.status === "success") {
          if (active) setOptionsTravelFilter(data.result);
        } else {
          setOpenTravelFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenTravelFilter(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingTravelFilter, selectedVehicleFilter, addToast, t]);
  useEffect(() => {
    if (!openTravelFilter) setOptionsTravelFilter([]);
  }, [openTravelFilter]);

  /**
   * Read coordinates when language is changed, using the same parameters
   */
  useEffect(() => {

    if (_.isEmpty(selectedVehicleFilter)) return;

    const queryParams: CoordinateHistoryFormData = {
      vehicle: selectedVehicleFilter,
      travel: selectedTravelFilter,
      startDate: formRef?.current?.getFieldValue("startDate"),
      endDate: formRef?.current?.getFieldValue("endDate")
    };

    readCoordinateAnalysis(queryParams).then();

  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);

  // endregion Effects

  return (
    <>
      <Container className="page">
        <Loading loading={loading} />
        <ContainerFilters>
          <div className="titleHeader">
            <div className="title">{t(CoordinateAnalysisMessages.dataTableTitle)}</div>
            <div className="subtitle">{t(FilterMessages.filterInstructions)}</div>
          </div>
          <Form className="form" ref={formRef} onSubmit={validations.validateForm}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Autocomplete
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    onChange={(event, value) => {
                      setSelectedTravelFilter({} as Travel);
                      formRef.current?.setFieldValue("travel", "");
                      setSelectedVehicleFilter(value as Vehicle);
                      validations.validateFieldError("vehicle");
                    }}
                    value={!_.isEmpty(selectedVehicleFilter) ? selectedVehicleFilter : null}
                    getOptionSelected={(option, value) => option.id_vehicle === value.id_vehicle}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    options={optionsVehicleFilter.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                      || -b.code.localeCompare(a.code))}
                    groupBy={(option) => t(VehicleTypesMessages[`${option.type.id_vehicle_type}-plural`])}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicle"
                        label={t(GlobalMessages.vehicle)}
                        helperText={t(GlobalMessages.required)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Autocomplete
                    open={openTravelFilter}
                    onOpen={() => setOpenTravelFilter(true)}
                    onClose={() => setOpenTravelFilter(false)}
                    onChange={(event, value) => setSelectedTravelFilter(value as Travel)}
                    value={!_.isEmpty(selectedTravelFilter) ? selectedTravelFilter : null}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => utils.formatAutoCompleteTravelLabel(option)}
                    options={optionsTravelFilter}
                    disabled={_.isEmpty(selectedVehicleFilter)
                      || dateHourPeriod > 72 || dateHourPeriod < 0}
                    loading={loadingTravelFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="travel"
                        label={t(CoordinateAnalysisMessages.travel)}
                        helperText={t(CoordinateAnalysisMessages.travelHelperText)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingTravelFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="startDate"
                    label={t(CoordinateAnalysisMessages.startDate)}
                    fullWidth
                    onChange={() => {
                      setDateHourPeriod(
                        dateFns.differenceInHours(
                          new Date(formRef?.current?.getFieldValue("endDate")),
                          new Date(formRef?.current?.getFieldValue("startDate"))
                        )
                      );
                    }}
                    disabled={!_.isEmpty(selectedTravelFilter)}
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T00:00")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="endDate"
                    label={t(CoordinateAnalysisMessages.finishDate)}
                    fullWidth
                    onChange={() => {
                      setDateHourPeriod(
                        dateFns.differenceInHours(
                          new Date(formRef?.current?.getFieldValue("endDate")),
                          new Date(formRef?.current?.getFieldValue("startDate"))
                        )
                      );
                    }}
                    disabled={!_.isEmpty(selectedTravelFilter)}
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T00:30")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <ButtonLoading
                    className="button-loading"
                    type="submit"
                    loading={loading}
                    loadingContent={t(CoordinateAnalysisMessages.loadingContent)}
                  >{t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
        {
          !loading && (
            <NewDataTable
              title={t(CoordinateAnalysisMessages.coordinateAnalysisTitle)}
              filters
              data={travelAnalyticRecords}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
            />
          )
        }
      </Container>

      <ContainerModal id="modalMapMarkerLocation">
        <Dialog
          open={openMapModal}
          onClose={handleCloseMap}
          scroll="paper"
          container={document.getElementById("modalMapMarkerLocation")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <MapFixedPointCoord
                mapHeight={500}
                latitude={latitude}
                longitude={longitude}
                dontGetAddress
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={handleCloseMap} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModal>

      <ContainerModal id="modalCommandDetails" isCommandDetails>
        <Dialog
          open={openCommandDetailsModal}
          onClose={handleCloseCommandDetails}
          scroll="paper"
          container={document.getElementById("modalCommandDetails")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <CoordinateAnalysisCommandDetails data={commandDetails} />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={handleCloseCommandDetails} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModal>
    </>
  );
};

export default CoordinateAnalysisDataTable;
