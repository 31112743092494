import { Alert } from "@shared/interfaces/alert.interface";

// Action types
export enum AlertsTypes {
  SET_ALERTS = "@screen/SET_ALERTS",
  REMOVE_ALERTS = "@screen/REMOVE_ALERTS",
  SET_ALERTS_FILTERED = "@screen/SET_ALERTS_FILTERED"
}

// Data type
export interface AlertsStateObj {
  list: Alert[];
  loading: boolean;
}

// State type
export interface AlertsState {
  all: AlertsStateObj;
  filtered?: AlertsStateObj;
}
