import React, { useCallback, useRef, useState } from "react";
import { HardwareModels } from "@shared/constants/hardware-models.enum";

// region Libraries
import * as Yup from "yup";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
import { Button, Slider } from "@material-ui/core";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, VehicleBlockMessages, YupMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
import { FormHandles } from "@unform/core";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Interfaces
// region Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Hooks
// region Components
import InputPassword from "@atoms/Login/InputPassword";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
// endregion Components
// region Constants
import { HardwareTypesID } from "@shared/constants/hardware-types.enum";
import { CommandType } from "@shared/types/command_type";
// endregion Constants
// region Services
import api from "@services/api";
// endregion Services
// region Styles
import { ReactComponent as OpenLock } from "@assets/icons/openLock.svg";
import { ReactComponent as ClosedLock } from "@assets/icons/closedLock.svg";
import { ReactComponent as UnblockVehicleIcon } from "@assets/icons/unblocked-vehicle.svg";
import { ReactComponent as BlockVehicleIcon } from "@assets/icons/blocked-vehicle.svg";
import * as Styled from "./styles";
// endregion Styles

interface IVehicleBlockRequestData {
  idVehicle: string;
  userMail: string;
  userPassword: string;
  justification: string;
  action: CommandType;
  hardwareModel: HardwareModels
}

type TVehicleBlockFormProps = {
  isBlocked: boolean;
  vehicle: Vehicle;
  onSuccessfulCommand: () => void;
}

const VehicleBlockForm: React.FC<TVehicleBlockFormProps> = ({
  isBlocked, vehicle, onSuccessfulCommand
}) => {

  // region States
  const [sliderValue, setSliderValue] = useState(0);
  const [isBlockButtonDisabled, setIsBlockButtonDisabled] = useState(true);
  const [openDialogConfirmBlock, setOpenDialogConfirmBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blockRequestData, setBlockRequestData] = useState<IVehicleBlockRequestData>();
  // endregion States
  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Hooks
  const { addToast } = useToast();
  const { t } = useTranslation();
  const { user } = useAuth();
  // endregion Hooks
  // region Constants
  const formRef = useRef<FormHandles>(null);

  const responsibilityTerms = t(VehicleBlockMessages.responsibilityTerms);
  // endregion Constants

  interface FormData {
    justification: string,
    password: string
  }

  // region Functions

  /**
   * Reset security method to block or unblock vehicle
   */
  const restartSliderAndButton = useCallback(() => {

    setTimeout(() => {
      setIsBlockButtonDisabled(true);
      setSliderValue(0);
    }, 100);

  }, [setSliderValue, setIsBlockButtonDisabled]);

  /**
   * Change security slider value
   * @param event Event of DOM element
   * @param value Value of slider
   */
  const changeSliderValue = useCallback((event, value) => {
    setSliderValue(value);
  }, [setSliderValue]);

  /**
   * Treatment to allow or not block vehicle
   * @param event Event of DOM element
   * @param inputValue Value of slider
   */
  const handleSliderInput = useCallback((event, inputValue) => {

    setSliderValue(inputValue);

    // If the slider input is not "100", restart slider to zero and keep button disabled
    if (inputValue === 100) setIsBlockButtonDisabled(false);
    else restartSliderAndButton();

  }, [setIsBlockButtonDisabled, setSliderValue, restartSliderAndButton]);

  /**
   * Block or unblock vehicle
   */
  const handleBlock = useCallback(async (requestData) => {

    try {

      setLoading(true);
      setOpenDialogConfirmBlock(false);

      const { data } = await api.post("vehicles/block", {
        id_vehicle: requestData!.idVehicle,
        user_mail: requestData!.userMail,
        user_password: requestData!.userPassword,
        justification: requestData!.justification,
        action: requestData!.action,
        hardwareModel: requestData!.hardwareModel
      });

      if (data.status === "success") onSuccessfulCommand();
      else addToast({ type: "error", title: t(GlobalMessages.error), description: data.message });

    } catch (error) {
      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoading(false);
    }

  }, [addToast, onSuccessfulCommand, t]);

  /**
   * Submit form to block or unblock vehicle
   * @param formData Form data
   */
  const handleSubmit = useCallback(async (formData: FormData) => {

    try {

      // Define the validation types
      const schema = Yup.object().shape({
        justification: Yup
          .string()
          .required(t(YupMessages.justificationRequired))
          .min(15, t(YupMessages.justificationMinLength15)),
        password: Yup
          .string()
          .required(t(YupMessages.passwordRequired))
      });

      // Validate inputs
      await schema.validate(formData, { abortEarly: false });

      const hardware = vehicle.hardwares.filter((hardware) => hardware.type.id_hardware_type === HardwareTypesID.RASTREADOR)?.[0];

      const requestData: IVehicleBlockRequestData = {
        idVehicle: vehicle.id_vehicle,
        userMail: user.email,
        userPassword: formData.password,
        justification: formData.justification,
        action: isBlocked ? CommandType.UNBLOCK : CommandType.BLOCK,
        hardwareModel: hardware.model as HardwareModels
      };

      setBlockRequestData(requestData);

      if (!isBlocked) setOpenDialogConfirmBlock(true);
      else await handleBlock(requestData);

    } catch (error) {
      restartSliderAndButton();
      formRef.current?.setErrors(getValidationErrors(error));
    }

  }, [user.email, vehicle.id_vehicle, vehicle.hardwares, restartSliderAndButton, isBlocked, handleBlock, FormData, t]);

  // endregion Functions

  return (
    <Styled.VehicleBlockForm isMobile={isMobile} isBlocked={isBlocked}>
      <Loading loading={loading} />
      <Form className="form" ref={formRef} onSubmit={handleSubmit}>
        <div className="container-vehicle-block-form__justify">
          <div className="vehicle-block-form__instruction">
            <strong className="strong">2</strong>
            {t(VehicleBlockMessages.justify)}
            {isBlocked ? t(VehicleBlockMessages.unblock) : t(VehicleBlockMessages.block)}
            {t(VehicleBlockMessages.ofTheVehicle)}
          </div>
          <TextField
            className="name"
            label={isBlocked ? t(VehicleBlockMessages.justifyUnblock) : t(VehicleBlockMessages.justifyBlock)}
            margin="dense"
            variant="outlined"
            name="justification"
            fullWidth
            multiline
            rows={10}
          />
        </div>
        <div className="container-vehicle-block-form__password">
          <div className="vehicle-block-form__instruction">
            <strong className="strong">3</strong> {t(VehicleBlockMessages.insertPassword)}
          </div>
          <InputPassword name="password" />
        </div>
        <div className="container-vehicle-block-form__slider">
          <div className="vehicle-block-form__instruction">
            <strong className="strong">4</strong> {t(VehicleBlockMessages.slideToRight)}
          </div>
          {isBlocked ? (
            <div className="vehicle-block-form-slider__icons">
              <ClosedLock style={{ color: "#BEBEBE" }} />
              <OpenLock style={{ color: "#00AD00" }} />
            </div>
          ) : (
            <div className="vehicle-block-form-slider__icons">
              <OpenLock style={{ color: "#BEBEBE" }} />
              <ClosedLock style={{ color: "#F00" }} />
            </div>
          )}
          <Slider
            value={sliderValue}
            onChange={changeSliderValue}
            onChangeCommitted={handleSliderInput}
          />
          <Button
            className="block_button"
            type="submit"
            disableRipple
            startIcon={isBlocked
              ? <UnblockVehicleIcon width="2rem" />
              : <BlockVehicleIcon width="2rem" />}
            disabled={isBlockButtonDisabled}
            variant="contained"
          >
            {isBlocked ? t(VehicleBlockMessages.toUnblock) : t(VehicleBlockMessages.toBlock)} {t(VehicleBlockMessages.vehicle)}
          </Button>
        </div>
      </Form>
      <DialogConfirmAction
        open={openDialogConfirmBlock}
        onClose={() => setOpenDialogConfirmBlock(false)}
        title={t(VehicleBlockMessages.blockConfirmationTitle)}
        actions={[
          { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirmBlock(false) },
          { text: t(GlobalMessages.yes).toUpperCase(), action: () => handleBlock(blockRequestData) }
        ]}
      >
        {responsibilityTerms}<br /><br />
        {t(VehicleBlockMessages.blockConfirmationMessage)}
      </DialogConfirmAction>
    </Styled.VehicleBlockForm>
  );
};

export default VehicleBlockForm;
