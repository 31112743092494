import {
  TranslationTravelAlertFullSpeedMessage,
  TravelAlertFullSpeedMessage
} from "../interfaces";

export const messages: TranslationTravelAlertFullSpeedMessage = {
  [TravelAlertFullSpeedMessage.youAreAboutFullSpeedTravel]: "You are about to create a ",
  [TravelAlertFullSpeedMessage.alertOfFullSpeed]: "speed limit alert ",
  [TravelAlertFullSpeedMessage.alertToVehicle]: "for a vehicle",
  [TravelAlertFullSpeedMessage.subtitleOnlyOneAlert]: "You can set only one alert per trip.",
  [TravelAlertFullSpeedMessage.checkVehicle]: "Check the vehicle to create the alert.",
  [TravelAlertFullSpeedMessage.setSpeed]: "Set the maximum speed limit.",
  [TravelAlertFullSpeedMessage.SpeedInput]: "Speed limit for alert",
  [TravelAlertFullSpeedMessage.infoAlertSpeed]: "The maximum speed limit alert is informational only. When exceeded "
  + "during the trip, the maximum speed on the card and in the vehicle profile appears in red. The list of vehicles that "
  + "exceeded the speed limit can be accessed in the top menu of the production screen.",
  [TravelAlertFullSpeedMessage.infoOnceAlert]: "The speed limit alert can be set only once per trip.",
  [TravelAlertFullSpeedMessage.createAlertSpeedTravel]: "create alert ",
  [TravelAlertFullSpeedMessage.limitFullSpeed]: "limit alert",
  [TravelAlertFullSpeedMessage.speed]: "speed",
  [TravelAlertFullSpeedMessage.confirmFullSpeedTravel]: "You are setting a speed limit of: ",
  [TravelAlertFullSpeedMessage.createFullSpeedTravel]: "create speed alert",
  [TravelAlertFullSpeedMessage.configuredSpeed]: "Full speed has been set.",
  [TravelAlertFullSpeedMessage.configuredAlertDate]: "This vehicle's maximum speed limit alert has been set to ",
  [TravelAlertFullSpeedMessage.alreadyFullSpeed]: "There is already a ",
  [TravelAlertFullSpeedMessage.toThisVehicle]: "for this vehicle",
  [TravelAlertFullSpeedMessage.OnlySetByTravel]: "You can set up a single alert for each travel",
  [TravelAlertFullSpeedMessage.vehicleWithAlert]: " Vehicle with maximum speed alert.",
  [TravelAlertFullSpeedMessage.dayOfSetFullSpeed]: " of the day ",
  [TravelAlertFullSpeedMessage.alertCount]: " The full speed limit for this trip has been exceeded ",
  [TravelAlertFullSpeedMessage.timesAlertCount]: " times ",
  [TravelAlertFullSpeedMessage.exceded]: " exceeded ",
  [TravelAlertFullSpeedMessage.normal]: " normal "
};
