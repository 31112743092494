/* eslint-disable import/prefer-default-export */
import { LoginMessages, TranslationLoginMessages } from "../interfaces";

export const messages: TranslationLoginMessages = {

  /* region Frontend messages */
  [LoginMessages.goToLoginPage]: "Ir para a página de login",
  [LoginMessages.expiredSession]: "Sua sessão expirou",
  [LoginMessages.expiredSessionInstructions]: "Realize novamente o login com seu usuário de acesso para continuar a utilizar o sistema com segurança",
  [LoginMessages.login]: "Entrar",
  [LoginMessages.loginWithMicrosoft]: "Entrar com Microsoft",
  [LoginMessages.loginOptionsText]: "Ou entre com",
  [LoginMessages.forgotMyPassword]: "Esqueci minha senha",
  [LoginMessages.recoverPassword]: "Recuperar senha",
  [LoginMessages.redirecting]: "Redirecionando para acesso...",
  [LoginMessages.sending]: "Enviando...",
  [LoginMessages.passwordConfirmation]: "Confirmação de senha",
  [LoginMessages.resettingPassword]: "Redefinindo...",
  [LoginMessages.resetPassword]: "Redefinir",
  [LoginMessages.resetLinkExpired]: "Link de redefinição expirado",
  [LoginMessages.resetLinkExpiredInstructions]: "O link de redefinição de senha está expirado!",
  [LoginMessages.requestAnotherLink]: "Solicite outro email de redefinição e tente novamente.",
  [LoginMessages.password]: "Senha",
  [LoginMessages.ssoAccessNotEnabled]: "O acesso SSO não está configurado para esta empresa.",
  [LoginMessages.ssoRequiredForLogin]: "O SSO é necessário para o login nesta empresa. Entre com a opção de login abaixo.",
  [LoginMessages.ssoLoginFailed]: "Erro ao iniciar sessão com SSO",
  /* endregion Frontend messages */
  /* region Backend messages */
  [LoginMessages.accessAllowed]: "Acesso permitido",
  [LoginMessages.tokenActivationSuccess]: "Token ativado com sucesso",
  [LoginMessages.tokenDeactivationSuccess]: "Token desativado com sucesso",
  [LoginMessages.tokenDeleteSuccess]: "Token excluído com sucesso",
  [LoginMessages.instructionsEmailSent]: "Email com instruções enviado com sucesso!",
  [LoginMessages.wrongPassword]: "Senha incorreta",
  [LoginMessages.onlyAdminCanGenerateIntegrationToken]: "Apenas usuários administradores podem gerar token de"
  + " integração",
  [LoginMessages.tokenNotRegistered]: "Token não registrado em nosso sistema",
  [LoginMessages.tokenNotLinkedToUserSent]: "O token em questão não pertence ao usuário enviado",
  [LoginMessages.wrongIDFormat]: "ID(s) não se encontra(m) no formato correto"
  /* endregion Backend messages */

};
