import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}
export const Container = styled.div<StylesheetProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 64px;
  height: 100%;

  .headerList {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    .headerTitle {

      .title {
        font-weight: 200;
        width: 100%;
        font-size: 2rem;
      }
      .subtitle {
        margin-top: 5px;
        font-size: 1.2rem;
        color: rgb(0 0 0 / 60%);
      }
    }
  }
  .mFooter {
    width: 100%;
    justify-content: space-between;
  }
  .action {
    display: inline-flex;
    button {
      margin-left: 16px;
      width: 104px;
    }
  }
`;
