import { Reducer } from "redux";
import {
  AggregateTrucksStatesState,
  AggregateTrucksStatesTypes
} from "@store/ducks/Vehicles/AggregateTrucksStates/aggregate-trucks-states.type";

const INITIAL_STATE: AggregateTrucksStatesState = {
  all: {
    list: {
      "Na usina": [],
      "A caminho": [],
      "No destino": [],
      "Retornando": [],
      "Descarregando": []
    },
    loading: true
  },
  filtered: {
    list: {
      "Na usina": [],
      "A caminho": [],
      "No destino": [],
      "Retornando": [],
      "Descarregando": []
    },
    loading: true
  }
};

const reducer: Reducer<AggregateTrucksStatesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AggregateTrucksStatesTypes.SET_AGGREGATE_TRUCKS_STATES:
      return { ...INITIAL_STATE, ...action.payload };
    case AggregateTrucksStatesTypes.SET_AGGREGATE_TRUCKS_STATES_ALL:
      return { ...state, all: action.payload };
    case AggregateTrucksStatesTypes.SET_AGGREGATE_TRUCKS_STATES_FILTERED:
      return { ...state, filtered: action.payload };
    default:
      return state;
  }
};

export default reducer;
