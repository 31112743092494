/* eslint-disable camelcase */
import {
  Entity,
  Column,
  PrimaryGeneratedColumn,
  CreateDateColumn,
  UpdateDateColumn,
  OneToMany,
  ManyToMany, JoinTable
} from "typeorm";
import { Alert } from "./reg_alerts.entity";
import { User } from "./reg_users.entity";

@Entity("tpe_alerts_types")
export class TpeAlert {

  @PrimaryGeneratedColumn("uuid")
    id_alert_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
    description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Alert, (alerts) => alerts.type)
    alerts?: Alert[];

  @ManyToMany(() => User, (user) => user.alerts_permissions)
  @JoinTable({
    name: "rel_users_alerts_permissions",
    joinColumn: {
      name: "id_alert_type_fk",
      referencedColumnName: "id_alert_type"
    },
    inverseJoinColumn: {
      name: "id_user_fk",
      referencedColumnName: "id_user"
    }
  })
    users?: User[];

}
