/* eslint-disable max-len */
import { LocationsModuleMessages, TranslationLocationsModuleMessages } from "../interfaces";

export const messages: TranslationLocationsModuleMessages = {

  /* region Frontend messages */
  [LocationsModuleMessages.name]: "Nome",
  [LocationsModuleMessages.type]: "Tipo",
  [LocationsModuleMessages.address]: "Logradouro",
  [LocationsModuleMessages.number]: "Número",
  [LocationsModuleMessages.district]: "Bairro",
  [LocationsModuleMessages.county]: "Cidade",
  [LocationsModuleMessages.state]: "Estado",
  [LocationsModuleMessages.zipCode]: "CEP",
  [LocationsModuleMessages.regional]: "Regional",
  [LocationsModuleMessages.noNumber]: "S/N",
  [LocationsModuleMessages.noNameRegistered]: "Sem nome cadastrado",
  [LocationsModuleMessages.dataTableTitle]: "locais",
  [LocationsModuleMessages.deleteConfirmationTitle]: "Confirmação de exclusão",
  [LocationsModuleMessages.deleteConfirmationText]: "Deseja realmente excluir o local",
  [LocationsModuleMessages.searching]: "Buscando locais...",
  [LocationsModuleMessages.formZipCodeIncomplete]: "CEP incompleto",
  [LocationsModuleMessages.formExternalIdAlreadyRegistered]: "ID externo já cadastrado",
  [LocationsModuleMessages.formTypeRequired]: "O tipo é obrigatório",
  [LocationsModuleMessages.formAddressRequired]: "O logradouro é obrigatório",
  [LocationsModuleMessages.formDistrictRequired]: "O bairro é obrigatório",
  [LocationsModuleMessages.formCountyRequired]: "O município é obrigatório",
  [LocationsModuleMessages.formNameRequired]: "O nome é obrigatório",
  [LocationsModuleMessages.formStateRequired]: "O estado é obrigatório",
  [LocationsModuleMessages.toastCoordsNotDefinedTitle]: "Coordenadas não definidas",
  [LocationsModuleMessages.toastCoordsNotDefinedDescription]: "Defina as coordenadas as setando no mapa ou buscando"
  + " pelo CEP",
  [LocationsModuleMessages.toastNoZipCodeDataFoundDescription]: "Nenhum dado encontrado para o cep",
  [LocationsModuleMessages.toastSelectLocationInMapDescription]: "Selecione um local no mapa",
  [LocationsModuleMessages.toastDataLoadedDescription]: "Dados carregados com sucesso!",
  [LocationsModuleMessages.updateConfirmationTitle]: "Confirmação para alteração de dados do endereço",
  [LocationsModuleMessages.updateConfirmationText]: "Deseja alterar os seguintes dados da localização?",
  [LocationsModuleMessages.detailsLocationRegisterTitle]: "Cadastro de locais",
  [LocationsModuleMessages.noNameRegistered]: "Sem nome cadastrado",
  [LocationsModuleMessages.detailsMapPosition]: "Posição no mapa",
  [LocationsModuleMessages.detailsEditLabel]: "Editar",
  [LocationsModuleMessages.externalId]: "ID externo",
  [LocationsModuleMessages.detailsExternalIdHelper]: "Ref. a integrações",
  [LocationsModuleMessages.detailsType]: "Tipo de local",
  [LocationsModuleMessages.detailsRadius]: "Cerca (Raio)",
  [LocationsModuleMessages.detailsRadiusHelper]: "Em metros (m)",
  [LocationsModuleMessages.searching]: "Buscando...",
  [LocationsModuleMessages.detailsSearchZipCode]: "Buscar CEP",
  [LocationsModuleMessages.complement]: "Complemento",
  [LocationsModuleMessages.detailsAllowTravelClosure]: "Permite encerramento de viagem",
  [LocationsModuleMessages.mapButtonConfirmLocation]: "Confirmar localização",
  [LocationsModuleMessages.active]: "Ativo",
  [LocationsModuleMessages.inactive]: "Inativo",
  [LocationsModuleMessages.activeLocation]: "Local ativo",
  /* endregion Frontend messages */
  /* region Backend messages */
  [LocationsModuleMessages.getLocationDataByGoogleSuccess]: "Dados retornados com sucesso",
  [LocationsModuleMessages.getLocationDataByGoogleError]: "Erro ao buscar dados do local",
  [LocationsModuleMessages.readLocationTypeSuccess]: "Tipos de Local(is) retornado(s) com sucesso",
  [LocationsModuleMessages.noLocationTypeFound]: "Nenhum tipo de local cadastrado",
  [LocationsModuleMessages.readLocationTypeError]: "Falha ao buscar tipos de local(is)",
  [LocationsModuleMessages.createLocationSuccess]: "Local(is) cadastrado(s) com sucesso",
  [LocationsModuleMessages.readLocationSuccess]: "Local(is) retornado(s) com sucesso",
  [LocationsModuleMessages.updateLocationSuccess]: "Local alterado com sucesso",
  [LocationsModuleMessages.deleteLocationSuccess]: "Local excluído com sucesso",
  [LocationsModuleMessages.getPlantsLocationsSuccess]: "Usinas retornadas com sucesso",
  [LocationsModuleMessages.createLocationError]: "Falha ao cadastrar local",
  [LocationsModuleMessages.readLocationError]: "Falha ao buscar local(is)",
  [LocationsModuleMessages.noLocationFound]: "Nenhum local cadastrado",
  [LocationsModuleMessages.locationNotFound]: "Local não cadastrado em nosso sistema",
  [LocationsModuleMessages.updateLocationError]: "Falha ao atualizar local",
  [LocationsModuleMessages.deleteLocationError]: "Falha ao excluir local",
  [LocationsModuleMessages.noPlantFound]: "Nenhuma usina cadastrada",
  [LocationsModuleMessages.getPlantsLocationsError]: "Falha ao buscar usinas",
  [LocationsModuleMessages.externalID]: "ID externo",
  [LocationsModuleMessages.createPlantSuccess]: "Usina cadastrada com sucesso",
  [LocationsModuleMessages.createPlantError]: "Falha ao cadastrar usina",
  [LocationsModuleMessages.updatePlantSuccess]: "Usina alterada com sucesso",
  [LocationsModuleMessages.updatePlantError]: "Falha ao alterar usina",
  [LocationsModuleMessages.plantNotFound]: "Usina não cadastrada em nosso sistema",
  [LocationsModuleMessages.deletePlantSuccess]: "Usina excluída com sucesso",
  [LocationsModuleMessages.deletePlantError]: "Falha ao excluir usina",

  /* region Integration messages */
  [LocationsModuleMessages.addressNotFound]: "Endereço(s) não encontrado(s)",
  [LocationsModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) já cadastrado(s)",
  [LocationsModuleMessages.needToInformLocationData]: "Deve ser informado latitude e longitude válidos ou informações do endereço (county, uf, district, ...)",
  [LocationsModuleMessages.noAddressChanges]: "Local não teve mudanças de endereço",
  [LocationsModuleMessages.locationLinkedToAVehicleState]: "Local vinculado a um estado de veículo",
  /* endregion Integration messages */

  /* endregion Backend messages */
  /* region Location Types */
  [LocationsModuleMessages.plant]: "Usina",
  [LocationsModuleMessages.construction]: "Obra",
  [LocationsModuleMessages.autoElectricalWorkshop]: "Auto Elétrica",
  [LocationsModuleMessages.tireRepairShop]: "Borracharia",
  [LocationsModuleMessages.gasStation]: "Posto de Combustível",
  [LocationsModuleMessages.workshop]: "Oficina Mecânica",
  [LocationsModuleMessages.parkingLot]: "Estacionamento",
  [LocationsModuleMessages.others]: "Outros",
  [LocationsModuleMessages.supplier]: "Fornecedor",
  [LocationsModuleMessages.client]: "Cliente"
  /* endregion Location Types */
};
