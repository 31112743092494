import React from "react";

// region Imports - External libraries
import { List, ListItem } from "@material-ui/core";
// endregion Imports - External libraries
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
// endregion Imports - Shared
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Styles
import * as Styled from "./styles";
// endregion Imports - Styles

// region Interfaces
interface IReportFilterDetailsProps {
  filterOptions: IParamQueryValues[]
}
// endregion Interfaces

const ReportFilterDetails: React.FC<IReportFilterDetailsProps> = ({ filterOptions }) => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region Functions

  /**
   * Render the paramValue to display in screen
   * @param paramValue The paramValue to be processed
   */
  const renderParamValue = (paramValue: string | string[]) => {
    if (Array.isArray(paramValue)) {
      return (
        <List>
          {paramValue.map((value) => (
            <ListItem className="list-item" key={value}>
              <Styled.FiberManualRecordIcon /> <span> {value} </span>
            </ListItem>
          ))}
        </List>
      );
    }

    return paramValue;
  };

  /**
   * Get formatted date paramValue
   * @param paramOption The paramOption to be processed
   */
  const getFormattedDate = (paramOption: IParamQueryValues) => {
    if (!isPeriodParam(paramOption.paramName)) return paramOption.paramValue;

    return Array.isArray(paramOption.paramValue)
      ? paramOption.paramValue.map((date) => utils.formatDateIfHave(date, "fullDate")) as string[]
      : utils.formatDateIfHave(paramOption.paramValue, "fullDate") as string;
  };

  /**
   * Check if the param is a period param
   * @param paramName The paramName to be checked
   */
  const isPeriodParam = (paramName: string) => paramName === t(FilterMessages.optionStartDate) || paramName === t(FilterMessages.optionEndDate);

  // endregion Functions
  return (
    <Styled.Container>
      {filterOptions.map((option: IParamQueryValues) => (
        <Styled.DetailsItem key={option.paramName}>
          <div className="filter-details__first">
            {option.paramName}
          </div>
          <div className="filter-details__second">
            {renderParamValue(getFormattedDate(option))}
          </div>
        </Styled.DetailsItem>
      ))}
    </Styled.Container>
  );
};

export default ReportFilterDetails;
