import styled from "styled-components";

const ContainerTravelSwap = styled.div`
  
`;
const ContainerActions = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

export {
  ContainerTravelSwap,
  ContainerActions
};
