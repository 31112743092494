import {
  ToastMessages,
  TranslationToastMessages
} from "../interfaces";

export const messages: TranslationToastMessages = {
  [ToastMessages.success]: "Sucesso!",
  [ToastMessages.alert]: "Alerta!",
  [ToastMessages.error]: "Erro",
  [ToastMessages.connectionNotEstablished]: "Conexão não estabelecida"
}
