export enum ParamsMessages {
  /* region Backend messages */
  readParamsSuccess = "paramsModule.readParamsSuccess",
  noParamsFound = "paramsModule.noParamsFound",
  readParamsError = "paramsModule.readParamsError",
  updateParamsSuccess = "paramsModule.updateParamsSuccess",
  updateParamsError = "paramsModule.updateParamsError",
  insertDataBlockSuccess = "paramsModule.insertDataBlockSuccess",
  insertDataBlockError = "paramsModule.insertDataBlockError"

  /* endregion Backend messages */
}

export type TranslationParamsMessages = {
  [key in ParamsMessages]: string;
}
