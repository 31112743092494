import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-content: flex-start;
  padding-bottom: 20px !important;

  &.page {
    padding-top: 1rem;
  }

  @media (max-width: 800px) { // Mobile
    padding-bottom: 0;
  }

  .titleHeader {
    padding: 10px 20px 0 20px;

    .title {
      font-weight: 200;
      width: 100%;
      font-size: 2rem;
    }
    .subtitle {
      margin-top: 5px;
      font-size: 1.2rem;
      color: rgb(0 0 0 / 60%);
    }
  }
`;
