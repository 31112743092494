/* eslint-disable import/prefer-default-export */
import { TimelineVehicleStatesMessages, TranslationTimelineVehicleStatesMessages } from "../interfaces";

export const messages: TranslationTimelineVehicleStatesMessages = {
  [TimelineVehicleStatesMessages.statesTitle]: "States",
  [TimelineVehicleStatesMessages.manometer]: "Manometer",
  [TimelineVehicleStatesMessages.blockedVehicle]: "Locked vehicle",
  [TimelineVehicleStatesMessages.blockingDate]: "Lock date",
  [TimelineVehicleStatesMessages.postUnloadIdleTime]: "Post-unload idleness"
};
