/* eslint-disable camelcase */

import { Column, Entity, OneToOne, PrimaryGeneratedColumn } from "typeorm";
import { Vehicle } from "./reg_vehicles.entity";

@Entity("reg_vehicle_current_infos")
export class VehicleCurrentInfo {

  @PrimaryGeneratedColumn("uuid")
    id_vehicle_current_info?: string;

  @Column({ type: "boolean", nullable: true })
    can_engine?: boolean;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_odometer?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_total_fuel_used?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_speed?: number;

  @Column({ type: "numeric", nullable: true })
    can_engine_rpm?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_engine_coolant_temperature?: number;

  @Column({ type: "varchar", nullable: true })
    can_fuel_consumption?: string;

  @Column({ type: "varchar", nullable: true })
    can_fuel_level?: string;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_total_engine_hours?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_total_driving_time?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    can_total_idle_fuel_used?: number;

  @Column({ type: "numeric", nullable: true })
    can_gross_combination_vehicle_weight?: number;

  @OneToOne(() => Vehicle, (vehicle) => vehicle.current_info, { nullable: true })
    vehicle?: Vehicle;

}
