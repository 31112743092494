/* eslint-disable import/prefer-default-export */
import { TravelDataMessages, TranslationTravelDataMessages } from "../interfaces";

export const messages: TranslationTravelDataMessages = {
  [TravelDataMessages.origin]: "Origem",
  [TravelDataMessages.destination]: "Destino",
  [TravelDataMessages.finalLocation]: "Localização final",
  [TravelDataMessages.taxInvoice]: "Nota fiscal",
  [TravelDataMessages.sealNumber]: "Número do lacre",
  [TravelDataMessages.invoiceIntegrationDate]: "Data de integração da NF",
  [TravelDataMessages.travelStartDate]: "Data de início da viagem",
  [TravelDataMessages.invoiceIssueDate]: "Data de emissão da NF",
  [TravelDataMessages.pieceToBeConcreted]: "Peça a ser concretada",
  [TravelDataMessages.loadVolume]: "Volume da carga",
  [TravelDataMessages.loadExpirationDate]: "Data de vencimento da carga",
  [TravelDataMessages.trace]: "Traço (Referente à carga)",
  [TravelDataMessages.application]: "Aplicação",
  [TravelDataMessages.slump]: "Slump",
  [TravelDataMessages.resistance]: "Resistência",
  [TravelDataMessages.resistanceType]: "Resistência (Tipo)",
  [TravelDataMessages.resistanceComplement]: "Resistência (Complemento)",
  [TravelDataMessages.gravel]: "Brita"
};
