import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

// region Libraries
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as dateFns from "date-fns";
import * as Yup from "yup";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
import MapIcon from "@material-ui/icons/Map";
import utils from "@utils/useful-functions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";
import Green from "@material-ui/core/colors/green";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ClearIcon from "@material-ui/icons/Clear";
import Red from "@material-ui/core/colors/red";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages, FilterMessages,
  GlobalMessages, ToastMessages,
  VehicleBlockMessages,
  VehicleTypesMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { ConfigCommand } from "@shared/interfaces/config-command.interface";
// endregion Interfaces
// region Hooks
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Hooks
// region Components
import api from "@services/api";
import ButtonTable from "@components/Button/ButtonTable";
import { MapFixedPointCoord } from "@components/Map";
import ButtonLoading from "@components/Button/ButtonLoading";
// endregion Components
// region Styles
import { ContainerModalMapMarker } from "@organisms/ModalFormLocation/styles";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import { Container, ContainerFilters } from "./styles";
// endregion Styles

interface BlockListFormData {
  vehicleDescription: string;
  startDate: Date;
  finishDate: Date;
}

const BlockList: React.FC = () => {

  const { addToast } = useToast();

  /* region States */
  // region General states
  const [vehicles, setVehicles] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [blockCommands, setBlockCommands] = useState<ConfigCommand[]>([] as Array<ConfigCommand>);
  const [loadingBlockCommands, setLoadingBlockCommands] = useState(false);
  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  // endregion General states

  // region Filter states
  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  // endregion Filter states

  // region Map control states
  const [blockCommandDetails, setBlockCommandDetails] = useState<ConfigCommand>({} as ConfigCommand);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  // endregion Map control states

  // region Date control states
  const [startDate, setStartDate] = useState<string>(dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00"));
  const [finishDate, setFinishDate] = useState<string>(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59"));
  // endregion Date control states
  /* endregion States */

  /* region Hooks */
  const { t, i18n } = useTranslation();
  /* endregion Hooks */

  /* region Constants */
  /* region DataTable */

  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    { ref: ".block-location", callback: (rowData: ConfigCommand) => handleBlockMap(rowData) }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Dev ID
      title: "DEV ID",
      width: "70px",
      data: (blockCommand: ConfigCommand) => blockCommand?.tracker?.dev_id || "",
      filterable: true,
      propertyName: "tracker.dev_id"
    },
    { // Veículo
      title: t(GlobalMessages.vehicle),
      width: "70px",
      data: (blockCommand: ConfigCommand) => blockCommand?.tracker?.vehicle?.code || "",
      filterable: true,
      propertyName: "tracker.vehicle.code"
    },
    { // Tipo
      title: t(GlobalMessages.type),
      width: "120px",
      data: (blockCommand: ConfigCommand) => (
        ReactDOMServer.renderToString(
          <b style={{ color: blockCommand.type === "BLOCK"
            ? "rgb(230, 74, 25)"
            : "rgb(70, 193, 125)" }}
          >
            {blockCommand.type === "BLOCK" ? t(VehicleBlockMessages.block) : t(VehicleBlockMessages.unblock)}
          </b>
        )
      ),
      filterable: true,
      propertyName: "type"
    },
    { // Usuário
      title: t(VehicleBlockMessages.user),
      width: "120px",
      data: (blockCommand: ConfigCommand) => blockCommand?.user?.name,
      filterable: true,
      propertyName: "user.name"
    },
    { // Data Criação
      title: t(VehicleBlockMessages.creationDate),
      width: "110px",
      data: (blockCommand: ConfigCommand) => (
        blockCommand.registration_date ? utils.formatDateIfHave(blockCommand.registration_date, "fullDate") : ""
      ),
      filterable: true,
      propertyName: "registration_date"
    },
    { // Data envio
      title: t(VehicleBlockMessages.sendDate),
      width: "110px",
      data: (blockCommand: ConfigCommand) => (
        blockCommand.sended_date ? utils.formatDateIfHave(blockCommand.sended_date, "fullDate") : ""
      ),
      filterable: true,
      propertyName: "sended_date"
    },
    { // Data Processamento
      title: t(VehicleBlockMessages.processingDate),
      width: "110px",
      data: (blockCommand: ConfigCommand) => (
        blockCommand.processed_date ? utils.formatDateIfHave(blockCommand.processed_date, "fullDate") : ""
      ),
      filterable: true,
      propertyName: "processed_date"
    },
    { // Status
      title: t(VehicleBlockMessages.status),
      width: "110px",
      data: (blockCommand: ConfigCommand) => {
        const iconStyle = { marginRight: "5px" };
        let icon = <AccessTimeIcon style={{ ...iconStyle, fill: "gray" }} />;
        const { status } = blockCommand;
        let title = "";

        if (status === "PROCESSED") {
          icon = <CheckIcon style={{ ...iconStyle, fill: Green[400] }} />;
          title = t(VehicleBlockMessages.processed);
        } else if (status === "SENDED") {
          icon = <PlayArrowIcon style={{ ...iconStyle, fill: "gray" }} />;
          title = t(VehicleBlockMessages.sent);
        } else if (status === "CANCELLED") {
          icon = <ClearIcon style={{ ...iconStyle, fill: Red[400] }} />;
          title = t(VehicleBlockMessages.cancelled);
        }

        return ReactDOMServer.renderToString(
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{icon} {title}</span>
        );
      },
      filterable: true,
      propertyName: "status"
    },
    { // Justificativa
      title: t(VehicleBlockMessages.justification),
      width: "250px",
      data: (blockCommand: ConfigCommand) => blockCommand?.justification || "",
      filterable: true,
      propertyName: "justification"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      width: "70px",
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button block-location"
              title={t(VehicleBlockMessages.blockLocation)}
              aria-disabled
            >
              <MapIcon />
            </ButtonTable>
          </Grid>
        </Grid>
      ),
      filterable: false
    }];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_bloqueios_${startDate}__${finishDate}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  /* endregion DataTable */

  const loadingVehicleFilter = openVehicleFilter && optionsVehicleFilter.length === 0;

  const formRef = useRef<FormHandles>(null);
  // endregion Constants

  // region Functions
  // Form validations
  const validations = {

    validateForm: async (formData: BlockListFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define the validation types
        const schema = Yup.object().shape({
          vehicleDescription: Yup.string()
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Return parameters to main Component
        await readBlockCommands(formData);

      } catch (error: any) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };

  /** Search blocks according to filters
   * @param formData Data of form filter (Send to request)
   */
  const readBlockCommands = useCallback(async (formData: BlockListFormData) => {

    try {

      let idVehicle;

      // Search the selected vehicle
      vehicles.forEach((vehicle) => {
        if (`${vehicle.code} - ${vehicle.description}` === formData.vehicleDescription) idVehicle = vehicle.id_vehicle;
      });

      const params = {
        startDate: utils.convertDateToISOWithTimezone(new Date(formData.startDate)),
        endDate: utils.convertDateToISOWithTimezone(new Date(formData.finishDate)),
        ...(idVehicle ? { idVehicle } : {})
      };

      // Get the block commands of selected vehicle
      setLoadingBlockCommands(true);
      const { data } = await api.get("hardwares/commands/get-block-commands", { params });

      if (data.status === "success") {

        setBlockCommands(data.result);
        setStartDate(params.startDate);
        setFinishDate(params.endDate);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        if (table.length) table.clear().draw();
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingBlockCommands(false);
    }

  }, [addToast, vehicles, table, t]);

  const handleBlockMap = useCallback((block: ConfigCommand) => {

    setOpenMapModal(true);
    setBlockCommandDetails(block);

  }, []);
  // endregion Functions
  /* region Effects */

  useEffect(() => {

    let active = true;

    if (!loadingVehicleFilter) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read", { params: { linkedHardware: true } });

        if (data.status === "success") {

          setVehicles(data.result);
          if (active) setOptionsVehicleFilter(data.result);

        } else {

          setOpenVehicleFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {
        setOpenVehicleFilter(false);
        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingVehicleFilter, addToast, t]);

  useEffect(() => {
    if (!openVehicleFilter) setOptionsVehicleFilter([]);
  }, [openVehicleFilter]);

  // Update coordinates according to row data (blockCommandDetails)
  useEffect(() => {

    if (blockCommandDetails?.tracker?.alerts && blockCommandDetails.tracker.alerts.length > 0) {
      const lastAlert = blockCommandDetails?.tracker?.alerts?.[blockCommandDetails.tracker.alerts.length - 1];

      if (lastAlert?.event) {
        setLatitude(Number(lastAlert.event.latitude));
        setLongitude(Number(lastAlert.event.longitude));
      }
    }
  }, [blockCommandDetails]);

  // Read initial data
  useEffect(() => {

    const queryParams: BlockListFormData = {
      // eslint-disable-next-line max-len
      startDate: formRef.current?.getFieldValue("startDate") ?? new Date(dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00")),
      finishDate: formRef.current?.getFieldValue("finishDate") ?? new Date(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")),
      vehicleDescription: formRef.current?.getFieldValue("vehicleDescription") ?? ""
    };

    readBlockCommands(queryParams).then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);

  /* endregion Effects */
  return (
    <>
      <Container>
        <ContainerFilters>
          <div className="titleHeader">
            <div className="title">{t(VehicleBlockMessages.blockHistoryTitle)}</div>
            <div className="subtitle">{t(FilterMessages.filterInstructions)}</div>
          </div>
          <Form className="form" ref={formRef} onSubmit={validations.validateForm}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={7} xl={7}>
                  <Autocomplete
                    id="vehicleFilter"
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    getOptionSelected={(option, value) => option.id_vehicle === value.id_vehicle}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    options={optionsVehicleFilter.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                      || -b.code.localeCompare(a.code))}
                    groupBy={(option) => t(VehicleTypesMessages[`${option.type.id_vehicle_type}-plural`])}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicleDescription"
                        label={t(GlobalMessages.vehicle)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="startDate"
                    label={t(FilterMessages.optionStartDate)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="finishDate"
                    label={t(FilterMessages.optionEndDate)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
                  <ButtonLoading
                    className="button-loading"
                    type="submit"
                    loading={loadingBlockCommands}
                  >
                    {t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
        {
          !loadingBlockCommands && (
            <NewDataTable
              title={t(VehicleBlockMessages.blockHistoryTitle)}
              filters
              data={blockCommands}
              columns={dataTableColumns}
              settings={dataTableSettings}
              actions={dataTableActions}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
            />
          )
        }
      </Container>
      <ContainerModalMapMarker id="modalMapMarkerLocation">
        <Dialog
          open={openMapModal}
          onClose={() => { setOpenMapModal(false); }}
          scroll="paper"
          container={document.getElementById("modalMapMarkerLocation")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <MapFixedPointCoord
                mapHeight={500}
                latitude={latitude}
                longitude={longitude}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={() => setOpenMapModal(false)} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModalMapMarker>

    </>
  );
};

export default BlockList;
