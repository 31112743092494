import styled from "styled-components";

const Container = styled.div`
  font-size: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  position: relative;
`;

const Chips = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  transition: all 0.1s ease-in-out;
  position: relative;
  visibility: visible;

  .MuiChip-colorSuccess {
    background-color: ${(props) => props.theme.colors.secondary} !important;
  }
`;

const HiddenChipsIndicator = styled.div`
  z-index: 999;
  font-size: 14px;
  background-color: white;
  padding-left: 2px;
  visibility: visible !important;
  min-width: 34px;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 26px;
  text-align: left;
  line-height: 28px;
  transition: all 0.1s ease-in-out;
`;

export { Container, Chips, HiddenChipsIndicator };
