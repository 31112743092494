import { SettingsMessages, TranslationSettingsMessages } from "../interfaces";

export const messages: TranslationSettingsMessages = {
  [SettingsMessages.settingsTitle]: "Configuraciones",
  [SettingsMessages.parameters]: "Parámetros",
  [SettingsMessages.timeForUndueStopOptionDescription]: "Tiempo necesario para que la parada de un vehículo se considere una parada indebida",
  [SettingsMessages.functionalities]: "Funcionalidades",
  [SettingsMessages.enablePumpTimelineOptionDescription]: "Habilitar mapa de bombas",
  [SettingsMessages.minutes]: "Minutos"
};
