import { CoordinateAnalysisMessages, TranslationCoordinateAnalysisMessages } from "../interfaces";

export const messages: TranslationCoordinateAnalysisMessages = {
  [CoordinateAnalysisMessages.command]: "Comando",
  [CoordinateAnalysisMessages.identifier]: "Identificador",
  [CoordinateAnalysisMessages.model]: "Modelo",
  [CoordinateAnalysisMessages.softwareVersion]: "Versión del software",
  [CoordinateAnalysisMessages.date]: "Fecha",
  [CoordinateAnalysisMessages.time]: "Hora",
  [CoordinateAnalysisMessages.locationCode]: "Código de localización",
  [CoordinateAnalysisMessages.latitude]: "Latitud",
  [CoordinateAnalysisMessages.longitude]: "Longitud",
  [CoordinateAnalysisMessages.speed]: "Velocidad",
  [CoordinateAnalysisMessages.ignition]: "encendido",
  [CoordinateAnalysisMessages.inclination]: "Inclinación",
  [CoordinateAnalysisMessages.activeSatellites]: "Satélites activos",
  [CoordinateAnalysisMessages.validPosition]: "Posicionamiento válido",
  [CoordinateAnalysisMessages.odometer]: "Odómetro",
  [CoordinateAnalysisMessages.batteryVoltage]: "Tensión de la batería",
  [CoordinateAnalysisMessages.inAndOutInputs]: "Inputs de entrada y salida",
  [CoordinateAnalysisMessages.activeMode]: "Modo activo",
  [CoordinateAnalysisMessages.numberOfMessages]: "Número de mensajes",
  [CoordinateAnalysisMessages.hourMeter]: "Horímetro",
  [CoordinateAnalysisMessages.backupBatteryVoltage]: "Tensión de la batería de backup",
  [CoordinateAnalysisMessages.messageType]: "Tipo de mensaje",
  [CoordinateAnalysisMessages.mixerRPM]: "RPM del tambor",
  [CoordinateAnalysisMessages.mixerBatteryVoltage]: "Tensión de la batería del tambor",
  [CoordinateAnalysisMessages.driverIdentifier]: "Identificador del conductor",
  [CoordinateAnalysisMessages.registrationStatus]: "Estado de registro",
  [CoordinateAnalysisMessages.serviceIdentification]: "Identificación de servicio",
  [CoordinateAnalysisMessages.countryCode]: "Código del país",
  [CoordinateAnalysisMessages.networkCode]: "Código de red",
  [CoordinateAnalysisMessages.receptionLevel]: "Nivel de recepción",
  [CoordinateAnalysisMessages.locationAreaCode]: "Código de localización",
  [CoordinateAnalysisMessages.temporalInfo]: "Información temporal",
  [CoordinateAnalysisMessages.GPSStatus]: "GPS encendido/apagado",
  [CoordinateAnalysisMessages.vehicleIdentifier]: "Identificador del vehículo",
  [CoordinateAnalysisMessages.ignitionKey]: "Llave de ignición",
  [CoordinateAnalysisMessages.CANOdometer]: "Odómetro CAN",
  [CoordinateAnalysisMessages.totalFuelConsumption]: "Consumo total de combustible (litros)",
  [CoordinateAnalysisMessages.CANSpeed]: "Velocidad CAN",
  [CoordinateAnalysisMessages.engineRPM]: "RPM del motor",
  [CoordinateAnalysisMessages.engineCoolantTemperature]: "Temperatura del líquido de enfriamiento del motor",
  [CoordinateAnalysisMessages.fuelConsumption]: "Consumo de combustible",
  [CoordinateAnalysisMessages.fuelLevel]: "Nivel de combustible",
  [CoordinateAnalysisMessages.CANHourMeter]: "Horímetro CAN",
  [CoordinateAnalysisMessages.drivingTime]: "Tiempo de conducción",
  [CoordinateAnalysisMessages.fuelConsumptionInIdle]: "Consumo de combustible en marcha lenta (litros)",
  [CoordinateAnalysisMessages.vehicleWeight]: "Peso bruto del vehículo",
  [CoordinateAnalysisMessages.sensorStatus]: "Estado del sensor",
  [CoordinateAnalysisMessages.drumInclination]: "Inclinación del tambor",
  [CoordinateAnalysisMessages.totalWaterAdded]: "Total de agua agregada",
  [CoordinateAnalysisMessages.drumPressure]: "Presión del tambor",
  [CoordinateAnalysisMessages.drumTemperature]: "Temperatura del tambor",
  [CoordinateAnalysisMessages.ignitionOn]: "Conectado",
  [CoordinateAnalysisMessages.ignitionOff]: "Apagado",

  [CoordinateAnalysisMessages.coordinateAnalysisTitle]: "Análisis de coordenadas",
  [CoordinateAnalysisMessages.mapTitle]: "Mapa",
  [CoordinateAnalysisMessages.dataTableTitle]: "Tabla de datos",

  [CoordinateAnalysisMessages.alerts]: "Alertas",
  [CoordinateAnalysisMessages.states]: "Estados",
  [CoordinateAnalysisMessages.noAlertsForThisPeriod]: "No hay alertas para este período",
  [CoordinateAnalysisMessages.commands]: "Comandos",
  [CoordinateAnalysisMessages.noCommandsForThisPeriod]: "No hay comandos para este período",
  [CoordinateAnalysisMessages.noStatesForThisPeriod]: "No hay estados para este período",
  [CoordinateAnalysisMessages.showMarker]: "Destacar marcador",
  [CoordinateAnalysisMessages.travel]: "Viaje",
  [CoordinateAnalysisMessages.travelHelperText]: "Seleccione un vehículo y un período inferior a 3 días",
  [CoordinateAnalysisMessages.startDate]: "Fecha inicial",
  [CoordinateAnalysisMessages.finishDate]: "Fecha final",
  [CoordinateAnalysisMessages.loadingContent]: "Analizando rutas..."

};
