import { ReportStatusType } from "../../types/report_status_type.enum";

export enum ScheduledReportMessages {
  columnDescription = "scheduledReport.column.description",
  columnStatus = "scheduledReport.column.status",
  columnRegistrationDate = "scheduledReport.column.registrationDate",
  columnProcessingDate = "scheduledReport.column.processingDate",
  columnFileSize = "scheduledReport.column.fileSize",
  columnReportType = "scheduledReport.column.reportType",

  statusInQueue = "scheduledReport.status.inQueue",
  statusProcessing = "scheduledReport.status.processing",
  statusProcessed = "scheduledReport.status.processed",
  statusError = "scheduledReport.status.error",
  statusCanceled = "scheduledReport.status.canceled",

  emptyReport = "scheduledReport.emptyReport",

  modalDescriptionTitle = "scheduledReport.modal.descriptionTitle",
  modalDescriptionTitleQuestion = "scheduledReport.modal.descriptionTitleQuestion",
  modalScheduleReportName = "scheduledReport.modal.scheduleReportName",
  modalButtonCancel = "scheduledReport.modal.buttonCancel",
  modalButtonClose = "scheduledReport.modal.buttonClose",
  modalButtonCreate = "scheduledReport.modal.buttonCreate",

  filterDetailsTitle = "scheduledReport.filterDetailsTitle",

  // ScheduledReport backend messages
  readSuccess = "scheduledReport.readSuccess",
  readError = "scheduledReport.readError",
  noScheduledReports = "scheduledReport.noScheduledReports",
  updateSuccess = "scheduledReport.updateSuccess",
  updateError = "scheduledReport.updateError",
  createSuccess = "scheduledReport.createSuccess",
  createError = "scheduledReport.createError",
  cancelSuccess = "scheduledReport.cancelSuccess",
  cancelError = "scheduledReport.cancelError",
  scheduledReportNotFound = "scheduledReport.scheduledReportNotFound",
  renewLinkSuccess = "scheduledReport.renewLinkSuccess",
  renewLinkError = "scheduledReport.renewLinkError"
}

export type TranslationScheduledReportMessages = {
  [key in ScheduledReportMessages]: string;
}

export const ReportStatusTypeMessages = {
  [ReportStatusType.IN_QUEUE]: ScheduledReportMessages.statusInQueue,
  [ReportStatusType.PROCESSING]: ScheduledReportMessages.statusProcessing,
  [ReportStatusType.PROCESSED]: ScheduledReportMessages.statusProcessed,
  [ReportStatusType.ERROR]: ScheduledReportMessages.statusError,
  [ReportStatusType.CANCELED]: ScheduledReportMessages.statusCanceled
};
