export enum UsersModuleMessages {
  createUserSuccess = "usersModule.createUserSuccess",
  confirmationEmailSent = "usersModule.confirmationEmailSent",
  createUserError = "usersModule.createUserError",
  readUserSuccess = "usersModule.readUserSuccess",
  noUserFound = "usersModule.noUserFound",
  readUserError = "usersModule.readUserError",
  updateUserSuccess = "usersModule.updateUserSuccess",
  userNotFound = "usersModule.userNotFound",
  updateUserError = "usersModule.updateUserError",
  deleteUserSuccess = "usersModule.deleteUserSuccess",
  deleteUserError = "usersModule.deleteUserError",
  inactiveAccountCheckEmail = "usersModule.inactiveAccountCheckEmail",
  inactiveUser = "usersModule.inactiveUser",
  userNotAdmin = "usersModule.userNotAdmin",
  readUserRestrictedVehiclesSuccess = "usersModule.readUserRestrictedVehiclesSuccess",
  readUserRestrictedVehiclesError = "usersModule.readUserRestrictedVehiclesError",
  loginWithSSOEror = "usersModule.loginWithSSOEror"
}

export type TranslationUsersModuleMessages = {
  [key in UsersModuleMessages]: string;
}
