export enum TravelsModuleMessages {
  createTravelSuccess = "travelsModule.createTravelSuccess",
  createTravelError = "travelsModule.createTravelError",
  readTravelSuccess = "travelsModule.readTravelSuccess",
  noTravelFound = "travelsModule.noTravelsFound",
  readTravelError = "travelsModule.readTravelError",
  updateTravelSuccess = "travelsModule.updateTravelSuccess",
  travelNotFound = "travelsModule.travelNotFound",
  updateTravelError = "travelsModule.updateTravelError",
  deleteTravelSuccess = "travelsModule.deleteTravelSuccess",
  deleteTravelError = "travelsModule.deleteTravelError",
  swapTravelsSuccess = "travelsModule.swapTravelsSuccess",
  swapTravelsError = "travelsModule.swapTravelsError",
  getTravelAnalyticsSuccess = "travelsModule.getTravelAnalyticsSuccess",
  getTravelAnalyticsError = "travelsModule.getTravelAnalyticsError",
  getTravelAnalyticsVeryLargeTravel = "travelsModule.getTravelAnalyticsVeryLargeTravel",
  getTravelFilterOptionsSuccess = "travelsModule.getTravelFilterOptionsSuccess",
  noTravelFilterOptionsFound = "travelsModule.noTravelFilterOptionsFound",
  getTravelFilterOptionsError = "travelsModule.getTravelFilterOptionsError",
  getTravelCoordinatesSuccess = "travelsModule.getTravelCoordinatesSuccess",
  getTravelCoordinatesError = "travelsModule.getTravelCoordinatesError",
  noTravelCoordinatesFound = "travelsModule.noCoordinatesFound",

  /* region Integration messages */
  vehicleCurrentInTravel = "travelsModule.vehicleCurrentInTravel",
  originLocationNotRegistered = "travelsModule.originLocationNotRegistered",
  vehicleNotRegistered = "travelsModule.vehicleNotRegistered",
  driverNotRegistered = "travelsModule.driverNotRegistered",
  destinationLocationNotRegistered = "travelsModule.destinationLocationNotRegistered",
  externalIDAlreadyRegistered = "travelsModule.externalIDAlreadyRegistered",
  invalidDatePeriod = "travelsModule.invalidDatePeriod",
  travelLinkedToAVehicle = "travelsModule.travelLinkedToAVehicle",
  readPositionSuccess = "travelsModule.readPositionSuccess",
  travelFinishedOrNonexistent = "travelsModule.travelFinishedOrNonexistent",
  needToInformIDOrExternalID = "travelsModule.needToInformIDOrExternalID",
  maxSearchInterval = "travelsModule.maxSearchInterval",
  days = "travelsModule.days",
  analyticVehicleData = "travelsModule.analyticVehicleData",
  returned = "travelsModule.returned",
  travelPreviouslyCancelled = "travelsModule.travelPreviouslyCancelled",
  cancelTravelSuccess = "travelsModule.cancelTravelSuccess"
  /* endregion */
}

export type TranslationTravelsModuleMessages = {
  [key in TravelsModuleMessages]: string;
}
