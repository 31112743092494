import { LocationTypesID } from "../../constants/location-types.enum";

export enum LocationsModuleMessages {
  /* region Frontend messages */
  name = "locationsModule.name",
  noNameRegistered = "locationsModule.noNameRegistered",
  type = "locationsModule.type",
  address = "locationsModule.address",
  number = "locationsModule.number",
  district = "locationsModule.district",
  regional = "locationsModule.regional",
  county = "locationsModule.county",
  state = "locationsModule.state",
  zipCode = "locationsModule.zipCode",
  noNumber = "locationsModule.noNumber",
  searching = "locationsModule.searching",
  dataTableTitle = "locationsModule.dataTableTitle",
  deleteConfirmationTitle = "locationsModule.excludeConfirmationTitle",
  deleteConfirmationText = "locationsModule.excludeConfirmationText",
  formZipCodeIncomplete = "locationsModule.formZipCodeIncomplete",
  formExternalIdAlreadyRegistered = "locationsModule.formExternalIdAlreadyRegistered",
  formTypeRequired = "locationsModule.formTypeRequired",
  formNameRequired = "locationsModule.formNameRequired",
  formAddressRequired = "locationsModule.formAddressRequired",
  formDistrictRequired = "locationsModule.formDistrictRequired",
  formCountyRequired = "locationsModule.formCountyRequired",
  formStateRequired = "locationsModule.formStateRequired",
  toastCoordsNotDefinedTitle = "locationsModule.toastCoordsNotDefined",
  toastCoordsNotDefinedDescription = "locationsModule.toastCoordsNotDefinedDescription",
  toastSelectLocationInMapDescription = "locationsModule.toastSelectLocationInMapDescription",
  toastDataLoadedDescription = "locationsModule.toastDataLoadedDescription",
  toastNoZipCodeDataFoundDescription = "locationsModule.toastNoZipCodeDataFoundDescription",
  updateConfirmationTitle = "locationsModule.updateConfirmationTitle",
  updateConfirmationText = "locationsModule.updateConfirmationText",
  detailsLocationRegisterTitle = "locationsModule.detailsLocationRegisterTitle",
  detailsMapPosition = "locationsModule.detailsMapPosition",
  detailsEditLabel = "locationsModule.detailsEditLabel",
  externalId = "locationsModule.externalId",
  detailsExternalIdHelper = "locationsModule.detailsExternalIdHelper",
  detailsType = "locationsModule.detailsType",
  detailsRadius = "locationsModule.detailsRadius",
  detailsRadiusHelper = "locationsModule.detailsRadiusHelper",
  detailsSearchZipCode = "locationsModule.detailsSearchZipCode",
  complement = "locationsModule.complement",
  detailsAllowTravelClosure = "locationsModule.detailsAllowTravelClosure",
  mapButtonConfirmLocation = "locationsModule.mapButtonConfirmLocation",

  /* endregion Frontend messages */
  /* region Backend messages */
  getLocationDataByGoogleSuccess = "locationsModule.getLocationDataByGoogleSuccess",
  getLocationDataByGoogleError = "locationsModule.getLocationDataByGoogleError",
  readLocationTypeSuccess = "locationsModule.readLocationTypeSuccess",
  noLocationTypeFound = "locationsModule.noLocationTypeFound",
  readLocationTypeError = "locationsModule.readLocationTypeError",
  createLocationSuccess = "locationsModule.createLocationSuccess",
  readLocationSuccess = "locationsModule.readLocationSuccess",
  updateLocationSuccess = "locationsModule.updateLocationSuccess",
  deleteLocationSuccess = "locationsModule.deleteLocationSuccess",
  getPlantsLocationsSuccess = "locationsModule.getPlantsLocationsSuccess",
  createLocationError = "locationsModule.createLocationError",
  readLocationError = "locationsModule.readLocationError",
  noLocationFound = "locationsModule.noLocationFound",
  locationNotFound = "locationsModule.locationNotFound",
  updateLocationError = "locationsModule.updateLocationError",
  deleteLocationError = "locationsModule.deleteLocationError",
  noPlantFound = "locationsModule.noPlantFound",
  getPlantsLocationsError = "locationsModule.getPlantsLocationsError",
  externalID = "locationsModule.externalID",
  createPlantSuccess = "locationsModule.createPlantSuccess",
  createPlantError = "locationsModule.createPlantError",
  updatePlantSuccess = "locationsModule.updatePlantSuccess",
  updatePlantError = "locationsModule.updatePlantError",
  plantNotFound = "locationsModule.plantNotFound",
  deletePlantSuccess = "locationsModule.deletePlantSuccess",
  deletePlantError = "locationsModule.deletePlantError",

  /* region Integration messages */
  addressNotFound = "locationsModule.addressNotFound",
  externalIDAlreadyRegistered = "locationsModule.externalIDAlreadyRegistered",
  needToInformLocationData = "locationsModule.needToInformLocationData",
  noAddressChanges = "locationsModule.noAddressChanges",
  locationLinkedToAVehicleState = "locationsModule.locationLinkedToAVehicleState",
  /* endregion Integration messages*/
  /* endregion Backend messages */

  /* region Location Types */
  plant = "locationsModule.plant",
  construction = "locationsModule.construction",
  autoElectricalWorkshop = "locationsModule.autoElectricalWorkshop",
  tireRepairShop = "locationsModule.tireRepairShop",
  gasStation = "locationsModule.gasStation",
  workshop = "locationsModule.workshop",
  parkingLot = "locationsModule.parkingLot",
  others = "locationsModule.others",
  supplier = "locationsModule.supplier",
  client = "locationsModule.client",
  active = "locationsModule.active",
  inactive = "locationsModule.inactive",
  activeLocation = "locationsModule.activeLocation"
  /* endregion */
}

export type TranslationLocationsModuleMessages = {
  [key in LocationsModuleMessages]: string;
}

export const LocationTypeMessages = {
  [LocationTypesID.USINA]: LocationsModuleMessages.plant,
  [LocationTypesID.OBRA]: LocationsModuleMessages.construction,
  [LocationTypesID.AUTO_ELETRICA]: LocationsModuleMessages.autoElectricalWorkshop,
  [LocationTypesID.BORRACHARIA]: LocationsModuleMessages.tireRepairShop,
  [LocationTypesID.POSTO_DE_COMBUSTIVEL]: LocationsModuleMessages.gasStation,
  [LocationTypesID.OFICINA_MECANICA]: LocationsModuleMessages.workshop,
  [LocationTypesID.ESTACIONAMENTO]: LocationsModuleMessages.parkingLot,
  [LocationTypesID.OUTROS]: LocationsModuleMessages.others,
  [LocationTypesID.FORNECEDOR]: LocationsModuleMessages.supplier,
  [LocationTypesID.CLIENTE]: LocationsModuleMessages.client
};
