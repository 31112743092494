import {
  ToastMessages,
  TranslationToastMessages
} from "../interfaces";

export const messages: TranslationToastMessages = {
  [ToastMessages.success]: "¡Éxito!",
  [ToastMessages.error]: "Error",
  [ToastMessages.alert]: "¡Alerta!",
  [ToastMessages.connectionNotEstablished]: "No se pudo establecer conexión"
}
