import { DualListboxMessages, TranslationDualListboxMessages } from "../interfaces";

export const messages: TranslationDualListboxMessages = {
  [DualListboxMessages.permittedVehiclesHeader]: "Veículos permitidos",
  [DualListboxMessages.restrictedVehiclesHeader]: "Veículos restritos",
  [DualListboxMessages.availableFilterHeader]: "Disponível",
  [DualListboxMessages.moveAllLeft]: "Mover todos para a esquerda",
  [DualListboxMessages.moveAllRight]: "Mover todos para a direita",
  [DualListboxMessages.moveLeft]: "Mover para a esquerda",
  [DualListboxMessages.moveRight]: "Mover para a direita",
  [DualListboxMessages.moveBottom]: "Mover para o final",
  [DualListboxMessages.moveDown]: "Mover para baixo",
  [DualListboxMessages.moveUp]: "Mover para cima",
  [DualListboxMessages.moveTop]: "Mover para o início",
  [DualListboxMessages.noAvailableOptions]: "Sem opções disponíveis",
  [DualListboxMessages.noSelectedOptions]: "Sem opções selecionadas",
  [DualListboxMessages.selectedFilterHeader]: "Selecionado",
  [DualListboxMessages.filterPlaceholder]: "Buscar..."
};
