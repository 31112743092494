/* eslint-disable import/prefer-default-export */
import { VehicleBlockMessages, TranslationVehicleBlockMessages } from "../interfaces";

export const messages: TranslationVehicleBlockMessages = {
  [VehicleBlockMessages.blockedVehicle]: "Vehículo bloqueado",
  [VehicleBlockMessages.checkVehicleToBeBlocked]: "Confira el vehículo a ser bloqueado",
  [VehicleBlockMessages.checkVehicleToBeUnblocked]: "Confira el vehículo a ser desbloqueado",
  [VehicleBlockMessages.blockCommandSent]: "Comando de bloqueo enviado con éxito!",
  [VehicleBlockMessages.unblockCommandSent]: "Comando de desbloqueo enviado con éxito!",
  [VehicleBlockMessages.blockProcessMonitoringInstruction]: "El seguimiento del proceso se puede realizar en la"
  + " pestaña de historial de bloqueos.",
  [VehicleBlockMessages.accessAvailableAt]: "Acceso disponible en",
  [VehicleBlockMessages.blockTabPath]: "Menú - Módulos - Vehículos - Bloqueos",
  [VehicleBlockMessages.youAreAboutTo]: "Estás a punto de ",
  [VehicleBlockMessages.toBlock]: "bloquear ",
  [VehicleBlockMessages.toUnblock]: "desbloquear ",
  [VehicleBlockMessages.aVehicle]: "un vehículo",
  [VehicleBlockMessages.yourActionWill]: "Tu acción irá ",
  [VehicleBlockMessages.vehicleMobilityAndFunctionality]: "la movilidad del vehículo y funciones relacionadas con su"
  + " funcionamiento.",
  [VehicleBlockMessages.responsibilityTerms]: "Topcon recomienda expresamente el uso del bloqueo solo con el vehículo"
  + " parado, de acuerdo con las leyes de tránsito vigentes, y no se responsabiliza, en ningún caso, por cualquier daño"
  + " material y corporal, propio o de terceros, derivado del uso del bloqueo. Al confirmar el comando de bloqueo, hay"
  + " una asunción expresa del cliente en cuanto a los riesgos y responsabilidades!",
  [VehicleBlockMessages.justify]: "Justifique el ",
  [VehicleBlockMessages.ofTheVehicle]: "del vehículo",
  [VehicleBlockMessages.justifyBlock]: "Justifique el bloqueo",
  [VehicleBlockMessages.justifyUnblock]: "Justifique el desbloqueo",
  [VehicleBlockMessages.block]: "bloqueo ",
  [VehicleBlockMessages.unblock]: "desbloqueo ",
  [VehicleBlockMessages.insertPassword]: "Ingrese su contraseña de usuario TopconFLEET",
  [VehicleBlockMessages.slideToRight]: "Deslice hacia la derecha",
  [VehicleBlockMessages.vehicle]: "vehículo",
  [VehicleBlockMessages.blockConfirmationTitle]: "Confirmación de bloqueo",
  [VehicleBlockMessages.blockConfirmationMessage]: "¿Realmente desea bloquear el vehículo?",
  [VehicleBlockMessages.user]: "Usuario",
  [VehicleBlockMessages.creationDate]: "Creación",
  [VehicleBlockMessages.sendDate]: "Envío",
  [VehicleBlockMessages.processingDate]: "Procesamiento",
  [VehicleBlockMessages.status]: "Status",
  [VehicleBlockMessages.processed]: "Procesado",
  [VehicleBlockMessages.sent]: "Enviado",
  [VehicleBlockMessages.cancelled]: "Cancelado",
  [VehicleBlockMessages.justification]: "Justificación",
  [VehicleBlockMessages.blockLocation]: "Local del bloqueo",
  [VehicleBlockMessages.blockHistoryTitle]: "Historial de bloqueos"
};
