import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;

  .logoSVG {
    text-align: center;
    & svg {
      width: 100%;
    }
  }
`;

export default LogoContainer;
