import { SIMcardsModuleMessages, TranslationSIMCardsModuleMessages } from "../interfaces";

export const messages: TranslationSIMCardsModuleMessages = {

  /* region Backend messages */
  [SIMcardsModuleMessages.createSIMCardSuccess]: "SIM card registered successfully",
  [SIMcardsModuleMessages.createSIMCardError]: "Failed to register SIM card",
  [SIMcardsModuleMessages.readSIMCardSuccess]: "SIM card returned successfully",
  [SIMcardsModuleMessages.noSIMCardFound]: "No SIM card found",
  [SIMcardsModuleMessages.readSIMCardError]: "Failed to fetch SIM card(s)",
  [SIMcardsModuleMessages.updateSIMCardSuccess]: "SIM card updated successfully",
  [SIMcardsModuleMessages.SIMCardNotFound]: "SIM card not found",
  [SIMcardsModuleMessages.updateSIMCardError]: "Failed to update SIM card",
  [SIMcardsModuleMessages.deleteSIMCardSuccess]: "SIM card deleted successfully",
  [SIMcardsModuleMessages.deleteSIMCardError]: "Failed to delete SIM card",
  /* endregion Backend messages */
  /* region Frontend messages */
  [SIMcardsModuleMessages.company]: "Company",
  [SIMcardsModuleMessages.status]: "Status",
  [SIMcardsModuleMessages.active]: "Active",
  [SIMcardsModuleMessages.inactive]: "Inactive",
  [SIMcardsModuleMessages.deleteConfirmationTitle]: "Delete confirmation",
  [SIMcardsModuleMessages.deleteConfirmationMessage]: "Are you sure you want to delete this SIM card?",
  [SIMcardsModuleMessages.SIMCardRegistration]: "SIM card registration",
  [SIMcardsModuleMessages.activeSIMCard]: "Active SIM card",
  [SIMcardsModuleMessages.plan]: "Plan",
  [SIMcardsModuleMessages.purchaseDate]: "Purchase date",
  [SIMcardsModuleMessages.activationDate]: "Activation date"
  /* endregion Frontend messages */
};
