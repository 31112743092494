/* eslint-disable camelcase, max-classes-per-file */
import {
  Column,
  CreateDateColumn,
  Entity,
  Index,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Event } from "./reg_events.entity";
import { Hardware } from "./reg_hardwares.entity";
import { Travel } from "./reg_travels.entity";
import { User } from "./reg_users.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { VehicleState } from "./reg_vehicles_states.entity";
import { TpeAlert } from "./tpe_alerts_types.entity";
import { SentAlerts } from "./rel_users_sent_alerts.entity";

@Entity("reg_alerts")
export class Alert {

  @PrimaryGeneratedColumn("uuid")
    id_alert?: string;

  @Column({ type: "varchar", nullable: true })
    justification?: string;

  @Column({ type: "timestamp", nullable: true })
    confirmation_date?: Date;

  @ManyToOne(() => TpeAlert, (type) => type.alerts, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_alert_type_fk" })
    type?: TpeAlert;

  @ManyToOne(() => User, (user) => user.alerts, { nullable: true })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

  @Index({ unique: true })
  @OneToOne(() => Event, (event) => event.alert, { nullable: true })
  @JoinColumn({ name: "id_event_fk" })
    event?: Event;

  @ManyToOne(() => Hardware, (hardware) => hardware.alerts, { nullable: true })
  @JoinColumn({ name: "id_hardware_fk" })
    hardware?: Hardware;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.alerts, { nullable: true })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

  @ManyToOne(() => VehicleState, (vehicle_state) => vehicle_state.alerts, { nullable: true, onDelete: "CASCADE" })
  @JoinColumn({ name: "id_vehicle_state_fk" })
    vehicle_state?: VehicleState;

  @ManyToOne(() => Travel, (travel) => travel.alerts, { nullable: true })
  @JoinColumn({ name: "id_travel_fk" })
    travel?: Travel;

  @ManyToMany(() => User, (user) => user.sent_alerts, { nullable: true })
  @JoinTable({
    name: "rel_user_sent_alerts",
    joinColumn: { name: "id_alert_fk", referencedColumnName: "id_alert" },
    inverseJoinColumn: { name: "id_user_fk", referencedColumnName: "id_user" }
  })
    sent_users?: User[];

  @OneToMany(() => SentAlerts, (sent_alerts) => sent_alerts.alert, { nullable: true })
    sent_alerts_history?: SentAlerts[];

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "timestamp", nullable: false })
    occurrence_date?: Date;

  @Column({ type: "varchar", nullable: true })
    note?: string;

}

export class AlertErrors {

  static readonly UNIQUE_CONSTRAINT_ERROR = "3.1.1 (TCON333131)";

}
