import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Driver } from "@shared/interfaces/driver.interface";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
import { AverageSpeed } from "@shared/interfaces/average-speed.interface";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, MenuMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";
// endregion Imports - Organisms

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    data: AverageSpeed[]
  ) => void;
  filterType: string;
}
// endregion Interfaces
const QueryFilterAverageSpeedHistory: React.FC<IProps> = ({ open, onClose, onFilter, filterType }) => {
  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region States
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsVehicleTypeFilter, setOptionsVehicleTypeFilter] = useState<VehicleType[]>([] as Array<VehicleType>);
  const [optionsDriverFilter, setOptionsDriverFilter] = useState<Driver[]>([] as Array<Driver>);

  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingVehicleTypeFilter, setLoadingVehicleTypeFilter] = useState(true);
  const [loadingDriverFilter, setLoadingDriverFilter] = useState(false);

  const [loadingAlerts, setLoadingAlerts] = useState(false);
  // endregion States

  // region Constants
  const paramNameStartDate = "startDate";
  const paramNameEndDate = "finishDate";
  const paramNameVehicle = "idVehicles";
  const paramNameVehicleType = "idVehicleTypes";
  const paramNameDriver = "idDrivers";

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [loadingVehicleFilter, optionsVehicleFilter, t]);

  const vehicleTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleTypeFilter,
    labelName: t(FilterMessages.optionVehicleTypes),
    paramName: paramNameVehicleType,
    values: optionsVehicleTypeFilter.map((vehicleType) => ({ value: vehicleType.id_vehicle_type!, label: vehicleType.description }))
  }), [loadingVehicleTypeFilter, optionsVehicleTypeFilter, t]);

  const driverFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingDriverFilter,
    labelName: t(MenuMessages.drivers),
    paramName: paramNameDriver,
    values: optionsDriverFilter.map((driver) => ({
      value: driver.id_driver,
      label: `${driver.name} - ${driver.cpf}`
    }))
  }), [loadingDriverFilter, optionsDriverFilter, t]);

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: utils.convertDateToISOWithTimezone(defaultEndDate)
    },
    maxRange: "7D"
  };

  const initialParamsFilter: IParamQueryValues[] = [
    { paramName: paramNameStartDate, paramValue: utils.convertDateToISOWithTimezone(defaultStartDate) },
    { paramName: paramNameEndDate, paramValue: utils.convertDateToISOWithTimezone(defaultEndDate) }
  ];
  // endregion Constants

  // region Refs
  const paramsFilterRef = useRef<IParamQueryValues[]>(initialParamsFilter);
  // endregion Refs

  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get("vehicles/read", { params: { linkedHardware: true, onlyLabel: true } });

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  /**
   * Load vehicle type options from the API.
   */
  const loadVehicleTypeOptions = async () => {
    try {

      // Get all alert types
      const { data } = await api.get("vehicles/types/read", { params: { translate: true } });

      if (data.status === "success") {
        setOptionsVehicleTypeFilter(data.result);

        return;
      }

      setOptionsVehicleTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleTypeFilter(false);
    }
  };

  /**
   * Load driver options from the API.
   */
  const loadDriverOptions = async () => {
    try {

      // Get all drivers
      const { data } = await api.get("drivers/read");

      if (data.status === "success") setOptionsDriverFilter(data.result as Driver[]);
      else setOptionsDriverFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingDriverFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[]) => {

    setLoadingAlerts(true);

    paramsFilterRef.current = paramsData;

    try {
      const mappedParams = paramsData.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("travels/get-average-speed", {
        ...mappedParams,
        filterType,
        finished: true
      });

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = validParamsData.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? [] as AverageSpeed[]
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoadingAlerts(false);
    }
  };
  // endregion Functions

  // region Effects

  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions(),
      loadVehicleTypeOptions(),
      loadDriverOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFilter(paramsFilterRef.current).then();
  }, [filterType]);
  // endregion Effects

  return (
    <>
      <Loading loading={loadingAlerts} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.AVERAGE_SPEED_HISTORY}
        onFilter={(paramsData) => handleFilter(paramsData)}
        multipleSelectionOptions={[
          vehicleFilterOptions,
          vehicleTypeFilterOptions,
          driverFilterOptions
        ]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterAverageSpeedHistory;
