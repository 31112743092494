/* eslint-disable camelcase, max-classes-per-file */
import { Column, CreateDateColumn, Entity, JoinColumn, JoinTable, ManyToMany, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { Location } from "./reg_locations.entity";
import { User } from "./reg_users.entity";

@Entity("reg_plants")
export class Plant {

  @PrimaryGeneratedColumn("uuid")
    id_plant?: string;

  @Column({ type: "varchar", nullable: false })
    name?: string;

  @Column({ type: "varchar", nullable: true })
    description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @OneToOne(() => Location, (location) => location.plant, { nullable: false })
  @JoinColumn({ name: "id_location_fk" })
    location?: Location;

  @ManyToMany(() => User, (users) => users.plants)
  @JoinTable({
    name: "rel_users_plants",
    joinColumn: {
      name: "id_plant_fk",
      referencedColumnName: "id_plant"
    },
    inverseJoinColumn: {
      name: "id_user_fk",
      referencedColumnName: "id_user"
    }
  })
    users?: User[];

}

export class PlantsErrors {

  static readonly LOCATION_NOT_FOUND = "3.6.1 (TCON333631)";

}
