export enum RegionalsModuleMessages {
  name = "regionalsModule.name",
  noNameRegistered = "regionalsModule.noNameRegistered",
  active = "regionalsModule.active",
  inactive = "regionalsModule.inactive",
  externalId = "regionalsModule.externalId",
  registrationDate = "regionalsModule.registrationDate",
  updatedDate = "regionalsModule.updatedDate",
  code = "regionalsModule.code",
  deleteConfirmationText = "regionalsModule.deleteConfirmationText",
  deleteRegionalError = "regionalsModule.deleteRegionalError",

  updateRegionalError = "regionalsModule.updateRegionalError",
  readRegionalSuccess = "regionalsModule.readRegionalSuccess",
  noRegionalFound = "regionalsModule.noRegionalFound",
  updateRegionalSuccess = "regionalsModule.updateRegionalSuccess",
  createRegionalSuccess = "regionalsModule.createRegionalSuccess",
  createRegionalError = "regionalsModule.createRegionalError",
  deleteRegionalSuccess = "regionalsModule.deleteRegionalSuccess"

}
export type TranslationRegionalsModuleMessages = {
  [key in RegionalsModuleMessages]: string;
}
