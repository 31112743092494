import styled from "styled-components";

interface Props {
  mapHeight: string;
}

export const ContainerMapAllVehiclesCoords = styled.div<Props>`

@media (min-width: 800px) { // Desktop

  .gm-ui-hover-effect {
    z-index: 1000;
  }

  .map {
    height: ${(props) => props.mapHeight};
    overflow: hidden;
  }
  .vehicles {
    height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
  }
  .filter {
    .input {
      width: calc(100% - 14px) !important;
      margin-left: 14px;
    }
  }
}

.vehicle-summary__accordion {
  width: 100% !important;
  margin-bottom: 0px !important;
}

.filter {

  .gm-style-iw-d { // Google info window
    padding: 20px !important;
  }
  .input {
    width: 100%;
    margin-bottom: 10px;
  }
  .vehicles {

    .MuiAccordion-root {
      width: 100%;
      box-shadow: none;
      margin-top: 0;

      .MuiAccordionSummary-root {
        min-height: auto;

        .MuiAccordionSummary-content {
          margin: 5px 0 0 0 !important;
        }
      }
      .MuiAccordionDetails-root {
        display: block;
      }
    }
  }
}
`;
