import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IMultipleSelectionOptions,
  IOnFilterReturn,
  ISimpleInputOptions
} from "@organisms/GenericQueryFilter";
import { IntegrationLogs } from "@shared/interfaces/integrations-log.interface";
// endregion Imports - Organisms

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    logsData: IntegrationLogs[],
    loadMore: boolean,
    startDate: string,
    finishDate: string
  ) => void;
  loadMoreToggle: boolean;
}
// endregion Interfaces
const QueryFilterIntegrationsReport: React.FC<IProps> = ({ open, onClose, onFilter, loadMoreToggle }) => {
  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region States
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);

  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingLogs, setLoadingLogs] = useState(false);

  // endregion States

  // region Constants
  const paramNameStartDate = "startDate";
  const paramNameEndDate = "finishDate";
  const paramNameVehicle = "idVehicles";
  const paramNameNFs = "numNf";
  const recordLimitPerFetch = 200;

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [loadingVehicleFilter, optionsVehicleFilter, t]);

  const numNfFilterOptions: ISimpleInputOptions ={
    labelName: "numNf",
    paramName: paramNameNFs,
    values: []
  };

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: utils.convertDateToISOWithTimezone(defaultEndDate)
    },
    maxRange: "7D",
    maxPeriod: "30D"
  };

  const initialParamsFilter: IParamQueryValues[] = [
    { paramName: paramNameStartDate, paramValue: utils.convertDateToISOWithTimezone(defaultStartDate) },
    { paramName: paramNameEndDate, paramValue: utils.convertDateToISOWithTimezone(defaultEndDate) }
  ];
  // endregion Constants

  // region Refs
  const paramsFilterRef = useRef<IParamQueryValues[]>(initialParamsFilter);
  const offsetRef = useRef<number|undefined>(undefined);
  // endregion Refs

  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get("vehicles/read", { params: { linkedHardware: true, onlyLabel: true } });

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[], loadMore: boolean) => {

    setLoadingLogs(true);

    paramsFilterRef.current = paramsData;

    if (loadMore) offsetRef.current! += recordLimitPerFetch;
    else offsetRef.current = 0;

    try {
      const mappedParams = {
        ...paramsData.reduce((acc, param) => {
          acc[param.paramName] = param.paramValue;

          return acc;
        }, {} as { [key: string]: string | string[] })
      };

      const { data } = await api.post("logs/get-logs", {
        ...mappedParams,
        offset: offsetRef.current,
        limit: recordLimitPerFetch
      });

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = validParamsData.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      const startDateValue = validParamsData.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
      const finishDateValue = validParamsData.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;


      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? [] as IntegrationLogs[],
        loadMore,
        startDateValue,
        finishDateValue
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);

      return { status: "error" } as IOnFilterReturn;
    }
    finally {
      setLoadingLogs(false);
    }
  };
  // endregion Functions

  // region Effects
  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offsetRef.current === undefined) {
      offsetRef.current = 0;

      handleFilter(paramsFilterRef.current, false).then();

      return;
    }

    handleFilter(paramsFilterRef.current, true).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreToggle]);
  // endregion Effects

  return (
    <>
      <Loading loading={loadingLogs} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.INTEGRATION_REPORT}
        onFilter={(paramsData) => handleFilter(paramsData, false)}
        multipleSelectionOptions={[
          vehicleFilterOptions
        ]}
        simpleInputOptions={[numNfFilterOptions]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterIntegrationsReport;
