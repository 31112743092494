import React from "react";
import { ReactSVG } from "react-svg";

import LogoFleet from "@assets/FleetLogo/loginLogo.svg";

import LogoContainer from "./styles";

const Logo: React.FC = () => (
  <LogoContainer>
    <ReactSVG className="logoSVG" src={LogoFleet} />
  </LogoContainer>
);

export default Logo;
