import React, { useCallback, useEffect, useRef, useState } from "react";

/* region Libraries */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import { TextField, Checkbox } from "unform-material-ui";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Loading from "react-fullscreen-loading";
import { Form } from "@unform/web";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import * as dateFns from "date-fns";
import ReactDOMServer from "react-dom/server";
/* endregion Libraries */
/* region Languages */
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  SIMcardsModuleMessages,
  ToastMessages,
  YupMessages
} from "@shared/languages/interfaces";
/* endregion Languages */
/* region Services */
import api from "@services/api";
/* endregion Services */
/* region Interfaces */
import { SimCard } from "@shared/interfaces/sim-card.interface";
/* endregion Interfaces */
/* region Components */
import ButtonTable from "@components/Button/ButtonTable";
import Header from "@components/Header";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
/* endregion Components */
/* region Store */
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
/* endregion Store */
/* region Utils */
import utils from "@utils/useful-functions";
/* endregion Utils */
/* region Hooks */
import { useToast } from "@hooks/useToast";
import { useSelector } from "react-redux";
import getValidationErrors from "@hooks/getValidationErrors";
/* endregion Hooks */
/* region Styles */
import { Container, ContainerModalFormSimCard } from "./styles";
/* endregion Styles */

const SimCardsList: React.FC = () => {

  /* region States */
  const [simCards, setSimCards] = useState<SimCard[]>([]);
  const [simCardDetails, setSimCardDetails] = useState<SimCard>({} as SimCard);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [isLoadingSimCardsList, setIsLoadingSimCardsList] = useState<boolean>(false);

  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");
  const [openModalFormSimCard, setOpenModalFormSimCard] = useState<boolean>(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>(
    {} as JQuery<HTMLTableRowElement>
  );

  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  /* endregion States */
  /* region Hooks */
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t, i18n } = useTranslation();
  /* endregion Hooks */
  /* region Form */
  const formRef = useRef<FormHandles>(null);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: SimCard, action: "details" | "register" | "update") => {

      try {
        // Define the validation types
        const schema = Yup.object().shape({
          caller_id: Yup.string().trim().required(t(YupMessages.callerIDRequired)),
          iccid: Yup.string().trim().required(t(YupMessages.ICCIDRequired)),
          phone_company: Yup.string().trim().required(t(YupMessages.phoneCompanyRequired)),
          purchase_date: Yup.string().trim().required(t(YupMessages.purchaseDateRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update hardware (According action selected)
        if (action === "register") await createSimCard(formData);
        else await updateSimCard(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-simcard-list-details",
      callback: (rowData: SimCard) => handleSimCardDetails(rowData)
    },
    {
      ref: ".modules-simcard-list-delete",
      callback: (rowData: SimCard) => handleSimCardDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Dev ID
      title: "Caller ID",
      data: (SimCard: SimCard) => SimCard.caller_id,
      defaultContent: "",
      filterable: true,
      propertyName: "caller_id"
    },
    { // Tipo de hardware
      title: "ICC ID",
      data: (SimCard: SimCard) => SimCard.iccid,
      filterable: true,
      propertyName: "iccid"
    },
    { // Fornecedor
      title: t(SIMcardsModuleMessages.company),
      data: (SimCard: SimCard) => SimCard.phone_company,
      filterable: true,
      propertyName: "phone_company"
    },
    { // Ativo
      title: t(SIMcardsModuleMessages.status),
      data: (SimCard: SimCard) => (
        ReactDOMServer.renderToString(
          <b style={{ color: SimCard.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>{ SimCard.active
            ? t(SIMcardsModuleMessages.active) : t(SIMcardsModuleMessages.inactive) }
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-simcard-list-details"
            ><FormatListBulletedIcon />
            </ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-simcard-list-delete"
            ><DeleteForeverIcon />
            </ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateSimCard()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readSimCards()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `simcards_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  /* endregion Constants */

  /* region Functions */

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setSimCardDetails({} as SimCard);
    setOpenModalFormSimCard(false);

  }, []);

  const readSimCards = useCallback(async () => {

    try {

      setIsLoadingSimCardsList(true);
      const { data } = await api.get("simcards/read", { params: { linkedHardware: true } });

      if (data.status === "success") setSimCards(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoadingSimCardsList(false);
    }

  }, [addToast, t]);

  const createSimCard = useCallback(async (simCard: SimCard) => {

    try {

      setIsLoadingSimCardsList(true);
      const insertSimCard = { ...simCard };

      Object.keys(insertSimCard).forEach((key) => {
        if (insertSimCard[key].length <= 0) delete insertSimCard[key];
      });

      const { data } = await api.post("simcards/create", insertSimCard);

      if (data.status === "success") {
        handleCloseModalForm();
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertSimCard.id_sim_card = data.result.id_sim_card;

        if (!simCards.length) await readSimCards();
        else {
          table.row.add(insertSimCard);
          table.draw();
        }

        // Search the inserted SIM Card and list
        table.search(simCard.caller_id).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoadingSimCardsList(false);
    }

  }, [handleCloseModalForm, readSimCards, simCards, table, addToast, t]);

  const updateSimCard = useCallback(async (simCard: SimCard) => {

    try {

      setIsLoadingSimCardsList(true);
      const updateSimCard = { ...simCard };

      Object.keys(updateSimCard).forEach((key) => {
        if (updateSimCard[key].length <= 0) delete updateSimCard[key];
      });

      const { data } = await api.patch(`simcards/update/${simCardDetails.id_sim_card}`, updateSimCard);

      if (data.status === "success") {
        handleCloseModalForm();
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        Object.assign(simCardDetails, updateSimCard);

        table.row(tableClickedRow).data(simCardDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoadingSimCardsList(false);
    }

  }, [addToast, table, simCardDetails, tableClickedRow, handleCloseModalForm, t]);

  const deleteSimCard = useCallback(async (simCard: SimCard) => {

    try {

      setIsLoadingSimCardsList(true);
      const { data } = await api.delete(`simcards/delete/${simCard.id_sim_card}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoadingSimCardsList(false);
    }

  }, [addToast, table, tableClickedRow, t]);

  // Handle functions
  const handleSimCardDelete = useCallback((simCard: SimCard) => {

    setSimCardDetails(simCard);
    setOpenDialogConfirmDelete(true);

  }, []);

  const handleSimCardDetails = useCallback((simCard: SimCard) => {

    setModalType("details");

    setSimCardDetails(simCard);
    setOpenModalFormSimCard(true);

  }, []);

  /*
   * Open modal to create new driver
   * */
  const FhandleCreateSimCard = useCallback(() => {
    setModalType("register");
    setOpenModalFormSimCard(true);
  }, []);

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
      case "details":
        return detailValue;
      case "update":
        return updateValue;
      case "register":
        return registerValue;
      default:
        return "S/N";
    }
  }, [modalType]);

  const handleActionButton = useCallback(() => {
    if (modalType === "details") {
      return <></>;
    }
    if (modalType === "register") {
      return (
        <Button
          disableRipple
          type="submit"
          color="primary"
          onClick={() => formRef.current?.submitForm()}
        >{t(GlobalMessages.register)}
        </Button>
      );
    }

    return (
      <Button
        disableRipple
        type="submit"
        color="primary"
        onClick={() => formRef.current?.submitForm()}
      >{t(GlobalMessages.edit)}
      </Button>
    );

  }, [formRef, modalType, t]);

  /* endregion Functions */
  /* region Effects */
  useEffect(() => {
    setActiveCheckbox(simCardDetails.active);

  }, [simCardDetails]);

  useEffect(() => {
    readSimCards().then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  /* endregion Effects */

  return (
    <>
      <Header title="SIM Cards" />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={isLoadingSimCardsList} />
        {
          !isLoadingSimCardsList && (
            <NewDataTable
              title="SIM cards"
              filters
              data={simCards}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={() => setOpenDialogConfirmDelete(false)}
          title={t(SIMcardsModuleMessages.deleteConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirmDelete(false) },
            { text: t(GlobalMessages.yes).toUpperCase(), action: () => deleteSimCard(simCardDetails) }
          ]}
        >
          {t(SIMcardsModuleMessages.deleteConfirmationMessage)} <br /><br />
          ICC ID: { simCardDetails?.iccid } <br />
          {t(SIMcardsModuleMessages.company)}: { simCardDetails?.phone_company }?
        </DialogConfirmAction>
        <ContainerModalFormSimCard id="modalFormSimCard">
          <Dialog
            open={openModalFormSimCard}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormSimCard")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  { defineValueAccordingModalType(
                    `${simCardDetails?.iccid} - ${simCardDetails.phone_company}`,
                    `${simCardDetails?.iccid} - ${simCardDetails.phone_company}`,
                    t(SIMcardsModuleMessages.SIMCardRegistration)
                  ) }
                </div>
              </div>
              <div className="actions">
                { modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  ) }
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form
                className="form"
                ref={formRef}
                onSubmit={(formData) => validations.validateForm(formData, modalType)}
              >
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className="checkboxes">
                      <FormControlLabel
                        className="r-checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setActiveCheckbox(event.target.checked)}
                            checked={activeCheckbox}
                            name="active"
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(SIMcardsModuleMessages.activeSIMCard)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        label="Caller ID"
                        name="caller_id"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={t(GlobalMessages.required)}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(simCardDetails?.caller_id,
                          simCardDetails?.caller_id, "")}
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("caller_id")}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        label="ICC ID"
                        name="iccid"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={t(GlobalMessages.required)}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(simCardDetails.iccid, simCardDetails.iccid, "")}
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("iccid")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(SIMcardsModuleMessages.company)}
                        name="phone_company"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={t(GlobalMessages.required)}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(simCardDetails.phone_company,
                          simCardDetails.phone_company, "")}
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("phone_company")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(SIMcardsModuleMessages.plan)}
                        name="plan"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(simCardDetails.plan, simCardDetails.plan, "")}
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("plan")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(SIMcardsModuleMessages.purchaseDate)}
                        name="purchase_date"
                        type="date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={t(GlobalMessages.required)}
                        disabled={modalType === "details"}
                        defaultValue={
                          !_.isEmpty(simCardDetails?.purchase_date)
                          && defineValueAccordingModalType(
                            dateFns.format(utils.getDateUTC(simCardDetails.purchase_date), "yyyy-MM-dd"),
                            dateFns.format(utils.getDateUTC(simCardDetails.purchase_date), "yyyy-MM-dd"),
                            ""
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("purchase_date")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(SIMcardsModuleMessages.activationDate)}
                        name="activation_date"
                        type="date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={modalType === "details"}
                        defaultValue={
                          !_.isEmpty(simCardDetails?.activation_date)
                          && defineValueAccordingModalType(
                            dateFns.format(utils.getDateUTC(simCardDetails.activation_date), "yyyy-MM-dd"),
                            dateFns.format(utils.getDateUTC(simCardDetails.activation_date), "yyyy-MM-dd"),
                            ""
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={() => validations.validateFieldError("activation_date")}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  handleActionButton()
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormSimCard>
      </Container>
    </>

  );

};

export default SimCardsList;
