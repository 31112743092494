import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import styled from "styled-components";

interface StylesheetProps {
  platform: ScreenPlatform;
  selectedTab: number;
}

export const Container = styled.div<StylesheetProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: ${(props) => (props.selectedTab === 1 ? 120 : 64)}px;
  margin-top: ${(prop) => (prop.platform === "mobile" ? "5rem" : "0")};
  padding-top: 40px !important;
  height: 100%;

  .dataTables_scroll .dataTables_scrollBody {
    max-height: calc(100vh - 350px) !important;
  }
`;

export const ContainerModalDataTable = styled.div`
  z-index: 2001;

  .MuiDialog-paper {
    width: 1200px;

    .mContent {

      .MuiTypography-root {
        width: 100%;
      }
      .MuiFormControl-root {
        width: 100%;

        .MuiFormHelperText-root {
          text-align: right;
          margin: 0;
        }
      }
    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  z-index: 500;
  display: flex;
  gap: 10px;
`;

export const CustomButton = styled.button`
  background-color: #002951;
  color: white;
  border-radius: 25px;
  padding: 10px 20px;

  &:hover {
    background-color: #001e3c;
  }
`;
