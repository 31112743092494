import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
}

const className = "travel-speed-header";

export const TravelSpeedHeaderStylesheet = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.isMobile ? "flex-direction: column;" : "")}
  align-items: center;
  flex: 0 0 auto;
  margin: 0;
  padding: ${(props) => (props.isMobile ? "10px" : "16px 24px")};

  .travel-speed-header__title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);

    .iconContainer {
      padding: 10px;
      border-radius: 50%;
      width: ${(props) => (props.isMobile ? "35px" : "50px")};
      height: ${(props) => (props.isMobile ? "35px" : "50px")};
      ${(props) => (props.isMobile ? "display: flex;" : "")}
      ${(props) => (props.isMobile ? "justify-content: center;" : "")}
      color: white;

      .icon {
        svg {
          position: relative;
          width: ${(props) => (props.isMobile ? "25px" : "35px")};
          right: ${(props) => (props.isMobile ? "" : "3px")};
          ${(props) => (props.isMobile ? "bottom: 11px;" : "bottom: 5px;")}
        }
      }
    }



    .content {
      padding-left: 15px;

      ${(props) => (props.isMobile ? "" : "font-weight: 200;")}
      .content__title {
        font-size: ${(props) => (props.isMobile ? "1rem" : " 1.25rem")};
      }

      .strong {
        color: #666666;
      }

      .content__subtitle {
        font-size: ${(props) => (props.isMobile ? "0.8rem" : "1rem")};
        color: #666666;
      }
    }
  }
}
`;
