import styled from "styled-components";

import { windowWidth } from "@styles/global";

import { Drawer } from "@libraries/mui";

const Container = styled(Drawer)`
  z-index: 2;

  > .MuiDrawer-paper {
    background-color: transparent !important;
    width: 350px;

    /* Large than any mobile device */
    @media ( min-width: ${windowWidth.mobile.large} ) {
      width: 400px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 10px;
  transition: all 0.5s;
  overflow: auto;
  overflow-x: hidden;
  gap: 10px;
  background-color: white;

  .apply-filter-button {
    border-radius: 5px !important;
    background-color: ${(props) => props.theme.colors.primary};

    .Mui-disabled {
      background-color: ${(props) => props.theme.colors.gray} !important;
    }
  }

  .save-filter-button {
    background-color: transparent !important;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
    width: 75%;
    min-height: 30px !important;
    align-self: center;
    font-size: 12px;
    box-shadow: none !important;

    :hover {
      text-decoration: underline;
    }

    .Mui-disabled {
      color: ${(props) => props.theme.colors.gray} !important;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px 3px 0 3px;

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

export { Container, Content, Title, FiltersContainer };
