/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-new */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useGoogleMaps } from "react-hook-google-maps";
import { Provider } from "react-redux";
import ReactDOMServer from "react-dom/server";

// region Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, MapMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Libraries
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrafficIcon from "@material-ui/icons/Traffic";
import _ from "lodash";
// endregion Libraries
// region Types and Interface
import { VehicleStatesTypes } from "@shared/constants/vehicle-states-types.enum";
import { GoogleApiRequestTypes } from "@shared/constants/google-api-request-types.enum";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { VehicleState } from "@shared/interfaces/vehicle-state.interface";
// endregion Types and Interface
// region Utils
import mapDefaultParameters from "@utils/parameters";
import utils from "@utils/useful-functions";
// endregion Utils
/* region Services */
import api from "@services/api";
/* endregion Services */
// region Hooks
import { usePrevious } from "@hooks/usePrevious";
import { useAuth } from "@hooks/useAuth";
import AppProvider from "../../../hooks";
// endregion Hooks
// region Components
import TimeLineItem from "../../TimeLines/TimeLineVehicles/TimeLineVehiclesItem";
// endregion Components
// region Store
import store from "../../../store";
import { VehicleStatesList } from "../../../store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
// endregion Store
// region Styles
import { ContainerMapAllVehiclesCoords } from "./styles";
// endregion Styles

interface MarkersVisualizationTypes {
  licensePlate: boolean;
  name: boolean;
}

type MapAllVehiclesCoordsProps = {
  mapHeight: string,
  vehicles: VehicleStatesList,
  markersVisualizationType: MarkersVisualizationTypes
}
const MapAllVehiclesCoords = React.memo<MapAllVehiclesCoordsProps>((
  { mapHeight, vehicles, markersVisualizationType }
) => {

  const GoogleApiKey = localStorage.getItem("@Fleet:google");
  const mapsDefaultConfig = {
    center: mapDefaultParameters.center,
    zoom: 4
  };
  const { ref, map } = useGoogleMaps(
    `${GoogleApiKey}&libraries=places&language=pt-BR`,
    mapsDefaultConfig
  );

  const { t } = useTranslation();
  const { user } = useAuth();

  // Merge all vehicles in one array (Removing repeat and ordering by vehicle code)
  const allVehicles = useMemo<VehicleStatesList>(() => (

    _.groupBy<Vehicle>(
      _.orderBy<Vehicle>(
        _.uniqBy<Vehicle>(Object.keys(vehicles).reduce((acc, cv) => [...acc, ...vehicles[cv]], [] as any[]), "id_vehicle"),
        "code"
      ),
      "type.description"
    )

  ), [vehicles]);

  const vehicleCount = useMemo(() => Object.values(allVehicles).reduce((acc, group) => acc + group.length, 0), [allVehicles]);

  // Traffic Layer States
  const [control, setControl] = useState<HTMLDivElement>();
  const [showTrafficLayer, setShowTrafficLayer] = useState<boolean>(true);
  const [trafficLayer, setTrafficLayer] = useState<google.maps.TrafficLayer | null>(null);

  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [circles, setCircles] = useState<google.maps.Circle[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<VehicleStatesList>({});
  const [activeBoxFilter, setActiveBoxFilter] = useState(false);
  const [contentTextFilter, setContentTextFilter] = useState("");
  const previousActiveBoxFilter = usePrevious(activeBoxFilter);

  const setMarkersValues = useCallback((vehicle: Vehicle) => {

    // Visualization with name and license plate
    if ((markersVisualizationType.licensePlate && markersVisualizationType.name)) {
      return {
        position: {
          lat: Number(vehicle.last_coord?.latitude),
          lng: Number(vehicle.last_coord?.longitude)
        },
        icon: {
          url: utils.getMarkerAccordingVehicleType(
            vehicle,
            vehicle?.states[vehicle?.states?.length - 1]?.status?.id_vehicle_state_type || "",
            markersVisualizationType
          ),
          scaledSize: new google.maps.Size(3 * 40, 3 * 40),
          anchor: new google.maps.Point(16.5, 47.5)
        }
      };

    // Visualization with only name
    } if (markersVisualizationType.name) {
      return {
        position: {
          lat: Number(vehicle.last_coord?.latitude),
          lng: Number(vehicle.last_coord?.longitude)
        },
        icon: {
          url: utils.getMarkerAccordingVehicleType(
            vehicle,
            (vehicle?.states[vehicle?.states?.length - 1]?.status?.id_vehicle_state_type || "") as VehicleStatesTypes,
            markersVisualizationType
          ),
          scaledSize: new google.maps.Size(88, 88),
          anchor: new google.maps.Point(16.5, 47.5)
        }
      };
    // Visualization with only license plate
    } if (markersVisualizationType.licensePlate) {
      return {
        position: {
          lat: Number(vehicle.last_coord?.latitude),
          lng: Number(vehicle.last_coord?.longitude)
        },
        icon: {
          url: utils.getMarkerAccordingVehicleType(
            vehicle,
            (vehicle?.states[vehicle?.states?.length - 1]?.status?.id_vehicle_state_type || "") as VehicleStatesTypes,
            markersVisualizationType
          ),
          scaledSize: new google.maps.Size(3 * 40, 3 * 40),
          anchor: new google.maps.Point(16.5, 47.5)
        }
      };
    }

    // Visualization with just icon
    return {
      position: {
        lat: Number(vehicle.last_coord?.latitude),
        lng: Number(vehicle.last_coord?.longitude)
      },
      icon: {
        url: utils.getMarkerAccordingVehicleType(
          vehicle,
          (vehicle?.states[vehicle?.states?.length - 1]?.status?.id_vehicle_state_type || "") as VehicleStatesTypes,
          markersVisualizationType
        ),
        scaledSize: new google.maps.Size(47.5, 47.5)
      },
      optimized: vehicleCount > 256 ? false : undefined // optimized: true => breaks marker map rendering
    };
  }, [markersVisualizationType, vehicleCount]);

  /** Draw vehicles coords as a custom markers (According vehicle type and vehicle state)
   */
  const drawVehiclesPoints = useCallback(() => {

    const bounds = new google.maps.LatLngBounds();
    const arrFilteredVehicles: string[] = Object.keys(filteredVehicles).reduce((acc, cv) => (
      [...acc, ...filteredVehicles[cv].map((icv) => icv.id_vehicle)] as any
    ), [] as string[]);

    Object.keys(allVehicles).forEach((key) => {
      allVehicles[key].forEach((vehicle) => {

        // Just vehicles with last coord
        if (!_.isEmpty(vehicle.last_coord?.latitude) && !_.isEmpty(vehicle.last_coord?.longitude)) {

          let instancedMarker: google.maps.Marker = {} as google.maps.Marker;

          // Verify if vehicle marker already instanced
          markers.forEach((marker) => {
            if (marker.get("id") === vehicle.id_vehicle) instancedMarker = marker;
          });

          // Verify if vehicle marker already exists
          // IF EXISTS => Update your values (coord, marker, infowindow)
          // IF NOT EXISTS => Create new marker
          if (!_.isEmpty(instancedMarker)) {

            // Change icon and position of marker
            instancedMarker.setValues(setMarkersValues(vehicle));

            // If already have a info window open, re-render
            if (!_.isEmpty(document.getElementById(`map-all-vehicles-${vehicle.id_vehicle}`))) {
              ReactDOM.render(
                <Provider store={store}>
                  <AppProvider>
                    <TimeLineItem vehicles={[vehicle]} showAllVehicles showTimeLine showMap={false} />
                  </AppProvider>
                </Provider>,
                document.getElementById(`map-all-vehicles-${vehicle.id_vehicle}`)
              );
            }

          } else {

            // Set marker on map
            instancedMarker = new google.maps.Marker({
              ...setMarkersValues(vehicle),
              map
            });

            instancedMarker.set("id", vehicle.id_vehicle);
            instancedMarker["infowindow"] = new google.maps.InfoWindow(); // Instance the info window component (of each vehicle)

            // Create event click in marker with his info
            google.maps.event.addListener(instancedMarker, "click", ((marker, content, infoWindow) => () => {

              instancedMarker["infowindow"].setContent(`<div id="map-all-vehicles-${vehicle.id_vehicle}"></div>`);
              instancedMarker["infowindow"].open(map, marker);

              setTimeout(() => {
                ReactDOM.render(
                  <Provider store={store}>
                    <AppProvider>
                      <TimeLineItem vehicles={[vehicle]} showAllVehicles showTimeLine showMap={false} />
                    </AppProvider>
                  </Provider>,
                  document.getElementById(`map-all-vehicles-${vehicle.id_vehicle}`)
                );
              }, 0);

            })(instancedMarker, instancedMarker["infowindow"]));

            setMarkers((state) => [...state, instancedMarker]);
          }

          // Hide markers and open infowindow if not filtered on list
          // Only add marker in bounds if marker is visible
          if (activeBoxFilter && !arrFilteredVehicles.includes(instancedMarker.get("id"))) {

            instancedMarker.setVisible(false);
            instancedMarker["infowindow"].close();

          } else {

            instancedMarker.setVisible(true);
            bounds.extend(instancedMarker.getPosition() as google.maps.LatLng);
          }
        }
      });
    });

    // Recenter bound based on filtered vehicles
    if ((previousActiveBoxFilter !== activeBoxFilter)) {
      map.fitBounds(bounds);
    }

  }, [map, markers, allVehicles, setMarkersValues, filteredVehicles, activeBoxFilter, previousActiveBoxFilter]);

  /** Draw plants circles */
  const drawPlantsCircles = useCallback(() => {

    // Transform object vehicles into array
    const flatVehicles = Object.keys(vehicles).reduce((acc, key) => [...acc, ...vehicles[key]], [] as Vehicle[]);

    // Get all states 'Na usina' into an array
    const flatStatesNaUsina = flatVehicles.reduce((acc, vehicle) => {

      const statesNaUsina = vehicle.states.filter((state) => state.status.description === "Na usina" && !_.isEmpty(state.location));

      return [...acc, ...statesNaUsina];
    }, [] as VehicleState[]);

    interface PlantLocations {
      latitude: string;
      longitude: string;
      radius: number;
      id: string;
    }

    // Extract all plants locations from 'Na usina' states
    const flatPlantsLocations = _.uniqBy<PlantLocations>(flatStatesNaUsina.reduce((acc, state) => {
      const location = {
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        radius: state.location.radius ?? 80,
        id: `${state.location.latitude},${state.location.longitude}`
      };

      return [...acc, location];
    }, [] as PlantLocations[]), "id");

    if (flatPlantsLocations) {
      for (const plant of flatPlantsLocations) {
        let instancedCircle: google.maps.Circle = {} as google.maps.Circle;

        // Verify if plant circle already instanced
        circles.forEach((circle) => {
          if (circle.get("id") === plant.id) instancedCircle = circle;
        });

        const circleConfig = {
          strokeColor: "#00FF00",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#00FF00",
          fillOpacity: 0.35,
          center: { lat: Number.parseFloat(plant.latitude), lng: Number.parseFloat(plant.longitude) },
          radius: plant.radius
        };

        if (!_.isEmpty(instancedCircle)) {

          instancedCircle.setValues(circleConfig);

        } else {

          // Set marker on map
          instancedCircle = new google.maps.Circle({
            ...circleConfig,
            map
          });

          instancedCircle.set("id", plant.id);

          setCircles((state) => [...state, instancedCircle]);
        }
      }
    }

  }, [map, vehicles, circles]);

  /** Filter vehicles according input */
  const handleFilter = useCallback((inputContent) => {

    const vehiclesToFilter = { ...allVehicles };

    Object.keys(vehiclesToFilter).forEach((key) => {
      vehiclesToFilter[key] = vehiclesToFilter[key].filter((vehicle) => (
        [vehicle.code, vehicle.license_plate, vehicle.description].some((el) => `${el}`.toLowerCase().search(inputContent.toLowerCase()) >= 0)
      ));
    });

    setActiveBoxFilter(true);
    setFilteredVehicles(vehiclesToFilter);

  }, [allVehicles]);

  // Draw and redraw vehicle markers
  useEffect(() => {
    if (map) {
      // First create circles, then markers to apply fit bounds
      drawPlantsCircles();
      drawVehiclesPoints();

    }
  },
  // eslint-disable-next-line
  [vehicles, markersVisualizationType, drawVehiclesPoints, drawPlantsCircles]);

  useEffect(() => {

    if (map) {
      if (markers.length > 0 && (map.getCenter().lat() === mapsDefaultConfig.center.lat && map.getCenter().lng() === mapsDefaultConfig.center.lng)) {
        // Await map initialize to fit bounds
        setTimeout(() => {
          const bounds = new google.maps.LatLngBounds();

          markers.forEach((marker) => bounds.extend(marker.getPosition() as google.maps.LatLng));

          map.fitBounds(bounds);
        }, 1);
      }
    }

  });

  // Instance traffic layer
  useEffect(() => {
    if (map && !trafficLayer) {
      const layer = new google.maps.TrafficLayer();

      layer.setMap(map);
      setTrafficLayer(layer);
    }
  }, [map, trafficLayer]);

  // Make Traffic Layer show / hide
  useEffect(() => {
    if (trafficLayer) {
      if (showTrafficLayer) {
        trafficLayer.setMap(map);
      } else {
        trafficLayer.setMap(null);
      }
    }
  }, [trafficLayer, map, showTrafficLayer]);

  // Draw traffic layer into google maps
  useEffect(() => {
    if (map) {

      if (!control && trafficLayer) {
        const customControl = {
          position: 5,
          element: "SEM USO",
          action: () => { setShowTrafficLayer((state) => !state); }
        };

        const controlDiv = document.createElement("div");
        const controlUI = document.createElement("div");
        const controlIcon = document.createElement("div");

        controlUI.style.background = "none rgb(255, 255, 255)";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "2px";
        controlUI.style.boxShadow = "rgb(0 0 0 / 30%) 0px 1px 4px -1px";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginLeft = "10px";
        controlUI.style.marginTop = "30px";
        controlUI.title = t(MapMessages.updateVehicleCurrentPosition);
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        // eslint-disable-next-line max-len
        controlIcon.innerHTML = ReactDOMServer.renderToString(<TrafficIcon />);
        controlIcon.style.display = "flex";
        controlIcon.style.padding = "5px";
        controlUI.appendChild(controlIcon);

        // Add action on control and put inside map
        controlDiv.addEventListener("click", () => customControl.action());
        map.controls[customControl.position].push(controlDiv);

        setControl(controlDiv);
      }
    }
  }, [map, trafficLayer, control, t]);

  useEffect(() => {

    // Just write 2 or more letters
    if (contentTextFilter.length >= 2) handleFilter(contentTextFilter);
    else setActiveBoxFilter(false);

  }, [vehicles, contentTextFilter, handleFilter]);

  // When the map is requested, post log to api (to count map requests)
  useEffect(() => {
    if (map) {
      api.post("/logs/save", {
        context: "Frontend",
        description: GoogleApiRequestTypes.ALL_VEHICLES_MAP,
        user: {
          name: user.name,
          email: user.email
        }
      }).then();
    }
  }, [map, user]);

  return (
    <ContainerMapAllVehiclesCoords mapHeight={mapHeight}>
      <Grid container spacing={1}>
        <Grid className="map" item xs={12} md={12} lg={9}><div ref={ref} style={{ width: "100%", height: mapHeight }} /></Grid>
        <Grid className="filter" item xs={12} md={12} lg={3}>
          <TextField
            className="input"
            value={contentTextFilter}
            label={t(MapMessages.allVehiclesSearchLabel)}
            variant="outlined"
            onChange={(e) => setContentTextFilter(e.target.value)}
          />
          <div className="vehicles">
            {
            Object.keys(activeBoxFilter ? filteredVehicles : allVehicles).map((key) => {
              // REVIEW To correct label 'Pa carregadeira', 'Veiculos de apoio'. In future can change label in database
              let label = key;

              switch (label) {
                case "Pa carregadeira": label = t(FilterMessages.loaders); break;
                case "Veiculo de apoio": label = t(FilterMessages.supportVehicles); break;
                case "Betoneira": label = t(FilterMessages.concreteMixers); break;
                case "Caminhão bomba": label = t(FilterMessages.pumpTrucks); break;
                default: break;
              }

              if ((activeBoxFilter ? filteredVehicles : allVehicles)[key].length < 1) {
                return <> </>;
              }

              return (
                <Accordion
                  key={`vehicle-list-filter-${key}`}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography component="div">{label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      <TimeLineItem
                        vehicles={(activeBoxFilter ? filteredVehicles : allVehicles)[key]}
                        showAllVehicles
                        showTimeLine={false}
                        position="menu"
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
          }
          </div>
        </Grid>
      </Grid>
    </ContainerMapAllVehiclesCoords>
  );
});

export { MapAllVehiclesCoords };
export type { MarkersVisualizationTypes };
