export enum AggregateTruckTravelDataMessages {
  origin = "aggregateTruckTravelData.origin",
  loadingLocation = "aggregateTruckTravelData.loadingLocation",
  unloadingLocation = "aggregateTruckTravelData.unloadingLocation",
  productDocument = "aggregateTruckTravelData.productDocument",
  serviceOrderDate = "aggregateTruckTravelData.serviceOrderDate",
  travelStartDate = "aggregateTruckTravelData.travelStartDate",
  travelDocumentDate = "aggregateTruckTravelData.travelDocumentDate",
  quantity = "aggregateTruckTravelData.quantity",
}

export type TranslationAggregateTruckTravelDataMessages = {
  [key in AggregateTruckTravelDataMessages]: string;
}
