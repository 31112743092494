import { action } from "typesafe-actions";
import { AlertsTypes, AlertsState, AlertsStateObj } from "./alerts.type";

const AlertsActions = {
  setAlerts: (alerts: AlertsState | undefined) => action(AlertsTypes.SET_ALERTS, alerts),
  removeAlerts: (alerts: AlertsStateObj | undefined) => action(AlertsTypes.REMOVE_ALERTS, alerts),
  setAlertsFiltered: (alertsFiltered: AlertsStateObj | undefined) => action(AlertsTypes.SET_ALERTS_FILTERED, alertsFiltered)
};

export { AlertsActions };
