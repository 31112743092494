import styled from "styled-components";
import { css } from "@emotion/react";

const Container = styled.div`
  width: 100%;

  .MuiAccordionSummary-root {
    .Mui-expanded {
      .accordion-summary-chips {
        visibility: hidden !important;
        opacity: 0 !important;
        position: absolute;
        top: 30px;
      }
    }
  }
`;

const AccordionDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;

  .select-all-button {
    display: block;
    background-color: transparent;
    text-transform: inherit;
    box-shadow: none;
    color: black;
    min-width: auto !important;
    min-height: auto !important;
    height: fit-content;
    padding: 0 2px 0 10px;

    :hover, :focus {
      background-color: transparent;
      box-shadow: none;
      text-decoration: underline;
    }
  }

  .form-control_values {
    flex-grow: 1;
  }

  .MuiFormGroup-root {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    min-height: 150px;
    height: 150px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    overflow: auto;

    &.Mui-error {
      border: 1px solid red !important;
    }
  }
`;

const NoOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  color: #888888;
  padding-top: 10px;
`;

const SyncLoader = css`
  align-self: center;
  margin: auto;
`;

export {
  Container,
  AccordionDetail,
  NoOptions,
  SyncLoader
};
