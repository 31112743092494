/* eslint-disable camelcase, max-classes-per-file */
import { Column, CreateDateColumn, Entity, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { Hardware } from "./reg_hardwares.entity";

@Entity("reg_sim_cards")
export class SimCard {

  @PrimaryGeneratedColumn("uuid")
    id_sim_card?: string;

  @Column({ type: "varchar", nullable: false })
    caller_id?: string;

  @Column({ type: "varchar", nullable: false })
    iccid?: string;

  @Column({ type: "varchar", nullable: false })
    phone_company?: string;

  @Column({ type: "varchar", nullable: true })
    plan?: string;

  @Column({ type: "timestamp", nullable: false })
    purchase_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    activation_date?: Date;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToOne(() => Hardware, (hardware) => hardware.sim_card)
    hardware?: Hardware;

}

export class SIMCardErrors {

  static readonly SIMCARD_IN_USE = "3.7.1 (TCON333731)";

}
