import styled from "styled-components";

export const Container = styled.div`
  .MuiAppBar-root {
    z-index: 1;
  }

  .appBarDesktop {
    z-index: 10;
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.60);
    box-shadow: none;

    .title {
      width: 300px;
    }
    .alertBlockDay{
      background-color: #E64A19;
      color: white;
      text-align: center;
      font-weight: bold;
    }
    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select {
        padding-left: 10px;
        display: flex;
        align-items: center;

        .icon {

          svg {
            width: 35px;

            * {
              fill: white;
            }
          }
        }

        .input {
          padding-left: 10px;
        }
      }

      .actions {

        .blockedVehiclesIcon {
          position: relative;
          bottom: 3px;
          fill: red;
        }

        .MuiButtonBase-root {
          border-radius: 0;
        }

        .iconNotifications {

          svg {
            position: relative;
            top: 1px;
          }
        }

        .iconImproperDischarge {

          svg {
            fill: #ff0000;
          }
        }

        .active {

          svg {
            fill: red;
          }
        }

        svg {
          width: 1.8rem;
          fill: #002951;
        }

        #menuIcon {
          svg {
            fill: #002951;
          }
        }
      }

      #actionPopperSpeedingAlerts {

        svg {
          fill: #BEBEBE;
        }

        .red-fill svg {
          fill: #FF0000;
        }

      }

      .logo_and_language_container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

        .logo {
          cursor: pointer;

          img {
            width: 130px;
          }
        }
    }
  }

  .appBarMobile {
    z-index: 10;
    top: 0;
    bottom: auto;
    background: #FFFFFF;
    color: rgba(0, 0, 0, 0.60);
    box-shadow: 0 -4px 5px -6px #333;
    border-top: 1px solid #ccc;

    .alertBlockDay{
      background-color: #E64A19;
      color: white;
      text-align: center;
      font-weight: bold;
    }

    .actions {
      width: 100%;
      text-align: right;

      .blockedVehiclesIcon {
        position: relative;
        bottom: 4px;
        fill: red;
      }

      .iconNotifications {
        top: 2px;
        position: relative;
      }

      .iconImproperDischarge {

        svg {
          fill: #ff0000;
        }
      }

      .active {

        svg {
          fill: red;
        }
      }

      button {
        padding: 7px;
      }

      svg {
        font-size: 1.8rem;
        width: 1.8rem;
        fill: #002951;
      }

    }

    #menuIcon {
      padding: 8px 12px 12px 12px;

      svg {
        fill: #002951;
      }
    }

  }
`;

export const ContainerSVGMenuIcon = styled.span`
  svg {
    height: 22.5px;
    width: 22.5px;
    fill: #757575;
  }
`;
