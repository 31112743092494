/* eslint-disable import/prefer-default-export */
import { TranslationVehicleStatusMessages, VehicleStatusMessages } from "../interfaces";

export const messages: TranslationVehicleStatusMessages = {
  [VehicleStatusMessages.active]: "Ativo",
  [VehicleStatusMessages.inactive]: "Inativo",
  [VehicleStatusMessages.inMotion]: "Em movimento",
  [VehicleStatusMessages.stationary]: "Parado",
  [VehicleStatusMessages.tracker]: "Rastreador",
  [VehicleStatusMessages.drum]: "Balão",
  [VehicleStatusMessages.currentSpeed]: "Velocidade atual",
  [VehicleStatusMessages.engine]: "Ignição atual",
  [VehicleStatusMessages.engineOn]: "Ligada",
  [VehicleStatusMessages.engineOff]: "Desligada",
  [VehicleStatusMessages.engineRunningTime]: "Tempo ligado",
  [VehicleStatusMessages.travel]: "Viagem",
  [VehicleStatusMessages.odometer]: "Odômetro",
  [VehicleStatusMessages.latestDataSent]: "Últimos dados enviados",
  [VehicleStatusMessages.lastLoadDateDescription]: "Balão no sentido horário (Carregamento)",
  [VehicleStatusMessages.lastUnloadDateDescription]: "Balão no sentido anti-horário (Descarregamento)",
  [VehicleStatusMessages.pumpingTimeDescription]: "Bombeamento",
  [VehicleStatusMessages.CANTelemetry]: "Telemetria CAN",
  [VehicleStatusMessages.totalFuelConsumption]: "Consumo total de combustível",
  [VehicleStatusMessages.radiatorTemperature]: "Temperatura do radiador",
  [VehicleStatusMessages.averageFuelConsumption]: "Consumo médio de combustível",
  [VehicleStatusMessages.fuelLevel]: "Nível de combustível",
  [VehicleStatusMessages.totalEngineRunningTime]: "Tempo total de funcionamento do motor",
  [VehicleStatusMessages.totalDrivingTime]: "Tempo total de condução",
  [VehicleStatusMessages.totalFuelUsedInIdle]: "Total de combustível usado em marcha lenta",
  [VehicleStatusMessages.combinedGrossVehicleWeight]: "Peso bruto combinado do veículo"
};
