/* eslint-disable import/prefer-default-export */
import { TravelDataMessages, TranslationTravelDataMessages } from "../interfaces";

export const messages: TranslationTravelDataMessages = {
  [TravelDataMessages.origin]: "Origen",
  [TravelDataMessages.destination]: "Destino",
  [TravelDataMessages.finalLocation]: "Ubicación final",
  [TravelDataMessages.taxInvoice]: "Factura",
  [TravelDataMessages.sealNumber]: "Número de sello",
  [TravelDataMessages.invoiceIntegrationDate]: "Fecha de integración de la factura",
  [TravelDataMessages.travelStartDate]: "Fecha de inicio del viaje",
  [TravelDataMessages.invoiceIssueDate]: "Fecha de emisión de la factura",
  [TravelDataMessages.pieceToBeConcreted]: "Pieza a concretar",
  [TravelDataMessages.loadVolume]: "Volumen de carga",
  [TravelDataMessages.loadExpirationDate]: "Fecha de vencimiento de la carga",
  [TravelDataMessages.trace]: "Traza (Referente a la carga)",
  [TravelDataMessages.application]: "Aplicación",
  [TravelDataMessages.slump]: "Slump",
  [TravelDataMessages.resistance]: "Resistencia",
  [TravelDataMessages.resistanceType]: "Resistencia (Tipo)",
  [TravelDataMessages.resistanceComplement]: "Resistencia (Complemento)",
  [TravelDataMessages.gravel]: "Grava"
};
