export enum AverageSpeedMessages {

  averageSpeedHistoryTitle = "averageSpeed.averageSpeedHistoryTitle",
  averageSpeedByDriver = "averageSpeed.averageSpeedByDriver",
  averageSpeedByVehicle = "averageSpeed.averageSpeedByVehicle",
  startDate = "averageSpeed.startDate",
  finishDate = "averageSpeed.finishDate",
  distance = "averageSpeed.distance",
  averageSpeedColumn = "averageSpeed.averageSpeedColumn",
  Vehicles = "averageSpeed.filterVehicle",
  Drivers = "averageSpeed.filterDriver",
  VehicleType = "averageSpeed.filterVehicleType",

  noAverageFound = "averageSpeed.noAverageFound",
  readAverageSuccess = "averageSpeed.readAverageSuccess",
  readAverageError = "averageSpeed.readAverageError",
}

export type TranslationAverageSpeedMessages = {
  [key in AverageSpeedMessages]: string;
}
