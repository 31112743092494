import { EventsMessages, TranslationEventsMessages } from "../interfaces";

export const messages: TranslationEventsMessages = {
  [EventsMessages.notFound]: "Evento no encontrado",
  [EventsMessages.updateSuccess]: "Evento actualizado con éxito",
  [EventsMessages.updateError]: "Error al actualizar evento",

  [EventsMessages.stop]: "Parada",
  [EventsMessages.improperDischarge]: "Descarga indebida",
  [EventsMessages.speeding]: "Exceso de velocidad",
  [EventsMessages.vehicleBlock]: "Bloqueo de vehículo",
  [EventsMessages.concreteExpiration]: "Vencimiento del concreto",
  [EventsMessages.speedingTravel]: "Vencimiento del concreto"
};
