import { useCallback } from "react";

// region Hooks
import { useToast } from "@hooks/useToast";
// endregion Hooks
// region Languages
import useTranslation from "src/translations/useTranslation";
import { ToastMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Services
import api from "@services/api";
// endregion Services
// region Shared
import { Param } from "@shared/entities/reg_params.entity";
import { ParamsNames } from "@shared/constants/params-names.enum";
// endregion Shared

interface UseParamsReturn {
  getParamsArr: () => Promise<Param[] | null>;
  getParamsObj: () => Promise<Partial<Record<ParamsNames, string>>>;
}

const useParams = (): UseParamsReturn => {

  // region Hooks
  const { addToast } = useToast();
  const { t } = useTranslation();
  // endregion Hooks
  // region Functions

  /**
   * Transform params array to object with name as key and key as value
   * @param paramsArray Array of params
   * @returns Object with name as key and key as value
   */
  const transformParamsToObject = (paramsArr: Param[]): Partial<Record<ParamsNames, string>> => {

    if (!Array.isArray(paramsArr) || paramsArr.length === 0) return {};

    const paramsObject = paramsArr.reduce((acc, param) => {

      if (param.name && Object.values(ParamsNames).includes(param.name as ParamsNames)) {
        acc[param.name as ParamsNames] = param.key; // Tipagem do `name` com enum
      }

      return acc;

    }, {} as Partial<Record<ParamsNames, string>>); // Tipagem do acumulador

    return paramsObject;
  };

  /**
   * Get all params
   * @returns Array of params
   */
  const getParamsArr = useCallback(async () => {

    try {

      // Get all params
      const { data: response } = await api.get("params/read");

      if (response.status === "success") {
        return response.result as Param[];
      } addToast({ type: "info", title: t(ToastMessages.alert), description: response.message });

    } catch (error) {

      if (!error.response) {

        addToast({
          type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished)
        });
      } else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    }

    return null;

  }, [addToast, t]);

  /**
   * Get all params as object
   * @returns Object with name as key and key as value
   */
  const getParamsObj = async (): Promise<Partial<Record<ParamsNames, string>>> => {

    const paramsArr = await getParamsArr();

    return transformParamsToObject(paramsArr || []);
  };

  // endregion Functions

  return { getParamsArr, getParamsObj };
};

export { useParams };
