import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";

// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  TravelHistoryMessages,
  GlobalMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages

// region Imports - Services
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services

// region Imports - Components
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import QueryFilterIntegrationsReport from "@organisms/QueryFilterIntegrationsReport";
import { IntegrationLogs } from "@shared/interfaces/integrations-log.interface";
// endregion Imports - Components

// region Imports - Styles
import utils from "@utils/useful-functions";
import { Container } from "./styles";
import * as dateFns from "date-fns";
// endregion Imports - Styles

const IntegrationReport: React.FC = () => {

  /* region States */
  const [logs, setLogs] =  useState<IntegrationLogs[]>([] as Array<IntegrationLogs>);

  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  // Used to create export file name
  const [startDate, setStartDate] = useState<string>(dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00"));
  const [finishDate, setFinishDate] = useState<string>(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59"));

  const [loadMoreToggle, setLoadMoreToggle] = useState(false);
  /* endregion States */

  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  /* endregion Hooks */

  /* region Constants */
  // Create config to specific state
  const dataTableSettings: DataTables.Settings = {
    order: [[1, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableColumns : DataTableColumns[] = [
    { // Num doc
      title: "Num NF",
      data: (log: IntegrationLogs) => log.numNf,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.numNf"
    },
    { // Status NF
      title: "Status NF",
      data: (log: IntegrationLogs) => log.statusNf,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.statusNf"
    },
    { // integração
      title: "Integração",
      data: (log: IntegrationLogs) => log.registrationDate,
      defaultContent: "",
      orderable: false,
      filterable: true,
      render: (data) => (data ? (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm")) : ""),
      propertyName: "log.registrationDate"
    },
    { // Emissao NF
      title: "Emissao NF",
      data: (log: IntegrationLogs) => log?.loadGroup,
      defaultContent: "",
      orderable: false,
      filterable: true,
      render: (data) => (data ? "Lote" : "Convencional"),
      propertyName: "log.loadGroup"
    },
    { // Grupo de Carga
      title: "Grupo de Carga",
      data: (log: IntegrationLogs) => (log?.loadGroup ?? []),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.loadGroup",
      render: (data) => {
        if (data) {
          return data.map((item: any) => `<i>${item}</i>`).join(", ");
        }

        return "";
      }
    },
    { // Veiculo
      title: "Veiculo",
      data: (log: IntegrationLogs) => log.vehicle,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.vehicle"
    },
    { // Status do Veiculo
      title: "status veículo",
      data: (log: IntegrationLogs) => (
        ReactDOMServer.renderToString(
          <b style={{ color: log.statusVehicle ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {log.statusVehicle ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.driverActive"
    },
    { // Placa
      title: "Placa",
      data: (log: IntegrationLogs) => log.licensePlate,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.licensePlate"
    },
    { // Motorista
      title: "Motorista",
      data: (log: IntegrationLogs) => log.driver,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.driver"
    },
    { // CPF Motorista
      title: "CPF Motorista",
      data: (log: IntegrationLogs) => log.driverCpf,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.driverCpf"
    },
    { // Status do Motorista
      title: "Status motorista",
      data: (log: IntegrationLogs) => (
        ReactDOMServer.renderToString(
          <b style={{ color: log.statusDriver ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {log.statusDriver ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.statusDriver"
    },
    { // Origem
      title: "Origem",
      data: (log: IntegrationLogs) => log.origin,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.origin"
    },
    { // Destino
      title: "Usina",
      data: (log: IntegrationLogs) => log.destination,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.destination"
    },
    { // Product Type
      title: "Tipo de Produto",
      data: (log: IntegrationLogs) => log.productType,
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "log.productType"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_integrações_${startDate}__${finishDate}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.moreRecords),
      callback: () => {
        setLoadMoreToggle((prevState) => !prevState);
      },
      key: "moreRecords"
    }
  ];
  /* endregion Constants */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: IntegrationLogs[],
    loadMore: boolean,
    startDate: string,
    finishDate: string
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    if (loadMore) setLogs([...logs, ...returnedData]);
    else setLogs(returnedData);

    setStartDate(startDate);
    setFinishDate(finishDate);
  };
  /* endregion Functions */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <NewDataTable
          title={t(TravelHistoryMessages.integrationReport)}
          filters
          data={logs}
          columns={dataTableColumns}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
        <QueryFilterIntegrationsReport
          open={openFilterFields}
          onClose={() => setOpenFilterFields(false)}
          onFilter={handleApplyFilter}
          loadMoreToggle={loadMoreToggle}
        />
      </Container>
    </>
  );
};

export default IntegrationReport;
