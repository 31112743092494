import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
  margin-top: ${(prop) => (prop.platform === "mobile" ? "5rem" : "0")};

  .aggregated-data {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .equipment-data {
    margin: 1rem 0 200px 0;
    height: 100%;
  }

  .dataTable-title {
    font-size: 1.5rem;
    font-weight: 200;
    padding: 16px;
  }
`;
