import React, { useEffect, useState } from "react";
import { Fab, makeStyles } from "@material-ui/core";

// region Assets
import { ReactComponent as BTIcon } from "@assets/icons/bt-fab.svg";
import { ReactComponent as PumpIcon } from "@assets/icons/pump-fab.svg";
import { ReactComponent as SupportVehicleIcon } from "@assets/icons/support-vehicle-fab.svg";
import { ReactComponent as AggregateTruckIcon } from "@assets/icons/aggregate-truck-fab.svg";
// endregion Assets
// region Enums
import { ParamsKeys } from "@shared/constants/params-names.enum";
// endregion Enums
// region Languages
import { MenuMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// region Libraries
import Popover from "@material-ui/core/Popover";
import { useHistory } from "react-router-dom";
// endregion Libraries
// region Hooks
import { useAuth } from "@hooks/useAuth";
// endregion Hooks
// region Styles
import { StyledMenu } from "./styles";

// Fab styles are defined here to load them when the component is rendered for the first time
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "1rem",
    left: "1rem",
    width: "15rem",
    height: "4rem",
    borderRadius: "2rem",
    backgroundColor: "#002951",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 1000,
    "& .MuiFab-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "1.5rem",
        gap: "1rem"
      },
      "& .active-option-name": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Roboto",
        fontSize: "1rem",
        width: "8rem"
      },
      "& svg": {
        [theme.breakpoints.down("sm")]: {
          width: "3rem"
        }
      }
    },
    "&:hover": {
      backgroundColor: "#002951"
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "1rem",
      width: "12.75rem",
      height: "3.5rem"
    }
  },
  fabMenuItem: {
    width: "15rem",
    height: "4rem",
    borderRadius: "2rem",
    backgroundColor: "#002951",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#FFF",
    "& .MuiFab-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.75rem",
      [theme.breakpoints.down("sm")]: {
        lineHeight: "1.5rem",
        gap: "1rem"
      },
      "& .option-name": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "8rem",
        fontFamily: "Roboto",
        fontSize: "1rem"
      },
      "& svg": {
        [theme.breakpoints.down("sm")]: {
          width: "13rem"
        }
      }
    },
    "&:hover": {
      backgroundColor: "#002951"
    },
    [theme.breakpoints.down("sm")]: {
      width: "12.75rem",
      height: "3.5rem"
    }
  }
}));
// endregion Styles

// region Interfaces
interface MenuOption {
  id: number,
  name: string,
  label: string,
  icon: JSX.Element,
  link: string,
  active: boolean
}
// endregion Interfaces

const FloatingMenu: React.FC = () => {

  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { params } = useAuth();

  // region Menu Options
  const options: MenuOption[] = [
    {
      id: 1,
      name: "production",
      label: t(MenuMessages.production).toUpperCase(),
      icon: <BTIcon />,
      link: "/main",
      active: true
    },
    {
      id: 2,
      name: "pumps",
      label: t(MenuMessages.pumps).toUpperCase(),
      icon: <PumpIcon />,
      link: "/timeline",
      active: false
    },
    {
      id: 3,
      name: "supportVehicles",
      label: t(MenuMessages.supportVehicles).toUpperCase(),
      icon: <SupportVehicleIcon />,
      link: "/support-vehicles",
      active: true
    },
    {
      id: 4,
      name: "trucks",
      label: t(MenuMessages.trucks).toUpperCase(),
      icon: <AggregateTruckIcon />,
      link: "/aggregate-trucks",
      active: true
    }
  ];
  // endregion Menu Options

  // Get the pump timeline enabled param from local storage
  const pumpTimelineEnabledParam = params?.find((param) => param.name === "pump_timeline_enabled");

  // Set the pump timeline option as active if the param is true
  const pumpTimelineOption = options.find((option) => option.id === 2);

  pumpTimelineOption!.active = pumpTimelineEnabledParam?.key === ParamsKeys.PUMP_TIMELINE_ENABLED_TRUE;

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeOption, setActiveOption] = useState(options.find((option) => option.link === history.location.pathname));

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: MenuOption) => {
    setActiveOption(option);
    handleCloseMenu();

    // Navigate to the option link
    history.push(option.link);
  };

  const open = Boolean(anchorEl);

  // Update activeOption label when the language is changed
  useEffect(() => {
    setActiveOption((prevOption) => {
      if (prevOption) {
        return {
          ...prevOption,
          label: t(MenuMessages[prevOption.name]).toUpperCase()
        };
      }

      return prevOption;
    });
  }, [i18n.language, t]);

  return (
    <>
      <Fab className={classes.fab} onClick={handleOpenMenu} variant="extended" disableRipple>
        { activeOption?.icon }
        <div className="active-option-name">
          { activeOption?.label }
        </div>
      </Fab>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorReference="anchorEl"
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          }
        }}
      >
        <StyledMenu>
          { options.map((option) => (
            option.active && option.id !== activeOption?.id && (
              <Fab
                className={classes.fabMenuItem}
                key={option.id}
                variant="extended"
                onClick={() => handleOptionClick(option)}
                disableRipple
              >
                { option.icon }
                <div className="option-name">
                  { option.label }
                </div>
              </Fab>
            )
          )) }
        </StyledMenu>
      </Popover>
    </>
  );
};

export default FloatingMenu;
