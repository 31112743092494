/* eslint-disable camelcase */
/* eslint-disable no-shadow */

// Action types
export enum ParamsTypes {
  SET_PARAMS = "@params/SET_PARAMS",
}

// State type
export interface ParamsState {
  googleApiKey: string;
}
