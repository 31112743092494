/* eslint-disable import/prefer-default-export */
import { TranslationVehicleStatusMessages, VehicleStatusMessages } from "../interfaces";

export const messages: TranslationVehicleStatusMessages = {
  [VehicleStatusMessages.active]: "Active",
  [VehicleStatusMessages.inactive]: "Inactive",
  [VehicleStatusMessages.inMotion]: "In motion",
  [VehicleStatusMessages.stationary]: "Stationary",
  [VehicleStatusMessages.tracker]: "Tracker",
  [VehicleStatusMessages.drum]: "Drum",
  [VehicleStatusMessages.currentSpeed]: "Current speed",
  [VehicleStatusMessages.engine]: "Engine",
  [VehicleStatusMessages.engineOn]: "On",
  [VehicleStatusMessages.engineOff]: "Off",
  [VehicleStatusMessages.engineRunningTime]: "Engine running time",
  [VehicleStatusMessages.travel]: "Travel",
  [VehicleStatusMessages.odometer]: "Odometer",
  [VehicleStatusMessages.latestDataSent]: "Latest data sent",
  [VehicleStatusMessages.lastLoadDateDescription]: "Drum in clockwise direction (Load)",
  [VehicleStatusMessages.lastUnloadDateDescription]: "Drum in counterclockwise direction (Unload)",
  [VehicleStatusMessages.pumpingTimeDescription]: "Pumping",
  [VehicleStatusMessages.CANTelemetry]: "CAN Telemetry",
  [VehicleStatusMessages.totalFuelConsumption]: "Total fuel consumption",
  [VehicleStatusMessages.radiatorTemperature]: "Radiator temperature",
  [VehicleStatusMessages.averageFuelConsumption]: "Average fuel consumption",
  [VehicleStatusMessages.fuelLevel]: "Fuel level",
  [VehicleStatusMessages.totalEngineRunningTime]: "Total engine running time",
  [VehicleStatusMessages.totalDrivingTime]: "Total driving time",
  [VehicleStatusMessages.totalFuelUsedInIdle]: "Total fuel used in idle",
  [VehicleStatusMessages.combinedGrossVehicleWeight]: "Combined gross vehicle weight"
};
