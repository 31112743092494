import { TranslateConfigCommandsMessages, ConfigCommandsContentID } from "../interfaces";

export const messages: TranslateConfigCommandsMessages = {
  [ConfigCommandsContentID.CHECAR_ICCID_ST4315U]: "Check ICCID - ST4315U",
  [ConfigCommandsContentID.CHECAR_ICCID_ST380]: "Check ICCID - ST380",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO]: "Change hour meter",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO]: "Change odometer",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BETONEIRA]: "Change sending time - Concrete Mixer (60s on/1h" +
  " off)",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BOMBA]: "Change sending time - Pump (60s on/60s off)",
  [ConfigCommandsContentID.LIMPAR_MEMORIA]: "Clear Memory",
  [ConfigCommandsContentID.REINICIAR]: "Restart",
  [ConfigCommandsContentID.DESABILITAR_IGNICAO_FISICA]: "Disable physical ignition",
  [ConfigCommandsContentID.HABILITAR_IGNICAO_FISICA]: "Enable physical ignition",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_BATERIA]: "Change ignition to battery",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_ACELEROMETRO]: "Change ignition to accelerometer",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS]: "Change data sending time",
  [ConfigCommandsContentID.PRESET_A]: "Preset A",
  [ConfigCommandsContentID.ALTERACAO_SERVIDOR_DESTINO]: "Change message destination server",
  [ConfigCommandsContentID.CHECAR_ICCID_ST300]: "Check ICCID - ST300 Family, excluding ST380",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO_4315_8310]: "Change odometer - 4315/8310",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO_4315_8310]: "Change hour meter - 4315/8310"
};
