import React from "react";

import { Tab, Tabs } from "@material-ui/core";

import { useSelector } from "react-redux";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import usePersistedState from "@hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import useTranslation from "src/translations/useTranslation";
import { HardwareModuleMessages, MenuMessages } from "@shared/languages/interfaces";
import Header from "../../../components/Header";
import TabPanel from "../../../components/TabPanel";
import CommandsList from "./CommandsList";
import HardwaresList from "./HardwaresList";
import InstallationHistoryList from "./InstallationHistoryList";
import LogViewer from "./LogViewer";

import { Container } from "./styles";

const Hardwares: React.FC = () => {

  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => { setSelectedTab(newValue); };
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();

  return (
    <>
      <Header title={t(MenuMessages.hardwares)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          style={{ marginTop: "-20px" }}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          <Tab label={t(HardwareModuleMessages.tabsHardware)} disableRipple />
          <Tab label={t(HardwareModuleMessages.tabsHardwareInstallationHistory)} disableRipple />
          <Tab label={t(HardwareModuleMessages.tabsCommands)} disableRipple />
          <Tab label={t(HardwareModuleMessages.tabsLogs)} disableRipple />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <HardwaresList />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <InstallationHistoryList />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <CommandsList />
        </TabPanel>
        <TabPanel value={selectedTab} index={3}>
          <LogViewer />
        </TabPanel>
      </Container>
    </>
  );
};

export default Hardwares;
