export enum DualListboxMessages {
  restrictedVehiclesHeader = "dualListbox.restrictedVehiclesHeader",
  permittedVehiclesHeader = "dualListbox.permittedVehiclesHeader",
  availableFilterHeader = "dualListbox.availableFilterHeader",
  selectedFilterHeader = "dualListbox.selectedFilterHeader",
  moveAllLeft = "dualListbox.moveAllLeft",
  moveAllRight = "dualListbox.moveAllRight",
  moveLeft = "dualListbox.moveLeft",
  moveRight = "dualListbox.moveRight",
  moveBottom = "dualListbox.moveBottom",
  moveDown = "dualListbox.moveDown",
  moveUp = "dualListbox.moveUp",
  moveTop = "dualListbox.moveTop",
  noAvailableOptions = "dualListbox.noAvailableOptions",
  noSelectedOptions = "dualListbox.noSelectedOptions",
  filterPlaceholder = "dualListbox.filterPlaceholder"
}

export type TranslationDualListboxMessages = {
  [key in DualListboxMessages]: string;
}
