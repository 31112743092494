import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Travel } from "./reg_travels.entity";

@Entity("reg_pump_status")
export class PumpStatus {

  @PrimaryGeneratedColumn("uuid")
    id_pump_status?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "timestamp", nullable: false })
    start_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    finish_date?: Date;

  @Column({ type: "boolean", nullable: false, default: false })
    active?: boolean;

  @ManyToOne(() => Travel, (travel) => travel.pump_status, { nullable: true })
  @JoinColumn({ name: "id_travel_fk" })
    travel?: Travel;

}
