/* eslint-disable camelcase */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { Vehicle } from "./reg_vehicles.entity";
import { Hardware } from "./reg_hardwares.entity";

@Entity("hty_hardware_installations")
export class HardwareInstallationHistory {

  @PrimaryGeneratedColumn("uuid")
    id_hardware_installation_history?: string;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.hardwareInstallationHistory, { nullable: false })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

  @ManyToOne(() => Hardware, (hardware) => hardware.hardwareInstallationHistory, { nullable: false })
  @JoinColumn({ name: "id_hardware_fk" })
    hardware?: Hardware;

  @Column({ type: "timestamp", nullable: false })
    occurrence_date?: Date;

  @Column({ type: "boolean", nullable: false, default: false })
    imported_data?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

}
