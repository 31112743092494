import React, { useState } from "react";
import { useSelector } from "react-redux";
// region Imports - Libraries
// endregion Imports - Libraries

// region Imports - Utils
// endregion Imports - Utils

// region Imports - Material-UI
// endregion Imports - Material-UI

// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
// endregion Imports - Languages

// region Imports - Shared
import {
  AlertMessages,
  AverageSpeedMessages,
  DataTableMessages,
  GlobalMessages,
  VehiclesModuleMessages
} from "@shared/languages/interfaces";
import { AverageSpeed } from "@shared/interfaces/average-speed.interface";
import { AverageSpeedFilter } from "@shared/constants/average-speed-filter.enum";
// endregion Imports - Shared

// region Imports - Components
import Header from "@components/Header";
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import QueryFilterAverageSpeedHistory from "@organisms/QueryFilterAverageSpeedHistory";
// endregion Imports - Components

// region Imports - Services
// endregion Imports - Services

// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store

// region Imports - Styles
import utils from "@utils/useful-functions";
import { format } from "date-fns";
import { Container } from "./styles";
// endregion Imports - Styles

const AverageSpeedHistoryByVehicle: React.FC = () => {

  /* region States */
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);
  const [dataTableValues, setDataTableValues] = useState<AverageSpeed[]>([] as Array<AverageSpeed>);
  /* endregion States */

  /* region Hooks */
  const { t } = useTranslation();
  const { screen } = useSelector((screen) => screen);
  /* endregion Hooks */

  /* region Constants */
  // Create config to specific state
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableColumns : DataTableColumns[] = [
    { // Veículo
      title: t(GlobalMessages.vehicle),
      data: (data: AverageSpeed) => (data.vehicleCode ?? ""),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "averageSpeed.code"
    },
    { // Tipo de Veículo
      title: t(VehiclesModuleMessages.vehicleType),
      className: "print csv",
      data: (data: AverageSpeed) => (data.vehicleType ?? ""),
      defaultContent: "",
      filterable: true
    },
    { // Placa
      title: t(AlertMessages.licensePlate),
      data: (data: AverageSpeed) => (data.licensePlate ?? ""),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "vehicle.licensePlate"
    },
    { // Inicio
      title: t(AverageSpeedMessages.startDate),
      data: (data: AverageSpeed) => (data.startDate ?? ""),
      filterable: true,
      propertyName: "averageSpeed.startData",
      render: (data) => (data ? data.replace(/'/g, "").split("T")[0].split("-").reverse().join("/") : "")
    },
    { // Fim
      title: t(AverageSpeedMessages.finishDate),
      data: (data: AverageSpeed) => (data.finishDate ?? ""),
      filterable: true,
      propertyName: "averageSpeed.finishDate",
      render: (data) => (data ? data.replace(/'/g, "").split("T")[0].split("-").reverse().join("/") : "")
    },
    { // Distancia total
      title: t(AverageSpeedMessages.distance),
      data: (data: AverageSpeed) => (data.distance ?? ""),
      filterable: true,
      propertyName: "averageSpeed.distance"
    },
    { // Velocidade média total
      title: t(AverageSpeedMessages.averageSpeedHistoryTitle),
      data: (data: AverageSpeed) => (data.averageSpeed ?? ""),
      filterable: true,
      propertyName: "averageSpeed.travels.average"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_velocidade_media_veiculo_${format(new Date(), "dd-MM-yyyy")}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: AverageSpeed[]
  ) => {
    setDataTableValues(returnedData);
    setNumberOfFilteredOptions(numberOfFilteredOptions);
  };
  /* endregion  Functions */

  return (
    <>
      <Header title={t(AverageSpeedMessages.averageSpeedHistoryTitle)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <NewDataTable
          title={t(AverageSpeedMessages.averageSpeedHistoryTitle)}
          filters
          data={dataTableValues}
          columns={dataTableColumns}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
      </Container>
      <QueryFilterAverageSpeedHistory
        open={openFilterFields}
        filterType={AverageSpeedFilter.VEHICLES}
        onClose={() => setOpenFilterFields(false)}
        onFilter={handleApplyFilter}
      />
    </>
  );
};

export default AverageSpeedHistoryByVehicle;
