import React, { useMemo, useState } from "react";

// region Libraries
import { Logo as LogoFleet } from "@libraries/icons";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { MapMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Components
import { MapFixedPointCoord } from "@components/Map";
// endregion Components
// region Utils
import utils from "@utils/useful-functions";
// endregion Utils
// region Styles
import { ContainerTimeLineItemMap } from "./styles";
// endregion Styles

type TimeLineItemMapProps = { latitude: number, longitude: number, vehicleTypeID: string, vehicleStateTypeID: string, lastSendTrackerDate: Date }
const TimeLineItemMap: React.FC<TimeLineItemMapProps> = ({ latitude, longitude, vehicleTypeID, vehicleStateTypeID, lastSendTrackerDate }) => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [showMap, setShowMap] = useState(false);
  const [refreshMap, setRefreshMap] = useState(false);
  // endregion States

  const map = useMemo(() => (
    <>
      <MapFixedPointCoord
        latitude={latitude}
        longitude={longitude}
        mapHeight={180}
        tooltip={{ title: utils.formatDateIfHave(new Date(lastSendTrackerDate), "fullDate") as string }}
        customMarker={{ url: utils.getMarkerWithIcon(vehicleTypeID, vehicleStateTypeID) }}
        customControl={{
          position: 5,
          element: "SEM USO",
          action: () => setRefreshMap((state) => !state)
        }}
      />
    </>

  // eslint-disable-next-line
  ), [refreshMap]);

  return (
    <ContainerTimeLineItemMap>
      {!showMap ? (
        <div className="staticMap">
          <img
            src={LogoFleet}
            alt={t(MapMessages.viewLocation)}
            onClick={() => setShowMap(true)}
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="drawerMap">{map}</div>
      )}
    </ContainerTimeLineItemMap>
  );
};

export { TimeLineItemMap };
