/* eslint-disable import/prefer-default-export */
import {
  MenuMessages,
  TranslationMenuMessages
} from "../interfaces";

export const messages: TranslationMenuMessages = {
  [MenuMessages.production]: "Producción",
  [MenuMessages.pumps]: "Bombas",
  [MenuMessages.supportVehicles]: "Vehículos de apoyo",
  [MenuMessages.trucks]: "Carretas",
  [MenuMessages.dashboards]: "Dashboards",
  [MenuMessages.management]: "Gerenciamiento",
  [MenuMessages.users]: "Usuarios",
  [MenuMessages.modules]: "Módulos",
  [MenuMessages.drivers]: "Conductores",
  [MenuMessages.vehicles]: "Vehículos",
  [MenuMessages.locations]: "Locales",
  [MenuMessages.hardwares]: "Hardwares",
  [MenuMessages.SIMCards]: "SIM Cards",
  [MenuMessages.reports]: "Informes",
  [MenuMessages.scheduledReports]: "Informes programados",
  [MenuMessages.travelHistory]: "Historial de viajes",
  [MenuMessages.alertHistory]: "Historial de alertas",
  [MenuMessages.averageSpeed]: "Velocidad promedio",
  [MenuMessages.coordinateAnalysis]: "Análisis de coordenadas",
  [MenuMessages.vehicleHistory]: "Historial de vehículos",
  [MenuMessages.vehicleStatesHistory]: "Historial de estados de vehículo",
  [MenuMessages.others]: "Otros",
  [MenuMessages.contactUs]: "Contáctenos",
  [MenuMessages.settings]: "Configuraciones",
  [MenuMessages.installApp]: "Instalar aplicación",
  [MenuMessages.exit]: "Salir",
  [MenuMessages.generalLocation]: "Localización general",
  [MenuMessages.vehiclesUnderMaintenance]: "Vehículos en mantenimiento",
  [MenuMessages.alerts]: "Alertas",
  [MenuMessages.speedingAlerts]: "Alerta de límite de velocidad",
  [MenuMessages.concreteExpiration]: "Validez del concreto",
  [MenuMessages.improperDischarges]: "Descargas indebidas",
  [MenuMessages.vehiclesInTrustedLocations]: "Vehículos en lugares de confianza",
  [MenuMessages.blockedVehicles]: "Vehículos bloqueados",
  [MenuMessages.regionals]: "Regional",
  [MenuMessages.help]: "Ayuda",
  [MenuMessages.groups]: "Grupos",
  [MenuMessages.pumpProgramming]: "Programación de bombas",
  [MenuMessages.zones]: "Zonas de restricción"
};
