/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { FiltersDashboardTypes, FiltersDashboardState } from "./filters-dashboard.type";

const FiltersDashboardActions = {
  setFiltersDashboard: (filtersDashboard: FiltersDashboardState | undefined) => action(FiltersDashboardTypes.SET_FILTERS_DASHBOARD, filtersDashboard)
};

export { FiltersDashboardActions };
