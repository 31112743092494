import React from "react";

import { Container } from "./styles";

import Header from "../../components/Header";
import TimeLine from "../../components/TimeLines/TimeLineVehicles";

const Main: React.FC = () => (
  <>
    <Header title="" />
    <Container className="page">
      <TimeLine />
    </Container>
  </>
);

export default Main;
