import React, { useEffect, useMemo, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Regional } from "@shared/entities/reg_regionals.entity";
import { Group } from "@shared/entities/reg_groups.entity";
import { Driver } from "@shared/interfaces/driver.interface";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
import { VehicleHistory } from "@shared/interfaces/vehicles-history.interface";
import { Plant } from "@shared/entities/reg_plants.entity";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, MenuMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";

// endregion Imports - Organisms

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    data: VehicleHistory,
    startDate: string,
    finishDate: string
  ) => void;
}
// endregion Interfaces

const QueryFilterVehicleHistory: React.FC<IProps> = ({ open, onClose, onFilter }) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region States
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsDriverFilter, setOptionsDriverFilter] = useState<Driver[]>([] as Array<Driver>);
  const [optionsVehicleTypeFilter, setOptionsVehicleTypeFilter] = useState<VehicleType[]>([] as Array<VehicleType>);
  const [optionsPlantFilter, setOptionsPlantFilter] = useState<Plant[]>([] as Array<Plant>);
  const [optionsRegionalFilter, setOptionsRegionalFilter] = useState<Regional[]>([] as Array<Regional>);
  const [optionsGroupFilter, setOptionsGroupFilter] = useState<Group[]>([] as Array<Group>);

  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingDriverFilter, setLoadingDriverFilter] = useState(false);
  const [loadingVehicleTypeFilter, setLoadingVehicleTypeFilter] = useState(false);
  const [loadingPlantFilter, setLoadingPlantFilter] = useState(true);
  const [loadingRegionalFilter, setLoadingRegionalFilter] = useState(true);
  const [loadingGroupFilter, setLoadingGroupFilter] = useState(true);

  const [loadingVehicleHistory, setLoadingVehicleHistory] = useState(false);
  // endregion States
  // region Constants
  const paramNameStartDate = "startDate";
  const paramNameEndDate = "finishDate";
  const paramNameVehicle = "idVehicles";
  const paramNameDriver = "idDrivers";
  const paramNameVehicleType = "idVehicleTypes";
  const paramNamePlant = "idOrigins";
  const paramNameRegional = "idRegionals";
  const paramNameGroup = "idGroups";

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [loadingVehicleFilter, optionsVehicleFilter, t]);

  const driverFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingDriverFilter,
    labelName: t(MenuMessages.drivers),
    paramName: paramNameDriver,
    values: optionsDriverFilter.map((driver) => ({
      value: driver.id_driver!,
      label: `${driver.name} - ${driver.cpf}`
    }))
  }), [loadingDriverFilter, optionsDriverFilter, t]);

  const vehicleTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleTypeFilter,
    labelName: t(FilterMessages.optionVehicleTypes),
    paramName: paramNameVehicleType,
    values: optionsVehicleTypeFilter.map((vehicleType) => ({ value: vehicleType.id_vehicle_type!, label: vehicleType.description }))
  }), [loadingVehicleTypeFilter, optionsVehicleTypeFilter, t]);

  const plantFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingPlantFilter,
    labelName: t(FilterMessages.optionPlants),
    paramName: paramNamePlant,
    values: optionsPlantFilter.map(
      (plant) => ({
        value: plant!.location!.id_location!,
        label: plant.name!
      })
    )
  }), [loadingPlantFilter, optionsPlantFilter, t]);

  const regionalFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingRegionalFilter,
    labelName: t(MenuMessages.regionals),
    paramName: paramNameRegional,
    values: optionsRegionalFilter.map(
      (regional) => ({
        value: regional.id_regional!,
        label: `${regional.code} - ${regional.name}`
      })
    )
  }), [loadingRegionalFilter, optionsRegionalFilter, t]);

  const groupFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingGroupFilter,
    labelName: t(MenuMessages.groups),
    paramName: paramNameGroup,
    values: optionsGroupFilter.map(
      (group) => ({
        value: group.id_group!,
        label: `${group.code} - ${group.name}`
      })
    )
  }), [loadingGroupFilter, optionsGroupFilter, t]);

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: utils.convertDateToISOWithTimezone(defaultEndDate)
    }
  };
  // endregion Constants
  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get("vehicles/read", { params: { linkedHardware: true, onlyLabel: true } });

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  /**
   * Load vehicle type options from the API.
   */
  const loadVehicleTypeOptions = async () => {
    try {

      // Get all alert types
      const { data } = await api.get("vehicles/types/read", { params: { translate: true } });

      if (data.status === "success") {
        const vehicleTypes = data.result.filter(
          (vehicleType: VehicleType) => vehicleType.id_vehicle_type === VehicleTypesID.BETONEIRA
            || vehicleType.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA
            || vehicleType.id_vehicle_type === VehicleTypesID.VEICULO_DE_APOIO
        );

        setOptionsVehicleTypeFilter(vehicleTypes);

        return;
      }

      setOptionsVehicleTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleTypeFilter(false);
    }
  };

  /**
   * Load plant options from the API.
   */
  const loadPlantOptions = async () => {
    try {

      // Get all plants
      const { data } = await api.get("plants/read", { params: { linkedLocation: true } });

      if (data.status === "success") setOptionsPlantFilter(data.result);
      else setOptionsPlantFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingPlantFilter(false);
    }
  };

  /**
   * Load driver options from the API.
   */
  const loadDriverOptions = async () => {
    try {

      // Get all drivers
      const { data } = await api.get("drivers/read");

      if (data.status === "success") setOptionsDriverFilter(data.result as Driver[]);
      else setOptionsDriverFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingDriverFilter(false);
    }
  };

  /**
   * Load regional options from the API.
   */
  const loadRegionalOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setOptionsRegionalFilter(data.result as Regional[]);
      else setOptionsRegionalFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingRegionalFilter(false);
    }
  };

  /**
   * Load group options from the API.
   */
  const loadGroupOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("groups/read");

      if (data.status === "success") setOptionsGroupFilter(data.result as Group[]);
      else setOptionsGroupFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingGroupFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[]) => {

    setLoadingVehicleHistory(true);

    try {

      const mappedParams = paramsData.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("vehicles/get-vehicles-history", {
        ...mappedParams,
        includeSupportVehicles: true
      });

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = validParamsData.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      const startDateValue = validParamsData.find((item) => item.paramName === paramNameStartDate)?.paramValue as string;
      const finishDateValue = validParamsData.find((item) => item.paramName === paramNameEndDate)?.paramValue as string;

      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? { syntheticData: [], analyticData: [] },
        startDateValue,
        finishDateValue
      );

      return { status: "success" } as IOnFilterReturn;

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoadingVehicleHistory(false);
    }
  };

  // endregion Functions
  // region Effects

  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions(),
      loadVehicleTypeOptions(),
      loadDriverOptions(),
      loadPlantOptions(),
      loadRegionalOptions(),
      loadGroupOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // endregion Effects

  return (
    <>
      <Loading loading={loadingVehicleHistory} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.VEHICLE_HISTORY}
        onFilter={(paramsData) => handleFilter(paramsData)}
        multipleSelectionOptions={[
          vehicleFilterOptions,
          driverFilterOptions,
          vehicleTypeFilterOptions,
          plantFilterOptions,
          regionalFilterOptions,
          groupFilterOptions
        ]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterVehicleHistory;
