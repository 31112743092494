/* eslint-disable camelcase, max-classes-per-file */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { Hardware } from "./reg_hardwares.entity";

@Entity("tpe_hardwares_types")
export class TpeHardware {

  @PrimaryGeneratedColumn("uuid")
    id_hardware_type?: string;

  @Column({ type: "varchar", nullable: false })
    description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Hardware, (hardwares) => hardwares.type)
    hardwares?: Hardware[];

}

export class TpeHardwareErrors {

  static readonly HARDWARE_TYPE_IN_USE = "3.3.1 (TCON333331)";

}
