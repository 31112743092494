/* eslint-disable no-param-reassign, no-underscore-dangle */

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";

// region Imports - Libraries
import Chart from "chart.js";
// endregion Imports - Libraries
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import { BarChart } from "@material-ui/icons";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  TravelHistoryMessages,
  VehiclesStatesTypesMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { Travel } from "@shared/interfaces/travel.interface";
import { CoordinateCommand } from "@shared/interfaces/coordinate-command.interface";
import { VehicleStatesTypesColors, VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { ReportTypes } from "@shared/constants/report-types.enum";
// endregion Imports - Shared
// region Imports - Components
import SegmentedControl from "@atoms/SegmentedControl";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import ButtonTable from "@components/Button/ButtonTable";
import { MapFixedPointCoord } from "@components/Map";
import { ToggleSwitch } from "@components/MapMarkerChangers/styles";
import DialogLandscape from "@atoms/DialogLandscape";
import QueryFilterCoordinatesTravelHistory from "@organisms/QueryFilterCoordinatesTravelHistory";
import { IDateRangeValues } from "@organisms/GenericQueryFilter";
// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import { Container, ContainerModal, ContainerSwitches } from "./styles";

// endregion Imports - Styles

interface CoordinateHistoryProps {
  travel: Travel;
  defaultDateRange?: IDateRangeValues;
}

const CoordinateHistory: React.FC<CoordinateHistoryProps> = ({ travel, defaultDateRange }) => {

  /* region Hooks */
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  const chartRef = useRef<Chart | null>(null);
  /* endregion Hooks */

  /* region States */
  const [loading, setLoading] = useState(false);

  const [coordinateCommands, setCoordinateCommands] = useState<CoordinateCommand[]>([]);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  // Chart states
  const [openSpeedChartModal, setOpenSpeedChartModal] = useState(false);
  const [showAverage, setShowAverage] = useState(true);
  const [showMovingAverage, setShowMovingAverage] = useState(true);
  const [showStatus, setShowStatus] = useState(true);

  // Filter states
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  // Report type
  const [reportType, setReportType] = useState<ReportTypes>(ReportTypes.ANALYTIC);

  const [screenOrientation, setScreenOrientation] = useState("");
  /* endregion States */
  /* region Constants */
  const movingAverageWindow = 10;
  const speedValues = coordinateCommands.map((record) => record.speed);
  const idValues = coordinateCommands.map((record) => record._id);

  // Create an array with the state type ID of each record (to be used in the chart)
  const stateTypesIDsArray = coordinateCommands.map((record) => record.stateTypeId!);

  // Map the colors to the status types (Add 60 to the end of the color to make it transparent)
  const statusColors = {
    [VehicleStatesTypesID.NA_USINA]: `${VehicleStatesTypesColors.NA_USINA}60`,
    [VehicleStatesTypesID.A_CAMINHO]: `${VehicleStatesTypesColors.A_CAMINHO}60`,
    [VehicleStatesTypesID.EM_OBRA]: `${VehicleStatesTypesColors.EM_OBRA}60`,
    [VehicleStatesTypesID.NO_DESTINO]: `${VehicleStatesTypesColors.NO_DESTINO}60`,
    [VehicleStatesTypesID.DESCARREGANDO]: `${VehicleStatesTypesColors.DESCARREGANDO}60`,
    [VehicleStatesTypesID.RETORNANDO]: `${VehicleStatesTypesColors.RETORNANDO}60`,
    [VehicleStatesTypesID.EM_LOCAL_CONFIAVEL]: `${VehicleStatesTypesColors.EM_LOCAL_CONFIAVEL}60`
  };
  /* endregion Constants */

  /* region Functions */

  /**
   * Apply the filter to the data and update the table
   */
  const handleApplyFilter = (numberOfFilteredOptions: number, travelAnalyticsData: CoordinateCommand[]) => {

    setLoading(true);

    try {

      setNumberOfFilteredOptions(numberOfFilteredOptions);
      setCoordinateCommands(travelAnalyticsData);
    } catch (error) {

      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Open map modal, using latitude and longitude from the selected coordinate
   */
  const handleOpenMap = useCallback((data: CoordinateCommand) => {

    setLatitude(Number(data.latitude));
    setLongitude(Number(data.longitude));
    setOpenMapModal(true);

  }, []);

  /**
   * Close map modal and reset latitude and longitude
   */
  const handleCloseMap = useCallback(() => {
    setLatitude(0);
    setLongitude(0);
    setOpenMapModal(false);
  }, []);

  /**
   * Calculate the median of an array of numbers, to be used in the speed chart
   * @param values
   */
  const calculateMedian = (values: number[]) => values.reduce((acc, val) => acc + val, 0) / values.length;

  /**
   * Calculate the moving average of an array of numbers, to be used in the speed chart
   * @param values
   */
  const calculateMovingAverage = (values: number[]) => {

    const movingAverage: (number | null)[] = [];

    for (let i = 0; i < values.length; i++) {
      if (i < movingAverageWindow - 1) {
        movingAverage.push(null); // Pad with null for the initial values
      } else if (i % movingAverageWindow === 0) {
        const average = values.slice(i - movingAverageWindow + 1, i + 1).reduce((sum, value) => sum + value, 0) / movingAverageWindow;

        movingAverage.push(average);
      } else {
        movingAverage.push(null);
      }
    }

    return movingAverage;
  };

  const handleChangeReportType = (value: string) => setReportType(value as ReportTypes);

  /**
   * Handle the generation and opening of the speed chart
   */
  const handleOpenSpeedChart = useCallback((rowData: CoordinateCommand) => {

    setOpenSpeedChartModal(true);

    // Find the index of the clicked row, using the id of the row (to highlight the point in the chart)
    const clickedIndex = idValues.findIndex((value) => value === rowData._id);

    // Map the status array to an array of the maximum speed value, so in chart it will be displayed as a bar
    // filling the entire height of the chart
    const statusData = stateTypesIDsArray.map(() => Math.max(...speedValues));

    const statusDataset = {
      label: t(TravelHistoryMessages.status),
      type: "bar",
      data: statusData,
      fill: true,
      pointRadius: 0,
      backgroundColor: (context: any) => {
        const status = stateTypesIDsArray[context.dataIndex];

        return statusColors[status];
      },
      barPercentage: 1,
      categoryPercentage: 1,
      hidden: !showStatus
    };

    // noinspection JSUnusedGlobalSymbols - This function is used in the chart creation
    const speedLineDataset = {
      label: t(TravelHistoryMessages.speed),
      data: speedValues,
      fill: false,
      borderColor: "#002951FF",
      pointRadius: (context: any) => (context.dataIndex === clickedIndex ? 6 : 0),
      pointBackgroundColor: (context: any) => (context.dataIndex === clickedIndex ? "#FF0000FF" : "#002951FF")
    };

    const averageLineDataset = {
      label: t(TravelHistoryMessages.average),
      borderColor: "#46C17DFF",
      pointBackgroundColor: "#46C17DFF",
      data: Array(speedValues.length).fill(calculateMedian(speedValues)),
      fill: false,
      pointRadius: 0,
      hidden: !showAverage
    };

    const movingAverageLineDataset = {
      label: `${t(TravelHistoryMessages.movingAverage)} (${movingAverageWindow} ${t(TravelHistoryMessages.records)})`,
      borderColor: "#FFC800FF",
      pointBackgroundColor: "#FFC800FF",
      data: calculateMovingAverage(speedValues),
      fill: false,
      pointRadius: 0,
      hidden: !showMovingAverage
    };

    // Define chart data
    const chartData = {
      labels: coordinateCommands.map((record) => utils.formatDateIfHave(record.occurrenceDate, "time")),
      datasets: [
        { ...statusDataset },
        { ...speedLineDataset },
        { ...averageLineDataset },
        { ...movingAverageLineDataset }
      ]
    };

    // Define chart options
    const chartOptions = {
      responsive: true,
      tooltips: {
        mode: "x-axis" as Chart.InteractionMode,
        intersect: false,
        callbacks: {
          label: (tooltipItem: any, data: any) => {
            const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;

            // If the dataset is the status dataset, return the status description
            if (datasetLabel === t(TravelHistoryMessages.status)) {

              const statusID = stateTypesIDsArray[tooltipItem.index];

              return t(VehiclesStatesTypesMessages[statusID]);
            }

            // If the dataset is the speed dataset, return the speed value (formatted)
            const speedValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

            return `${speedValue.toFixed(1)} km/h`;
          }
        }
      },
      elements: {
        line: {
          tension: 0.4
        }
      },
      spanGaps: true
    };

    // Get the canvas element and create the chart
    const canvas = document.getElementById("speedChart");

    if (canvas) {
      chartRef.current = new Chart(canvas as HTMLCanvasElement, {
        type: "line",
        data: chartData,
        options: chartOptions
      });
    }

  }, [coordinateCommands, t, showAverage, showMovingAverage, idValues, showStatus, speedValues, stateTypesIDsArray, statusColors]);

  /* endregion Functions */

  /* region DataTable */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    { ref: ".map", callback: (rowData: CoordinateCommand) => handleOpenMap(rowData) },
    { ref: ".openSpeedChart", callback: (rowData: CoordinateCommand) => handleOpenSpeedChart(rowData) }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Occurrence date
      title: t(TravelHistoryMessages.occurrenceDate),
      className: "print csv",
      data: (record: CoordinateCommand) => (utils.formatDateIfHave(record.occurrenceDate, "fullDate")),
      filterable: false
    },
    { // Interval Between Last Record
      title: t(TravelHistoryMessages.intervalBetweenLastCommand),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.intervalBetweenLastCommand),
      defaultContent: "",
      filterable: false
    },
    { // Registration date
      title: t(TravelHistoryMessages.registrationDate),
      className: "print csv",
      data: (record: CoordinateCommand) => (utils.formatDateIfHave(record.registrationDate, "fullDate")),
      filterable: false
    },
    { // Status
      title: t(TravelHistoryMessages.status),
      className: "print csv",
      data: (record: CoordinateCommand) => (t(VehiclesStatesTypesMessages[record.stateTypeId!])),
      filterable: false
    },
    { // Ignition
      title: t(TravelHistoryMessages.ignition),
      className: "print csv",
      data: (record: CoordinateCommand) => {
        const color = record.ignition ? "#46C17DFF" : "#E64A19FF";

        return (
          ReactDOMServer.renderToString(
            <b style={{ color }}>
              {record.ignition ? t(TravelHistoryMessages.on) : t(TravelHistoryMessages.off)}
            </b>
          )
        );

      },
      filterable: false
    },
    { // Speed
      title: t(TravelHistoryMessages.speed),
      className: "print csv",
      data: (record: CoordinateCommand) => (`${record.speed.toFixed(1)} km/h`),
      filterable: false
    },
    { // Latitude
      title: t(TravelHistoryMessages.latitude),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.latitude),
      filterable: false
    },
    { // Longitude
      title: t(TravelHistoryMessages.longitude),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.longitude),
      filterable: false
    },
    { // Travel doc
      title: t(TravelHistoryMessages.document),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.travelDoc),
      filterable: false
    },
    { // Driver
      title: t(GlobalMessages.driver),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.driverLabel),
      filterable: false
    },
    {
      title: t(GlobalMessages.vehicle),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.vehicleCode),
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.vehiclePlate),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.vehiclePlate),
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.vehicleType),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.vehicleType),
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.plant),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.plantLabel),
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.regional),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.regionalLabel),
      filterable: false
    },
    {
      title: t(TravelHistoryMessages.group),
      className: "print csv",
      data: (record: CoordinateCommand) => (record.groupLabel),
      filterable: false
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      width: "130px",
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1} justify="space-between">
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button map"><MapIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button openSpeedChart"><BarChart /></ButtonTable>
          </Grid>
        </Grid>
      ),
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: "relatorio_viagens",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion DataTable */

  /* region Effects */

  /**
   * Used to update the chart when the toggles are changed (showAverage and showMovingAverage)
   */
  useEffect(() => {
    if (chartRef.current?.data?.datasets) {

      chartRef.current.data.datasets[0].hidden = !showStatus;
      chartRef.current.data.datasets[2].hidden = !showAverage;
      chartRef.current.data.datasets[3].hidden = !showMovingAverage;
      chartRef.current.update();
    }

  }, [showAverage, showMovingAverage, showStatus]);

  /**
   * Screen orientation change listener
   */
  useEffect(() => {
    if (screen.width && screen.height) {

      if (screen.width > screen.height) {
        setScreenOrientation("landscape");
      } else {
        setScreenOrientation("portrait");
      }
    }
  }, [screen]);
  /* endregion Effects */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <SegmentedControl
          className="segmented-control"
          name="name"
          segments={[
            { value: "analytic" as ReportTypes, label: t(TravelHistoryMessages.analyticReport) },
            { value: "synthetic" as ReportTypes, label: t(TravelHistoryMessages.syntheticReport) }
          ]}
          defaultIndex={0} // Default index is 0 (analytic)
          onChange={handleChangeReportType}
        />
        {!loading && (
          <NewDataTable
            title={t(TravelHistoryMessages.travelHistoryTitle)}
            filters
            data={coordinateCommands}
            columns={dataTableColumns}
            actions={dataTableActions}
            settings={dataTableSettings}
            buttons={dataTableButtons}
            onClickFilterButton={() => setOpenFilterFields(true)}
            numberOfFilteredOptions={numberOfFilteredOptions}
          />
        )}
      </Container>
      <QueryFilterCoordinatesTravelHistory
        open={openFilterFields}
        onClose={() => setOpenFilterFields(false)}
        initialTravelData={travel.vehicle ? travel : undefined}
        initialDateRange={defaultDateRange}
        reportType={reportType}
        onFilter={handleApplyFilter}
      />
      <ContainerModal id="modalMapMarkerLocation">
        <Dialog
          open={openMapModal}
          onClose={handleCloseMap}
          scroll="paper"
          container={document.getElementById("modalMapMarkerLocation")}
        >
          <DialogContent dividers className="mContent">
            <DialogContentText tabIndex={-1} component="div">
              <MapFixedPointCoord
                mapHeight={500}
                latitude={latitude}
                longitude={longitude}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={handleCloseMap} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModal>
      <ContainerModal id="modalSpeedChart">
        <Dialog
          open={openSpeedChartModal}
          onClose={() => setOpenSpeedChartModal(false)}
          scroll="paper"
          container={document.getElementById("modalSpeedChart")}
        >
          <DialogContent dividers className="mContent">
            <canvas id="speedChart" />
          </DialogContent>
          <DialogActions className="mFooter">
            <Button disableRipple onClick={() => setOpenSpeedChartModal(false)} color="primary">{t(GlobalMessages.close)}</Button>
            <ContainerSwitches>
              <FormControlLabel
                control={(
                  <ToggleSwitch
                    checked={showAverage}
                    onChange={() => setShowAverage(!showAverage)}
                  />
            )}
                label={t(TravelHistoryMessages.average)}
              />
              <FormControlLabel
                control={(
                  <ToggleSwitch
                    checked={showMovingAverage}
                    onChange={() => setShowMovingAverage(!showMovingAverage)}
                  />
              )}
                label={t(TravelHistoryMessages.movingAverage)}
              />
              <FormControlLabel
                control={(
                  <ToggleSwitch
                    checked={showStatus}
                    onChange={() => setShowStatus(!showStatus)}
                  />
                )}
                label={t(TravelHistoryMessages.status)}
              />
            </ContainerSwitches>
          </DialogActions>
        </Dialog>
        <DialogLandscape isOpen={openSpeedChartModal && screenOrientation === "portrait"} />
      </ContainerModal>
    </>
  );
};

export default CoordinateHistory;
