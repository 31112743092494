import { TranslationMailMessages, MailMessages } from "../interfaces";

export const messages: TranslationMailMessages = {

  // Password Reset
  [MailMessages.passwordReset]: "Restablecimiento de contraseña",
  [MailMessages.looksLikeYouForgotYourPassword]: "Parece que olvidaste tu contraseña para",
  [MailMessages.clickHereToResetYourPassword]: "Haga clic a continuación para restablecer su contraseña.",
  [MailMessages.setYourNewPassword]: "Establezca su nueva contraseña.",
  [MailMessages.resetMyPassword]: "Restablecer mi contraseña",
  [MailMessages.ignoreEmailIfPasswordNotForgotten]: "Si no olvidó su contraseña, puede ignorar este correo electrónico.",

  // Account confirmation
  [MailMessages.accountConfirmation]: "Confirmación de cuenta",
  [MailMessages.welcomeTo]: "Bienvenido a",
  [MailMessages.accessToPlatform]: "Acceso a la plataforma",
  [MailMessages.confirmYourAccount]: "Confirme su cuenta y cree su contraseña para acceder a la plataforma.",
  [MailMessages.createMyPassword]: "Crear mi contraseña",

  // General
  [MailMessages.hello]: "Hola",
  [MailMessages.needMoreInformation]: "¿Tiene alguna pregunta o necesita más información?",
  [MailMessages.sendUsAnEmail]: "Envíenos un correo electrónico",
  [MailMessages.bestRegards]: "Saludos cordiales",
  [MailMessages.distributedBy]: "Distribuido por",

  // Report
  [MailMessages.dailyReportSubject]: "Informe diario - TopconFLEET",
  [MailMessages.weeklyReportSubject]: "Informe semanal - TopconFLEET",
  [MailMessages.syntheticReportFileName]: "informe-sintetico.pdf",
  [MailMessages.analyticalReportFileName]: "informe-analitico.pdf",
  [MailMessages.belowYouCanSeeTheAlertGraphs]: "A continuación puede ver los gráficos de alerta",
  [MailMessages.ofTheLastTwoWeeks]: "de las últimas dos semanas",
  [MailMessages.ofTheLastTwoDays]: "de los últimos dos días",
  [MailMessages.moreInformationCanBeFoundAt]: "Información más detallada se puede encontrar en los informes PDF adjuntos."
};
