export enum ConfigCommandsContentID {
  PRESET_A = "11b76357-2986-44ba-af2e-b8273ab76035",
  ALTERACAO_TEMPO_ENVIO_DADOS = "1c1cab29-4e11-41e7-b983-220f745ed3d4",
  ALTERACAO_IGNICAO_ACELEROMETRO = "75eefc9e-29d2-41cb-9d15-98825765bb1a",
  ALTERACAO_IGNICAO_BATERIA = "137beacd-0dfd-414d-a415-3c56a176a290",
  HABILITAR_IGNICAO_FISICA = "b8962d6a-1293-4e19-ae30-782295e1ffdf",
  DESABILITAR_IGNICAO_FISICA = "0cfa05b8-67a9-44f2-83a4-b359ccb76d7a",
  REINICIAR = "6644c9a7-7e99-47e7-b7da-30adfe3485fc",
  LIMPAR_MEMORIA = "4e071281-9614-4801-bdfd-b8d9b74c4909",
  ALTERACAO_TEMPO_ENVIO_DADOS_BOMBA = "bdae1e00-68fb-40d6-b608-1d3a348dd422",
  ALTERACAO_TEMPO_ENVIO_DADOS_BETONEIRA = "70b744ee-da75-4423-bf49-cf6d30b52567",
  ALTERACAO_ODOMETRO = "64e92b3e-f2a0-47d0-82fa-b785b263a19d",
  ALTERACAO_HORIMETRO = "6e75d117-3465-441e-a5e8-ec1c4502e5be",
  ALTERACAO_SERVIDOR_DESTINO = "e3b46db0-5b66-4c75-b7f2-7d8f30c53a48",
  CHECAR_ICCID_ST300 = "9d5596c0-c58c-459c-adbf-4aad7a6eac1c",
  CHECAR_ICCID_ST380 = "f0b10bf3-eeff-4678-90d9-ed0f0ff9590f",
  CHECAR_ICCID_ST4315U = "12dcc9cb-bede-4d00-bec1-5a0accbcfb1f",
  ALTERACAO_ODOMETRO_4315_8310 = "3549db84-864f-4815-bb4d-49098ab26f6f",
  ALTERACAO_HORIMETRO_4315_8310 = "5d15f7ab-8948-473f-b739-7ee7f61b7a78",
}
