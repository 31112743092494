/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { Vehicle } from "./reg_vehicles.entity";

@Entity("tpe_vehicles_types")
export class TpeVehicle {

  @PrimaryGeneratedColumn("uuid")
  id_vehicle_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => Vehicle, (vehicles) => vehicles.type)
  vehicles?: Vehicle[];

}
