import React from "react";

// region Assets
import IconMaintenanceVehicle from "@assets/icons/maintenance-vehicle.svg";
// endregion Assets
// region Libraries
import { useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { VehicleMaintenanceMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Interfaces
// region Styles
import { VehicleMaintenanceHeaderStylesheet as Stylesheet } from "@components/Modal/VehicleMaintenance/VehicleMaintenanceHeader/styles";
import { ReactSVG } from "react-svg";
// endregion Styles

const VehicleMaintenanceHeader: React.FC = () => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control

  return (
    <Stylesheet isMobile={isMobile}>
      <div className="vehicle-maintenance-header">
        <div className="vehicle-maintenance-header__title">
          <div
            className="iconContainer"
            style={{ backgroundColor: "#666666" }}
          >
            <ReactSVG className="icon" src={IconMaintenanceVehicle} />
          </div>
          <div className="content">
            <div className="content__title">
              {t(VehicleMaintenanceMessages.youAreAboutToPutTheVehicle)}
              <strong className="strong">{t(VehicleMaintenanceMessages.inMaintenance).toLowerCase()}</strong>.
            </div>
            <div className="content__subtitle">
              {t(VehicleMaintenanceMessages.yourActionWillPutTheVehicleInMaintenance)}
            </div>
          </div>
        </div>
      </div>
    </Stylesheet>

  );
};

export default VehicleMaintenanceHeader;
