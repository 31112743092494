export enum SIMcardsModuleMessages {
  /* region Backend messages */
  createSIMCardSuccess = "simcardsModule.createSIMCardSuccess",
  createSIMCardError = "simcardsModule.createSIMCardError",
  readSIMCardSuccess = "simcardsModule.readSIMCardSuccess",
  noSIMCardFound = "simcardsModule.noSIMCardFound",
  readSIMCardError = "simcardsModule.readSIMCardError",
  updateSIMCardSuccess = "simcardsModule.updateSIMCardSuccess",
  SIMCardNotFound = "simcardsModule.SIMCardNotFound",
  updateSIMCardError = "simcardsModule.updateSIMCardError",
  deleteSIMCardSuccess = "simcardsModule.deleteSIMCardSuccess",
  deleteSIMCardError = "simcardsModule.deleteSIMCardError",
  /* endregion Backend messages */

  /* region Frontend messages */
  company = "simcardsModule.company",
  status = "simcardsModule.status",
  active = "simcardsModule.active",
  inactive = "simcardsModule.inactive",
  deleteConfirmationTitle = "simcardsModule.deleteConfirmationTitle",
  deleteConfirmationMessage = "simcardsModule.deleteConfirmationMessage",
  SIMCardRegistration = "simcardsModule.SIMCardRegistration",
  activeSIMCard = "simcardsModule.activeSIMCard",
  plan = "simcardsModule.plan",
  purchaseDate = "simcardsModule.purchaseDate",
  activationDate = "simcardsModule.activationDate"
  /* endregion Frontend messages */
}

export type TranslationSIMCardsModuleMessages = {
  [key in SIMcardsModuleMessages]: string;
}
