export enum ParamsNames {
  GOOGLE = "google",
  CLIENT_NAME = "client_name",
  CLIENT_IDENTIFIER = "client_identifier",
  SECONDS_FOR_UNDUE_STOP = "seconds_for_undue_stop",
  PUMP_TIMELINE_ENABLED = "pump_timeline_enabled",
  BLOCK_DATE = "block_date",
  AZURE_CLIENT_ID = "azure_client_id",
  AZURE_TENANT_ID = "azure_tenant_id",
  AZURE_CLIENT_SECRET = "azure_client_secret",
  AZURE_SSO_ENABLED = "azure_sso_enabled"
}

export enum ParamsKeys {
  PUMP_TIMELINE_ENABLED_TRUE = "y",
  PUMP_TIMELINE_ENABLED_FALSE = "n"
}
