import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding: 2px 0;

  .coordinate-analysis-details__first {
    font-weight: 500;
  }
  .coordinate-analysis-details__second {
    color: rgb(0 0 0 / 60%);
  }

`;

export { Container, DetailsItem };
