/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

// region Libraries
import { Dialog, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { ContactUsMessages, GlobalMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Assets
import LogoFleet from "@assets/FleetLogo/loginLogo.svg";
// endregion Assets
// region Styles
import { Container } from "./styles";
// endregion Styles

type ModalContactUsProps = { onClose: () => void }
const ModalContactUs: React.FC<ModalContactUsProps> = ({ onClose }) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Container>
      <Dialog
        id="modalContactUs"
        open={open}
        onClose={() => onClose()}
        scroll="paper"
      >
        <DialogContent dividers className="mContent">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="logo">
                <ReactSVG alt="Topcon Fleet" src={LogoFleet} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="read-content">
                <div className="about">
                  {t(ContactUsMessages.developedBy)}
                  <br />
                  {t(ContactUsMessages.topcon)}
                  <br />
                  {t(ContactUsMessages.allRightsReserved)} | {new Date().getFullYear()}
                </div>
                <div className="contacts">
                  <div className="item" onClick={() => window.location.href = "mailto:suporte@topconsuite.com"}>
                    <div className="icon"> <MailOutlineIcon /> </div>
                    <div className="text">suporte@topconsuite.com</div>
                  </div>
                  <div className="item" onClick={() => window.location.href = "tel:+551127551817"}>
                    <div className="icon"> <PhoneIcon /> </div>
                    <div className="text">+55 (11) 2755-1817</div>
                  </div>
                  <div className="item" onClick={() => window.location.href = "https://web.whatsapp.com/send?phone=551135643314"}>
                    <div className="icon"> <WhatsAppIcon /> </div>
                    <div className="text">+55 (11) 3564-3314</div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="mFooter">
          <Button
            disableRipple
            onClick={() => onClose()}
            color="primary"
          >
            {t(GlobalMessages.close)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ModalContactUs;
