/* eslint-disable import/prefer-default-export */
import { TimelineVehicleStatesMessages, TranslationTimelineVehicleStatesMessages } from "../interfaces";

export const messages: TranslationTimelineVehicleStatesMessages = {
  [TimelineVehicleStatesMessages.statesTitle]: "Estados",
  [TimelineVehicleStatesMessages.manometer]: "Manómetro",
  [TimelineVehicleStatesMessages.blockedVehicle]: "Vehículo bloqueado",
  [TimelineVehicleStatesMessages.blockingDate]: "Momento del bloqueo",
  [TimelineVehicleStatesMessages.postUnloadIdleTime]: "Inactividad post-descarga"
};
