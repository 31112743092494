import React, { useCallback, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";

// region Imports - Libraries
import * as dateFns from "date-fns";
import _ from "lodash";
// endregion Imports - Libraries
// region Imports - Utils
import utils from "@utils/useful-functions";
import { formatOdometer } from "@shared/utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import { Grid } from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SwapIcon from "@material-ui/icons/SwapCalls";
import ExploreIcon from "@material-ui/icons/Explore";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  LocationTypeMessages,
  ToastMessages,
  TravelHistoryMessages,
  VehiclesModuleMessages,
  VehicleTypesMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { AlertTypes } from "@shared/constants/alert-types.enum";
import { VehicleStatesTypes } from "@shared/constants/vehicle-states-types.enum";
import { Alert } from "@shared/interfaces/alert.interface";
import { Travel } from "@shared/interfaces/travel.interface";
import { VehicleState } from "@shared/interfaces/vehicle-state.interface";
import { WaterAddition } from "@shared/interfaces/water-addition.interface";
// endregion Imports - Shared
// region Imports - Components
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import QueryFilterTravelHistory from "@organisms/QueryFilterTravelHistory";
import ButtonTable from "@components/Button/ButtonTable";
import { ModalTravelSwap } from "@components/Modal/TravelSwap";
import { ModalVehicleAndTravelDetails } from "@components/Modal/VehicleAndTravelDetails";
import { IDateRangeValues } from "@organisms/GenericQueryFilter";
// endregion Imports - Components
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import { Container } from "./styles";
// endregion Imports - Styles

interface TravelHistoryProps {
  onOpenTravelCoordinates: (travel: Travel, dateRange: IDateRangeValues) => void;
}

const TravelsHistory: React.FC<TravelHistoryProps> = ({ onOpenTravelCoordinates }) => {

  // region States
  const [travels, setTravels] = useState<Travel[]>([] as Array<Travel>);
  const [travelDetails, setTravelDetails] = useState<Travel>({} as Travel);
  const [travelStates, setTravelStates] = useState<VehicleState[]>([] as Array<VehicleState>);
  const [travelAlerts, setTravelAlerts] = useState<Alert[]>([] as Array<Alert>);
  const [travelWaterAdditions, setTravelWaterAdditions] = useState<WaterAddition[]>([] as Array<WaterAddition>);
  const [travelSwapDetails, setTravelSwapDetails] = useState<Travel>({} as Travel);

  const [openModalVehicleAndTravelDetails, setOpenModalVehicleAndTravelDetails] = useState(false);

  const [loadingTravelDetails, setLoadingTravelDetails] = useState(false);
  const [loadMoreToggle, setLoadMoreToggle] = useState(false);

  const [openFilterFields, setOpenFilterFields] = useState(false);

  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);
  /* endregion States */
  // region Refs
  const startDateRef = React.useRef(utils.convertDateToISOWithTimezone(dateFns.subDays(new Date(), 1)));
  const finishDateRef = React.useRef(utils.convertDateToISOWithTimezone(new Date()));
  // endregion Refs
  /* region Hooks */
  const { user } = useAuth();
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  /* endregion Hooks */
  /* region Constants */
  // Create config to specific state
  const createTableColumnsStates = (title: string, stateType: VehicleStatesTypes) => [
    {
      title,
      className: "print",
      orderable: false,
      searchable: false,
      visible: false,
      data: (travel: Travel) => calculateTotalDurationByState(travel, stateType, "durationDescriptiveTime"),
      filterable: false
    },
    {
      title: `${title} - ${t(TravelHistoryMessages.time)}`,
      className: "csv",
      orderable: false,
      searchable: false,
      visible: false,
      data: (travel: Travel) => calculateTotalDurationByState(travel, stateType, "durationIntervalTime"),
      filterable: false
    },
    {
      title: `${title} - ${t(TravelHistoryMessages.distance).toLowerCase()}`,
      className: "csv",
      orderable: false,
      searchable: false,
      visible: false,
      data: (travel: Travel) => calculateTotalDistanceByState(travel, stateType),
      filterable: false
    }
  ];

  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    { ref: ".travel-history-details", callback: (rowData: Travel) => readTravelDetails(rowData) },
    { ref: ".travel-coordinates", callback: (rowData: Travel) => handleOpenTravelCoordinates(rowData) },
    ...(user.super_admin ? [
      {
        ref: ".travel-swap",
        callback: (rowData: Travel) => setTravelSwapDetails(rowData)
      }
    ] : [])
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Veículo
      title: t(GlobalMessages.vehicle),
      className: "print csv",
      data: (travel: Travel) => (travel.vehicle.code),
      defaultContent: "",
      filterable: true,
      propertyName: "vehicle.code"
    },
    { // Tipo de Veículo
      title: t(VehiclesModuleMessages.vehicleType),
      className: "print csv",
      data: (travel: Travel) => (t(VehicleTypesMessages[travel.vehicle.type.id_vehicle_type])),
      defaultContent: "",
      filterable: true,
      propertyName: "vehicle.type.id_vehicle_type"
    },
    { // Início
      title: t(GlobalMessages.start),
      className: "print csv",
      data: (travel: Travel) => {

        // noinspection UnnecessaryLocalVariableJS
        const ret = travel.states.filter((state) => state.status.description === VehicleStatesTypes.A_CAMINHO)?.[0]?.registration_date;

        return ret;
      },
      render: (data, type) => (type === "sort" ? data : (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm"))),
      filterable: false
    },
    { // Fim
      title: t(GlobalMessages.end),
      className: "print csv",
      data: (travel: Travel) => travel.finish_date,
      render: (data, type) => (type === "sort" ? data : (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm"))),
      filterable: false
    },
    { // Duração
      title: t(GlobalMessages.duration),
      className: "print",
      data: (travel: Travel) => travel,
      render: (data) => {

        const registrationDate = data.states.filter((state) => state.status.description === VehicleStatesTypes.A_CAMINHO)?.[0]?.registration_date;

        return utils.formatDateIfHave(
          utils.calcDataRange(registrationDate, data.finish_date), "durationDescriptiveTime"
        );
      },
      filterable: true,
      propertyName: "vehicle.duration",
      filterTitle: t(TravelHistoryMessages.durationInMinutes),
      toFilterValue: (data: Travel) => {
        const registrationDate = data.states.filter((state) => state.status.description === VehicleStatesTypes.A_CAMINHO)?.[0]?.registration_date;

        const realDuration = utils.calcDataRange(registrationDate, data.finish_date);

        if (realDuration === "") {
          return 0;
        }

        return (realDuration.hours ?? 0) * 60 + (realDuration.minutes ?? 0);
      },
      width: "5%"
    },
    { // Duração
      title: t(GlobalMessages.duration),
      className: "csv",
      orderable: false,
      searchable: false,
      visible: false,
      data: (travel: Travel) => travel,
      render: (data) => utils.formatDateIfHave(
        utils.calcDataRange(data.states[0].registration_date, data.finish_date), "durationIntervalTime"
      ),
      filterable: false
    },
    { // Distancia
      title: t(TravelHistoryMessages.distance),
      className: "print",
      data: (travel: Travel) => travel,
      render: (data) => formatOdometer(data.finish_odometer - data.start_odometer),
      filterable: true,
      propertyName: "vehicle.finish_odometer",
      filterTitle: t(TravelHistoryMessages.distanceInKilometers),
      toFilterValue: (data: Travel) => (data.finish_odometer - data.start_odometer) / 1000
    },
    { // Distancia
      title: t(TravelHistoryMessages.distance),
      className: "csv",
      orderable: false,
      searchable: false,
      visible: false,
      data: (travel: Travel) => travel,
      render: (data) => formatOdometer(data.finish_odometer - data.start_odometer, false),
      filterable: false
    },
    { // Velocidade Média
      title: t(TravelHistoryMessages.averageSpeed),
      className: "print csv",
      data: (travel: Travel) => (travel.average_speed ? `${travel.average_speed} km/h` : ""),
      filterable: true,
      propertyName: "average_speed",
      width: "3%"
    },
    { // Numero Documento
      title: t(TravelHistoryMessages.document),
      className: "print csv",
      data: (travel: Travel) => (travel.num_doc ? travel.num_doc : ""),
      filterable: true,
      propertyName: "num_doc"
    },
    { // Motorista
      title: t(GlobalMessages.driver),
      className: "print csv",
      data: (travel: Travel) => (travel.driver ? travel.driver.name : ""),
      filterable: true,
      propertyName: "driver.name"
    },
    { // Origem
      title: t(TravelHistoryMessages.origin),
      className: "print csv",
      data: (travel: Travel) => (
        ReactDOMServer.renderToString(
          <>
            <b>{t(LocationTypeMessages[travel.origin.type.id_location_type])}: </b>
            {
                travel.origin.name || `${travel.origin.address},`
                + `${travel.origin.number || ""} - ${travel.origin.district || ""}`
                + `| ${travel.origin.county} - ${travel.origin.uf}`
              }
          </>
        )
      ),
      filterable: true,
      propertyName: "origin.name"
    },
    { // Destino
      title: t(TravelHistoryMessages.destination),
      className: "print csv",
      data: (travel: Travel) => (
        ReactDOMServer.renderToString(
          <>
            {travel.destination
              ? (
                <>
                  <b>{t(LocationTypeMessages[travel.destination.type.id_location_type])}: </b>
                  {
                      travel.destination.name || `${travel.destination.address},`
                      + `${travel.destination.number || ""} - ${travel.destination.district || ""}`
                      + `| ${travel.destination.county} - ${travel.destination.uf}`
                    }
                </>
              )
              : <></>}
          </>
        )
      ),
      filterable: true,
      propertyName: "destination.name",
      width: "20%"
    },
    { // Ponto final
      title: t(TravelHistoryMessages.finishPoint),
      className: "print csv",
      data: (travel: Travel) => (
        ReactDOMServer.renderToString(
          <>
            {travel.end
              ? (
                <>
                  <b>{t(LocationTypeMessages[travel.end.type.id_location_type])}:</b> {travel.end.name || ""}
                </>
              )
              : <></>}
          </>
        )
      ),
      filterable: true,
      propertyName: "end.name"
    },
    ...createTableColumnsStates("Usina", VehicleStatesTypes.NA_USINA),
    ...createTableColumnsStates("Caminho", VehicleStatesTypes.A_CAMINHO),
    ...createTableColumnsStates("Obra", VehicleStatesTypes.EM_OBRA),
    ...createTableColumnsStates("Descarga", VehicleStatesTypes.DESCARREGANDO),
    ...createTableColumnsStates("Retorno", VehicleStatesTypes.RETORNANDO),
    ...createTableColumnsStates("Local confiável", VehicleStatesTypes.EM_LOCAL_CONFIAVEL),
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => {
        const size = user.super_admin ? 6 : 12;

        return ReactDOMServer.renderToString(
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={size} sm={size} md={size} lg={size} xl={size}>
              <ButtonTable className="action-button travel-history-details"><FormatListBulletedIcon /></ButtonTable>
            </Grid>
            <Grid item xs={size} sm={size} md={size} lg={size} xl={size}>
              <ButtonTable className="action-button travel-coordinates"><ExploreIcon /></ButtonTable>
            </Grid>
            {user.super_admin && (
            <Grid item xs={size} sm={size} md={size} lg={size} xl={size}>
              <ButtonTable className="action-button travel-swap"><SwapIcon /></ButtonTable>
            </Grid>
            )}
          </Grid>
        );
      },
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_viagens_${startDateRef.current}__${finishDateRef.current}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.moreRecords),
      callback: () => {
        setLoadMoreToggle((prevState) => !prevState);
      },
      key: "moreRecords"
    }
  ];
  /* endregion Constants */
  /* region Components */
  /** Instance Travel Swap Modal and create callback function to reload table when save
   */
  const ModalTravelSwapComponent = useMemo(() => (
    <ModalTravelSwap
      travel={travelSwapDetails}
      startDate={startDateRef.current}
      finishDate={finishDateRef.current}
      onClose={(status?: "success") => {
        if (status) {
          setLoadingTravelDetails(true);
        }
        setTravelSwapDetails({} as Travel);
      }}
    />
  ), [travelSwapDetails, setLoadingTravelDetails]);

  /** Search details of selected travel (including states and events of selected travel)
   * @param travel Target travel
   */
  const readTravelDetails = useCallback(async (travel: Travel) => {

    // Set travel row clicked details data
    setTravelDetails(travel);
    setTravelAlerts([] as Alert[]);
    setTravelWaterAdditions([] as WaterAddition[]);

    // Internal functions
    const FreadTravelStates = async () => await (await api.get(`vehicles/states/get-filtered-by-travel/${travel.id_travel}`)).data;
    const FreadTravelAlerts = async (params) => await (await api.post("alerts/read", params)).data;
    const FreadWaterAdditions = async () => await (await api.get(`water-additions/get-filtered-by-travel/${travel.id_travel}`)).data;

    // Get details of selected travel
    setLoadingTravelDetails(true);

    try {

      let alertMessageContent = "";

      const promise1 = FreadTravelStates(); // Get travel states
      const promise2 = FreadTravelAlerts({ idTravel: travel.id_travel, justified: false }); // Get travel alerts
      const promise3 = FreadWaterAdditions(); // Get travel water addition events

      const [travelStatesResponse, travelAlertsResponse, travelWaterAdditionsResponse] = await Promise.all([
        promise1, promise2, promise3
      ]);

      // Verify if have some alert in states travel response
      if (travelStatesResponse.status === "success") setTravelStates(travelStatesResponse.result);
      else alertMessageContent += travelStatesResponse.message;

      // Verify if have some alert in events travel response
      if (travelAlertsResponse.status === "success") {
        setTravelAlerts(travelAlertsResponse.result
          .filter((alert: Alert) => [
            AlertTypes.EXCESSO_DE_VELOCIDADE,
            AlertTypes.DESCARREGAMENTO_INDEVIDO,
            AlertTypes.PARADA_INDEVIDA,
            AlertTypes.ZMRC
          ].includes(alert.type.description as AlertTypes)));
      } else alertMessageContent += travelAlertsResponse.message;

      // Verify if have some alert in states travel response
      if (travelWaterAdditionsResponse.status === "success") setTravelWaterAdditions(travelWaterAdditionsResponse.result);
      else if (travelWaterAdditionsResponse.status === "error") alertMessageContent += travelWaterAdditionsResponse.message;

      // If have some alert, show message to user
      if (!_.isEmpty(alertMessageContent)) addToast({ type: "info", title: t(ToastMessages.alert), description: alertMessageContent });

      setOpenModalVehicleAndTravelDetails(true);

    } catch (error) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingTravelDetails(false);
    }
  }, [addToast, t]);

  /* endregion Components */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: Travel[],
    loadMore: boolean,
    startDate: string,
    finishDate: string
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    if (loadMore) setTravels([...travels, ...returnedData]);
    else setTravels(returnedData);

    startDateRef.current = startDate;
    finishDateRef.current = finishDate;
  };

  /**
   * Handle open travel coordinates with specific travel and date range
   */
  const handleOpenTravelCoordinates = (travel: Travel) => {

    onOpenTravelCoordinates(travel, { startDate: startDateRef.current, endDate: finishDateRef.current });
  };

  /** Instance Travel Details Modal as memo to prevent unnecessary renderings */
  const ModalVehicleAndTravelDetailsComponent = useMemo(() => (
    <ModalVehicleAndTravelDetails
      vehicle={{ ...travelDetails.vehicle, states: travelStates, alerts: travelAlerts }}
      travel={{ ...travelDetails, water_additions: travelWaterAdditions }}
      open={openModalVehicleAndTravelDetails}
      onClose={() => setOpenModalVehicleAndTravelDetails(false)}
      historical
    />
  ), [
    travelDetails,
    travelStates,
    travelAlerts,
    travelWaterAdditions,
    openModalVehicleAndTravelDetails,
    setOpenModalVehicleAndTravelDetails
  ]);

  // Sum and format duration from specific state
  const calculateTotalDurationByState = (
    travel: Travel,
    stateType: VehicleStatesTypes,
    format: "durationDescriptiveTime" | "durationIntervalTime"
  ) => utils.formatDateIfHave(
    travel.states
      .filter((state) => state.status.description === stateType)
      .reduce((acc, curr) => ({
        seconds: acc.seconds + (curr?.duration?.seconds ?? 0),
        minutes: acc.minutes + (curr?.duration?.minutes ?? 0),
        hours: acc.hours + (curr?.duration?.hours ?? 0)
      }), { hours: 0, minutes: 0, seconds: 0 }), format
  );

  // Sum and format distance from specific state
  const calculateTotalDistanceByState = (
    travel: Travel,
    stateType: VehicleStatesTypes,
    appendKM = false
  ) => formatOdometer(
    travel.states
      .filter((state) => state.status.description === stateType)
      .reduce((acc, curr) => acc + ((curr.finish_odometer ?? 0) - (curr.start_odometer ?? 0)), 0),
    appendKM
  );

  /* endregion Functions */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingTravelDetails} />
        <NewDataTable
          title={t(TravelHistoryMessages.travelHistoryTitle)}
          filters
          data={travels}
          columns={dataTableColumns}
          actions={dataTableActions}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
        {!_.isEmpty(travelDetails) && openModalVehicleAndTravelDetails && (
          ModalVehicleAndTravelDetailsComponent
        )}
        {user.super_admin && !_.isEmpty(travelSwapDetails) && (
          ModalTravelSwapComponent
        )}
        <QueryFilterTravelHistory
          open={openFilterFields}
          onClose={() => { setOpenFilterFields(false); }}
          onFilter={handleApplyFilter}
          loadMoreToggle={loadMoreToggle}
        />
      </Container>
    </>
  );
};

export default TravelsHistory;
