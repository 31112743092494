import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
}

const className = "vehicle-maintenance__dialog";

const ModalVehicleMaintenance = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  .vehicle-maintenance-dialog__list {
    flex-direction: ${(props) => (props.isMobile ? "column" : "")};
    align-items: ${(props) => (props.isMobile ? "flex-start;" : "")};
  }

  .vehicle-maintenance-dialog__item-grid {
    display: ${(props) => (props.isMobile ? "flex" : "")};
  }

  .maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .vehicle-maintenance-dialog__instruction {
    display: flex;
    line-height: 1.4;
    align-items: center;
    gap: 0.2rem;

    .strong {
      color: #666666;
    }
  }
`;

export { ModalVehicleMaintenance };
