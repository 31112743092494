export enum GroupsModuleMessages {
  group = "groupsModule.group",
  name = "groupsModule.name",
  noNameRegistered = "groupsModule.noNameRegistered",
  active = "groupsModule.active",
  inactive = "groupsModule.inactive",
  externalId = "groupsModule.externalId",
  registrationDate = "groupsModule.registrationDate",
  updatedDate = "groupsModule.updatedDate",
  code = "groupsModule.code",
  deleteConfirmationText = "groupsModule.deleteConfirmationText",
  deleteRegionalError = "groupsModule.deleteGroupError",

  updateGroupError = "groupsModule.updateGroupError",
  readGroupSuccess = "groupsModule.readGroupSuccess",
  noGroupFound = "groupsModule.noGroupFound",
  updateGroupSuccess = "groupsModule.updateGroupSuccess",
  createGroupSuccess = "groupsModule.createGroupSuccess",
  createGroupError = "groupsModule.createGroupError",
  deleteGroupSuccess = "groupsModule.deleteGroupSuccess"

}
export type TranslationGroupsModuleMessages = {
  [key in GroupsModuleMessages]: string;
}
