import styled from "styled-components";

interface IContainerProps {
  showControlButtons?: boolean;
}

const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  padding-top: 6px;

  margin-bottom: ${(props) => (props.showControlButtons ? "1.5rem" : "unset")};

  .MuiLoadingButton-root {
    :not(.Mui-disabled) {
      background-color: ${(props) => props.theme.colors.primary} !important;
    }

    border-radius: 5px !important;

    > span {
      width: 100% !important;
    }
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  width: 100%;
  min-width: 200px;

  .MuiFab-primary:not(.Mui-disabled) {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  > :first-child {
    flex-grow: 1;
  }

  .MuiFormHelperText-root {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;

export { Container, Field };
