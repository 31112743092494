export enum TimelineVehiclesItemMessages {
  latestUpdate = "timelineVehiclesItem.latestUpdate",
  rightNow = "timelineVehiclesItem.rightNow",
  for = "timelineVehiclesItem.for",
  before = "timelineVehiclesItem.before",
  during = "timelineVehiclesItem.during",
  ago = "timelineVehiclesItem.ago",
  vehicleRunningFor = "timelineVehiclesItem.vehicleRunningFor",
  moreInfo = "timelineVehiclesItem.moreInfo",
  driverIbuttonVerified = "timelineVehiclesItem.driverIbuttonVerified",
  noDriver = "timelineVehiclesItem.noDriver",
  noCurrentAllocation = "timelineVehiclesItem.noCurrentAllocation",
  distanceTraveledOnTrip = "timelineVehiclesItem.distanceTraveledOnTrip"
}

export type TranslationTimelineVehiclesItemMessages = {
  [key in TimelineVehiclesItemMessages]: string;
}
