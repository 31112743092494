import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const AccordionDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;

  .MuiFormGroup-root {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    min-height: 150px;
    height: 150px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    overflow: auto;

    &.Mui-error {
      border: 1px solid red !important;
    }
  }

`;

export {
  Container,
  AccordionDetail
};
