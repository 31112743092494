/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, OneToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { Alert } from "./reg_alerts.entity";
import { Location } from "./reg_locations.entity";
import { Travel } from "./reg_travels.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { TpeEvent } from "./tpe_events_types.entity";

@Entity("reg_events")
export class Event {

  @PrimaryGeneratedColumn("uuid")
  id_event?: string;

  @Column({ type: "varchar", nullable: false })
  latitude?: string;

  @Column({ type: "varchar", nullable: false })
  longitude?: string;

  @Column({ type: "varchar", nullable: true })
  address?: string;

  @Column({ type: "timestamp", nullable: false })
  start_date?: Date;

  @Column({ type: "timestamp", nullable: false })
  finish_date?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToOne(() => TpeEvent, (type) => type.events, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_event_type_fk" })
  type?: TpeEvent;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.events, { nullable: false })
  @JoinColumn({ name: "id_vehicle_fk" })
  vehicle?: Vehicle;

  @ManyToOne(() => Travel, (travel) => travel.events, { nullable: true })
  @JoinColumn({ name: "id_travel_fk" })
  travel?: Travel;

  @ManyToOne(() => Location, (travel) => travel.events, { nullable: true })
  @JoinColumn({ name: "id_location_fk" })
  location?: Location;

  @OneToOne(() => Alert, (alert) => alert.event)
  alert?: Alert;

  @OneToMany(() => Vehicle, (vehicles_at) => vehicles_at.current_event)
  vehicles_at?: Vehicle[];

  @OneToMany(() => Vehicle, (vehicles_at_last) => vehicles_at_last.last_event)
  vehicles_at_last?: Vehicle[];

  @Column({ type: "numeric", nullable: true })
  speed?: number;

  @Column({ type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
  start_hour_meter?: number;

}
