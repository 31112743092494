import styled from "styled-components";

import backgroundWeb from "../../../assets/Login/background-web.svg";

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: url(${backgroundWeb}) no-repeat center;
  background-size: cover;

  button {
    margin-top: 15px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  width: 380px;
  max-width: 94vw;
  height: 635px;
  max-height: 94vh;
  background-color: white;
  border-radius: 8px;
  padding: 10px 0 50px 0;

  & > form {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const ForgotPassword = styled.div`
  margin: 15px 0;

  a {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline; // Adiciona sublinhado no hover
    }
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0 10px 0;
`;

export { Container, Content, ForgotPassword, Divider };
