/* eslint-disable import/prefer-default-export */
import { VehicleBlockMessages, TranslationVehicleBlockMessages } from "../interfaces";

export const messages: TranslationVehicleBlockMessages = {
  [VehicleBlockMessages.blockedVehicle]: "Veículo bloqueado",
  [VehicleBlockMessages.checkVehicleToBeBlocked]: "Confira o veículo a ser bloqueado",
  [VehicleBlockMessages.checkVehicleToBeUnblocked]: "Confira o veículo a ser desbloqueado",
  [VehicleBlockMessages.blockCommandSent]: "Comando de bloqueio enviado com sucesso!",
  [VehicleBlockMessages.unblockCommandSent]: "Comando de desbloqueio enviado com sucesso!",
  [VehicleBlockMessages.blockProcessMonitoringInstruction]: "O acompanhamento do processo pode ser feito na aba de"
  + " histórico de bloqueios.",
  [VehicleBlockMessages.accessAvailableAt]: "Acesso disponível em",
  [VehicleBlockMessages.blockTabPath]: "Menu - Módulos - Veículos - Bloqueios",
  [VehicleBlockMessages.youAreAboutTo]: "Você está prestes a ",
  [VehicleBlockMessages.toBlock]: "bloquear ",
  [VehicleBlockMessages.toUnblock]: "desbloquear ",
  [VehicleBlockMessages.aVehicle]: "um veículo",
  [VehicleBlockMessages.yourActionWill]: "Sua ação irá ",
  [VehicleBlockMessages.vehicleMobilityAndFunctionality]: "a mobilidade do veículo e funções relacionadas ao seu"
  + " funcionamento.",
  [VehicleBlockMessages.responsibilityTerms]: "A Topcon recomenda expressamente o uso do bloqueio somente com o veículo parado, em"
  + " conformidade com as leis de trânsito vigentes, e não se responsabiliza, em hipótese alguma, por quaisquer danos"
  + " materiais e corporais, próprios ou a terceiros, decorrentes do uso do bloqueio. Em confirmando o comando de"
  + " bloqueio, há uma assunção expressa do cliente quanto aos riscos e responsabilidades!",
  [VehicleBlockMessages.justify]: "Justifique o ",
  [VehicleBlockMessages.ofTheVehicle]: "do veículo",
  [VehicleBlockMessages.justifyBlock]: "Justifique o bloqueio",
  [VehicleBlockMessages.justifyUnblock]: "Justifique o desbloqueio",
  [VehicleBlockMessages.block]: "bloqueio ",
  [VehicleBlockMessages.unblock]: "desbloqueio ",
  [VehicleBlockMessages.insertPassword]: "Insira sua senha de usuário TopconFLEET",
  [VehicleBlockMessages.slideToRight]: "Arraste para a direita",
  [VehicleBlockMessages.vehicle]: "veículo",
  [VehicleBlockMessages.blockConfirmationTitle]: "Confirmação de bloqueio",
  [VehicleBlockMessages.blockConfirmationMessage]: "Deseja realmente efetuar o bloqueio do veículo?",
  [VehicleBlockMessages.user]: "Usuário",
  [VehicleBlockMessages.creationDate]: "Criação",
  [VehicleBlockMessages.sendDate]: "Envio",
  [VehicleBlockMessages.processingDate]: "Processamento",
  [VehicleBlockMessages.status]: "Status",
  [VehicleBlockMessages.processed]: "Processado",
  [VehicleBlockMessages.sent]: "Enviado",
  [VehicleBlockMessages.cancelled]: "Cancelado",
  [VehicleBlockMessages.justification]: "Justificativa",
  [VehicleBlockMessages.blockLocation]: "Local do bloqueio",
  [VehicleBlockMessages.blockHistoryTitle]: "Histórico de bloqueios"
};
