import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  padding-top: 85px;

  .optionsAccordion{
    display: flex;
    flex-direction:column;
    align-items: center;
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 33%;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .description {
    font-size: 1rem;
    color: rgb(0 0 0 / 60%);
  }


  .availableOptions {
    margin-left: 30px;
  }
`;

export { Container, OptionContainer };
