/* eslint-disable @typescript-eslint/ban-types */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback
} from "react";
// region Libraries
import { AlertCircle, Visibility, VisibilityOff } from "@libraries/icons";
import { useField } from "@unform/core";
// endregion Libraries
// region Languages
import { LoginMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion Languages
// region Styles
import { Container, Error } from "./styles";
// endregion Styles

interface InputPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  placeHolder?: string;
}

const InputPassword: React.FC<InputPasswordProps> = ({ name, placeHolder, ...rest }) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  const {
    fieldName, defaultValue, error, registerField
  } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);

  const handleVisibilityIconClick = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <Container
      isError={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        placeholder={placeHolder ?? t(LoginMessages.password)}
        type={showPassword ? "text" : "password"}
        {...rest}
      />
      {showPassword ? <Visibility onClick={handleVisibilityIconClick} /> : <VisibilityOff onClick={handleVisibilityIconClick} /> }

      {error && (
        <Error title={error}>
          <AlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default InputPassword;
