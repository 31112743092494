import styled, { createGlobalStyle } from "styled-components";

const fieldControlButtonsHeight = "41px";

export const windowWidth = {
  uhd: "2560px",
  laptop: {
    large: "1440px",
    medium: "1024px"
  },
  tablet: "768px",
  mobile: {
    large: "425px",
    medium: "375px",
    small: "320px"
  }
};

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  html, #root, body {
    height: 100%;
    font-size: 15px;

    @media (max-width: 800px) { // Mobile
      font-size: 13px;
    }
  }
  body {
    background: #fff;
    color: ${(props) => props.theme.colors.text};
    font-family: 'Roboto';
    overflow: hidden;
    height: 100%;

    @media (max-width: 800px) { // Mobile
      overflow: scroll;
      overflow-x: hidden;

      .page {
        padding-top: 20px;
        height: auto;
        padding-bottom: 100px;
      }
    }
    @media (min-width: 800px) { // Desktop

      .page {
        padding-top: 85px;
        height: 100%;
      }
    }

    .gm-style { // Google maps
      margin: 0 !important;

      /* a[rel=noopener] { // Google maps footer logo
        display: none !important;
      } */
      .gm-style-cc {
        display: none;

        &:nth-child(1) {
          display: block !important;
        }
      }
      .gm-style-iw { // Info window
        padding: 20px;
        padding-bottom: 20px !important;
        padding-right: 20px !important;
        max-height: max-content !important;

        .gm-style-iw-d {
          overflow: hidden !important;
          max-height: max-content !important;
        }
      }
    }
    .pac-container { // Google autocomplete places
      display: block;
      z-index: 100000;

      &:after {
        background-image: none;
        height: auto;
      }
    }
    .loading-background { // Loading background full screen
      z-index: 99999;
      backdrop-filter: blur(6px);
      background: rgb(255 255 255 / 25%) !important;

      .loading-circle-1 {
        background: ${(props) => props.theme.colors.primary} !important;
      }
      .loading-circle-2 {
        background: ${(props) => props.theme.colors.secondary} !important;
      }
    }
    .drawer { // Global drawer (left menu)

      .MuiPaper-root {
        min-width: 370px;

        @media (max-width: 800px) { // Mobile
          min-width: 100%;
        }

        .container {
          background: ${(props) => props.theme.colors.primary};
          color: white;
          z-index: 2;
          position: sticky;
          top: 0;

          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;

            .closeIcon {
              cursor: pointer;

              svg {
                font-size: 2rem;
                fill: white;
                font-weight: bold;
              }
            }
            .client-beta-name {
              text-transform: capitalize;
            }
            .logo {
              display: grid;

              svg {
                height: 30px;
              }
              span {
                text-align: right;
                font-size: 0.6rem;
              }
            }
          }
          .profile {
            align-items: center;
            padding: 0 20px 20px 20px;
            display: flex;
            justify-content: space-between;

            .photo {
              background: white;
              border-radius: 50%;

              img {
                width: 70px;
              }
            }
            .data {
              padding-left: 15px;
              text-align: right;

              .name {
                font-size: 1.3rem;
                font-weight: bold;
              }
            }
          }
          .help {

            .helpItem {
              display: flex;
              align-items: center;
              margin-left: auto;

              .MuiListItemIcon-root {
                min-width: 35px;
              }

              svg {
                fill: white;
              }
            }
          }
        }
        .menu {
          padding: 20px;
          color: rgba(0,0,0,0.6);
          flex: 1;

          .box {
            margin-bottom: 20px;

            .title {
              font-size: 0.8rem;
              margin-bottom: 5px;
            }
            .content {

              .MuiList-root {
                padding-bottom: 0;
                padding-top: 0;

                .MuiListItem-root {
                  padding-left: 10px;

                  .item {
                    display: flex;
                    align-items: center;

                    .text {
                      padding-left: 10px;
                      font-size: 1.15rem;
                    }
                  }
                }
              }
            }
          }
        }
        .logout {
          position: sticky;
          bottom: 0;
          background: ${(props) => props.theme.colors.primary};
          color: white;
          z-index: 2;

          svg {
            fill: white;
          }
        }
      }
    }
    .MuiBackdrop-root { // Global backdrop
      backdrop-filter: blur(6px);
      background: rgb(255 255 255 / 25%);
    }
    .Mui-disabled { // Disable icons (SVG)

      svg {
        fill: rgba(0, 0, 0, 0.26) !important;
      }
    }
    .MuiBadge-badge {
      background-color: #FF0000;
    }
    .MuiDialog-paper { // Paper dialog
      margin: 10px;

      .MuiDialogActions-root {
        padding: 8px 24px;
      }
    }
    .MuiAutocomplete-popper { // Dropbox field
      z-index: 2010;
    }
    .vehicle-travel-details.overAll  {
      z-index: 2001 !important;
    }
    .vehicle-travel-details__dialog {
      .MuiPaper-root {
        width: 100%;
        max-width: 1300px;
      }
    }
    .vehicle-travel-details__dialog--overAll {
      z-index: 2001 !important;
    }

    #modalContactUs {

      .MuiPaper-root {
        width: 400px;
        max-width: 100%;

        .mContent {
          display: flex;
          padding: 36px;
          border-bottom: none;

          /* @media (max-width: 800px) { // Mobile
            text-align: center;

            .MuiGrid-item {
              display: block !important;

              .read-content {

                .contacts {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                }
                .about {
                  margin-top: 50px;
                  text-align: center;
                }
              }
            }
          } */

          .MuiGrid-item {
            display: flex;
            align-items: center;

            .logo {

              svg {
                width: 100%;
                padding: 14px;
              }
            }
            .read-content {
              width: 100%;
              display: flex;
              flex-direction: column;

              .contacts {
                margin-top: 25px;
                margin-left: auto;
                margin-right: auto;

                .item {
                  cursor: pointer;
                  display: flex;

                  .icon {

                    svg {
                      fill: #a9a9a9;
                    }
                  }
                  .text {
                    padding-left: 10px;
                    color: #a9a9a9;
                  }
                  &:hover {

                    .icon {

                      svg {
                        fill: #676666;
                      }
                    }
                    .text {
                      color: #676666;
                    }
                  }
                }
              }
              .about {
                color: #a9a9a9;
                text-align: center;

              }
            }
          }
        }
        .mFooter {
          display: flex;
          justify-content: center;
        }
      }

    }

    #modalHelp {

      .MuiPaper-root {
        width: 500px;
        max-width: 100%;

        &.MuiDialog-paper {
          height: 90%;
        }

        .MuiAccordion-root {
          box-shadow: unset;

          .MuiAccordionSummary-content .MuiTypography-root {
            font-size: 1.2rem;
            font-weight: 500;
          }

          .subtitle {
            font-weight: 500;

            &:not(:first-child) {
              margin-top: 1.5rem;
            }
          }

          .subcontent {
            margin: 1rem 0 0 0.5rem;

            .description {
              margin-top: 0.3rem;
              text-align: justify;
            }

            .MuiListItemIcon-root {
              svg {
                height: 30px;
                width: 30px;
              }
            }
          }
        }

      }

    }

    #modalTravelAnalytics {

      .MuiPaper-root {
        width: 1300px;
      }
    }
    #modalMapAllVehicles {

      .mContent {
        overflow: hidden;

        @media (max-width: 800px) { // Mobile
          overflow: auto;
        }

        .MuiTypography-root {
          margin-bottom: 0;
        }
      }
    }
    #dialogConfirmAction {

      .MuiDialogTitle-root {

        h2 {
          font-weight: 200;
        }
      }

    }
  }
  .noScroll {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }
  svg {

    .cls-1 {
      fill: none
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #F0F0F7;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #F0F0F7;

    @media (max-width: 800px) { // Mobile
      width: 0px !important;
    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.scrollBar || "#e0e0e0"};
    border-radius: 10px;
  }

  .MuiInputLabel-outlined {
    z-index: 0;
  }
`;

const FieldActionButtons = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 10px !important;
  height: ${fieldControlButtonsHeight} !important;
`;

const GlobalStyledComponents = {
  FieldActionButtons
};

export { GlobalStyledComponents };
