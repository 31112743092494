import React, { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";

// region Imports - External libraries
import _ from "lodash";
import { Button } from "@npm-telluria-tecnologia/telluria-ui/dist";
// endregion Imports - External libraries
// region Imports - Shared
import { IFilter, IParamQueryValues } from "@shared/interfaces/filter.interface";
import { Filter } from "@shared/entities/reg_filters.entity";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
// endregion Imports - Shared
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Components
import ModalFormScheduledReportDescription from "@components/Modal/FormScheduledReportDescription";
import ModalFormFilterDescription from "@components/Modal/FormFilterDescription";
// endregion Imports - Components
// region Imports - Molecules
import KanbanAccordionCardFilterDateRange from "@molecules/KanbanAccordionCardFilterDateRange";
import KanbanAccordionCardFilter from "@molecules/KanbanAccordionCardFilter";
import AutocompleteSavedFilters from "@molecules/AutocompleteSavedFilters";
import KanbanASimpleInput from "@molecules/KanbanSimpleInput";
// endregion Imports - Molecules
// region Imports - Languages
import { DataTableMessages, FilterMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion Imports - Languages
// region Imports - Utils
import utils, { StandardReturnObject } from "@utils/useful-functions";
import * as dateFns from "date-fns";
import DateRange from "@shared/utils/date-range";
// endregion Imports - Utils
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Styles
import { Container, Content, FiltersContainer, Title } from "./styles";
// endregion Imports - Styles

// region Types
export type MaxRangeType = "1D" | "7D" | "30D";
export type OnFilterStatusType = "success" | "error" | "scheduledReport"
// endregion Types
// region Interfaces
export interface IMultipleSelectionOptions {
  isLoadingValues: boolean;
  labelName: string;
  paramName: string;
  values: IOptionValue[];
  refreshOptions?: {
    refresh: (currentValues: IParamQueryValues[]) => void;
    dependentParamNames: string[];
  };
}

export interface ISelectedOptions {
  labelName: string;
  values: IOptionValue[];
}

export interface IOptionValue {
  value: string;
  label: string;
}

export interface IDateRangeOptions {
  labelName: string;
  paramNameStartDate: string;
  paramNameEndDate: string;
  defaultValues: IDateRangeValues;
  maxRange?: MaxRangeType;
  maxPeriod?: MaxRangeType;
}

export interface ISimpleInputOptions {
  labelName: string;
  paramName: string;
  values: string[];
}

export interface IDateRangeValues {
  startDate: string;
  endDate: string;
}

export interface IOnFilterReturn {
  status: OnFilterStatusType;
  additionalFilterOptions?: IParamQueryValues[];
}

interface IProps {
  open: boolean;
  onClose: () => void;
  defaultAppliedFilterOptions?: IParamQueryValues[];
  applicationTypeID: FilterApplicationTypesID;
  onFilter: (data: IParamQueryValues[]) => Promise<IOnFilterReturn>;
  multipleSelectionOptions?: IMultipleSelectionOptions[];
  dateRangeOptions?: IDateRangeOptions;
  simpleInputOptions?: ISimpleInputOptions[];
}
// endregion Interfaces

const GenericQueryFilter: React.FC<IProps> = ({
  open,
  onClose,
  defaultAppliedFilterOptions,
  applicationTypeID,
  onFilter,
  multipleSelectionOptions,
  dateRangeOptions,
  simpleInputOptions
}) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region Constants
  const initialParamQueryReturnData: IParamQueryValues[] = [];

  if (dateRangeOptions) {
    initialParamQueryReturnData.push(
      {
        paramName: dateRangeOptions.paramNameStartDate,
        paramValue: dateRangeOptions.defaultValues.startDate
      },
      {
        paramName: dateRangeOptions.paramNameEndDate,
        paramValue: dateRangeOptions.defaultValues.endDate
      }
    );
  }

  const initialFilterPreset: IFilter = {
    application_type_id: applicationTypeID,
    filter_options: defaultAppliedFilterOptions ?? initialParamQueryReturnData
  };
  // endregion Constants
  // region Refs
  const currentPresetRef = useRef<IFilter>(initialFilterPreset);
  const appliedPresetRef = useRef<IFilter>(initialFilterPreset);

  const isLoadedOptionsRef = useRef<boolean>(false);

  const additionalFilterOptionsToScheduledReportRef = useRef<IParamQueryValues[]>([]);
  // endregion Refs
  // region States
  const [selectedSavedPreset, setSelectedSavedPreset] = useState<IFilter | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<ISelectedOptions[]>([]);
  const [filterInputOptions, setFilterInputOptions] = useState<ISimpleInputOptions[]>([]);
  const [isOpenFormToSaveFilter, setIsOpenFormToSaveFilter] = React.useState<boolean>(false);
  const [isOpenFormToCreateScheduledReport, setIsOpenFormToCreateScheduledReport] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isValidDateRange, setIsValidDateRange] = useState<boolean>(true);
  const [currentStartDate, setCurrentStartDate] = useState<string | undefined>(dateRangeOptions?.defaultValues.startDate);
  const [currentEndDate, setCurrentEndDate] = useState<string | undefined>(dateRangeOptions?.defaultValues.endDate);
  // endregion States
  // region Functions
  /**
   * Function to handle the param query return data
   * @param paramName The param name of the multiple selection option
   * @param labelName The label name of the multiple selection option
   * @param newSelectedOptions The new selected options
   */
  const handleChangeSelectedOptions = (paramName: string, labelName: string, newSelectedOptions: IOptionValue[]) => {
    const oldSelectedOptions = selectedOptions.filter((option) => option.labelName !== labelName);

    setSelectedOptions([...oldSelectedOptions, { labelName, values: newSelectedOptions }]);

    const oldFilterValues = currentPresetRef.current.filter_options.filter((filter) => filter.paramName !== paramName);
    const selectedValues = newSelectedOptions.map((option) => option.value);
    const newOptions = [...oldFilterValues, { paramName, paramValue: selectedValues }];

    handleChangeCurrentFilter({ ...currentPresetRef.current, filter_options: newOptions } as IFilter);
  };

  const handleSimpleInputChange = (paramName:string, values: string[]) => {
    const oldFilterValues = currentPresetRef.current.filter_options.filter((filter) => filter.paramName !== paramName);
    const newOptions = [...oldFilterValues, { paramName, paramValue: values }];

    handleChangeCurrentFilter({...currentPresetRef.current, filter_options: newOptions} as IFilter);
  };

  /**
   * Function to handle the date range change
   * @param values The changed date range values
   */
  const handleChangeDateRange = (values: IDateRangeValues) => {

    const dateRange = new DateRange(values.startDate, values.endDate);
    const maxRangeInDays = dateRangeOptions?.maxRange === "1D" ? 1 : dateRangeOptions?.maxRange === "7D" ? 7 : 30;

    if (dateRangeOptions?.maxRange && !dateRange.isValidRange("DAYS", maxRangeInDays)) {
      setIsValidDateRange(false);

      return;
    }

    if(dateRangeOptions?.maxPeriod){
      const differenceInDays = dateFns.differenceInCalendarDays(new Date(), new Date(values.startDate));
      const maxRangeInDays = dateRangeOptions?.maxPeriod === "7D" ? 7 : dateRangeOptions?.maxRange === "30D" ? 30 : 1;

      if(differenceInDays > maxRangeInDays){

        setIsValidDateRange(false);

        return;
      }
    }

    setIsValidDateRange(true);

    setCurrentStartDate(values.startDate);
    setCurrentEndDate(values.endDate);

    const { paramNameStartDate, paramNameEndDate } = dateRangeOptions as IDateRangeOptions;

    const oldFilterValues = currentPresetRef.current.filter_options.filter(
      (filter) => filter.paramName !== paramNameStartDate && filter.paramName !== paramNameEndDate
    );

    const newFilterOptions = [
      ...oldFilterValues,
      { paramName: paramNameStartDate, paramValue: values.startDate },
      { paramName: paramNameEndDate, paramValue: values.endDate }
    ];

    handleChangeCurrentFilter({ ...currentPresetRef.current, filter_options: newFilterOptions } as IFilter);
  };

  /**
   * Function to handle the apply filter button
   */
  const handleApplyFilter = async () => {
    transformMultipleOptionValuesToStringArray();

    const onFilterStatus = await onFilter(currentPresetRef.current.filter_options.filter(
      (filter) => !_.isEmpty(filter.paramValue)
    ));

    if (onFilterStatus.status !== "success") {
      if (onFilterStatus.status === "scheduledReport") {
        additionalFilterOptionsToScheduledReportRef.current = onFilterStatus?.additionalFilterOptions ?? [];
        setIsOpenFormToCreateScheduledReport(true);
      }

      return;
    }

    defineDefaultDateRangeAccordingToPreset();

    appliedPresetRef.current = _.cloneDeep(currentPresetRef.current);

    onClose();
  };

  /**
   * Function to handle the creation of a new selected preset
   * @param filter The filter created
   */
  const handleCreateSelectedPreset = (filter: IFilter) => {
    setSelectedSavedPreset(filter);
    currentPresetRef.current = _.cloneDeep(filter);
  };

  /**
   * Function to handle the change of the selected preset
   * @param filter The selected filter
   * @param previousDeleted If the previous filter was deleted
   */
  const handleChangeSelectedPreset = (filter: IFilter | null, previousDeleted?: boolean) => {
    setSelectedSavedPreset(filter);

    if (previousDeleted && _.isEqual(currentPresetRef.current.id_filter, appliedPresetRef.current.id_filter)) {
      removeFilterReferenceFromAppliedPreset();
    }

    const newCurrentPreset = _.cloneDeep(filter) ?? appliedPresetRef.current;

    handleChangeCurrentFilter(newCurrentPreset, true);

    currentPresetRef.current = newCurrentPreset;
    defineDefaultDateRangeAccordingToPreset();
  };

  /**
   * Function to handle the closing of the modal form dialog
   */
  const handleCloseModalFormDialog = () => setIsOpenFormToSaveFilter(false);

  /**
   * Function to handle the closing of the modal form dialog of the scheduled report
   */
  const handleCloseModalFormDialogScheduledReport = () => {
    additionalFilterOptionsToScheduledReportRef.current = [];
    setIsOpenFormToCreateScheduledReport(false);
  };

  /**
   * Handle the change of the current filter
   * @param newFilter The new filter to be applied
   * @param isChangedPreset Update the selected options
   */
  const handleChangeCurrentFilter = (newFilter: IFilter, isChangedPreset?: boolean) => {

    const newOptions = newFilter.filter_options;
    const oldOptions = currentPresetRef.current.filter_options.filter((option) => option.paramValue);

    currentPresetRef.current = newFilter;

    const refreshableOptions = multipleSelectionOptions?.filter((option) => option.refreshOptions);

    if (!refreshableOptions) {
      if (isChangedPreset) updateSelectedOptions(true);

      return;
    }

    let mustUpdateSelectedOptions = isChangedPreset;

    const changedOptionsParamNames: string[] = [];

    for (const newOption of newOptions) {

      const oldOption = oldOptions.find(
        (oldOption) => oldOption.paramName === newOption.paramName
      );

      if (!isEqualParamQueryValues(newOption, oldOption)) changedOptionsParamNames.push(newOption.paramName);
    }

    for (const refreshableOption of refreshableOptions) {

      const isDependentOptionChanged = refreshableOption.refreshOptions?.dependentParamNames.some(
        (dependentParamName) => changedOptionsParamNames.includes(dependentParamName)
      );

      if (isDependentOptionChanged) {
        const currentValues = newOptions.filter(
          (option) => refreshableOption.refreshOptions?.dependentParamNames.includes(option.paramName)
        );

        refreshableOption.refreshOptions?.refresh(currentValues);

        // If the refreshable option has changed, the selected options will be updated by useEffect hook
        mustUpdateSelectedOptions = false;
      }
    }

    updateSelectedOptions(mustUpdateSelectedOptions);
  };

  /**
   * Check if the param query values are equal
   * @param paramQueryValues1 The first param query values
   * @param paramQueryValues2 The second param query values
   */
  const isEqualParamQueryValues = (paramQueryValues1?: IParamQueryValues, paramQueryValues2?: IParamQueryValues) => {
    if (!paramQueryValues1 && !paramQueryValues2) return true;

    if (!paramQueryValues1 || !paramQueryValues2) return false;

    const isEqualParamName = paramQueryValues1.paramName === paramQueryValues2.paramName;

    let isEqualParamValue: boolean;

    if ((Array.isArray(paramQueryValues1.paramValue) && Array.isArray(paramQueryValues2.paramValue))
      || (!Array.isArray(paramQueryValues1.paramValue) && !Array.isArray(paramQueryValues2.paramValue))) {

      isEqualParamValue = _.isEqual(paramQueryValues1.paramValue, paramQueryValues2.paramValue);
    } else if (!Array.isArray(paramQueryValues1.paramValue)) {

      isEqualParamValue = _.isEqual([paramQueryValues1.paramValue], paramQueryValues2.paramValue);
    } else {

      isEqualParamValue = _.isEqual(paramQueryValues1.paramValue, [paramQueryValues2.paramValue]);
    }

    return isEqualParamName && isEqualParamValue;
  };

  /**
   * Transform the multiple option values to string array. All multiple selection options must be an array.
   */
  const transformMultipleOptionValuesToStringArray = () => {
    currentPresetRef.current.filter_options = currentPresetRef.current.filter_options.map((option) => {

      const isMultipleSelectionOption = multipleSelectionOptions?.some(
        (multipleSelectionOption) => multipleSelectionOption.paramName === option.paramName
      );

      if (Array.isArray(option.paramValue) || !isMultipleSelectionOption) return option;

      return { ...option, paramValue: [option.paramValue] };

    });
  };

  /**
   * Update the selected options according to the current preset
   * @param withoutOptionChanges If there were no changes to the options, only those selected
   */
  const updateSelectedOptions = (withoutOptionChanges?: boolean) => {
    if (!currentPresetRef.current.filter_options || !multipleSelectionOptions) return;

    const isLoadingOptions = multipleSelectionOptions.some((option) => option.isLoadingValues);

    if (isLoadingOptions) {
      isLoadedOptionsRef.current = false;

      return;
    }

    // If the options are already loaded, doesn't have changes to update
    if (isLoadedOptionsRef.current && !withoutOptionChanges) return;

    isLoadedOptionsRef.current = true;

    const newSelectedOptions: ISelectedOptions[] = [];
    const paramQueryValuesNotFound: IParamQueryValues[] = [];

    multipleSelectionOptions.forEach((optionsLabel) => {
      const paramQueryMatched = currentPresetRef.current.filter_options.find(
        (filter) => filter.paramName === optionsLabel.paramName
      );

      if (paramQueryMatched) {
        // Multiple selection option must be an array
        const selectedValues = Array.isArray(paramQueryMatched.paramValue)
          ? paramQueryMatched.paramValue
          : [paramQueryMatched.paramValue as string];

        selectedValues.forEach((value) => {
          const optionValue = optionsLabel.values.find((option) => option.value === value);

          if (optionValue) {
            const selectedOptionIndex = newSelectedOptions.findIndex(
              (selectedOption) => selectedOption.labelName === optionsLabel.labelName
            );

            if (selectedOptionIndex !== -1) {
              newSelectedOptions[selectedOptionIndex].values.push(optionValue);
            } else {
              newSelectedOptions.push({ labelName: optionsLabel.labelName, values: [optionValue] });
            }

          } else {
            const notFoundParamQueryIndex = paramQueryValuesNotFound.findIndex(
              (paramQueryValueNotFound) => paramQueryValueNotFound.paramName === optionsLabel.paramName
            );

            if (notFoundParamQueryIndex !== -1) {
              const paramValuesNotFound = paramQueryValuesNotFound[notFoundParamQueryIndex].paramValue as string[];

              paramValuesNotFound.push(value);

              paramQueryValuesNotFound[notFoundParamQueryIndex].paramValue = paramValuesNotFound;
            } else {
              paramQueryValuesNotFound.push({ paramName: optionsLabel.paramName, paramValue: [value] });
            }
          }
        });
      }
    });

    const newInputOptions = [...filterInputOptions];

    simpleInputOptions?.forEach((optionsLabel) => {
      const paramQueryMatched = currentPresetRef.current.filter_options.find(
        (filter) => filter.paramName === optionsLabel.paramName
      );

      if (paramQueryMatched) {
        newInputOptions.push(
          {
            labelName: optionsLabel.labelName,
            paramName: optionsLabel.paramName, values: paramQueryMatched.paramValue as string[]
          });
      }
    });

    setFilterInputOptions(newInputOptions);

    setSelectedOptions(newSelectedOptions);

    removeInvalidValuesFromCurrentPreset(paramQueryValuesNotFound);
  };

  /**
   * Define the default date range according to the current preset
   */
  const defineDefaultDateRangeAccordingToPreset = () => {
    if (!currentPresetRef.current.filter_options || !dateRangeOptions) return;

    const presetValues = currentPresetRef.current.filter_options;

    const paramStartDate = presetValues.find(
      (option) => option.paramName === dateRangeOptions?.paramNameStartDate
    );
    const paramEndDate = presetValues.find(
      (option) => option.paramName === dateRangeOptions?.paramNameEndDate
    );

    if (!paramStartDate || !paramEndDate) return;

    // Date range options must be a string
    const startDate = paramStartDate.paramValue as string;
    const endDate = paramEndDate.paramValue as string;

    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
  };

  /**
   * Remove values in the current preset that are not found in the multiple selection options
   * @param paramQueryValuesNotFound The param query values not found
   */
  const removeInvalidValuesFromCurrentPreset = (paramQueryValuesNotFound: IParamQueryValues[]) => {

    if (_.isEmpty(paramQueryValuesNotFound)) return;

    const newFilterOptions = currentPresetRef.current.filter_options.map((paramQuery) => {

      const paramQueryValueNotFound = paramQueryValuesNotFound.find(
        (paramQueryValueNotFound) => paramQueryValueNotFound.paramName === paramQuery.paramName
      );

      if (!paramQueryValueNotFound) return paramQuery;

      // Multiple selection option must be an array
      const oldParamValues = Array.isArray(paramQuery.paramValue)
        ? paramQuery.paramValue
        : [paramQuery.paramValue as string];
      const paramValuesToRemove = paramQueryValueNotFound.paramValue as string[];

      const newParamValue = oldParamValues.filter(
        (paramValue) => !paramValuesToRemove.includes(paramValue)
      );

      return { paramName: paramQueryValueNotFound.paramName, paramValue: newParamValue };
    });

    handleChangeCurrentFilter({ ...currentPresetRef.current, filter_options: newFilterOptions } as IFilter);
  };

  /**
   * Get the initial selected options according to the label name
   * @param labelName The label name of the multiple selection option
   */
  const getSelectedOptionsByLabelName = (labelName: string): IOptionValue[] => {
    const selectedOptionsLabel = selectedOptions.find(
      (option) => option.labelName === labelName
    );

    return selectedOptionsLabel?.values ?? [];
  };

  /**
   * Save the changes to the current preset in the database
   */
  const saveChangesToCurrentPreset = async () => {

    setIsLoading(true);

    try {

      const filterToUpdate = new Filter(currentPresetRef.current);
      const response = await api.patch("/filters/update", filterToUpdate);
      const data = response?.data as StandardReturnObject<Filter>;

      if (data?.status === "success") {
        addToast({ type: "success", title: data.message });
        setSelectedSavedPreset(_.cloneDeep(currentPresetRef.current));

        return;
      }

      addToast({ type: "error", title: data.message });

    } catch (error) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Remove the filter reference (id and description) from the applied preset
   */
  const removeFilterReferenceFromAppliedPreset = () => {
    // eslint-disable-next-line camelcase
    const { id_filter, description, ...newAppliedPreset } = appliedPresetRef.current;

    appliedPresetRef.current = _.cloneDeep(newAppliedPreset);
  };

  // endregion Functions
  // region Effects

  /**
   * Effect to reset the filter values to last applied preset
   */
  useEffect(() => {
    if (open) return;

    const newCurrentPreset = _.cloneDeep(appliedPresetRef.current);

    handleChangeCurrentFilter(newCurrentPreset, true);

    defineDefaultDateRangeAccordingToPreset();
    setSelectedSavedPreset(currentPresetRef.current?.id_filter ? currentPresetRef.current : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * Effect to update the selected options when the multiple selection options change
   */
  useEffect(() => {
    updateSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleSelectionOptions]);

  // endregion Effects

  return (
    <Container anchor="right" open={open} onClose={onClose}>
      <Loading loading={isLoading} />
      <Content>
        <Title>{t(DataTableMessages.filterTitle)}</Title>
        <AutocompleteSavedFilters
          onChange={handleChangeSelectedPreset}
          showControlButtons
          selectedFilter={selectedSavedPreset}
          applicationTypeID={applicationTypeID}
        />
        <FiltersContainer>
          {dateRangeOptions && (
            <KanbanAccordionCardFilterDateRange
              label={dateRangeOptions.labelName}
              defaultValues={{ startDate: currentStartDate!, endDate: currentEndDate! }}
              maxRange={dateRangeOptions.maxRange}
              maxPeriod={dateRangeOptions.maxPeriod}
              onChangeDateRange={(values) => handleChangeDateRange(values)}
            />
          )}
          {multipleSelectionOptions?.map((option) => (
            <KanbanAccordionCardFilter
              key={option.labelName}
              label={option.labelName}
              options={option.values as IOptionValue[]}
              onChangeSelectedOptions={(selectedOptions) => {
                handleChangeSelectedOptions(option.paramName, option.labelName, selectedOptions);
              }}
              initialSelectedOptions={getSelectedOptionsByLabelName(option.labelName)}
              isLoading={option.isLoadingValues}
            />
          ))}
          {simpleInputOptions?.map((option) => (
            <KanbanASimpleInput
              label={option.labelName}
              onChangeSimpleInput={(insertedValue) => {
                handleSimpleInputChange(option.paramName, insertedValue);
                }}
              initialSelectedOptions={filterInputOptions}
              // no lugar da getSelectedOptionsByLabelName
            />
          ))}
        </FiltersContainer>
        <Button
          className="apply-filter-button"
          text={t(DataTableMessages.filterApply)}
          onClick={handleApplyFilter}
          disabled={!isValidDateRange}
        />
        {selectedSavedPreset ? (
          <Button
            className="save-filter-button"
            type="button"
            text={t(FilterMessages.saveChanges)}
            disableRipple
            onClick={saveChangesToCurrentPreset}
            disabled={!isValidDateRange}
          />
        ) : (
          <Button
            className="save-filter-button"
            type="button"
            text={t(FilterMessages.saveNewFilter)}
            disableRipple
            onClick={() => setIsOpenFormToSaveFilter(true)}
            disabled={!isValidDateRange}
          />
        )}
      </Content>
      <ModalFormFilterDescription
        open={isOpenFormToSaveFilter}
        onClose={handleCloseModalFormDialog}
        filter={currentPresetRef.current}
        onSubmit={handleCreateSelectedPreset}
      />
      <ModalFormScheduledReportDescription
        open={isOpenFormToCreateScheduledReport}
        onClose={handleCloseModalFormDialogScheduledReport}
        filter={currentPresetRef.current}
        additionalFilterOptions={additionalFilterOptionsToScheduledReportRef.current}
      />
    </Container>
  );
};

GenericQueryFilter.defaultProps = {
  multipleSelectionOptions: undefined,
  dateRangeOptions: undefined
};

export default GenericQueryFilter;
