import styled from "styled-components";

export const Container = styled.div`
  padding: 0 15px;
  padding-top: 85px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
`;
