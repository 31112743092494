import { Fab } from "@material-ui/core";
import styled from "styled-components";

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 10px;
`;

export const StyledMenuItem = styled(Fab)`
  width: 16rem;
  height: 4rem;
  background-color: #002951;
  color: #fff;

  .MuiFab-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    @media (max-width: 800px) { // Mobile
      line-height: 1.5rem;
      gap: 1rem;
    }

    .option-name {
      display: flex;
      justify-content: center;
    }
  }

  &:hover {
    background-color: #002951;
  }

  @media (max-width: 800px) { // Mobile
    width: 12.75rem;
    height: 3.5rem;
  }
`;
