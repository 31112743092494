export enum LocationTypes {
  USINA = "Usina",
  OBRA = "Obra",
  AUTO_ELETRICA = "Auto Elétrica",
  BORRACHARIA = "Borracharia",
  POSTO_DE_COMBUSTIVEL = "Posto de Combustível",
  OFICINA_MECANICA = "Oficina Mecânica",
  ESTACIONAMENTO = "Estacionamento",
  OUTROS = "Outros",
  FORNECEDOR = "Fornecedor",
  CLIENTE = "Cliente"
}

export enum LocationTypesID {
  USINA = "b4f469f2-eddb-4d3f-ad55-21a4bcc5e24b",
  OBRA = "a89363bd-95d1-49cd-8eee-3a17bf2d767e",
  AUTO_ELETRICA = "dbbd0e6f-a2ed-4ddf-9be6-48db95eaf3bd",
  BORRACHARIA = "6b3091f3-d9d8-452c-bac1-b83994d900ad",
  POSTO_DE_COMBUSTIVEL = "9d64d394-a20f-419d-892a-9463c565be8c",
  OFICINA_MECANICA = "b53a2d1d-862e-4828-bba2-3b9ca1609d97",
  ESTACIONAMENTO = "dcb8e7d3-53af-4273-b4f3-00369e5ccce9",
  OUTROS = "694b31e1-197a-4a2a-b448-29dd3250d756",
  FORNECEDOR = "8c65e411-23da-4675-8706-502d18a07ffd",
  CLIENTE = "83dc9798-ca49-4fb0-a029-f08e8433cbab"
}
