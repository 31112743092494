import React from "react";
import { ReactSVG } from "react-svg";

// region Libraries
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, TravelDataMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Interfaces
// region Utils
import utils from "../../utils/useful-functions";
// endregion Utils
// region Assets
import IconObra from "../../assets/stateIcons/Na obra.svg";
// endregion Assets
// region Styles
import { Container, ContainerTravelDataListItem } from "./styles";
// endregion Styles

type TravelDataListItemProps = { title: string, content: string }
const TravelDataListItem = React.memo<TravelDataListItemProps>((
  { content, title }
) => (
  <ContainerTravelDataListItem className="item">
    <div className="title">{title}</div>
    <div className="content">{content}</div>
  </ContainerTravelDataListItem>
));

type TravelDataProps = { travel: Travel, vehicle: Vehicle };
const TravelData: React.FC<TravelDataProps> = ({ travel, vehicle }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="block locations">
        <div className="box">
          <div className="write">
            <div className="title">{t(TravelDataMessages.origin)}</div>
            <div className="text">
              { travel.origin.name || "" }
              <br />
              { `
                ${travel.origin.address}, ${travel.origin.number || ""} - ${travel.origin.district || ""}
                ${(travel.origin?.complement) ? ` - ${travel.origin?.complement}`
                : ""} | ${travel.origin.county} - ${travel.origin.uf}
              ` }
            </div>
          </div>
          <div className="icon">
            <div className="aux">
              { vehicle.states.length > 0 && utils.getStateIcon(vehicle.states[0].status.id_vehicle_state_type) }
            </div>
          </div>
        </div>
        { travel.destination && (
          <div className="box">
            <div className="write">
              <div className="title">{t(TravelDataMessages.destination)}</div>
              <div className="text">
                { travel.client || t(GlobalMessages.noClientRegistered) }
                <br />
                { travel.destination.name || t(GlobalMessages.noNameRegistered) }
                <br />
                { `
                ${travel.destination.address}, ${travel.destination.number
                || ""} - ${travel.destination.district || ""}
                ${travel.destination?.complement ? ` - ${travel.destination?.complement}` : ""} |
                ${travel.destination.county} - ${travel.destination.uf}
              ` }
              </div>
            </div>
            <div className="icon">
              <div className="aux">
                <ReactSVG src={IconObra} />
              </div>
            </div>
          </div>
        ) }
        { travel.end && (
          <div className="box">
            <div className="write">
              <div className="title">{t(TravelDataMessages.finalLocation)}</div>
              <div className="text">
                { travel.end.name || "" }
                <br />
                { `
                ${travel.end.address}, ${travel.end.number || ""} -
                ${travel.end.district || ""}${travel.end?.complement ? ` - ${travel.end?.complement}` : ""} |
                ${travel.end.county} - ${travel.end.uf}
              ` }
              </div>
            </div>
            <div className="icon">
              <div className="aux">
                { utils.getStateIcon(vehicle.states[vehicle.states.length - 1]?.status.id_vehicle_state_type) }
              </div>
            </div>
          </div>
        ) }
        <Divider />
        <div className="extra-data">
          <div className="docs-data">
            { (travel?.num_doc || travel?.load_label) && (
              <>
                <Divider />
                { travel?.num_doc && (
                  <div className="box">
                    <div className="write">
                      <div className="title">{t(TravelDataMessages.taxInvoice)}</div>
                      <div className="text">{ travel?.num_doc || "" }</div>
                    </div>
                  </div>
                ) }
                { travel?.load_label && (
                  <div className="box">
                    <div className="write">
                      <div className="title">{t(TravelDataMessages.sealNumber)}</div>
                      <div className="text">
                        { travel?.load_label || "" }
                      </div>
                    </div>
                  </div>
                ) }
              </>
            ) }
          </div>
          <div className="travel-date">
            <div className="box">
              <div className="write">
                <div className="title">{ travel?.integration_date ? t(TravelDataMessages.invoiceIntegrationDate)
                  : t(TravelDataMessages.travelStartDate) }
                </div>
                <div className="text">
                  { travel?.integration_date
                    ? utils.formatDateIfHave(travel.integration_date, "fullDate")
                    : utils.formatDateIfHave(travel.registration_date, "fullDate") }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="travel-data">
          {/* eslint-disable */ }
          { travel.document_date && <TravelDataListItem title={t(TravelDataMessages.invoiceIssueDate)} content={ utils.formatDateIfHave(travel.document_date, "fullDate") as string }/> }
          { travel.concrete_order && <TravelDataListItem title={t(TravelDataMessages.pieceToBeConcreted)} content={ travel.concrete_order }/> }
          { travel.volume && <TravelDataListItem title={t(TravelDataMessages.loadVolume)} content={ travel.volume.toString() }/> }
          { travel.concrete_expiration && <TravelDataListItem title={t(TravelDataMessages.loadExpirationDate)} content={ utils.formatDateIfHave(travel.concrete_expiration, "fullDate") as string }/> }
          {/* eslint-enable */ }
          { travel?.concrete_trace && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="div">{t(TravelDataMessages.trace)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  {/* eslint-disable */ }
                  { travel?.concrete_trace?.application && <TravelDataListItem title={t(TravelDataMessages.application)} content={ travel.concrete_trace.application }/> }
                  { travel?.concrete_trace?.slump && <TravelDataListItem title={t(TravelDataMessages.slump)} content={ travel.concrete_trace.slump.toString() }/> }
                  { travel?.concrete_trace?.resistance && <TravelDataListItem title={t(TravelDataMessages.resistance)} content={ travel.concrete_trace.resistance }/> }
                  { travel?.concrete_trace?.resistance_type && <TravelDataListItem title={t(TravelDataMessages.resistanceType)} content={ travel.concrete_trace.resistance_type.abbreviation }/> }
                  { travel?.concrete_trace?.application && <TravelDataListItem title={t(TravelDataMessages.resistanceComplement)} content={ travel.concrete_trace.resistance_complement }/> }
                  { travel?.concrete_trace?.gravel && <TravelDataListItem title={t(TravelDataMessages.gravel)} content={ travel.concrete_trace.gravel.abbreviation }/> }
                  {/* eslint-enable */ }
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) }
        </div>
      </div>
    </Container>
  );
};

export default TravelData;
