import React from "react";

// region Libraries
import { useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { VehicleBlockMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Interfaces
// region Styles
import { VehicleBlockHeaderStylesheet as Stylesheet } from "@components/Modal/VehicleBlock/VehicleBlockHeader/styles";
import IconBlockedVehicle from "@assets/icons/blocked-vehicle.svg";
import IconUnblockedVehicle from "@assets/icons/unblocked-vehicle.svg";
import { ReactSVG } from "react-svg";
// endregion Styles

type VehicleBlockHeaderProps = {
  isBlocked: boolean;
}

const VehicleBlockHeader: React.FC<VehicleBlockHeaderProps> = ({
  isBlocked
}) => {

  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  return (
    <Stylesheet isMobile={isMobile} isBlocked={isBlocked}>
      <div className="vehicle-block-header">
        <div className="vehicle-block-header__title">
          {isBlocked ? (
            <div
              className="iconContainer"
              style={{ backgroundColor: "#00AD00" }}
            >
              <ReactSVG className="icon" src={IconUnblockedVehicle} />
            </div>
          ) : (
            <div
              className="iconContainer"
              style={{ backgroundColor: "#F00" }}
            >
              <ReactSVG className="icon" src={IconBlockedVehicle} />
            </div>
          )}
          <div className="content">
            <div className="content__title">
              {t(VehicleBlockMessages.youAreAboutTo)}
              <strong className="strong">{isBlocked ? t(VehicleBlockMessages.toUnblock) : t(VehicleBlockMessages.toBlock)}</strong>
              {t(VehicleBlockMessages.aVehicle)}
            </div>
            <div className="content__subtitle">
              {t(VehicleBlockMessages.yourActionWill)}
              {isBlocked ? t(VehicleBlockMessages.toUnblock) : t(VehicleBlockMessages.toBlock)}
              {t(VehicleBlockMessages.vehicleMobilityAndFunctionality)}
            </div>
          </div>
        </div>
      </div>
    </Stylesheet>

  );
};

export default VehicleBlockHeader;
