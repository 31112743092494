import {
  ToastMessages,
  TranslationToastMessages
} from "../interfaces";

export const messages: TranslationToastMessages = {
  [ToastMessages.success]: "Success!",
  [ToastMessages.error]: "Error",
  [ToastMessages.alert]: "Alert!",
  [ToastMessages.connectionNotEstablished]: "Connection not established"
}
