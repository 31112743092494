import React from "react";

/* region Libraries */
import { Tab, Tabs } from "@material-ui/core";
/* endregion */
/* region Languages */
import useTranslation from "src/translations/useTranslation";
import { AverageSpeedMessages } from "@shared/languages/interfaces";
/* endregion */
/* region Components */
import AverageSpeedHistoryByVehicle from "@pages/Reports/AverageSpeedHistory/AverageSpeedByVehicle";
import AverageSpeedHistoryByDriver from "@pages/Reports/AverageSpeedHistory/AverageSpeedByDriver";
import Header from "@components/Header";
import TabPanel from "@components/TabPanel";
/* endregion */
/* region Shared */
/* endregion */
/* region Hooks */
import { useSelector } from "react-redux";
import usePersistedState from "@hooks/usePersistedState";
/* endregion */
/* region Store */
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
/* endregion */
/* region Styles */
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { Container } from "./styles";
/* endregion */

const AverageSpeedHistory: React.FC = () => {

  /* region States */
  const [selectedTab, setSelectedTab] = React.useState(0);
  /* endregion */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  /* endregion */
  /* region Methods */
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  /* endregion */

  return (
    <>
      <Header title={t(AverageSpeedMessages.averageSpeedHistoryTitle)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          className="tabContainer"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          <Tab label={t(AverageSpeedMessages.averageSpeedByVehicle)} disableRipple />
          <Tab label={t(AverageSpeedMessages.averageSpeedByDriver)} disableRipple />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <AverageSpeedHistoryByVehicle />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AverageSpeedHistoryByDriver />
        </TabPanel>
      </Container>
    </>
  );
};

export default AverageSpeedHistory;
