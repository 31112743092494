/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { Location } from "./reg_locations.entity";

@Entity("tpe_locations_types")
export class TpeLocation {

  @PrimaryGeneratedColumn("uuid")
  id_location_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => Location, (locations) => locations.type)
  locations?: Location[];

}
