import { combineReducers } from "redux";

import vehiclesStates from "./Vehicles/VehiclesStates/vehicles-states.reducer";
import aggregateTrucksStates from "./Vehicles/AggregateTrucksStates/aggregate-trucks-states.reducer";
import alerts from "./Alerts/alerts.reducer";
import locationsForm from "./Locations/LocationsForm/locations-form.reducer";
import filtersDashboard from "./Filters/FiltersDashboard/filters-dashboard.reducer";
import screen from "./Screen/screen.reducer";
import params from "./Params/params.reducer";

const reducers = combineReducers({
  vehiclesStates, aggregateTrucksStates, alerts, locationsForm, filtersDashboard, screen, params
});

export default reducers;
export type AppState = ReturnType<typeof reducers>;
