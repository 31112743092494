/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import Loading from "react-fullscreen-loading";

// region Libraries
import { MenuItem } from "@material-ui/core";
// endregion Libraries

// region Services
import api from "@services/api";
import { socket } from "@services/websocketContext";
// endregion Services

// region Languages
import { languages, TLanguages } from "@shared/languages/languageData";
import useTranslation from "src/translations/useTranslation";
// endregion Languages

// region Styles
import * as Styled from "./styles";
// endregion Styles

// region Interfaces
interface LanguageMenuProps {
  anchorElement: null | HTMLElement;
  onClose: (language: TLanguages) => void;
  inHeader?: boolean;
}
// endregion Interfaces

const LanguageMenu: React.FC<LanguageMenuProps> = ({ anchorElement, onClose, inHeader }) => {

  // region Hooks
  const { i18n } = useTranslation();
  // endregion Hooks
  // region Constants
  const currentLanguage = i18n.language;
  // endregion Constants
  // region States
  const [loading, setLoading] = useState(false);
  // endregion States

  const handleClick = (language: TLanguages) => {

    if (inHeader) setLoading(true);

    // Change language in i18n configuration
    i18n.changeLanguage(language, () => {

      // Change language in axios configuration (for API requests)
      api.interceptors.request.use((config) => {

        config.headers["Accept-Language"] = i18n.language;

        return config;
      });

      // Reconnect socket with new language
      socket.disconnect();

      socket.auth = { token: localStorage.getItem("@Fleet:token"), acceptLanguage: language };

      socket.connect();
    }).then(() => {

      // Change language in localStorage
      localStorage.setItem("@Fleet:language", language);

      // Close menu
      onClose(language);
    });

    setLoading(false);
  };

  return (
    <>
      <Loading loading={loading} />
      <Styled.Container
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorElement)}
        onClose={onClose}
      >
        { languages.map((language) => (
          <MenuItem
            key={language.language}
            onClick={() => handleClick(language.language)}
            className={currentLanguage === language.language ? "selected" : ""}
          >
            { language.description }
          </MenuItem>
        )) }
      </Styled.Container>
    </>
  );
};

export default LanguageMenu;
