/* eslint-disable import/prefer-default-export */
import { TranslationVehicleStatusMessages, VehicleStatusMessages } from "../interfaces";

export const messages: TranslationVehicleStatusMessages = {
  [VehicleStatusMessages.active]: "Activo",
  [VehicleStatusMessages.inactive]: "Inactivo",
  [VehicleStatusMessages.inMotion]: "En movimiento",
  [VehicleStatusMessages.stationary]: "Parado",
  [VehicleStatusMessages.tracker]: "Rastreador",
  [VehicleStatusMessages.drum]: "Tambor",
  [VehicleStatusMessages.currentSpeed]: "Velocidad actual",
  [VehicleStatusMessages.engine]: "Motor",
  [VehicleStatusMessages.engineOn]: "Encendido",
  [VehicleStatusMessages.engineOff]: "Apagado",
  [VehicleStatusMessages.engineRunningTime]: "Tiempo encendido",
  [VehicleStatusMessages.travel]: "Viaje",
  [VehicleStatusMessages.odometer]: "Odómetro",
  [VehicleStatusMessages.latestDataSent]: "Últimos datos enviados",
  [VehicleStatusMessages.lastLoadDateDescription]: "Tambor en sentido horario (Carga)",
  [VehicleStatusMessages.lastUnloadDateDescription]: "Tambor en sentido antihorario (Descarga)",
  [VehicleStatusMessages.pumpingTimeDescription]: "Bombeo",
  [VehicleStatusMessages.CANTelemetry]: "Telemetría CAN",
  [VehicleStatusMessages.totalFuelConsumption]: "Consumo total de combustible",
  [VehicleStatusMessages.radiatorTemperature]: "Temperatura del radiador",
  [VehicleStatusMessages.averageFuelConsumption]: "Consumo medio de combustible",
  [VehicleStatusMessages.fuelLevel]: "Nivel de combustible",
  [VehicleStatusMessages.totalEngineRunningTime]: "Tiempo total de funcionamiento del motor",
  [VehicleStatusMessages.totalDrivingTime]: "Tiempo total de conducción",
  [VehicleStatusMessages.totalFuelUsedInIdle]: "Total de combustible usado en ralentí",
  [VehicleStatusMessages.combinedGrossVehicleWeight]: "Peso bruto combinado del vehículo"
};
