import {
  PumpProgrammingImportsModuleMessages,
  TranslationPumpProgrammingImportsModuleMessages
} from "../interfaces/pumpProgrammingImportsModuleMessages";

export const messages: TranslationPumpProgrammingImportsModuleMessages = {
  [PumpProgrammingImportsModuleMessages.title]: "Importações de programações de bomba",

  [PumpProgrammingImportsModuleMessages.newRegister]: "Nova importação de programações",

  [PumpProgrammingImportsModuleMessages.importingResult]: "Resultado da importação",

  [PumpProgrammingImportsModuleMessages.columnDescription]: "Descrição",
  [PumpProgrammingImportsModuleMessages.columnStatus]: "Status",
  [PumpProgrammingImportsModuleMessages.columnRegistrationDate]: "Data de registro",
  [PumpProgrammingImportsModuleMessages.columnProcessingDate]: "Data de processamento",

  [PumpProgrammingImportsModuleMessages.readSuccess]: "Importações de programações de bomba retornadas com sucesso",
  [PumpProgrammingImportsModuleMessages.readError]: "Erro ao ler importações de programações de bomba",
  [PumpProgrammingImportsModuleMessages.createSuccess]: "Importação de programação de bomba criada com sucesso",
  [PumpProgrammingImportsModuleMessages.createError]: "Erro ao criar importação de programação de bomba",
  [PumpProgrammingImportsModuleMessages.cancelSuccess]: "Cancelamento da importação de programação de bomba realizado com sucesso",
  [PumpProgrammingImportsModuleMessages.cancelError]: "Erro ao cancelar a importação de programação de bomba",
  [PumpProgrammingImportsModuleMessages.pumpProgrammingImportNotFound]: "Importação de programação de bomba não encontrada",
  [PumpProgrammingImportsModuleMessages.noPumpProgrammingImports]: "Nenhuma importação de programação de bomba encontrada",
  [PumpProgrammingImportsModuleMessages.notFound]: "não encontrado no sistema",
  [PumpProgrammingImportsModuleMessages.insertedRecords]: "Registros inseridos",
  [PumpProgrammingImportsModuleMessages.updatedRecords]: "Registros atualizados"
};
