import { TranslationMailMessages, MailMessages } from "../interfaces";

export const messages: TranslationMailMessages = {

  // Password Reset
  [MailMessages.passwordReset]: "Redefinição de senha",
  [MailMessages.looksLikeYouForgotYourPassword]: "Parece que você esqueceu sua senha para",
  [MailMessages.clickHereToResetYourPassword]: "Clique abaixo para redefinir sua senha.",
  [MailMessages.setYourNewPassword]: "Defina sua nova senha.",
  [MailMessages.resetMyPassword]: "Redefinir minha senha",
  [MailMessages.ignoreEmailIfPasswordNotForgotten]: "Se você não esqueceu sua senha, pode ignorar este e-mail.",

  // Account confirmation
  [MailMessages.accountConfirmation]: "Confirmação de conta",
  [MailMessages.welcomeTo]: "Bem-vindo ao",
  [MailMessages.accessToPlatform]: "Acesso à plataforma",
  [MailMessages.confirmYourAccount]: "Confirme sua conta e cadastre sua senha para acessar a plataforma.",
  [MailMessages.createMyPassword]: "Criar minha senha",

  // General
  [MailMessages.hello]: "Olá",
  [MailMessages.needMoreInformation]: "Tem alguma dúvida ou precisa de mais informações?",
  [MailMessages.sendUsAnEmail]: "Envie-nos um e-mail",
  [MailMessages.bestRegards]: "Atenciosamente",
  [MailMessages.distributedBy]: "Distribuído por",

  // Report
  [MailMessages.dailyReportSubject]: "Relatório diário - TopconFLEET",
  [MailMessages.weeklyReportSubject]: "Relatório semanal - TopconFLEET",
  [MailMessages.syntheticReportFileName]: "relatorio-sintetico.pdf",
  [MailMessages.analyticalReportFileName]: "relatorio-analitico.pdf",
  [MailMessages.belowYouCanSeeTheAlertGraphs]: "Abaixo você pode ver os gráficos de alerta",
  [MailMessages.ofTheLastTwoWeeks]: "das últimas duas semanas",
  [MailMessages.ofTheLastTwoDays]: "dos últimos dois dias",
  [MailMessages.moreInformationCanBeFoundAt]: "Informações mais detalhadas podem ser encontradas nos relatórios PDF em anexo.",
};
