export enum VehiclesHistoryMessages {
  data = "vehiclesHistory.data",
  equipment = "vehiclesHistory.equipment",
  travels = "vehiclesHistory.travels",
  engineRuntime = "vehiclesHistory.engineRuntime",
  kmTraveled = "vehiclesHistory.kmTraveled",
  productionVolume = "vehiclesHistory.productionVolume",
  volumePerTrip = "vehiclesHistory.volumePerTrip",
  durationPerTrip = "vehiclesHistory.durationPerTrip",
  engineRuntimePerTrip = "vehiclesHistory.engineRuntimePerTrip",
  kmTraveledPerTrip = "vehiclesHistory.kmTraveledPerTrip",
  speedingAlerts = "vehiclesHistory.speedingAlerts",
  improperDischargeAlerts = "vehiclesHistory.improperDischargeAlerts",
  improperStopAlerts = "vehiclesHistory.improperStopAlerts",
  licensePlate = "vehiclesHistory.licensePlate",
  durationInMinutes = "vehiclesHistory.durationInMinutes",
  engineRuntimeInMinutes = "vehiclesHistory.engineRuntimeInMinutes",
  distanceInKilometers = "vehiclesHistory.distanceInKilometers",
  durationPerTripInMinutes = "vehiclesHistory.durationPerTripInMinutes",
  engineRuntimePerTripInMinutes = "vehiclesHistory.engineRuntimePerTripInMinutes",
  distancePerTripInKilometers = "vehiclesHistory.distancePerTripInKilometers",
  title = "vehiclesHistory.title",
  vehicleType = "vehiclesHistory.vehicleType",
  generalHistory = "vehiclesHistory.generalHistory",
  historyByEquipment = "vehiclesHistory.historyByEquipment"
}

export type TranslationVehiclesHistoryMessages = {
  [key in VehiclesHistoryMessages]: string;
}
