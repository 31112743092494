import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
}

const className = "vehicle-maintenance__form";

const VehicleMaintenanceForm = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`

  .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .container-vehicle-maintenance-form__justify {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22rem;
  }

  .container-vehicle-maintenance-form__password {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
  }

  .vehicle-maintenance-form__instruction {
    display: flex;
    line-height: 1.4;
    align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
    gap: 0.2rem;

    .strong {
      color: #666666;
    }
  }

  .vehicle-maintenance-form__justification {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .maintenance_button {
    color: white;
    background-color: #666666;
    border-radius: 8px;
    padding: 6px 32px 6px 32px;

    svg {
      color: inherit;
      margin-right: 2rem;
    }
  }
  .speedTravelButton {
    color: white;
    background-color: #666666;
    border-radius: 8px;
    padding: 6px 32px 6px 32px;

    svg {
      color: inherit;
      margin-right: 2rem;
    }
  }
`;

export { VehicleMaintenanceForm };
