/* eslint-disable import/prefer-default-export */
import {
  TranslationAggregateTruckTravelDataMessages,
  AggregateTruckTravelDataMessages
} from "../interfaces";

export const messages: TranslationAggregateTruckTravelDataMessages = {
  [AggregateTruckTravelDataMessages.origin]: "Origen",
  [AggregateTruckTravelDataMessages.loadingLocation]: "Ubicación de carga",
  [AggregateTruckTravelDataMessages.unloadingLocation]: "Ubicación de descarga",
  [AggregateTruckTravelDataMessages.productDocument]: "Documento del producto",
  [AggregateTruckTravelDataMessages.serviceOrderDate]: "Fecha de la orden de servicio",
  [AggregateTruckTravelDataMessages.travelStartDate]: "Fecha de inicio del viaje",
  [AggregateTruckTravelDataMessages.travelDocumentDate]: "Fecha de emisión del documento de viaje",
  [AggregateTruckTravelDataMessages.quantity]: "Cantidad"
};
