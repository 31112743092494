import { VehicleTypesID } from "../../constants/vehicle-types.enum";

export enum VehiclesModuleMessages {
  /* region Backend messages */
  /* region Vehicle-events messages */
  updateVehicleEventSuccess = "vehiclesModule.updateVehicleEventSuccess",
  eventNotFound = "vehiclesModule.eventNotFound",
  updateVehicleEventError = "vehiclesModule.updateVehicleEventError",
  readVehicleEventSuccess = "vehiclesModule.readVehicleEventSuccess",
  noVehicleEventFound = "vehiclesModule.noVehicleEventFound",
  readVehicleEventError = "vehiclesModule.readVehicleEventError",
  /* endregion */
  /* region Vehicles-states messages */
  createVehicleStateSuccess = "vehiclesModule.createVehicleStateSuccess",
  createVehicleStateError = "vehiclesModule.createVehicleStateError",
  readVehicleStateSuccess = "vehiclesModule.readVehicleStateSuccess",
  noVehicleStateFound = "vehiclesModule.noVehicleStateFound",
  readVehicleStateError = "vehiclesModule.readVehicleStateError",
  noVehicleStateInTravelFound = "vehiclesModule.noVehicleStateInTravelFound",
  getVehiclesAndTravelsError = "vehiclesModule.getVehiclesAndTravelsError",
  getVehiclesHardwaresError = "vehiclesModule.getVehiclesHardwaresError",
  getVehiclesAlertsError = "vehiclesModule.getVehiclesAlertsError",
  getMixerTravelsSpecificInfoError = "vehiclesModule.getMixerTravelsSpecificInfoError",
  getPumpTravelsSpecificInfoError = "vehiclesModule.getPumpTravelsSpecificInfoError",
  getAggregateTruckTravelsSpecificInfoError = "vehiclesModule.getAggregateTruckTravelsSpecificInfoError",
  noVehicleStateInVehicleFound = "vehiclesModule.noVehicleStateInVehicleFound",
  /* endregion */

  /* region Vehicle-states-types messages */
  readVehicleStateTypeSuccess = "vehiclesModule.readVehicleStateTypeSuccess",
  noVehicleStateTypeFound = "vehiclesModule.noVehicleStateTypeFound",
  readVehicleStateTypeError = "vehiclesModule.readVehicleStateTypeError",
  /* endregion */

  /* region Vehicle-types messages */
  readVehicleTypeSuccess = "vehiclesModule.readVehicleTypeSuccess",
  noVehicleTypeFound = "vehiclesModule.noVehicleTypeFound",
  readVehicleTypeError = "vehiclesModule.readVehicleTypeError",
  /* endregion */

  /* region Vehicles messages */
  createVehicleSuccess = "vehiclesModule.createVehicleSuccess",
  createVehicleError = "vehiclesModule.createVehicleError",
  readVehicleSuccess = "vehiclesModule.readVehicleSuccess",
  noVehicleFound = "vehiclesModule.noVehicleFound",
  readVehicleError = "vehiclesModule.readVehicleError",
  updateVehicleSuccess = "vehiclesModule.updateVehicleSuccess",
  vehicleNotFound = "vehiclesModule.vehicleNotFound",
  updateVehicleError = "vehiclesModule.updateVehicleError",
  deleteVehicleSuccess = "vehiclesModule.deleteVehicleSuccess",
  onlyInactivateVehicleMessage = "vehiclesModule.onlyInactivateVehicleMessage",
  deleteVehicleError = "vehiclesModule.deleteVehicleError",
  code = "vehiclesModule.code",
  licensePlate = "vehiclesModule.licensePlate",
  invalidUserOrPassword = "vehiclesModule.invalidUserOrPassword",
  userNotAuthorizedToBlockVehicle = "vehiclesModule.userNotAuthorizedToBlockVehicle",
  vehicleWithoutHardware = "vehiclesModule.vehicleWithoutHardware",
  vehicleWithoutBlockCapableHardware = "vehiclesModule.vehicleWithoutBlockCapableHardware",
  blockRequestSent = "vehiclesModule.blockRequestSent",
  unblockRequestSent = "vehiclesModule.unblockRequestSent",
  blockRequestError = "vehiclesModule.blockRequestError",
  general = "vehiclesModule.general",
  total = "vehiclesModule.total",
  average = "vehiclesModule.average",

  /* region Vehicle types */
  concreteMixers = "vehiclesModule.concreteMixers",
  concreteMixer = "vehiclesModule.concreteMixer",
  pumpTrucks = "vehiclesModule.pumpTrucks",
  pumpTruck = "vehiclesModule.pumpTruck",
  supportVehicles = "vehiclesModule.supportVehicles",
  supportVehicle = "vehiclesModule.supportVehicle",
  loaders = "vehiclesModule.loaders",
  loader = "vehiclesModule.loader",
  aggregateTrucks = "vehiclesModule.aggregateTrucks",
  aggregateTruck = "vehiclesModule.aggregateTruck",
  siloTrucks = "vehiclesModule.siloTrucks",
  siloTruck = "vehiclesModule.siloTruck",
  /* endregion */

  /* region Integration messages */
  locationNotRegistered = "vehiclesModule.locationNotRegistered",
  driverNotRegistered = "vehiclesModule.driverNotRegistered",
  externalIDAlreadyRegistered = "vehiclesModule.externalIDAlreadyRegistered",
  licensePlateAlreadyRegistered = "vehiclesModule.licensePlateAlreadyRegistered",
  codeAlreadyRegistered = "vehiclesModule.codeAlreadyRegistered",
  invalidDateFormat = "vehiclesModule.invalidDateFormat",
  noDataForThisVehicleOrDay = "vehiclesModule.noDataForThisVehicleOrDay",
  readVehicleDailySummarySuccess = "vehiclesModule.readVehicleDailySummarySuccess",
  vehicleWithoutTracker = "vehiclesModule.vehicleWithoutTracker",
  /* endregion */
  /* endregion */
  /* endregion */

  /* region Frontend messages */
  description = "vehiclesModule.description",
  status = "vehiclesModule.status",
  odometer = "vehiclesModule.odometer",
  odometerInKm = "vehiclesModule.odometerInKm",
  hourMeter = "vehiclesModule.hourMeter",
  hourMeterInHours = "vehiclesModule.hourMeterInHours",
  maintenance = "vehiclesModule.maintenance",
  originalAllocation = "vehiclesModule.originalAllocation",
  currentAllocation = "vehiclesModule.currentAllocation",
  currentEvent = "vehiclesModule.currentEvent",
  searchingVehicles = "vehiclesModule.searchingVehicles",
  vehicleDeleteConfirmation = "vehiclesModule.vehicleDeleteConfirmation",
  vehicleRegistration = "vehiclesModule.vehicleRegistration",
  activeVehicle = "vehiclesModule.activeVehicle",
  active = "vehiclesModule.active",
  inactive = "vehiclesModule.inactive",
  vehicleUnderMaintenance = "vehiclesModule.vehicleUnderMaintenance",
  vehicleType = "vehiclesModule.vehicleType",
  maxSpeedAllowed = "vehiclesModule.maxSpeedAllowed",
  allocationPlant = "vehiclesModule.allocationPlant",
  defaultDriver = "vehiclesModule.defaultDriver",
  title = "vehiclesModule.title",
  deleteDialogTitle = "vehiclesModule.deleteDialogTitle",
  vehicles = "vehiclesModule.vehicles",
  blocks = "vehiclesModule.blocks",
  group = "vehiclesModule.group",
  regional = "vehiclesModule.regional",
  plant = "vehiclesModule.plant",
  lastCommunicationDate = "vehiclesModule.lastCommunicationDate",
  lastCommunication = "vehiclesModule.lastCommunication",
  ignition = "vehiclesModule.ignition",
  lastIgnitionDate = "vehiclesModule.lastIgnitionDate",
  lastPlantExitDate = "vehiclesModule.lastPlantExitDate",
  currentLatitude = "vehiclesModule.currentLatitude",
  currentLongitude = "vehiclesModule.currentLongitude",
  currentAddress = "vehiclesModule.currentAddress",
  activeHardware = "vehiclesModule.activeHardware",

  analyticReport = "vehiclesModule.analyticReport"
  /* endregion */

}

export type TranslationVehiclesModuleMessages = {
  [key in VehiclesModuleMessages]: string;
}

export const VehicleTypesMessages = {
  [`${VehicleTypesID.BETONEIRA}-plural`]: VehiclesModuleMessages.concreteMixers,
  [`${VehicleTypesID.CAMINHAO_BOMBA}-plural`]: VehiclesModuleMessages.pumpTrucks,
  [`${VehicleTypesID.VEICULO_DE_APOIO}-plural`]: VehiclesModuleMessages.supportVehicles,
  [`${VehicleTypesID.PA_CARREGADEIRA}-plural`]: VehiclesModuleMessages.loaders,
  [`${VehicleTypesID.CARRETA_AGREGADOS}-plural`]: VehiclesModuleMessages.aggregateTrucks,
  [`${VehicleTypesID.CARRETA_SILO}-plural`]: VehiclesModuleMessages.siloTrucks,
  [VehicleTypesID.BETONEIRA]: VehiclesModuleMessages.concreteMixer,
  [VehicleTypesID.CAMINHAO_BOMBA]: VehiclesModuleMessages.pumpTruck,
  [VehicleTypesID.VEICULO_DE_APOIO]: VehiclesModuleMessages.supportVehicle,
  [VehicleTypesID.PA_CARREGADEIRA]: VehiclesModuleMessages.loader,
  [VehicleTypesID.CARRETA_AGREGADOS]: VehiclesModuleMessages.aggregateTruck,
  [VehicleTypesID.CARRETA_SILO]: VehiclesModuleMessages.siloTruck
};
