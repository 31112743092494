/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from "react";

// region Assets
import { ReactComponent as AlertSpeedIcon } from "@assets/icons/alerts/criar-alerta-velocidade.svg";
import { ReactComponent as AlertSpeedIconRed } from "@assets/icons/alerts/create-alert-speed-red.svg";
import { ReactComponent as AlertGreenButtonIcon } from "@assets/icons/alerts/alert-button-speed-green.svg";
import { ReactComponent as AlertRedButtonIcon } from "@assets/icons/alerts/alert-button-speed-red.svg";
import { ReactComponent as AlertSpeed } from "@assets/icons/alerts/alert-speed-travel.svg";
import { ReactComponent as NotificationSpeedIcon } from "@assets/icons/alerts/notification-speed-travel.svg";
import { ReactComponent as CardExampleSpeed } from "@assets/icons/alerts/alert-example-speed-travel.svg";
import LockIcon from "@mui/icons-material/Https";
// endregion Assets
// region Libraries
import * as Yup from "yup";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  GlobalMessages,
  ToastMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
import { FormHandles } from "@unform/core";
// endregion Interfaces
// region Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
// endregion Components
// region Shared
import { AlertTypesID } from "@shared/constants/alert-types.enum";
// endregion Shared
// region Styles
import getValidationErrors from "@hooks/getValidationErrors";
import { TravelAlertFullSpeedMessage } from "@shared/languages/interfaces/travelAlertFullSpeedMessage";
import api from "@services/api";
import { Travel } from "@shared/interfaces/travel.interface";
import { useToast } from "@hooks/useToast";
import utils from "@utils/useful-functions";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { VehiclesStatesActions } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.action";

import { VehicleStatesList } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
import * as Styled from "./styles";
// endregion Styles

type TTravelSpeedAlertFormProps = {
  travel: Travel;
  vehicle: Vehicle;
  onClose:() => void;
}
interface FormData {
  speed: string;
}

const TravelSpeedAlertForm: React.FC<TTravelSpeedAlertFormProps> = ({ travel, vehicle, onClose }) => {
  // region Hooks
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  // endregion Hooks
  // region States
  const [openDialogConfirmFullSpeed, setOpenDialogConfirmFullSpeed] = useState(false);
  const [speed, setSpeed] = useState("");
  const [dialogSetSpeed, setdDialogSetSpeed] = useState(true);
  const [countSpeedAlerts, setCountSpeedAlerts] = useState(0);
  const handleInputChange = (event) => {
    setSpeed(event.target.value);

  };
  // endregion States
  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Functions

  useEffect(() => {
    if (vehicle && vehicle.alerts) {
      const specificAlerts = vehicle.alerts.filter((alert) => alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM);

      setCountSpeedAlerts(specificAlerts.length);
    }
  }, [vehicle]);

  /**
   * Submit form to change maintenance vehicle
   * @param formData Form data
   */
  const handleSubmit = useCallback(async (formData: FormData) => {

    try {

      // Define the validation types
      const schema = Yup.object().shape({
        fullSpeed: Yup
          .number()
          .required(t(GlobalMessages.required))
      });

      // Validate inputs
      await schema.validate(formData, { abortEarly: false });

      setOpenDialogConfirmFullSpeed(true);

    } catch (error) {
      formRef.current?.setErrors(getValidationErrors(error));
    }

  }, [t]);

  // endregion Functions
  const handleChangeSpeedTravelAlertFormProps = useCallback(async () => {
    try {
      const requestBodyData = {
        id_travel: travel.id_travel,
        alert_maximum_speed: Number(speed)
      };

      const { data } = await api.patch(`travels/update-speed/${travel.id_travel}`, requestBodyData);

      if (data.status === "success") {

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setOpenDialogConfirmFullSpeed(false);

        const updatedVehiclesList: VehicleStatesList = { ...vehiclesStates.all.list };
        const travelStatus = vehicle.states[vehicle.states.length - 1];
        const vehicles = updatedVehiclesList[travelStatus.status.description];

        const [result] = vehicles.filter((currentVehicle) => vehicle.id_vehicle === currentVehicle.id_vehicle);

        if (result) {
          try {
            result.current_travel.alert_maximum_speed = Number(speed);
            result.current_travel.updated_date_alert_maximum_speed = new Date();
          } catch (error) {
            if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
            else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
          }
        }
        // update vehicle information
        dispatch(VehiclesStatesActions.setVehiclesStatesAll({
          list: updatedVehiclesList,
          loading: false
        }));
        onClose();
      } else addToast({ type: "error", title: t(ToastMessages.error), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    }

  }, [addToast, t, travel, speed, vehicle]);

  useEffect(() => {
    if (travel.alert_maximum_speed) {
      setdDialogSetSpeed(false);
    } else {
      setdDialogSetSpeed(true);
    }
  }, [travel.alert_maximum_speed]);

  return (
    <>
      { dialogSetSpeed && (
        <Styled.SetTravelSpeedAlertForm isMobile={isMobile}>
          <Form className="form" ref={formRef} onSubmit={handleSubmit}>
            <div className="container-travel-speed-form__justify">
              <div className="travel-speed-form__instruction">
                <strong className="strong" style={{ color: "red" }}>2 </strong>
                {t(TravelAlertFullSpeedMessage.setSpeed)}
              </div>
              <TextField
                className="name"
                label={t(TravelAlertFullSpeedMessage.SpeedInput)}
                margin="dense"
                variant="outlined"
                name="fullSpeed"
                type="number"
                fullWidth
                value={speed}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: <div> km/h</div>
                }}
              />
            </div>

            <div>
              <strong className="strong" style={{ color: "red" }}> 3 </strong>
              {t(TravelAlertFullSpeedMessage.infoOnceAlert)}
            </div>
            <div className="container">
              <div className="card">
                <CardExampleSpeed />
              </div>
              <div className="columnContainer">
                <AlertGreenButtonIcon />
                <AlertRedButtonIcon />
              </div>
              <div className="columnContainer">
                <AlertSpeed />
                <NotificationSpeedIcon />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <p>
                <strong className="strong" style={{ color: "red" }}> 4 </strong>
                {t(TravelAlertFullSpeedMessage.infoAlertSpeed)}
              </p>
            </div>
            <Button
              className="fullSpeedTravel_button red_button"
              type="submit"
              disabled={!speed}
              disableRipple
              variant="contained"
              onClick={() => setOpenDialogConfirmFullSpeed(true)}
            >
              <AlertSpeedIconRed style={{ marginRight: "20px" }} />
              {t(TravelAlertFullSpeedMessage.createAlertSpeedTravel)}
              <br />
              {t(TravelAlertFullSpeedMessage.limitFullSpeed)}
            </Button>
          </Form>
          <DialogConfirmAction
            open={openDialogConfirmFullSpeed}
            onClose={() => setOpenDialogConfirmFullSpeed(false)}
            title={(
              <>
                <span>{t(TravelAlertFullSpeedMessage.confirmFullSpeedTravel)}</span>
                <span className="strong" style={{ color: "red" }}>{speed} km/h</span>
              </>
            )}
            actions={[
              { text: t(GlobalMessages.no).toUpperCase(),
                action: () => setOpenDialogConfirmFullSpeed(false) },
              {
                text: t(GlobalMessages.yes).toUpperCase(),
                action: async () => {
                  await handleChangeSpeedTravelAlertFormProps();
                  setOpenDialogConfirmFullSpeed(false);
                }
              }
            ]}
          >
            {t(GlobalMessages.confirmTravel)}
          </DialogConfirmAction>
        </Styled.SetTravelSpeedAlertForm>
      )}
      { !dialogSetSpeed && (
        <Styled.SetTravelSpeedAlertForm isMobile={isMobile}>
          <Form className="form" ref={formRef} onSubmit={handleSubmit}>
            <div className="container-travel-speed-form__justify">
              <div className="travel-speed-form__instruction">
                <strong className="strong" style={{ color: "red" }}>2 </strong>
                {t(TravelAlertFullSpeedMessage.configuredSpeed)}
              </div>
              <TextField
                className="name"
                disabled
                margin="dense"
                variant="outlined"
                name="fullSpeed"
                fullWidth
                type="number"
                value={travel.alert_maximum_speed}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: <div><LockIcon /></div>
                }}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <p>
                <p>
                  <strong className="strong" style={{ color: "red" }}> 3 </strong>
                  {t(TravelAlertFullSpeedMessage.configuredAlertDate)}
                </p>
                <strong>
                  {utils.formatDateIfHave(travel.updated_date_alert_maximum_speed, "timeWithoutSeconds")}
                  {t(TravelAlertFullSpeedMessage.dayOfSetFullSpeed)}
                  {utils.formatDateIfHave(travel.updated_date_alert_maximum_speed, "date")}.
                </strong>
              </p>
            </div>
            { countSpeedAlerts > 0 && (
            <div style={{ textAlign: "center" }}>
              <strong className="strong" style={{ color: "red" }}> 4 </strong>
              <span>{t(TravelAlertFullSpeedMessage.alertCount)}</span>
              <p className="strong" style={{ color: "red" }}>{countSpeedAlerts} vezes</p>
            </div>
            )}

            <Button
              className="fullSpeedTravel_button"
              type="submit"
              disabled
              startIcon={<AlertSpeedIcon />}
              variant="contained"
              onClick={() => setOpenDialogConfirmFullSpeed(true)}
            >
              {t(TravelAlertFullSpeedMessage.createAlertSpeedTravel)}
              <br />
              {t(TravelAlertFullSpeedMessage.limitFullSpeed)}
            </Button>
          </Form>
          <DialogConfirmAction
            open={openDialogConfirmFullSpeed}
            onClose={() => setOpenDialogConfirmFullSpeed(false)}
            title={(
              <>
                <span>{t(TravelAlertFullSpeedMessage.confirmFullSpeedTravel)}</span>
                <span className="strong" style={{ color: "red" }}>{speed} km/h</span>
              </>
            )}
            actions={[
              { text: t(GlobalMessages.no).toUpperCase(),
                action: () => setOpenDialogConfirmFullSpeed(false) },
              {
                text: t(GlobalMessages.yes).toUpperCase(),
                action: async () => {
                  await handleChangeSpeedTravelAlertFormProps();
                  setOpenDialogConfirmFullSpeed(false);
                }
              }
            ]}
          >
            {t(GlobalMessages.confirm)}
          </DialogConfirmAction>
        </Styled.SetTravelSpeedAlertForm>
      )}
    </>
  );
};

export default TravelSpeedAlertForm;
