import { DataTableMessages, TranslationDataTableMessages } from "../interfaces";

export const messages: TranslationDataTableMessages = {
  [DataTableMessages.status]: "Status",
  [DataTableMessages.actions]: "Acciones",
  [DataTableMessages.buttonsAddNew]: "Agregar nuevo",
  [DataTableMessages.buttonsExport]: "Exportar",
  [DataTableMessages.buttonsPrint]: "Imprimir",
  [DataTableMessages.buttonsRefresh]: "Actualizar",
  [DataTableMessages.emptyTable]: "No se encontraron registros",
  [DataTableMessages.info]: "Mostrando _START_ a _END_ de _TOTAL_ registros",
  [DataTableMessages.infoEmpty]: "Mostrando 0 a 0 de 0 registros",
  [DataTableMessages.infoFiltered]: "(Filtrados de _MAX_ registros)",
  [DataTableMessages.lengthMenu]: "_MENU_ registros por página",
  [DataTableMessages.loadingRecords]: "Cargando...",
  [DataTableMessages.processing]: "Procesando...",
  [DataTableMessages.zeroRecords]: "No se encontraron registros",
  [DataTableMessages.search]: "Buscar...",
  [DataTableMessages.searchPlaceholder]: "Filtrar datos en pantalla...",
  [DataTableMessages.filterTitle]: "Filtros",
  [DataTableMessages.filterApplied]: "Filtros aplicados a",
  [DataTableMessages.filterNoneApplied]: "No hay filtros aplicados",
  [DataTableMessages.filterCorrespondence]: "Correspondencia de todos estos filtros",
  [DataTableMessages.filterAdd]: "Agregar filtro",
  [DataTableMessages.filterRemoveAll]: "Remover todos",
  [DataTableMessages.filterApply]: "Aplicar filtro",
  [DataTableMessages.filterNewFilterField]: "Nuevo filtro de campo",
  [DataTableMessages.filterOperatorEquals]: "Igual a",
  [DataTableMessages.filterOperatorNotEquals]: "Diferente de",
  [DataTableMessages.filterOperatorStartsWith]: "Comienza con",
  [DataTableMessages.filterOperatorContains]: "Contiene",
  [DataTableMessages.filterOperatorNotContains]: "No contiene",
  [DataTableMessages.filterOperatorGreaterThan]: "Mayor que",
  [DataTableMessages.filterOperatorLessThan]: "Menor que",
  [DataTableMessages.filterOperatorGreaterThanOrEqual]: "Mayor o igual a",
  [DataTableMessages.filterOperatorLessThanOrEqual]: "Menor o igual a",
  [DataTableMessages.filterProperty]: "Propiedad",
  [DataTableMessages.filterValue]: "Valor",
  [DataTableMessages.filterOperator]: "Operador",
  [DataTableMessages.moreRecords]: "Cargar más registros"
};
