import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

interface Actions {
  text: string,
  action: () => void
}

type DialogConfirmActionProps = {
  open: boolean,
  onClose: () => void,
  title: any,
  actions: Actions[],
  zIndex?: number
}
const DialogConfirmAction: React.FC<DialogConfirmActionProps> = (
  { children, open, onClose, title, actions, zIndex = 2000 }
) => (
  <Dialog
    style={{ zIndex }}
    id="dialogConfirmAction"
    open={open}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {children}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {actions.map((action) => (
        <Button disableRipple key={action.text} onClick={() => action.action()} color="primary">
          {action.text}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

export default DialogConfirmAction;
