/* eslint-disable camelcase, max-classes-per-file */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { Alert } from "./reg_alerts.entity";
import { Driver } from "./reg_drivers.entity";
import { Event } from "./reg_events.entity";
import { Location } from "./reg_locations.entity";
import { Coord } from "./reg_coords.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { VehicleState } from "./reg_vehicles_states.entity";
import { Trace } from "./reg_traces.entity";
import { TravelContact } from "./reg_travels_contacts.entity";
import { Hardware } from "./reg_hardwares.entity";
import { PumpStatus } from "./reg_pump_status.entity";
import { ProductType } from "../types/product_type";
import { ServiceOrder } from "./reg_service_orders.entity";
import { WaterAddition } from "./reg_water_addition.entity";
import { PumpProgrammingHistory } from "./hty_pump_programmings.entity";

import { formatDateIfHave } from "../utils/useful-functions";

@Entity("reg_travels")
export class Travel {

  @PrimaryGeneratedColumn("uuid")
    id_travel?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @Column({ type: "varchar", nullable: true })
    num_doc?: string;

  @Column({ type: "varchar", nullable: true })
    load_label?: string;

  @Column({ type: "varchar", nullable: true })
    description?: string;

  @Column({ type: "interval", nullable: true })
    duration?: Date;

  @Column({ type: "timestamp", nullable: true })
    finish_date?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    integration_date?: Date;

  @Column({ type: "boolean", nullable: false, default: true })
    started?: boolean;

  @Column({ type: "boolean", nullable: false, default: false })
    already_discharged?: boolean;

  @Column({ type: "timestamp", nullable: true })
    document_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    concrete_expiration?: Date;

  @Column({ type: "varchar", nullable: true })
    concrete_order?: string;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    volume?: number;

  @Column({ type: "numeric", nullable: true })
    alert_maximum_speed?: number;

  @Column({ type: "timestamp", nullable: true })
    updated_date_alert_maximum_speed?: Date;

  @Column({ type: "numeric", nullable: true })
    average_speed?: number;

  @Column({ type: "timestamp", nullable: true })
    last_due_unload_date?: Date;

  @ManyToOne(() => Driver, (driver) => driver.travels, { nullable: true })
  @JoinColumn({ name: "id_driver_fk" })
    driver?: Driver;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.travels, { nullable: false })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

  @ManyToOne(() => Vehicle, (triggered_vehicle) => triggered_vehicle.travels, { nullable: true })
  @JoinColumn({ name: "id_triggered_vehicle_fk" })
    triggered_vehicle?: Vehicle;

  @ManyToOne(() => Location, (destination) => destination.travels_destination, { nullable: true })
  @JoinColumn({ name: "id_destination_location_fk" })
    destination?: Location;

  @ManyToOne(() => Location, (origin) => origin.travels_origin, { nullable: false })
  @JoinColumn({ name: "id_origin_location_fk" })
    origin?: Location;

  @ManyToOne(() => Location, (end) => end.travels_end, { nullable: true })
  @JoinColumn({ name: "id_end_location_fk" })
    end?: Location;

  @OneToMany(() => VehicleState, (states) => states.travel)
    states?: VehicleState[];

  @OneToMany(() => Event, (events) => events.travel)
    events?: Event[];

  @OneToMany(() => Alert, (alerts) => alerts.travel)
    alerts?: Alert[];

  @OneToMany(() => Vehicle, (vehicles_at) => vehicles_at.current_travel)
    vehicles_at?: Vehicle[];

  @OneToMany(() => Coord, (coord) => coord.travel)
    coords?: Coord[];

  @OneToMany(() => PumpStatus, (pump_status) => pump_status.travel)
    pump_status?: PumpStatus[];

  @OneToMany(() => WaterAddition, (waterAddition) => waterAddition.travel)
    water_additions?: WaterAddition[];

  @OneToMany(() => PumpProgrammingHistory, (pumpProgrammingHistory) => pumpProgrammingHistory.user)
    pumpProgrammingHistoryList?: PumpProgrammingHistory[];

  @ManyToOne(() => Trace, (concrete_trace) => concrete_trace.travels, { nullable: true })
  @JoinColumn({ name: "id_trace_fk" })
    concrete_trace?: Trace;

  @ManyToOne(() => Hardware, (tracker) => tracker.travels, { nullable: true })
  @JoinColumn({ name: "id_tracker_hardware_fk" })
    tracker?: Hardware;

  @ManyToMany(() => TravelContact, (contacts) => contacts.travels)
    contacts?: TravelContact[];

  @OneToOne(() => ServiceOrder, (service_order) => service_order.travel, { nullable: true })
  @JoinColumn({ name: "id_service_order_fk" })
    service_order?: ServiceOrder;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_odometer?: number;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    finish_odometer?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_total_water_added?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    finish_total_water_added?: number;

  @Column({ type: "varchar", nullable: true })
    load_group?: string;

  @Column({ type: "enum", enum: ProductType, nullable: false, default: ProductType.CONCRETE })
    product_type?: ProductType;

  @Column({ type: "boolean", nullable: false, default: false })
    canceled?: boolean;

  @Column({ type: "timestamp", nullable: true })
    cancellation_date?: Date;

  @Column({ type: "boolean", nullable: false, default: false })
    driver_ibutton_verified?: boolean;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_hour_meter?: number;

  @Column({
    type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    finish_hour_meter?: number;

  @Column({ type: "varchar", nullable: true })
    client?: string;

  @Column({ type: "boolean", nullable: false, default: false })
    already_loaded?: boolean;

  @ManyToMany(() => Travel, (travel) => travel.related_pump_travel, { nullable: true })
  @JoinTable({
    name: "rel_pump_mixers_travels",
    joinColumn: {
      name: "id_pump_travel_fk",
      referencedColumnName: "id_travel"
    },
    inverseJoinColumn: {
      name: "id_mixer_travel_fk",
      referencedColumnName: "id_travel"
    }
  })
    related_mixers_travels?: Travel[];

  @ManyToMany(() => Travel, (travel) => travel.related_mixers_travels, { nullable: true })
    related_pump_travel?: Travel;

  GetLabelName?(): string {
    let description = `[ ${formatDateIfHave(this?.registration_date, "fullDateWithoutYear")} ]
      ${this?.end?.name ?? this?.end?.address ?? ""}`;

    if (this.num_doc) {
      description = `[ ${formatDateIfHave(this?.document_date, "fullDateWithoutYear")} ]
        (${this.num_doc}) ${this.description ?? ""}`;
    } else if (this.destination) {
      description = `[ ${formatDateIfHave(this?.registration_date, "fullDateWithoutYear")} ]
        ${this.destination.address ?? ""}`;
    }

    return description;
  }

}

export class TravelErrors {

  static readonly UNIQUE_CONSTRAINT = "3.8.1 (TCON333831)";

  static readonly LINKED_ENTITY_NOT_FOUND = "3.8.2 (TCON333832)";

  static readonly TRAVEL_IN_USE = "3.8.3 (TCON333833)";

}
