export enum TravelAlertFullSpeedMessage {
  /* region Frontend */
  youAreAboutFullSpeedTravel = "travelAlertFullSpeedMessage.youAreAboutFullSpeedTravel",
  alertOfFullSpeed = "travelAlertFullSpeedMessage.alertOfFull",
  alertToVehicle= "travelAlertFullSpeedMessage.alertToVehicle",
  subtitleOnlyOneAlert = "travelAlertFullSpeedMessage.subtitleOnlyOneAlert",
  checkVehicle = "travelAlertFullSpeedMessage.checkVehicle",
  setSpeed = "travelAlertFullSpeedMessage.setSpeed",
  SpeedInput = "travelAlertFullSpeedMessage.SpeedInput",
  infoAlertSpeed = "travelAlertFullSpeedMessage.infoAlertSpeed",
  infoOnceAlert = "travelAlertFullSpeedMessage.infoOnceAlert",
  createAlertSpeedTravel = "travelAlertFullSpeedMessage.createAlertSpeedTravel",
  limitFullSpeed = "travelAlertFullSpeedMessage.limitFullSpeed",
  confirmFullSpeedTravel = "travelAlertFullSpeedMessage.confirmFullSpeedTravel",
  speed = "travelAlertFullSpeedMessage.speed",
  createFullSpeedTravel = "travelAlertFullSpeedMessage.createFullSpeedTravel",
  configuredSpeed = "travelAlertFullSpeedMessage.configuredSpeed",
  configuredAlertDate = "travelAlertFullSpeedMessage.confifuredAlertDate",
  alreadyFullSpeed = "travelAlertFullSpeedMessage.alreadyFullSpeed",
  toThisVehicle = "travelAlertFullSpeedMessage.toThisVehicle",
  OnlySetByTravel = "travelAlertFullSpeedMessage.OnlySetByTravel",
  vehicleWithAlert = "travelAlertFullSpeedMessage.vehicleWithAlert",
  dayOfSetFullSpeed = "travelAlertFullSpeedMessage.dayOfSetFullSpeed",
  alertCount = "travelAlertFullSpeedMessage.alertCount",
  timesAlertCount = "travelAlertFullSpeedMessage.timesAlertCount",
  exceded = "excedida",
  normal = "normal"

  /* endregion Frontend */
}

export type TranslationTravelAlertFullSpeedMessage = {
  [key in TravelAlertFullSpeedMessage]: string;
}
