import React from "react";

// region imports - Languages
import useTranslation from "src/translations/useTranslation";
// endregion imports - Languages
// region imports - Libraries
import { Chip, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
// endregion imports - Libraries
// region imports - Styles
import * as Styled from "./styles";
// endregion imports - Styles

// region Interfaces
interface IChipProps {
  id: string;
  label: string;
}

interface IProps {
  label: string;
  chips: IChipProps[];
  onDeleteChip: (id: string) => void;
}

// endregion Interfaces

// regions Constants
const initialWidthHiddenChipsIndicator = 34;
// endregion Constants

const AccordionSummaryChips: React.FC<IProps> = ({ label, chips, onDeleteChip }) => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  // region States
  const [amountOfHiddenOptions, setAmountOfHiddenOptions] = React.useState<number>(0);
  const [openChipsTooltip, setOpenChipsTooltip] = React.useState<boolean>(false);
  const [
    widthHiddenChipsIndicator,
    setWidthHiddenChipsIndicator
  ] = React.useState<number>(initialWidthHiddenChipsIndicator);
  // endregion States

  // region Refs
  const chipsRef = React.createRef<HTMLDivElement>();
  // endregion Refs

  // region Effects
  React.useEffect(() => {
    const element = chipsRef.current;
    const parentElement = element?.parentElement;

    if (!element) return;

    if (!parentElement) throw new Error("chips must have a parent.");

    const widthOfParentElementToConsider = parentElement.clientWidth - initialWidthHiddenChipsIndicator;

    setAmountOfHiddenOptions(0);
    setWidthHiddenChipsIndicator(initialWidthHiddenChipsIndicator);

    if (element.scrollWidth <= widthOfParentElementToConsider) return;

    let totalWidth = 0;

    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i] as HTMLDivElement;
      const gapElements = 5;
      const widthOfChildToConsider = child.clientWidth + gapElements;

      totalWidth += widthOfChildToConsider;

      if (totalWidth > widthOfParentElementToConsider) {
        setWidthHiddenChipsIndicator(parentElement.clientWidth - (totalWidth - widthOfChildToConsider) + 2);

        setAmountOfHiddenOptions(element.children.length - i);

        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips]);

  // region SubComponent
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom-end" />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(170, 170, 170, 0.95)",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 1px 3px 1px rgb(0 0 0 / 25%)",
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "5px",
      padding: "15px"
    },
    ".MuiChip-colorSuccess": {
      backgroundColor: "#00569A !important"
    }
  }));
  // endregion SubComponent

  return (
    <Styled.Container>
      <span>{t(label)}</span>
      <Styled.Chips className="accordion-summary-chips" ref={chipsRef}>
        {chips.map((item) => (
          <Chip
            key={item.id}
            label={item.label}
            color="success"
            size="small"
            onDelete={() => onDeleteChip(item.id)}
          />
        ))}
      </Styled.Chips>
      {amountOfHiddenOptions > 0 && (
        <LightTooltip
          open={openChipsTooltip}
          title={chips.slice(chips.length - amountOfHiddenOptions).map((item) => (
            <Chip
              key={item.id}
              label={item.label}
              color="success"
              size="small"
              onDelete={() => onDeleteChip(item.id)}
            />
          ))}
        >
          <Styled.HiddenChipsIndicator
            style={{ width: `${widthHiddenChipsIndicator}px` }}
            className="accordion-summary-chips"
            onClick={(ev) => {
              ev.stopPropagation();
              setOpenChipsTooltip(!openChipsTooltip);
            }}
          >
            {`+${amountOfHiddenOptions}`}
          </Styled.HiddenChipsIndicator>
        </LightTooltip>
      )}
    </Styled.Container>
  );
};

export default AccordionSummaryChips;
