import styled from "styled-components";

interface ContainerModalProps {
  isCommandDetails?: boolean;
}

export const Container = styled.div`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;

  &.page {
    padding-bottom: 160px;
    padding-top: 1rem;
  }

  .titleHeader {
    padding: 10px 20px 0 20px;

    .title {
      font-weight: 200;
      width: 100%;
      font-size: 2rem;
    }
    .subtitle {
      margin-top: 5px;
      font-size: 1.2rem;
      color: rgb(0 0 0 / 60%);
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: 800px) { // Mobile
    height: auto;

    .title, .filterHeader, .filterFooter {
      text-align: center !important;
    }
  }

  .form {
    background: white;
    padding: 0 20px;

    .filterContent {
      margin: 25px 0;
    }
    .button-loading {
      margin-top: 0 !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export const ContainerModal = styled.div<ContainerModalProps>`
  z-index: 2001;

  .MuiDialog-paper {
    max-width: ${(props) => (props.isCommandDetails ? "500px" : "1200px")};
    width: 1200px;

    .mContent {

      .MuiTypography-root {
        width: 100%;
      }
      .MuiFormControl-root {
        width: 100%;

        .MuiFormHelperText-root {
          text-align: right;
          margin: 0;
        }
      }
    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;
