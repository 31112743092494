import { AlertTypesID } from "../../constants/alert-types.enum";
import { MixerDiagnosticCommandTypesDescription } from "../../constants/mixer-diagnostic-command-types.enum";

export enum AlertMessages {

  /* region Frontend messages */
  noPermissionToJustify = "alert.noPermissionToJustify",
  siteRegistration = "alert.siteRegistration",
  searchLocation = "alert.searchLocation",
  alertConfirmation = "alert.alertConfirmation",
  vehicleAtTheAlertMoment = "alert.vehicleAtTheAlertMoment",
  expirationDate = "alert.expirationDate",
  occurrenceDate = "alert.occurrenceDate",
  expiresIn = "alert.expiresIn",
  justification = "alert.justification",
  details = "alert.details",
  expiredIn = "alert.expiredIn",
  unauthorizedStop = "alert.unauthorizedStop",
  block = "alert.block",
  concreteAboutToExpire = "alert.concreteAboutToExpire",
  expiredConcrete = "alert.expiredConcrete",
  mainBatteryConnection = "alert.mainBatteryConnection",
  mainBatteryDisconnection = "alert.mainBatteryDisconnection",
  unauthorizedUnloading = "alert.unauthorizedUnloading",
  speeding = "alert.speeding",
  speedingTravel = "alert.speedingTravel",
  hardwareInactivity = "alert.hardwareInactivity",
  maintenance = "alert.maintenance",
  jammingDetected = "alert.jammingDetected",
  observation = "alert.observation",
  licensePlate = "alert.licensePlate",
  justificationDeleteConfirmation = "alert.justificationDeleteConfirmation",
  intervalOfDays = "alert.intervalOfDays",
  blockedPlataforom = "alert.blockedPlataform",
  zmrc = "alert.zmrc",

  /* region Alerts History messages */
  alert = "alert.alert",
  confirmationDate = "alert.confirmationDate",
  authorizer = "alert.authorizer",
  alertsHistoryTitle = "alert.alertsHistoryTitle",
  startDate = "alert.startDate",
  finishDate = "alert.finishDate",
  type = "alert.type",
  /* endregion */

  /* region Alert Note messages */
  noTxCommunication = "alert.noTxCommunication",
  noRxCommunication = "alert.noRxCommunication",
  lowBattery = "alert.lowBattery",
  possibleViolation = "alert.possibleViolation",
  unknown = "alert.unknown",
  /* endregion */
  /* endregion Frontend messages */

  /* region Backend messages */
  readAlertTypesSuccess = "alert.readAlertTypesSuccess",
  readAlertSuccess = "alert.readAlertSuccess",
  confirmAlertSuccess = "alert.confirmAlertSuccess",
  createAlertSuccess = "alert.createAlertSuccess",
  noAlertType = "alert.noAlertType",
  readAlertTypesError = "alert.readAlertTypesError",
  noAlertFound = "alert.noAlertFound",
  readAlertError = "alert.readAlertError",
  alertNotRegistered = "alert.alertNotRegistered",
  noVehicleLinkedToHardware = "alert.noVehicleLinkedToHardware",
  alertNotMapped = "alert.alertNotMapped",
  checkVehicleTrackerAlertError = "alert.checkVehicleTrackerAlertError",
  createAlertJustificationSuccess = "alert.createAlertJustificationSuccess",
  createAlertJustificationError = "alert.createAlertJustificationError",
  readAlertJustificationSuccess = "alert.readAlertJustificationSuccess",
  readAlertJustificationError = "alert.readAlertJustificationError",
  deleteAlertJustificationSuccess = "alert.deleteAlertJustificationSuccess",
  deleteAlertJustificationError = "alert.deleteAlertJustificationError",
  alertjustificationNotRegistered = "alert.alertjustificationNotRegistered",
  noAlertJustificationFound = "alert.noAlertJustificationFound",

  /* region Integrations messages */
  invalidDatePeriod = "alert.invalidDatePeriod"
  /* endregion */
  /* endregion Backend messages */
}

export type TranslationAlertMessages = {
  [key in AlertMessages]: string;
}

export const AlertTypeMessages = {
  [AlertTypesID.PARADA_INDEVIDA]: AlertMessages.unauthorizedStop,
  [AlertTypesID.CONCRETO_VENCIDO]: AlertMessages.expiredConcrete,
  [AlertTypesID.BLOQUEIO]: AlertMessages.block,
  [AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO]: AlertMessages.concreteAboutToExpire,
  [AlertTypesID.CONEXAO_DE_BATERIA_PRINCIPAL]: AlertMessages.mainBatteryConnection,
  [AlertTypesID.DESCARREGAMENTO_INDEVIDO]: AlertMessages.unauthorizedUnloading,
  [AlertTypesID.DESCONEXAO_DE_BATERIA_PRINCIPAL]: AlertMessages.mainBatteryDisconnection,
  [AlertTypesID.EXCESSO_DE_VELOCIDADE]: AlertMessages.speeding,
  [AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM]: AlertMessages.speedingTravel,
  [AlertTypesID.INATIVIDADE_DE_HARDWARE]: AlertMessages.hardwareInactivity,
  [AlertTypesID.MANUTENCAO]: AlertMessages.maintenance,
  [AlertTypesID.JAMMING_DETECTADO]: AlertMessages.jammingDetected,
  [AlertTypesID.ZMRC]: AlertMessages.zmrc
};

export const AlertNoteMessages = {
  [MixerDiagnosticCommandTypesDescription.NO_TX_COMMUNICATION]: AlertMessages.noTxCommunication,
  [MixerDiagnosticCommandTypesDescription.NO_RX_COMMUNICATION]: AlertMessages.noRxCommunication,
  [MixerDiagnosticCommandTypesDescription.LOW_BATTERY]: AlertMessages.lowBattery,
  [MixerDiagnosticCommandTypesDescription.POSSIBLE_VIOLATION]: AlertMessages.possibleViolation,
  [MixerDiagnosticCommandTypesDescription.UNKNOWN]: AlertMessages.unknown
};
