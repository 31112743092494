import React from "react";

// region Assets
import AlertaLimiteVelocidade from "@assets/icons/alerts/alerta-limite-velocidade.svg";
// endregion Assets
// region Libraries
import { useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Interfaces
// region Styles
import { TravelSpeedHeaderStylesheet as Stylesheet } from "@components/Modal/SpeedTravelAlert/TravelSpeedHeader/styles";
import { ReactSVG } from "react-svg";
import { TravelAlertFullSpeedMessage } from "@shared/languages/interfaces/travelAlertFullSpeedMessage";
// endregion Styles
interface TravelSpeedHeaderProps {
  hasTravel: boolean;
}
const TravelSpeedHeader:React.FC<TravelSpeedHeaderProps> = ({
  hasTravel
}) => {
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control

  return (
    <>
      <Stylesheet isMobile={isMobile}>
        <div className="travel-speed-header">
          <div className="travel-speed-header__title">
            <div
              className="iconContainer"
            >
              <ReactSVG className="icon" src={AlertaLimiteVelocidade} />
            </div>
            <div className="content">
              <div className="content__title">
                { !hasTravel && (
                  <p>{t(TravelAlertFullSpeedMessage.youAreAboutFullSpeedTravel)}
                    <strong className="strong" style={{ color: "red" }}> {t(TravelAlertFullSpeedMessage.alertOfFullSpeed)} </strong>
                    {t(TravelAlertFullSpeedMessage.alertToVehicle)}
                  </p>
                )}
                { hasTravel && (
                  <p>{t(TravelAlertFullSpeedMessage.alreadyFullSpeed)}
                    <strong className="strong" style={{ color: "red" }}> {t(TravelAlertFullSpeedMessage.alertOfFullSpeed)} </strong>
                    {t(TravelAlertFullSpeedMessage.toThisVehicle)}
                  </p>
                )}
              </div>
              <div className="content__subtitle">
                {t(TravelAlertFullSpeedMessage.subtitleOnlyOneAlert)}
              </div>
            </div>
          </div>
        </div>
      </Stylesheet>
    </>

  );
};

export default TravelSpeedHeader;
