import styled from "styled-components";
import { Dialog, DialogActions as DialogActionsMaterialUI } from "@material-ui/core";

const ContainerDialog = styled(Dialog)`

  .MuiDialog-paper {
    width: 600px;

    .MuiTypography-root {
      display: flex;
      align-items: center;

      font-weight: 200;
    }
  }
`;

const DialogActions = styled(DialogActionsMaterialUI)`
  justify-content: space-between;
`;

export { ContainerDialog, DialogActions };
