import React from "react";

/* region Libraries */
import { Tab, Tabs } from "@material-ui/core";
/* endregion */
/* region Languages */
import useTranslation from "src/translations/useTranslation";
import { TravelHistoryMessages } from "@shared/languages/interfaces";
/* endregion */
/* region Components */
import TravelsHistory from "@pages/Reports/TravelsHistory/History";
import CoordinateHistory from "@pages/Reports/TravelsHistory/CoordinateHistory";
import IntegrationReport from "@pages/Reports/TravelsHistory/IntegrationReport";
import Header from "@components/Header";
import TabPanel from "@components/TabPanel";
import { IDateRangeValues } from "@organisms/GenericQueryFilter";
/* endregion */
/* region Shared */
import { Travel } from "@shared/interfaces/travel.interface";
import { useAuth } from "@hooks/useAuth";
/* endregion */
/* region Hooks */
import { useSelector } from "react-redux";
import usePersistedState from "@hooks/usePersistedState";
/* endregion */
/* region Store */
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
/* endregion */
/* region Styles */
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { Container } from "./styles";
/* endregion */

const TravelHistoryMain: React.FC = () => {

  /* region States */
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [travel, setTravel] = React.useState({} as Travel);
  const [dateRange, setDateRange] = React.useState<IDateRangeValues | undefined>(undefined);
  /* endregion */
  /* region Hooks */
  const { user } = useAuth();
  const { screen } = useSelector((screen) => screen);
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  /* endregion */
  /* region Methods */
  const handleChangeTab = (event, newValue) => {

    if (newValue === 0) {

      setTravel({} as Travel);
    }

    setSelectedTab(newValue);
  };
  const handleOpenTravelCoordinates = (travel: Travel, dateRange: IDateRangeValues) => {

    setDateRange(dateRange);
    setTravel(travel);
    setSelectedTab(1);
  };
  /* endregion */

  return (
    <>
      <Header title={t(TravelHistoryMessages.travelHistoryTitle)} />
      <Container platform={screen.platform as ScreenPlatform} selectedTab={selectedTab} className="page">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          className="tabContainer"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.colors.primary
            }
          }}
        >
          <Tab label={t(TravelHistoryMessages.travels)} disableRipple />
          <Tab label={t(TravelHistoryMessages.coordinatesTitle)} disableRipple />
          { user.super_admin && (
          <Tab label={t(TravelHistoryMessages.integrationReport)} disableRipple />
          )}
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <TravelsHistory onOpenTravelCoordinates={handleOpenTravelCoordinates} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <CoordinateHistory travel={travel} defaultDateRange={dateRange} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <IntegrationReport />
        </TabPanel>
      </Container>
    </>
  );
};

export default TravelHistoryMain;
