/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";
import { Filter } from "./reg_filters.entity";

@Entity("tpe_filter_application_types")
export class TpeFilterApplication {

  @PrimaryGeneratedColumn("uuid")
    id_filter_application_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
    description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Filter, (filter) => filter.application_type)
    filters?: Filter[];

}
