import { VehiclesStatesMessages, TranslationVehiclesStatesMessages } from "../interfaces";

export const messages: TranslationVehiclesStatesMessages = {
  [VehiclesStatesMessages.atThePlant]: "At the plant",
  [VehiclesStatesMessages.onTheWay]: "On the way",
  [VehiclesStatesMessages.atTheConstructionSite]: "At construction site",
  [VehiclesStatesMessages.unloading]: "Unloading",
  [VehiclesStatesMessages.returning]: "Returning",
  [VehiclesStatesMessages.atDestination]: "At destination",
  [VehiclesStatesMessages.inTrustedLocation]: "In trusted location",
  [VehiclesStatesMessages.pumping]: "Pumping",
  [VehiclesStatesMessages.idle]: "Idle",

  [VehiclesStatesMessages.columnStartDate]: "Start",
  [VehiclesStatesMessages.columnEndDate]: "End",
  [VehiclesStatesMessages.columnDuration]: "Duration",
  [VehiclesStatesMessages.columnGroup]: "Group",
  [VehiclesStatesMessages.columnRegional]: "Regional",
  [VehiclesStatesMessages.columnPlant]: "Plant",
  [VehiclesStatesMessages.columnVehicleType]: "Vehicle type",
  [VehiclesStatesMessages.columnVehicle]: "Vehicle",
  [VehiclesStatesMessages.columnVehicleLicensePlate]: "License plate",
  [VehiclesStatesMessages.columnVehicleStateType]: "State",
  [VehiclesStatesMessages.columnLocation]: "Location",
  [VehiclesStatesMessages.columnLocationType]: "Location type",
  [VehiclesStatesMessages.columnCurrentLatitude]: "Current latitude",
  [VehiclesStatesMessages.columnCurrentLongitude]: "Current longitude",
  [VehiclesStatesMessages.columnCurrentAddress]: "Current address",

  [VehiclesStatesMessages.readVehicleStateHistorySuccess]: "Vehicle state history read successfully",
  [VehiclesStatesMessages.noVehicleStateHistoryFound]: "No vehicle state history found",
  [VehiclesStatesMessages.readVehicleStateHistoryError]: "Error reading vehicle state history"
};
