export enum HelpMessages {
  // Titles
  titleColors = "help.title.colors",
  titleIcons = "help.title.icons",

  // Subtitles
  subtitleOperationalStates = "help.subtitle.operationalStates",
  subtitleSituationalStates = "help.subtitle.situationalStates",
  subtitleAlerts = "help.subtitle.alerts",

  // Descriptions
  descriptionOperationalStatesGoal = "help.description.operationalStatesGoal",
  descriptionOperationalStatesUtilization = "help.description.operationalStatesUtilization",
  descriptionSituationalStatesGoal = "help.description.situationalStatesGoal",
  descriptionSituationalStatesUtilization = "help.description.situationalStatesUtilization",

  // Items
  blockedOrImproperDischarge = "help.item.blockedOrImproperDischarge",
  active = "help.item.noAlerts",
  hasAlerts = "help.item.hasAlerts",
  noCommunication = "help.item.noCommunication",
  selectFile = "help.item.selectFile",
  noFileSelected = "help.item.noFileSelected"
}

export type TranslationHelpMessages = {
  [key in HelpMessages]: string;
}
