export enum ServiceOrderMessages {
  /* region Frontend messages */
  newServiceOrder = "serviceOrder.newServiceOrder",
  expectedDate = "serviceOrder.expectedDate",
  serviceOrderType = "serviceOrder.serviceOrderType",
  delivery = "serviceOrder.delivery",
  pickup = "serviceOrder.pickup",
  product = "serviceOrder.product",
  binder = "serviceOrder.binder",
  smallAggregate = "serviceOrder.smallAggregate",
  coarseAggregate = "serviceOrder.coarseAggregate",
  unit = "serviceOrder.unit",
  transportDocument = "serviceOrder.transportDocument",
  productDocument = "serviceOrder.productDocument",
  originLocation = "serviceOrder.originLocation",
  loadingLocation = "serviceOrder.loadingLocation",
  unloadingLocation = "serviceOrder.unloadingLocation",
  quantity = "serviceOrder.quantity",
  /* endregion Frontend messages */

  /* region Backend messages */
  createServiceOrderSuccess = "serviceOrder.createServiceOrderSuccess",
  createServiceOrderError = "serviceOrder.createServiceOrderError"
  /* endregion Backend messages */
}

export type TranslationServiceOrderMessages = {
  [key in ServiceOrderMessages]: string
}
