export enum PumpProgrammingImportsModuleMessages {
  title = "pumpProgrammingImport.title",

  newRegister = "pumpProgrammingImport.newRegister",

  importingResult = "pumpProgrammingImport.importingResult",

  columnDescription = "pumpProgrammingImport.column.description",
  columnStatus = "pumpProgrammingImport.column.status",
  columnRegistrationDate = "pumpProgrammingImport.column.registrationDate",
  columnProcessingDate = "pumpProgrammingImport.column.processingDate",

  // PumpProgrammingImport backend messages
  readSuccess = "pumpProgrammingImport.readSuccess",
  readError = "pumpProgrammingImport.readError",
  createSuccess = "pumpProgrammingImport.createSuccess",
  createError = "pumpProgrammingImport.createError",
  cancelSuccess = "pumpProgrammingImport.cancelSuccess",
  cancelError = "pumpProgrammingImport.cancelError",
  pumpProgrammingImportNotFound = "pumpProgrammingImport.pumpProgrammingImportNotFound",
  noPumpProgrammingImports = "pumpProgrammingImport.noPumpProgrammingImports",
  notFound = "pumpProgrammingImport.notFound",
  insertedRecords = "pumpProgrammingImport.insertedRecords",
  updatedRecords = "pumpProgrammingImport.updatedRecords"
}

export type TranslationPumpProgrammingImportsModuleMessages = {
  [key in PumpProgrammingImportsModuleMessages]: string;
}
