import styled from "styled-components";

import backgroundWeb from "../../../assets/Login/background-web.svg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: url(${backgroundWeb}) no-repeat center;
  background-size: cover;

  button {
    margin-top: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  width: 380px;
  max-width: 94vw;
  height: 500px;
  max-height: 94vh;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & a {
      margin-top: 25px;
    }
  }
`;
