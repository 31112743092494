import React from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

import BTIcon from "@assets/icons/betoneira.svg";
import BTTrack from "@assets/icons/timeline/bt-track.svg";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import Styled from "./styles";

type SubVehicleOfPumpProps = {
  vehicle: Vehicle;
}

const SubVehicleOfPump: React.FC<SubVehicleOfPumpProps> = ({ vehicle }) => {

  const screen = useSelector(({ screen: state }) => state);

  return (
    <Styled.VehicleContainer platform={screen.platform}>
      <div className="infoContainer">
        <div className="iconContainer">
          <ReactSVG src={BTIcon} />
        </div>
        <span>{vehicle?.code}</span>
        <p> · {vehicle?.license_plate}</p>
      </div>
      <div>
        <ReactSVG src={BTTrack} />
      </div>
    </Styled.VehicleContainer>
  );
};

export default SubVehicleOfPump;
