import { Column, CreateDateColumn, Entity, OneToOne, PrimaryGeneratedColumn, Unique, UpdateDateColumn } from "typeorm";
import { VehicleUsageType } from "../types/vehicle_usage_type.enum";
import { ServiceOrderStatusType } from "../types/service_order_status_type.enum";
import { UnitType } from "../types/unit_type.enum";
import { ProductType } from "../types/product_type";
import { Travel } from "./reg_travels.entity";

@Entity("reg_service_orders")
@Unique(["number"])

export class ServiceOrder {

  @PrimaryGeneratedColumn("uuid")
    id_service_order?: string;

  @Column({ type: "int", nullable: false })
    number?: number;

  @Column({ type: "varchar", nullable: true })
    external_id?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @Column({ type: "timestamp", nullable: false })
    expected_start_date?: Date;

  @Column({ type: "enum", enum: VehicleUsageType, nullable: true })
    type?: VehicleUsageType;

  @Column({ type: "enum", enum: ServiceOrderStatusType, nullable: true })
    status?: ServiceOrderStatusType;

  @Column({ type: "varchar", nullable: true })
    transport_document?: string;

  @Column({ type: "varchar", nullable: true })
    product_document?: string;

  @Column({ type: "enum", enum: ProductType, nullable: true })
    product?: ProductType;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    quantity?: number;

  @Column({ type: "enum", enum: UnitType, nullable: true })
    unit?: UnitType;

  @Column({ type: "varchar", nullable: true })
    observation?: string;

  @OneToOne(() => Travel, (travel) => travel.service_order, { nullable: false })
    travel?: Travel;

}
