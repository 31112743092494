import React, { useEffect, useMemo, useRef, useState } from "react";

// region Imports - External libraries
import _ from "lodash";
import * as dateFns from "date-fns";
import Loading from "react-fullscreen-loading";
// endregion Imports - External libraries
// region Imports - Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { FilterApplicationTypesID } from "@shared/constants/filter-application-types.enum";
import { Regional } from "@shared/entities/reg_regionals.entity";
import { Group } from "@shared/entities/reg_groups.entity";
import { Plant } from "@shared/entities/reg_plants.entity";
import { VehicleStateHistory } from "@shared/interfaces/vehicle-state-history.interface";
import { VehicleType } from "@shared/interfaces/vehicle-type.interface";
import { ParamNameTypes } from "@shared/constants/param-name-types.enum";
import { InternalCodeErrors } from "@shared/constants/internal-code-errors.enum";
import { TpeVehicleState } from "@shared/entities/tpe_vehicles_states_types.entity";
// endregion Imports - Shared
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { FilterMessages, ToastMessages, VehiclesStatesTypesMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Organisms
import GenericQueryFilter, {
  IDateRangeOptions,
  IMultipleSelectionOptions,
  IOnFilterReturn
} from "@organisms/GenericQueryFilter";
// endregion Imports - Organisms

// region Interfaces
interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (
    numberOfFilteredOptions: number,
    returnedData: VehicleStateHistory[],
  ) => void;
}
// endregion Interfaces

const QueryFilterVehicleStatesHistory: React.FC<IProps> = ({ open, onClose, onFilter }) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();
  // endregion Hooks
  // region useState
  const [optionsVehicleTypeFilter, setOptionsVehicleTypeFilter] = useState<VehicleType[]>([] as Array<VehicleType>);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [optionsPlantFilter, setOptionsPlantFilter] = useState<Plant[]>([] as Array<Plant>);
  const [optionsRegionalFilter, setOptionsRegionalFilter] = useState<Regional[]>([] as Array<Regional>);
  const [optionsGroupFilter, setOptionsGroupFilter] = useState<Group[]>([] as Array<Group>);
  const [optionsVehicleStateTypeFilter, setOptionsVehicleStateTypeFilter] = useState([] as Array<TpeVehicleState>);

  const [loadingVehicleTypeFilter, setLoadingVehicleTypeFilter] = useState(true);
  const [loadingVehicleFilter, setLoadingVehicleFilter] = useState(true);
  const [loadingPlantFilter, setLoadingPlantFilter] = useState(true);
  const [loadingRegionalFilter, setLoadingRegionalFilter] = useState(true);
  const [loadingGroupFilter, setLoadingGroupFilter] = useState(true);
  const [loadingVehicleStateTypeFilter, setLoadingVehicleStateTypeFilter] = useState(false);

  const [loadingVehicleStatesHistory, setLoadingVehicleStatesHistory] = useState(false);
  // endregion useState
  // region Constants
  const paramNameStartDate = ParamNameTypes.START_DATE;
  const paramNameEndDate = ParamNameTypes.END_DATE;
  const paramNameVehicleType = ParamNameTypes.ID_VEHICLE_TYPES;
  const paramNameVehicle = ParamNameTypes.ID_VEHICLES;
  const paramNamePlant = ParamNameTypes.ID_PLANTS;
  const paramNameGroups = ParamNameTypes.ID_GROUPS;
  const paramNameRegional = ParamNameTypes.ID_REGIONALS;
  const paramNameVehicleStateType = ParamNameTypes.ID_VEHICLE_STATE_TYPES;

  const vehicleTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleTypeFilter,
    labelName: t(FilterMessages.optionVehicleTypes),
    paramName: paramNameVehicleType,
    values: optionsVehicleTypeFilter.map((vehicleType) => ({ value: vehicleType.id_vehicle_type!, label: vehicleType.description }))
  }), [paramNameVehicleType, loadingVehicleTypeFilter, optionsVehicleTypeFilter, t]);

  const plantFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingPlantFilter,
    labelName: t(FilterMessages.optionPlants),
    paramName: paramNamePlant,
    values: optionsPlantFilter.map(
      (plant) => ({
        value: plant!.location!.id_location!,
        label: plant.name!
      })
    )
  }), [paramNamePlant, loadingPlantFilter, optionsPlantFilter, t]);

  const regionalFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingRegionalFilter,
    labelName: t(FilterMessages.optionRegionals),
    paramName: paramNameRegional,
    values: optionsRegionalFilter.map(
      (regional) => ({
        value: regional.id_regional!,
        label: `${regional.code} - ${regional.name}`
      })
    )
  }), [paramNameRegional, loadingRegionalFilter, optionsRegionalFilter, t]);

  const groupFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingGroupFilter,
    labelName: t(FilterMessages.optionGroups),
    paramName: paramNameGroups,
    values: optionsGroupFilter.map(
      (group) => ({
        value: group.id_group!,
        label: `${group.code} - ${group.name}`
      })
    )
  }), [paramNameGroups, loadingGroupFilter, optionsGroupFilter, t]);

  const vehicleFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleFilter,
    labelName: t(FilterMessages.optionVehicles),
    paramName: paramNameVehicle,
    // eslint-disable-next-line max-len
    values: optionsVehicleFilter.map((vehicle) => ({ value: vehicle.id_vehicle, label: `${vehicle.code} - ${vehicle.description}` }))
  }), [paramNameVehicle, loadingVehicleFilter, optionsVehicleFilter, t]);

  const vehicleStateTypeFilterOptions: IMultipleSelectionOptions = useMemo(() => ({
    isLoadingValues: loadingVehicleStateTypeFilter,
    labelName: t(FilterMessages.optionVehicleStateTypes),
    paramName: paramNameVehicleStateType,
    values: optionsVehicleStateTypeFilter.map(
      (vehicleStateType) => ({
        value: vehicleStateType.id_vehicle_state_type!,
        label: t(VehiclesStatesTypesMessages[vehicleStateType.id_vehicle_state_type!])
      })
    )
  }), [paramNameVehicleStateType, loadingVehicleStateTypeFilter, optionsVehicleStateTypeFilter, t]);

  const defaultStartDate = dateFns.subDays(new Date(), 1);

  defaultStartDate.setHours(0, 0, 0, 0);

  const defaultEndDate = new Date();

  defaultEndDate.setHours(23, 59, 59, 999);

  const dateRangeOptions: IDateRangeOptions = {
    labelName: t(FilterMessages.dateRangeLabel),
    paramNameStartDate,
    paramNameEndDate,
    defaultValues: {
      startDate: utils.convertDateToISOWithTimezone(defaultStartDate),
      endDate: utils.convertDateToISOWithTimezone(defaultEndDate)
    },
    maxRange: "30D"
  };

  const initialParamsFilter: IParamQueryValues[] = [
    { paramName: paramNameStartDate, paramValue: utils.convertDateToISOWithTimezone(defaultStartDate) },
    { paramName: paramNameEndDate, paramValue: utils.convertDateToISOWithTimezone(defaultEndDate) }
  ];
  // endregion Constants
  // region Refs
  const paramsFilterRef = useRef<IParamQueryValues[]>(initialParamsFilter);
  const hasMounted = useRef(false);
  // endregion Refs
  // region Functions

  /**
   * Load vehicle options from the API.
   */
  const loadVehicleOptions = async () => {

    try {

      // Get all vehicles
      const { data } = await api.get(
        "vehicles/read",
        { params: { linkedHardware: true, onlyLabel: true } }
      );

      if (data.status === "success") setOptionsVehicleFilter(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoadingVehicleFilter(false);
    }
  };

  /**
   * Load plant options from the API.
   */
  const loadPlantOptions = async () => {
    try {

      // Get all plants
      const { data } = await api.get("plants/read", { params: { linkedLocation: true } });

      if (data.status === "success") setOptionsPlantFilter(data.result);
      else setOptionsPlantFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingPlantFilter(false);
    }
  };

  /**
   * Load regional options from the API.
   */
  const loadRegionalOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("regionals/read");

      if (data.status === "success") setOptionsRegionalFilter(data.result as Regional[]);
      else setOptionsRegionalFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingRegionalFilter(false);
    }
  };

  /**
   * Load group options from the API.
   */
  const loadGroupOptions = async () => {
    try {

      // Get all regionals
      const { data } = await api.get("groups/read");

      if (data.status === "success") setOptionsGroupFilter(data.result as Group[]);
      else setOptionsGroupFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingGroupFilter(false);
    }
  };

  /**
   * Load vehicle type options from the API.
   */
  const loadVehicleTypeOptions = async () => {
    try {

      // Get all alert types
      const { data } = await api.get("vehicles/types/read", { params: { translate: true } });

      if (data.status === "success") {
        setOptionsVehicleTypeFilter(data.result);

        return;
      }

      setOptionsVehicleTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleTypeFilter(false);
    }
  };

  /**
   * Load vehicle state type options from the API.
   */
  const loadVehicleStateTypeOptions = async () => {
    try {

      // Get all vehicle state types
      const { data } = await api.get("vehicles/get-all-state-types");

      if (data.status === "success") setOptionsVehicleStateTypeFilter(data.result);
      else setOptionsVehicleStateTypeFilter([]);

    } catch (error: any) {
      utils.handleStandardError(error, t, addToast);
    } finally {
      setLoadingVehicleStateTypeFilter(false);
    }
  };

  const handleFilter = async (paramsData: IParamQueryValues[]) => {

    setLoadingVehicleStatesHistory(true);

    paramsFilterRef.current = paramsData;

    try {

      const mappedParams = paramsData.reduce((acc, param) => {
        acc[param.paramName] = param.paramValue;

        return acc;
      }, {} as { [key: string]: string | string[] });

      const { data } = await api.post("vehicles/get-state-history", {
        ...mappedParams,
        [ParamNameTypes.UTC_OFFSET]: utils.getUtcOffset()
      });

      const validParamsData = paramsData.filter((item) => !_.isEmpty(item.paramValue));

      const validParamsDataWithoutDate = validParamsData.filter(
        (item) => item.paramName !== paramNameStartDate && item.paramName !== paramNameEndDate
      );

      if (data.status === "alert" && data.errorCode === InternalCodeErrors.VERY_LARGE_FILTER) {
        const additionalFilterOptions : IParamQueryValues[] = [
          { paramName: ParamNameTypes.UTC_OFFSET, paramValue: (utils.getUtcOffset()).toString() }
        ];

        return { status: "scheduledReport", additionalFilterOptions } as IOnFilterReturn;
      }

      if (data.status !== "success") addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      onFilter(
        validParamsDataWithoutDate.length,
        data.result ?? [] as VehicleStateHistory[]
      );

      return { status: "success" } as IOnFilterReturn;
    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      return { status: "error" } as IOnFilterReturn;
    } finally {
      setLoadingVehicleStatesHistory(false);
    }
  };
  // endregion Functions
  // region Effects

  // Load options from the API
  useEffect(() => {

    Promise.all([
      loadVehicleOptions(),
      loadPlantOptions(),
      loadRegionalOptions(),
      loadGroupOptions(),
      loadVehicleTypeOptions(),
      loadVehicleStateTypeOptions()
    ]).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMounted.current) handleFilter(paramsFilterRef.current).then();
    else hasMounted.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  // endregion Effects

  return (
    <>
      <Loading loading={loadingVehicleStatesHistory} />
      <GenericQueryFilter
        open={open}
        onClose={onClose}
        applicationTypeID={FilterApplicationTypesID.VEHICLE_STATE_HISTORY}
        onFilter={(paramsData) => handleFilter(paramsData)}
        multipleSelectionOptions={[
          vehicleStateTypeFilterOptions,
          vehicleFilterOptions,
          vehicleTypeFilterOptions,
          plantFilterOptions,
          regionalFilterOptions,
          groupFilterOptions
        ]}
        dateRangeOptions={dateRangeOptions}
      />
    </>
  );
};

export default QueryFilterVehicleStatesHistory;
