import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { User } from "./reg_users.entity";

import { ClaimType } from "../types/claim_type.enum";
import { ClaimValue } from "../types/claim_value.enum";

@Entity("reg_user_claims")
export class UserClaim {
  
  @PrimaryGeneratedColumn("uuid")
  id_user_claim?: string;

  @Column({ 
    type: "enum", 
    enum: ClaimType,
    nullable: true 
  })
  claim_type?: string;

  @Column({ 
    type: "enum", 
    enum: ClaimValue,
    nullable: true 
  })
  claim_value?: string;

  @Column({ type: "boolean", nullable: false, default: true })
  active?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToOne(() => User, (user) => user.claims, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
  user?: User;
}