/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, JoinTable, ManyToMany,
  ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { TpeResistance } from "./tpe_resistances_types.entity";
import { TpeGravel } from "./tpe_gravels_types.entity";
import { Travel } from "./reg_travels.entity";

@Entity("reg_traces")
export class Trace {

  @PrimaryGeneratedColumn("uuid")
  id_trace?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @Column({ type: "varchar", nullable: false })
  application?: string;

  @Column({ type: "numeric", nullable: true })
  slump?: number;

  @Column({ type: "varchar", nullable: false })
  resistance?: string;

  @Column({ type: "varchar", nullable: true })
  resistance_complement?: string;

  @ManyToOne(() => TpeResistance, (resistance_type) => resistance_type.traces, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_resistance_type_fk" })
  resistance_type?: TpeResistance;

  @ManyToOne(() => TpeGravel, (gravels) => gravels.traces, { nullable: true, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_gravel_type_fk" })
  gravel?: TpeGravel;

  @OneToMany(() => Travel, (travels) => travels.concrete_trace)
  travels?: Travel[];

}
