import {
  PumpProgrammingImportsModuleMessages,
  TranslationPumpProgrammingImportsModuleMessages
} from "../interfaces/pumpProgrammingImportsModuleMessages";

export const messages: TranslationPumpProgrammingImportsModuleMessages = {
  [PumpProgrammingImportsModuleMessages.title]: "Pump programming imports",

  [PumpProgrammingImportsModuleMessages.newRegister]: "New pump programming import",

  [PumpProgrammingImportsModuleMessages.importingResult]: "Importing result",

  [PumpProgrammingImportsModuleMessages.columnDescription]: "Description",
  [PumpProgrammingImportsModuleMessages.columnStatus]: "Status",
  [PumpProgrammingImportsModuleMessages.columnRegistrationDate]: "Registration date",
  [PumpProgrammingImportsModuleMessages.columnProcessingDate]: "Processing date",

  [PumpProgrammingImportsModuleMessages.readSuccess]: "Pump programming imports returned successfully",
  [PumpProgrammingImportsModuleMessages.readError]: "Error reading pump programming imports",
  [PumpProgrammingImportsModuleMessages.createSuccess]: "Pump programming import created successfully",
  [PumpProgrammingImportsModuleMessages.createError]: "Error creating pump programming import",
  [PumpProgrammingImportsModuleMessages.cancelSuccess]: "Pump programming import cancellation performed successfully",
  [PumpProgrammingImportsModuleMessages.cancelError]: "Error canceling pump programming import",
  [PumpProgrammingImportsModuleMessages.pumpProgrammingImportNotFound]: "Pump programming import not found",
  [PumpProgrammingImportsModuleMessages.noPumpProgrammingImports]: "No pump programming imports found",
  [PumpProgrammingImportsModuleMessages.notFound]: "not found in the system",
  [PumpProgrammingImportsModuleMessages.insertedRecords]: "Inserted records",
  [PumpProgrammingImportsModuleMessages.updatedRecords]: "Updated records"
};
