import { PumpProgrammingMessages, TranslationPumpProgrammingMessages } from "../interfaces";

export const messages: TranslationPumpProgrammingMessages = {
  [PumpProgrammingMessages.createPumpProgrammingSuccess]: "Pump programming created successfully",
  [PumpProgrammingMessages.createPumpProgrammingError]: "Error creating pump programming",
  [PumpProgrammingMessages.readPumpProgrammingSuccess]: "Pump programming read successfully",
  [PumpProgrammingMessages.noPumpProgrammingFound]: "No pump programming found",
  [PumpProgrammingMessages.readPumpProgrammingError]: "Error reading pump programming",
  [PumpProgrammingMessages.updatePumpProgrammingSuccess]: "Pump programming updated successfully",
  [PumpProgrammingMessages.updatePumpProgrammingError]: "Error updating pump programming",
  [PumpProgrammingMessages.cancelPumpProgrammingSuccess]: "Pump programming canceled successfully",
  [PumpProgrammingMessages.cancelPumpProgrammingError]: "Error canceling pump programming",
  [PumpProgrammingMessages.noChangePumpProgrammingInProgress]: "Cannot change pump programming that is already in progress",
  [PumpProgrammingMessages.readPumpProgrammingHistorySuccess]: "Pump programming history read successfully",
  [PumpProgrammingMessages.readPumpProgrammingHistoryError]: "Error reading pump programming history",
  [PumpProgrammingMessages.noPumpProgrammingHistoryFound]: "No pump programming history found",

  [PumpProgrammingMessages.pumpProgrammingTitle]: "Pump programming",
  [PumpProgrammingMessages.dataTableTitle]: "pump programmings",

  [PumpProgrammingMessages.tabProgrammings]: "Programmings",
  [PumpProgrammingMessages.tabImports]: "Imports",
  [PumpProgrammingMessages.tabHistory]: "History",

  [PumpProgrammingMessages.columnDescription]: "Description",
  [PumpProgrammingMessages.columnClient]: "Client",
  [PumpProgrammingMessages.columnIntegrationDate]: "Integration date",
  [PumpProgrammingMessages.columnDocumentDate]: "Document date",
  [PumpProgrammingMessages.columnDoc]: "Doc.",
  [PumpProgrammingMessages.columnExternalId]: "External id",
  [PumpProgrammingMessages.columnOrigin]: "Origin",
  [PumpProgrammingMessages.columnVehicle]: "Vehicle",
  [PumpProgrammingMessages.columnDriver]: "Driver",
  [PumpProgrammingMessages.columnMaxSpeed]: "Max speed allowed (km/h)",
  [PumpProgrammingMessages.columnUser]: "User",
  [PumpProgrammingMessages.columnOccurrenceDate]: "Occurrence date",
  [PumpProgrammingMessages.columnChangeType]: "Change type",
  [PumpProgrammingMessages.columnImportRef]: "Import reference",

  [PumpProgrammingMessages.cancelConfirmationTitle]: "Cancel pump programming",
  [PumpProgrammingMessages.cancelConfirmationText]: "Are you sure you want to cancel this pump programming?",

  [PumpProgrammingMessages.newPumpProgramming]: "New pump programming",

  [PumpProgrammingMessages.changeTypeCreate]: "Creation",
  [PumpProgrammingMessages.changeTypeUpdate]: "Update",
  [PumpProgrammingMessages.changeTypeCancel]: "Cancellation",
  [PumpProgrammingMessages.changeTypeDelete]: "Deletion"
};
