import { TravelHistoryMessages, TranslationTravelHistoryMessages } from "../interfaces";

export const messages: TranslationTravelHistoryMessages = {
  [TravelHistoryMessages.time]: "tiempo",
  [TravelHistoryMessages.distance]: "Distancia",
  [TravelHistoryMessages.durationInMinutes]: "Duración (en minutos)",
  [TravelHistoryMessages.distanceInKilometers]: "Distancia (en kilómetros)",
  [TravelHistoryMessages.document]: "Doc.",
  [TravelHistoryMessages.origin]: "Origen",
  [TravelHistoryMessages.destination]: "Destino",
  [TravelHistoryMessages.finishPoint]: "Punto final",
  [TravelHistoryMessages.travelHistoryTitle]: "Historial de viajes",
  [TravelHistoryMessages.travels]: "Viajes",
  [TravelHistoryMessages.coordinatesTitle]: "Coordenadas",
  [TravelHistoryMessages.on]: "Encendido",
  [TravelHistoryMessages.off]: "Apagado",
  [TravelHistoryMessages.occurrenceDate]: "Fecha de ocurrencia",
  [TravelHistoryMessages.status]: "Status",
  [TravelHistoryMessages.ignition]: "Motor",
  [TravelHistoryMessages.speed]: "Velocidad",
  [TravelHistoryMessages.latitude]: "Latitud",
  [TravelHistoryMessages.longitude]: "Longitud",
  [TravelHistoryMessages.address]: "Dirección",
  [TravelHistoryMessages.average]: "Promedio",
  [TravelHistoryMessages.movingAverage]: "Promedio móvil",
  [TravelHistoryMessages.records]: "registros",
  [TravelHistoryMessages.averageSpeed]: "Velocidad media",
  [TravelHistoryMessages.registrationDate]: "Fecha de registro",
  [TravelHistoryMessages.intervalBetweenLastCommand]: "Intervalo",
  [TravelHistoryMessages.analyticReport]: "Informe analítico",
  [TravelHistoryMessages.syntheticReport]: "Informe sintético",
  [TravelHistoryMessages.vehiclePlate]: "Placa",
  [TravelHistoryMessages.vehicleType]: "Tipo de vehículo",
  [TravelHistoryMessages.plant]: "Planta",
  [TravelHistoryMessages.regional]: "Regional",
  [TravelHistoryMessages.group]: "Grupo",
  [TravelHistoryMessages.addressNotFound]: "Dirección no encontrada",
  [TravelHistoryMessages.integrationReport]: "Informe de integración"
};
