/* eslint-disable no-restricted-globals */

import React, { createContext, useCallback, useContext, useState } from "react";

// region Libraries
import jwt from "jwt-decode";
import _ from "lodash";
// endregion Libraries
// region Shared
import { User } from "@shared/interfaces/user.interface";
import { TokenPayload } from "@shared/interfaces/token-payload.interface";
import { AuthStateResponse } from "@shared/interfaces/auth.interface";
import { Param } from "@shared/entities/reg_params.entity";
// endregion Shared
// region Services
import { WebSocketContext } from "@services/websocketContext";
import api from "@services/api";
// endregion Services

// region Interfaces
interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  params: Param[];
  validateToken: (token: string) => boolean;
  getTokenData: (token: string) => any;
  signIn(credentials: SignInCredentials, authState?: AuthStateResponse): Promise<void>;
  signOut(): void;
}
// endregion Interfaces

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
const AuthProvider: React.FC = ({ children }) => {

  // region Hooks
  const socket = useContext(WebSocketContext);
  const [data, setData] = useState<AuthStateResponse>(() => {

    const token = localStorage.getItem("@Fleet:token");
    let user;
    let params;

    if (token) {

      try {
        const tokenPayload: TokenPayload = jwt(token);

        user = tokenPayload.user;
        params = tokenPayload.params;
      } catch (error) {
        user = undefined;
        params = undefined;
      }
    }

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { access_token: token, user, params };
    }

    return {} as AuthStateResponse;
  });
  // endregion Hooks
  // region Functions

  /**
   * Log in and store data access
   * @param { email, password }
   */
  const signIn = useCallback(async ({ email, password }, authState?: AuthStateResponse) => {
    const response = authState
      ? { data: { result: authState } }
      : await api.post("/auth/login", { email, password });
    const { access_token: token, user, params } = response.data.result;

    api.defaults.headers.authorization = `Bearer ${token}`;

    // If we have current survey on user
    // Set auxiliary prop to show survey (only once per login)
    if (!_.isEmpty(user.current_survey)) {
      user.current_survey.show = true;
    }

    localStorage.setItem("@Fleet:token", token);
    localStorage.setItem("@Fleet:currentSurvey", JSON.stringify(user.current_survey));

    // Verify if have Google key
    // If haven't save in storage, get and save
    // If have use the same
    if (!localStorage.getItem("@Fleet:google")) {

      try {

        const { data } = await api.get("params/read/google");

        if (data.status === "success") localStorage.setItem("@Fleet:google", data.result[0].key);
        else localStorage.setItem("@Fleet:google", "AIzaSyAk2H-iRNgKlHmiaXD6Sg0AbRGkqugVOkA");

      } catch (error: any) {
        localStorage.setItem("@Fleet:google", "AIzaSyAk2H-iRNgKlHmiaXD6Sg0AbRGkqugVOkA");
      }
    }

    setData({ access_token: token, user, params });

    // Send login event and token to socket
    socket.emit("login", token);

  }, [socket]);

  /**
   * Log out and clear store data access
   */
  const signOut = useCallback(() => {

    localStorage.removeItem("@Fleet:token");
    localStorage.removeItem("@Fleet:currentSurvey");
    localStorage.removeItem("@Fleet:google");

    setData({} as AuthStateResponse);

    // Send logout event to socket
    socket.emit("logout");

  }, [socket]);

  /**
   * Get token decrypted data
   * @param token
   */
  const getTokenData = useCallback((token: string) => jwt(token) as any, []);

  /**
   * Verify if token is valid
   * @param token
   */
  const validateToken = useCallback((token: string) => {

    if (!token) return false;

    try {
      const decoded = getTokenData(token);

      if (!decoded.exp) return false;

      return Date.now() < decoded.exp * 1000;
    } catch (e) {
      return false;
    }
  }, [getTokenData]);
  // endregion Functions

  return (
    <AuthContext.Provider
      value={{
        user: data.user, params: data.params, signIn, signOut, getTokenData, validateToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
