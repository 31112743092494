/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinTable, ManyToMany,
  PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { Travel } from "./reg_travels.entity";

@Entity("reg_travels_contacts")
export class TravelContact {

  @PrimaryGeneratedColumn("uuid")
  id_travel_contact?: string;

  @Column({ type: "varchar", nullable: true })
  name?: string;

  @Column({ type: "varchar", nullable: false })
  contact?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToMany(() => Travel, (travels) => travels.contacts)
  @JoinTable({
    name: "rel_travels_contacts",
    joinColumn: {
      name: "id_travel_contact_fk",
      referencedColumnName: "id_travel_contact"
    },
    inverseJoinColumn: {
      name: "id_travel_fk",
      referencedColumnName: "id_travel"
    }
  })
  travels?: Travel[];

}
