/* eslint-disable import/prefer-default-export */

import { AlertMessages, TranslationAlertMessages } from "../interfaces";

export const messages: TranslationAlertMessages = {

  /* region Frontend messages */
  [AlertMessages.noPermissionToJustify]: "No está permitido justificar alertas de tipo",
  [AlertMessages.siteRegistration]: "Registro del sitio",
  [AlertMessages.searchLocation]: "Busque la ubicación primero",
  [AlertMessages.alertConfirmation]: "Confirmación de alerta",
  [AlertMessages.vehicleAtTheAlertMoment]: "Vehículo en el momento de la alerta",
  [AlertMessages.expirationDate]: "Fecha de vencimiento",
  [AlertMessages.occurrenceDate]: "Fecha de ocurrencia",
  [AlertMessages.expiresIn]: "Vencimiento en",
  [AlertMessages.justification]: "Justificación",
  [AlertMessages.details]: "Detalles",
  [AlertMessages.expiredIn]: "Vencido en",
  [AlertMessages.unauthorizedStop]: "Parada indebida",
  [AlertMessages.expiredConcrete]: "Concreto vencido",
  [AlertMessages.block]: "Bloqueo",
  [AlertMessages.concreteAboutToExpire]: "Concreto próximo al vencimiento",
  [AlertMessages.mainBatteryConnection]: "Conexión de batería principal",
  [AlertMessages.mainBatteryDisconnection]: "Desconexión de batería principal",
  [AlertMessages.unauthorizedUnloading]: "Descarga indebida",
  [AlertMessages.speeding]: "Exceso de velocidad",
  [AlertMessages.speedingTravel]: "Exceso de velocidad en el viaje",
  [AlertMessages.hardwareInactivity]: "Inactividad de hardware",
  [AlertMessages.maintenance]: "Mantenimiento de vehículo",
  [AlertMessages.jammingDetected]: "Evento de Jammer",
  [AlertMessages.readAlertTypesSuccess]: "Tipo(s) de alerta(s) devuelto(s) con éxito",
  [AlertMessages.observation]: "Observación",
  [AlertMessages.licensePlate]: "Placa",
  [AlertMessages.justificationDeleteConfirmation]: "¿Desea eliminar la justificación:",
  [AlertMessages.intervalOfDays]: "Su licencia de uso expirará en {{days}} día(s)!<br/>"
    + "Por favor, póngase en contacto con nuestro departamento financiero hasta {{limitDate}} para regularizar la situación.<br/>"
    + "Contactos: WhatsApp / Teléfono (11) 3564-3314 o E-mail: financeiro@topconsuite.com.",
  [AlertMessages.blockedPlataforom]: "plataforma bloqueada para los usuarios",
  [AlertMessages.zmrc]: "Restricción de Circulación",

  /* region Alerts History messages */
  [AlertMessages.alert]: "Alerta",
  [AlertMessages.confirmationDate]: "Fecha de confirmación",
  [AlertMessages.authorizer]: "Autorizador",
  [AlertMessages.alertsHistoryTitle]: "Historial de alertas",
  [AlertMessages.startDate]: "Fecha inicial",
  [AlertMessages.finishDate]: "Fecha final",
  [AlertMessages.type]: "Tipo",
  /* endregion */

  /* region Alert note messages */
  [AlertMessages.noTxCommunication]: "Sin comunicación con sensor TX por más de 48 horas",
  [AlertMessages.noRxCommunication]: "Sin comunicación con sensor RX por más de 24 horas",
  [AlertMessages.lowBattery]: "Batería baja del sensor",
  [AlertMessages.possibleViolation]: "Posible violación del sensor",
  [AlertMessages.unknown]: "Diagnóstico de estado de sensor desconocido",
  /* endregion */

  /* endregion Frontend messages */
  /* region Backend messages */
  [AlertMessages.readAlertSuccess]: "Alerta(s) devuelto(s) con éxito",
  [AlertMessages.confirmAlertSuccess]: "Alerta confirmado con éxito",
  [AlertMessages.createAlertSuccess]: "Alerta creado con éxito Tipo: ",
  [AlertMessages.noAlertType]: "Ningún tipo de alerta encontrado",
  [AlertMessages.readAlertTypesError]: "Falla al buscar tipo(s) de alerta(s)",
  [AlertMessages.noAlertFound]: "Ningún alerta encontrado",
  [AlertMessages.readAlertError]: "Falla al buscar alertas",
  [AlertMessages.alertNotRegistered]: "Alerta no registrado en nuestro sistema",
  [AlertMessages.noVehicleLinkedToHardware]: "Ningún vehículo vinculado al hardware",
  [AlertMessages.alertNotMapped]: "Alerta no homologado",
  [AlertMessages.checkVehicleTrackerAlertError]: "Falla al verificar comando de alerta de vehículo",
  [AlertMessages.createAlertJustificationSuccess]: "Justificación guardada con éxito",
  [AlertMessages.createAlertJustificationError]: "Falla al guardar justificación",
  [AlertMessages.readAlertJustificationSuccess]: "Justificación(es) devuelta(s) con éxito",
  [AlertMessages.readAlertJustificationError]: "Falla al buscar justificación(es)",
  [AlertMessages.deleteAlertJustificationSuccess]: "Justificación eliminada con éxito",
  [AlertMessages.deleteAlertJustificationError]: "Falla al eliminar justificación",
  [AlertMessages.alertjustificationNotRegistered]: "Justificación no registrada en nuestro sistema",
  [AlertMessages.noAlertJustificationFound]: "Ninguna justificación encontrada",

  /* region Integrations messages */
  [AlertMessages.invalidDatePeriod]: "Período de fecha inválido"
  /* endregion */
  /* endregion Backend messages */
};
