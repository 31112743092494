/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { VehicleState } from "./reg_vehicles_states.entity";

@Entity("tpe_vehicles_states_types")
export class TpeVehicleState {

  @PrimaryGeneratedColumn("uuid")
  id_vehicle_state_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => VehicleState, (vehicles_states) => vehicles_states.status)
  vehicles_states?: VehicleState[];

}
