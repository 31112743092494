import React from "react";
import { TextField } from "@libraries/mui";

type Props = {
  label: string;
  number: number;
  setNumber: React.Dispatch<React.SetStateAction<number>>;
  min?: number;
  max?: number;
  style?: React.CSSProperties
};

const NumberField: React.FC<Props> = ({ label, number, setNumber, min, max, style }) => {
  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 69 || event.keyCode === 190 || event.keyCode === 189 || event.keyCode === 187
      || event.keyCode === 188) {
      event.preventDefault();
    }
  };

  const handleBlur = (event) => {
    const value = parseInt(event.target.value);

    if (isNaN(value)) {
      setNumber(min as number);

      return;
    }

    if (min && value < min) {
      setNumber(min);
    } else if (max && value > max) {
      setNumber(max);
    }
  };

  const inputProps = {
    onKeyDown: handleKeyDown,
    min,
    style
  };

  if (max) inputProps["max"] = max;

  return (
    <TextField
      label={label}
      type="number"
      value={number}
      onChange={handleChange}
      onBlur={handleBlur}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={inputProps}
    />
  );
};

NumberField.defaultProps = {
  min: 0,
  style: {
    width: "60px",
    textAlign: "center"
  }
};

export default NumberField;
