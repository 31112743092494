/* eslint-disable no-shadow */
import styled, { css } from "styled-components";

import Tooltip from "@components/Tooltip";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isError: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* background: #bdbdbd; */
  border-radius: 6px;
  border: 2px solid #DDDDDD;
  padding: 12px 8px 12px 16px;
  width: 100%;
  color: black;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) => props.isError
    && css`
      border-color: #c53030;
    `}

  ${(props) => props.isFocused
    && css`
      color: ${(props) => props.theme.colors.text};
      border-color: ${(props) => props.theme.colors.text};
    `}

  ${(props) => props.isFilled
    && css`
      color: ${(props) => props.theme.colors.primary};
    `}



  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: ${(props) => props.theme.colors.text};
  }

  svg {
    margin-left: 8px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    font-weight: bold;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
