/* eslint-disable import/prefer-default-export */
import { TranslationZonesValidityMessages, ZonesValidityMessages } from "../interfaces";

export const messages: TranslationZonesValidityMessages = {

  [ZonesValidityMessages.columnStartTime]: "Start Time",
  [ZonesValidityMessages.columnEndTime]: "End Time",
  [ZonesValidityMessages.columnLicensePlateEnd]: "License Plate end",
  [ZonesValidityMessages.columnDayOfWeek]: "Day of Week",

  [ZonesValidityMessages.sunday]: "Sunday",
  [ZonesValidityMessages.monday]: "Monday",
  [ZonesValidityMessages.tuesday]: "Tuesday",
  [ZonesValidityMessages.wednesday]: "Wednesday",
  [ZonesValidityMessages.thursday]: "Thursday",
  [ZonesValidityMessages.friday]: "Friday",
  [ZonesValidityMessages.saturday]: "Saturday",

  [ZonesValidityMessages.zoneRuleEdit]: "Edit",
  [ZonesValidityMessages.zoneRuleTitle]: "Zone Rules",
  [ZonesValidityMessages.newZoneRule]: "New Zone Rule",
  [ZonesValidityMessages.zoneRuleDeleteConfirmationText]: "Are you sure you want to delete the zone rule?",
  [ZonesValidityMessages.zoneRuleDeleteConfirmation]: "Delete confirmation",

  [ZonesValidityMessages.readSuccess]: "Zone rules read successfully",
  [ZonesValidityMessages.readError]: "Error reading zone rules",
  [ZonesValidityMessages.updateSuccess]: "Zone rules successfully updated",
  [ZonesValidityMessages.updateError]: "It was not possible to update the zone rule",
  [ZonesValidityMessages.createSuccess]: "Zone rules created successfully",
  [ZonesValidityMessages.createError]: "Error creating zone rules",
  [ZonesValidityMessages.noZonesFound]: "No zone rules found",
  [ZonesValidityMessages.deleteSuccess]: "Zone rules deleted successfully",
  [ZonesValidityMessages.deleteError]: "Error deleting zone rules",


}
