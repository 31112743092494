import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { windowWidth } from "@styles/global";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-content: flex-start;
  padding-bottom: 20px !important;

  &.page {
    padding-top: 1rem;
  }

  @media (max-width: 800px) { // Mobile
    padding-bottom: 0;
  }

  /**
    The segmented control object needs to be closer to the top of the data table
   */
  .segmented-control {
    z-index: 2;
    margin-bottom: -30px;

    @media ( max-width: ${windowWidth.mobile.large} ) {
      margin-bottom: 0px;
    }
  }

  .speedInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .openSpeedChart {
      cursor: pointer;
    }
  }
`;

export const ContainerModal = styled.div`
  z-index: 2001;

  .MuiDialog-paper {
    max-width: 1200px;
    width: 1200px;

    .mContent {

      .MuiTypography-root {
        width: 100%;
      }
      .MuiFormControl-root {
        width: 100%;

        .MuiFormHelperText-root {
          text-align: right;
          margin: 0;
        }
      }
    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;

export const ContainerSwitches = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  `;
