export enum TravelSwapMessages {
  swapCompleted = "travelSwap.swapCompleted",
  swapTravelData = "travelSwap.swapTravelData",
  originalTravel = "travelSwap.originalTravel",
  replaceTravel = "travelSwap.travelToSwap",
  swap = "travelSwap.swap",
  swapConfirmationTitle = "travelSwap.swapConfirmation",
  swapConfirmationDescription = "travelSwap.confirm"
}

export type TranslationTravelSwapMessages = {
  [key in TravelSwapMessages]: string;
}
