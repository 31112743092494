/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as _ from "lodash";

// region Imports - Assets
import LogoFleet from "@assets/FleetLogo/fleetLogo.svg";
// endregion Imports - Assets
// region Imports - Libraries
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { TextField } from "unform-material-ui";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
// endregion Imports - Libraries
// region Imports - Shared
import { EventTypes } from "@shared/constants/event-types.enum";
import { AlertTypesID } from "@shared/constants/alert-types.enum";
import { Alert as AlertInterface } from "@shared/interfaces/alert.interface";
import { Event as EventInterface } from "@shared/interfaces/event.interface";
import { Justification } from "@shared/interfaces/justification.interface";
import { Location } from "@shared/interfaces/location.interface";
import { Travel } from "@shared/interfaces/travel.interface";
// endregion Imports - Shared
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Hooks
import getValidationErrors from "@hooks/getValidationErrors";
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Pages
import ModalFormLocation from "@organisms/ModalFormLocation";
// endregion Imports - Pages
// region Imports - Store
import { AlertsActions } from "@store/ducks/Alerts/alerts.action";
import { LocationsFormActions } from "@store/ducks/Locations/LocationsForm/locations-form.action";
import { LocationsFormState } from "@store/ducks/Locations/LocationsForm/locations-form.type";
// endregion Imports - Store
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  AlertMessages,
  AlertTypeMessages,
  GlobalMessages,
  AlertNoteMessages,
  VehiclesStatesTypesMessages,
  YupMessages,
  EventsMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import { MapFixedPointCoord } from "@components/Map";
import { ModalVehicleAndTravelDetails } from "@components/Modal/VehicleAndTravelDetails";
// endregion Imports - Components
// region Imports - Styles
import { ContentContainer } from "./styles";

// endregion Imports - Styles

interface AlertJustification extends Justification {
  newValue?: boolean;
}

interface AlertDialogJustifyInterface {
  selectedAlert: AlertInterface,
  actionType: LocationsFormState["type"],
  closeCallback: () => void,
  setLoading?: (boolean) => void,
  canDetailTravel?: boolean
}
const AlertDialogJustify: React.FC<AlertDialogJustifyInterface> = (
  { selectedAlert, actionType, closeCallback, setLoading, canDetailTravel = true }
) => {

  // region Hooks
  const { addToast } = useToast();
  const { user } = useAuth();
  const { t } = useTranslation();

  const formRef = useRef<FormHandles>(null);
  const locationsForm = useSelector(({ locationsForm: state }) => state);
  const dispatch = useDispatch();
  // endregion Hooks
  // region States
  const [addressAlert, setAddressAlert] = useState<google.maps.GeocoderResult>({} as google.maps.GeocoderResult);
  const [selectedTravel, setSelectedTravel] = useState<Travel>({} as Travel);
  const [openTravelModal, setOpenTravelModal] = useState<boolean>(false);
  const [loadingTravel, setLoadingTravel] = useState<boolean>(false);
  const [valueTyped, setValueTyped] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [selectedJustification, setSelectedJustification] = useState<AlertJustification | string>("");
  const [options, setOptions] = useState<AlertJustification[]>([]);
  // endregion States
  // region Functions
  const newJustificationKey = "newJustification";
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: {justification: string}) => {

      try {

        // Define the validation types
        const schema = Yup.object().shape({
          justification: Yup.string().trim().required(t(YupMessages.justificationRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Confirm alert
        await confirmAlert(formData.justification);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };

  /** Update alert as a confirmation
   * @param justification Justification of confirm alert
   */
  const confirmAlert = useCallback(async (justification: string) => {

    try {

      if (!user.admin
        && !user.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.id_alert_type === selectedAlert.type.id_alert_type
        )) {

        addToast({
          type: "error",
          title: t(GlobalMessages.actionNotPermitted),
          description: `${t(AlertMessages.noPermissionToJustify)}: ${selectedAlert.type.description}`
        });

        return;
      }

      const updateAlert: AlertInterface = {
        justification,
        confirmation_date: new Date(),
        user: {
          id_user: user.id_user
        }
      } as AlertInterface;

      // Confirm the alert
      if (setLoading) setLoading(true);
      const { data } = await api.patch(`alerts/update/${selectedAlert.id_alert}`, updateAlert);

      if (data.status === "success") {

        addToast({ type: "success", title: t(GlobalMessages.success), description: data.message });

        // Remove alert from store
        dispatch(AlertsActions.removeAlerts({
          list: [selectedAlert],
          loading: false
        }));

        closeCallback();

      } else addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      if (setLoading) setLoading(false);
    }

  }, [addToast, selectedAlert, dispatch, closeCallback, setLoading, user, t]);

  const getJustifications = useCallback(async () => {

    setLoadingOptions(true);

    try {
      const { data } = await api.get("alerts/justification/read");

      if (data.status === "success") {
        setOptions(data.result);

      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error) {
      if (!error.response) {
        addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });

      } else {
        addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    } finally {
      setLoadingOptions(false);
    }
  }, [addToast, t]);

  /** Set created location in active event alert
   * PS: When linked location in event, trigger will confirm the alert automatically
   */
  const setLocationEvent = useCallback(async (location: Location, idEvent: string) => {

    try {

      if (setLoading) setLoading(true);
      const { data } = await api.patch(`/vehicles/events/update/${idEvent}`, { location: { id_location: location.id_location } } as EventInterface);

      if (data.status === "success") {

        // Remove alert from store
        dispatch(AlertsActions.removeAlerts({
          list: [selectedAlert],
          loading: false
        }));

        // Set justification
        formRef.current?.setFieldValue("justification", `${t(AlertMessages.siteRegistration)}: ${location.name}`);

      } else addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      if (setLoading) setLoading(false);
    }

  }, [addToast, dispatch, selectedAlert, formRef, setLoading, t]);

  /** Set options to open form register of location
   */
  const setFormLocationData = useCallback(() => {

    if (_.isEmpty(addressAlert)) addToast({ type: "info", title: t(AlertMessages.searchLocation) });
    else {
      dispatch(LocationsFormActions.setLocationsForm({
        ...locationsForm,
        googleAddressData: addressAlert as google.maps.GeocoderResult,
        type: "register",
        externalComponent: true,
        open: true
      }));
    }

  }, [dispatch, locationsForm, addressAlert, addToast, t]);

  // Get travel /vehicle data and show Travel Details Modal
  const showTravel = useCallback(async (idTravel: string) => {

    const FreadTravel = async () => await (await api.get(`travels/read/${idTravel}`)).data;
    const FreadTravelAlerts = async () => await (await api.post("alerts/read/", { idTravel: idTravel })).data;
    const FreadTravelStates = async () => await (await api.get(`vehicles/states/get-filtered-by-travel/${idTravel}`)).data;

    const promise1 = FreadTravel(); // Get travel
    const promise2 = FreadTravelAlerts(); // Get travel alerts
    const promise3 = FreadTravelStates(); // Get travel states (with description)

    setLoadingTravel(true);

    const [travelResponse, travelAlertsResponse, travelStatesResponse] = await Promise.all([
      promise1, promise2, promise3
    ]);

    // Transform fetched data to travel pattern
    const travelDetails = {
      ...travelResponse.result,
      vehicle: {
        ...travelResponse.result.vehicle,
        states: travelStatesResponse.result,
        alerts: travelAlertsResponse.result
      }
    };

    setLoadingTravel(false);

    setOpenTravelModal(true);
    setSelectedTravel(travelDetails);

  }, []);

  /**
   * Fill event address if the event don't have address yet
   * @param formattedAddressData Formatted address data to fill event
   */
  const fillEventAddressIfEmpty = useCallback(async (formattedAddressData: string) => {

    if (!selectedAlert?.event?.address) {

      try {

        const { data } = await api.patch(
          `events/updateAddress/${selectedAlert?.event?.id_event}`,
          { address: formattedAddressData }
        );

        if (data.status !== "success") {
          addToast({ type: "error", title: t(EventsMessages.updateError), description: data.message });
        }

      } catch (error) {

        addToast({ type: "error", title: t(EventsMessages.updateError), description: error.message });
      }
    }

  }, [addToast, selectedAlert, t]);

  // Used to prevent re-rendering in  Travel Details Modal
  const memoModalVehicleAndTravelDetails = useMemo(() => {
    if (!_.isEmpty(selectedTravel)) {
      return (
        <>
          <ModalVehicleAndTravelDetails
            vehicle={{ ...selectedTravel.vehicle }}
            travel={selectedTravel}
            open={openTravelModal}
            onClose={() => setOpenTravelModal(false)}
            historical
            overAll
            onlyView
          />
        </>
      );
    }

    return <></>;
  }, [selectedTravel, openTravelModal]);

  /**
   * Add new value typed in justification input to database
   * @param valueToInclude
   */
  const addValueTyped = async (valueToInclude: string) => {

    try {
      const { data } = await api.post("alerts/justification/create", { description: valueToInclude });

      if (data.status === "success") {
        const { result: justification } = data;

        setSelectedJustification(justification);
        setOptions([...options, justification]);

        addToast({ type: "success", title: t(GlobalMessages.success), description: data.message });

      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error) {
      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    }
  };

  /**
   * Open confirmation dialog to delete justification
   * @param option
   */
  const deleteConfirmation = (option: Justification) => {
    // eslint-disable-next-line no-restricted-globals
    const value = confirm(`${t(AlertMessages.justificationDeleteConfirmation)} "${option.description}"? `);

    if (value) {
      deleteOption(option.id_justification).then();
    }
  };

  /**
   * Delete justification
   * @param optionToDelete
   */
  const deleteOption = async (optionToDelete) => {

    try {
      const { data } = await api.delete(`alerts/justification/delete/${optionToDelete}`);

      if (data.status === "success") {
        setOptions(options.filter((option) => option.id_justification !== optionToDelete));
        setSelectedJustification("");
        addToast({ type: "success", title: t(GlobalMessages.success), description: data.message });

      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error) {
      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    }
  };

  /**
   * Handle change of justification
   * @param event
   * @param justification
   */
  const handleChange = async (event: any, justification: Justification | null | string) => {

    if (justification && typeof justification !== "string") {
      if (justification.id_justification === newJustificationKey) {

        setSelectedJustification(justification);
        await addValueTyped(justification.description);

      } else {

        setSelectedJustification(justification);
      }
    }
  };

  /**
   * Generate filter options
   */
  const filter = createFilterOptions<AlertJustification>();
  // endregion Functions
  // region Effects

  // Identify when location is register from alert
  useEffect(() => {

    // Location created
    if (locationsForm.actions.type === "create" && !_.isEmpty(locationsForm.actions.response)) {

      // Link the created location in active event alert
      setLocationEvent(locationsForm.actions.response, selectedAlert.event?.id_event as string);
    }

  }, [locationsForm.actions, setLocationEvent, selectedAlert.event]);

  // endregion Effects

  return (
    <>
      {!_.isEmpty(selectedAlert) && (
        <DialogConfirmAction
          open
          onClose={() => closeCallback()}
          title={t(AlertMessages.alertConfirmation)}
          actions={[
            { text: t(GlobalMessages.close), action: () => closeCallback() },
            ...(actionType !== "details" ? [{ text: t(GlobalMessages.confirm), action: () => formRef.current?.submitForm() }] : [])
          ]}
        >
          <ContentContainer>
            <div className="dialogContent-Alert">
              <Loading loading={loadingTravel} />
              <div className="firstContent">
                <div className="box">
                  <div className="alertIcon">{utils.getAlertIconAccordingType(selectedAlert.type.id_alert_type)}</div>
                  <div className="description">{t(AlertTypeMessages[selectedAlert.type.id_alert_type])}</div>
                </div>
                <div className="box">
                  <div className="vehicleIcon">{utils.getVehicleIconAccordingTypeAndState(selectedAlert.vehicle, selectedAlert.vehicle_state)}</div>
                  <div className="description">{selectedAlert.vehicle?.code}</div>
                </div>
              </div>
              <div className="secondContent">
                {selectedAlert.vehicle_state && (
                  <>
                    <div className="box">
                      <div className="title">{t(AlertMessages.vehicleAtTheAlertMoment)}</div>
                      <div className="text">{t(VehiclesStatesTypesMessages[selectedAlert.vehicle_state?.status.id_vehicle_state_type ?? ""])}</div>
                    </div>
                  </>
                )}
                <div className="box">
                  <div className="title">
                    {selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
                    || selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                      ? t(AlertMessages.expirationDate)
                      : t(AlertMessages.occurrenceDate)}
                  </div>
                  <div className="text">
                    {(selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO)
                    && selectedAlert.event
                      ? utils.formatDateIfHave(selectedAlert.event.finish_date, "fullDate")
                      : utils.formatDateIfHave(selectedAlert.occurrence_date, "fullDate")}
                  </div>
                </div>
                {selectedAlert.hardware && (
                  <>
                    <div className="box">
                      <div className="title">Hardware</div>
                      <div className="text">{selectedAlert.hardware.type.description}</div>
                    </div>
                    <div className="box">
                      <div className="title">{t(GlobalMessages.model)}</div>
                      <div className="text">{selectedAlert.hardware.brand} - {selectedAlert.hardware.model}</div>
                    </div>
                  </>
                )}
                {selectedAlert?.note && (
                  <div className="box">
                    <div className="title">{t(AlertMessages.observation)}</div>
                    <div className="text">{
                      !_.isEmpty(t(AlertNoteMessages[selectedAlert.note]))
                        ? t(AlertNoteMessages[selectedAlert.note])
                        : selectedAlert.note
                      }
                    </div>
                  </div>
                )}
                {selectedAlert.event && selectedAlert.event.start_date && selectedAlert.event.finish_date && (
                  <>
                    <div className="box">
                      {selectedAlert.event.type.description === EventTypes.EXCESSO_DE_VELOCIDADE
                        ? (
                          <>
                            <div className="title">{t(GlobalMessages.speed)}</div>
                            <div className="text">{selectedAlert.event.speed ? Math.floor(selectedAlert.event.speed) : "S/N"}</div>
                          </>
                        )
                        : selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
                          ? (
                            <></>
                          )
                          : (
                            <>
                              <div className="title">
                                {selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                                  ? t(AlertMessages.expiresIn)
                                  : t(GlobalMessages.duration)}
                              </div>
                              <div className="text">
                                {selectedAlert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                                  ? utils.formatDateIfHave(
                                    utils.calcDataRange(new Date(), selectedAlert.event.finish_date),
                                    "durationDescriptiveTime"
                                  )
                                  : utils.formatDateIfHave(
                                    utils.calcDataRange(selectedAlert.event.start_date, selectedAlert.event.finish_date),
                                    "durationDescriptiveTime"
                                  )}
                              </div>
                            </>
                          )}
                    </div>
                    <div className="box">
                      <div className="title">{t(GlobalMessages.address)}</div>
                      <div className="text">{_.isEmpty(addressAlert?.formatted_address) ? "S/N" : addressAlert?.formatted_address}</div>
                    </div>
                  </>
                )}
              </div>
              <div className="thirdContent">
                <div className="actions">
                  {canDetailTravel
                    && selectedAlert?.travel?.id_travel
                    && (
                      <Button
                        disableRipple
                        id="btn-travel-details"
                        color="primary"
                        onClick={() => showTravel(selectedAlert?.travel?.id_travel ?? "")}
                      >{t(GlobalMessages.travelDetails)}
                      </Button>
                    )}
                  {actionType !== "details"
                    && selectedAlert.event
                    && selectedAlert.event.latitude
                    && selectedAlert.event.longitude
                    && ![EventTypes.EXCESSO_DE_VELOCIDADE as string].includes(selectedAlert?.event?.type?.description)
                    && selectedAlert.type.id_alert_type !== AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
                    && selectedAlert.type.id_alert_type !== AlertTypesID.CONCRETO_VENCIDO
                    && (
                      <Button
                        disableRipple
                        color="primary"
                        onClick={() => setFormLocationData()}
                      >
                        {t(GlobalMessages.registerLocation)}
                      </Button>
                    )}
                </div>
                {selectedAlert.event && selectedAlert.event.latitude && selectedAlert.event.longitude && (
                  <>
                    <div className="staticMap" id={`dialog-alert-static-${selectedAlert.id_alert}`}>
                      <img
                        src={LogoFleet}
                        alt="Visualizar localização"
                        onClick={() => utils.showDrawerMap(
                          "show",
                          `#dialog-alert-drawer-${selectedAlert.id_alert}`,
                          `#dialog-alert-static-${selectedAlert.id_alert}`,
                          <MapFixedPointCoord
                            mapHeight={250}
                            latitude={Number(selectedAlert.event?.latitude)}
                            longitude={Number(selectedAlert.event?.longitude)}
                            returnAddressData={async (addressData) => {
                              setAddressAlert(addressData);
                              await fillEventAddressIfEmpty(addressData.formatted_address);
                            }}
                            alertMarker
                          />
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="drawerMap hidden" id={`dialog-alert-drawer-${selectedAlert.id_alert}`}><CircularProgress /></div>
                  </>
                )}
              </div>
              <div className="fourContent">
                <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData)}>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      onOpen={() => getJustifications()}
                      freeSolo
                      limitTags={1}
                      getOptionLabel={(option) => option?.description || ""}
                      options={options}
                      renderOption={(props, option) => {
                        if (props.id_justification !== newJustificationKey) {
                          return (
                            <li {...props} style={{ width: "100%" }}>
                              <div
                                style={{ display: "flex", justifyContent: "space-between", overflow: "auto", width: "auto", alignItems: "center" }}
                              >
                                <div>{props.description}</div>
                                <Button onClick={() => deleteConfirmation(props)}>
                                  <RemoveCircle style={{ verticalAlign: "middle", color: "red" }} />
                                </Button>
                              </div>
                            </li>
                          );
                        }

                        return (
                          <li {...props}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", overflow: "auto" }}>
                              <div style={{ display: "flex", alignItems: "center" }}>{
                                props.newValue
                                  ? (
                                    <span style={{ display: "inline-flex", alignItems: "center", marginRight: "5px" }}>
                                      <AddCircle style={{ verticalAlign: "middle", color: "green" }} />
                                    </span>
                                  )
                                  : <></>
                              }
                                {props.description}
                              </div>
                            </div>
                          </li>
                        );

                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push({
                            id_justification: newJustificationKey,
                            description: valueTyped,
                            newValue: true
                          });
                        }

                        return filtered;
                      }}
                      onInputChange={(event, value) => setValueTyped(value)}
                      value={selectedJustification}
                      getOptionSelected={(option, value) => option.id_justification === value.id_justification}
                      onChange={handleChange}
                      disabled={actionType === "details"}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            {...params}
                            rowsMax={1}
                            multiline
                            variant="outlined"
                            label={t(AlertMessages.justification)}
                            name="justification"
                            InputLabelProps={{ shrink: !_.isEmpty(selectedJustification) ? true : undefined }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  { loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        </div>
                      )}
                    />
                  </FormControl>
                </Form>
              </div>
            </div>
          </ContentContainer>
          <ModalFormLocation />
        </DialogConfirmAction>
      )}
      {openTravelModal && canDetailTravel && (memoModalVehicleAndTravelDetails)}
    </>
  );
};

export { AlertDialogJustify as default };
