import styled from "styled-components";

interface ContainerProps {
  inHeader?: boolean;
}

export const Container = styled.div`
    cursor: pointer;
    padding: 7px;
    position: ${(props: ContainerProps) => (props.inHeader ? "" : "absolute")};
    top: 1rem;
    right: 1rem;
    z-index: 1;

  svg {
    font-size: 1.8rem;
    fill: ${(props: ContainerProps) => (props.inHeader ? "#002951" : "#fff")};
  }
`;
