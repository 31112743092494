export enum TravelDataMessages {
  origin = "travelDataMessages.origin",
  destination = "travelDataMessages.destination",
  finalLocation = "travelDataMessages.finalLocation",
  taxInvoice = "travelDataMessages.taxInvoice",
  sealNumber = "travelDataMessages.sealNumber",
  invoiceIntegrationDate = "travelDataMessages.invoiceIntegrationDate",
  travelStartDate = "travelDataMessages.travelStartDate",
  invoiceIssueDate = "travelDataMessages.invoiceIssueDate",
  pieceToBeConcreted = "travelDataMessages.pieceToBeConcreted",
  loadVolume = "travelDataMessages.loadVolume",
  loadExpirationDate = "travelDataMessages.loadExpirationDate",
  trace = "travelDataMessages.trace",
  application = "travelDataMessages.application",
  slump = "travelDataMessages.slump",
  resistance = "travelDataMessages.resistance",
  resistanceType = "travelDataMessages.resistanceType",
  resistanceComplement = "travelDataMessages.resistanceComplement",
  gravel = "travelDataMessages.gravel"
}

export type TranslationTravelDataMessages = {
  [key in TravelDataMessages]: string;
}
