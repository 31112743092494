import React, { useCallback, useRef, useState } from "react";

// region Assets
import { ReactComponent as ManutencaoIcon } from "@assets/icons/maintenance-vehicle.svg";
// endregion Assets
// region Libraries
import * as Yup from "yup";
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
import { Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  GlobalMessages,
  VehicleMaintenanceMessages,
  YupMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { ScreenSize } from "@store/ducks/Screen/screen.type";
import { FormHandles } from "@unform/core";
// endregion Interfaces
// region Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
// endregion Components
// region Styles
import getValidationErrors from "@hooks/getValidationErrors";
import * as Styled from "./styles";
// endregion Styles

type TVehicleMaintenanceFormProps = {
  handleChangeVehicleMaintenance: (justification: string) => void;
}

interface FormData {
  justificationOption: string,
  justificationField: string
}

const VehicleMaintenanceForm: React.FC<TVehicleMaintenanceFormProps> = ({
  handleChangeVehicleMaintenance
}) => {

  // region Hooks
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [openDialogConfirmMaintenance, setOpenDialogConfirmMaintenance] = useState(false);
  const [justificationOption, setJustificationOption] = useState("");
  const [justification, setJustification] = useState("");
  // endregion States
  // region Constants
  const maintenanceOptions = [
    t(VehicleMaintenanceMessages.optionHydraulic),
    t(VehicleMaintenanceMessages.optionElectrical),
    t(VehicleMaintenanceMessages.optionTireService),
    t(VehicleMaintenanceMessages.optionSuspension),
    t(VehicleMaintenanceMessages.optionBodywork),
    t(VehicleMaintenanceMessages.optionRockBreaker),
    t(VehicleMaintenanceMessages.optionOthers)
  ];
  // endregion Constants
  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Functions

  /**
   * Submit form to change maintenance vehicle
   * @param formData Form data
   */
  const handleSubmit = useCallback(async (formData: FormData) => {

    try {

      // Define the validation types
      const schema = Yup.object().shape({
        justificationOption: Yup
          .string()
          .required(t(YupMessages.fieldRequired)),
        justificationField: Yup
          .string()
          .when("justificationOption", {
            is: t(VehicleMaintenanceMessages.optionOthers),
            then: Yup.string().required(t(YupMessages.justificationRequired))
          })
      });

      // Validate inputs
      await schema.validate(formData, { abortEarly: false });

      let justificationContent: string;

      if (formData.justificationOption === t(VehicleMaintenanceMessages.optionOthers)) {
        justificationContent = formData.justificationField;
      } else {
        justificationContent = formData.justificationField !== ""
          ? `${formData.justificationOption} - ${formData.justificationField}` : formData.justificationOption;
      }

      setJustification(justificationContent);
      setOpenDialogConfirmMaintenance(true);

    } catch (error) {
      formRef.current?.setErrors(getValidationErrors(error));
    }

  }, [t]);

  // endregion Functions

  return (
    <Styled.VehicleMaintenanceForm isMobile={isMobile}>
      <Form className="form" ref={formRef} onSubmit={handleSubmit}>
        <div className="container-vehicle-maintenance-form__justify">
          <div className="vehicle-maintenance-form__instruction">
            <strong className="strong">2</strong>
            {t(VehicleMaintenanceMessages.justifyOrSelect)}
            <strong className="strong">{t(VehicleMaintenanceMessages.maintenance).toLowerCase()}</strong>
          </div>
          <div className="vehicle-maintenance-form__justification">
            <Autocomplete
              fullWidth
              options={maintenanceOptions}
              onChange={(_, value) => setJustificationOption(value ?? "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="justificationOption"
                  label={t(VehicleMaintenanceMessages.selectMaintenance)}
                  margin="dense"
                  variant="outlined"
                />
              )}
            />
            <TextField
              label={t(VehicleMaintenanceMessages.justifyMaintenance)}
              margin="dense"
              variant="outlined"
              name="justificationField"
              fullWidth
              disabled={!justificationOption}
              multiline
              rows={3}
            />
          </div>
        </div>
        <Button
          className="maintenance_button"
          type="submit"
          disableRipple
          startIcon={<ManutencaoIcon />}
          variant="contained"
        >
          {t(VehicleMaintenanceMessages.maintenance)}
        </Button>
      </Form>
      <DialogConfirmAction
        open={openDialogConfirmMaintenance}
        onClose={() => setOpenDialogConfirmMaintenance(false)}
        title={t(VehicleMaintenanceMessages.maintenanceConfirmation)}
        actions={[
          { text: t(GlobalMessages.no).toUpperCase(),
            action: () => setOpenDialogConfirmMaintenance(false) },
          {
            text: t(GlobalMessages.yes).toUpperCase(),
            action: () => {
              handleChangeVehicleMaintenance(justification);
              setOpenDialogConfirmMaintenance(false);
            }
          }
        ]}
      >
        {t(VehicleMaintenanceMessages.maintenanceConfirmationMessage)}
      </DialogConfirmAction>
    </Styled.VehicleMaintenanceForm>
  );
};

export default VehicleMaintenanceForm;
