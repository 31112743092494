import React, { useCallback, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";

// region Imports - Libraries
import classNames from "classnames";
import * as dateFns from "date-fns";
import _ from "lodash";
// endregion Imports - Libraries
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import { Grid } from "@material-ui/core";
import DoneAllTwoToneIcon from "@material-ui/icons/DoneAllTwoTone";
import SearchIcon from "@material-ui/icons/Search";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  AlertMessages,
  AlertTypeMessages,
  DataTableMessages,
  GlobalMessages,
  AlertNoteMessages,
  VehiclesModuleMessages,
  VehicleTypesMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Shared
import { AlertTypes } from "@shared/constants/alert-types.enum";
import { Alert } from "@shared/interfaces/alert.interface";
import { Travel } from "@shared/interfaces/travel.interface";
// endregion Imports - Shared
// region Imports - Components
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import AlertDialogJustify from "@components/Alert/AlertDialogJustify";
import ButtonTable from "@components/Button/ButtonTable";
import Header from "@components/Header";
import QueryFilterAlertHistory from "@organisms/QueryFilterAlertHistory";
// endregion Imports - Components
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { LocationsFormState } from "@store/ducks/Locations/LocationsForm/locations-form.type";
// endregion Imports - Store
// region Imports - Styles
import { Container } from "./styles";
// endregion Imports - Styles

const AlertsHistory: React.FC = () => {

  /* region States */
  const [alerts, setAlerts] = useState<Alert[]>([] as Array<Alert>);

  const [selectedAlert, setSelectedAlert] = useState<Alert>({} as Alert);
  const [actionType, setActionType] = useState<LocationsFormState["type"]>("details");

  // Used to create export file name
  const [startDate, setStartDate] = useState<string>(dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00"));
  const [finishDate, setFinishDate] = useState<string>(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59"));

  const [loadMoreToggle, setLoadMoreToggle] = useState(false);
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);
  /* endregion States */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  /* endregion Hooks */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    { ref: ".alert-justify", callback: (rowData: Alert) => { setActionType("update"); setSelectedAlert(rowData); } },
    { ref: ".alert-justified", callback: (rowData: Alert) => { setActionType("details"); setSelectedAlert(rowData); } }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Veículo
      title: t(GlobalMessages.vehicle),
      data: (alert: Alert) => (alert?.vehicle?.code),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "vehicle.code"
    },
    { // Tipo de Veículo
      title: t(VehiclesModuleMessages.vehicleType),
      className: "print csv",
      data: (alert: Alert) => (t(VehicleTypesMessages[alert?.vehicle?.type?.id_vehicle_type ?? ""])),
      defaultContent: "",
      filterable: true,
      propertyName: "vehicle.type.id_vehicle_type"
    },
    { // Placa
      title: t(AlertMessages.licensePlate),
      data: (alert: Alert) => (alert?.vehicle?.license_plate),
      defaultContent: "",
      orderable: false,
      filterable: true,
      propertyName: "vehicle.license_plate"
    },
    { // Data Ocorrência
      title: t(AlertMessages.occurrenceDate),
      data: (alert: Alert) => alert?.occurrence_date,
      render: (data, type) => (type === "sort" ? data : (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm"))),
      filterable: false
    },
    { // Motorista
      title: t(GlobalMessages.driver),
      data: (alert: Alert & {travel: Travel}) => (alert?.travel?.driver ? alert.travel.driver.name : ""),
      filterable: true,
      propertyName: "travel.driver.name"
    },
    { // Alerta
      title: t(AlertMessages.alert),
      data: (alert: Alert) => (alert?.type?.description ? t(AlertTypeMessages[alert.type.id_alert_type]) : ""),
      filterable: true,
      propertyName: "type.description"
    },
    { // Detalhes
      title: t(AlertMessages.details),
      data: (alert: Alert) => {
        const alertType = alert?.type?.description;

        if (alertType === AlertTypes.EXCESSO_DE_VELOCIDADE) {
          const speed = Number.parseFloat(`${alert?.event?.speed ?? 0}`).toFixed(1);

          return `${speed} km/h`;
        }
        if (alertType === AlertTypes.PARADA_INDEVIDA || alertType === AlertTypes.DESCARREGAMENTO_INDEVIDO) {
          return `${utils.formatDateIfHave(
            utils.calcDataRange(
              alert?.event?.start_date ?? new Date(), alert?.event?.finish_date ?? new Date()
            ), "durationDescriptiveTime"
          )}<br>${alert?.event?.address || ""}
                    `;
        }

        return "";
      },
      filterable: false
    },
    { // Data Confirmação
      title: t(AlertMessages.confirmationDate),
      data: (alert: Alert) => alert?.confirmation_date,
      render: (data, type) => (type === "sort" ? data : (data ? dateFns.format(new Date(data), "dd/MM/yyyy HH:mm") : "")),
      filterable: true,
      propertyName: "confirmation_date"
    },
    { // Autorizador
      title: t(AlertMessages.authorizer),
      data: (alert: Alert) => alert?.user?.name ?? "",
      filterable: true,
      propertyName: "user.name"
    },
    { // Justification
      title: t(AlertMessages.justification),
      data: "justification",
      filterable: false
    },
    { // Observação
      title: t(AlertMessages.observation),
      data: (alert: Alert) => (alert?.note && !_.isEmpty(t(AlertNoteMessages[alert.note]))
        ? t(AlertNoteMessages[alert.note])
        : alert?.note ?? ""),
      filterable: true,
      propertyName: "note"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: (data) => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable
              aria-disabled
              className={classNames(
                "action-button", data.confirmation_date ? "alert-justified" : "alert-justify"
              )}
            >
              {data.confirmation_date ? <SearchIcon /> : <DoneAllTwoToneIcon />}
            </ButtonTable>
          </Grid>
        </Grid>
      ),
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_alertas_${startDate}__${finishDate}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.moreRecords),
      callback: () => {
        setLoadMoreToggle((prevState) => !prevState);
      },
      key: "moreRecords"
    }
  ];
  /* endregion Constants */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: Alert[],
    loadMore: boolean,
    startDate: string,
    finishDate: string
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    if (loadMore) setAlerts([...alerts, ...returnedData]);
    else setAlerts(returnedData);

    setStartDate(startDate);
    setFinishDate(finishDate);
  };

  /** After close justify modal, query new value, if changed update in alerts state
   * REVIEW for best performance, need to create a new callback on JustifyModal to get updated value
   */
  const closeCallbackJustifyAlert = useCallback(async () => {

    const FreadAlerts = async () => (await api.post(`alerts/read/${selectedAlert.id_alert}`)).data;

    try {

      const data = await FreadAlerts();

      if (data.status === "success") {
        if (data.result) {

          const newState: Alert[] = [];
          const newAlert: Alert = data?.result?.[0] ?? {};
          let updated = false;

          // Search for selected alert, and update if changed
          for (let i = 0; i < alerts.length; i++) {

            const element: Alert = alerts[i];

            // Search for selectedAlert
            if (element.id_alert === newAlert?.id_alert) {
              // if alert has updated
              if (element.confirmation_date !== newAlert.confirmation_date) {
                updated = true;
                newState.push(newAlert);
              }
            } else {
              newState.push(element);
            }
          }

          // Only update alerts state if selected alert has changed (prevent reload table when just close modal)
          if (updated) {
            setAlerts(newState);
          }
        }
      }

    } catch (err) {
      console.log(err);
    }

    setSelectedAlert({} as Alert);
  }, [selectedAlert, alerts, setAlerts]);
  /* endregion Functions */

  return (
    <>
      <Header title={t(AlertMessages.alertsHistoryTitle)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <NewDataTable
          title={t(AlertMessages.alertsHistoryTitle)}
          filters
          data={alerts}
          columns={dataTableColumns}
          actions={dataTableActions}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
      </Container>
      {!_.isEmpty(selectedAlert) && (
        <AlertDialogJustify selectedAlert={selectedAlert} actionType={actionType} closeCallback={closeCallbackJustifyAlert} />
      )}
      <QueryFilterAlertHistory
        open={openFilterFields}
        onClose={() => setOpenFilterFields(false)}
        onFilter={handleApplyFilter}
        loadMoreToggle={loadMoreToggle}
      />
    </>
  );
};

export default AlertsHistory;
