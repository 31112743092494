import styled from "styled-components";
import { windowWidth } from "@styles/global";

export const FileInputContainer = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error }) => (error ? "#d32f2f" : "#CCCCCC")};
  border-radius: 4px;
  width: 100%;

  #buttonFile {
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
    line-height: 1.1rem;
    font-size: 13px;
    text-align: center;
    width: 115px;
    min-width: 115px;
    min-height: 32px;
  }

  .file-name {
    font-size: 14px;
    color: ${({ error }) => (error ? "#d32f2f" : "#666666")};
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${windowWidth.mobile.large}) {
    #buttonFile {
      font-size: 12px;
      width: 105px;
      min-width: 105px;
    }
  }
`;

export const FileInputHelperText = styled.p<{ error: boolean }>`
  margin: 0;
  text-align: right;
  color: ${({ error }) => (error ? "#d32f2f" : "unset")};
`;
