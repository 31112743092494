import { ParamsMessages, TranslationParamsMessages } from "../interfaces";

export const messages: TranslationParamsMessages = {
  [ParamsMessages.readParamsSuccess]: "Parameter(s) returned successfully",
  [ParamsMessages.noParamsFound]: "No parameter registered",
  [ParamsMessages.readParamsError]: "Failed to fetch parameter(s)",
  [ParamsMessages.updateParamsSuccess]: "Parameter(s) updated successfully",
  [ParamsMessages.updateParamsError]: "Failed to update parameters",
  [ParamsMessages.insertDataBlockSuccess]: "Block date successfully set",
  [ParamsMessages.insertDataBlockError]: "Failed to enter lock parameter"
};
