/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { RequestType } from "../types/request_type.enum";
import { Token } from "./reg_tokens.entity";
import { TpePublicEndpoint } from "./tpe_public_endpoints_types.entity";

@Entity("log_integrations")
export class LogIntegration {

  @PrimaryGeneratedColumn("uuid")
  id_log_integration?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @Column({ type: "timestamp", nullable: false })
  request_date?: Date;

  @Column({ type: "json", nullable: false })
  request_object?: string;

  @Column({ type: "varchar", nullable: true })
  request_ip?: string;

  @Column({ type: "varchar", nullable: true })
  request_latitude?: string;

  @Column({ type: "varchar", nullable: true })
  request_longitude?: string;

  @Column({ type: "enum", enum: RequestType, nullable: false })
  request_type?: RequestType;

  @Column({ type: "varchar", nullable: true })
  code_error?: string;

  @ManyToOne(() => Token, (request_token) => request_token.log_integrations, { nullable: false })
  @JoinColumn({ name: "id_token_fk" })
  request_token?: Token;

  @ManyToOne(() => TpePublicEndpoint, (type) => type.log_integrations, { nullable: false })
  @JoinColumn({ name: "id_public_endpoint_type_fk" })
  public_endpoint_type?: TpePublicEndpoint;

}
