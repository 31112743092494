import styled from "styled-components";

export const Container = styled.span`
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }

  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;
