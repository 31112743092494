/* eslint-disable @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require */

import {
  TranslationAggregateTruckTravelDataMessages,
  TranslationAlertMessages,
  TranslationAverageSpeedMessages,
  TranslationGlobalMessages,
  TranslationYupMessages,
  TranslationMenuMessages,
  TranslationFilterMessages,
  TranslationMapMessages,
  TranslationContactUsMessages,
  TranslationServiceOrderMessages,
  TranslationLoginMessages,
  TranslationTravelSwapMessages,
  TranslationVehicleAndTravelDetailsMessages,
  TranslationVehicleStatusMessages,
  TranslationTravelDataMessages,
  TranslationTimelineVehicleStatesMessages,
  TranslationTimelineVehiclesItemMessages,
  TranslationVehicleBlockMessages,
  TranslationVehiclesStatesMessages,
  TranslationManagementMessages,
  TranslationDataTableMessages,
  TranslationToastMessages,
  TranslationDriverModuleMessages,
  TranslationPumpTimelineMessages,
  TranslationSettingsMessages,
  TranslationHardwareModuleMessage,
  TranslateConfigCommandsMessages,
  TranslationMailMessages,
  TranslationCoordsMessages,
  TranslationLocationsModuleMessages,
  TranslationRegionalsModuleMessages,
  TranslationParamsMessages,
  TranslationSIMCardsModuleMessages,
  TranslationSurveyMessages,
  TranslationTravelsModuleMessages,
  TranslationUsersModuleMessages,
  TranslationVehiclesModuleMessages,
  TranslationCoordinateAnalysisMessages,
  TranslationTravelHistoryMessages,
  TranslationVehiclesHistoryMessages,
  TranslationEventsMessages,
  TranslationVehicleMaintenanceMessages,
  TranslationTravelAlertFullSpeedMessage,
  TranslationHelpMessages,
  TranslationDualListboxMessages,
  TranslationGroupsModuleMessages,
  TranslationScheduledReportMessages,
  TranslationPumpProgrammingMessages,
  TranslationPumpProgrammingImportsModuleMessages,
  TranslationZonesModuleMessages,
  TranslationZonesValidityMessages
} from "./interfaces";

interface TranslationData extends
  TranslationAlertMessages,
  TranslationAverageSpeedMessages,
  TranslationGlobalMessages,
  TranslationAggregateTruckTravelDataMessages,
  TranslationYupMessages,
  TranslationMenuMessages,
  TranslationFilterMessages,
  TranslationMapMessages,
  TranslationContactUsMessages,
  TranslationServiceOrderMessages,
  TranslationLoginMessages,
  TranslationTravelSwapMessages,
  TranslationVehicleAndTravelDetailsMessages,
  TranslationVehicleStatusMessages,
  TranslationTravelDataMessages,
  TranslationTimelineVehicleStatesMessages,
  TranslationTimelineVehiclesItemMessages,
  TranslationVehicleBlockMessages,
  TranslationVehiclesStatesMessages,
  TranslationManagementMessages,
  TranslationDataTableMessages,
  TranslationToastMessages,
  TranslationDriverModuleMessages,
  TranslationPumpTimelineMessages,
  TranslationSettingsMessages,
  TranslationHardwareModuleMessage,
  TranslateConfigCommandsMessages,
  TranslationMailMessages,
  TranslationCoordsMessages,
  TranslationLocationsModuleMessages,
  TranslationRegionalsModuleMessages,
  TranslationGroupsModuleMessages,
  TranslationParamsMessages,
  TranslationSIMCardsModuleMessages,
  TranslationSurveyMessages,
  TranslationTravelsModuleMessages,
  TranslationUsersModuleMessages,
  TranslationVehiclesModuleMessages,
  TranslationCoordinateAnalysisMessages,
  TranslationTravelHistoryMessages,
  TranslationVehiclesHistoryMessages,
  TranslationEventsMessages,
  TranslationVehicleMaintenanceMessages,
  TranslationTravelAlertFullSpeedMessage,
  TranslationHelpMessages,
  TranslationDualListboxMessages,
  TranslationScheduledReportMessages,
  TranslationPumpProgrammingMessages,
  TranslationPumpProgrammingImportsModuleMessages,
  TranslationZonesModuleMessages,
  TranslationZonesValidityMessages
{}

export interface LanguageData {
  translation: TranslationData;
}

export type TLanguages = "pt-BR" | "en-US" | "es";

interface ILanguage {
  language: TLanguages;
  description: string;
}

export const languages: ILanguage[] = [
  {
    language: "en-US",
    description: "English"
  },
  {
    language: "pt-BR",
    description: "Português"
  },
  {
    language: "es",
    description: "Español"
  }
];

const getMessages = (language: TLanguages, fileName: string) => {
  const data = require(`./${language}/${fileName}`);

  return data.messages;
};

export function getLanguageData(language: TLanguages): LanguageData {
  const alertMessages = getMessages(
    language,
    "alertMessages"
  ) as TranslationAlertMessages;
  const averageSpeedMessages = getMessages(
    language,
    "averageSpeedMessages"
  ) as TranslationAverageSpeedMessages;
  const globalMessages = getMessages(
    language,
    "globalMessages"
  ) as TranslationGlobalMessages;
  const aggregateTruckTravelDataMessages = getMessages(
    language,
    "aggregateTruckTravelDataMessages"
  ) as TranslationAggregateTruckTravelDataMessages;
  const yupMessages = getMessages(
    language,
    "yupMessages"
  ) as TranslationYupMessages;
  const menuMessages = getMessages(
    language,
    "menuMessages"
  ) as TranslationMenuMessages;
  const filterMessages = getMessages(
    language,
    "filterMessages"
  ) as TranslationFilterMessages;
  const mapMessages = getMessages(
    language,
    "mapMessages"
  ) as TranslationMapMessages;
  const contactUsMessages = getMessages(
    language,
    "contactUsMessages"
  ) as TranslationContactUsMessages;
  const serviceOrderMessages = getMessages(
    language,
    "serviceOrderMessages"
  ) as TranslationServiceOrderMessages;
  const loginMessages = getMessages(
    language,
    "loginMessages"
  ) as TranslationLoginMessages;
  const travelSwapMessages = getMessages(
    language,
    "travelSwapMessages"
  ) as TranslationTravelSwapMessages;
  const vehicleAndTravelDetailsMessages = getMessages(
    language,
    "vehicleAndTravelDetailsMessages"
  ) as TranslationVehicleAndTravelDetailsMessages;
  const vehicleStatusMessages = getMessages(
    language,
    "vehicleStatusMessages"
  ) as TranslationVehicleStatusMessages;
  const travelDataMessages = getMessages(
    language,
    "travelDataMessages"
  ) as TranslationTravelDataMessages;
  const timelineVehicleStatesMessages = getMessages(
    language,
    "timelineVehicleStatesMessages"
  ) as TranslationTimelineVehicleStatesMessages;
  const timelineVehiclesItemMessages = getMessages(
    language,
    "timelineVehiclesItemMessages"
  ) as TranslationTimelineVehiclesItemMessages;
  const vehicleBlockMessages = getMessages(
    language,
    "vehicleBlockMessages"
  ) as TranslationVehicleBlockMessages;
  const vehiclesStatesMessages = getMessages(
    language,
    "vehiclesStatesMessages"
  ) as TranslationVehiclesStatesMessages;
  const managementMessages = getMessages(
    language,
    "managementMessages"
  ) as TranslationManagementMessages;
  const dataTableMessages = getMessages(
    language,
    "dataTableMessages"
  ) as TranslationDataTableMessages;
  const toastMessages = getMessages(
    language,
    "toastMessages"
  ) as TranslationToastMessages;
  const driverModuleMessages = getMessages(
    language,
    "driverModuleMessages"
  ) as TranslationDriverModuleMessages;
  const pumpTimelineMessages = getMessages(
    language,
    "pumpTimelineMessages"
  ) as TranslationPumpTimelineMessages;
  const settingsMessages = getMessages(
    language,
    "settingsMessages"
  ) as TranslationSettingsMessages;
  const hardwareModuleMessages = getMessages(
    language,
    "hardwareModuleMessages"
  ) as TranslationHardwareModuleMessage;
  const configCommandsMessages = getMessages(
    language,
    "configCommandsMessages"
  ) as TranslateConfigCommandsMessages;
  const mailMessages = getMessages(
    language,
    "mailMessages"
  ) as TranslationMailMessages;
  const coordsMessages = getMessages(
    language,
    "coordsMessages"
  ) as TranslationCoordsMessages;
  const locationsModuleMessages = getMessages(
    language,
    "locationsModuleMessages"
  ) as TranslationLocationsModuleMessages;
  const regionalsModuleMessages = getMessages(
    language,
    "regionalsModuleMessages"
  ) as TranslationRegionalsModuleMessages;
  const groupsModuleMessages = getMessages(
    language,
    "groupsModuleMessages"
  ) as TranslationGroupsModuleMessages;
  const paramsMessages = getMessages(
    language,
    "paramsMessages"
  ) as TranslationParamsMessages;
  const simCardsModuleMessages = getMessages(
    language,
    "simcardsModuleMessages"
  ) as TranslationSIMCardsModuleMessages;
  const surveyMessages = getMessages(
    language,
    "surveyMessages"
  ) as TranslationSurveyMessages;
  const travelsModuleMessages = getMessages(
    language,
    "travelsModuleMessages"
  ) as TranslationTravelsModuleMessages;
  const usersModuleMessages = getMessages(
    language,
    "usersModuleMessages"
  ) as TranslationUsersModuleMessages;
  const vehiclesModuleMessages = getMessages(
    language,
    "vehiclesModuleMessages"
  ) as TranslationVehiclesModuleMessages;
  const coordinateAnalysisMessages = getMessages(
    language,
    "coordinateAnalysisMessages"
  ) as TranslationCoordinateAnalysisMessages;
  const travelHistoryMessages = getMessages(
    language,
    "travelHistoryMessages"
  ) as TranslationTravelHistoryMessages;
  const vehiclesHistoryMessages = getMessages(
    language,
    "vehiclesHistoryMessages"
  ) as TranslationVehiclesHistoryMessages;
  const eventsMessages = getMessages(
    language,
    "eventsMessages"
  ) as TranslationEventsMessages;
  const vehicleMaintenanceMessages = getMessages(
    language,
    "vehicleMaintenanceMessages"
  ) as TranslationVehicleMaintenanceMessages;
  const travelAlertFullSpeedMessage = getMessages(
    language,
    "travelAlertFullSpeedMessage"
  ) as TranslationTravelAlertFullSpeedMessage;
  const helpMessages = getMessages(
    language,
    "helpMessages"
  ) as TranslationHelpMessages;
  const dualListboxMessages = getMessages(
    language,
    "dualListboxMessages"
  ) as TranslationDualListboxMessages;
  const scheduledReportMessages = getMessages(
    language,
    "scheduledReportMessages"
  ) as TranslationScheduledReportMessages;
  const pumpProgrammingMessages = getMessages(
    language,
    "pumpProgrammingMessages"
  ) as TranslationPumpProgrammingMessages;
  const pumpProgrammingImportsModuleMessages = getMessages(
    language,
    "pumpProgrammingImportsModuleMessages"
  ) as TranslationPumpProgrammingImportsModuleMessages;
  const zonesModuleMessages = getMessages(
    language,
    "zonesModuleMessages"
  ) as TranslationZonesModuleMessages;
  const zonesValidityMessages = getMessages(
    language,
    "zonesValidityMessages"
  ) as TranslationZonesValidityMessages;

  return {
    translation: {
      ...alertMessages,
      ...averageSpeedMessages,
      ...globalMessages,
      ...aggregateTruckTravelDataMessages,
      ...yupMessages,
      ...menuMessages,
      ...filterMessages,
      ...mapMessages,
      ...contactUsMessages,
      ...serviceOrderMessages,
      ...loginMessages,
      ...travelSwapMessages,
      ...vehicleAndTravelDetailsMessages,
      ...vehicleStatusMessages,
      ...travelDataMessages,
      ...timelineVehicleStatesMessages,
      ...timelineVehiclesItemMessages,
      ...vehicleBlockMessages,
      ...vehiclesStatesMessages,
      ...managementMessages,
      ...dataTableMessages,
      ...toastMessages,
      ...driverModuleMessages,
      ...pumpTimelineMessages,
      ...settingsMessages,
      ...hardwareModuleMessages,
      ...configCommandsMessages,
      ...mailMessages,
      ...coordsMessages,
      ...locationsModuleMessages,
      ...regionalsModuleMessages,
      ...groupsModuleMessages,
      ...paramsMessages,
      ...simCardsModuleMessages,
      ...surveyMessages,
      ...travelsModuleMessages,
      ...usersModuleMessages,
      ...vehiclesModuleMessages,
      ...coordinateAnalysisMessages,
      ...travelHistoryMessages,
      ...vehiclesHistoryMessages,
      ...eventsMessages,
      ...vehicleMaintenanceMessages,
      ...travelAlertFullSpeedMessage,
      ...helpMessages,
      ...dualListboxMessages,
      ...scheduledReportMessages,
      ...pumpProgrammingMessages,
      ...pumpProgrammingImportsModuleMessages,
      ...zonesModuleMessages,
      ...zonesValidityMessages
    }
  };
}
