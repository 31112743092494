export default {
  title: "light",
  colors: {
    primary: "#002951",
    secondary: "#597aa7",
    background: "#d9d9d9",
    scrollBar: "#e0e0e0",
    gray: "#545454",
    text: "black",
    error: "#e74c3c"
  }
};
