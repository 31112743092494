import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0;
`;
const Line = styled.div`
  flex: 1;
  height: 1px;
  opacity: 0.2;
  background-color: ${({ theme }) => theme.colors.gray};
`;
const Text = styled.span`
  padding: 0 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1rem;
`;

export { Container, Line, Text };
