/* eslint-disable import/prefer-default-export */
import { TimelineVehicleStatesMessages, TranslationTimelineVehicleStatesMessages } from "../interfaces";

export const messages: TranslationTimelineVehicleStatesMessages = {
  [TimelineVehicleStatesMessages.statesTitle]: "Estados",
  [TimelineVehicleStatesMessages.manometer]: "Manômetro",
  [TimelineVehicleStatesMessages.blockedVehicle]: "Veículo bloqueado",
  [TimelineVehicleStatesMessages.blockingDate]: "Momento do bloqueio",
  [TimelineVehicleStatesMessages.postUnloadIdleTime]: "Ociosidade pós-descarga"
};
