import {
  GroupsModuleMessages,
  TranslationGroupsModuleMessages
} from "../interfaces";

export const messages: TranslationGroupsModuleMessages = {

  [GroupsModuleMessages.group]: "Grupo",
  [GroupsModuleMessages.name]: "Nome",
  [GroupsModuleMessages.noNameRegistered]: "Sem nome cadastrado",
  [GroupsModuleMessages.active]: "Ativo",
  [GroupsModuleMessages.inactive]: "Inativo",
  [GroupsModuleMessages.externalId]: "ID Externo",
  [GroupsModuleMessages.registrationDate]: "Data de registro",
  [GroupsModuleMessages.updatedDate]: "Data de Atualização",
  [GroupsModuleMessages.code]: "Código",
  [GroupsModuleMessages.deleteConfirmationText]: "Tem certeza de que deseja excluir o grupo?",
  [GroupsModuleMessages.deleteRegionalError]: "Falha ao excluir grupo",

  [GroupsModuleMessages.deleteGroupSuccess]: "Grupo excluído com sucesso",
  [GroupsModuleMessages.updateGroupSuccess]: "Grupo atualizado com sucesso",
  [GroupsModuleMessages.updateGroupError]: "Não foi possivel atualizar o grupo",
  [GroupsModuleMessages.readGroupSuccess]: "Grupo(s) retornardo(s) com sucesso",
  [GroupsModuleMessages.noGroupFound]: "Nenhum grupo encontrado",
  [GroupsModuleMessages.createGroupSuccess]: "Grupo criado com sucesso",
  [GroupsModuleMessages.createGroupError]: "Não foi possivel criar o grupo"

};
