import { CoordinateAnalysisMessages, TranslationCoordinateAnalysisMessages } from "../interfaces";

export const messages: TranslationCoordinateAnalysisMessages = {
  [CoordinateAnalysisMessages.command]: "Command",
  [CoordinateAnalysisMessages.identifier]: "Identifier",
  [CoordinateAnalysisMessages.model]: "Model",
  [CoordinateAnalysisMessages.softwareVersion]: "Software version",
  [CoordinateAnalysisMessages.date]: "Date",
  [CoordinateAnalysisMessages.time]: "Time",
  [CoordinateAnalysisMessages.locationCode]: "Location code",
  [CoordinateAnalysisMessages.latitude]: "Latitude",
  [CoordinateAnalysisMessages.longitude]: "Longitude",
  [CoordinateAnalysisMessages.speed]: "Speed",
  [CoordinateAnalysisMessages.inclination]: "Course",
  [CoordinateAnalysisMessages.activeSatellites]: "Active satellites",
  [CoordinateAnalysisMessages.validPosition]: "Valid position",
  [CoordinateAnalysisMessages.odometer]: "Odometer",
  [CoordinateAnalysisMessages.batteryVoltage]: "Battery voltage",
  [CoordinateAnalysisMessages.inAndOutInputs]: "In and out inputs",
  [CoordinateAnalysisMessages.activeMode]: "Active mode",
  [CoordinateAnalysisMessages.numberOfMessages]: "Number of messages",
  [CoordinateAnalysisMessages.ignition]: "Ignition",
  [CoordinateAnalysisMessages.hourMeter]: "Hour meter",
  [CoordinateAnalysisMessages.backupBatteryVoltage]: "Backup battery voltage",
  [CoordinateAnalysisMessages.messageType]: "Message type",
  [CoordinateAnalysisMessages.mixerRPM]: "Mixer RPM",
  [CoordinateAnalysisMessages.mixerBatteryVoltage]: "Mixer sensor battery voltage",
  [CoordinateAnalysisMessages.driverIdentifier]: "Driver identifier",
  [CoordinateAnalysisMessages.registrationStatus]: "Registration status",
  [CoordinateAnalysisMessages.serviceIdentification]: "Service identification",
  [CoordinateAnalysisMessages.countryCode]: "Country code",
  [CoordinateAnalysisMessages.networkCode]: "Network code",
  [CoordinateAnalysisMessages.receptionLevel]: "Reception level",
  [CoordinateAnalysisMessages.locationAreaCode]: "Location area code",
  [CoordinateAnalysisMessages.temporalInfo]: "Temporal info",
  [CoordinateAnalysisMessages.GPSStatus]: "GPS status",
  [CoordinateAnalysisMessages.vehicleIdentifier]: "Vehicle identifier",
  [CoordinateAnalysisMessages.ignitionKey]: "Ignition key",
  [CoordinateAnalysisMessages.CANOdometer]: "CAN odometer",
  [CoordinateAnalysisMessages.totalFuelConsumption]: "Total fuel consumption (liters)",
  [CoordinateAnalysisMessages.CANSpeed]: "CAN speed",
  [CoordinateAnalysisMessages.engineRPM]: "Engine RPM",
  [CoordinateAnalysisMessages.engineCoolantTemperature]: "Engine coolant temperature",
  [CoordinateAnalysisMessages.fuelConsumption]: "Fuel consumption",
  [CoordinateAnalysisMessages.fuelLevel]: "Fuel level",
  [CoordinateAnalysisMessages.CANHourMeter]: "CAN hour meter",
  [CoordinateAnalysisMessages.drivingTime]: "Driving time",
  [CoordinateAnalysisMessages.fuelConsumptionInIdle]: "Fuel consumption in idle (liters)",
  [CoordinateAnalysisMessages.vehicleWeight]: "Vehicle weight",
  [CoordinateAnalysisMessages.sensorStatus]: "Sensor status",
  [CoordinateAnalysisMessages.drumInclination]: "Drum inclination",
  [CoordinateAnalysisMessages.totalWaterAdded]: "Total water added",
  [CoordinateAnalysisMessages.drumPressure]: "Drum pressure",
  [CoordinateAnalysisMessages.drumTemperature]: "Drum temperature",
  [CoordinateAnalysisMessages.ignitionOn]: "On",
  [CoordinateAnalysisMessages.ignitionOff]: "Off",

  [CoordinateAnalysisMessages.coordinateAnalysisTitle]: "Coordinate analysis",
  [CoordinateAnalysisMessages.mapTitle]: "Map",
  [CoordinateAnalysisMessages.dataTableTitle]: "Data table",

  [CoordinateAnalysisMessages.alerts]: "Alerts",
  [CoordinateAnalysisMessages.states]: "States",
  [CoordinateAnalysisMessages.noAlertsForThisPeriod]: "No alerts for this period",
  [CoordinateAnalysisMessages.commands]: "Commands",
  [CoordinateAnalysisMessages.noCommandsForThisPeriod]: "No commands for this period",
  [CoordinateAnalysisMessages.noStatesForThisPeriod]: "No states for this period",
  [CoordinateAnalysisMessages.showMarker]: "Show marker",
  [CoordinateAnalysisMessages.travel]: "Travel",
  [CoordinateAnalysisMessages.travelHelperText]: "Select a vehicle and a period of less than 3 days",
  [CoordinateAnalysisMessages.startDate]: "Start date",
  [CoordinateAnalysisMessages.finishDate]: "Finish date",
  [CoordinateAnalysisMessages.loadingContent]: "Analyzing routes..."
};
