import React from "react";
import { ReactSVG } from "react-svg";

// region Libraries
import { Divider } from "@material-ui/core";
// endregion Libraries
// region Interfaces
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
// endregion Interfaces
// region Utils
import utils from "@utils/useful-functions";
// endregion Utils
// region Assets
import { Carregando, DescarregandoAgregado } from "@libraries/icons/index";
// endregion Assets
// region Languages
import useTranslation from "src/translations/useTranslation";
import { AggregateTruckTravelDataMessages, GlobalMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Styles
import { Container, ContainerTravelDataListItem } from "./styles";
// endregion Styles
// region Types
type AggregateTrucksTravelDataListItemProps = { title: string, content: string }
type AggregateTrucksTravelDataProps = { travel: Travel, vehicle: Vehicle };
// endregion Types

const AggregateTrucksTravelDataListItem = React.memo<AggregateTrucksTravelDataListItemProps>((
  { content, title }
) => (
  <ContainerTravelDataListItem className="item">
    <div className="title">{title}</div>
    <div className="content">{content}</div>
  </ContainerTravelDataListItem>
));

const AggregateTrucksTravelData: React.FC<AggregateTrucksTravelDataProps> = ({ travel, vehicle }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="block locations">
        <div className="box">
          <div className="write">
            <div className="title">{t(AggregateTruckTravelDataMessages.origin)}</div>
            <div className="text">
              {travel?.origin?.name ?? ""}
              <br />
              {`
                ${travel?.origin?.address}, ${travel?.origin?.number ?? ""} - ${travel?.origin?.district ?? ""}
                ${(travel.origin?.complement) ? ` - ${travel.origin?.complement}` : ""} | ${travel?.origin?.county} - ${travel?.origin?.uf}
              `}
            </div>
          </div>
          <div className="icon">
            <div className="aux">
              {vehicle.states.length > 0 && utils.getStateIcon(vehicle.states[0].status.id_vehicle_state_type)}
            </div>
          </div>
        </div>
        {travel.destination && (
        <div className="box">
          <div className="write">
            <div className="title">{t(AggregateTruckTravelDataMessages.loadingLocation)}</div>
            <div className="text">
              {travel.destination.name ?? t(GlobalMessages.noNameRegistered)}
              <br />
              {`
                ${travel.destination.address}, ${travel.destination.number ?? ""} - ${travel.destination.district ?? ""}
                ${travel.destination?.complement ? ` - ${travel.destination?.complement}` : ""} |
                ${travel.destination.county} - ${travel.destination.uf}
              `}
            </div>
          </div>
          <div className="icon">
            <div className="aux">
              <ReactSVG src={Carregando} />
            </div>
          </div>
        </div>
        )}
        {travel.end && (
        <div className="box">
          <div className="write">
            <div className="title">{t(AggregateTruckTravelDataMessages.unloadingLocation)}</div>
            <div className="text">
              {travel.end.name ?? ""}
              <br />
              {`
                ${travel.end.address}, ${travel.end.number ?? ""} -
                ${travel.end.district ?? ""}${travel.end?.complement ? ` - ${travel.end?.complement}` : ""} |
                ${travel.end.county} - ${travel.end.uf}
              `}
            </div>
          </div>
          <div className="icon">
            <div className="aux">
              <ReactSVG src={DescarregandoAgregado} />
            </div>
          </div>
        </div>
        )}
        <Divider />
        <div className="extra-data">
          <div className="docs-data">
            <Divider />
            {travel?.service_order?.product_document && (
            <div className="box">
              <div className="write">
                <div className="title">{t(AggregateTruckTravelDataMessages.productDocument)}</div>
                <div className="text">{travel.service_order.product_document || ""}</div>
              </div>
            </div>
          )}
          </div>
          <div className="travel-date">
            <div className="box">
              <div className="write">
                <div className="title">{travel?.service_order?.expected_start_date
                  ? t(AggregateTruckTravelDataMessages.serviceOrderDate)
                  : t(AggregateTruckTravelDataMessages.travelStartDate)}
                </div>
                <div className="text">
                  {travel?.service_order?.expected_start_date
                    ? utils.formatDateIfHave(travel.service_order.expected_start_date, "fullDate")
                    : utils.formatDateIfHave(travel.registration_date, "fullDate")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="travel-data">
          {/* eslint-disable */}
        {travel.document_date &&
          <AggregateTrucksTravelDataListItem
          title={t(AggregateTruckTravelDataMessages.travelDocumentDate)}
          content={utils.formatDateIfHave(travel.document_date, "fullDate") as string}
        />
        }
        {travel?.service_order?.quantity && travel?.service_order?.unit && <AggregateTrucksTravelDataListItem
          title={t(AggregateTruckTravelDataMessages.quantity)}
          content={`${travel?.service_order.quantity} ${utils.getLabelAccordingUnitType(travel?.service_order.unit)}`}
        />}
        {/* eslint-enable */}
        </div>
      </div>
    </Container>
  );
};

export default AggregateTrucksTravelData;
