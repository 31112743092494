import {
  PumpProgrammingImportsModuleMessages,
  TranslationPumpProgrammingImportsModuleMessages
} from "../interfaces/pumpProgrammingImportsModuleMessages";

export const messages: TranslationPumpProgrammingImportsModuleMessages = {
  [PumpProgrammingImportsModuleMessages.title]: "Importaciones de programación de bombas",

  [PumpProgrammingImportsModuleMessages.newRegister]: "Nueva importación de programaciónes",

  [PumpProgrammingImportsModuleMessages.importingResult]: "Resultado de la importación",

  [PumpProgrammingImportsModuleMessages.columnDescription]: "Descripción",
  [PumpProgrammingImportsModuleMessages.columnStatus]: "Estado",
  [PumpProgrammingImportsModuleMessages.columnRegistrationDate]: "Fecha de registro",
  [PumpProgrammingImportsModuleMessages.columnProcessingDate]: "Fecha de procesamiento",

  [PumpProgrammingImportsModuleMessages.readSuccess]: "Las importaciones de programación de bombas se devolvieron con éxito",
  [PumpProgrammingImportsModuleMessages.readError]: "Error al leer las programaciones de bomba",
  [PumpProgrammingImportsModuleMessages.createSuccess]: "La importación de programación de bomba se creó con éxito",
  [PumpProgrammingImportsModuleMessages.createError]: "Error al crear la importación de programación de bomba",
  [PumpProgrammingImportsModuleMessages.cancelSuccess]: "La cancelación de la importación de programación de bomba se realizó con éxito",
  [PumpProgrammingImportsModuleMessages.cancelError]: "Error al cancelar la importación de programación de bomba",
  [PumpProgrammingImportsModuleMessages.pumpProgrammingImportNotFound]: "No se encontró la importación de programación de bomba",
  [PumpProgrammingImportsModuleMessages.noPumpProgrammingImports]: "No hay importaciones de programación de bombas",
  [PumpProgrammingImportsModuleMessages.notFound]: "no encontrado en el sistema",
  [PumpProgrammingImportsModuleMessages.insertedRecords]: "Registros insertados",
  [PumpProgrammingImportsModuleMessages.updatedRecords]: "Registros actualizados"
};
