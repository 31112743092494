import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { PumpProgrammingHistory } from "./hty_pump_programmings.entity";
import { ReportStatusType } from "../types/report_status_type.enum";
import { User } from "./reg_users.entity";

@Entity("reg_pump_programming_imports")
export class PumpProgrammingImport {

  @PrimaryGeneratedColumn("uuid")
    id_pump_programming_import?: string;

  @Column({ type: "varchar", nullable: false, length: 30 })
    description?: string;

  @Column({ type: "varchar", nullable: false, length: 25 })
    language?: string;

  @Column({ type: "varchar", nullable: false, length: 2048 })
    file_path?: string;

  @Column({ type: "enum", enum: ReportStatusType, nullable: false, default: ReportStatusType.IN_QUEUE })
    status?: ReportStatusType;

  @Column({ type: "varchar", nullable: false, length: 2048 })
    message_result?: string;

  @Column({ type: "timestamp", nullable: true })
    processing_date?: Date;

  @Column({ type: "integer", nullable: true })
    processing_time?: number;

  @ManyToOne(() => User, (user) => user.pumpProgrammingImports, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => PumpProgrammingHistory, (pumpProgrammingHistory) => pumpProgrammingHistory.user)
    pumpProgrammingHistoryList?: PumpProgrammingHistory[];

}
