/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinTable, ManyToMany, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { RoleClaim } from "./reg_role_claims.entity";
import { User } from "./reg_users.entity";

@Entity("reg_roles")
export class Role {

  @PrimaryGeneratedColumn("uuid")
    id_role?: string;

  @Column({ type: "varchar", nullable: false })
    name?: string;

  @Column({ type: "varchar", nullable: true })
    description?: string;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToMany(() => User, (users) => users.roles)
  @JoinTable({
    name: "rel_users_roles",
    joinColumn: {
      name: "id_role_fk",
      referencedColumnName: "id_role"
    },
    inverseJoinColumn: {
      name: "id_user_fk",
      referencedColumnName: "id_user"
    }
  })
    users?: User[];

  @OneToMany(() => RoleClaim, (roleClaim) => roleClaim.role)
    claims?: RoleClaim[];

}
