import { AverageSpeedMessages, TranslationAverageSpeedMessages } from "../interfaces"

export const messages: TranslationAverageSpeedMessages = {
  [AverageSpeedMessages.averageSpeedHistoryTitle]: "Speed History",
  [AverageSpeedMessages.averageSpeedByDriver]: "Average Speed by Driver",
  [AverageSpeedMessages.averageSpeedByVehicle]: "Average Speed by Vehicle",
  [AverageSpeedMessages.startDate]: "Start Date",
  [AverageSpeedMessages.finishDate]: "Finish Date",
  [AverageSpeedMessages.distance]: "Distance",
  [AverageSpeedMessages.averageSpeedColumn]: "Average Speed",
  [AverageSpeedMessages.Vehicles]: "Vehicle",
  [AverageSpeedMessages.Drivers]: "Driver",
  [AverageSpeedMessages.VehicleType]: "Vehicles of the type",

  [AverageSpeedMessages.noAverageFound]: "No averages found",
  [AverageSpeedMessages.readAverageSuccess]: "Average read successfully",
  [AverageSpeedMessages.readAverageError]: "Error reading average",
}
