import { DualListboxMessages, TranslationDualListboxMessages } from "../interfaces";

export const messages: TranslationDualListboxMessages = {
  [DualListboxMessages.permittedVehiclesHeader]: "Permitted vehicles",
  [DualListboxMessages.restrictedVehiclesHeader]: "Restricted vehicles",
  [DualListboxMessages.availableFilterHeader]: "Available",
  [DualListboxMessages.moveAllLeft]: "Move all left",
  [DualListboxMessages.moveAllRight]: "Move all right",
  [DualListboxMessages.moveLeft]: "Move left",
  [DualListboxMessages.moveRight]: "Move right",
  [DualListboxMessages.moveBottom]: "Move bottom",
  [DualListboxMessages.moveDown]: "Move down",
  [DualListboxMessages.moveUp]: "Move up",
  [DualListboxMessages.moveTop]: "Move top",
  [DualListboxMessages.noAvailableOptions]: "No available options",
  [DualListboxMessages.noSelectedOptions]: "No selected options",
  [DualListboxMessages.selectedFilterHeader]: "Selected",
  [DualListboxMessages.filterPlaceholder]: "Search..."
};
