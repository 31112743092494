import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import BlurMap from "@assets/blur-map.jpg";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`

  @media (min-width: 800px) { // Desktop
    overflow: hidden;
  }

  &.page {
    padding-top: 1rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;
  margin-top: 0;

  .titleHeader {
    padding: 10px 20px 0 20px;

    .title {
      font-weight: 200;
      width: 100%;
      font-size: 2rem;
    }
    .subtitle {
      margin-top: 5px;
      font-size: 1.2rem;
      color: rgb(0 0 0 / 60%);
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;

  @media (max-width: 800px) { // Mobile
    height: auto;

    .title, .filterHeader, .filterFooter {
      text-align: center !important;
    }
  }

  .form {
    background: white;
    padding: 0 20px;

    .filterContent {
      margin: 25px 0;

      .MuiFormHelperText-root {
        text-align: right;
        margin: 0;
      }
    }
    .button-loading {
      margin-top: 0px !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export const ContainerContent = styled.div`

  @media (min-width: 800px) { // Desktop

    .coordinate-analysis-details {
      height: calc(105vh - 280px);
      margin: 0;

      .MuiGrid-item {
        height: 100%;

        .coordinate-analysis-details__map--static {
          height: 100%;
        }
        .coordinate-analysis-details__commands {
          height: 100%;
        }
      }
    }

    .left-container-block {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .coordinate-analysis-details {
    padding: 0 20px;

    .left-container-block {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .MuiGrid-container {
      height: 95%;

      .map {

        @media (max-width: 800px) { // Mobile
          height: 500px !important;
        }
      }
      .MuiGrid-justify-xs-space-evenly {
        justify-content: end;

        @media (max-width: 800px) { // Mobile
          justify-content: center;
        }
      }
    }

    .coordinate-analysis-details__states {

      .coordinate-analysis-details__block {

        .coordinate-analysis-details__title {
          color: black;
          font-weight: 300;
          font-size: 1.5rem;
        }
      }

      .coordinate-analysis-details__block--title {
        margin-bottom: 20px;
      }

      .coordinate-analysis-details__subtitle {
        font-size: 1.2rem;
        color: rgb(0 0 0 / 60%);
        margin-top: 10px;
      }
    }

    .coordinate-analysis-details__alerts {
      height: 100%;

      .coordinate-analysis-details__block {

        .MuiAccordionSummary-content {

          .content {
            padding: 0;
          }
        }
        .coordinate-analysis-details__title {
          color: black;
          font-weight: 300;
          font-size: 1.5rem;
        }
        .coordinate-analysis-details__subtitle {
          font-size: 1.2rem;
          color: rgb(0 0 0 / 60%);
          margin-top: 10px;
        }
      }

      .coordinate-analysis-details__block--title {
        margin-bottom: 20px;
      }
    }
    .coordinate-analysis-details__map {

      .vehicle-travel-details__map-info-content {
        min-width: 120px;

        .block {
          padding: 0 5px;
        }
        .first {
          text-align: center;
          margin-bottom: 15px;

          .icon {

            img {
              width: 20px;
            }
          }
        }
        .second {
          width: 100%;

          .content {
            display: flex;
            justify-content: space-between;

            .title {
              font-weight: 400;
            }
            .text {
              padding-left: 15px;
              text-align: right;
            }
          }
        }
      }
    }
    .coordinate-analysis-details__map--static {
      display: flex;
      justify-content: center;
      height: 450px;
      align-items: center;
      background-image: url(${BlurMap});
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-box-pack: center;
      -webkit-box-align: center;
    }
    .coordinate-analysis-details__commands {
      height: 100%;

      .coordinate-analysis-details__block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .coordinate-analysis-details__title {
          color: black;
          font-weight: 300;
          font-size: 1.5rem;
        }
        .coordinate-analysis-details__list {
          width: 100%;
          overflow-y: scroll;
          height: calc(100% - 50px);

          @media (max-width: 800px) { // Mobile
            height: auto;
          }

          .coordinate-analysis-details__item {
            width: 100%;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .coordinate-analysis-details__summary {
              justify-content: space-between;

              .MuiAccordionSummary-content {
                justify-content: space-between;
                align-items: center;
                margin: 0;

                .coordinate-analysis-details__index {
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;

                  .MuiChip-root {
                    min-width: 45px;
                    cursor: pointer;
                  }
                }
                .coordinate-analysis-details__icon {
                  display: flex;

                  svg {
                    width: 20px;
                    height: auto;
                    margin: 0 1px;
                  }
                }
                &:hover {

                  .coordinate-analysis-details__index {

                    .MuiChip-root {
                      background-color: ${(props) => props.theme.colors.primary};
                      color: white;
                    }
                  }
                }
              }
            }
            .coordinate-analysis-details__details {
              display: block;

              .coordinate-analysis-details__button {
                background-color: #707070;
                color: white;
                float: right;
                margin: 30px 0;
              }
            }
          }
        }
        .coordinate-analysis-details__subtitle {
          font-size: 1.2rem;
          color: rgb(0 0 0 / 60%);
          margin-top: 10px;
        }
      }
      .coordinate-analysis-details__block--title {
        display: block !important;
      }
      .coordinate-analysis-details__block--full-height {
        height: 100%;
      }
    }
  }
`;
