import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";

// region Imports - External Libraries
import * as dateFns from "date-fns";
import _ from "lodash";
import * as Yup from "yup";
// endregion Imports - External Libraries
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Imports - Hooks
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  PumpProgrammingMessages,
  ToastMessages,
  YupMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Molecules
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Molecules
// region Imports - Components
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import ButtonTable from "@components/Button/ButtonTable";
import QueryFilterPumpProgramming from "@organisms/QueryFilterPumpProgramming";
// endregion Imports - Components
// region Imports - Shared
import { Travel } from "@shared/entities/reg_travels.entity";
import { Vehicle } from "@shared/entities/reg_vehicles.entity";
import { Location } from "@shared/entities/reg_locations.entity";
import { Driver } from "@shared/entities/reg_drivers.entity";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
// endregion Imports - Shared
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
// endregion Imports - Material-UI
// region Imports - Unform
import { Form } from "@unform/web";
import { TextField } from "unform-material-ui";
import { FormHandles } from "@unform/core";
// endregion Imports - Unform
// region Imports - Styles
import { Container, ContainerModalFormPumpProgramming } from "./styles";
// endregion Imports - Styles

const PumpProgrammingData: React.FC = () => {

  // region States

  // Open options states
  const [openOptionsVehicles, setOpenOptionsVehicles] = useState(false);
  const [openOptionsDrivers, setOpenOptionsDrivers] = useState(false);
  const [openOptionsOriginLocations, setOpenOptionsOriginLocations] = useState(false);

  // Options lists states
  const [optionsVehicles, setOptionsVehicles] = useState<Vehicle[]>([] as Vehicle[]);
  const [optionsOriginLocations, setOptionsOriginLocations] = useState<Location[]>([] as Location[]);
  const [optionsDrivers, setOptionsDrivers] = useState<Driver[]>([] as Driver[]);

  // Active options states
  const [activeOptionVehicle, setActiveOptionVehicle] = useState<Vehicle>({} as Vehicle);
  const [activeOptionOriginLocation, setActiveOptionOriginLocation] = useState<Location>({} as Location);
  const [activeOptionDriver, setActiveOptionDriver] = useState<Driver>({} as Driver);

  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExternalIdValidation, setIsLoadingExternalIdValidation] = useState(false);
  const loadingOptionsVehicles = openOptionsVehicles && optionsVehicles.length === 0;
  const loadingOptionsOriginLocations = openOptionsOriginLocations && optionsOriginLocations.length === 0;
  const loadingOptionsDrivers = openOptionsDrivers && optionsDrivers.length === 0;

  // Filter states
  const [loadMoreToggle, setLoadMoreToggle] = useState(false);
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);
  const [reloadToggle, setReloadToggle] = useState(false);

  const [pumpProgrammingList, setPumpProgrammingList] = useState<Travel[]>([] as Array<Travel>);

  const [pumpProgrammingDetails, setPumpProgrammingDetails] = useState<Travel>({} as Travel);

  const [openDialogConfirmCancel, setOpenDialogConfirmCancel] = useState(false);
  const [openModalFormPumpProgramming, setOpenModalFormPumpProgramming] = useState(false);
  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");
  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);

  // endregion States
  // region Hooks
  const { screen } = useSelector((screen) => screen);
  const { addToast } = useToast();
  const { t } = useTranslation();
  // endregion Hooks
  // region Form Validation
  const formRef = useRef<FormHandles>(null);
  /**
   * Validation fields
   */
  const validations = {
    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    // Validate external id
    validateExternalId: async (externalId: string) => {

      if (!externalId) {
        formRef.current?.setFieldError("external_id", "");

        return;
      }

      try {

        setIsLoadingExternalIdValidation(true);

        // Verify if RE is already registered
        const { data } = await api.get(`travels/verify-unique-field/external_id/${externalId.trim()}`);

        if (data.status !== "success") formRef.current?.setFieldError("external_id", t(YupMessages.uniqueField));
        else formRef.current?.setFieldError("external_id", "");

      } catch (error) {
        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      } finally {
        setIsLoadingExternalIdValidation(false);
      }
    },
    validateForm: async (formData: Travel, action: "details" | "register" | "update") => {

      try {
        // Define the validation types
        const schema = Yup.object().shape({
          num_doc: Yup.string().trim().required(t(YupMessages.fieldRequired)),
          external_id: Yup.string().trim().required(t(YupMessages.fieldRequired)),
          document_date: Yup.string().trim().required(t(YupMessages.fieldRequired)),
          vehicle: Yup.string().required(t(YupMessages.fieldRequired)),
          origin: Yup.string().trim().required(t(YupMessages.fieldRequired))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update pump programming (According action selected)
        if (action === "register") await createPumpProgramming(formData);
        else await updatePumpProgramming(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  // endregion Form Validation
  // region Constants
  const dataTableSettings: DataTables.Settings = {
    order: [[3, "desc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-pump-programming-list-details",
      callback: (rowData: Travel) => handleOpenDetails(rowData)
    },
    {
      ref: ".modules-pump-programming-list-cancel",
      callback: (rowData: Travel) => handlePumpProgrammingCancel(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Descrição
      title: t(PumpProgrammingMessages.columnDescription),
      data: (travel: Travel) => travel?.description,
      filterable: true,
      defaultContent: "",
      propertyName: "description"
    },
    { // Cliente
      title: t(PumpProgrammingMessages.columnClient),
      data: (travel: Travel) => travel?.client,
      filterable: true,
      defaultContent: "",
      propertyName: "client"
    },
    { // Veículo
      title: t(PumpProgrammingMessages.columnVehicle),
      data: (travel: Travel) => travel.vehicle?.code,
      filterable: true,
      defaultContent: "",
      propertyName: "vehicle.code"
    },
    { // Data de integração
      title: t(PumpProgrammingMessages.columnIntegrationDate),
      data: (travel: Travel) => utils.formatDateIfHave(travel.integration_date, "fullDate"),
      filterable: true,
      defaultContent: "",
      propertyName: "integration_date"
    },
    { // Data do documento
      title: t(PumpProgrammingMessages.columnDocumentDate),
      data: (travel: Travel) => utils.formatDateIfHave(travel.document_date, "fullDate"),
      filterable: true,
      defaultContent: "",
      propertyName: "document_date"
    },
    { // Doc.
      title: t(PumpProgrammingMessages.columnDoc),
      data: (travel: Travel) => travel.num_doc,
      filterable: true,
      defaultContent: "",
      propertyName: "num_doc"
    },
    { // Id externo
      title: t(PumpProgrammingMessages.columnExternalId),
      data: (travel: Travel) => travel.external_id,
      filterable: true,
      defaultContent: "",
      propertyName: "external_id"
    },
    { // Local de origem
      title: t(PumpProgrammingMessages.columnOrigin),
      data: (travel: Travel) => travel?.origin?.name,
      filterable: true,
      defaultContent: "",
      propertyName: "origin"
    },
    { // Motorista
      title: t(PumpProgrammingMessages.columnDriver),
      data: (travel: Travel) => travel?.driver?.name,
      filterable: true,
      defaultContent: "",
      propertyName: "driver"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-pump-programming-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-pump-programming-list-cancel"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => handleOpenCreateForm()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_programacoes_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.moreRecords),
      callback: () => {
        setLoadMoreToggle((prevState) => !prevState);
      },
      key: "moreRecords"
    }
  ];
  // endregion Constants
  // region Functions
  /**
   * Handle close modal form
   */
  const handleCloseModalForm = useCallback(() => {

    setActiveOptionVehicle({} as Vehicle);
    setActiveOptionOriginLocation({} as Location);
    setActiveOptionDriver({} as Driver);

    setPumpProgrammingDetails({} as Travel);

    setOpenModalFormPumpProgramming(false);

  }, []);

  /**
   * Handle open modal form to create a pump programming
   */
  const handleOpenCreateForm = () => {
    setModalType("register");
    setOpenModalFormPumpProgramming(true);
  };

  /**
   * Handle open modal form to update a pump programming
   */
  const handleOpenDetails = useCallback((pumpProgramming: Travel) => {

    setPumpProgrammingDetails(pumpProgramming);

    // Set autocomplete fields
    if (pumpProgramming.vehicle) setActiveOptionVehicle(pumpProgramming.vehicle);
    if (pumpProgramming.origin) setActiveOptionOriginLocation(pumpProgramming.origin);
    if (pumpProgramming.driver) setActiveOptionDriver(pumpProgramming.driver);

    setModalType("details");

    setOpenModalFormPumpProgramming(true);

  }, [setPumpProgrammingDetails, setModalType, setOpenModalFormPumpProgramming]);

  /**
   * Handle cancel a pump programming
   */
  const handlePumpProgrammingCancel = useCallback((pumpProgramming: Travel) => {

    setPumpProgrammingDetails(pumpProgramming);

    setOpenDialogConfirmCancel(true);

  }, [setPumpProgrammingDetails, setOpenDialogConfirmCancel]);

  /**
   * Handle close dialog confirm cancel
   */
  const handleCloseDialogConfirmCancel = useCallback(() => {
    setOpenDialogConfirmCancel(false);
  }, []);

  /**
   * Handle apply filter to get pump programming data
   * @param numberOfFilteredOptions
   * @param returnedData
   * @param loadMore
   */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: Travel[],
    loadMore: boolean
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    if (loadMore) setPumpProgrammingList([...pumpProgrammingList, ...returnedData]);
    else setPumpProgrammingList(returnedData);
  };

  /**
   * Create a pump programming
   * @param travel Pump programming to create
   */
  const createPumpProgramming = useCallback(async (travel: Travel) => {

    try {
      let insertPumpProgramming = { ...travel };

      // Remove properties without value
      Object.keys(insertPumpProgramming).forEach((key) => {
        if (insertPumpProgramming[key].length <= 0) delete insertPumpProgramming[key];
      });

      if (insertPumpProgramming.vehicle) insertPumpProgramming.vehicle = activeOptionVehicle;
      if (insertPumpProgramming.origin) insertPumpProgramming.origin = activeOptionOriginLocation;
      if (insertPumpProgramming.driver) insertPumpProgramming.driver = activeOptionDriver;
      if (insertPumpProgramming.alert_maximum_speed) insertPumpProgramming.alert_maximum_speed = Number(insertPumpProgramming.alert_maximum_speed);

      setIsLoading(true);
      const { data } = await api.post(
        "travels/pump-programming/create",
        {
          ...insertPumpProgramming,
          document_date: utils.convertDateToISOWithTimezone(new Date(insertPumpProgramming.document_date!))
        }
      );

      if (data.status === "success") {

        handleCloseModalForm();
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertPumpProgramming = data.result;

        setReloadToggle((prevState) => !prevState);

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoading(false);
    }
  }, [table, setIsLoading, activeOptionVehicle, activeOptionOriginLocation, activeOptionDriver]);

  /**
   * Update a pump programming
   * @param pumpProgramming Travel to update
   */
  const updatePumpProgramming = useCallback(async (pumpProgramming: Travel) => {

    try {

      setIsLoading(true);

      const updatePumpProgramming = { ...pumpProgramming };

      // Set null to empty fields
      Object.keys(updatePumpProgramming).forEach((key) => {
        if (updatePumpProgramming[key].length <= 0) updatePumpProgramming[key] = null;
      });

      if (updatePumpProgramming.vehicle) updatePumpProgramming.vehicle = activeOptionVehicle;
      if (updatePumpProgramming.origin) updatePumpProgramming.origin = activeOptionOriginLocation;
      if (updatePumpProgramming.driver) updatePumpProgramming.driver = activeOptionDriver;
      if (updatePumpProgramming.alert_maximum_speed) updatePumpProgramming.alert_maximum_speed = Number(updatePumpProgramming.alert_maximum_speed);

      const { data } = await api.patch(
        `travels/pump-programming/update/${pumpProgrammingDetails.id_travel}`,
        {
          ...updatePumpProgramming,
          document_date: utils.convertDateToISOWithTimezone(new Date(updatePumpProgramming.document_date!))
        }
      );

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setPumpProgrammingDetails(Object.assign(updatePumpProgramming, pumpProgramming));

        // Update row in table with updated driver data
        table.row(tableClickedRow).data(updatePumpProgramming);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoading(false);
    }

  }, [addToast, table, tableClickedRow, pumpProgrammingDetails, handleCloseModalForm, t, setIsLoading, setPumpProgrammingDetails,
    activeOptionVehicle, activeOptionOriginLocation, activeOptionDriver]);

  /**
   * Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
      case "details": return detailValue;
      case "update": return updateValue;
      case "register": return registerValue;
      default: return "S/N";
    }
  }, [modalType]);

  /**
   * Cancel selected pump programming
   * @param pumpProgramming Pump programming to cancel
   */
  const cancelPumpProgramming = useCallback(async (pumpProgramming: Travel) => {

    try {

      setIsLoading(true);
      const { data } = await api.patch(`travels/pump-programming/cancel/${pumpProgramming.id_travel}`);

      if (data.status === "success") {

        handleCloseDialogConfirmCancel(); // Close dialog confirm cancel
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setReloadToggle((prevState) => !prevState);

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setIsLoading(false);
    }

  }, [addToast, handleCloseDialogConfirmCancel, table, tableClickedRow, t]);

  // endregion Functions
  // region Effects

  // Vehicle options
  useEffect(() => {
    if (!openOptionsVehicles) setOptionsVehicles([]);
  }, [openOptionsVehicles]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsVehicles) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read",
          { params: { linkedHardware: true, onlyLabel: true, idVehicleType: VehicleTypesID.CAMINHAO_BOMBA } });

        if (data.status === "success") {
          if (active) setOptionsVehicles(data.result);
        } else {
          setOpenOptionsVehicles(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenOptionsVehicles(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsVehicles, addToast, t]);

  // Origin location options
  useEffect(() => {
    if (!openOptionsOriginLocations) setOptionsOriginLocations([]);
  }, [openOptionsOriginLocations]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsOriginLocations) return undefined;

    (async () => {

      try {

        // Get all locations that are not of type construction
        const { data } = await api.get("locations/get-without-constructions",
          { params: { onlyLocationsWithName: true, onlyPlants: true } });

        if (data.status === "success") {
          if (active) setOptionsOriginLocations(data.result);
        } else {
          setOpenOptionsOriginLocations(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsOriginLocations(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsOriginLocations, addToast]);

  // Driver options
  useEffect(() => {
    if (!openOptionsDrivers) setOptionsDrivers([]);
  }, [openOptionsDrivers]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsDrivers) return undefined;

    (async () => {

      try {

        // Get all drivers
        const { data } = await api.get("drivers/read");

        if (data.status === "success") {
          if (active) setOptionsDrivers(data.result);
        } else {
          setOpenOptionsDrivers(false);
          addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsDrivers(false);

        if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsDrivers, addToast]);

  // endregion Effects
  return (
    <>
      <Container platform={screen.platform as ScreenPlatform}>
        <Loading loading={isLoading} />
        {
          !isLoading && (
            <NewDataTable
              title={t(PumpProgrammingMessages.dataTableTitle)}
              filters
              data={pumpProgrammingList}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
              onClickFilterButton={() => setOpenFilterFields(true)}
              numberOfFilteredOptions={numberOfFilteredOptions}
            />
          )
        }
        <QueryFilterPumpProgramming
          open={openFilterFields}
          onClose={() => { setOpenFilterFields(false); }}
          onFilter={handleApplyFilter}
          loadMoreToggle={loadMoreToggle}
          reloadToggle={reloadToggle}
        />
        <DialogConfirmAction
          open={openDialogConfirmCancel}
          onClose={handleCloseDialogConfirmCancel}
          title={t(PumpProgrammingMessages.cancelConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.no), action: () => handleCloseDialogConfirmCancel() },
            { text: t(GlobalMessages.yes), action: () => cancelPumpProgramming(pumpProgrammingDetails) }
          ]}
        >
          {t(PumpProgrammingMessages.cancelConfirmationText)} <br /><br />
          {t(PumpProgrammingMessages.columnDoc)}: {pumpProgrammingDetails?.num_doc} <br />
          {t(PumpProgrammingMessages.columnExternalId)}: {pumpProgrammingDetails?.external_id}
        </DialogConfirmAction>
        <ContainerModalFormPumpProgramming id="modalFormPumpProgramming">
          <Dialog
            open={openModalFormPumpProgramming}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormPumpProgramming")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {
                    modalType !== "register"
                      ? `${t(PumpProgrammingMessages.pumpProgrammingTitle)} - ${pumpProgrammingDetails?.num_doc}`
                      : t(PumpProgrammingMessages.newPumpProgramming)
                  }
                </div>
              </div>
              <div className="actions">
                {modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData, modalType)}>
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnDescription)}
                        margin="dense"
                        variant="outlined"
                        name="description"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("description").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(pumpProgrammingDetails.description, pumpProgrammingDetails.description, "")}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnClient)}
                        margin="dense"
                        variant="outlined"
                        name="client"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("client").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(pumpProgrammingDetails.client, pumpProgrammingDetails.client, "")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnDoc)}
                        margin="dense"
                        variant="outlined"
                        name="num_doc"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("num_doc").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(pumpProgrammingDetails.num_doc, pumpProgrammingDetails.num_doc, "")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnExternalId)}
                        margin="dense"
                        variant="outlined"
                        name="external_id"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("external_id").length > 0 ? true : undefined
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {isLoadingExternalIdValidation ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                          )
                        }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(pumpProgrammingDetails.external_id, pumpProgrammingDetails.external_id, "")}
                        onChange={() => validations.validateExternalId(formRef.current?.getFieldValue("external_id"))}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnDocumentDate)}
                        name="document_date"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={t(GlobalMessages.required)}
                        type="datetime-local"
                        InputLabelProps={{ shrink: true }}
                        disabled={modalType === "details"}
                        onChange={() => validations.validateFieldError("document_date")}
                        defaultValue={
                          !_.isEmpty(pumpProgrammingDetails?.document_date)
                          && defineValueAccordingModalType(
                            dateFns.format(new Date(pumpProgrammingDetails.document_date!), "yyyy-MM-dd'T'HH:mm"),
                            dateFns.format(new Date(pumpProgrammingDetails.document_date!), "yyyy-MM-dd'T'HH:mm"),
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        label={t(PumpProgrammingMessages.columnMaxSpeed)}
                        name="alert_maximum_speed"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("alert_maximum_speed").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(
                          pumpProgrammingDetails.alert_maximum_speed, pumpProgrammingDetails.alert_maximum_speed, ""
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsVehicles}
                        onOpen={() => setOpenOptionsVehicles(true)}
                        onClose={() => setOpenOptionsVehicles(false)}
                        getOptionSelected={(option, value) => option.id_vehicle === value.id_vehicle}
                        getOptionLabel={(option) => option.code}
                        options={optionsVehicles}
                        disabled={modalType === "details"}
                        loading={loadingOptionsVehicles}
                        onChange={(_event, value) => setActiveOptionVehicle(value)}
                        defaultValue={
                          !_.isEmpty(pumpProgrammingDetails?.vehicle)
                          && defineValueAccordingModalType(pumpProgrammingDetails?.vehicle,
                            pumpProgrammingDetails?.vehicle, "")
                        }
                        value={activeOptionVehicle}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="vehicle"
                            label={t(PumpProgrammingMessages.columnVehicle)}
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{
                              shrink: formRef.current?.getFieldValue("vehicle").length > 0 ? true : undefined
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  { loadingOptionsVehicles ? <CircularProgress color="inherit" size={20} /> : null }
                                  { params.InputProps.endAdornment }
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsOriginLocations}
                        onOpen={() => setOpenOptionsOriginLocations(true)}
                        onClose={() => setOpenOptionsOriginLocations(false)}
                        getOptionSelected={(option, value) => option.id_location === value.id_location}
                        getOptionLabel={(option) => option.name ?? ""}
                        options={optionsOriginLocations}
                        disabled={modalType === "details"}
                        loading={loadingOptionsOriginLocations}
                        onChange={(_event, value) => setActiveOptionOriginLocation(value)}
                        defaultValue={
                          !_.isEmpty(pumpProgrammingDetails?.origin)
                          && defineValueAccordingModalType(pumpProgrammingDetails?.origin,
                            pumpProgrammingDetails?.origin, "")
                        }
                        value={activeOptionOriginLocation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="origin"
                            label={t(PumpProgrammingMessages.columnOrigin)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: formRef.current?.getFieldValue("origin").length > 0 ? true : undefined
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  { loadingOptionsOriginLocations ? <CircularProgress color="inherit" size={20} />
                                    : null }
                                  { params.InputProps.endAdornment }
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        open={openOptionsDrivers}
                        onOpen={() => setOpenOptionsDrivers(true)}
                        onClose={() => setOpenOptionsDrivers(false)}
                        getOptionSelected={(option, value) => option.id_driver === value.id_driver}
                        getOptionLabel={(option) => option.name ?? ""}
                        options={optionsDrivers}
                        disabled={modalType === "details"}
                        loading={loadingOptionsDrivers}
                        onChange={(_event, value) => setActiveOptionDriver(value)}
                        defaultValue={
                          !_.isEmpty(pumpProgrammingDetails?.driver)
                          && defineValueAccordingModalType(pumpProgrammingDetails?.driver,
                            pumpProgrammingDetails?.driver, "")
                        }
                        value={activeOptionDriver}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="driver"
                            label={t(GlobalMessages.driver)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: formRef.current?.getFieldValue("driver").length > 0 ? true : undefined
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  { loadingOptionsDrivers ? <CircularProgress color="inherit" size={20} /> : null }
                                  { params.InputProps.endAdornment }
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalType === "details"
                    ? <></>
                    : modalType === "register"
                      // eslint-disable-next-line max-len
                      ? <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.register)}</Button>
                      // eslint-disable-next-line max-len
                      : <Button disableRipple type="submit" color="primary" onClick={() => formRef.current?.submitForm()}>{t(GlobalMessages.save)}</Button>
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormPumpProgramming>
      </Container>
    </>
  );
};

export default PumpProgrammingData;
