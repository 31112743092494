import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
import Green from "@material-ui/core/colors/green";
import Red from "@material-ui/core/colors/red";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BugReportIcon from "@material-ui/icons/BugReport";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { HardwareTypesID } from "@shared/constants/hardware-types.enum";

import { ConfigCommand as ConfigCommandInterface } from "@shared/interfaces/config-command.interface";
import { CommandStatusType } from "@shared/types/command_status_type.enum";
import { ConfigCommandContent } from "@shared/interfaces/config-command-content.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { HardwareModels } from "@shared/constants/hardware-models.enum";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";

import { Checkbox, TextField } from "unform-material-ui";

import * as dateFns from "date-fns";
import * as _ from "lodash";
import * as Yup from "yup";

import LimitedChipsField from "@atoms/LimitedChipsField";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import useTranslation from "src/translations/useTranslation";
import { DataTableMessages, GlobalMessages, HardwareModuleMessages, ToastMessages } from "@shared/languages/interfaces";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import ButtonTable from "../../../../components/Button/ButtonTable";
import DialogConfirmAction from "../../../../components/Dialog/ConfirmAction";

import utils from "../../../../utils/useful-functions";
import getValidationErrors from "../../../../hooks/getValidationErrors";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/useToast";
import { ContainerCommandsList, ContainerFilters, ContainerModalFormCommand } from "./style";

interface ConfigCommandsFilter {
  vehicleDescription: string;
  idHardware?: string;
  status: string;
  dateFilterType: string;
  startDate: string;
  finishDate: string;
}

const HardwaresList: React.FC = () => {

  const { addToast } = useToast();
  const { t, i18n } = useTranslation();

  /* region States */
  const [loadingConfigCommandsList, setLoadingConfigCommandsList] = useState(false);

  const [openModalFormConfigCommands, setOpenModalFormConfigCommands] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>(
    {} as JQuery<HTMLTableRowElement>
  );

  const [activeDefaultConfigCommand, setActiveDefaultConfigCommand] = useState<string>("");
  const [configCommands, setConfigCommands] = useState<ConfigCommandInterface[]>([]);
  const [configCommandDetail, setConfigCommandDetail] = useState<ConfigCommandInterface>({} as ConfigCommandInterface);

  const [openDataFilterType, setOpenDataFilterType] = useState(false);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);

  const [optionsVehicles, setOptionsVehicles] = useState<Vehicle[]>([] as Vehicle[]);

  // Autocomplete async - Vehicle (Filter)
  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const loadingVehicleFilter = openVehicleFilter && optionsVehicles.length === 0;

  // Autocomplete async - Vehicle (Modal)
  const [openVehicleFilterModal, setOpenVehicleFilterModal] = useState(false);
  const [activeVehicleFilterModal, setActiveVehicleFilterModal] = useState<Vehicle[]>([] as Vehicle[]);
  const loadingVehicleFilterModal = openVehicleFilterModal && optionsVehicles.length === 0;

  // Autocomplete async - Config Commands Content (Modal)
  const [openConfigCommandContentModal, setOpenConfigCommandContentModal] = useState(false);
  const [optionsConfigCommandContentModal, setOptionsConfigCommandContentModal] = useState<ConfigCommandContent[]>(
    [] as Array<ConfigCommandContent>
  );
  const [activeConfigCommandContentModal, setActiveConfigCommandContentModal] = useState<ConfigCommandContent | null>(
    null
  );

  /* region Constants */
  // REVIEW Change fixed values to database get values
  // Some dropdowns values, we used a fixed values
  const fixedValues = {
    brands: ["Suntech", "Auto Sender", "Fleet"],
    models: [
      HardwareModels["ST 300HD"],
      HardwareModels["ST 310U"],
      HardwareModels["ST 310UC2"],
      HardwareModels["ST 310U EVT"],
      HardwareModels["ST 340UR"],
      HardwareModels["ST 4315U EVT"],
      HardwareModels["ST 4315U"],
      HardwareModels["ST 4305"],
      HardwareModels["ST 8310U EVT"],
      HardwareModels["ST 8310U"],
      HardwareModels["ST 8300"],
      HardwareModels["2684"],
      HardwareModels["0721"],
      HardwareModels["RAS_1"],
      HardwareModels["GV350MG"],
      HardwareModels["GV350CEU"]
    ],
    dateFilterTypes: [
      { description: t(HardwareModuleMessages.commandFilterCreation) },
      { description: t(HardwareModuleMessages.commandFilterSend) },
      { description: t(HardwareModuleMessages.commandFilterProcessing) }],
    status: [
      { description: t(HardwareModuleMessages.commandStatusPending), code: CommandStatusType.PENDING },
      { description: t(HardwareModuleMessages.commandStatusSent), code: CommandStatusType.SENDED },
      { description: t(HardwareModuleMessages.commandStatusProcessed), code: CommandStatusType.PROCESSED },
      { description: t(HardwareModuleMessages.commandStatusCancelled), code: CommandStatusType.CANCELLED }
    ]
  };

  const loadingConfigCommandContentModal = openConfigCommandContentModal && optionsConfigCommandContentModal.length
    === 0;

  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-config-commands-list-delete",
      callback: (rowData: ConfigCommandInterface) => handleDeleteConfigCommands(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Dev ID
      title: t(HardwareModuleMessages.devId).toUpperCase(),
      width: "70px",
      data: (ConfigCommand: ConfigCommandInterface) => ConfigCommand?.tracker?.dev_id || "",
      filterable: true,
      propertyName: "tracker.dev_id"
    },
    { // Comando
      title: t(HardwareModuleMessages.command),
      data: (ConfigCommand: ConfigCommandInterface) => ConfigCommand.command,
      filterable: true,
      propertyName: "command"
    },
    { // Data Criação
      title: t(HardwareModuleMessages.commandRegistrationDate),
      width: "110px",
      data: (ConfigCommand: ConfigCommandInterface) => (
        ConfigCommand.registration_date ? utils.formatDateIfHave(ConfigCommand.registration_date, "fullDate")
          : ""
      ),
      filterable: true,
      propertyName: "registration_date"
    },
    { // Data envio
      title: t(HardwareModuleMessages.commandSendDate),
      width: "110px",
      data: (ConfigCommand: ConfigCommandInterface) => (
        ConfigCommand.sended_date ? utils.formatDateIfHave(ConfigCommand.sended_date, "fullDate") : ""
      ),
      filterable: true,
      propertyName: "sended_date"
    },
    { // Data Processamento
      title: t(HardwareModuleMessages.commandProcessedDate),
      width: "110px",
      data: (ConfigCommand: ConfigCommandInterface) => (
        ConfigCommand.processed_date ? utils.formatDateIfHave(ConfigCommand.processed_date, "fullDate") : ""
      ),
      filterable: true,
      propertyName: "processed_date"
    },
    { // Estados
      title: t(DataTableMessages.status),
      width: "110px",
      data: (ConfigCommand: ConfigCommandInterface) => {
        const iconStyle = { marginRight: "5px" };
        let icon = <AccessTimeIcon style={{ ...iconStyle, fill: "gray" }} />;
        const title = fixedValues.status.find((status) => status.code === ConfigCommand.status)?.description;

        if (title === t(HardwareModuleMessages.commandStatusProcessed)) {
          icon = <CheckIcon style={{ ...iconStyle, fill: Green[400] }} />;
        } else if (title === t(HardwareModuleMessages.commandStatusSent)) {
          icon = <PlayArrowIcon style={{ ...iconStyle, fill: "gray" }} />;
        } else if (title === t(HardwareModuleMessages.commandStatusCancelled)) {
          icon = <ClearIcon style={{ ...iconStyle, fill: Red[400] }} />;
        }

        return ReactDOMServer.renderToString(
          <span
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            {icon} {title}
          </span>
        );
      },
      filterable: true,
      propertyName: "status"
    },
    { // Tentativas
      title: t(HardwareModuleMessages.commandAttempts),
      width: "30px",
      data: (ConfigCommand: ConfigCommandInterface) => ConfigCommand.attempts,
      filterable: true,
      propertyName: "attempts"
    },
    { // Resposta
      title: t(HardwareModuleMessages.commandResponse),
      width: "40px",
      data: (ConfigCommand: ConfigCommandInterface) => {
        const { response } = ConfigCommand;

        if (!response) return "";

        const error = response.toLocaleLowerCase().includes("erro");
        const fill = error ? Red[400] : Green[400];
        const icon = error ? <BugReportIcon style={{ fill }} /> : <FileCopyIcon style={{ fill }} />;

        return ReactDOMServer.renderToString(
          <span
            title={ConfigCommand.response}
            className="copyToClipboard"
          >
            {icon}
          </span>
        );
      },
      filterable: true,
      propertyName: "response"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: (ConfigCommand: ConfigCommandInterface) => {
        if (ConfigCommand.status !== CommandStatusType.PENDING) return "";

        return ReactDOMServer.renderToString(
          <Grid container spacing={1}>
            <Grid item xs sm md lg xl>
              <ButtonTable
                className="action-button modules-config-commands-list-delete"
              ><DeleteIcon />
              </ButtonTable>
            </Grid>
          </Grid>
        );
      },
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleCreateConfigCommands()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_hardwares_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */
  /* region Form */
  const formRefFilter = useRef<FormHandles>(null);
  const formRefModal = useRef<FormHandles>(null);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string, form: any) => {
      if (form.current?.getFieldError(fieldName)?.length) form.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: any) => {

      let pass = true;

      try {

        // Define the validation types
        const schema = Yup.object().shape({
          vehicles: Yup.string().test("hasHardware", t(HardwareModuleMessages.commandFormSelectVehicle),
            (val) => (activeVehicleFilterModal.length !== 0)),
          defaultCommand: Yup.string().trim().required(t(HardwareModuleMessages.commandFormSelectCommand))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

      } catch (error) {
        formRefModal.current?.setErrors(getValidationErrors(error));
      } finally {

        if (formData.vehicleCommands) {

          // Validate list of vehicles to send command
          Object.keys(formData.vehicleCommands).forEach((key) => {
            if (formData.vehicleCommands[key] === "") {
              pass = false;
              formRefModal.current?.setFieldError(`vehicleCommands.${key}`, t(HardwareModuleMessages.commandFormRequiredCommand));
            }
          });

          // If not have any error, organize object to create config commands
          if (pass) {

            const commandsToAdd: ConfigCommandInterface[] = [];

            activeVehicleFilterModal.forEach((vehicle) => {

              const command: ConfigCommandInterface = {
                command: formData.vehicleCommands[vehicle.id_vehicle],
                tracker: {
                  id_hardware: vehicle.hardwares[0].id_hardware,
                  dev_id: vehicle.hardwares[0].dev_id
                } as any,
                status: CommandStatusType.PENDING
              };

              commandsToAdd.push(command);
            });

            // Create config commands
            createConfigCommands(commandsToAdd);
          }
        }
      }
    },
    validadeFilter: async (formData: ConfigCommandsFilter) => {

      try {

        formRefFilter.current?.setErrors({});

        const idHardware = optionsVehicles.find(
          (el) => `${el.code} - ${el.description} (${el?.hardwares?.[0]?.dev_id ?? t(HardwareModuleMessages.commandFilterNoTracker)})`
            === formData.vehicleDescription
        )?.hardwares?.[0]?.id_hardware;

        // Define the validation types
        const schema = Yup.object().shape({
          startDate: Yup.date(),
          finishDate: Yup.date().min(Yup.ref("startDate"), t(HardwareModuleMessages.commandFilterDateRange)),
          vehicleDescription: Yup.string().test("hasHardware", t(HardwareModuleMessages.commandFilterNoLinkedTracker),
            (val) => (`${val}`.trim() === "" || idHardware !== undefined))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Return parameters to main Component
        readConfigCommands({
          ...formData,
          idHardware
        });

      } catch (error) {
        formRefFilter.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Form */
  /* region Functions */

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setOpenModalFormConfigCommands(false);
    setTimeout(() => {
      setActiveVehicleFilterModal([]);
      setActiveConfigCommandContentModal(null);
      setActiveDefaultConfigCommand("");
    }, 100);

  }, []);

  // Remove selected vehicle of list of vehicles to send command
  const handleRemoveVehicleOptionCommandModal = useCallback((vehicle: Vehicle) => {

    const newVehicles = activeVehicleFilterModal.filter((item) => item.id_vehicle !== vehicle.id_vehicle);

    setActiveVehicleFilterModal(newVehicles);

  }, [activeVehicleFilterModal]);

  // Add default command in vehicle selected list
  const handleApplyDefaultCommandInVehiclesList = useCallback((command: string) => {

    if (command !== "") {

      activeVehicleFilterModal.forEach((vehicle) => {

        formRefModal.current?.setFieldValue(
          `vehicleCommands.${vehicle.id_vehicle}`,
          command.replace("<DEV_ID>", vehicle.hardwares[0].dev_id)
        );

        validations.validateFieldError(`vehicleCommands.${vehicle.id_vehicle}`, formRefModal);
      });
    }

  }, [activeVehicleFilterModal, validations]);

  /** Create a config command(s)
   * @param configCommands Config commands to create
   */
  const createConfigCommands = useCallback(async (configCommandsToAdd: ConfigCommandInterface[]) => {

    try {

      // Create a config commands
      setLoadingConfigCommandsList(true);
      const { data } = await api.post("hardwares/commands/create", { config_commands: configCommandsToAdd });

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        // Add extra props in return inserted config commands
        for (let index = 0; index < configCommandsToAdd.length; index++) {
          data.result[index].status = configCommandsToAdd[index].status;
          data.result[index].tracker.dev_id = configCommandsToAdd[index]?.tracker?.dev_id;
        }

        setConfigCommands(data.result);

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingConfigCommandsList(false);
    }

  }, [addToast, handleCloseModalForm, t]);

  /** Get all filtered config commands */
  const readConfigCommands = useCallback(async (formData: ConfigCommandsFilter) => {

    try {

      // Get all hardwares
      setLoadingConfigCommandsList(true);

      // Generate params to query
      const startDate = `${formData.startDate}:00`;
      const finishDate = `${formData.finishDate}:00`;

      const getFilterDateBy = (dateFilterType: string) => {
        switch (dateFilterType) {
          case t(HardwareModuleMessages.commandFilterCreation):
            return {
              startRegistrationDate: startDate,
              endRegistrationDate: finishDate
            };
          case t(HardwareModuleMessages.commandFilterSend):
            return {
              startSendedDate: startDate,
              endSendedDate: finishDate
            };
          case t(HardwareModuleMessages.commandFilterProcessing):
            return {
              startProcessedDate: startDate,
              endProcessedDate: finishDate
            };
          default:
            return {};
        }
      };

      let status = "";

      if (formData.status !== "") {
        status = fixedValues.status.find((status) => status.description === formData.status)?.code ?? "";
      }

      const params = {
        ...getFilterDateBy(formData.dateFilterType),
        ...(!_.isEmpty(formData.idHardware) ? { idHardware: formData.idHardware } : {}),
        ...(!_.isEmpty(status) ? { status } : {})
      };

      const { data } = await api.get("hardwares/commands/get-filtered-by", { params });

      if (data.status === "success") setConfigCommands(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingConfigCommandsList(false);
    }

  }, [addToast, fixedValues.status, t]);

  const FhandleCreateConfigCommands = useCallback(() => {
    setOpenModalFormConfigCommands(true);
  }, []);

  /** Delete a config command(s) */
  const deleteConfigCommands = useCallback(async (configCommand: ConfigCommandInterface) => {
    try {
      // setLoadingConfigCommandsList(true);

      const params = {
        status: CommandStatusType.CANCELLED
      };

      const { data } = await api.patch(`hardwares/commands/update/${configCommand.id_config_command}`, params);

      if (data.status === "success") {

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        // Update row in table with updated driver data
        table.row(tableClickedRow).data({ ...configCommand, ...params });
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {

      setOpenDialogConfirmDelete(false);
    }
  }, [table, tableClickedRow, addToast, t]);

  const handleDeleteConfigCommands = useCallback(async (configCommand: ConfigCommandInterface) => {

    setConfigCommandDetail(configCommand);

    setOpenDialogConfirmDelete(true);

  }, []);

  /* endregion Functions */
  /* region Effects */
  /** Used to disable and change text when is searching for data */
  useEffect(() => {

    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingConfigCommandsList ? "Buscando hardwares..." : "Pesquisar";

      if (loadingConfigCommandsList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingConfigCommandsList]);

  /** Always change the active config command, change default config command textfield */
  useEffect(() => {

    if (activeConfigCommandContentModal?.command) {
      setActiveDefaultConfigCommand(activeConfigCommandContentModal?.command);
      validations.validateFieldError("defaultCommand", formRefModal);
    }
  }, [activeConfigCommandContentModal, validations]);

  /** Used to add copy to clipboard function */
  useEffect(() => {

    if (!loadingConfigCommandsList) {

      /* Function to copy text to oclipboard */
      const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
      };

      const commandElements: NodeListOf<HTMLElement> = document.querySelectorAll(".copyToClipboard");

      commandElements.forEach((el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();
          copyToClipboard(el.title ?? "");
          addToast({ type: "success", title: t(ToastMessages.success), description: t(HardwareModuleMessages.commandCopyAlert) });
        });
      });
    }
  }, [addToast, loadingConfigCommandsList, t]);

  /** Read Vehicles data (Options) */
  useEffect(() => {

    let active = true;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read", { params: { idHardwareType: HardwareTypesID.RASTREADOR } });

        if (data.status === "success") {
          if (active) setOptionsVehicles(data.result);
        } else {
          setOpenVehicleFilter(false);
          setOpenVehicleFilterModal(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenVehicleFilter(false);
        setOpenVehicleFilterModal(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [addToast, t]);

  /** Read Config Commands Content data (Options) */
  useEffect(() => {

    let active = true;

    if (!loadingConfigCommandContentModal) return undefined;

    (async () => {

      try {

        // Get all config commands content
        const { data } = await api.get("hardwares/commands/content/read", { params: { active: true } });

        if (data.status === "success") {
          if (active) setOptionsConfigCommandContentModal(data.result);
        } else {

          setOpenConfigCommandContentModal(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenConfigCommandContentModal(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingConfigCommandContentModal, addToast, t]);

  useEffect(() => {

    const queryParams: ConfigCommandsFilter = {
      dateFilterType: formRefFilter.current?.getFieldValue("dateFilterType") ?? "Criação",
      startDate: formRefFilter.current?.getFieldValue("startDate") ?? dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00"),
      finishDate: formRefFilter.current?.getFieldValue("finishDate") ?? dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59"),
      status: formRefFilter.current?.getFieldValue("status") ?? "",
      vehicleDescription: formRefFilter.current?.getFieldValue("vehicleDescription") ?? ""
    };

    readConfigCommands(queryParams).then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  /* endregion Effects */

  return (
    <>
      <Loading loading={loadingConfigCommandsList} />
      <ContainerCommandsList>
        <div className="container headerList">
          <div className="headerTitle">
            <div className="title">{t(HardwareModuleMessages.commandTitle)}</div>
          </div>
        </div>
        <ContainerFilters>
          <Form className="form" ref={formRefFilter} onSubmit={validations.validadeFilter}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Autocomplete
                    id="vehicleFilter"
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => `${option.code} - ${option.description} (${option?.hardwares?.[0]?.dev_id
                    ?? t(HardwareModuleMessages.commandFilterNoTracker)})`}
                    getOptionDisabled={(option) => option.hardwares.length === 0}
                    options={optionsVehicles.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                      || -b.code.localeCompare(a.code))}
                    groupBy={(option) => option.type.description}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicleDescription"
                        label={t(HardwareModuleMessages.vehicle)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Autocomplete
                    open={openStatusFilter}
                    onOpen={() => setOpenStatusFilter(true)}
                    onClose={() => setOpenStatusFilter(false)}
                    getOptionSelected={(option, value) => option.code === value.code}
                    getOptionLabel={(option) => `${option.description}`}
                    options={fixedValues.status}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="status"
                        label={t(HardwareModuleMessages.commandStatus)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Autocomplete
                    open={openDataFilterType}
                    onOpen={() => setOpenDataFilterType(true)}
                    onClose={() => setOpenDataFilterType(false)}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => option.description}
                    options={fixedValues.dateFilterTypes}
                    disableClearable
                    defaultValue={fixedValues.dateFilterTypes[0]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="dateFilterType"
                        label={t(HardwareModuleMessages.commandFilterDateBy)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="startDate"
                    label={t(HardwareModuleMessages.startDate)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="finishDate"
                    label={t(HardwareModuleMessages.finishDate)}
                    fullWidth
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <ButtonLoading
                    type="submit"
                    className="button-loading"
                    loading={loadingConfigCommandsList}
                  >{t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
      </ContainerCommandsList>
      {
        !loadingConfigCommandsList && (
          <NewDataTable
            title={t(HardwareModuleMessages.dataTableTitle)}
            filters
            data={configCommands}
            columns={dataTableColumns}
            actions={dataTableActions}
            settings={dataTableSettings}
            buttons={dataTableButtons}
            returnTable={(table) => setTable(table)}
            returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
          />
        )
      }
      {openDialogConfirmDelete && (
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={() => setOpenDialogConfirmDelete(false)}
          title={t(HardwareModuleMessages.commandDeleteTitle)}
          actions={[
            { text: t(GlobalMessages.no), action: () => setOpenDialogConfirmDelete(false) },
            { text: t(GlobalMessages.yes), action: () => deleteConfigCommands(configCommandDetail) }
          ]}
        >
          {t(HardwareModuleMessages.commandDeleteText)} <br /><br />
          {t(HardwareModuleMessages.devId)}: {configCommandDetail?.tracker?.dev_id ?? ""} <br />
          {t(HardwareModuleMessages.command)}: {configCommandDetail?.command}
        </DialogConfirmAction>
      )}
      <ContainerModalFormCommand id="modalFormConfigCommands">
        <Dialog
          open={openModalFormConfigCommands}
          onClose={handleCloseModalForm}
          scroll="paper"
          container={document.getElementById("modalFormConfigCommands")}
        >
          <DialogTitle className="mHeader">
            <div className="content">
              <div className="title">{t(HardwareModuleMessages.commandCreateTitle)}</div>
            </div>
          </DialogTitle>
          <DialogContent dividers className="mContent">
            <Form className="form" ref={formRefModal} onSubmit={(formData) => validations.validateForm(formData)}>
              <DialogContentText tabIndex={-1} component="div">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} className="vehicles">
                    <Autocomplete
                      multiple
                      fullWidth
                      renderTags={(value, getTagProps) => (
                        <LimitedChipsField
                          data={value}
                          limit={3}
                          getTagProps={getTagProps}
                          labelsFormat={(vehicle: Vehicle) => `${vehicle.code} - ${vehicle.license_plate}`}
                          keysFormat={(vehicle: Vehicle) => vehicle.id_vehicle}
                        />
                      )}
                      limitTags={3}
                      disableCloseOnSelect
                      open={openVehicleFilterModal}
                      onOpen={() => setOpenVehicleFilterModal(true)}
                      onClose={() => setOpenVehicleFilterModal(false)}
                      onChange={(event, value) => {
                        setActiveVehicleFilterModal(value);
                        validations.validateFieldError("vehicles", formRefModal);
                      }}
                      getOptionSelected={(option, value) => option.code === value.code}
                      getOptionLabel={(option) => `${option.code} - ${option.description}`}
                      getOptionDisabled={(option) => option.hardwares.length === 0}
                      options={optionsVehicles.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                        || -b.code.localeCompare(a.code))}
                      groupBy={(option) => option.type.description}
                      loading={loadingVehicleFilterModal}
                      value={activeVehicleFilterModal || []}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            name="undefined"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option.code} - {option.description}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="vehicles"
                          rowsMax={1}
                          multiline
                          label={t(HardwareModuleMessages.vehicle)}
                          variant="outlined"
                          helperText={t(GlobalMessages.required)}
                          InputLabelProps={{
                            shrink: activeVehicleFilterModal.length > 0
                              || formRefModal.current?.getFieldValue("vehicles")?.length > 0
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingVehicleFilterModal ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="predefinedCommand">
                    <Autocomplete
                      open={openConfigCommandContentModal}
                      onOpen={() => setOpenConfigCommandContentModal(true)}
                      onClose={() => setOpenConfigCommandContentModal(false)}
                      onChange={(event, value) => setActiveConfigCommandContentModal(value)}
                      getOptionSelected={(option, value) => option.id_config_command_content
                        === value.id_config_command_content}
                      getOptionLabel={(option) => `${option.name}`}
                      options={optionsConfigCommandContentModal.sort((a, b) => -b.name.localeCompare(a.name))}
                      loading={loadingConfigCommandContentModal}
                      value={activeConfigCommandContentModal || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="predefinedCommand"
                          label={t(HardwareModuleMessages.commandCreateDefinedCommand)}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!activeConfigCommandContentModal
                              || formRefModal.current?.getFieldValue("predefinedCommand")?.length > 0
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingConfigCommandContentModal ? <CircularProgress color="inherit" size={20} />
                                  : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <div className="divider">&nbsp;</div>
                  <Grid item xs={12} md={12} lg={12} className="defaultCommand">
                    <Grid container spacing={1}>
                      <Grid item xs={9} md={10} lg={10} className="textfield">
                        <TextField
                          label={t(HardwareModuleMessages.commandCreateToSendCommand)}
                          variant="outlined"
                          name="defaultCommand"
                          onChange={(event) => {

                            setActiveDefaultConfigCommand(event.target.value);
                            validations.validateFieldError("defaultCommand", formRefModal);

                            // If default commands is changed, remove predefined command from dropdown
                            if (event.target.value !== activeConfigCommandContentModal?.command) {
                              setActiveConfigCommandContentModal(null);
                            }
                          }}
                          value={activeDefaultConfigCommand}
                          InputLabelProps={{ shrink: activeDefaultConfigCommand.length > 0 }}
                          helperText={t(GlobalMessages.required)}
                        />
                      </Grid>
                      <Grid item xs={3} md={2} lg={2} className="applied">
                        <ButtonLoading
                          type="button"
                          loading={false}
                          onClick={() => handleApplyDefaultCommandInVehiclesList(activeDefaultConfigCommand)}
                        >{t(GlobalMessages.apply)}
                        </ButtonLoading>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="vehicleCommandsList">
                    <Scope path="vehicleCommands">
                      {
                        activeVehicleFilterModal.length > 0
                          ? activeVehicleFilterModal.map((vehicle) => (
                            <div className="vehicleCommandsItem" key={vehicle.id_vehicle}>
                              <div className="icon">{utils.getVehicleIconAccordingType(vehicle.type.id_vehicle_type)}
                              </div>
                              <div className="textfield">
                                <TextField
                                  label={`${t(HardwareModuleMessages.command)} - ${vehicle.code} - ${vehicle.description}`}
                                  variant="outlined"
                                  margin="dense"
                                  onChange={() => validations.validateFieldError(`vehicleCommands.${vehicle.id_vehicle}`,
                                    formRefModal)}
                                  name={vehicle.id_vehicle}
                                  InputLabelProps={{ shrink: true }}
                                  helperText={t(GlobalMessages.required)}
                                />
                              </div>
                              <Button
                                className="removeVehicle"
                                onClick={() => handleRemoveVehicleOptionCommandModal(vehicle)}
                              >
                                <IndeterminateCheckBoxIcon />
                              </Button>
                            </div>
                          ))
                          : <div className="noVehicleSelected">{t(HardwareModuleMessages.commandCreateNoVehicle)}</div>
                      }
                    </Scope>
                  </Grid>
                </Grid>
              </DialogContentText>
            </Form>
          </DialogContent>
          <DialogActions className="mFooter">
            <div className="actions">
              <Button
                disableRipple
                type="submit"
                color="primary"
                onClick={() => formRefModal.current?.submitForm()}
              >{t(GlobalMessages.register)}
              </Button>
            </div>
            <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
          </DialogActions>
        </Dialog>
      </ContainerModalFormCommand>
    </>
  );
};

export default HardwaresList;
