export enum DriverModuleMessages {

  /* region Frontend messages */
  deleteConfirmation = "driverModule.deleteConfirmation",
  deleteConfirmationText = "driverModule.deleteConfirmationText",
  deleteConfirmationHolder = "driverModule.deleteConfirmationHolder",
  detailsTitle = "driverModule.detailsTitle",
  detailsActive = "driverModule.detailsActive",
  document = "driverModule.document",
  re = "driverModule.re",
  licenseNumber = "driverModule.licenseNumber",
  licenseExpiredDate = "driverModule.licenseExpiredDate",
  detailsDocument = "driverModule.detailsDocument",
  detailsAvaiableGroup = "driverModule.detailsAvaiableGroups",
  detailsUsedGroup = "driverModule.detailsUsedGroups",
  detailsIButtonCode = "driverModule.detailsIButtonCode",
  fullname = "driverModule.fullname",
  iButton = "driverModule.iButton",
  dataTableExpiredDocument = "driverModule.dataTableExpiredDocument",
  invalidCPF = "driverModule.invalidCPF",
  invalidUniqueCPF = "driverModule.invalidUniqueCPF",
  invalidUniqueRE = "driverModule.invalidUniqueRE",
  formRequiredName = "driverModule.formRequiredName",
  formIncompleteDocument = "driverModule.formIncompleteDocument",
  formRequiredDocument = "driverModule.formRequiredDocument",
  /* endregion Frontend messages */

  /* region Backend messages */
  createDriverSuccess = "driverModule.createDriverSuccess",
  readDriverSuccess = "driverModule.readDriverSuccess",
  updateDriverSuccess = "driverModule.updateDriverSuccess",
  deleteDriverSuccess = "driverModule.deleteDriverSuccess",
  createDriverError = "driverModule.createDriverError",
  readDriverError = "driverModule.readDriverError",
  noDriverFound = "driverModule.noDriverFound",
  driverNotFound = "driverModule.driverNotFound",
  updateDriverError = "driverModule.updateDriverError",
  deleteDriverError = "driverModule.deleteDriverError",

  /* region Integration messages */
  externalIDAlreadyRegistered = "driverModule.externalIDAlreadyRegistered",
  documentAlreadyRegistered = "driverModule.documentAlreadyRegistered",
  REAlreadyRegistered = "driverModule.REAlreadyRegistered",
  driverLinkedToATravel = "driverModule.driverLinkedToATravel"
  /* endregion */
  /* endregion Backend messages */
}

export type TranslationDriverModuleMessages = {
  [key in DriverModuleMessages]: string;
}
