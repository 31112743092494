import styled from "styled-components";

export const Container = styled.div`

  a {
    cursor: pointer;
    margin: 0 5px;
    display: inline-block;
    padding: 0.3em 1.2em;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color: black;
    background-color: #DDDDDD;
    text-align: center;
    transition: all 0.2s;

    @media all and (max-width:30em){
      display:block;
      margin:0.2em auto;
    }
    &:hover {
      background-color:#FFFFFF;
    }
    svg {
      color: #707070;
    }
  }
  .disable {
    background-color: rgb(221 221 221 / 35%);
    cursor: not-allowed;

    &:hover {
      background-color: rgb(221 221 221 / 35%);
    }
  }
`;
