import styled from "styled-components";

export const Container = styled.div`

  .locations {

    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .write {

        .title {
          font-size: 0.8rem;
          font-weight: 500;
          color: #666666;
        }
        .text {
          font-size: 1rem;
        }
      }
      .icon {
        padding-left: 20px;

        .aux {

          svg {
            position: relative;
            top: 5px;
          }
        }
      }
    }
    .travel-data {
      margin: 10px 0;

      .MuiAccordion-root {
        box-shadow: none;
        margin: 0;

        .MuiAccordionSummary-root {
          padding: 0;
          height: 23px;
          min-height: 23px;

          .MuiTypography-root {
            color: #666666;
            font-weight: 500;
            font-size: 0.8rem;
          }
        }
        .MuiAccordionDetails-root {
          display: block;

          .MuiTypography-root {

            .item {
              padding: 0;
            }
          }
        }
        ::before {
          display: none;
        }
      }
    }
    .extra-data {
      display: flex;
      justify-content: space-between;

      .docs-data {
        display: flex;

        .box {
          padding-right: 20px;
        }
      }
      .travel-date {
        text-align: right;
      }
    }
  }
`;

export const ContainerTravelDataListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5px 0;

  .title {
    color: #666666;
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
