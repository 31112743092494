import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

import Header from "src/components/Header";
import FloatingMenu from "src/components/FloatingMenu";
import { MapAllVehiclesCoords, MarkersVisualizationTypes } from "src/components/Map";
import { VehiclesStatesObj, VehicleStatesList } from "src/store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
import MapMarkerChangers from "src/components/MapMarkerChangers";

import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

import { Container, ContainerMapVehicles, ContainerToggleSwitch, Loading } from "./styles";

const SupportVehicleDashboard: React.FC = () => {

  const screen = useSelector(({ screen: state }) => state);
  const filtersDashboard = useSelector(({ filtersDashboard: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const [vehicles, setVehicles] = useState<VehiclesStatesObj>(vehiclesStates.all);
  const [supportVehicles, setSupportVehicles] = useState<VehicleStatesList>({} as VehicleStatesList);
  const [markersVisualizationType, setMarkersVisualizationType] = useState<MarkersVisualizationTypes>(
    { name: false, licensePlate: false }
  );

  // update with all vehicles regardless of header filter
  useEffect(() => {
    setVehicles(vehiclesStates.all);
  }, [filtersDashboard, vehiclesStates]);

  // When vehicle states change, get the latest support vehicle tracker coordination and update the map
  useEffect(() => {

    const allSupportVehicles = vehicles.list["Outros veiculos"];

    if (!allSupportVehicles) return;

    const supportVehiclesWithCoordinates: Vehicle[] = [];

    allSupportVehicles.forEach((vehicle) => {
      vehicle.hardwares.forEach((hardware) => {
        if (hardware.type.description === "Rastreador") {
          supportVehiclesWithCoordinates.push(
            { ...vehicle, last_coord: { latitude: hardware.last_latitude, longitude: hardware.last_longitude } }
          );
        }
      });
    });

    setSupportVehicles({ "Outros veiculos": supportVehiclesWithCoordinates });

  }, [vehicles]);

  return (
    <>
      <Header title="" />
      {vehicles.loading
        ? (
          <Loading>
            <CircularProgress />
          </Loading>
        )
        : (
          <Container platform={screen.platform as ScreenPlatform}>
            <ContainerToggleSwitch>
              <MapMarkerChangers
                markersVisualizationType={markersVisualizationType}
                setMarkersVisualizationType={setMarkersVisualizationType}
              />
            </ContainerToggleSwitch>
            <ContainerMapVehicles id="modalMapAllVehicles">
              <MapAllVehiclesCoords
                vehicles={supportVehicles}
                mapHeight="80vh"
                markersVisualizationType={markersVisualizationType}
              />
            </ContainerMapVehicles>
          </Container>
        )}
      {
        screen.platform === "desktop" && <FloatingMenu />
      }
    </>
  );
};

export default SupportVehicleDashboard;
