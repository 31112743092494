import styled from "styled-components";

export const ContainerPopperVehiclesInMaintenance = styled.div`

  .popper {

    .popperContent {
      width: 350px;
      max-width: 400px;
      overflow-y: scroll;
      max-height: 85vh;
      overflow-x: hidden;
      padding: 15px;

      .titleH {
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 300;
      }

      .vehicle-summary__accordion {
        box-shadow: none;
        margin: 0;

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0 0 0;
        }
      }
    }
  }
`;
