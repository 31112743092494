/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { LocationsFormTypes, LocationsFormState } from "./locations-form.type";

const LocationsFormActions = {
  setLocationsForm: (locationsForm: LocationsFormState | undefined) => action(LocationsFormTypes.SET_LOCATIONS_FORM, locationsForm)
};

export { LocationsFormActions };
