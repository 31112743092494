import { AverageSpeedMessages, TranslationAverageSpeedMessages } from "../interfaces"

export const messages: TranslationAverageSpeedMessages = {
  [AverageSpeedMessages.averageSpeedHistoryTitle]: "Histórico V. Média",
  [AverageSpeedMessages.averageSpeedByDriver]: "V. Média por Motorista",
  [AverageSpeedMessages.averageSpeedByVehicle]: "V. Média por Veículo",
  [AverageSpeedMessages.startDate]: "Data Inicial",
  [AverageSpeedMessages.finishDate]: "Data Final",
  [AverageSpeedMessages.distance]: "Distância",
  [AverageSpeedMessages.averageSpeedColumn]: "Velocidade Média",
  [AverageSpeedMessages.Vehicles]: "Veículo",
  [AverageSpeedMessages.Drivers]: "Motorista",
  [AverageSpeedMessages.VehicleType]: "Veículos do tipo",

  [AverageSpeedMessages.noAverageFound]: "Nenhuma média encontrada",
  [AverageSpeedMessages.readAverageSuccess]: "Média lida com sucesso",
  [AverageSpeedMessages.readAverageError]: "Erro ao ler média"

}
