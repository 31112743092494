import styled from "styled-components";

export const ContainerModalMapAllVehicles = styled.div`

  .MuiPaper-root {
    max-width: 100%;
    width: 90%;

    .mHeader {

      .MuiTypography-root {
        font-size: 1rem;
        font-weight: 200;
        color: #666666;
      }
    }

    .mContent {

      .gm-style-iw { // Google info window
        padding: 0 !important;
        max-width: 250px !important;

        .gm-style-iw-d {

          .vehicle-summary__accordion {
            margin: 0;
            width: 100%;

            .gm-style-iw {
              padding: 20px !important;
            }
          }
        }
      }
    }

    .mFooter {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
