import styled from "styled-components";
import BlurMap from "../../../assets/blur-map.jpg";

export const Container = styled.div`

  .MuiAccordion-root {
    box-shadow: none;

    .boxHeaderItemsEvent {
      padding: 0;

      .MuiAccordionSummary-content {
        align-items: center;
        display: block;
        margin-bottom: 0;

        .content {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 0 15px 0;

          .boxHeaderItemsIcon {

            svg {
              width: 30px;
              height: 30px;
            }
          }

          .boxHeaderItemsDescription {
            width: 100%;
            padding-left: 15px;
            display: flex;
            flex-direction: column;

            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .type {
                padding-right: 10px;
                width: 8rem;
              }
            }

            .typeAndDuration {
              font-weight: 600;
              font-size: 1rem;

              .duration {
                display: flex;
                width: 100px;
                justify-content: flex-end;
              }
            }

            .startDate, .finishDate, .speed {
              font-size: 0.8rem;
              color: black;
            }

            .startDate {
              color: rgb(0 0 0 / 60%);
            }
          }
        }
      }
    }

    .boxContentItemsEvent {
      padding: 8px 16px 0 16px;
      display: block;

      .maps {
        margin-bottom: 0 !important;

        .hidden {
          display: none !important;
        }

        .staticMap {
          height: 250px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url(${BlurMap});
          background-repeat: no-repeat;
          background-size: cover;

          img {
            cursor: pointer;
            width: 40px;
            border-radius: 50%;
            font-weight: bold;
            background-color: white;
            /* box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%); */
          }
        }

        .drawerMap {
          height: 250px !important;
        }
      }

      .actions {
        margin: 5px 0;
        text-align: end;
      }
    }
    .MuiSvgIcon-root {
      width: 0.4em;
      height: 0.4em;
  }
`;
