/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { ScreenTypes, ScreenState } from "./screen.type";

const ScreenActions = {
  setScreenSize: (screen: ScreenState | undefined) => action(ScreenTypes.SET_SCREEN_SIZE, screen)
};

export { ScreenActions };
