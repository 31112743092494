import { createStore, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./ducks/root.reducer";

import { VehiclesStatesState } from "./ducks/Vehicles/VehiclesStates/vehicles-states.type";
import { AlertsState } from "./ducks/Alerts/alerts.type";
import { LocationsFormState } from "./ducks/Locations/LocationsForm/locations-form.type";
import { FiltersDashboardState } from "./ducks/Filters/FiltersDashboard/filters-dashboard.type";
import { ScreenState } from "./ducks/Screen/screen.type";
import { ParamsState } from "./ducks/Params/params.type";

export interface ApplicationState {
  vehiclesStates: VehiclesStatesState,
  alerts: AlertsState,
  locationsForm: LocationsFormState,
  filtersDashboard: FiltersDashboardState,
  screen: ScreenState,
  params: ParamsState
}

const store: Store<ApplicationState> = createStore(rootReducer, applyMiddleware(thunk));

export default store;
