import { TravelsModuleMessages, TranslationTravelsModuleMessages } from "../interfaces";

export const messages: TranslationTravelsModuleMessages = {
  [TravelsModuleMessages.createTravelSuccess]: "Viaje registrado con éxito",
  [TravelsModuleMessages.createTravelError]: "Falla al registrar nuevo viaje",
  [TravelsModuleMessages.readTravelSuccess]: "Viaje(s) devuelto(s) con éxito",
  [TravelsModuleMessages.noTravelFound]: "Ningún viaje encontrado",
  [TravelsModuleMessages.readTravelError]: "Falla al buscar viaje(s)",
  [TravelsModuleMessages.updateTravelSuccess]: "Viaje alterado con éxito",
  [TravelsModuleMessages.travelNotFound]: "Viaje no registrado en nuestro sistema",
  [TravelsModuleMessages.updateTravelError]: "Falla al alterar viaje",
  [TravelsModuleMessages.deleteTravelSuccess]: "Viaje excluido con éxito",
  [TravelsModuleMessages.deleteTravelError]: "Falla al excluir viaje",
  [TravelsModuleMessages.swapTravelsSuccess]: "Viajes cambiados con éxito",
  [TravelsModuleMessages.swapTravelsError]: "Falla al cambiar viajes",
  [TravelsModuleMessages.getTravelAnalyticsSuccess]: "Datos de análisis devueltos con éxito. Viaje: ",
  [TravelsModuleMessages.getTravelAnalyticsError]: "Falla al buscar datos de análisis del viaje",
  [TravelsModuleMessages.getTravelAnalyticsVeryLargeTravel]: "Viaje demasiado extenso para visualización en el mapa",
  [TravelsModuleMessages.getTravelFilterOptionsSuccess]: "Opciones de filtro de viaje devueltas con éxito",
  [TravelsModuleMessages.noTravelFilterOptionsFound]: "Ninguna obra registrada para ese filtro de viaje",
  [TravelsModuleMessages.getTravelFilterOptionsError]: "Falla al buscar obra(s)",
  [TravelsModuleMessages.getTravelCoordinatesSuccess]: "Coordenadas de viaje devueltas con éxito",
  [TravelsModuleMessages.getTravelCoordinatesError]: "Falla al buscar coordenadas de viaje",
  [TravelsModuleMessages.noTravelCoordinatesFound]: "Ninguna coordenada de viaje encontrada",

  /* region Integration messages */
  [TravelsModuleMessages.vehicleCurrentInTravel]: "Vehículo(s) ya está en un viaje actualmente. Datos del viaje en cuestión actualizados",
  [TravelsModuleMessages.originLocationNotRegistered]: "Local(es) de origen no registrado(s)",
  [TravelsModuleMessages.vehicleNotRegistered]: "Vehículo(s) no registrado(s)",
  [TravelsModuleMessages.driverNotRegistered]: "Conductor(es) no registrado(s)",
  [TravelsModuleMessages.destinationLocationNotRegistered]: "Local(es) de destino no registrado(s)",
  [TravelsModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) ya registrado",
  [TravelsModuleMessages.invalidDatePeriod]: "Período de fecha inválido",
  [TravelsModuleMessages.travelLinkedToAVehicle]: "Viaje ya vinculado en algún vehículo, su exclusión no puede ser"
  + " hecha",
  [TravelsModuleMessages.readPositionSuccess]: "Posicionamiento devuelto con éxito",
  [TravelsModuleMessages.travelFinishedOrNonexistent]: "El viaje ya fue terminado o no existe",
  [TravelsModuleMessages.needToInformIDOrExternalID]: "Es necesario informar el ID o el ID externo del vehículo",
  [TravelsModuleMessages.maxSearchInterval]: "Intervalo máximo de búsqueda es",
  [TravelsModuleMessages.days]: "días",
  [TravelsModuleMessages.analyticVehicleData]: "Datos analíticos del vehículo",
  [TravelsModuleMessages.returned]: "devueltos con éxito",
  [TravelsModuleMessages.travelPreviouslyCancelled]: "Viaje ya fue cancelado anteriormente",
  [TravelsModuleMessages.cancelTravelSuccess]: "Viaje cancelado con éxito"
  /* endregion */
};
