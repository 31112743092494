/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { AggregateTrucksStatesObj, AggregateTrucksStatesTypes } from "./aggregate-trucks-states.type";

const AggregateTrucksStatesActions = {
  setAggregateTrucksStatesAll: (vehicleStatesAll: AggregateTrucksStatesObj | undefined) => action(AggregateTrucksStatesTypes.SET_AGGREGATE_TRUCKS_STATES_ALL, vehicleStatesAll),
  setAggregateTrucksStatesFiltered: (vehicleStatesFiltered: AggregateTrucksStatesObj | undefined) => action(AggregateTrucksStatesTypes.SET_AGGREGATE_TRUCKS_STATES_FILTERED, vehicleStatesFiltered)
};

export { AggregateTrucksStatesActions };
