import { ParamsMessages, TranslationParamsMessages } from "../interfaces";

export const messages: TranslationParamsMessages = {
  [ParamsMessages.readParamsSuccess]: "Parâmetro(s) retornado(s) com sucesso",
  [ParamsMessages.noParamsFound]: "Nenhum parâmetro cadastrado",
  [ParamsMessages.readParamsError]: "Falha ao buscar parâmetro(s)",
  [ParamsMessages.updateParamsSuccess]: "Parâmetro(s) atualizado(s) com sucesso",
  [ParamsMessages.updateParamsError]: "Falha ao atualizar parâmetros",
  [ParamsMessages.insertDataBlockSuccess]: "Data de bloqueio definida com sucesso",
  [ParamsMessages.insertDataBlockError]: "Falha ao inserir parametro de bloqueio"

};
