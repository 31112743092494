import styled from "styled-components";

export const ContainerPopperFilterVehiclesDashboard = styled.div`
  .popper {

    .popperContent {
      max-width: 400px;
      overflow-y: scroll;
      max-height: 85vh;
      overflow-x: hidden;
      padding: 15px;

      .title {
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 300;
      }

      .form {

        .MuiGrid-item {
          margin: 5px;
        }

        .vehiclesTypes {

          .MuiFormLabel-root {
            color: #707070;
          }

          .MuiFormGroup-root {
            flex-direction: initial;
            justify-content: space-between;

            .MuiCheckbox-root {
              color: #707070;
            }

            .last {
              margin-right: 36px;

              @media (max-width: 800px) {
                // Mobile
                margin-right: 34px;
              }
            }
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .applyFilterButton {
            width: fit-content;
            float: right;
          }
        }
      }
    }
  }
`;
