import styled from "styled-components";

export const Container = styled.div`

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 1.5rem;

    .date {
      font-size: 1.2rem;
    }
  }
  .vertical-timeline {
    padding-bottom: 0;
    padding-top: 0.5em;

    ::before {
      height: calc(100% - 110px);
      top: 22px;
    }

    .vertical-timeline-element {
      margin: 10px 0;

      .vertical-timeline-element-date {
        padding-bottom: 0;
        font-size: 0.8rem;
      }
      .vertical-timeline-element-icon {
        box-shadow: none;

        svg {
          margin-top: -15px;
          width: 40px;
          height: auto;
          position: initial;
          margin-left: auto;
        }
        div:first-child {
          position: relative;
          top: 50%;
        }
      }
      .vertical-timeline-element-content {

        .vertical-timeline-element-date {
          display: none;
        }
        .duration-title {
          font-weight: 300;
          font-size: 1.2rem;
        }
        .block {

          .content {
            padding: 0;
            display: flex;
            justify-content: space-between;
          }
        }
        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            display: flex;
            font-weight: bold;
            align-items: center;
            padding-left: 15px;

            svg {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .vertical-timeline::before {
    background: #DDDDDD !important;
  }
`;
