import React, { useState } from "react";
import Loading from "react-fullscreen-loading";

// region Imports - External libraries
import { Grid } from "@material-ui/core";
// endregion Imports - External libraries
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  FilterMessages,
  VehiclesModuleMessages,
  VehicleStatusMessages,
  VehicleTypesMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Molecules
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Molecules
// region Imports - Organisms
import QueryFilterVehicleAnalyticReport from "@organisms/QueryFilterVehicleAnalyticReport";
// endregion Imports - Organisms
// region Imports - Shared
import { VehicleAnalyticReport as IVehicleAnalyticReport } from "@shared/interfaces/vehicle-analytic-report.interface";
// endregion Imports - Shared
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Styles
import { Container } from "./styles";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import ReactDOMServer from "react-dom/server";
// endregion Imports - Styles

const VehicleAnalyticReport: React.FC = () => {
  // region States

  // Filter states
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  const [vehicleAnalyticReportList, setVehicleAnalyticReportList] = useState<IVehicleAnalyticReport[]>([] as Array<IVehicleAnalyticReport>);

  const [isLoading, setIsLoading] = useState(false);
  // endregion States
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region Constants
  const dataTableColumns: DataTableColumns[] = [
    { // Grupo
      title: t(VehiclesModuleMessages.group),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.groupLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "groupLabel"
    },
    { // Regional
      title: t(VehiclesModuleMessages.regional),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.regionalLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "regionalLabel"
    },
    { // Usina
      title: t(VehiclesModuleMessages.plant),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.plantLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "plantLabel"
    },
    { // Código do veículo
      title: t(VehiclesModuleMessages.code),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.vehicleCode,
      filterable: true,
      defaultContent: "",
      propertyName: "vehicleCode"
    },
    { // Placa do veículo
      title: t(VehiclesModuleMessages.licensePlate),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.vehicleLicensePlate,
      filterable: true,
      defaultContent: "",
      propertyName: "vehicleLicensePlate"
    },
    { // Descrição do veículo
      title: t(VehiclesModuleMessages.description),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.vehicleDescription,
      filterable: true,
      defaultContent: "",
      propertyName: "vehicleDescription"
    },
    { // Tipo de veículo
      title: t(VehiclesModuleMessages.vehicleType),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => t(VehicleTypesMessages[vehicleAnalytic.vehicleTypeId!]),
      filterable: true,
      defaultContent: "",
      propertyName: "vehicleTypeLabel"
    },
    { // Data da última comunicação
      title: t(VehiclesModuleMessages.lastCommunicationDate),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => utils.formatDateIfHave(vehicleAnalytic.trackerLastSendDate, "fullDate"),
      filterable: true,
      defaultContent: "",
      propertyName: "trackerLastSendDate"
    },
    { // Última comunicação
      title: t(VehiclesModuleMessages.lastCommunication),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => {
        if (vehicleAnalytic.trackerLastSendDate) {
          return utils.formatDateIfHave(
            utils.calcDataRange(vehicleAnalytic.trackerLastSendDate, new Date()),
            "durationDescriptiveTime",
            { durationDescriptiveTime: { onlyMinutes: true } }
          );
        }

        return "";
      },
      filterable: true,
      defaultContent: "",
      propertyName: "trackerLastSend"
    },
    { // GPS ativo
      title: t(VehiclesModuleMessages.activeHardware),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => (
        ReactDOMServer.renderToString(
          <b style={{ color: vehicleAnalytic.activeTracker ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {vehicleAnalytic.activeTracker ? t(VehiclesModuleMessages.active).toUpperCase() : t(VehiclesModuleMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (data: Vehicle) => (data.active
        ? t(VehiclesModuleMessages.active).toUpperCase()
        : t(VehiclesModuleMessages.inactive).toUpperCase()),
      filterable: true,
      defaultContent: "",
      propertyName: "activeTracker"
    },
    { // Ignição
      title: t(VehiclesModuleMessages.ignition),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => (
        ReactDOMServer.renderToString(
          <b style={{ color: vehicleAnalytic.currentIgnition ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {vehicleAnalytic.currentIgnition ? t(VehicleStatusMessages.engineOn).toUpperCase() : t(VehicleStatusMessages.engineOff).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (data: Vehicle) => (data.active
        ? t(VehicleStatusMessages.engineOn).toUpperCase()
        : t(VehicleStatusMessages.engineOff).toUpperCase()),
      filterable: true,
      defaultContent: "",
      propertyName: "currentIgnition"
    },
    { // Última ignição
      title: t(VehiclesModuleMessages.lastIgnitionDate),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => {
        if (!vehicleAnalytic.currentIgnition) return utils.formatDateIfHave(vehicleAnalytic.lastIgnitionDate, "fullDate");

        return "";
      },
      filterable: true,
      defaultContent: "",
      propertyName: "lastIgnitionDate"
    },
    { // Latitude atual do veículo
      title: t(VehiclesModuleMessages.currentLatitude),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.currentLatitude,
      filterable: true,
      defaultContent: "",
      propertyName: "currentLatitude"
    },
    { // Longitude atual do veículo
      title: t(VehiclesModuleMessages.currentLongitude),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.currentLongitude,
      filterable: true,
      defaultContent: "",
      propertyName: "currentLongitude"
    },
    { // Endereço atual do veículo
      title: t(VehiclesModuleMessages.currentAddress),
      data: (vehicleAnalytic: IVehicleAnalyticReport) => vehicleAnalytic.currentAddress,
      filterable: true,
      defaultContent: "",
      propertyName: "currentAddress"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print"
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_analitico_veiculos_${new Date().toISOString().split("T")[0]}`
    }
  ];
  // endregion Constants
  // region Functions
  /**
   * Handle apply filter to get vehicle states history
   * @param numberOfFilteredOptions
   * @param returnedData
   */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: IVehicleAnalyticReport[]
  ) => {
    setIsLoading(true);

    setNumberOfFilteredOptions(numberOfFilteredOptions);
    setVehicleAnalyticReportList(returnedData);

    setIsLoading(false);
  };
  // endregion Functions

  return (
    <>
      <Container>
        <Loading loading={isLoading} />
        {!isLoading && (
          <NewDataTable
            title={t(FilterMessages.applicationVehicleAnalyticReport)}
            filters
            data={vehicleAnalyticReportList}
            columns={dataTableColumns}
            buttons={dataTableButtons}
            onClickFilterButton={() => setOpenFilterFields(true)}
            numberOfFilteredOptions={numberOfFilteredOptions}
          />
        )}
        <QueryFilterVehicleAnalyticReport
          open={openFilterFields}
          onClose={() => { setOpenFilterFields(false); }}
          onFilter={handleApplyFilter}
        />
        {/* Hidden Grid component to inject styles from "MuiGrid" */}
        <Grid style={{ display: "none" }} />
      </Container>
    </>
  );
};

export default VehicleAnalyticReport;
