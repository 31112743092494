import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
}

const className = "vehicle-maintenance__form";

const SetTravelSpeedAlertForm = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
  }

  .card {
   padding: 1rem;
    border-radius: 8px;
    border: 1px solid #c7c7c7;
    border-left: 0;
    mso-border-shadow: 0 2px 4px rgb(33 32 32 / 10%);
    //box-shadow: 0 2px 4px rgb(33 32 32 / 10%);
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .container-travel-speed-form__justify {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22rem;
  }

  .travel-speed-form__instruction {
    display: flex;
    line-height: 1.4;
    align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
    gap: 0.2rem;

    .strong {
      color: #666666;
    }
  }
  .fullSpeedTravel_button{
    width: 13rem;
    height: 3rem;
    text-transform: none;
    padding: 0.75rem;
    border-radius: 5px;
  }
  .red_button{
    background-color: red;
    color: white;
  }
  .fullSpeedTravel_button:disabled {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  .travel-speed_button {
    color: white;
    background-color: #666666;
    border-radius: 8px;
    padding: 6px 32px 6px 32px;

    svg {
      color: inherit;
      margin-right: 2rem;
    }
  }

`;

export { SetTravelSpeedAlertForm };
