import React, { useState } from "react";
import Loading from "react-fullscreen-loading";

// region Imports - External libraries
import { Grid } from "@material-ui/core";
// endregion Imports - External libraries
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { DataTableMessages, MenuMessages, VehiclesStatesMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Components
import Header from "@components/Header";
// endregion Imports - Components
// region Imports - Molecules
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Molecules
// region Imports - Organisms
import QueryFilterVehicleStatesHistory from "@organisms/QueryFilterVehicleStatesHistory";
// endregion Imports - Organisms
// region Imports - Shared
import { VehicleStateHistory } from "@shared/interfaces/vehicle-state-history.interface";
// endregion Imports - Shared
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Styles
import { Container } from "./styles";
// endregion Imports - Styles

const VehicleStatesHistory: React.FC = () => {
  // region States

  // Filter states
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  const [vehicleStateHistory, setVehicleStateHistory] = useState<VehicleStateHistory[]>([] as Array<VehicleStateHistory>);

  const [isLoading, setIsLoading] = useState(false);
  // endregion States
  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks
  // region Constants
  const dataTableColumns: DataTableColumns[] = [
    { // Data de início
      title: t(VehiclesStatesMessages.columnStartDate),
      data: (state: VehicleStateHistory) => state.startDate,
      filterable: true,
      defaultContent: "",
      propertyName: "state.startDate"
    },
    { // Data de fim
      title: t(VehiclesStatesMessages.columnEndDate),
      data: (state: VehicleStateHistory) => state.endDate,
      filterable: true,
      defaultContent: "",
      propertyName: "state.endDate"
    },
    { // Duração
      title: t(VehiclesStatesMessages.columnDuration),
      data: (state: VehicleStateHistory) => state.duration,
      filterable: true,
      defaultContent: "",
      propertyName: "state.duration"
    },
    { // Grupo
      title: t(VehiclesStatesMessages.columnGroup),
      data: (state: VehicleStateHistory) => state.groupLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.groupLabel"
    },
    { // Regional
      title: t(VehiclesStatesMessages.columnRegional),
      data: (state: VehicleStateHistory) => state.regionalLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.regionalLabel"
    },
    { // Usina
      title: t(VehiclesStatesMessages.columnPlant),
      data: (state: VehicleStateHistory) => state.plantLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.plantLabel"
    },
    { // Tipo de veículo
      title: t(VehiclesStatesMessages.columnVehicleType),
      data: (state: VehicleStateHistory) => state.vehicleTypeLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.vehicleTypeLabel"
    },
    { // Veículo
      title: t(VehiclesStatesMessages.columnVehicle),
      data: (state: VehicleStateHistory) => state.vehicleCode,
      filterable: true,
      defaultContent: "",
      propertyName: "state.vehicleCode"
    },
    { // Placa do veículo
      title: t(VehiclesStatesMessages.columnVehicleLicensePlate),
      data: (state: VehicleStateHistory) => state.vehicleLicensePlate,
      filterable: true,
      defaultContent: "",
      propertyName: "state.vehicleLicensePlate"
    },
    { // Tipo de estado
      title: t(VehiclesStatesMessages.columnVehicleStateType),
      data: (state: VehicleStateHistory) => state.vehicleStateTypeLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.vehicleStateTypeLabel"
    },
    { // Local
      title: t(VehiclesStatesMessages.columnLocation),
      data: (state: VehicleStateHistory) => state.locationLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.locationLabel"
    },
    { // Tipo de local
      title: t(VehiclesStatesMessages.columnLocationType),
      data: (state: VehicleStateHistory) => state.locationTypeLabel,
      filterable: true,
      defaultContent: "",
      propertyName: "state.locationTypeLabel"
    },
    { // Latitude atual do veículo
      title: t(VehiclesStatesMessages.columnCurrentLatitude),
      data: (state: VehicleStateHistory) => state.currentLatitude,
      filterable: true,
      defaultContent: "",
      propertyName: "state.currentLatitude"
    },
    { // Longitude atual do veículo
      title: t(VehiclesStatesMessages.columnCurrentLongitude),
      data: (state: VehicleStateHistory) => state.currentLongitude,
      filterable: true,
      defaultContent: "",
      propertyName: "state.currentLongitude"
    },
    { // Endereço atual do veículo
      title: t(VehiclesStatesMessages.columnCurrentAddress),
      data: (state: VehicleStateHistory) => state.currentAddress,
      filterable: true,
      defaultContent: "",
      propertyName: "state.currentAddress",
      className: vehicleStateHistory.length > 0 ? "minWidthAddressColumn" : ""
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print"
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_estados_${new Date().toISOString().split("T")[0]}`
    }
  ];
  // endregion Constants
  // region Functions
  /**
   * Handle apply filter to get vehicle states history
   * @param numberOfFilteredOptions
   * @param returnedData
   */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: VehicleStateHistory[]
  ) => {
    setIsLoading(true);

    setNumberOfFilteredOptions(numberOfFilteredOptions);
    setVehicleStateHistory(returnedData);

    setIsLoading(false);
  };
  // endregion Functions

  return (
    <>
      <Header title={t(MenuMessages.vehicleStatesHistory)} />
      <Container>
        <Loading loading={isLoading} />
        {!isLoading && (
          <NewDataTable
            title={t(MenuMessages.vehicleStatesHistory)}
            filters
            data={vehicleStateHistory}
            columns={dataTableColumns}
            buttons={dataTableButtons}
            onClickFilterButton={() => setOpenFilterFields(true)}
            numberOfFilteredOptions={numberOfFilteredOptions}
          />
        )}
        <QueryFilterVehicleStatesHistory
          open={openFilterFields}
          onClose={() => { setOpenFilterFields(false); }}
          onFilter={handleApplyFilter}
        />
        {/* Hidden Grid component to inject styles from "MuiGrid" */}
        <Grid style={{ display: "none" }} />
      </Container>
    </>
  );
};

export default VehicleStatesHistory;
