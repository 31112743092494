/* eslint-disable import/prefer-default-export */
import { ServiceOrderMessages, TranslationServiceOrderMessages } from "../interfaces";

export const messages: TranslationServiceOrderMessages = {

  /* region Frontend messages */
  [ServiceOrderMessages.newServiceOrder]: "New Service Order",
  [ServiceOrderMessages.expectedDate]: "Expected date",
  [ServiceOrderMessages.serviceOrderType]: "Service Order Type",
  [ServiceOrderMessages.delivery]: "Delivery",
  [ServiceOrderMessages.pickup]: "Pickup",
  [ServiceOrderMessages.product]: "Product",
  [ServiceOrderMessages.binder]: "Binder",
  [ServiceOrderMessages.smallAggregate]: "Small Aggregate",
  [ServiceOrderMessages.coarseAggregate]: "Coarse Aggregate",
  [ServiceOrderMessages.unit]: "Unit",
  [ServiceOrderMessages.transportDocument]: "Transport Document",
  [ServiceOrderMessages.productDocument]: "Product Document",
  [ServiceOrderMessages.originLocation]: "Origin Location",
  [ServiceOrderMessages.loadingLocation]: "Loading Location",
  [ServiceOrderMessages.unloadingLocation]: "Unloading Location",
  [ServiceOrderMessages.quantity]: "Quantity",
  /* endregion Frontend messages */
  /* region Backend messages */
  [ServiceOrderMessages.createServiceOrderSuccess]: "Service order created successfully",
  [ServiceOrderMessages.createServiceOrderError]: "Error creating new service order"
  /* endregion Backend messages */
};
