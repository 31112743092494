import { ChangeType } from "../../types/change_type.enum";

export enum PumpProgrammingMessages {
  createPumpProgrammingSuccess = "pumpProgramming.createPumpProgrammingSuccess",
  createPumpProgrammingError = "pumpProgramming.createPumpProgrammingError",
  readPumpProgrammingSuccess = "pumpProgramming.readPumpProgrammingSuccess",
  noPumpProgrammingFound = "pumpProgramming.noPumpProgrammingFound",
  readPumpProgrammingError = "pumpProgramming.readPumpProgrammingError",
  updatePumpProgrammingSuccess = "pumpProgramming.updatePumpProgrammingSuccess",
  updatePumpProgrammingError = "pumpProgramming.updatePumpProgrammingError",
  cancelPumpProgrammingSuccess = "pumpProgramming.cancelPumpProgrammingSuccess",
  cancelPumpProgrammingError = "pumpProgramming.cancelPumpProgrammingError",
  noChangePumpProgrammingInProgress = "pumpProgramming.noChangePumpProgrammingInProgress",
  readPumpProgrammingHistorySuccess = "pumpProgramming.readPumpProgrammingHistorySuccess",
  readPumpProgrammingHistoryError = "pumpProgramming.readPumpProgrammingHistoryError",
  noPumpProgrammingHistoryFound = "pumpProgramming.noPumpProgrammingHistoryFound",

  pumpProgrammingTitle = "pumpProgramming.pumpProgrammingTitle",
  dataTableTitle = "pumpProgramming.dataTableTitle",

  tabProgrammings = "pumpProgramming.tabProgrammings",
  tabImports = "pumpProgramming.tabImports",
  tabHistory = "pumpProgramming.tabHistory",

  columnDescription = "pumpProgramming.column.description",
  columnClient = "pumpProgramming.column.client",
  columnIntegrationDate = "pumpProgramming.column.integrationDate",
  columnDocumentDate = "pumpProgramming.column.documentDate",
  columnDoc = "pumpProgramming.column.doc",
  columnExternalId = "pumpProgramming.column.externalId",
  columnOrigin = "pumpProgramming.column.origin",
  columnVehicle = "pumpProgramming.column.vehicle",
  columnDriver = "pumpProgramming.column.driver",
  columnMaxSpeed = "pumpProgramming.column.maxSpeed",
  columnUser = "pumpProgramming.column.user",
  columnOccurrenceDate = "pumpProgramming.column.occurrenceDate",
  columnChangeType = "pumpProgramming.column.changeType",
  columnImportRef = "pumpProgramming.column.importRef",

  cancelConfirmationTitle = "pumpProgramming.cancelConfirmationTitle",
  cancelConfirmationText = "pumpProgramming.cancelConfirmationText",

  newPumpProgramming = "pumpProgramming.newPumpProgramming",

  changeTypeCreate = "pumpProgramming.changeType.create",
  changeTypeUpdate = "pumpProgramming.changeType.update",
  changeTypeCancel = "pumpProgramming.changeType.cancel",
  changeTypeDelete = "pumpProgramming.changeType.delete"
}

export type TranslationPumpProgrammingMessages = {
  [key in PumpProgrammingMessages]: string;
}

export const ChangeTypeMessages = {
  [ChangeType.CREATE]: PumpProgrammingMessages.changeTypeCreate,
  [ChangeType.UPDATE]: PumpProgrammingMessages.changeTypeUpdate,
  [ChangeType.CANCEL]: PumpProgrammingMessages.changeTypeCancel,
  [ChangeType.DELETE]: PumpProgrammingMessages.changeTypeDelete
};
