/* eslint-disable no-shadow,no-unused-vars */

export enum VehicleStatesTypes {
  EM_LOCAL_CONFIAVEL = "Em local confiavel",
  NA_USINA = "Na usina",
  A_CAMINHO = "A caminho",
  EM_OBRA = "Em obra",
  DESCARREGANDO = "Descarregando",
  RETORNANDO = "Retornando",
  NO_DESTINO = "No destino"
}

export enum VehicleStatesTypesColors {
  EM_LOCAL_CONFIAVEL = "#666",
  NA_USINA = "#46C17D",
  A_CAMINHO = "#7E73F8",
  EM_OBRA = "#C7B301",
  DESCARREGANDO = "#616161",
  RETORNANDO = "#E64A19",
  NO_DESTINO = "#C7B301"
}

export enum VehicleStatesTypesID {
  EM_LOCAL_CONFIAVEL = "4fc662ca-c2f1-4241-8eb2-404257856bed",
  NA_USINA = "7f546c5e-8560-4e1b-a0c7-862580e51923",
  A_CAMINHO = "17e76911-23cf-42cf-8079-b3108fc29c7c",
  EM_OBRA = "666f75a9-af72-4c21-b2ae-9cc8fb14f081",
  DESCARREGANDO = "f265ad44-6ca2-4f16-9abe-844e58151eb5",
  RETORNANDO = "2c3e5f80-2e9d-440a-a42d-05d2b31a7456",
  NO_DESTINO = "534ae24e-e149-4339-8c10-95691867bae7"
}
