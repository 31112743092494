/* eslint-disable import/prefer-default-export */
import { VehicleBlockMessages, TranslationVehicleBlockMessages } from "../interfaces";

export const messages: TranslationVehicleBlockMessages = {
  [VehicleBlockMessages.blockedVehicle]: "Blocked vehicle",
  [VehicleBlockMessages.checkVehicleToBeBlocked]: "Check the vehicle to be blocked",
  [VehicleBlockMessages.checkVehicleToBeUnblocked]: "Check the vehicle to be unblocked",
  [VehicleBlockMessages.blockCommandSent]: "Block command sent successfully!",
  [VehicleBlockMessages.unblockCommandSent]: "Unblock command sent successfully!",
  [VehicleBlockMessages.blockProcessMonitoringInstruction]: "You can monitor the process in the toBlock history tab.",
  [VehicleBlockMessages.accessAvailableAt]: "Access available at",
  [VehicleBlockMessages.blockTabPath]: "Menu - Modules - Vehicles - Blocks",
  [VehicleBlockMessages.youAreAboutTo]: "You are about to ",
  [VehicleBlockMessages.toBlock]: "block ",
  [VehicleBlockMessages.toUnblock]: "unblock ",
  [VehicleBlockMessages.aVehicle]: "a vehicle",
  [VehicleBlockMessages.yourActionWill]: "Your action will ",
  [VehicleBlockMessages.vehicleMobilityAndFunctionality]: "the vehicle mobility and functions related to its"
  + " operation.",
  [VehicleBlockMessages.responsibilityTerms]: "Topcon expressly recommends the use of the block only with the vehicle"
  + " stopped, in accordance with the current traffic laws, and is not responsible, under any circumstances, for any"
  + " material and bodily damage, own or third parties, arising from the use of the block. By confirming the block"
  + " command, there is an express assumption of the customer regarding the risks and responsibilities!",
  [VehicleBlockMessages.justify]: "Justify ",
  [VehicleBlockMessages.ofTheVehicle]: "the vehicle",
  [VehicleBlockMessages.justifyBlock]: "Justify blocking",
  [VehicleBlockMessages.justifyUnblock]: "Justify unblocking",
  [VehicleBlockMessages.block]: "blocking ",
  [VehicleBlockMessages.unblock]: "unblocking ",
  [VehicleBlockMessages.insertPassword]: "Enter your TopconFLEET user password",
  [VehicleBlockMessages.slideToRight]: "Slide to the right",
  [VehicleBlockMessages.vehicle]: "vehicle",
  [VehicleBlockMessages.blockConfirmationTitle]: "Block confirmation",
  [VehicleBlockMessages.blockConfirmationMessage]: "Do you really want to block the vehicle?",
  [VehicleBlockMessages.user]: "User",
  [VehicleBlockMessages.creationDate]: "Creation",
  [VehicleBlockMessages.sendDate]: "Send date",
  [VehicleBlockMessages.processingDate]: "Processing date",
  [VehicleBlockMessages.status]: "Status",
  [VehicleBlockMessages.processed]: "Processed",
  [VehicleBlockMessages.sent]: "Sent",
  [VehicleBlockMessages.cancelled]: "Cancelled",
  [VehicleBlockMessages.justification]: "Justification",
  [VehicleBlockMessages.blockLocation]: "Block location",
  [VehicleBlockMessages.blockHistoryTitle]: "Block history"
};
