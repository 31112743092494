import { TranslateConfigCommandsMessages, ConfigCommandsContentID } from "../interfaces";

export const messages: TranslateConfigCommandsMessages = {
  [ConfigCommandsContentID.CHECAR_ICCID_ST4315U]: "Checar ICCID - ST4315U",
  [ConfigCommandsContentID.CHECAR_ICCID_ST380]: "Checar ICCID - ST380",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO]: "Alterar horímetro",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO]: "Alterar odômetro",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BETONEIRA]: "Alterar tempo de envio - Betoneira (60s ligado/ 1h desligado)",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BOMBA]: "Alterar tempo de envio - Bomba (60s ligado/ 60s" +
  " desligado)",
  [ConfigCommandsContentID.LIMPAR_MEMORIA]: "Limpar Memória",
  [ConfigCommandsContentID.REINICIAR]: "Reiniciar",
  [ConfigCommandsContentID.DESABILITAR_IGNICAO_FISICA]: "Desabilitar a ignição física",
  [ConfigCommandsContentID.HABILITAR_IGNICAO_FISICA]: "Habilitar a ignição física",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_BATERIA]: "Alterar ignição para bateria",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_ACELEROMETRO]: "Alteração ignição para acelerômetro",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS]: "Alteração de tempo de envio de dados",
  [ConfigCommandsContentID.PRESET_A]: "Preset A",
  [ConfigCommandsContentID.ALTERACAO_SERVIDOR_DESTINO]: "Alterar servidor de destino das mensagens",
  [ConfigCommandsContentID.CHECAR_ICCID_ST300]: "Checar ICCID - Família ST300, excluindo o ST380",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO_4315_8310]: "Alterar odômetro - 4315/8310",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO_4315_8310]: "Alterar horímetro - 4315/8310"
};
