/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */

import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

// region Third-party libraries
import $ from "jquery";
import * as _ from "lodash";
import { SpeedDial, SpeedDialMenu } from "@npm-telluria-tecnologia/telluria-ui/dist";
import { Badge } from "@libraries/mui";
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  FilterList,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  PriorityHigh,
  Search,
  ViewWeekRounded
} from "@libraries/icons";
// endregion
// region Data table libraries
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// endregion Data table libraries
// region Atoms
import DataTableFilter, { IFilterField } from "@atoms/DataTableFilter";
// endregion Atoms
// region Styles
import usePersistedState from "@hooks/usePersistedState";
import { DefaultTheme } from "styled-components";
import light from "@styles/themes/light.theme";
import { DataTableMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
import { Container, Buttons } from "./styles";
// endregion Styles

// region Interfaces
export interface DataTableActions<T = any> {
  ref: string,
  callback: (rowData: T) => any
}

// endregion Interfaces
// region Types
export type DataTableButtons = DataTables.ButtonSettings & { key: string, name: string, callback?: () => any }
export type DataTableColumns = DataTables.ColumnSettings & {
  filterable: boolean,
  propertyName?: string,
  toFilterValue?: ((data: any) => number | string) | null,
  filterTitle?: string
}
type DataTableProps = {
  data: any[],
  columns: DataTableColumns[],
  settings?: DataTables.Settings,
  actions?: DataTableActions[],
  buttons?: DataTableButtons[],
  buttonsChildren?: React.ReactNode,
  filters?: boolean,
  editColumns?: boolean,
  title?: string,
  use?: "maintenanceScreen" | "dialogScreen",
  width?: string,
  showPaging?: boolean,
  showInfo?: boolean,
  showSearching?: boolean,
  returnTable?: (table: DataTables.Api) => void,
  returnClickedRow?: (clickedRow: JQuery<HTMLTableRowElement>) => void,
  onClickFilterButton?: () => void,
  numberOfFilteredOptions?: number
}
// endregion Types

const NewDataTable: React.FC<DataTableProps> = ({
  data: dataProp,
  columns,
  settings,
  actions,
  buttons,
  buttonsChildren,
  filters,
  editColumns,
  title,
  use = "maintenanceScreen",
  returnTable,
  returnClickedRow,
  width,
  showInfo,
  showPaging,
  showSearching,
  onClickFilterButton,
  numberOfFilteredOptions
}) => {

  // region Refs
  const tableRef = useRef<any>(null);
  // endregion Refs
  // region Hooks
  const [theme] = usePersistedState<DefaultTheme>("@Fleet:theme", light);
  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [table, setTable] = useState<DataTables.Api>();
  const [dataFiltered, setDataFiltered] = useState<any[]>([]);
  const [openSpeedDialActionMenus, setOpenSpeedDialActionMenus] = useState(false);
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [dataLength, setDataLength] = useState(dataProp.length);
  // endregion States

  // region Filter state control
  const [filterableColumns] = useState(columns.filter((column) => column.filterable));
  const [filterProperties] = useState<IFilterField[]>(filterableColumns.map((column) => ({
    propName: column.propertyName, visualName: column.title, toFilterValue: column.toFilterValue, filterTitle: column.filterTitle
  } as IFilterField)));
  // endregion Filter state control
  // region Filter menus handlers
  const handleOpenFilterFields = () => {
    if (onClickFilterButton) {
      onClickFilterButton();

      return;
    }

    setOpenFilterFields(true);
  };
  const handleCloseFilterFields = () => { setOpenFilterFields(false); };
  // endregion Filter menus handlers
  // region Filter table handler
  const handleFilterFields = (filteredData: any[]) => {

    setDataFiltered(filteredData);

    // If filtered data is equal to original data, then reset filtered data
    // (Hide badge active filter)
    if (filteredData.length === dataProp.length) {
      setDataFiltered([]);
    }
  };
  // endregion Filter table handler

  // region Speed dial action menus handlers
  const handleOpenActionMenusSpeedDial = () => { setOpenSpeedDialActionMenus(true); };
  const handleCloseActionMenusSpeedDial = () => { setOpenSpeedDialActionMenus(false); };
  const handleClickActionMenuSpeedDial = (menuCallback?: (() => any) | undefined) => {

    if (menuCallback) {
      menuCallback();
    }

    handleCloseActionMenusSpeedDial();
  };
  // endregion Speed dial action menus handlers

  // region Effects

  // Render the Data Table
  // PS: If language changes, the table will be reloaded
  useEffect(() => {

    const initialTableSettings: DataTables.Settings = {};

    if (table) initialTableSettings.destroy = true;

    // REVIEW need to redraw component (reorder button, input, etc.) after change resolution
    // Only render one time when screen has platform
    if (!table || initialTableSettings.destroy) {

      const tableSettings: DataTables.Settings = {
        ...initialTableSettings,
        responsive: true,
        data: !_.isEmpty(dataFiltered) ? dataFiltered : dataProp,
        columns,
        info: showInfo,
        paging: showPaging,
        searching: showSearching,
        language: {
          paginate: {
            first: ReactDOMServer.renderToString(<KeyboardArrowLeft />),
            last: ReactDOMServer.renderToString(<KeyboardArrowRight />),
            next: ReactDOMServer.renderToString(<ArrowForwardIos />),
            previous: ReactDOMServer.renderToString(<ArrowBackIos />)
          },
          emptyTable: t(DataTableMessages.emptyTable),
          info: t(DataTableMessages.info),
          infoEmpty: t(DataTableMessages.infoEmpty),
          infoFiltered: `<br>${t(DataTableMessages.infoFiltered)}`,
          lengthMenu: t(DataTableMessages.lengthMenu),
          loadingRecords: t(DataTableMessages.loadingRecords),
          processing: t(DataTableMessages.processing),
          zeroRecords: t(DataTableMessages.zeroRecords),
          search: ReactDOMServer.renderToString(<Search />),
          searchPlaceholder: t(DataTableMessages.searchPlaceholder)
        },
        scrollY: "calc(100vh - 395px)",
        scrollX: false,
        scrollCollapse: true,
        autoWidth: true,
        dom: (() => {

          const gridHeader = "MuiGrid-root MuiGrid-item MuiGrid-grid-xs";
          const gridFooter = showPaging ? "MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6" : "";
          const gridFilter = filters || editColumns ? `<"${gridHeader} dataTables_filter">` : "";
          const container = "MuiGrid-root MuiGrid-container";

          return `
            <"${container} dataTables_search"
              <"${gridHeader}"f>
              ${gridFilter}
            >
              rt
            ${!showPaging ? "<div />" : `
              <"${container} fixed--bottom"
              <"${gridFooter} MuiGrid-direction-xs-column"
                <"${gridFooter}"l>
                <"${gridFooter}"i>
              >
                <"${gridFooter}"p>
                <"${gridFooter} flex--center flex--hidden"B>
              >
            `}
          `;
        })(),
        buttons: buttons ?? []
      };

      const instanceTable = $(tableRef.current).DataTable({ ...tableSettings, ...settings });

      setTable(instanceTable);
      returnTable && returnTable(instanceTable);
    }

  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (dataProp.length > dataLength) {
      table?.clear();
      table?.rows.add(dataProp).draw(false);
    } else {
      table?.clear();
      table?.rows.add(dataProp);
      table?.draw();
      table?.columns.adjust();
    }
    setDataLength(dataProp.length);
  }, [dataProp]);

  // Update data in Data Table when data is change
  useEffect(() => {
    const ref = tableRef.current;
    const dataToLoad = !_.isEmpty(dataFiltered) ? dataFiltered : dataProp;

    if (table) {
      table.clear();
      table.rows.add(dataToLoad);
      table.draw();
      table?.columns.adjust();

      if (actions && dataToLoad) {

        returnTable && returnTable(table);

        actions.forEach((action) => {
          $(tableRef.current).on("click", `${action.ref}`, (event) => {
            if ($(event.currentTarget).parents("tr").hasClass("child")) {
              action.callback(table.row($(event.currentTarget).parents("tr").prev("tr")).data());
              returnClickedRow && returnClickedRow($(event.currentTarget).parents("tr").prev("tr"));
            } else {
              action.callback(table.row($(event.currentTarget).parents("tr")).data());
              returnClickedRow && returnClickedRow($(event.currentTarget).parents("tr"));
            }
          });
        });
      }
    }

    // REVIEW The code behaves strangely, needing to call the function twice to adjust the size of the columns
    table?.columns.adjust();

    return function cleanUp() {
      $(ref).prop("onclick", null).off("click");
    };

  },
  // No more deps are necessary
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dataFiltered, table]);

  // endregion Effects

  return (
    <Container use={use} filters={filters} editColumns={editColumns}>
      {filters && (
        <Badge
          badgeContent={!_.isEmpty(dataFiltered) ? <PriorityHigh /> : numberOfFilteredOptions ?? 0}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          className="filter-button"
          color="error"
          onClick={handleOpenFilterFields}
        >
          <FilterList className="filter-icon" />
        </Badge>
      )}
      {editColumns && (
        <Badge
          badgeContent={!_.isEmpty(dataFiltered) ? <PriorityHigh /> : 0}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          className="filter-button"
          color="error"
          onClick={handleOpenFilterFields}
        >
          <ViewWeekRounded />
        </Badge>
      )}
      <table className="display cell-border hover row-border compact" width={width} ref={tableRef} />
      {buttons && buttons.length > 0 && (
        <Buttons>
          <SpeedDial
            primaryColor={theme.colors.primary}
            secondColor={theme.colors.secondary}
            size="medium"
            icon={<Add fontSize="large" />}
            open={openSpeedDialActionMenus}
            onOpen={handleOpenActionMenusSpeedDial}
            onClose={handleCloseActionMenusSpeedDial}
          >
            {buttons.map((menu) => (
              <SpeedDialMenu
                key={menu.key}
                menu={{ id: menu.key, title: menu.name }}
                colorOnHover={theme.colors.secondary}
                onClick={() => handleClickActionMenuSpeedDial(menu.callback)}
              />
            ))}
          </SpeedDial>
        </Buttons>
      )}
      {(!buttons || buttons.length === 0) && buttonsChildren && (
        <Buttons>
          {buttonsChildren}
        </Buttons>
      )}
      {filters && !onClickFilterButton && (
        <DataTableFilter
          title={title!}
          filterData={dataProp}
          filterProperties={filterProperties}
          open={openFilterFields}
          onClose={handleCloseFilterFields}
          onFilter={(filteredData) => handleFilterFields(filteredData)}
        />
      )}
    </Container>
  );
};

NewDataTable.defaultProps = {
  settings: {},
  actions: [],
  filters: false,
  editColumns: false,
  title: "",
  use: "maintenanceScreen",
  buttons: undefined,
  buttonsChildren: undefined,
  returnTable: undefined,
  returnClickedRow: undefined,
  width: "100%",
  showPaging: true,
  showInfo: true,
  showSearching: true,
  onClickFilterButton: undefined,
  numberOfFilteredOptions: 0
};

export default NewDataTable;
