import {
  TranslationTravelAlertFullSpeedMessage,
  TravelAlertFullSpeedMessage
} from "../interfaces";

export const messages: TranslationTravelAlertFullSpeedMessage = {
  [TravelAlertFullSpeedMessage.youAreAboutFullSpeedTravel]: "Estás a punto de crear una  ",
  [TravelAlertFullSpeedMessage.alertOfFullSpeed]: "alerta de límite de velocidad ",
  [TravelAlertFullSpeedMessage.alertToVehicle]: "para un vehículo",
  [TravelAlertFullSpeedMessage.subtitleOnlyOneAlert]: "Puedes configurar solo una alerta por viaje.",
  [TravelAlertFullSpeedMessage.checkVehicle]: "Verifica el vehículo para crear la alerta.",
  [TravelAlertFullSpeedMessage.setSpeed]: "Establece el límite de velocidad máxima.",
  [TravelAlertFullSpeedMessage.SpeedInput]: "Límite de velocidad para la alerta",
  [TravelAlertFullSpeedMessage.infoAlertSpeed]: "La alerta de límite de velocidad máxima es solo informativa. Cuando se"
  + " excede durante el viaje, la velocidad máxima en la tarjeta y en el perfil del vehículo aparece en rojo. La lista de "
  + "vehículos que superaron el límite de velocidad se puede acceder en el menú superior de la pantalla de producción.",
  [TravelAlertFullSpeedMessage.infoOnceAlert]: "La alerta de límite de velocidad se puede configurar solo una vez"
  + " por viaje.",
  [TravelAlertFullSpeedMessage.createAlertSpeedTravel]: "Crear una alerta de ",
  [TravelAlertFullSpeedMessage.limitFullSpeed]: "límite de velocidad",
  [TravelAlertFullSpeedMessage.confirmFullSpeedTravel]: "Estás estableciendo un límite de velocidad de: ",
  [TravelAlertFullSpeedMessage.speed]: "velocidad",
  [TravelAlertFullSpeedMessage.createFullSpeedTravel]: "Crear una alerta de velocidad",
  [TravelAlertFullSpeedMessage.configuredSpeed]: "Velocidad máxima configurada.",
  [TravelAlertFullSpeedMessage.configuredAlertDate]: "La alerta de límite de velocidad máxima de este vehículo se ha"
  + " establecido en",
  [TravelAlertFullSpeedMessage.alreadyFullSpeed]: "Ya hay uno ",
  [TravelAlertFullSpeedMessage.toThisVehicle]: "para este vehículo.",
  [TravelAlertFullSpeedMessage.OnlySetByTravel]: "Puedes configurar una única alerta para cada viaje.",
  [TravelAlertFullSpeedMessage.vehicleWithAlert]: "Vehículo con alerta de velocidad máxima.",
  [TravelAlertFullSpeedMessage.dayOfSetFullSpeed]: " del día ",
  [TravelAlertFullSpeedMessage.alertCount]: " Se ha excedido el límite de velocidad total para este viaje ",
  [TravelAlertFullSpeedMessage.timesAlertCount]: " veces ",
  [TravelAlertFullSpeedMessage.exceded]: " excedida ",
  [TravelAlertFullSpeedMessage.normal]: " normal "

};
