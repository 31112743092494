import styled, { css } from "styled-components";

export const Container = styled.button`
  background: ${(props) => props.theme.colors.primary};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  /* margin-top: 16px; */
  transition: background-color 0.2s;



  ${(props) => (!props.disabled
    && css`
      :hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    `)
};

  ${(props) => (props.disabled
    && css`
      opacity: 0.7;
      cursor: auto;
    `)
};

`;
