/* eslint-disable import/prefer-default-export */
import { ServiceOrderMessages, TranslationServiceOrderMessages } from "../interfaces";

export const messages: TranslationServiceOrderMessages = {

  /* region Frontend messages */
  [ServiceOrderMessages.newServiceOrder]: "Nueva Orden de Servicio",
  [ServiceOrderMessages.expectedDate]: "Fecha prevista",
  [ServiceOrderMessages.serviceOrderType]: "Tipo de O.S.",
  [ServiceOrderMessages.delivery]: "Entrega",
  [ServiceOrderMessages.pickup]: "Retirada",
  [ServiceOrderMessages.product]: "Producto",
  [ServiceOrderMessages.binder]: "Cemento",
  [ServiceOrderMessages.smallAggregate]: "Agregado fino",
  [ServiceOrderMessages.coarseAggregate]: "Agregado grueso",
  [ServiceOrderMessages.unit]: "Unidad",
  [ServiceOrderMessages.transportDocument]: "Documento de transporte",
  [ServiceOrderMessages.productDocument]: "Documento del producto",
  [ServiceOrderMessages.originLocation]: "Ubicación de origen",
  [ServiceOrderMessages.loadingLocation]: "Ubicación de carga",
  [ServiceOrderMessages.unloadingLocation]: "Ubicación de descarga",
  [ServiceOrderMessages.quantity]: "Cantidad",
  /* endregion Frontend messages */
  /* region Backend messages */
  [ServiceOrderMessages.createServiceOrderSuccess]: "Orden de servicio creada con éxito",
  [ServiceOrderMessages.createServiceOrderError]: "Error al crear nueva orden de servicio"
  /* endregion Backend messages */
};
