import { PumpProgrammingMessages, TranslationPumpProgrammingMessages } from "../interfaces";

export const messages: TranslationPumpProgrammingMessages = {
  [PumpProgrammingMessages.createPumpProgrammingError]: "Erro ao criar programação de bomba",
  [PumpProgrammingMessages.createPumpProgrammingSuccess]: "Programação de bomba criada com sucesso",
  [PumpProgrammingMessages.cancelPumpProgrammingError]: "Erro ao cancelar programação de bomba",
  [PumpProgrammingMessages.cancelPumpProgrammingSuccess]: "Programação de bomba cancelada com sucesso",
  [PumpProgrammingMessages.noPumpProgrammingFound]: "Nenhuma programação de bomba encontrada",
  [PumpProgrammingMessages.readPumpProgrammingError]: "Erro ao ler programação de bomba",
  [PumpProgrammingMessages.readPumpProgrammingSuccess]: "Programação de bomba lida com sucesso",
  [PumpProgrammingMessages.updatePumpProgrammingError]: "Erro ao atualizar programação de bomba",
  [PumpProgrammingMessages.updatePumpProgrammingSuccess]: "Programação de bomba atualizada com sucesso",
  [PumpProgrammingMessages.noChangePumpProgrammingInProgress]: "Não é possível alterar programação de bomba que já está em andamento",
  [PumpProgrammingMessages.readPumpProgrammingHistoryError]: "Erro ao ler histórico de programação de bomba",
  [PumpProgrammingMessages.readPumpProgrammingHistorySuccess]: "Histórico de programação de bomba lido com sucesso",
  [PumpProgrammingMessages.noPumpProgrammingHistoryFound]: "Nenhum histórico de programação de bomba encontrado",

  [PumpProgrammingMessages.pumpProgrammingTitle]: "Programação de bomba",
  [PumpProgrammingMessages.dataTableTitle]: "programações de bomba",

  [PumpProgrammingMessages.tabProgrammings]: "Programações",
  [PumpProgrammingMessages.tabImports]: "Importações",
  [PumpProgrammingMessages.tabHistory]: "Histórico",

  [PumpProgrammingMessages.columnDescription]: "Descrição",
  [PumpProgrammingMessages.columnClient]: "Cliente",
  [PumpProgrammingMessages.columnIntegrationDate]: "Data de integração",
  [PumpProgrammingMessages.columnDocumentDate]: "Data do documento",
  [PumpProgrammingMessages.columnDoc]: "Doc.",
  [PumpProgrammingMessages.columnExternalId]: "Id externo",
  [PumpProgrammingMessages.columnOrigin]: "Origem",
  [PumpProgrammingMessages.columnVehicle]: "Veículo",
  [PumpProgrammingMessages.columnDriver]: "Motorista",
  [PumpProgrammingMessages.columnMaxSpeed]: "Vel. máx. permitida (km/h)",
  [PumpProgrammingMessages.columnUser]: "Usuário",
  [PumpProgrammingMessages.columnOccurrenceDate]: "Data de ocorrência",
  [PumpProgrammingMessages.columnChangeType]: "Tipo de alteração",
  [PumpProgrammingMessages.columnImportRef]: "Importação referente",

  [PumpProgrammingMessages.cancelConfirmationTitle]: "Cancelar programação de bomba",
  [PumpProgrammingMessages.cancelConfirmationText]: "Tem certeza que deseja cancelar esta programação de bomba?",

  [PumpProgrammingMessages.newPumpProgramming]: "Nova programação de bomba",

  [PumpProgrammingMessages.changeTypeCreate]: "Criação",
  [PumpProgrammingMessages.changeTypeUpdate]: "Atualização",
  [PumpProgrammingMessages.changeTypeCancel]: "Cancelamento",
  [PumpProgrammingMessages.changeTypeDelete]: "Exclusão"
};
