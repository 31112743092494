/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { Trace } from "./reg_traces.entity";

@Entity("tpe_gravels_types")
export class TpeGravel {

  @PrimaryGeneratedColumn("uuid")
  id_gravel_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @Column({ type: "varchar", nullable: false })
  abbreviation?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => Trace, (traces) => traces.gravel)
  traces?: Trace[];

}
