import { RegionalsModuleMessages, TranslationRegionalsModuleMessages } from "../interfaces";

export const messages: TranslationRegionalsModuleMessages = {

  [RegionalsModuleMessages.name]: "Name",
  [RegionalsModuleMessages.noNameRegistered]: "No name registered",
  [RegionalsModuleMessages.active]: "Active",
  [RegionalsModuleMessages.inactive]: "Inactive",
  [RegionalsModuleMessages.externalId]: "External ID",
  [RegionalsModuleMessages.registrationDate]: "Registration date",
  [RegionalsModuleMessages.updatedDate]: "Updated date",
  [RegionalsModuleMessages.code]: "Code",
  [RegionalsModuleMessages.deleteConfirmationText]: "Are you sure you want to delete this user",
  [RegionalsModuleMessages.deleteRegionalError]: "Failed to delete regional",

  [RegionalsModuleMessages.updateRegionalError]: "Unable to update region",
  [RegionalsModuleMessages.updateRegionalSuccess]: "Regional updated successfully",
  [RegionalsModuleMessages.readRegionalSuccess]: "Regional(s) returned successfully",
  [RegionalsModuleMessages.noRegionalFound]: "No region found",
  [RegionalsModuleMessages.createRegionalSuccess]: "Successfully created regional",
  [RegionalsModuleMessages.createRegionalError]: "Unable to create regional",
  [RegionalsModuleMessages.deleteRegionalSuccess]: "Unable to delete regional"

};
