import styled from "styled-components";

interface StylesheetProps {
  isMobile: boolean;
  isBlocked: boolean;
}

const className = "vehicle-block__dialog";

const ModalVehicleBlock = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`
  display: flex;
  max-height: calc(100% - 64px);
  min-height: 770px;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  .vehicle-block-dialog__list {
    flex-direction: ${(props) => (props.isMobile ? "column" : "")};
    align-items: ${(props) => (props.isMobile ? "flex-start;" : "")};
  }

  .vehicle-block-dialog__item-grid {
    display: ${(props) => (props.isMobile ? "flex" : "")};
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .vehicle-block-dialog__instruction {
    display: flex;
    line-height: 1.4;
    align-items: center;
    gap: 0.2rem;

    .strong {
      color: ${(props) => (props.isBlocked ? "#00AD00" : "#F00")};
    }
  }
`;

const ModalSuccessBlock = styled.div`

  .MuiDialogContent-root {
    text-align: center;

    img {
      width: 80px;
      margin-bottom: 25px;
    }
  }
`;

export { ModalVehicleBlock, ModalSuccessBlock };
