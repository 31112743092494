import React, { useEffect, useState } from "react";

// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { HelpMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - External libraries
import { useField } from "@unform/core";
import { Button } from "@material-ui/core";
// endregion Imports - External libraries
// region Imports - Styles
import { FileInputContainer, FileInputHelperText } from "./styles";
// endregion Imports - Styles

// region Interfaces
interface FileInputProps {
  name: string;
  helperText: string;
  onFileChange?: (file: File | null) => void;
  accept?: string;
}
// endregion Interfaces

const FileInput: React.FC<FileInputProps> = ({ name, helperText, onFileChange, accept }) => {
  // region Hooks
  const { t } = useTranslation();
  const { fieldName, registerField, error, clearError } = useField(name);
  // endregion Hooks
  // region States
  const [value, setValue] = useState<File | null>(null);
  // endregion States
  // region Functions
  const handleInputFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    setValue(file ?? null);
    clearError();
    if (onFileChange) onFileChange(file ?? null);
  };
  // endregion Functions
  // region Effects

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: { value },
      path: "value",
      clearValue: () => setValue(null),
      setValue: (_ref, val) => setValue(val as File)
    });
  }, [fieldName, registerField, value]);
  // endregion Effects

  return (
    <>
      <FileInputContainer error={!!error}>
        <Button variant="contained" component="label" id="buttonFile">
          {t(HelpMessages.selectFile)}
          <input
            hidden
            type="file"
            onChange={handleInputFileChange}
            accept={accept}
          />
        </Button>
        <span className="file-name">
          {value?.name ?? t(HelpMessages.noFileSelected)}
        </span>
      </FileInputContainer>
      <FileInputHelperText
        className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense"
        error={!!error}
      >
        {error ?? helperText}
      </FileInputHelperText>
    </>
  );
};

export default FileInput;
