import styled from "styled-components";
import { windowWidth } from "@styles/global";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 64px;
  height: 100%;

  .titleHeader {
    padding: 0 20px 0 20px;

    .title {
      font-weight: 200;
      width: 100%;
      font-size: 2rem;
    }
    .subtitle {
      margin-top: 5px;
      font-size: 1.2rem;
      color: rgb(0 0 0 / 60%);
    }

    @media ( max-width: ${windowWidth.mobile.large} ) {  // Mobile
      padding-top: 0;
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  padding-bottom: 20px;

  .form {
    background: white;
    padding: 0 20px;

    .filterContent {
      margin: 25px 0 0 0;
    }
    .filterFooter {
      text-align: end;

      button {
        width: fit-content;
        font-weight: bold;
        margin-top: 0px;
        background: ${(props) => props.theme.colors.primary};
      }
    }

    .button-loading {
      margin-top: 0px !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;
