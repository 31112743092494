export enum VehicleMaintenanceMessages {

  /* region Backend */
  inMaintenance = "vehicleMaintenance.inMaintenance",
  outOfMaintenance = "vehicleMaintenance.outOfMaintenance",
  vehicleIsAlready = "vehicleMaintenance.vehicleIsAlready",
  cannotGetLocation = "vehicleMaintenance.cannotGetLocation",
  putInMaintenanceSuccess = "vehicleMaintenance.putInMaintenanceSuccess",
  putOutOfMaintenanceSuccess = "vehicleMaintenance.putOutOfMaintenanceSuccess",
  changeMaintenanceStatusError = "vehicleMaintenance.changeMaintenanceStatusError",
  /* endregion Backend */

  /* region Frontend */
  checkVehicle = "vehicleMaintenance.checkVehicle",
  removeFromMaintenance = "vehicleMaintenance.removeFromMaintenance",
  removeFromMaintenanceConfirmation = "vehicleMaintenance.removeFromMaintenanceConfirmation",
  justifyOrSelect = "vehicleMaintenance.justify",
  maintenance = "vehicleMaintenance.maintenance",
  selectMaintenance = "vehicleMaintenance.selectMaintenance",
  justifyMaintenance = "vehicleMaintenance.justifyMaintenance",
  maintenanceOptions = "vehicleMaintenance.maintenanceOptions",
  maintenanceConfirmation = "vehicleMaintenance.maintenanceConfirmation",
  maintenanceConfirmationMessage = "vehicleMaintenance.maintenanceConfirmationMessage",
  youAreAboutToPutTheVehicle = "vehicleMaintenance.youAreAboutToPutTheVehicle",
  yourActionWillPutTheVehicleInMaintenance = "vehicleMaintenance.yourActionWillPutTheVehicleInMaintenance",
  optionHydraulic = "vehicleMaintenance.option.hydraulic",
  optionElectrical = "vehicleMaintenance.option.electrical",
  optionTireService = "vehicleMaintenance.option.tireService",
  optionSuspension = "vehicleMaintenance.option.suspension",
  optionBodywork = "vehicleMaintenance.option.bodywork",
  optionRockBreaker = "vehicleMaintenance.option.rockBreaker",
  optionOthers = "vehicleMaintenance.option.others"
  /* endregion Frontend */
}

export type TranslationVehicleMaintenanceMessages = {
  [key in VehicleMaintenanceMessages]: string;
}
