/* eslint-disable import/prefer-default-export */
import { TravelSwapMessages, TranslationTravelSwapMessages } from "../interfaces";

export const messages: TranslationTravelSwapMessages = {
  [TravelSwapMessages.swapCompleted]: "Troca realizada!",
  [TravelSwapMessages.swapTravelData]: "Trocar dados da viagem",
  [TravelSwapMessages.originalTravel]: "Viagem original",
  [TravelSwapMessages.replaceTravel]: "Viagem para trocar",
  [TravelSwapMessages.swap]: "Trocar",
  [TravelSwapMessages.swapConfirmationTitle]: "Confirmação de troca",
  [TravelSwapMessages.swapConfirmationDescription]: "Tem certeza que deseja trocar os dados das seguintes viagens?"
};
