import DriverPermission from "../permissions/driver-permissions.enum";
import VehiclePermission from "../permissions/vehicle-permissions.enum";

const ClaimValue = {
  ...DriverPermission,
  ...VehiclePermission
};

type ClaimValue = DriverPermission | VehiclePermission;

export { ClaimValue };
