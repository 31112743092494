export enum VehicleBlockMessages {
  blockedVehicle = "vehicleBlock.lockedVehicle",
  checkVehicleToBeBlocked = "vehicleBlock.checkVehicleToBeBlocked",
  checkVehicleToBeUnblocked = "vehicleBlock.checkVehicleToBeUnlocked",
  blockCommandSent = "vehicleBlock.lockCommandSent",
  unblockCommandSent = "vehicleBlock.unlockCommandSent",
  blockProcessMonitoringInstruction = "vehicleBlock.lockProcessMonitoringInstruction",
  accessAvailableAt = "vehicleBlock.accessAvailableAt",
  blockTabPath = "vehicleBlock.blockTabPath",
  youAreAboutTo = "vehicleBlock.youAreAboutTo",
  toBlock = "vehicleBlock.toBlock",
  toUnblock = "vehicleBlock.toUnblock",
  aVehicle = "vehicleBlock.aVehicle",
  yourActionWill = "vehicleBlock.yourActionWill",
  vehicleMobilityAndFunctionality = "vehicleBlock.vehicleMobilityAndFunctionality",
  responsibilityTerms = "vehicleBlock.responsibilityTerms",
  justify = "vehicleBlock.justify",
  ofTheVehicle = "vehicleBlock.ofTheVehicle",
  justifyBlock = "vehicleBlock.justifyBlock",
  justifyUnblock = "vehicleBlock.justifyUnblock",
  block = "vehicleBlock.block",
  unblock = "vehicleBlock.unblock",
  insertPassword = "vehicleBlock.insertPassword",
  slideToRight = "vehicleBlock.slideToRight",
  vehicle = "vehicleBlock.vehicle",
  blockConfirmationTitle = "vehicleBlock.blockConfirmationTitle",
  blockConfirmationMessage = "vehicleBlock.blockConfirmationMessage",
  user = "vehicleBlock.user",
  creationDate = "vehicleBlock.creationDate",
  sendDate = "vehicleBlock.sendDate",
  processingDate = "vehicleBlock.processingDate",
  status = "vehicleBlock.status",
  processed = "vehicleBlock.processed",
  sent = "vehicleBlock.sent",
  cancelled = "vehicleBlock.cancelled",
  justification = "vehicleBlock.justification",
  blockLocation = "vehicleBlock.blockLocation",
  blockHistoryTitle = "vehicleBlock.blockHistoryTitle"
}

export type TranslationVehicleBlockMessages = {
  [key in VehicleBlockMessages]: string;
}
