/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";

import { Country } from "./reg_countries.entity";
import { ZipCode } from "./reg_zip_codes.entity";

@Entity("reg_states")
export class State {

  @PrimaryGeneratedColumn("uuid")
  id_state?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  name?: string;

  @Column({ type: "varchar", nullable: false })
  abbreviation?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @ManyToOne(() => Country, (country) => country.states, { nullable: false })
  @JoinColumn({ name: "id_country_fk" })
  country?: Country;

  @OneToMany(() => ZipCode, (zip_codes) => zip_codes.state)
  zip_codes?: ZipCode[];

}
