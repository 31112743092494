import {
  TranslationTravelAlertFullSpeedMessage,
  TravelAlertFullSpeedMessage
} from "../interfaces";

export const messages: TranslationTravelAlertFullSpeedMessage = {
  [TravelAlertFullSpeedMessage.youAreAboutFullSpeedTravel]: "Você está prestes a criar um ",
  [TravelAlertFullSpeedMessage.alertOfFullSpeed]: "alerta de limite de velocidade ",
  [TravelAlertFullSpeedMessage.alertToVehicle]: "para um veículo",
  [TravelAlertFullSpeedMessage.subtitleOnlyOneAlert]: "Você pode configurar um único alerta a cada viagem ",
  [TravelAlertFullSpeedMessage.checkVehicle]: " Confira o veículo para criar o alerta.",
  [TravelAlertFullSpeedMessage.setSpeed]: " Estabeleça o limite de velocidade máxima.",
  [TravelAlertFullSpeedMessage.SpeedInput]: "Limite de velocidade para alerta",
  [TravelAlertFullSpeedMessage.infoAlertSpeed]: " O alerta de limite de velocidade máxima é apenas informativo e"
  + " quando é excedido na viagem a velocidade máxima no cartão e no perfil do veículo aparece em vermelho "
  + " lista de veículos que ultrapassaram o limite de velocidade pode ser acessada no menu superior da tela de"
  + " produção.",
  [TravelAlertFullSpeedMessage.infoOnceAlert]: " O alerta de limite de velocidade pode ser configurado uma única vez"
  + " por viagem.",
  [TravelAlertFullSpeedMessage.createAlertSpeedTravel]: "Criar alerta de ",
  [TravelAlertFullSpeedMessage.limitFullSpeed]: "limite de velocidade",
  [TravelAlertFullSpeedMessage.confirmFullSpeedTravel]: "Você esta definindo um de limite de velocidade de: ",
  [TravelAlertFullSpeedMessage.speed]: "velocidade",
  [TravelAlertFullSpeedMessage.createFullSpeedTravel]: "Criar alerta de velocidade",
  [TravelAlertFullSpeedMessage.configuredSpeed]: "Velocidade máxima configurada.",
  [TravelAlertFullSpeedMessage.configuredAlertDate]: "O alerta de limite de velocidade máxima deste veículo foi"
  + " configurado às ",
  [TravelAlertFullSpeedMessage.alreadyFullSpeed]: "Já existe um ",
  [TravelAlertFullSpeedMessage.toThisVehicle]: "para este veículo",
  [TravelAlertFullSpeedMessage.OnlySetByTravel]: "Você pode configurar um único alerta a cada viagem",
  [TravelAlertFullSpeedMessage.vehicleWithAlert]: "Veículo com alerta de velocidade máxima.",
  [TravelAlertFullSpeedMessage.dayOfSetFullSpeed]: " do dia ",
  [TravelAlertFullSpeedMessage.alertCount]: " O limite de velocidade máxima desta viagem foi excedido ",
  [TravelAlertFullSpeedMessage.timesAlertCount]: " vezes ",
  [TravelAlertFullSpeedMessage.exceded]: " excedida ",
  [TravelAlertFullSpeedMessage.normal]: " normal "
};
