import { Status as StatusColor } from "@shared/constants/colors.enum";
import styled, { keyframes } from "styled-components";
import { ScreenState } from "@store/ducks/Screen/screen.type";

const KeyFramePulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.35);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(255, 0, 0, 0.35);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.35);
  }
`;

interface ContainerProps {
  platform: ScreenState["platform"];
  isInTimelinePump: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  .vehicle-summary__accordion {
    min-height: 100.5px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 25%);

    &:before {
      display: none;
    }

    .vehicle-summary {
      padding: 0;

      .MuiAccordionSummary-content {
        min-height: 110px;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 100%;
      }

      &__aggregates-icons {
        display: flex;

        * {
          margin-right: 2px;
        }
      }

      &__collapse-icon {

        &--open {
          background-color: rgb(163 163 163 / 30%);
        }
      }

      &__content {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 6px 15px 0 15px; //
      }

      &__symbol {
        width: 50px;
        height: 50px;
        padding: 10px;
        border-radius: 50%;

        .on .MuiBadge-badge {
          background-color:  ${StatusColor.IGNITION_ON};
          padding-left: 10.33px;
          padding-right: 8.81px;

          svg {
            max-height: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 12 : 12)}px;
            max-width: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 12 : 12)}px;
          }
        }

        .off .MuiBadge-badge {
          background-color: ${StatusColor.INACTIVE};
          padding-left: 9px;
          padding-right: 8.2px;

          svg {
            max-height: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 16 : 16)}px;
            max-width: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 16 : 16)}px;
          }
        }

        .MuiBadge-badge {
          height: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 25.5 : 25.5)}px;
          width: ${(props) => ((props.isInTimelinePump && props.platform === "mobile") ? 51 : 51)}px;
          border-radius: 20px;
          top: 55px;
          right: 20px;
          z-index: 0;
          justify-content: space-between;
          color: white;
          font-size: 14px;
          font-weight: 400;
        }

        .iconBT, .iconBM {

          svg {
            position: relative;
            right: 2px;
            top: 3px;
          }
        }

        .iconPC {

          svg {
            width: 35px;
            position: relative;
            right: 3px;
            top: 1px;
          }
        }

        .iconVA {

          svg {
            position: relative;
            right: 3px;
            width: 35px;
            top: 5px;
          }
        }

        .iconCA {

          svg {
            position: relative;
            bottom: 12px;
            right: 3px;
            width: 35px;
          }
        }

        .iconBlocked {

          svg {
            position: relative;
            right: 3px;
            width: 35px;
            bottom: 5px;
          }
        }
      }

      &__block {

        &--description {
          margin: 15px;
          display: flex;
          width: 100%;
          flex-direction: column;
          overflow: hidden;
        }

        &--status {
          padding-top: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__code {
        font-weight: 600;
        font-size: 1rem;
      }

      &__plate {
        color: rgb(0 0 0 / 60%);
      }

      &__plate, &__driver {
        font-size: 0.8rem;

        &--ibutton {
          font-size: 1.2rem;
          position: relative;
          top: 0.2rem;
          color: ${StatusColor.ACTIVE};
          margin-right: 1.5rem;
        }
      }

      &__status {
        padding-left: 10px;
      }

      &__hardware-icon {
        margin-bottom: 2px;

        svg {
          width: 20px;

          .cls-1 {
            stroke: none;
          }
        }
      }

      &__speed {
        font-weight: bold;
        text-align: center;

        svg {
          position: relative;
          right: 1px;
        }
      }

      [class^='vehicle-summary__collapse-icon'] {
        height: 5px;
        width: 42%;
        background-color: rgb(163 163 163 / 70%);
        border-radius: 10px;
        margin: auto auto 12px;
      }
    }

    .vehicle-details {
      padding: 0;
      border-top: 1px solid rgb(128 128 128 / 20%);
      display: block;

      &__current-load-information {
        display: flex;
        margin: 5px 0px;
        justify-content: center;

        &--block {
          display: flex;
          margin-left: 0.5rem;
          font-size: 0.8rem !important;
        }
      }

      .vehicle-details__icon--state {

        svg {
          width: 18px;
          height: 18px;
        }
      }

      &--travelInfo {
        display: flex;
        gap: 10px;
      }

      &__content {
        padding: 4px 4px;
      }

      &__row {

        &--state-odometer {
          margin-top: 20px;
          justify-content: space-between !important;
        }

        &--timeline {
          display: inline-block !important;
          margin: 20px 0 !important;
        }

        &--alerts {
          margin: 5px 0 !important;
        }

        &--tracker, &--hourMeter, &--blocked {
          margin: 5px 0 !important;
        }
      }

      &__block {

        &--timeline {
          display: flex !important;
          justify-content: space-around !important;

          .RSPBprogressBar {
            border-radius: 0;
            width: calc(100% - 40px);

            .RSPBstep {

              .inactive .circleIconState {
                fill: #BCBCBC;
              }

              .icon {
                border-radius: 50%;
                padding: 5px;

                svg {
                  width: 35px;
                }
              }
            }

            .RSPBprogression {
              border-radius: 0;
            }
          }

          &.oneColor {

            .RSPBprogressBar {

              .RSPBprogression {
                background: linear-gradient(to right, rgb(70, 193, 125) 0%, rgb(70, 193, 125) 100%);
              }
            }
          }

          &.twoColors {

            .RSPBprogression {
              background: linear-gradient(to right, rgb(70, 193, 125) 0%, rgb(70, 193, 125) 50%,
              rgb(126, 115, 248) 50%, rgb(126, 115, 248) 100%);
            }
          }

          &.threeColors {

            .RSPBprogression {
              background: linear-gradient(to right, rgb(70, 193, 125) 0%, rgb(70, 193, 125) 33%,
              rgb(126, 115, 248) 33%, rgb(126, 115, 248) 66%,
              rgb(199, 179, 1) 66%, rgb(199, 179, 1) 100%);
            }
          }

          &.fourColors {

            .RSPBprogression {
              background: linear-gradient(to right, rgb(70, 193, 125) 0%, rgb(70, 193, 125) 25%,
              rgb(126, 115, 248) 25%, rgb(126, 115, 248) 50%,
              rgb(199, 179, 1) 50%, rgb(199, 179, 1) 75%,
              rgb(97, 97, 97) 75%, rgb(97, 97, 97) 100%);
            }
          }
        }

        &--last-state {
          font-size: 0.8rem !important;
          display: block !important;

          span {
            text-transform: lowercase;
            font-weight: bold;
          }
        }

        &--tracker {
          font-size: 0.8rem !important;
        }
      }

      &__description {

        &--state {

          span {
            font-weight: bold;
          }
        }
      }

      .accordionPumpTimeline {
        width: 100%;
        padding: 7px 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      [class^='vehicle-details__row'] {
        display: inline-flex;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
        flex-wrap: wrap;
      }

      [class^='vehicle-details__block'] {
        display: inline-flex;
        padding: 2px 0;
        align-items: center;
      }

      [class^='vehicle-details__icon'] {
        width: 18px;
        height: 18px;
        margin: 0 2px;
        border-radius: 50%;
        overflow: hidden;
      }

      [class^='vehicle-details__description'] {
        padding: 2px;
        font-size: 13px;
      }
    }

    .actions {
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiButton-label {
        color: rgb(98, 0, 238);
      }
    }
  }

  .pulse {
    animation: ${KeyFramePulse} 1.5s infinite;
  }
`;
