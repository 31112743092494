import { EventsMessages, TranslationEventsMessages } from "../interfaces";

export const messages: TranslationEventsMessages = {
  [EventsMessages.notFound]: "Event not found",
  [EventsMessages.updateSuccess]: "Event updated successfully",
  [EventsMessages.updateError]: "Error updating event",

  [EventsMessages.stop]: "Stop",
  [EventsMessages.improperDischarge]: "Improper discharge",
  [EventsMessages.speeding]: "Speeding",
  [EventsMessages.vehicleBlock]: "Vehicle block",
  [EventsMessages.concreteExpiration]: "Concrete expiration",
  [EventsMessages.speedingTravel]: "velocidad de desplazamiento excedida"

};
