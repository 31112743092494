/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase, max-classes-per-file */

import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  Unique,
  UpdateDateColumn
} from "typeorm";
import { Alert } from "./reg_alerts.entity";
import { ConfigCommand } from "./reg_config_commands.entity";
import { SimCard } from "./reg_sim_cards.entity";
import { Travel } from "./reg_travels.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { TpeHardware } from "./tpe_hardwares_types.entity";
import { Driver } from "./reg_drivers.entity";
import { HardwareInstallationHistory } from "./hty_hardware_installations.entity";

@Entity("reg_hardwares")
@Unique(["type", "vehicle"])
export class Hardware {

  @PrimaryGeneratedColumn("uuid")
    id_hardware?: string;

  @Column({ type: "varchar", nullable: false })
    brand?: string;

  @Column({ type: "varchar", nullable: false })
    model?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
    dev_id?: string;

  @Column({ type: "varchar", nullable: true })
    ibutton_code?: string;

  @Column({ type: "timestamp", nullable: false })
    purchase_date?: Date;

  @Column({ type: "boolean", nullable: false, default: true })
    active?: boolean;

  @Column({ type: "boolean", nullable: false, default: true })
    idle?: boolean;

  @Column({ type: "timestamp", nullable: true })
    last_load_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    last_unload_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    last_send_date?: Date;

  @Column({ type: "varchar", nullable: true })
    last_command?: string;

  @Column({ type: "varchar", nullable: true })
    last_latitude?: string;

  @Column({ type: "varchar", nullable: true })
    last_longitude?: string;

  @Column({ type: "varchar", nullable: true })
    last_voltage?: string;

  @Column({ type: "boolean", nullable: false, default: false })
    block_capable?: boolean;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    last_satellites?: number;

  @Column({
    type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    last_course?: number;

  @Column({ type: "varchar", nullable: true })
    iccid_req?: string;

  @Column({ type: "timestamp", nullable: true })
    iccid_req_date?: Date;

  @Column({ type: "boolean", nullable: false, default: false })
    send_diagnostic_commands?: boolean;

  @Column({ type: "timestamp", nullable: true })
    last_send_date_tx_sensor?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  // region Relations
  @ManyToOne(() => TpeHardware, (type) => type.hardwares, { nullable: false })
  @JoinColumn({ name: "id_hardware_type_fk" })
    type?: TpeHardware;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.hardwares, { nullable: true })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

  @OneToOne(() => SimCard, (sim_card) => sim_card.hardware, { nullable: true })
  @JoinColumn({ name: "id_sim_card_fk" })
    sim_card?: SimCard;

  @OneToMany(() => Alert, (alerts) => alerts.hardware)
    alerts?: Alert[];

  @OneToMany(() => Travel, (travels) => travels.tracker)
    travels?: Travel[];

  @OneToMany(() => ConfigCommand, (ConfigCommands) => ConfigCommands.tracker)
    ConfigCommands?: ConfigCommand[];

  @OneToMany(() => HardwareInstallationHistory, (hardwareInstallationHistory) => hardwareInstallationHistory.hardware)
    hardwareInstallationHistory?: HardwareInstallationHistory[];

  @OneToOne(() => Driver, (driver) => driver.hardware, { nullable: true })
    driver?: Driver;

  UpdateSensorStatus?(trackerData: string, updateTxLastSendData = true): void {

    if (updateTxLastSendData) this.last_send_date_tx_sensor = new Date(trackerData);

    this.active = true;
    this.last_send_date = new Date(trackerData);
  }

  // endregion Relations

}

export class HardwareErrors {

  static readonly DUPLICATE_KEY = "3.4.1 (TCON333431)";

  static readonly HARDWARE_IN_USE = "3.4.2 (TCON333432)";

}
