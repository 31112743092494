import { VehiclesHistoryMessages, TranslationVehiclesHistoryMessages } from "../interfaces";

export const messages: TranslationVehiclesHistoryMessages = {
  [VehiclesHistoryMessages.data]: "Dado",
  [VehiclesHistoryMessages.equipment]: "Equipamiento",
  [VehiclesHistoryMessages.travels]: "Viajes",
  [VehiclesHistoryMessages.engineRuntime]: "Tiempo encendido",
  [VehiclesHistoryMessages.kmTraveled]: "Km recorridos",
  [VehiclesHistoryMessages.productionVolume]: "Volumen de producción",
  [VehiclesHistoryMessages.volumePerTrip]: "Volumen/viaje",
  [VehiclesHistoryMessages.durationPerTrip]: "Duración/viaje",
  [VehiclesHistoryMessages.engineRuntimePerTrip]: "Tiempo encendido/viaje",
  [VehiclesHistoryMessages.kmTraveledPerTrip]: "Km/viaje",
  [VehiclesHistoryMessages.speedingAlerts]: "Excesos de velocidad",
  [VehiclesHistoryMessages.improperDischargeAlerts]: "Descargas indebidas",
  [VehiclesHistoryMessages.improperStopAlerts]: "Paradas indebidas",
  [VehiclesHistoryMessages.licensePlate]: "Placa",
  [VehiclesHistoryMessages.durationInMinutes]: "Duración (min)",
  [VehiclesHistoryMessages.engineRuntimeInMinutes]: "Tiempo encendido (min)",
  [VehiclesHistoryMessages.distanceInKilometers]: "Distancia (km)",
  [VehiclesHistoryMessages.durationPerTripInMinutes]: "Duración/viaje (min)",
  [VehiclesHistoryMessages.engineRuntimePerTripInMinutes]: "Tiempo encendido/viaje (min)",
  [VehiclesHistoryMessages.distancePerTripInKilometers]: "Distancia/viaje (km)",
  [VehiclesHistoryMessages.title]: "Historial de vehículos",
  [VehiclesHistoryMessages.vehicleType]: "Tipo de vehículo",
  [VehiclesHistoryMessages.generalHistory]: "Historial general",
  [VehiclesHistoryMessages.historyByEquipment]: "Historial por equipamiento"
};
