/* eslint-disable no-restricted-globals */

import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_TEST === "ON"
    ? "http://dev-api.topconsuite.app.br"
    : `https://${location.hostname.split(".")[0]}-api.rastrin.app`
});

export default api;
