import React from "react";

// region imports - External libraries
import _ from "lodash";
import { matchesSubstringIgnoreCaseAndAccents } from "@npm-telluria-tecnologia/telluria-utils";
import { SyncLoader } from "react-spinners";
import { Button } from "@material-ui/core";
import { Close } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
// endregion imports - External libraries
// region imports - Atoms
import AccordionSummaryChips from "@atoms/AccordionSummaryChips";
// endregion imports - Atoms
// region imports - Molecules
import KanbanAccordionCard from "@molecules/KanbanAccordionCard";
// endregion imports - Molecules
// region imports - Organisms
import { IOptionValue } from "@organisms/GenericQueryFilter";
// endregion imports - Organisms
// region imports - Languages
import { DualListboxMessages, FilterMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion imports - Languages
// region imports - Styles
import * as Styled from "./styles";
// endregion imports - Styles

// region Interfaces

interface IProps {
  label: string;
  options: IOptionValue[];
  initialSelectedOptions: IOptionValue[];
  onChangeSelectedOptions: (selectedOptions: IOptionValue[]) => void;
  isLoading: boolean;
}

// endregion Interfaces

const KanbanAccordionCardFilter: React.FC<IProps> = ({
  label,
  onChangeSelectedOptions,
  options,
  initialSelectedOptions,
  isLoading
}) => {

  // region Hooks
  const { t } = useTranslation();
  // endregion Hooks

  // region States
  const [textFilter, setTextFilter] = React.useState<string>("");
  const [selectAllOptions, setSelectAllOptions] = React.useState<boolean>(true);
  const [selectAllFilteredOptions, setSelectAllFilteredOptions] = React.useState<boolean>(true);
  const [filteredOptions, setFilteredOptions] = React.useState<IOptionValue[]>(options);
  const [isInitialSelectedOptions, setIsInitialSelectedOptions] = React.useState<boolean>(true);
  const [selectedOptions, setSelectedOptions] = React.useState<IOptionValue[]>([]);
  const [expandedCard, setExpandedCard] = React.useState<boolean>(false);
  // endregion States

  // region Refs
  const textFieldRef = React.createRef<HTMLInputElement>();
  // endregion Refs

  // region Functions
  const handleAccordionCardChange = async (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedCard(isExpanded);
  };

  const setTextFieldFocus = () => {
    textFieldRef.current?.focus();
  };

  const handleClickSelectAllOptions = () => {
    setTextFieldFocus();

    if (selectAllOptions) {
      setSelectedOptions(filteredOptions.map((option) => option));

      return;
    }

    setSelectedOptions([]);
  };

  const handleClickSelectAllFilteredOptions = () => {
    setTextFieldFocus();

    setSelectAllFilteredOptions((value) => !value);

    if (selectAllFilteredOptions) {
      const updatedSelectedOptions = _.cloneDeep(selectedOptions);

      filteredOptions.forEach((option) => {
        const isAlreadySelected = updatedSelectedOptions.find((selectedOption) => selectedOption.value === option.value);

        if (!isAlreadySelected) {
          updatedSelectedOptions.push(option);
        }
      });

      setSelectedOptions(updatedSelectedOptions);

      return;
    }

    const optionsToKeep = selectedOptions.filter(
      (option) => !filteredOptions.some((filteredOption) => filteredOption.value === option.value)
    );

    setSelectedOptions(optionsToKeep);
  };

  const handleOptionCheck = (checkedOption: IOptionValue) => {
    const checkedOptionAlreadyExists = selectedOptions.some((range) => range.value === checkedOption.value) ?? false;

    setSelectedOptions(checkedOptionAlreadyExists
      ? selectedOptions.filter((range) => range.value !== checkedOption.value)
      : [...selectedOptions, checkedOption]);
  };

  const handleDeleteOption = (value: string) => {
    const updatedSelectedOptions = selectedOptions.filter((option) => option.value !== value);

    setSelectedOptions(updatedSelectedOptions);
  };

  const checkIfAllOptionsAreSelected = () => {
    setSelectAllOptions(selectedOptions.length !== filteredOptions.length || filteredOptions.length === 0);
  };

  const checkIfAllFilteredOptionsAreSelected = (filteredOptionsParam: IOptionValue[]) => {
    for (const option of filteredOptionsParam) {
      const isAlreadySelected = selectedOptions.find((selectedOption) => selectedOption.value === option.value);

      setSelectAllFilteredOptions(!isAlreadySelected);

      if (!isAlreadySelected) break;
    }
  };

  // endregion Functions

  // region Effects

  React.useEffect(() => {
    if (textFilter) checkIfAllFilteredOptionsAreSelected(filteredOptions);
    else checkIfAllOptionsAreSelected();

    if (!isInitialSelectedOptions) onChangeSelectedOptions(selectedOptions);
    else setIsInitialSelectedOptions(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  React.useEffect(() => {
    setFilteredOptions(options.filter((option) => matchesSubstringIgnoreCaseAndAccents(option.label, textFilter)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFilter, options]);

  React.useEffect(() => {
    setIsInitialSelectedOptions(!_.isEqual(initialSelectedOptions, selectedOptions));
    setSelectedOptions(initialSelectedOptions);

  }, [initialSelectedOptions]);
  // endregion Effects

  return (
    <Styled.Container>
      <KanbanAccordionCard
        onChange={handleAccordionCardChange}
        showExpandIcon
        accordionSummary={(
          <AccordionSummaryChips
            label={label}
            chips={selectedOptions.map((option) => ({ id: option.value, label: option.label }))}
            onDeleteChip={handleDeleteOption}
          />
        )}
        expanded={expandedCard}
      >
        <Styled.AccordionDetail>
          { expandedCard && (
            <>
              <TextField
                label={t(FilterMessages.filterOptions)}
                inputRef={textFieldRef}
                variant="outlined"
                onFocus={(ev) => ev.target.select()}
                size="small"
                fullWidth
                autoComplete="off"
                value={textFilter}
                onChange={(ev) => setTextFilter(ev.target.value)}
                InputProps={{
                  endAdornment: textFilter !== "" && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear filter"
                        onClick={() => {
                          setTextFilter("");
                          setTextFieldFocus();
                        }}
                        onMouseDown={(evt) => evt.preventDefault()}
                        edge="end"
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {textFilter === "" ? (
                <Button
                  type="button"
                  className="select-all-button"
                  disableRipple
                  onClick={handleClickSelectAllOptions}
                >
                  {selectAllOptions
                    ? t(FilterMessages.selectAll)
                    : t(FilterMessages.removeAll)}
                </Button>
              ) : (
                <Button
                  type="button"
                  className="select-all-button"
                  disableRipple
                  onClick={handleClickSelectAllFilteredOptions}
                >
                  {selectAllFilteredOptions
                    ? t(FilterMessages.selectAllFiltered)
                    : t(FilterMessages.removeAllFiltered)}
                </Button>
              )}
              <FormControl fullWidth className="form-control_values">
                <FormGroup>
                  {!filteredOptions.length && !isLoading && (
                    <Styled.NoOptions>{t(DualListboxMessages.noAvailableOptions)}</Styled.NoOptions>
                  )}
                  {
                    !isLoading && filteredOptions.length > 0 && filteredOptions.map((item) => (
                      <FormControlLabel
                        key={item.value}
                        control={(
                          <Checkbox
                            checked={selectedOptions.some((option) => option.value === item.value)}
                            onChange={() => {
                              handleOptionCheck(item);
                              setTextFieldFocus();
                            }}
                            size="small"
                          />
                        )}
                        label={item.label}
                      />
                    ))
                  }
                  { isLoading && (
                    <SyncLoader size={10} loading={isLoading} css={Styled.SyncLoader} />
                  )}
                </FormGroup>
              </FormControl>
            </>
          )}
        </Styled.AccordionDetail>
      </KanbanAccordionCard>
    </Styled.Container>
  );
};

KanbanAccordionCardFilter.defaultProps = {
};

export default KanbanAccordionCardFilter;
