export enum VehicleStatusMessages {
  active = "vehicleStatus.active",
  inactive = "vehicleStatus.inactive",
  inMotion = "vehicleStatus.inMotion",
  stationary = "vehicleStatus.stationary",
  tracker = "vehicleStatus.tracker",
  drum = "vehicleStatus.tank",
  currentSpeed = "vehicleStatus.currentSpeed",
  engine = "vehicleStatus.engine",
  engineOn = "vehicleStatus.engineOn",
  engineOff = "vehicleStatus.engineOff",
  engineRunningTime = "vehicleStatus.engineRunningTime",
  travel = "vehicleStatus.travel",
  odometer = "vehicleStatus.odometer",
  latestDataSent = "vehicleStatus.latestDataSent",
  lastLoadDateDescription = "vehicleStatus.lastLoadDateDescription",
  lastUnloadDateDescription = "vehicleStatus.lastUnloadDateDescription",
  pumpingTimeDescription = "vehicleStatus.pumpingTimeDescription",
  CANTelemetry = "vehicleStatus.CANTelemetry",
  totalFuelConsumption = "vehicleStatus.totalFuelConsumption",
  radiatorTemperature = "vehicleStatus.radiatorTemperature",
  averageFuelConsumption = "vehicleStatus.averageFuelConsumption",
  fuelLevel = "vehicleStatus.fuelLevel",
  totalEngineRunningTime = "vehicleStatus.totalEngineRunningTime",
  totalDrivingTime = "vehicleStatus.totalDrivingTime",
  totalFuelUsedInIdle = "vehicleStatus.totalFuelUsedInIdle",
  combinedGrossVehicleWeight = "vehicleStatus.combinedGrossVehicleWeight"
}

export type TranslationVehicleStatusMessages = {
  [key in VehicleStatusMessages]: string;
}
