import { EventsMessages, TranslationEventsMessages } from "../interfaces";

export const messages: TranslationEventsMessages = {
  [EventsMessages.notFound]: "Evento não encontrado",
  [EventsMessages.updateSuccess]: "Evento atualizado com sucesso",
  [EventsMessages.updateError]: "Erro ao atualizar evento",

  [EventsMessages.stop]: "Parada",
  [EventsMessages.improperDischarge]: "Descarregamento indevido",
  [EventsMessages.speeding]: "Excesso de velocidade",
  [EventsMessages.vehicleBlock]: "Bloqueio de veículo",
  [EventsMessages.concreteExpiration]: "Vencimento do concreto",
  [EventsMessages.speedingTravel]: "velocidade da viagem excedida"
};
