import { TranslationZonesValidityMessages,ZonesValidityMessages } from "../interfaces";

export const messages: TranslationZonesValidityMessages = {
  [ZonesValidityMessages.columnStartTime]: "Hora de Inicio",
  [ZonesValidityMessages.columnEndTime]: "Hora de Fin",
  [ZonesValidityMessages.columnLicensePlateEnd]: "Placa de Vehículo al Final",
  [ZonesValidityMessages.columnDayOfWeek]: "Día de la Semana",

  [ZonesValidityMessages.sunday]: "Domingo",
  [ZonesValidityMessages.monday]: "Lunes",
  [ZonesValidityMessages.tuesday]: "Martes",
  [ZonesValidityMessages.wednesday]: "Miércoles",
  [ZonesValidityMessages.thursday]: "Jueves",
  [ZonesValidityMessages.friday]: "Viernes",
  [ZonesValidityMessages.saturday]: "Sábado",

  [ZonesValidityMessages.zoneRuleEdit]: "Editar",
  [ZonesValidityMessages.zoneRuleTitle]: "Reglas de Zona",
  [ZonesValidityMessages.newZoneRule]: "Nueva Regla",
  [ZonesValidityMessages.zoneRuleDeleteConfirmationText]: "¿Está seguro de que desea eliminar la regla de la zona?",
  [ZonesValidityMessages.zoneRuleDeleteConfirmation]: "Confirmación de Eliminación",

  [ZonesValidityMessages.readSuccess]: "Reglas de la zona leídas con éxito",
  [ZonesValidityMessages.readError]: "Error al leer reglas de la zona",
  [ZonesValidityMessages.createSuccess]: "Reglas de la zona creadas con éxito",
  [ZonesValidityMessages.updateSuccess]: "Reglas de la zona actualizadas con éxito",
  [ZonesValidityMessages.updateError]: "No es posible actualizar la regra",
  [ZonesValidityMessages.createError]: "Error al crear reglas de la zona",
  [ZonesValidityMessages.noZonesFound]: "No se encontraron reglas de la zona",
  [ZonesValidityMessages.deleteSuccess]: "Reglas de la zona eliminadas con éxito",
  [ZonesValidityMessages.deleteError]: "Error al eliminar reglas de la zona",

}
