/* eslint-disable max-len */
import {
  GroupsModuleMessages,
  TranslationGroupsModuleMessages
} from "../interfaces";

export const messages: TranslationGroupsModuleMessages = {

  [GroupsModuleMessages.group]: "Grupo",
  [GroupsModuleMessages.name]: "Name",
  [GroupsModuleMessages.noNameRegistered]: "No name registered",
  [GroupsModuleMessages.active]: "Active",
  [GroupsModuleMessages.inactive]: "Inactive",
  [GroupsModuleMessages.externalId]: "External ID",
  [GroupsModuleMessages.registrationDate]: "Registration date",
  [GroupsModuleMessages.updatedDate]: "Updated date",
  [GroupsModuleMessages.code]: "Code",
  [GroupsModuleMessages.deleteConfirmationText]: "Are you sure you want to delete this user",
  [GroupsModuleMessages.deleteRegionalError]: "Failed to delete region",

  [GroupsModuleMessages.updateGroupError]: "Unable to update region",
  [GroupsModuleMessages.updateGroupSuccess]: "Group updated successfully",
  [GroupsModuleMessages.readGroupSuccess]: "Group(s) returned successfully",
  [GroupsModuleMessages.noGroupFound]: "No group found",
  [GroupsModuleMessages.createGroupSuccess]: "Successfully created region",
  [GroupsModuleMessages.createGroupError]: "Unable to create regional",
  [GroupsModuleMessages.deleteGroupSuccess]: "Unable to delete region"
};
