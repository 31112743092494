import styled from "styled-components";

export const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: calc(100% - 3rem);

  .chip {
    max-width: 100%;
  }

  .hiddenChipsCount {
    margin-left: 0.25rem;
  }

  }
`;
