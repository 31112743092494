/// <reference types="googlemaps" />

import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Location } from "@shared/interfaces/location.interface";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import utils from "@utils/useful-functions";
import ModalFormLocation from "@organisms/ModalFormLocation";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  LocationsModuleMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import api from "@services/api";
import { useToast } from "@hooks/useToast";
import { LocationsFormActions } from "@store/ducks/Locations/LocationsForm/locations-form.action";
import { State, states } from "@store/ducks/Locations/LocationsForm/locations-form.type";
import ButtonTable from "@components/Button/ButtonTable";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";

import {
  Container
} from "./styles";

const LocationData: React.FC = () => {

  /* region Refs */
  const locationsForm = useSelector(({ locationsForm: state }) => state);
  const dispatch = useDispatch();

  /* endregion Refs */
  /* region States */
  const [locations, setLocations] = useState<Location[]>([] as Array<Location>);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [loadingLocationList, setLoadingLocationList] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);
  /* endregion States */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  /* endregion Hooks */
  /* region Constants */
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-location-list-details",
      callback: (rowData: Location) => handleLocationDetails(rowData)
    },
    {
      ref: ".modules-location-list-delete",
      callback: (rowData: Location) => handleLocationDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Nome
      title: t(LocationsModuleMessages.name),
      data: (location: Location) => location.name || t(LocationsModuleMessages.noNameRegistered),
      filterable: true,
      defaultContent: "",
      propertyName: "name"
    },
    { // Type locations
      title: t(LocationsModuleMessages.type),
      data: (location: Location) => location.type.description,
      filterable: true,
      defaultContent: "",
      propertyName: "type.description"
    },
    { // Logradouro
      title: t(LocationsModuleMessages.address),
      data: (location: Location) => location.address,
      filterable: true,
      defaultContent: "",
      propertyName: "address"
    },
    { // Número
      title: t(LocationsModuleMessages.number),
      data: (location: Location) => location.number || "",
      filterable: true,
      defaultContent: "",
      propertyName: "number"
    },
    { // Bairro
      title: t(LocationsModuleMessages.district),
      data: (location: Location) => location.district,
      filterable: true,
      defaultContent: "",
      propertyName: "district"
    },
    { // Regional
      title: t(LocationsModuleMessages.regional),
      data: (location: Location) => location.regional?.name || "",
      filterable: true,
      defaultContent: "",
      propertyName: "regional"
    },
    { // Cidade
      title: t(LocationsModuleMessages.county),
      data: (location: Location) => location.county,
      filterable: true,
      defaultContent: "",
      propertyName: "county"
    },
    { // CEP
      title: t(LocationsModuleMessages.zipCode),
      data: (location: Location) => location.cep || "",
      filterable: true,
      defaultContent: "",
      propertyName: "cep"
    },
    { // Ativo
      title: "Status",
      filterable: true,
      propertyName: "active",
      data: (location: Location) => (
        ReactDOMServer.renderToString(
          <b style={{ color: location.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {location.active ? t(LocationsModuleMessages.active).toUpperCase() : t(LocationsModuleMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      toFilterValue: (location: Location) => (
        location.active ? t(LocationsModuleMessages.active).toUpperCase() : t(LocationsModuleMessages.inactive).toUpperCase()
      )
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-location-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-location-list-delete"><DeleteForeverIcon /></ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleLocationCreate()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readLocations()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_locais_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  // Actions locations list
  const handleLocationDetails = useCallback((location: Location) => {

    dispatch(LocationsFormActions.setLocationsForm({
      ...locationsForm,
      data: location,
      type: "details",
      open: true,
      fieldsValues: {
        cep: location.cep,
        type: location.type,
        regional: location.regional,
        uf: states.find((state) => state.abbreviation === location.uf) as State,
        coord: { lat: Number(location.latitude), lng: Number(location.longitude) }
      }
    }));

  }, [dispatch, locationsForm]);
  const handleLocationDelete = useCallback((location: Location) => {

    dispatch(LocationsFormActions.setLocationsForm({
      ...locationsForm,
      data: location
    }));

    setOpenDialogConfirmDelete(true);

  }, [dispatch, locationsForm]);
  /*
   * Open modal to create new location
   * */
  const FhandleLocationCreate = useCallback(() => {

    dispatch(LocationsFormActions.setLocationsForm({
      ...locationsForm,
      data: { finish_point: true, active: true } as Location,
      type: "register",
      open: true
    }));

  }, [dispatch, locationsForm]);

  // Actions on close/open modal/dialog
  const handleCloseDialogConfirmDelete = useCallback(() => {
    setOpenDialogConfirmDelete(false);
  }, []);

  /** Get all locations data
   */
  const readLocations = useCallback(async () => {

    try {

      // Get the travels of selected vehicle
      setLoadingLocationList(true);
      const { data } = await api.get("locations/get-without-constructions");

      if (data.status === "success") setLocations(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingLocationList(false);
    }

  }, [addToast, t]);

  /** Delete selected location
   * @param location Location to delete
   */
  const deleteLocation = useCallback(async (location: Location) => {

    try {

      setLoadingLocationList(true);
      const { data } = await api.delete(`locations/delete/${location.id_location}`);

      if (data.status === "success") {

        handleCloseDialogConfirmDelete(); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.row(tableClickedRow).remove();
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingLocationList(false);
    }

  }, [addToast, handleCloseDialogConfirmDelete, table, tableClickedRow, t]);

  /* endregion Functions */
  /* region Effects */
  // Used to disable and change text when is searching for data
  useEffect(() => {
    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingLocationList ? t(LocationsModuleMessages.searching) : t(GlobalMessages.search);

      if (loadingLocationList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingLocationList, t]);

  useEffect(() => {
    readLocations().then();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  /* endregion Effects */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform}>
        <Loading loading={loadingLocationList} />
        {
          !loadingLocationList && (
            <NewDataTable
              title={t(LocationsModuleMessages.dataTableTitle)}
              filters
              data={locations}
              columns={dataTableColumns}
              actions={dataTableActions}
              settings={dataTableSettings}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={handleCloseDialogConfirmDelete}
          title={t(LocationsModuleMessages.deleteConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.no), action: () => handleCloseDialogConfirmDelete() },
            { text: t(GlobalMessages.yes), action: () => deleteLocation(locationsForm.data) }
          ]}
        >
          {t(LocationsModuleMessages.deleteConfirmationText)} <br /><br />
          {locationsForm.data.name && <><span>{t(LocationsModuleMessages.name)}: {locationsForm.data.name}</span><br /></>}
          {t(LocationsModuleMessages.address)}: {locationsForm.data.address},
          {locationsForm.data.number || t(LocationsModuleMessages.noNumber)} <br />
          {t(LocationsModuleMessages.district)}: {locationsForm.data.district} - {locationsForm.data.county}
        </DialogConfirmAction>
        <ModalFormLocation readLocations={readLocations} locations={locations} table={{ reference: table, clickedRow: tableClickedRow }} />
      </Container>
    </>
  );
};

export default LocationData;
