import { VehicleMaintenanceMessages, TranslationVehicleMaintenanceMessages } from "../interfaces";

export const messages: TranslationVehicleMaintenanceMessages = {

  /* region Backend */
  [VehicleMaintenanceMessages.inMaintenance]: "In maintenance",
  [VehicleMaintenanceMessages.outOfMaintenance]: "Out of maintenance",
  [VehicleMaintenanceMessages.vehicleIsAlready]: "Vehicle is already",
  [VehicleMaintenanceMessages.cannotGetLocation]: "Could not get vehicle location to generate the event.",
  [VehicleMaintenanceMessages.putInMaintenanceSuccess]: "Vehicle put in maintenance successfully.",
  [VehicleMaintenanceMessages.putOutOfMaintenanceSuccess]: "Vehicle taken out of maintenance successfully.",
  [VehicleMaintenanceMessages.changeMaintenanceStatusError]: "Failed to change vehicle maintenance status",
  /* endregion Backend */

  /* region Frontend */
  [VehicleMaintenanceMessages.checkVehicle]: "Check vehicle",
  [VehicleMaintenanceMessages.removeFromMaintenance]: "Remove from maintenance",
  [VehicleMaintenanceMessages.removeFromMaintenanceConfirmation]: "Do you really want to take the vehicle out of"
  + " maintenance?",
  [VehicleMaintenanceMessages.justifyOrSelect]: "Justify or select ",
  [VehicleMaintenanceMessages.maintenance]: "Maintenance",
  [VehicleMaintenanceMessages.selectMaintenance]: "Select maintenance",
  [VehicleMaintenanceMessages.justifyMaintenance]: "Justify maintenance",
  [VehicleMaintenanceMessages.maintenanceOptions]: "Maintenance options",
  [VehicleMaintenanceMessages.maintenanceConfirmation]: "Maintenance confirmation",
  [VehicleMaintenanceMessages.maintenanceConfirmationMessage]: "Do you really want to put the vehicle in maintenance?",
  [VehicleMaintenanceMessages.youAreAboutToPutTheVehicle]: "You are about to put the vehicle ",
  [VehicleMaintenanceMessages.yourActionWillPutTheVehicleInMaintenance]: "Your action will put the vehicle in"
  + " maintenance.",
  [VehicleMaintenanceMessages.optionHydraulic]: "Hydraulic",
  [VehicleMaintenanceMessages.optionElectrical]: "Electrical",
  [VehicleMaintenanceMessages.optionTireService]: "Tire Service",
  [VehicleMaintenanceMessages.optionSuspension]: "Suspension",
  [VehicleMaintenanceMessages.optionBodywork]: "Bodywork",
  [VehicleMaintenanceMessages.optionRockBreaker]: "Rock Breaker",
  [VehicleMaintenanceMessages.optionOthers]: "Others (justify)"
  /* endregion Frontend */
};
