export enum YupMessages {
  justificationRequired = "yup.string.justificationRequired",
  expectedDateRequired = "yup.date.expectedDateRequired",
  expectedDateGreaterThanCurrentDate = "yup.date.expectedDateGreaterThanCurrentDate",
  originLocationRequired = "yup.string.originLocationRequired",
  loadingLocationRequired = "yup.string.loadingLocationRequired",
  unloadingLocationRequired = "yup.string.unloadingLocationRequired",
  vehicleRequired = "yup.string.vehicleRequired",
  productRequired = "yup.string.productRequired",
  serviceOrderTypeRequired = "yup.string.serviceOrderTypeRequired",
  emailRequired = "yup.string.emailRequired",
  passwordRequired = "yup.string.passwordRequired",
  passwordMinLength = "yup.string.passwordMinLength",
  invalidEmail = "yup.string.invalidEmail",
  passwordsDontMatch = "yup.string.passwordsDontMatch",
  justificationMinLength15 = "yup.string.justificationMinLength15",
  finishDateGreaterThanStartDate = "yup.date.finishDateGreaterThanStartDate",
  maxDateInterval24h = "yup.date.maxDateInterval24h",
  maxDateInterval7d = "yup.date.maxDateInterval7d",
  maxPeriodInterval30d = "yup.date.maxPeriodInterval30d",
  maxPeriodInterval7d = "yup.date.maxPeriodInterval7d",
  maxPeriodInterval1d = "yup.date.maxPeriodInterval1d",
  maxDateInterval30d = "yup.date.maxDateInterval30d",
  codeRequired = "yup.string.codeRequired",
  incompleteLicensePlate = "yup.string.incompleteLicensePlate",
  licensePlateRequired = "yup.string.licensePlateRequired",
  typeRequired = "yup.string.typeRequired",
  descriptionRequired = "yup.string.descriptionRequired",
  callerIDRequired = "yup.string.callerIDRequired",
  ICCIDRequired = "yup.string.ICCIDRequired",
  phoneCompanyRequired = "yup.string.phoneCompanyRequired",
  purchaseDateRequired = "yup.date.purchaseDateRequired",
  fieldRequired = "yup.string.fieldRequired",
  fieldMaxLength30 = "yup.string.fieldMaxLength30",
  uniqueField = "yup.string.uniqueField",
  largeFileSize = "yup.string.largeFileSize"
}

export type TranslationYupMessages = {
  [key in YupMessages]: string;
}
