/* eslint-disable no-param-reassign */
import React, { ButtonHTMLAttributes } from "react";

// region Languages
import { GlobalMessages } from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
// endregion Languages
// region Styles
import * as Styled from "./styles";
// endregion Styles

type ButtonLoginMicrosoftProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loadingContent?: string;
  disabled?: boolean;
}

const ButtonLoginMicrosoft: React.FC<ButtonLoginMicrosoftProps> = ({
  children, loading, loadingContent, disabled, ...rest
}) => {

  const { t } = useTranslation();

  loadingContent = loadingContent ?? t(GlobalMessages.loading);

  return (
    <Styled.Container type="button" {...rest} disabled={!!loading || disabled}>
      <Styled.MicrosoftIcon />
      {loading ? loadingContent : children}
    </Styled.Container>
  );

};

ButtonLoginMicrosoft.defaultProps = {
  loading: false,
  disabled: false
};

export default ButtonLoginMicrosoft;
