import React, { useState } from "react";
import { useSelector } from "react-redux";

// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  PumpProgrammingMessages
} from "@shared/languages/interfaces";
import { ChangeTypeMessages } from "@shared/languages/interfaces/pumpProgrammingMessages";
// endregion Imports - Languages
// region Imports - Molecules
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Molecules
// region Imports - Organisms
import QueryFilterPumpProgrammingHistory from "@organisms/QueryFilterPumpProgrammingHistory";
// endregion Imports - Organisms
// region Imports - Shared
import { PumpProgrammingHistory as PumpProgrammingHistoryEntity } from "@shared/entities/hty_pump_programmings.entity";
import { Travel } from "@shared/entities/reg_travels.entity";
// endregion Imports - Shared
// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Store
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Styles
import { Container } from "./styles";
// endregion Imports - Styles

const PumpProgrammingHistory: React.FC = () => {
  // region States

  // Filter states
  const [loadMoreToggle, setLoadMoreToggle] = useState(false);
  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  const [pumpProgrammingList, setPumpProgrammingList] = useState<Travel[]>([] as Array<Travel>);
  // endregion States
  // region Hooks
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  // endregion Hooks
  // region Constants
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "desc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableColumns: DataTableColumns[] = [
    { // Data de ocorrência
      title: t(PumpProgrammingMessages.columnOccurrenceDate),
      data: (history: PumpProgrammingHistoryEntity) => utils.formatDateIfHave(history.registration_date, "fullDate"),
      filterable: true,
      defaultContent: "",
      propertyName: "history.registration_date"
    },
    { // Usuário
      title: t(PumpProgrammingMessages.columnUser),
      data: (history: PumpProgrammingHistoryEntity) => history?.user?.name,
      filterable: true,
      defaultContent: "",
      propertyName: "history.user.name"
    },
    { // Tipo de alteração
      title: t(PumpProgrammingMessages.columnChangeType),
      data: (history: PumpProgrammingHistoryEntity) => {
        const text = t(ChangeTypeMessages[history.change_type!]);
        const color = utils.getChangeTypeColor(history.change_type!);

        return `<span style="color: ${color}; font-weight: bold;">${text}</span>`;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "history.change_type"
    },
    { // Importação referente
      title: t(PumpProgrammingMessages.columnImportRef),
      data: (history: PumpProgrammingHistoryEntity) => history?.pumpProgrammingImport?.description,
      filterable: true,
      defaultContent: "",
      propertyName: "history.pumpProgrammingImport.description"
    },
    { // Doc.
      title: t(PumpProgrammingMessages.columnDoc),
      data: (history: PumpProgrammingHistoryEntity) => history?.travel?.num_doc,
      filterable: true,
      defaultContent: "",
      propertyName: "history.travel.num_doc"
    },
    { // Id externo
      title: t(PumpProgrammingMessages.columnExternalId),
      data: (history: PumpProgrammingHistoryEntity) => history?.travel?.external_id,
      filterable: true,
      defaultContent: "",
      propertyName: "history.travel.external_id"
    },
    { // Data do documento
      title: t(PumpProgrammingMessages.columnDocumentDate),
      data: (history: PumpProgrammingHistoryEntity) => utils.formatDateIfHave(history?.travel?.document_date, "fullDate"),
      filterable: true,
      defaultContent: "",
      propertyName: "history.travel.document_date"
    },
    { // Veículo
      title: t(PumpProgrammingMessages.columnVehicle),
      data: (history: PumpProgrammingHistoryEntity) => history?.travel?.vehicle?.code,
      filterable: true,
      defaultContent: "",
      propertyName: "history.travel.vehicle.code"
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_programacoes_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.moreRecords),
      callback: () => {
        setLoadMoreToggle((prevState) => !prevState);
      },
      key: "moreRecords"
    }
  ];
  // endregion Constants
  // region Functions
  /**
   * Handle apply filter to get pump programming data
   * @param numberOfFilteredOptions
   * @param returnedData
   * @param loadMore
   */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: PumpProgrammingHistoryEntity[],
    loadMore: boolean
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    if (loadMore) setPumpProgrammingList([...pumpProgrammingList, ...returnedData]);
    else setPumpProgrammingList(returnedData);
  };
  // endregion Functions

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform}>
        <NewDataTable
          title={t(PumpProgrammingMessages.dataTableTitle)}
          filters
          data={pumpProgrammingList}
          columns={dataTableColumns}
          settings={dataTableSettings}
          buttons={dataTableButtons}
          onClickFilterButton={() => setOpenFilterFields(true)}
          numberOfFilteredOptions={numberOfFilteredOptions}
        />
        <QueryFilterPumpProgrammingHistory
          open={openFilterFields}
          onClose={() => { setOpenFilterFields(false); }}
          onFilter={handleApplyFilter}
          loadMoreToggle={loadMoreToggle}
        />
      </Container>
    </>
  );
};

export default PumpProgrammingHistory;
