import { TranslateConfigCommandsMessages, ConfigCommandsContentID } from "../interfaces";

export const messages: TranslateConfigCommandsMessages = {
  [ConfigCommandsContentID.CHECAR_ICCID_ST4315U]: "Verificar ICCID - ST4315U",
  [ConfigCommandsContentID.CHECAR_ICCID_ST380]: "Verificar ICCID - ST380",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO]: "Cambiar horímetro",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO]: "Cambiar odómetro",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BETONEIRA]: "Cambiar tiempo de envío - Betoneira (60s ligado/ 1h desligado)",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS_BOMBA]: "Cambiar tiempo de envío - Bomba (60s ligado/ 60s"
  + " desligado)",
  [ConfigCommandsContentID.LIMPAR_MEMORIA]: "Limpiar Memoria",
  [ConfigCommandsContentID.REINICIAR]: "Reiniciar",
  [ConfigCommandsContentID.DESABILITAR_IGNICAO_FISICA]: "Desactivar la ignición física",
  [ConfigCommandsContentID.HABILITAR_IGNICAO_FISICA]: "Activar la ignición física",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_BATERIA]: "Cambiar ignición para batería",
  [ConfigCommandsContentID.ALTERACAO_IGNICAO_ACELEROMETRO]: "Cambiar ignición para acelerómetro",
  [ConfigCommandsContentID.ALTERACAO_TEMPO_ENVIO_DADOS]: "Cambiar tiempo de envío de datos",
  [ConfigCommandsContentID.PRESET_A]: "Preset A",
  [ConfigCommandsContentID.ALTERACAO_SERVIDOR_DESTINO]: "Cambiar servidor de destino de los mensajes",
  [ConfigCommandsContentID.CHECAR_ICCID_ST300]: "Comprobar ICCID - Familia ST300, excluyendo el ST380",
  [ConfigCommandsContentID.ALTERACAO_ODOMETRO_4315_8310]: "Cambiar odómetro - 4315/8310",
  [ConfigCommandsContentID.ALTERACAO_HORIMETRO_4315_8310]: "Cambiar horímetro - 4315/8310"
};
