export enum CoordinateAnalysisMessages {
  command = "coordinateAnalysis.command",
  identifier = "coordinateAnalysis.identifier",
  model = "coordinateAnalysis.model",
  softwareVersion = "coordinateAnalysis.softwareVersion",
  date = "coordinateAnalysis.date",
  time = "coordinateAnalysis.time",
  locationCode = "coordinateAnalysis.locationCode",
  latitude = "coordinateAnalysis.latitude",
  longitude = "coordinateAnalysis.longitude",
  speed = "coordinateAnalysis.speed",
  ignition= "coordinateAnalysis.ignition",
  inclination = "coordinateAnalysis.inclination",
  activeSatellites = "coordinateAnalysis.activeSatellites",
  validPosition = "coordinateAnalysis.validPosition",
  odometer = "coordinateAnalysis.odometer",
  batteryVoltage = "coordinateAnalysis.batteryVoltage",
  inAndOutInputs = "coordinateAnalysis.inAndOutInputs",
  activeMode = "coordinateAnalysis.activeMode",
  numberOfMessages = "coordinateAnalysis.numberOfMessages",
  hourMeter = "coordinateAnalysis.hourMeter",
  backupBatteryVoltage = "coordinateAnalysis.backupBatteryVoltage",
  messageType = "coordinateAnalysis.messageType",
  mixerRPM = "coordinateAnalysis.mixerRPM",
  mixerBatteryVoltage = "coordinateAnalysis.mixerBatteryVoltage",
  driverIdentifier = "coordinateAnalysis.driverIdentifier",
  registrationStatus = "coordinateAnalysis.registrationStatus",
  serviceIdentification = "coordinateAnalysis.serviceIdentification",
  countryCode = "coordinateAnalysis.countryCode",
  networkCode = "coordinateAnalysis.networkCode",
  receptionLevel = "coordinateAnalysis.receptionLevel",
  locationAreaCode = "coordinateAnalysis.locationAreaCode",
  temporalInfo = "coordinateAnalysis.temporalInfo",
  GPSStatus = "coordinateAnalysis.GPSStatus",
  vehicleIdentifier = "coordinateAnalysis.vehicleIdentifier",
  ignitionKey = "coordinateAnalysis.ignitionKey",
  CANOdometer = "coordinateAnalysis.CANOdometer",
  totalFuelConsumption = "coordinateAnalysis.totalFuelConsumption",
  CANSpeed = "coordinateAnalysis.CANSpeed",
  engineRPM = "coordinateAnalysis.engineRPM",
  engineCoolantTemperature = "coordinateAnalysis.engineCoolantTemperature",
  fuelConsumption = "coordinateAnalysis.fuelConsumption",
  fuelLevel = "coordinateAnalysis.fuelLevel",
  CANHourMeter = "coordinateAnalysis.CANHourMeter",
  drivingTime = "coordinateAnalysis.drivingTime",
  fuelConsumptionInIdle = "coordinateAnalysis.fuelConsumptionInIdle",
  vehicleWeight = "coordinateAnalysis.vehicleWeight",
  sensorStatus = "coordinateAnalysis.sensorStatus",
  drumInclination = "coordinateAnalysis.drumInclination",
  totalWaterAdded = "coordinateAnalysis.totalWaterAdded",
  drumPressure = "coordinateAnalysis.drumPressure",
  drumTemperature = "coordinateAnalysis.drumTemperature",
  ignitionOn = "coordinateAnalysis.ignitionOn",
  ignitionOff = "coordinateAnalysis.ignitionOff",

  coordinateAnalysisTitle = "coordinateAnalysis.title",
  mapTitle = "coordinateAnalysis.mapTitle",
  dataTableTitle = "coordinateAnalysis.dataTableTitle",

  alerts = "coordinateAnalysis.alerts",
  states = "coordinateAnalysis.states",
  noAlertsForThisPeriod = "coordinateAnalysis.noAlertsForThisPeriod",
  commands = "coordinateAnalysis.commands",
  noCommandsForThisPeriod = "coordinateAnalysis.noCommandsForThisPeriod",
  noStatesForThisPeriod = "coordinateAnalysis.noStatesForThisPeriod",
  showMarker = "coordinateAnalysis.showMarker",
  travel = "coordinateAnalysis.travel",
  travelHelperText = "coordinateAnalysis.travelHelperText",
  startDate = "coordinateAnalysis.startDate",
  finishDate = "coordinateAnalysis.finishDate",
  loadingContent = "coordinateAnalysis.loadingContent"
}

export type TranslationCoordinateAnalysisMessages = {
  [key in CoordinateAnalysisMessages]: string;
}
