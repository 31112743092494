import styled from "styled-components";
import BlurMap from "@assets/blur-map.jpg";

export const ContainerTimeLineItemMap = styled.div`

.hidden {
  display: none !important;
}
.staticMap {
  display: flex;
  justify-content: center;
  height: 180px;
  align-items: center;
  background-image: url(${BlurMap});
  background-repeat: no-repeat;
  background-size: cover;

  img {
    cursor: pointer;
    width: 50px;
    font-weight: bold;
    /* box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%); */
  }
}
.drawerMap {
  display: flex;
  justify-content: center;
  height: 180px;
  align-items: center;
  background-image: url(${BlurMap});
  background-repeat: no-repeat;
  background-size: cover;
}
`;
