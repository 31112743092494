import React from "react";
import { ReactSVG } from "react-svg";

// region Assets
import IconAlertBloqueio from "@assets/icons/alerts/bloqueio.svg";
import IconAlertDescargaIndevida from "@assets/icons/alerts/descarregamento-indevido.svg";
import IconAlertExcessoVelocidade from "@assets/icons/alerts/excesso-velocidade.svg";
import IconAlertParadaIndevida from "@assets/icons/alerts/parada-indevida.svg";
import IconCaminho from "@assets/stateIcons/A caminho.svg";
import IconDescarregando from "@assets/stateIcons/Descarregando.svg";
import IconObra from "@assets/stateIcons/Na obra.svg";
import IconLocalConfiavel from "@assets/stateIcons/Em local confiavel.svg";
import IconRetornando from "@assets/stateIcons/Retorno.svg";
import IconUsina from "@assets/stateIcons/Usina.svg";
// endregion Assets
// region Libraries
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  AlertMessages,
  GlobalMessages,
  HelpMessages,
  MenuMessages,
  VehiclesStatesMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Components
// endregion Components
// region Styles
import { ColorSquare, Container } from "./styles";
// endregion Styles

type ModalIconLegendProps = { onClose: () => void }

const HelpColorItemList = ({ color, text }) => (
  <ListItem>
    <ListItemIcon>
      <ColorSquare color={color} />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const HelpIconItemList = ({ icon, text }) => (
  <ListItem>
    <ListItemIcon>
      <ReactSVG src={icon} />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const ModalHelp: React.FC<ModalIconLegendProps> = ({ onClose }) => {

  const { t } = useTranslation();

  // Used to remove warning
  const openDialog = true;

  return (
    <Container>
      <Dialog
        open={openDialog}
        id="modalHelp"
        onClose={() => onClose()}
        scroll="paper"
      >
        <DialogTitle className="mHeader">{t(MenuMessages.help)}</DialogTitle>
        <DialogContent dividers className="mContent">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t(HelpMessages.titleColors)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography className="subtitle">{t(HelpMessages.subtitleOperationalStates)}</Typography>
                <div className="subcontent">
                  <Typography className="description">{t(HelpMessages.descriptionOperationalStatesGoal)}</Typography>
                  <Typography className="description">{t(HelpMessages.descriptionOperationalStatesUtilization)}</Typography>
                  <List>
                    <HelpColorItemList color="#008000" text={t(VehiclesStatesMessages.atThePlant)} />
                    <HelpColorItemList color="#7E73F8" text={t(VehiclesStatesMessages.onTheWay)} />
                    <HelpColorItemList color="#C7B301" text={t(VehiclesStatesMessages.atTheConstructionSite)} />
                    <HelpColorItemList color="#616161" text={t(VehiclesStatesMessages.unloading)} />
                    <HelpColorItemList color="#E64A19" text={t(VehiclesStatesMessages.returning)} />
                    <HelpColorItemList color="#666666" text={t(VehiclesStatesMessages.inTrustedLocation)} />
                  </List>
                </div>
                <Typography className="subtitle">{t(HelpMessages.subtitleSituationalStates)}</Typography>
                <div className="subcontent">
                  <Typography className="description">{t(HelpMessages.descriptionSituationalStatesGoal)}</Typography>
                  <Typography className="description">{t(HelpMessages.descriptionSituationalStatesUtilization)}</Typography>
                  <List>
                    <HelpColorItemList color="#64DD17" text={t(HelpMessages.active)} />
                    <HelpColorItemList color="#D9FF00" text={t(HelpMessages.hasAlerts)} />
                    <HelpColorItemList color="#F00" text={t(HelpMessages.blockedOrImproperDischarge)} />
                    <HelpColorItemList color="#A3A3A3" text={t(HelpMessages.noCommunication)} />
                  </List>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t(HelpMessages.titleIcons)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography className="subtitle">{t(HelpMessages.subtitleAlerts)}</Typography>
                <div className="subcontent">
                  <List>
                    <HelpIconItemList icon={IconAlertBloqueio} text={t(AlertMessages.block)} />
                    <HelpIconItemList icon={IconAlertDescargaIndevida} text={t(AlertMessages.unauthorizedUnloading)} />
                    <HelpIconItemList icon={IconAlertExcessoVelocidade} text={t(AlertMessages.speeding)} />
                    <HelpIconItemList icon={IconAlertParadaIndevida} text={t(AlertMessages.unauthorizedStop)} />
                  </List>
                </div>
                <Typography className="subtitle">{t(HelpMessages.subtitleOperationalStates)}</Typography>
                <div className="subcontent">
                  <List>
                    <HelpIconItemList icon={IconUsina} text={t(VehiclesStatesMessages.atThePlant)} />
                    <HelpIconItemList icon={IconCaminho} text={t(VehiclesStatesMessages.onTheWay)} />
                    <HelpIconItemList icon={IconObra} text={t(VehiclesStatesMessages.atTheConstructionSite)} />
                    <HelpIconItemList icon={IconDescarregando} text={t(VehiclesStatesMessages.unloading)} />
                    <HelpIconItemList icon={IconRetornando} text={t(VehiclesStatesMessages.returning)} />
                    <HelpIconItemList icon={IconLocalConfiavel} text={t(VehiclesStatesMessages.inTrustedLocation)} />
                  </List>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions className="mFooter">
          <Button disableRipple onClick={() => onClose()} color="primary">{t(GlobalMessages.close)}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ModalHelp;
