import { Column, CreateDateColumn, Entity, OneToMany, PrimaryGeneratedColumn, UpdateDateColumn } from "typeorm";
import { Location } from "./reg_locations.entity";
import { Regional } from "./reg_regionals.entity";

@Entity("reg_groups")
export class Group {

  @PrimaryGeneratedColumn("uuid")
    id_group?: string;

  @Column({ type: "varchar", nullable: false, unique: true })
    code: string;

  @Column({ type: "varchar", nullable: false })
    name: string;

  @Column({ type: "boolean", default: true })
    active?: boolean;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @OneToMany(() => Location, (location) => location.regional)
    regionals?: Regional[];

  constructor() {
    this.code = "";
    this.name = "";
  }

  GetLabelName?(): string {
    return `${this.code} - ${this.name}`;
  }

}
