import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";

import { Filter } from "./reg_filters.entity";
import { ReportStatusType } from "../types/report_status_type.enum";
import { getFilterApplicationTypeName } from "../constants/filter-application-types.enum";

@Entity("reg_scheduled_reports")
export class ScheduledReport {

  @PrimaryGeneratedColumn("uuid")
    id_scheduled_report?: string;

  @OneToOne(() => Filter, (filter) => filter.scheduled_report_data, { nullable: false })
  @JoinColumn({ name: "id_filter_fk" })
    filter?: Filter;

  @Column({ type: "varchar", nullable: false, length: 25 })
    language?: string;

  @Column({ type: "varchar", nullable: true, length: 255 })
    s3_file_name?: string;

  @Column({ type: "timestamp", nullable: true })
    url_expiration_date?: Date;

  @Column({ type: "varchar", nullable: true, length: 2048 })
    file_url?: string;

  @Column({ type: "bigint", nullable: true })
    file_size?: number;

  @Column({ type: "enum", enum: ReportStatusType, nullable: false, default: ReportStatusType.IN_QUEUE })
    status?: ReportStatusType;

  @Column({ type: "timestamp", nullable: true })
    processing_date?: Date;

  @Column({ type: "integer", nullable: true })
    processing_time?: number;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  GenerateFileName?(clientName: string) {
    const applicationTypeName = getFilterApplicationTypeName(
      this.filter?.application_type?.id_filter_application_type
    ) || "unknown";

    return `scheduled-reports/${clientName}/${applicationTypeName}/${this.id_scheduled_report}.csv`;
  }

}
