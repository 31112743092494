import styled from "styled-components";
import BlurMap from "../../../assets/blur-map.jpg";

interface StylesheetProps {
  isMobile: boolean;
  isBlocked: boolean;
}

const className = "vehicle-travel-details__dialog";

export const ModalVehicleAndTravelDetailsStylesheet = styled.div.attrs(
  (props) => ({ ...props, className, id: className })
)<StylesheetProps>`
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  .vehicle-travel-details__header {
    display: flex;
    justify-content: space-between;
    ${(props) => (props.isMobile ? "flex-direction: column;" : "")}
    align-items: center;
    flex: 0 0 auto;
    margin: 0;
    padding: ${(props) => (props.isMobile ? "10px" : "16px 24px")};

    .vehicle-travel-details__title {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-size: 1.25rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      margin: 0;
      color: rgba(0, 0, 0, 0.87);

      .icon {
        padding: 10px;
        border-radius: 50%;
        width: ${(props) => (props.isMobile ? "35px" : "50px")};
        height: ${(props) => (props.isMobile ? "35px" : "50px")};

        ${(props) => (props.isMobile ? "display: flex;" : "")}
        ${(props) => (props.isMobile ? "justify-content: center;" : "")}
        .iconBT, .iconBM {
          svg {
            position: relative;
            ${(props) => (props.isMobile ? "bottom: 4px; width: 20px;" : "top: 3px; right: 2px;")}
          }
        }

        .iconPC {
          svg {
            position: relative;
            width: ${(props) => (props.isMobile ? "20px" : "35px")};
            right: ${(props) => (props.isMobile ? "1px" : "3px")};
            ${(props) => (props.isMobile ? "bottom: 6px;" : "top: 1px;")}
          }
        }

        .iconVA {
          svg {
            position: relative;
            width: ${(props) => (props.isMobile ? "20px" : "35px")};
            ${(props) => (props.isMobile ? "bottom: 2px;" : "top: 5px; right: 3px;")}
          }
        }

        .iconCA {
          svg {
            position: relative;
            ${(props) => (props.isMobile ? "bottom: 18px; width: 28px;" : "bottom: 11px; width: 36px; right: 2px;")}
          }
        }

        .iconBlocked {
          svg {
            position: relative;
            ${(props) => (props.isMobile ? "bottom: 11px; width: 25px;" : "bottom: 3px; right: 2px; width: 35px;")}
          }
        }
        .iconMaintenance {

          svg {
            position: relative;
            right: 2px;
            width: 35px;
            bottom: 5px;
          }
        }
      }

      .content {
        padding-left: 15px;

        ${(props) => (props.isMobile ? "" : "font-weight: 200;")}
        .content__title {
          font-size: ${(props) => (props.isMobile ? "1rem" : " 1.25rem")};
        }

        .content__subtitle {
          font-size: ${(props) => (props.isMobile ? "0.8rem" : "1rem")};
          color: #666666;
        }
      }
    }

    .actions {
      display: flex;
      gap: 2rem;
    }

    .block_button {
      color: white;
      background-color: ${(props) => (props.isBlocked ? "#00AD00" : "#F00")};
      border-radius: 8px;
      padding: ${(props) => (props.isMobile ? "0 8px 0 8px" : "")};
    }

    .maintenance_button {
      color: white;
      background-color: #666666;
      border-radius: 8px;
      padding: ${(props) => (props.isMobile ? "0 8px 0 8px" : "")};
    }
    .create_alert_button{
      color: white;
      height: ${(props) => (props.isMobile ? "35px" : "50px")};
      background-color: #00AD00;
      border-radius: 8px;
      padding: ${(props) => (props.isMobile ? "0 8px 0 8px" : "")};
    }
    .show_speed_alert_button{
      color: white;
      height: ${(props) => (props.isMobile ? "35px" : "50px")};
      border-radius: 8px;
      padding: ${(props) => (props.isMobile ? "0 8px 0 8px" : "")};
      width: ${(props) => (props.isMobile ? "" : "250px")};

      .MuiSvgIcon-root {
        width: 0.4em;
        height: 0.4em;
      }
    }
  }

  .vehicle-travel-details__content {
    display: flex;

    .vehicle-travel-details__status .content {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
    }

    .vehicle-travel-details__map {
      margin-top: 10px;
      margin-bottom: 10px;

      .hidden {
        display: none !important;
      }

      .vehicle-travel-details__map-info-content {
        min-width: 120px;

        .block {
          padding: 0 5px;
        }

        .first {
          text-align: center;
          margin-bottom: 15px;

          .icon {

            img {
              width: 20px;
            }
          }
        }

        .second {
          width: 100%;

          .content {
            display: flex;
            justify-content: space-between;

            .title {
              font-weight: 400;
            }

            .text {
              padding-left: 15px;
              text-align: right;
            }
          }
        }
      }
    }

    .vehicle-travel-details__map {

      .vehicle-travel-details__map--static {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        height: 400px;
        -webkit-box-align: center;
        align-items: center;
        background-image: url(${BlurMap});
        background-repeat: no-repeat;
        background-size: cover;

        .vehicle-travel-details__map-icon {
          cursor: pointer;
          width: 40px;
          border-radius: 50%;
          font-weight: bold;
        }
      }
    }

  }

  .vehicle-travel-details__content--desktop {

    .vehicle-travel-details__list {

      .vehicle-travel-details__item-grid {
        height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;

        .vehicle-travel-details__work-header {

          .box {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;

            .title {
              color: black;
              font-weight: 300;
              font-size: 1.5rem;
            }

            .text {
              color: rgb(102, 102, 102);
            }
          }
        }

        .vehicle-travel-details__events {
          margin-bottom: 15px;

          .block {
            margin-bottom: 20px;
          }

          :last-child {
            margin-bottom: 0;
          }

          .MuiAccordion-root {
            width: 100% !important;
            box-shadow: none;

            .subtitle {
              display: flex;
              font-size: 1.0rem;

              .bold {
                font-weight: 900;
              }
            }

            .MuiAccordionSummary-root {
              min-height: 25px;
              height: 25px;
              padding: 0;

              .MuiTypography-root {
                width: 100%;
                justify-content: space-between;
                display: flex;
                align-items: center;

                .title {
                  color: black;
                  font-weight: 300;
                  font-size: 1.5rem;
                }
              }
            }

            .MuiAccordionDetails-root {
              padding: 20px 0 0 0;

              .MuiTypography-root {
                width: 100%;

                .MuiDivider-root {
                  display: none;
                }

                .MuiAccordionSummary-root {
                  min-height: auto;
                  height: auto;

                  .MuiAccordionSummary-content {
                    margin: 0;

                    .content {
                      padding: 0;
                    }
                  }
                }

                .date {
                  align-items: center;
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }

      .vehicle-travel-details__item-grid--alerts {
        display: grid;
        grid-template-columns: 300px 300px;
      }
    }
  }

  .vehicle-travel-details__content--mobile {
    padding: 15px;
    overflow-x: hidden;

    .MuiPaper-root {
      max-width: 100%;
    }

    .MuiDialogContentText-root {
      width: 100%;
    }

    .vehicle-travel-details__work-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .content {
        padding-left: 15px;

        .title {
          font-weight: 500;
          color: black;
          text-transform: uppercase;
        }

        .subtitle {
          font-size: 1rem;
          color: #666666;
        }
      }
    }

    .events {
      margin-top: 15px;

      .MuiAccordion-root {
        margin: 0;
        box-shadow: none;

        .water-addition {
          min-height: unset;

          .MuiAccordionSummary-content.Mui-expanded {
            margin: 0;
          }
          .MuiIconButton-root {
            padding: 0 12px;
          }
        }

        .subtitle {
          padding-left: 8px;
          display: flex;
          font-size: 1.0rem;

          .bold {
            font-weight: 900;
          }
        }

        .MuiAccordionSummary-root {
          padding: 0 8px;

          .MuiAccordionSummary-content {

            .content {
              padding-bottom: 0;
            }

            .MuiTypography-root {
              font-weight: 500;
              font-size: 1.4rem;
              display: flex;
              width: 100%;
              justify-content: space-between;
            }
          }
        }

        .MuiCollapse-container {

          .MuiAccordionDetails-root {
            padding: 15px 10px 0 10px;

            .MuiTypography-root {
              width: 100%;

              .date {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                margin-bottom: 20px;
                font-size: 1.2rem;

                .title {
                  color: red;
                  padding-right: 10px;
                }
              }
            }

            .boxHeaderItems {
              padding: 0;

              .MuiAccordionSummary-content {

                .content {
                  padding: 0;
                }
              }
            }

            .hidden {
              display: none !important;
            }

            .staticMap {
              display: flex;
              justify-content: center;
              height: 250px;
              align-items: center;
              background-image: url(${BlurMap});
              background-repeat: no-repeat;
              background-size: cover;

              img {
                cursor: pointer;
                width: 40px;
                border-radius: 50%;
                font-weight: bold;
                background-color: white;
                /* box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%); */
              }
            }

            .drawerMap {
              display: flex;
              justify-content: center;
              height: 250px;
              align-items: center;
              background-image: url(${BlurMap});
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }

    .current-load {

      .current-load-information__icon {
        width: 30px;
        height: 30px;
      }

    }

    .water-additions {
      .MuiAccordion-root {
        margin: 0;
        box-shadow: none;

        .MuiAccordionSummary-root.Mui-expanded {
          min-height: unset;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin-bottom: 0;
        }

        .MuiIconButton-root {
          padding: 0 12px;
        }

        .subtitle {
          padding-left: 8px;
          display: flex;
          font-size: 1.0rem;
          margin-bottom: 10px;
          line-height: 0.8rem;

          .bold {
            font-weight: 900;
          }
        }

        .MuiAccordionSummary-root {
          padding: 0 8px;

          .MuiAccordionSummary-content {

            .content {
              padding-bottom: 0;
            }

            .MuiTypography-root {
              font-weight: 500;
              font-size: 1.4rem;
              display: flex;
              width: 100%;
              justify-content: space-between;
            }
          }
        }

        .MuiCollapse-container {

          .MuiAccordionDetails-root {
            padding: 15px 10px 0 10px;

            .MuiTypography-root {
              width: 100%;

              .date {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                margin-bottom: 20px;
                font-size: 1.2rem;

                .title {
                  color: red;
                  padding-right: 10px;
                }
              }
            }

            .boxHeaderItems {
              padding: 0;

              .MuiAccordionSummary-content {

                .content {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    .states {

      .MuiAccordion-root {
        margin: 0;
        box-shadow: none;

        .MuiAccordionSummary-root {
          padding: 0 8px;

          .MuiAccordionSummary-content {

            .MuiTypography-root {
              font-weight: 500;
              font-size: 1.4rem;
            }
          }
        }

        .MuiCollapse-container {

          .MuiAccordionDetails-root {
            padding: 5px 10px;

            .MuiTypography-root {
              width: 100%;

              .date {
                display: flex;
                justify-content: space-between;
                font-weight: 300;
                margin-bottom: 20px;
                font-size: 1.2rem;

                .title {
                  color: red;
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .vehicle-travel-details__map {
      margin-top: 0;

      .MuiAccordion-root {
        margin: 0;
        box-shadow: none;

        .MuiAccordionSummary-root {
          padding: 0 8px;

          .MuiAccordionSummary-content {

            .MuiTypography-root {
              font-weight: 500;
              font-size: 1.4rem;
            }
          }
        }

        .MuiAccordionDetails-root {

          .MuiTypography-root {
            width: 100%;
          }
        }
      }
    }
  }

  .current-load-information {
    display: flex;
    align-content: space-between;
    align-items: center;
    padding: 0 0 15px 0;

    &__icon {
      svg {
        width: 37px;
        height: 37px;
      }
    }

    &__details {
      width: 100%;
      padding-left: 15px;

      &--title {
        font-size: 0.9rem;
        font-weight: bold;
      }

      &--amount {
        display: flex;
        align-content: space-between;
        align-items: center;

        p {
          font-weight: bold;
        }
      }

      &--observation {
        font-size: 0.8rem
      }
    }
  }

  .vehicle-travel-details__footer {
    justify-content: flex-end;
  }


  .blue-background-badge {
    .MuiBadge-badge {
      background-color: #3D9AFF;
    }
  }
`;
