import styled from "styled-components";

export const Controls = styled.div`
  display: inline-flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 500px;
  padding: 0.2rem;
  margin: auto;
  overflow: hidden;
  position: relative;

  &.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }
`;

export const SegmentDiv = styled.div`
  min-width: 120px;
  position: relative;
  text-align: center;
  border-radius: 15px;

  &.active {
    color: #fff;
    background: ${(props) => props.theme.colors.primary};
    background-size: 50%;
  }

  label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    padding: 0.4rem;
    transition: color 0.5s ease;
  }

  input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
  }
`;
