import styled from "styled-components";

export const Container = styled.div`

  .content {
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid rgb(221 221 221 / 60%);

    .boxIcon {

      svg {
        width: 25px;
        height: 25px;
      }
      .disconnection {

        svg {
          fill: red;
        }
      }
      .connection {

        svg {
          fill: green;
        }
      }
    }
    .boxDescription {
      padding-left: 15px;
      width: 100%;

      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .alertC-title {
        font-size: 1.15rem;

        .txt1 {
          display: flex;
          align-items: center;

          .iconVehicleType {

            div:first-child {
              border-radius: 50%;
              height: 23px;
              width: 23px;
            }
            svg {
              width: 20px;
              fill: white;
            }
            .iconBT, .iconBM {

              svg {
                position: relative;
                left: 3.5px;
                width: 16px;
              }
            }
            .iconPC {

              svg {
                position: relative;
                left: 2px;
                bottom: 2px;
                width: 18px;
              }
            }
            .iconVA {

              svg {
                position: relative;
                left: 2px;
                top: 2px;
                width: 18px;
              }
            }
            .iconCA {

              svg {
                position: relative;
                left: 0.25rem;
                bottom: 0.95rem;
                width: 1.1rem;
              }
            }
          }

          .iconState {

            div:first-child {
              border-radius: 50%;
              height: 23px;
              width: 23px;
            }

            svg {
              width: 20px;
              position: relative;
              bottom: 9px;
            }
          }

          .desc {
            padding-left: 5px;
          }
        }

        .txt2 {
          display: flex;
          font-size: 0.9rem;
          color: #FF0000;
          font-weight: 500;
        }
      }
      .alertC-subtitle {
        color: rgb(0 0 0 / 60%);
      }
    }
    &:hover {
      background-color: rgb(221 221 221 / 40%);
      cursor: pointer;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;
