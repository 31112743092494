import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import * as Styled from "./styles";

interface KanbanAccordionCardProps {
  accordionSummary: React.ReactNode;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  showExpandIcon?: boolean;
  expanded: boolean;
}

const KanbanAccordionCard: React.FC<KanbanAccordionCardProps> = (
  { accordionSummary, children, onChange, showExpandIcon, expanded }
) => {

  // region Handlers
  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    onChange && onChange(event, isExpanded);
  };
  // endregion Handlers

  return (
    <Styled.Container showExpandIcon={showExpandIcon}>
      <Accordion
        className="accordionComponent"
        onChange={handleAccordionChange}
        expanded={expanded}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          expandIcon={showExpandIcon && <ExpandMore />}
          id="panel1a-header"
        >
          {accordionSummary}
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </Styled.Container>
  );
};

KanbanAccordionCard.defaultProps = {
  showExpandIcon: false,
  onChange: () => { /** empty */ }
};

export default KanbanAccordionCard;
