import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Travel } from "./reg_travels.entity";

@Entity("reg_water_additions")
export class WaterAddition {

  @PrimaryGeneratedColumn("uuid")
    id_water_addition?: string;

  @Column({
    type: "numeric",
    nullable: false,
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    water_added?: number;

  @Column({
    type: "numeric",
    nullable: false,
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_total_water_added?: number;

  @Column({ type: "timestamp", default: "(now() at time zone 'utc')" })
    start_date?: Date;

  @Column({ type: "timestamp", nullable: true })
    finish_date?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => Travel, (travel) => travel.water_additions, { nullable: true })
  @JoinColumn({ name: "id_travel_fk" })
    travel?: Travel;

}
