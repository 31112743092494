import { CoordinateAnalysisMessages, TranslationCoordinateAnalysisMessages } from "../interfaces";

export const messages: TranslationCoordinateAnalysisMessages = {
  [CoordinateAnalysisMessages.command]: "Comando",
  [CoordinateAnalysisMessages.identifier]: "Identificador",
  [CoordinateAnalysisMessages.model]: "Modelo",
  [CoordinateAnalysisMessages.softwareVersion]: "Versão do software",
  [CoordinateAnalysisMessages.date]: "Data",
  [CoordinateAnalysisMessages.time]: "Hora",
  [CoordinateAnalysisMessages.locationCode]: "Código de localização",
  [CoordinateAnalysisMessages.latitude]: "Latitude",
  [CoordinateAnalysisMessages.longitude]: "Longitude",
  [CoordinateAnalysisMessages.speed]: "Velocidade",
  [CoordinateAnalysisMessages.ignition]: "Ignição",
  [CoordinateAnalysisMessages.inclination]: "Inclinação",
  [CoordinateAnalysisMessages.activeSatellites]: "Satélites ativos",
  [CoordinateAnalysisMessages.validPosition]: "Posicionamento válido",
  [CoordinateAnalysisMessages.odometer]: "Odômetro",
  [CoordinateAnalysisMessages.batteryVoltage]: "Tensão da bateria",
  [CoordinateAnalysisMessages.inAndOutInputs]: "Inputs de entrada e saída",
  [CoordinateAnalysisMessages.activeMode]: "Modo ativo",
  [CoordinateAnalysisMessages.numberOfMessages]: "Número de mensagens",
  [CoordinateAnalysisMessages.hourMeter]: "Horímetro",
  [CoordinateAnalysisMessages.backupBatteryVoltage]: "Tensão da bateria de backup",
  [CoordinateAnalysisMessages.messageType]: "Tipo de mensagem",
  [CoordinateAnalysisMessages.mixerRPM]: "RPM do balão",
  [CoordinateAnalysisMessages.mixerBatteryVoltage]: "Tensão da bateria do sensor de balão",
  [CoordinateAnalysisMessages.driverIdentifier]: "Identificador do motorista",
  [CoordinateAnalysisMessages.registrationStatus]: "Status de registro",
  [CoordinateAnalysisMessages.serviceIdentification]: "Identificação de serviço",
  [CoordinateAnalysisMessages.countryCode]: "Código do país",
  [CoordinateAnalysisMessages.networkCode]: "Código de rede",
  [CoordinateAnalysisMessages.receptionLevel]: "Nível de recepção",
  [CoordinateAnalysisMessages.locationAreaCode]: "Código de localização",
  [CoordinateAnalysisMessages.temporalInfo]: "Informação temporal",
  [CoordinateAnalysisMessages.GPSStatus]: "GPS ligado/desligado",
  [CoordinateAnalysisMessages.vehicleIdentifier]: "Identificador do veículo",
  [CoordinateAnalysisMessages.ignitionKey]: "Chave de ignição",
  [CoordinateAnalysisMessages.CANOdometer]: "Odômetro CAN",
  [CoordinateAnalysisMessages.totalFuelConsumption]: "Consumo total de combustível (litros)",
  [CoordinateAnalysisMessages.CANSpeed]: "Velocidade CAN",
  [CoordinateAnalysisMessages.engineRPM]: "RPM do motor",
  [CoordinateAnalysisMessages.engineCoolantTemperature]: "Temperatura do líquido de arrefecimento do motor",
  [CoordinateAnalysisMessages.fuelConsumption]: "Consumo de combustível",
  [CoordinateAnalysisMessages.fuelLevel]: "Nível de combustível",
  [CoordinateAnalysisMessages.CANHourMeter]: "Horímetro CAN",
  [CoordinateAnalysisMessages.drivingTime]: "Tempo de condução",
  [CoordinateAnalysisMessages.fuelConsumptionInIdle]: "Consumo de combustível em marcha lenta (litros)",
  [CoordinateAnalysisMessages.vehicleWeight]: "Peso bruto do veículo",
  [CoordinateAnalysisMessages.sensorStatus]: "Status do sensor",
  [CoordinateAnalysisMessages.drumInclination]: "Inclinação do balão",
  [CoordinateAnalysisMessages.totalWaterAdded]: "Total de água adicionada",
  [CoordinateAnalysisMessages.drumPressure]: "Pressão do balão",
  [CoordinateAnalysisMessages.drumTemperature]: "Temperatura do balão",
  [CoordinateAnalysisMessages.ignitionOn]: "Ligada",
  [CoordinateAnalysisMessages.ignitionOff]: "Desligada",

  [CoordinateAnalysisMessages.coordinateAnalysisTitle]: "Análise de coordenadas",
  [CoordinateAnalysisMessages.mapTitle]: "Mapa",
  [CoordinateAnalysisMessages.dataTableTitle]: "Tabela",

  [CoordinateAnalysisMessages.alerts]: "Alertas",
  [CoordinateAnalysisMessages.states]: "Estados",
  [CoordinateAnalysisMessages.noAlertsForThisPeriod]: "Não há alertas para este período",
  [CoordinateAnalysisMessages.commands]: "Comandos",
  [CoordinateAnalysisMessages.noCommandsForThisPeriod]: "Não há comandos para este período",
  [CoordinateAnalysisMessages.noStatesForThisPeriod]: "Não há estados para este período",
  [CoordinateAnalysisMessages.showMarker]: "Destacar marcador",
  [CoordinateAnalysisMessages.travel]: "Viagem",
  [CoordinateAnalysisMessages.travelHelperText]: "Selecione um veículo e um período inferior a 3 dias",
  [CoordinateAnalysisMessages.startDate]: "Data inicial",
  [CoordinateAnalysisMessages.finishDate]: "Data final",
  [CoordinateAnalysisMessages.loadingContent]: "Analisando rotas..."
};
