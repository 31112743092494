/* eslint-disable camelcase */
import { Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, OneToMany } from "typeorm";

import { Event } from "./reg_events.entity";

@Entity("tpe_events_types")
export class TpeEvent {

  @PrimaryGeneratedColumn("uuid")
  id_event_type?: string;

  @Column({ type: "varchar", nullable: false, length: 100 })
  description?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
  registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
  updated_date?: Date;

  @OneToMany(() => Event, (events) => events.type)
  events?: Event[];

}
