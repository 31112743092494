import styled from "styled-components";
import { styled as MUIStyled, Switch } from "@material-ui/core";

export const ContainerToggleSwitch = styled.div`
  display: flex;
`;

export const ToggleSwitch = MUIStyled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#002951FF"
  },

  "& .MuiSwitch-track": {
    backgroundColor: "#AAA"
  },

  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#002951FF"
  }
}));
