/* eslint-disable import/prefer-default-export */
import { TravelDataMessages, TranslationTravelDataMessages } from "../interfaces";

export const messages: TranslationTravelDataMessages = {
  [TravelDataMessages.origin]: "Origin",
  [TravelDataMessages.destination]: "Destination",
  [TravelDataMessages.finalLocation]: "Final location",
  [TravelDataMessages.taxInvoice]: "Tax invoice",
  [TravelDataMessages.sealNumber]: "Seal number",
  [TravelDataMessages.invoiceIntegrationDate]: "Invoice integration date",
  [TravelDataMessages.travelStartDate]: "Travel start date",
  [TravelDataMessages.invoiceIssueDate]: "Invoice issue date",
  [TravelDataMessages.pieceToBeConcreted]: "Piece to be concreted",
  [TravelDataMessages.loadVolume]: "Load volume",
  [TravelDataMessages.loadExpirationDate]: "Load expiration date",
  [TravelDataMessages.trace]: "Trace (Referring to the load)",
  [TravelDataMessages.application]: "Application",
  [TravelDataMessages.slump]: "Slump",
  [TravelDataMessages.resistance]: "Resistance",
  [TravelDataMessages.resistanceType]: "Resistance (Type)",
  [TravelDataMessages.resistanceComplement]: "Resistance (Complement)",
  [TravelDataMessages.gravel]: "Gravel"
};
