export enum HardwareTypes {
  RASTREADOR = "Rastreador",
  SENSOR_DE_BALAO = "Sensor de Balão",
  IBUTTON = "Ibutton"
}

export enum HardwareTypesID {
  RASTREADOR = "633d9675-1bde-4fdb-be9c-51c0e5d6d068",
  SENSOR_DE_BALAO = "f80c0188-55db-4feb-8033-4a43772cf5b0",
  IBUTTON = "267ff36e-1cc4-4533-89e6-8505b0932013"
}
