import React, { HTMLAttributes } from "react";

import * as Styled from "./styles";

type SpanProps = HTMLAttributes<HTMLSpanElement>;

const Span: React.FC<SpanProps> = ({ children, className, ...rest }) => {

  /**
   * On mouse over starts auto-scrolling if text is larger than element size
   * @param event - The event object
   */
  const handleMouseOver = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const element = event.currentTarget;

    const parentElement = element?.parentElement;

    if (!parentElement) throw new Error("span must have a parent.");

    const speedOfTransition = 20;

    const hasScroll = element.scrollWidth - parentElement.clientWidth > 1;

    if (hasScroll) {
      element.style.transition = `all ${(element.scrollWidth - parentElement.clientWidth) / speedOfTransition}s`;
      element.classList.remove("ellipsis");
      element.style.transform = `translateX(${parentElement.clientWidth - element.scrollWidth}px)`;
    }
  };

  /**
   * On mouse out stops auto-scrolling
   * @param event - The event object
   */
  const handleMouseOut = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const element = event.currentTarget;

    const parentElement = element?.parentElement;

    if (!parentElement) throw new Error("span must have a parent.");

    if (element.scrollWidth > parentElement.clientWidth) {
      element.style.transition = "all 0.3s";
      element.style.transform = "translateX(0px)";
      element.classList.add("ellipsis");
    }
  };

  return (
    <Styled.Container
      {...rest}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={`ellipsis ${className ?? ""}`}
    >
      {children}
    </Styled.Container>
  );
};

export default Span;
