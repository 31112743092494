import React, { useState } from "react";

// region Libraries
import * as dateFns from "date-fns";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages, GlobalMessages, VehiclesHistoryMessages
} from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { AnalyticVehicleHistory, SyntheticVehicleHistory, VehicleHistory } from "@shared/interfaces/vehicles-history.interface";
// endregion Interfaces
// region Entities
// endregion Entities
// region Utils
import utils from "@utils/useful-functions";
import { formatHourMeter, formatOdometer } from "@shared/utils/useful-functions";
// endregion Utils
// region Components
import Header from "@components/Header";
import NewDataTable, { DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import QueryFilterVehicleHistory from "@organisms/QueryFilterVehicleHistory";
// endregion Components
// region Styles
import { useSelector } from "react-redux";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { Container } from "./styles";
// endregion Styles

const VehiclesHistory: React.FC = () => {

  /* region States */
  const [vehicleHistoryData, setVehicleHistoryData] = useState<VehicleHistory>(
    { analyticData: [], syntheticData: [] }
  );

  const [openFilterFields, setOpenFilterFields] = useState(false);
  const [numberOfFilteredOptions, setNumberOfFilteredOptions] = useState(0);

  // Used to create export file name
  const [startDate, setStartDate] = useState<string>(
    dateFns.format(dateFns.subDays(new Date(), 1), "yyyy-MM-dd").concat("T00:00")
  );
  const [finishDate, setFinishDate] = useState<string>(dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59"));

  /* endregion States */
  /* region Hooks */
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  /* endregion Hooks */
  /* region Constants */
  // General DataTable
  const generalDataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const generalDataTableColumns: DataTableColumns[] = [
    { // Dado
      title: t(VehiclesHistoryMessages.data),
      data: (data: SyntheticVehicleHistory) => (data.dataType),
      filterable: false
    },
    { // Equipamento
      title: t(VehiclesHistoryMessages.equipment),
      data: (data: SyntheticVehicleHistory) => (data.vehicleType),
      filterable: false
    },
    { // Viagens
      title: t(VehiclesHistoryMessages.travels),
      data: (data: SyntheticVehicleHistory) => {
        if (data.dataType === "Total") return data.travelsCount;

        return data.travelsCount.toFixed(2).toString();
      },
      filterable: false
    },
    { // Duração
      title: t(GlobalMessages.duration),
      data: (data: SyntheticVehicleHistory) => (formatHourMeter(data.durationInMinutes)),
      filterable: false
    },
    { // Tempo ligado
      title: t(VehiclesHistoryMessages.engineRuntime),
      data: (data: SyntheticVehicleHistory) => (formatHourMeter(data.durationIgnitionOnInMinutes)),
      filterable: false
    },
    { // Km rodados
      title: t(VehiclesHistoryMessages.kmTraveled),
      data: (data: SyntheticVehicleHistory) => formatOdometer(data.distance),
      filterable: false
    },
    { // Volume de produção
      title: t(VehiclesHistoryMessages.productionVolume),
      data: (data: SyntheticVehicleHistory) => {
        if (data.dataType === "Total") return data.volume;

        return data.volume.toFixed(2).toString();
      },
      filterable: false
    },
    { // Volume por viagem
      title: t(VehiclesHistoryMessages.volumePerTrip),
      data: (data: SyntheticVehicleHistory) => (data.averageVolume?.toFixed(2).toString() || ""),
      filterable: false
    },
    { // Duração por viagem
      title: t(VehiclesHistoryMessages.durationPerTrip),
      data: (data: SyntheticVehicleHistory) => formatHourMeter(data.averageDuration),
      filterable: false
    },
    { // Horas por viagem
      title: t(VehiclesHistoryMessages.engineRuntimePerTrip),
      data: (data: SyntheticVehicleHistory) => formatHourMeter(data.averageDurationIgnitionOn),
      filterable: false
    },
    { // Km por viagem
      title: t(VehiclesHistoryMessages.kmTraveledPerTrip),
      data: (data: SyntheticVehicleHistory) => (
        data.averageDistance || data.averageDistance === 0
          ? formatOdometer(data.averageDistance)
          : ""
      ),
      filterable: false
    },
    { // Excessos de velocidade
      title: t(VehiclesHistoryMessages.speedingAlerts),
      data: (data: SyntheticVehicleHistory) => {
        if (data.dataType === "Total") return data.speedAlertsCount;

        return data.speedAlertsCount.toFixed(2).toString();
      },
      filterable: false
    },
    { // Descargas indevidas
      title: t(VehiclesHistoryMessages.improperDischargeAlerts),
      data: (data: SyntheticVehicleHistory) => {
        if (data.dataType === "Total") return data.improperDischargeAlertsCount;

        return data.improperDischargeAlertsCount.toFixed(2).toString();
      },
      filterable: false
    },
    { // Paradas indevidas
      title: t(VehiclesHistoryMessages.improperStopAlerts),
      data: (data: SyntheticVehicleHistory) => {
        if (data.dataType === "Total") return data.improperStopsAlertsCount;

        return data.improperStopsAlertsCount.toFixed(2).toString();
      },
      filterable: false
    }
  ];

  // Equipment DataTable
  const equipmentDataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const equipmentDataTableColumns: DataTableColumns[] = [
    { // Equipamento
      title: t(VehiclesHistoryMessages.equipment),
      data: (data: AnalyticVehicleHistory) => (data.vehicleName),
      defaultContent: "",
      filterable: true,
      propertyName: "vehicleName"
    },
    { // Placa
      title: t(VehiclesHistoryMessages.licensePlate),
      data: (data: AnalyticVehicleHistory) => (data.licensePlate),
      filterable: true,
      propertyName: "licensePlate"
    },
    { // Viagens
      title: t(VehiclesHistoryMessages.travels),
      data: (data: AnalyticVehicleHistory) => (data.travelsCount),
      filterable: true,
      propertyName: "travelsCount"
    },
    { // Duração
      title: t(GlobalMessages.duration),
      data: (data: AnalyticVehicleHistory) => formatHourMeter(data.durationInMinutes),
      filterable: true,
      propertyName: "durationInMinutes",
      filterTitle: "Duração (em minutos)"
    },
    { // Tempo ligado
      title: t(VehiclesHistoryMessages.engineRuntime),
      data: (data: AnalyticVehicleHistory) => formatHourMeter(data.durationIgnitionOnInMinutes),
      filterable: true,
      propertyName: "durationIgnitionOnInMinutes",
      filterTitle: t(VehiclesHistoryMessages.engineRuntimeInMinutes)
    },
    { // Km rodados
      title: t(VehiclesHistoryMessages.kmTraveled),
      data: (data: AnalyticVehicleHistory) => formatOdometer(data.distance),
      filterable: true,
      propertyName: "distance",
      filterTitle: t(VehiclesHistoryMessages.distanceInKilometers),
      toFilterValue: (data: AnalyticVehicleHistory) => data.distance / 1000
    },
    { // Volume de produção
      title: t(VehiclesHistoryMessages.productionVolume),
      data: (data: AnalyticVehicleHistory) => data.volume,
      filterable: true,
      propertyName: "volume"
    },
    { // Volume por viagem
      title: t(VehiclesHistoryMessages.volumePerTrip),
      data: (data: AnalyticVehicleHistory) => data.averageVolume!.toFixed(2).toString(),
      filterable: true,
      propertyName: "averageVolume"
    },
    { // Duração por viagem
      title: t(VehiclesHistoryMessages.durationPerTrip),
      data: (data: AnalyticVehicleHistory) => formatHourMeter(data.averageDuration),
      filterable: true,
      propertyName: "averageDuration",
      filterTitle: t(VehiclesHistoryMessages.durationPerTripInMinutes)
    },
    { // Tempo ligado por viagem
      title: t(VehiclesHistoryMessages.engineRuntimePerTrip),
      data: (data: AnalyticVehicleHistory) => formatHourMeter(data.averageDurationIgnitionOn),
      filterable: true,
      propertyName: "averageDurationIgnitionOn",
      filterTitle: t(VehiclesHistoryMessages.engineRuntimePerTripInMinutes)
    },
    { // Km por viagem
      title: t(VehiclesHistoryMessages.kmTraveledPerTrip),
      data: (data: AnalyticVehicleHistory) => formatOdometer(data.averageDistance!),
      filterable: true,
      propertyName: "averageDistance",
      filterTitle: t(VehiclesHistoryMessages.distancePerTripInKilometers),
      toFilterValue: (data: AnalyticVehicleHistory) => data.averageDistance! / 1000
    },
    { // Excessos de velocidade
      title: t(VehiclesHistoryMessages.speedingAlerts),
      data: (data: AnalyticVehicleHistory) => data.speedAlertsCount,
      filterable: true,
      propertyName: "speedAlertsCount"
    },
    { // Descargas indevidas
      title: t(VehiclesHistoryMessages.improperDischargeAlerts),
      data: (data: AnalyticVehicleHistory) => data.improperDischargeAlertsCount,
      filterable: true,
      propertyName: "improperDischargeAlertsCount"
    },
    { // Paradas indevidas
      title: t(VehiclesHistoryMessages.improperStopAlerts),
      data: (data: AnalyticVehicleHistory) => data.improperStopsAlertsCount,
      filterable: true,
      propertyName: "improperStopsAlertsCount"
    }
  ];
  const equipmentDataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_equipamentos_${startDate}__${finishDate}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];
  /* endregion Constants */

  /* region Functions */
  const handleApplyFilter = (
    numberOfFilteredOptions: number,
    returnedData: VehicleHistory,
    startDate: string,
    finishDate: string
  ) => {
    setNumberOfFilteredOptions(numberOfFilteredOptions);

    setVehicleHistoryData(returnedData);

    setStartDate(startDate);
    setFinishDate(finishDate);
  };
  /* endregion Functions */

  return (
    <>
      <Header title={t(VehiclesHistoryMessages.title)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <div className="aggregated-data">
          <h2 className="dataTable-title">{t(VehiclesHistoryMessages.generalHistory)}</h2>
          <NewDataTable
            data={vehicleHistoryData.syntheticData}
            columns={generalDataTableColumns}
            settings={generalDataTableSettings}
            showPaging={false}
            showInfo={false}
            filters
            onClickFilterButton={() => setOpenFilterFields(true)}
            numberOfFilteredOptions={numberOfFilteredOptions}
          />
        </div>
        <div className="equipment-data">
          <h2 className="dataTable-title">{t(VehiclesHistoryMessages.historyByEquipment)}</h2>
          {
            <NewDataTable
              title="histórico por equipamento"
              data={vehicleHistoryData.analyticData}
              columns={equipmentDataTableColumns}
              settings={equipmentDataTableSettings}
              buttons={equipmentDataTableButtons}
            />

          }
        </div>
        <QueryFilterVehicleHistory
          open={openFilterFields}
          onClose={() => setOpenFilterFields(false)}
          onFilter={handleApplyFilter}
        />
      </Container>
    </>
  );
};

export default VehiclesHistory;
