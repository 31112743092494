/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */

import React, { useCallback, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useDispatch, useSelector } from "react-redux";

// region Libraries
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, ToastMessages, VehicleMaintenanceMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
// endregion Hooks
// region Shared
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { ScreenSize } from "@store/ducks/Screen/screen.type";
// endregion Shared
// region Services
import api from "@services/api";
// region Components
import TimeLineVehiclesItem from "@components/TimeLines/TimeLineVehicles/TimeLineVehiclesItem";
import VehicleMaintenanceHeader from "@components/Modal/VehicleMaintenance/VehicleMaintenanceHeader";
import VehicleMaintenanceForm from "@components/Modal/VehicleMaintenance/VehicleMaintenanceForm";
// endregion Components
// region Store
import { VehiclesStatesActions } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.action";
import { VehicleStatesList } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
// endregion Store
// region Styles
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
import * as Styled from "./styles";
// endregion Styles

type ModalVehicleMaintenanceProps = {
  vehicle: Vehicle;
  onClose: () => void;
  open: boolean;
  isInMaintenance: boolean;
}

interface IVehicleChangeMaintenanceRequestData {
  id_vehicle: string;
  user_mail: string;
  maintenance: boolean;
  justification?: string;
}

const ModalVehicleMaintenance: React.FC<ModalVehicleMaintenanceProps> = ({
  vehicle,
  onClose,
  open,
  isInMaintenance
}) => {

  // region Hooks
  const { addToast } = useToast();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const { t } = useTranslation();
  // endregion Hooks
  // region States
  const [openDialogConfirmMaintenance, setOpenDialogConfirmMaintenance] = useState(isInMaintenance);
  const [loading, setLoading] = useState(false);
  // endregion States
  // region Screen Control
  const screen = useSelector(({ screen: state }) => state);
  const isMobile = screen.size !== ScreenSize.large;
  // endregion Screen Control
  // region Functions

  // Handlers
  const handleClose = useCallback(() => { onClose(); }, [onClose]);

  /**
   * Update the global state of vehicles, according to the vehicle maintenance status
   */
  const updateState = useCallback((vehicle: Vehicle) => {

    // Clone the existing vehiclesList to make changes
    const updatedVehiclesList: VehicleStatesList = { ...vehiclesStates.all.list };

    // Get the current state description of the vehicle
    const currentStatusDescription = vehicle?.states[vehicle?.states.length - 1]?.status.description;

    // If the action is to remove the vehicle from maintenance
    if (isInMaintenance) {

      // Remove vehicle from the "Veiculos em manutencao" list
      updatedVehiclesList["Veiculos em manutencao"] = updatedVehiclesList["Veiculos em manutencao"].filter(
        (vehicleItem) => vehicleItem.id_vehicle !== vehicle.id_vehicle
      );

      // Add the vehicle to the list corresponding to its status description, updating its maintenance status
      updatedVehiclesList[currentStatusDescription] = [
        ...(updatedVehiclesList[currentStatusDescription] || []),
        {
          ...vehicle,
          maintenance: false
        }];

    } else {

      // Find the vehicle in the list corresponding to its status description and remove it
      updatedVehiclesList[currentStatusDescription] = (updatedVehiclesList[currentStatusDescription] || []).filter(
        (vehicleItem) => vehicleItem?.id_vehicle !== vehicle.id_vehicle
      );

      // Add the vehicle to the "Veiculos em manutencao" list, updating its maintenance status
      updatedVehiclesList["Veiculos em manutencao"] = [
        ...(updatedVehiclesList["Veiculos em manutencao"] || []),
        {
          ...vehicle,
          maintenance: true
        }
      ];
    }

    // Update the global state
    dispatch(VehiclesStatesActions.setVehiclesStatesAll({
      list: updatedVehiclesList,
      loading: false
    }));
  }, [dispatch, isInMaintenance, vehiclesStates.all.list]);

  /**
   * Change vehicle maintenance status
   * @param justification Justification to put vehicle in maintenance
   */
  const handleChangeVehicleMaintenance = useCallback(async (justification?: string) => {

    try {

      setLoading(true);

      const requestBodyData: IVehicleChangeMaintenanceRequestData = {
        id_vehicle: vehicle.id_vehicle,
        user_mail: user.email as string,
        maintenance: !isInMaintenance,
        justification
      };

      const { data } = await api.post("/vehicles/put-vehicle-in-maintenance", requestBodyData);

      if (data.status === "success") {

        updateState(vehicle);

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        onClose();

      } else addToast({ type: "error", title: t(ToastMessages.error), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoading(false);
    }

  }, [addToast, t, onClose, updateState, user.email, vehicle, isInMaintenance]);

  // endregion Functions

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
    >
      <Loading loading={loading} />
      {!isInMaintenance ? (
        <Styled.ModalVehicleMaintenance isMobile={isMobile}>
          <VehicleMaintenanceHeader />
          <DialogContent dividers>
            <Grid container spacing={3} className="vehicle-maintenance-dialog__list">
              <Grid item xs={6} md={6} lg={6} className="vehicle-maintenance-dialog__item-grid">
                <div className="maintenance">
                  <div className="vehicle-maintenance-dialog__instruction">
                    <strong className="strong">1</strong> {t(VehicleMaintenanceMessages.checkVehicle)}
                  </div>
                  <TimeLineVehiclesItem vehicles={[vehicle]} />
                </div>
              </Grid>
              <Grid item xs={6} md={6} lg={6} className="vehicle-maintenance-dialog__item-grid">
                <div className="maintenance">
                  <VehicleMaintenanceForm handleChangeVehicleMaintenance={handleChangeVehicleMaintenance} />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="vehicle-maintenance-dialog__footer">
            <Button disableRipple onClick={() => handleClose()} color="primary">
              {t(GlobalMessages.close)}
            </Button>
          </DialogActions>
        </Styled.ModalVehicleMaintenance>
      ) : (
        <DialogConfirmAction
          open={open && openDialogConfirmMaintenance}
          onClose={() => onClose()}
          title={t(VehicleMaintenanceMessages.removeFromMaintenance)}
          actions={[
            { text: t(GlobalMessages.no).toUpperCase(),
              action: () => onClose()
            },
            {
              text: t(GlobalMessages.yes).toUpperCase(),
              action: async () => {
                setOpenDialogConfirmMaintenance(false);
                await handleChangeVehicleMaintenance();
              }
            }
          ]}
        >
          {t(VehicleMaintenanceMessages.removeFromMaintenanceConfirmation)}
        </DialogConfirmAction>
      )}
    </Dialog>
  );
};

export default ModalVehicleMaintenance;
