import { ParamsMessages, TranslationParamsMessages } from "../interfaces";

export const messages: TranslationParamsMessages = {
  [ParamsMessages.readParamsSuccess]: "Parámetro(s) devuelto(s) con éxito",
  [ParamsMessages.noParamsFound]: "Ningún parámetro registrado",
  [ParamsMessages.readParamsError]: "Falla al buscar parámetro(s)",
  [ParamsMessages.updateParamsSuccess]: "Parámetro(s) actualizado(s) con éxito",
  [ParamsMessages.updateParamsError]: "Falla al actualizar parámetros",
  [ParamsMessages.insertDataBlockSuccess]: "Fecha de bloqueo establecida correctamente",
  [ParamsMessages.insertDataBlockError]: "Falha ao inserir parámetro de bloqueio"

};
