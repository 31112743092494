import { SIMcardsModuleMessages, TranslationSIMCardsModuleMessages } from "../interfaces";

export const messages: TranslationSIMCardsModuleMessages = {

  /* region Backend messages */
  [SIMcardsModuleMessages.createSIMCardSuccess]: "SIM card registrado con éxito",
  [SIMcardsModuleMessages.createSIMCardError]: "Falla al registrar SIM card",
  [SIMcardsModuleMessages.readSIMCardSuccess]: "SIM card devuelto con éxito",
  [SIMcardsModuleMessages.noSIMCardFound]: "Ningún SIM card encontrado",
  [SIMcardsModuleMessages.readSIMCardError]: "Falla al buscar SIM card",
  [SIMcardsModuleMessages.updateSIMCardSuccess]: "SIM card actualizado con éxito",
  [SIMcardsModuleMessages.SIMCardNotFound]: "SIM card no encontrado",
  [SIMcardsModuleMessages.updateSIMCardError]: "Falla al actualizar SIM card",
  [SIMcardsModuleMessages.deleteSIMCardSuccess]: "SIM card eliminado con éxito",
  [SIMcardsModuleMessages.deleteSIMCardError]: "Falla al eliminar SIM card",
  /* endregion Backend messages */
  /* region Frontend messages */
  [SIMcardsModuleMessages.company]: "Empresa",
  [SIMcardsModuleMessages.status]: "Status",
  [SIMcardsModuleMessages.active]: "Activo",
  [SIMcardsModuleMessages.inactive]: "Inactivo",
  [SIMcardsModuleMessages.deleteConfirmationTitle]: "Confirmación de eliminación",
  [SIMcardsModuleMessages.deleteConfirmationMessage]: "¿Estás seguro de que deseas eliminar esta SIM card?",
  [SIMcardsModuleMessages.SIMCardRegistration]: "Registro de SIM card",
  [SIMcardsModuleMessages.activeSIMCard]: "SIM card activo",
  [SIMcardsModuleMessages.plan]: "Plan",
  [SIMcardsModuleMessages.purchaseDate]: "Fecha de adquisición",
  [SIMcardsModuleMessages.activationDate]: "Fecha de activación"
  /* endregion Frontend messages */
};
