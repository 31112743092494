export enum PumpTimelineTypes {
  SUMMARY = "Summary",
  DATA = "Data",
  GAP = "Gap",
  SUBGROUP = "SubGroup",
}

export enum PumpTimelineGroupsHeights {
  SUMMARY = 30,
  DATA = 85,
  GAP = 24,
  SUBGROUP = 25,
}

export enum PumpStatusTypes {
  OCIOSA = "Ociosa",
  BOMBEANDO = "Bombeando"
}

export enum PumpStatusTypesColors {
  OCIOSA = "#F70700",
  BOMBEANDO = "#8B92BB"
}
