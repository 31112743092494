/* eslint-disable import/prefer-default-export */
import { TranslationMapMessages, MapMessages } from "../interfaces";

export const messages: TranslationMapMessages = {
  [MapMessages.updateVehicleCurrentPosition]: "Update vehicle's current position",
  [MapMessages.errorFetchingAddressData]: "Error fetching address data",
  [MapMessages.locationSearchTitle]: "Location search",
  [MapMessages.typeLocationLabel]: "Type location",
  [MapMessages.allVehiclesSearchLabel]: "Search by plate, description or code",
  [MapMessages.route]: "Route",
  [MapMessages.outwardTrip]: "Outward trip",
  [MapMessages.returnTrip]: "Return",
  [MapMessages.names]: "Names",
  [MapMessages.licensePlates]: "License plates",
  [MapMessages.alerts]: "Alerts",
  [MapMessages.states]: "States",
  [MapMessages.events]: "Events",
  [MapMessages.viewLocation]: "View location",
  [MapMessages.zmrc]: "ZMRC"
};
