import { TravelsModuleMessages, TranslationTravelsModuleMessages } from "../interfaces";

export const messages: TranslationTravelsModuleMessages = {
  [TravelsModuleMessages.createTravelSuccess]: "Travel successfully registered",
  [TravelsModuleMessages.createTravelError]: "Failed to register new travel",
  [TravelsModuleMessages.readTravelSuccess]: "Travel(s) successfully returned",
  [TravelsModuleMessages.noTravelFound]: "No travel found",
  [TravelsModuleMessages.readTravelError]: "Failed to fetch travel(s)",
  [TravelsModuleMessages.updateTravelSuccess]: "Travel successfully edited",
  [TravelsModuleMessages.travelNotFound]: "Travel not registered in our system",
  [TravelsModuleMessages.updateTravelError]: "Failed to edit travel",
  [TravelsModuleMessages.deleteTravelSuccess]: "Travel successfully deleted",
  [TravelsModuleMessages.deleteTravelError]: "Failed to delete travel",
  [TravelsModuleMessages.swapTravelsSuccess]: "Travels successfully swapped",
  [TravelsModuleMessages.swapTravelsError]: "Failed to swap travels",
  [TravelsModuleMessages.getTravelAnalyticsSuccess]: "Analysis data successfully returned. Travel: ",
  [TravelsModuleMessages.getTravelAnalyticsError]: "Failed to fetch travel analysis data",
  [TravelsModuleMessages.getTravelAnalyticsVeryLargeTravel]: "Travel too extensive for viewing on the map",
  [TravelsModuleMessages.getTravelFilterOptionsSuccess]: "Travel filter options successfully returned",
  [TravelsModuleMessages.noTravelFilterOptionsFound]: "No constructions registered for this travel filter",
  [TravelsModuleMessages.getTravelFilterOptionsError]: "Failed to fetch construction(s)",
  [TravelsModuleMessages.getTravelCoordinatesSuccess]: "Travel coordinates successfully returned",
  [TravelsModuleMessages.getTravelCoordinatesError]: "Failed to fetch travel coordinates",
  [TravelsModuleMessages.noTravelCoordinatesFound]: "No travel coordinates found",

  /* region Integration messages */
  [TravelsModuleMessages.vehicleCurrentInTravel]: "Vehicle(s) is already in a travel currently. Data from the travel in question updated",
  [TravelsModuleMessages.originLocationNotRegistered]: "Origin location(s) not registered",
  [TravelsModuleMessages.vehicleNotRegistered]: "Vehicle(s) not registered",
  [TravelsModuleMessages.driverNotRegistered]: "Driver(s) not registered",
  [TravelsModuleMessages.destinationLocationNotRegistered]: "Destination location(s) not registered",
  [TravelsModuleMessages.externalIDAlreadyRegistered]: "External ID(s) already registered",
  [TravelsModuleMessages.invalidDatePeriod]: "Invalid date period",
  [TravelsModuleMessages.travelLinkedToAVehicle]: "Travel already linked to a vehicle, its exclusion cannot be"
  + " done",
  [TravelsModuleMessages.readPositionSuccess]: "Positioning successfully returned",
  [TravelsModuleMessages.travelFinishedOrNonexistent]: "The travel has already been finished or does not exist",
  [TravelsModuleMessages.needToInformIDOrExternalID]: "It is necessary to inform the ID or the external ID of the vehicle",
  [TravelsModuleMessages.maxSearchInterval]: "Maximum search interval is",
  [TravelsModuleMessages.days]: "days",
  [TravelsModuleMessages.analyticVehicleData]: "Analytic data of vehicle",
  [TravelsModuleMessages.returned]: "successfully returned",
  [TravelsModuleMessages.travelPreviouslyCancelled]: "Travel has already been cancelled previously",
  [TravelsModuleMessages.cancelTravelSuccess]: "Travel successfully cancelled"
  /* endregion */
};
