/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */

import React, { useCallback, useEffect, useState } from "react";

// region Libraries
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, TravelSwapMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { Travel } from "@shared/interfaces/travel.interface";
// endregion Interfaces
// region Services
import { useToast } from "@hooks/useToast";
import api from "../../../services/api";
// endregion Services
// region Hooks
// endregion Hooks
// region Utils
import utils from "../../../utils/useful-functions";
// endregion Utils
// region Components
import DialogConfirmAction from "../../Dialog/ConfirmAction";
// endregion Components
// region Styles
import { ContainerActions, ContainerTravelSwap } from "./style";
// endregion Styles

type ModalTravelSwapProps = {
  travel: Travel;
  startDate: string;
  finishDate: string;
  onClose: (status?: "success") => void;
};

const ModalTravelSwap: React.FC<ModalTravelSwapProps> = ({
  travel, startDate, finishDate, onClose
}) => {

  const { addToast } = useToast();
  const { t } = useTranslation();
  const [openOptionsOriginalTravel, setOpenOptionsOriginalTravel] = useState(false);
  const [openOptionsReplaceTravel, setOpenOptionsReplaceTravel] = useState(false);
  const [loadingTravels, setLoadingTravels] = useState(false);
  const [selectedTravel, setSelectedTravel] = useState<Travel | null>(null);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [travels, setTravels] = useState<Travel[]>([]);

  // Search all travels of day. Including not started
  const readAllTravelsOfDay = useCallback(async () => {
    try {
      const idVehicle = travel.vehicle.id_vehicle;

      const params = {
        startDate,
        finishDate,
        ...(idVehicle ? { idVehicle } : {})
      };

      setLoadingTravels(true);

      // Get the travels of selected vehicle
      const { data } = await api.post("travels/get-filtered-by", params);

      if (data.status === "success") {
        // Filter travel to not bring selected. Sort by registration date
        setTravels(data.result
          .filter((a: Travel) => a.id_travel !== travel.id_travel)
          .sort(
            (a:Travel, b:Travel) => new Date(a.registration_date).getTime() - new Date(b.registration_date).getTime()
          ));
      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingTravels(false);
    }
  }, [travel, startDate, finishDate, addToast, t]);

  // Send Swap request to server
  const SendSwapTravel = useCallback(async () => {
    try {

      setLoadingTravels(true);
      const { data } = await api.get(`travels/swap/${travel.id_travel}/${selectedTravel?.id_travel}`);

      if (data.status === "success") {
        addToast({ type: "success", title: t(TravelSwapMessages.swapCompleted), description: data.message });
        onClose("success");
      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingTravels(false);
      setOpenDialogConfirm(false);
    }

  }, [travel, selectedTravel, addToast, onClose, t]);

  // Close modal actions
  const handleClose = useCallback(() => {
    setSelectedTravel(null);
    onClose();
  }, [onClose]);

  // Get others travels of period
  useEffect(() => {
    readAllTravelsOfDay();
  }, [readAllTravelsOfDay]);

  return (
    <ContainerTravelSwap>
      <Dialog
        id="modalTravelAnalytics"
        open
        onClose={() => handleClose()}
        scroll="paper"
      >
        <DialogTitle className="mHeader">
          {t(TravelSwapMessages.swapTravelData)}
        </DialogTitle>
        <DialogContent dividers className="mContent">
          <Autocomplete
            open={openOptionsOriginalTravel}
            onOpen={() => setOpenOptionsOriginalTravel(true)}
            onClose={() => setOpenOptionsOriginalTravel(false)}
            getOptionSelected={(option, value) => option.id_travel === value.id_travel}
            getOptionLabel={(opt) => utils.formatAutoCompleteTravelLabel(opt)}
            options={[travel]}
            disabled
            defaultValue={travel}
            renderInput={(params) => (
              <TextField
                {...params}
                name="original_travel"
                label={t(TravelSwapMessages.originalTravel)}
                margin="dense"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            open={openOptionsReplaceTravel}
            onOpen={() => setOpenOptionsReplaceTravel(true)}
            onClose={() => setOpenOptionsReplaceTravel(false)}
            getOptionSelected={(option, value) => option.id_travel === value.id_travel}
            getOptionLabel={(opt) => utils.formatAutoCompleteTravelLabel(opt)}
            options={travels}
            loading={loadingTravels}
            onChange={(event, value) => { setSelectedTravel(value); setOpenDialogConfirm(false); }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="replace_travel"
                label={t(TravelSwapMessages.replaceTravel)}
                margin="dense"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingTravels ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions className="mFooter">
          <ContainerActions>
            <Button
              onClick={() => setOpenDialogConfirm(true)}
              color="primary"
              disabled={!selectedTravel}
            >
              {t(TravelSwapMessages.swap)}
            </Button>
            <Button
              onClick={() => handleClose()}
              color="primary"
            >
              {t(GlobalMessages.close)}
            </Button>
          </ContainerActions>
        </DialogActions>
      </Dialog>
      {selectedTravel && (
        <DialogConfirmAction
          open={openDialogConfirm}
          onClose={() => setOpenDialogConfirm(false)}
          title={t(TravelSwapMessages.swapConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.yes).toUpperCase(), action: () => SendSwapTravel() },
            { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirm(false) }
          ]}
        >
          {t(TravelSwapMessages.swapConfirmationDescription)}
          <br />
          {utils.formatAutoCompleteTravelLabel(travel)}
          <br />
          {utils.formatAutoCompleteTravelLabel(selectedTravel)}
        </DialogConfirmAction>
      )}
    </ContainerTravelSwap>
  );
};

export {
  ModalTravelSwap
};
