import { PumpProgrammingMessages, TranslationPumpProgrammingMessages } from "../interfaces";

export const messages: TranslationPumpProgrammingMessages = {
  [PumpProgrammingMessages.createPumpProgrammingSuccess]: "Programación de bomba creada con éxito",
  [PumpProgrammingMessages.createPumpProgrammingError]: "Error al crear la programación de la bomba",
  [PumpProgrammingMessages.readPumpProgrammingSuccess]: "Programación de bomba leída con éxito",
  [PumpProgrammingMessages.noPumpProgrammingFound]: "No se encontró programación de bomba",
  [PumpProgrammingMessages.readPumpProgrammingError]: "Error al leer la programación de la bomba",
  [PumpProgrammingMessages.updatePumpProgrammingSuccess]: "Programación de bomba actualizada con éxito",
  [PumpProgrammingMessages.updatePumpProgrammingError]: "Error al actualizar la programación de la bomba",
  [PumpProgrammingMessages.cancelPumpProgrammingSuccess]: "Programación de bomba cancelada con éxito",
  [PumpProgrammingMessages.cancelPumpProgrammingError]: "Error al cancelar la programación de la bomba",
  [PumpProgrammingMessages.noChangePumpProgrammingInProgress]: "No se puede cambiar la programación de la bomba que ya está en curso",
  [PumpProgrammingMessages.readPumpProgrammingHistorySuccess]: "Historial de programación de bomba leído con éxito",
  [PumpProgrammingMessages.readPumpProgrammingHistoryError]: "Error al leer el historial de programación de la bomba",
  [PumpProgrammingMessages.noPumpProgrammingHistoryFound]: "No se encontró historial de programación de bomba",

  [PumpProgrammingMessages.pumpProgrammingTitle]: "Programación de bomba",
  [PumpProgrammingMessages.dataTableTitle]: "programaciones de bomba",

  [PumpProgrammingMessages.tabProgrammings]: "Programaciones",
  [PumpProgrammingMessages.tabImports]: "Importaciones",
  [PumpProgrammingMessages.tabHistory]: "Historial",

  [PumpProgrammingMessages.columnDescription]: "Descripción",
  [PumpProgrammingMessages.columnClient]: "Cliente",
  [PumpProgrammingMessages.columnIntegrationDate]: "Fecha de integración",
  [PumpProgrammingMessages.columnDocumentDate]: "Fecha del documento",
  [PumpProgrammingMessages.columnDoc]: "Doc.",
  [PumpProgrammingMessages.columnExternalId]: "Id externo",
  [PumpProgrammingMessages.columnOrigin]: "Origen",
  [PumpProgrammingMessages.columnVehicle]: "Vehículo",
  [PumpProgrammingMessages.columnDriver]: "Conductor",
  [PumpProgrammingMessages.columnMaxSpeed]: "Vel. máx. permitida (km/h)",
  [PumpProgrammingMessages.columnUser]: "Usuario",
  [PumpProgrammingMessages.columnOccurrenceDate]: "Fecha de ocurrencia",
  [PumpProgrammingMessages.columnChangeType]: "Tipo de cambio",
  [PumpProgrammingMessages.columnImportRef]: "Referencia de importación",

  [PumpProgrammingMessages.cancelConfirmationTitle]: "Cancelar programación de bomba",
  [PumpProgrammingMessages.cancelConfirmationText]: "¿Estás seguro de que quieres eliminar esta programación de bomba?",

  [PumpProgrammingMessages.newPumpProgramming]: "Nueva programación de bomba",

  [PumpProgrammingMessages.changeTypeCreate]: "Creación",
  [PumpProgrammingMessages.changeTypeUpdate]: "Actualización",
  [PumpProgrammingMessages.changeTypeCancel]: "Cancelación",
  [PumpProgrammingMessages.changeTypeDelete]: "Eliminación"
};
