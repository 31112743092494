/* eslint-disable camelcase */
import { Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from "typeorm";

import { User } from "./reg_users.entity";
import { Travel } from "./reg_travels.entity";
import { PumpProgrammingImport } from "./reg_pump_programming_imports.entity";

import { ChangeType } from "../types/change_type.enum";

@Entity("hty_pump_programmings")
export class PumpProgrammingHistory {

  @PrimaryGeneratedColumn("uuid")
    id_pump_programming_history?: string;

  @Column({ type: "enum", enum: ChangeType, nullable: false })
    change_type?: ChangeType;

  @ManyToOne(() => User, (user) => user.pumpProgrammingHistoryList, { nullable: false })
  @JoinColumn({ name: "id_user_fk" })
    user?: User;

  @ManyToOne(() => Travel, (travel) => travel.pumpProgrammingHistoryList, { nullable: false })
  @JoinColumn({ name: "id_travel_fk" })
    travel?: Travel;

  @ManyToOne(() => PumpProgrammingImport, (pumpProgrammingImport) => pumpProgrammingImport.pumpProgrammingHistoryList)
  @JoinColumn({ name: "id_pump_programming_import_fk" })
    pumpProgrammingImport?: PumpProgrammingImport;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

}
