import { TranslationZonesModuleMessages, ZonesModuleMessages } from "../interfaces";

export const messages: TranslationZonesModuleMessages = {
  [ZonesModuleMessages.columnName]: "Nome",
  [ZonesModuleMessages.columnDescription]: "Descrição",
  [ZonesModuleMessages.newRegister]: "Nova zona de restrição",
  [ZonesModuleMessages.columnActive]: "Ativo",
  [ZonesModuleMessages.columnInactive]: "Inativo",


  [ZonesModuleMessages.readSuccess]: "Zonas lidas com sucesso",
  [ZonesModuleMessages.readError]: "Erro ao ler zonas",
  [ZonesModuleMessages.createSuccess]: "Zonas criadas com sucesso",
  [ZonesModuleMessages.createError]: "Erro ao criar zonas",
  [ZonesModuleMessages.noZonesFound]: "Nenhuma zona encontrada"
};
