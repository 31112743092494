/* eslint-disable no-param-reassign */

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { ReactSVG } from "react-svg";
import Loading from "react-fullscreen-loading";

import * as Yup from "yup";
import * as dateFns from "date-fns";
import * as _ from "lodash";

import { Accordion, AccordionDetails, AccordionSummary,
  Button, Chip, CircularProgress, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneIcon from "@material-ui/icons/Done";

import useTranslation from "src/translations/useTranslation";
import {
  CoordinateAnalysisMessages, FilterMessages,
  GlobalMessages,
  MapMessages,
  ToastMessages, VehicleTypesMessages,
  YupMessages
} from "@shared/languages/interfaces";

import { MapColors } from "@shared/constants/map.enum";
import {
  VehicleStatesTypesColors,
  VehicleStatesTypesID
} from "@shared/constants/vehicle-states-types.enum";
import { LocationTypes } from "@shared/constants/location-types.enum";

import { CircleOptions, CommandPointer, MarkerOptions, PolylineOptions } from "@shared/interfaces/map.interface";
import { TravelAnalytics } from "@shared/interfaces/travel-analytics.interface";
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { TextField } from "unform-material-ui";

import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { useSelector } from "react-redux";

import ButtonLoading from "@components/Button/ButtonLoading";
import AlertComponent from "@components/Alert/AlertModalList";
import { MapAnalytics, PopperMarker } from "@components/Map";
import TimeLineVehicleStates from "@components/TimeLines/TimeLineVehicleStates";

import getValidationErrors from "@hooks/getValidationErrors";
import { useToast } from "@hooks/useToast";

import api from "@services/api";
import utils from "@utils/useful-functions";

import { Container, ContainerContent, ContainerFilters } from "@pages/Reports/CoordinateAnalysis/CoordinateAnalysisMap/styles";
import CoordinateAnalysisCommandDetails from "@atoms/CoordinateAnalysisCommandDetails";

// Form filter interface
interface CoordinateAnalysisFormData {
  vehicle: Vehicle;
  travel: Travel;
  startDate: string;
  endDate: string;
}

// Map Filter interface
interface MapFilter {
  [key:string]: {
    class: string,
    title: string,
    status: boolean,
    color: string
  }
}

/** Generate an array of MarkerOptions (used by map component to create markers)
 * @param travelAnalyticRecords Travel analytic records
 * @return A array of MarkerOptions
 */
const generateMarkerOptions = (travelAnalyticRecords: TravelAnalytics[]): MarkerOptions[] => {

  // Array of markers options
  const generatedMarkers: MarkerOptions[] = [];

  if (travelAnalyticRecords.length > 0) {

    // Control variable to determine if is going or returning
    let isGoing = true;

    // Create index based on inserted markers
    let index = 0;

    // Iterate over all travel analytic records
    for (let i = 0; i < travelAnalyticRecords.length; i++) {

      // Get current travel analytic record
      const rec = travelAnalyticRecords[i];

      // Increment index in every loop
      index += 1;

      const color: string = isGoing ? MapColors.GOING : MapColors.RETURNING;
      const generateTitle = (idx:number, description = "") => `[${idx}] ${description}\n${utils.formatDateIfHave(rec.registration_date, "fullDate")}`;
      const generateInfoContent = (idx:number, going = false) => ReactDOMServer.renderToString(
        <PopperMarker
          index={idx}
          isGoing={going}
          record={rec}
        />
      );

      // Default option. Case reg is not State, Alert or Event, use this exact options to create a marker
      const defaultOptions: MarkerOptions = {
        position: { lat: Number.parseFloat(rec?.content?.LAT?.content), lng: Number.parseFloat(rec?.content?.LON?.content) },
        title: generateTitle(index),
        opacity: 1,
        icon: {
          url: utils.getMarkerDynamically(Number.parseFloat(rec?.content?.CRS?.content ?? "0"), color),
          scaledSize: { width: 14, height: 14 } as google.maps.Size
        },
        visible: true,
        infoContent: generateInfoContent(index, !!isGoing),
        class: isGoing ? "going" : "returning"
      };

      if (rec.vehicleState) {

        // If State is Retornando, then change travel to Return
        if (VehicleStatesTypesID.RETORNANDO === rec.vehicleState.status.id_vehicle_state_type) {
          isGoing = false;
        }

        // States Marker
        generatedMarkers.push({
          ...defaultOptions,
          title: generateTitle(index, rec.vehicleState.status.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleState(rec.vehicleState.status.id_vehicle_state_type),
            scaledSize: { width: 30, height: 45 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, !!isGoing),
          class: "states"
        });

      } else if (rec.alert) {

        const markerOptions = {
          ...defaultOptions,
          title: generateTitle(index, rec.alert.type.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleAlert(rec.alert.type.id_alert_type),
            scaledSize: { width: 25, height: 40 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, !!isGoing),
          class: "alerts"
        };

        // Alert Marker
        generatedMarkers.push(markerOptions);

      } else if (rec.event) {

        const markerOptions = {
          ...defaultOptions,
          title: generateTitle(index, rec.event.type.description),
          animation: 2,
          opacity: 1,
          icon: {
            ...defaultOptions.icon as google.maps.Icon,
            url: utils.getMarkerAccordingVehicleEvent(rec.event.type.id_event_type)
              || `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${index}|${"#ff9800".replace("#", "")}|000000`,
            scaledSize: { width: 25, height: 40 } as google.maps.Size
          },
          infoContent: generateInfoContent(index, !!isGoing),
          class: "events"
        };

        // Get coords of events when have
        if (rec.event.latitude && rec.event.longitude) {
          markerOptions.position = { lat: Number(rec.event.latitude), lng: Number(rec.event.longitude) };
        }

        // Event Marker
        generatedMarkers.push(markerOptions);

      } else {

        // Default marker (First)
        generatedMarkers.push(defaultOptions);
      }
    }
  }

  return generatedMarkers;
};

/** Generate an array of Circle Options (used by map component to create circles)
 * @param travelAnalyticRecords Travel analytic records
 * @return A array of CircleOptions
 */
const generateCirclesOptions = (travelAnalyticRecords: TravelAnalytics[]): CircleOptions[] => {

  const circles: CircleOptions[] = [];

  travelAnalyticRecords.forEach((rec: TravelAnalytics) => {

    if (rec.vehicleState && rec.vehicleState.location) {

      circles.push({
        strokeColor: rec.vehicleState.location.type.description === LocationTypes.USINA
          ? VehicleStatesTypesColors.NA_USINA : VehicleStatesTypesColors.EM_OBRA,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: rec.vehicleState.location.type.description === LocationTypes.USINA
          ? VehicleStatesTypesColors.NA_USINA : VehicleStatesTypesColors.EM_OBRA,
        fillOpacity: 0.35,
        center: {
          lat: Number.parseFloat(rec.vehicleState.location.latitude),
          lng: Number.parseFloat(rec.vehicleState.location.longitude)
        },
        radius: Number.parseFloat(`${rec.vehicleState.location.radius}`) || 80
      });

    }
  });

  return circles;
};

/** Generate object of default map filter
 * @return A object of MapFilter
 */
const generateDefaultMapFilter = (t): MapFilter => ({
  going: { class: "going", title: "Ida", status: true, color: MapColors.GOING },
  returning: { class: "returning", title: "Volta", status: true, color: MapColors.RETURNING },
  states: { class: "states", title: t(MapMessages.states), status: true, color: "#7e73f8" },
  alerts: { class: "alerts", title: t(MapMessages.alerts), status: true, color: "#fd0013" },
  events: { class: "events", title: t(MapMessages.events), status: true, color: "#a89423" }
});

/** Generate an array of PolylinesOptions (used by map component to trace routes lines)
 * @param markerOptions Marker options to be used to create polylines
 */
const generatePolylinesOptions = (markerOptions: MarkerOptions[]) => {

  interface Coord { lat: number, lng: number }
  interface PolylineCoord { going: Coord[], returning: Coord[] }

  // Control variable to determine if is going or returning
  let isGoing = true;

  // Array of polylines options
  const generatedPolylines: PolylineOptions[] = [];
  const coords: PolylineCoord = { going: [], returning: [] };

  // Iterate over all travel analytic records
  markerOptions.forEach((rec) => {

    // Verify if rec is a vehicle state and the state ir after 'Retornando'
    if (isGoing && rec.class === "returning") isGoing = false;

    coords[`${isGoing ? "going" : "returning"}`].push(rec.position as Coord);
  });

  // Add marker 'Retornando' in 'going' last position, to create a continuous line
  if (coords["returning"]?.length > 0) {
    coords["returning"].unshift(coords["going"][coords["going"].length - 1]);
  }

  // Default options of polylines
  const defaultOptions: PolylineOptions = { strokeOpacity: 0.8, strokeWeight: 8, geodesic: true };

  Object.keys(coords).forEach((key) => {
    generatedPolylines.push({
      ...defaultOptions,
      path: coords[key],
      strokeColor: key === "going" ? MapColors.GOING : MapColors.RETURNING,
      class: key
    });
  });

  return generatedPolylines;
};

/** Generate chip components used to show/change filter map
 * @param vehicle Used to analyze if vehicle is "Veiculo de apoio"
 * @return A object of MapFilter
 */
const generateMapFilterChips = (
  mapFilter: MapFilter,
  setMapFilter: React.Dispatch<React.SetStateAction<MapFilter>>
) => Object.keys(mapFilter).map((key) => {

  const filter = mapFilter[key];

  return (
    <Chip
      key={`filter-chip-${filter.title}`}
      label={filter.title}
      style={{
        minWidth: "100px",
        fontWeight: "bold",
        margin: "2px 5px",
        color: filter.status ? "#fafafa" : "#9e9e9e",
        backgroundColor: filter.status ? filter.color : "#DDDDDD"
      }}
      onDelete={() => { setMapFilter((state) => ({ ...state, [key]: { ...state[key], status: !filter.status } })); }}
      {...(!filter.status ? { deleteIcon: <DoneIcon /> } : {})}
    />
  );
});

const CoordinateAnalysisMap: React.FC = () => {

  /* region Hooks */
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t } = useTranslation();
  /* endregion Hooks */

  /* region States */
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [dateHourPeriod, setDateHourPeriod] = useState(0);
  const [travelAnalyticRecords, setTravelAnalyticRecords] = useState<TravelAnalytics[]>([]);
  const [mapFilter, setMapFilter] = useState(generateDefaultMapFilter(t));
  const [commandsPointer, setCommandsPointer] = useState<CommandPointer>({} as CommandPointer);

  // Vehicle options
  const [openVehicleFilter, setOpenVehicleFilter] = useState(false);
  const [optionsVehicleFilter, setOptionsVehicleFilter] = useState<Vehicle[]>([] as Array<Vehicle>);
  const [selectedVehicleFilter, setSelectedVehicleFilter] = useState<Vehicle>({} as Vehicle);

  // Travel options
  const [openTravelFilter, setOpenTravelFilter] = useState(false);
  const [optionsTravelFilter, setOptionsTravelFilter] = useState<Travel[]>([] as Array<Travel>);
  const [selectedTravelFilter, setSelectedTravelFilter] = useState<Travel>({} as Travel);
  /* endregion States */

  /* region Memo */
  const markers = useMemo(() => generateMarkerOptions(travelAnalyticRecords), [travelAnalyticRecords]);
  const polylines = useMemo(() => generatePolylinesOptions(markers), [markers]);
  const circles = useMemo(() => generateCirclesOptions(travelAnalyticRecords), [travelAnalyticRecords]);
  /* endregion Memo */
  /* region Constants */
  const loadingVehicleFilter = openVehicleFilter && optionsVehicleFilter.length === 0;
  const loadingTravelFilter = openTravelFilter && optionsTravelFilter.length === 0;
  /* endregion Constants */
  /* region Forms */
  const formRef = useRef<FormHandles>(null);
  /** Validations */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: CoordinateAnalysisFormData) => {

      try {

        formRef.current?.setErrors({});

        // Define the validation types
        const schema = Yup.object().shape({
          vehicle: Yup.string().required(t(YupMessages.vehicleRequired)),
          startDate: Yup.date(),
          endDate: Yup.date().min(Yup.ref("startDate"), t(YupMessages.finishDateGreaterThanStartDate))
            .test("validateRangeDays", t(YupMessages.maxDateInterval24h),
              (val) => dateFns.differenceInMinutes(val, new Date(formData.startDate)) <= 1440 || !_.isEmpty(formData.travel))
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Set values of autocomplete fields
        formData.vehicle = selectedVehicleFilter;
        if (!_.isEmpty(selectedTravelFilter)) formData.travel = selectedTravelFilter;

        // Pass date to string utc
        formData.startDate = new Date(formData.startDate).toISOString();
        formData.endDate = new Date(formData.endDate).toISOString();

        // Return parameters to main Component
        readCoordinateAnalysis(formData);

      } catch (error: any) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  /* endregion Forms */

  /* region Functions */

  /** Get coordinates of vehicle in travel / period
   * @param formData: Form data
   */
  const readCoordinateAnalysis = useCallback(async (formData: CoordinateAnalysisFormData) => {

    setLoading(true);

    try {

      const { data } = await api.get("travels/get-travel-analytics", { params: {
        idVehicle: formData.vehicle.id_vehicle,
        idTravel: formData.travel.id_travel,
        fullAnalytics: true,
        startDate: formData.startDate,
        endDate: formData.endDate
      } });

      if (data.status === "success") {
        setTravelAnalyticRecords(data.result);
        setShowMap(true);
      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error: any) {
      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
    } finally {
      setLoading(false);
    }

  }, [addToast, t]);

  /** COMPONENT
   * List of alerts
   * @param travelAnalyticRecords: Travel Analytic Records
  */
  const alertsContainer = useCallback((travelAnalyticRecords: TravelAnalytics[]) => {

    const travelAnalyticRecordsWithAlerts = travelAnalyticRecords.filter((record) => !_.isEmpty(record.alert));
    const alerts = travelAnalyticRecordsWithAlerts.map((record) => record.alert);

    return (
      <div className="coordinate-analysis-details__alerts">
        <div className="coordinate-analysis-details__block coordinate-analysis-details__block--title">
          <div className="coordinate-analysis-details__title">{t(CoordinateAnalysisMessages.alerts)}</div>
          {alerts.length <= 0
            && (<div className="coordinate-analysis-details__subtitle"> {t(CoordinateAnalysisMessages.noAlertsForThisPeriod)} </div>)}
        </div>
        {alerts.length > 0
          && (
          <div className="coordinate-analysis-details__block">
            <AlertComponent alerts={alerts} />
          </div>
          )}
      </div>
    );

  }, [t]);

  /** COMPONENT
   * States timeline
   */
  const statesContainer = useCallback((travelAnalyticRecords: TravelAnalytics[]) => {

    const travelAnalyticRecordsWithAlerts = travelAnalyticRecords.filter((record) => !_.isEmpty(record.alert));
    const alerts = travelAnalyticRecordsWithAlerts.map((record) => record.alert);
    const states = travelAnalyticRecords
      .filter((record) => !!record.vehicleState)
      .map((record) => record.vehicleState);
    const vehicle = { states, alerts } as Vehicle;

    return (
      <div className="coordinate-analysis-details__states">
        {states.length > 0 ? (
          <TimeLineVehicleStates vehicle={vehicle} historicalTravel />
        ) : (
          <div className="coordinate-analysis-details__block coordinate-analysis-details__block--title">
            <div className="coordinate-analysis-details__title">{ t(CoordinateAnalysisMessages.states) }</div>
            <div className="coordinate-analysis-details__subtitle">
              { t(CoordinateAnalysisMessages.noStatesForThisPeriod) }
            </div>
          </div>
        ) }
      </div>
    );

  }, [t]);
  /* endregion Functions */
  /* region Components */
  const mapFilterChips = useMemo(() => generateMapFilterChips(mapFilter, setMapFilter), [mapFilter, setMapFilter]);

  /** COMPONENT
   * Map Component
   */
  const mapContainer = useMemo(() => {

    if (!showMap) return <div className="coordinate-analysis-details__map--static" />;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} className="coordinate-analysis-details__map">
          <MapAnalytics
            mapHeight="100%"
            markers={markers}
            polylines={polylines}
            circles={circles}
            snapToRoads
            commandsPointer={commandsPointer}
            filter={Object.keys(mapFilter).map((key) => {
              const fil = mapFilter[key];

              return { class: fil.class, status: fil.status };
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-evenly">
            {mapFilterChips}
          </Grid>
        </Grid>
      </Grid>
    );

  }, [showMap, markers, circles, polylines, commandsPointer, mapFilterChips, mapFilter]);

  /** COMPONENT
   * List of commands component
   */
  const commandsContainer = useMemo(() => (
    <div className="coordinate-analysis-details__commands">
      <div className="coordinate-analysis-details__block coordinate-analysis-details__block--title">
        <div className="coordinate-analysis-details__title">{t(CoordinateAnalysisMessages.commands)}</div>
        {travelAnalyticRecords.length <= 0
          && (<div className="coordinate-analysis-details__subtitle"> {t(CoordinateAnalysisMessages.noCommandsForThisPeriod)} </div>)}
      </div>
      {travelAnalyticRecords.length > 0
        && (
          <div className="coordinate-analysis-details__block coordinate-analysis-details__block--full-height">
            <div className="coordinate-analysis-details__list">
              {travelAnalyticRecords.map((record, index) => (
                <Accordion
                  className="coordinate-analysis-details__item"
                  key={record.binary_data}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="coordinate-list"
                    className="coordinate-analysis-details__summary"
                  >
                    <div className="coordinate-analysis-details__index"><Chip label={index + 1} /></div>
                    <div className="coordinate-analysis-details__date">{utils.formatDateIfHave(new Date(record.occurrence_date), "fullDate")}</div>
                    <div className="coordinate-analysis-details__icon">
                      {record.vehicleState
                        && <ReactSVG src={utils.getMarkerAccordingVehicleState(record.vehicleState.status.id_vehicle_state_type)} />}
                      {record.alert
                        && <ReactSVG src={utils.getMarkerAccordingVehicleAlert(record.alert.type.id_alert_type)} />}
                      {record.event
                        && <ReactSVG src={utils.getMarkerAccordingVehicleEvent(record.event.type.id_event_type)} />}
                      {!record.vehicleState && !record.alert && !record.event && <RemoveIcon />}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="coordinate-analysis-details__details">
                    <CoordinateAnalysisCommandDetails data={record.content} />
                    <Button
                      className="coordinate-analysis-details__button"
                      onClick={() => setCommandsPointer({ ...commandsPointer, selected: index })}
                    >{t(CoordinateAnalysisMessages.showMarker)}
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        )}
    </div>
  ), [travelAnalyticRecords, commandsPointer, t]);
  /* endregion Components */
  /* region Effects */

  /** Read Vehicles data (Options) */
  useEffect(() => {

    let active = true;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.get("vehicles/read");

        if (data.status === "success") {
          if (active) setOptionsVehicleFilter(data.result);
        } else {
          setOpenVehicleFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenVehicleFilter(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [addToast, t]);

  /** Read Travels data (Options) */
  useEffect(() => {

    let active = true;

    // Search travels only click on travel filter
    if (!loadingTravelFilter) return undefined;

    (async () => {

      try {

        // Get all vehicles
        const { data } = await api.post("travels/get-filtered-by", {
          idVehicles: [selectedVehicleFilter.id_vehicle],
          startDate: formRef?.current?.getFieldValue("startDate"),
          finishDate: formRef?.current?.getFieldValue("endDate")
        });

        if (data.status === "success") {
          if (active) setOptionsTravelFilter(data.result);
        } else {
          setOpenTravelFilter(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error: any) {

        setOpenTravelFilter(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();

    return () => { active = false; };

  }, [loadingTravelFilter, selectedVehicleFilter, addToast, t]);
  useEffect(() => {
    if (!openTravelFilter) setOptionsTravelFilter([]);
  }, [openTravelFilter]);
  /* endregion Effects */

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loading} />
        <ContainerFilters>
          <div className="titleHeader">
            <div className="title">{t(CoordinateAnalysisMessages.mapTitle)}</div>
            <div className="subtitle">{t(FilterMessages.filterInstructions)}</div>
          </div>
          <Form className="form" ref={formRef} onSubmit={validations.validateForm}>
            <div className="filterContent">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Autocomplete
                    open={openVehicleFilter}
                    onOpen={() => setOpenVehicleFilter(true)}
                    onClose={() => setOpenVehicleFilter(false)}
                    onChange={(event, value) => {
                      setSelectedTravelFilter({} as Travel);
                      formRef.current?.setFieldValue("travel", "");
                      setSelectedVehicleFilter(value as Vehicle);
                      validations.validateFieldError("vehicle");
                    }}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    options={optionsVehicleFilter.sort((a, b) => -b.type.description.localeCompare(a.type.description)
                      || -b.code.localeCompare(a.code))}
                    groupBy={(option) => t(VehicleTypesMessages[`${option.type.id_vehicle_type}-plural`])}
                    loading={loadingVehicleFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="vehicle"
                        label={t(GlobalMessages.vehicle)}
                        helperText={t(GlobalMessages.required)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingVehicleFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Autocomplete
                    open={openTravelFilter}
                    onOpen={() => setOpenTravelFilter(true)}
                    onClose={() => setOpenTravelFilter(false)}
                    onChange={(event, value) => setSelectedTravelFilter(value as Travel)}
                    value={!_.isEmpty(selectedTravelFilter) ? selectedTravelFilter : null}
                    getOptionSelected={(option, value) => option.description === value.description}
                    getOptionLabel={(option) => utils.formatAutoCompleteTravelLabel(option)}
                    options={optionsTravelFilter}
                    disabled={_.isEmpty(selectedVehicleFilter)
                      || dateHourPeriod > 72 || dateHourPeriod < 0}
                    loading={loadingTravelFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="travel"
                        label={t(CoordinateAnalysisMessages.travel)}
                        helperText={t(CoordinateAnalysisMessages.travelHelperText)}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingTravelFilter ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="startDate"
                    label={t(CoordinateAnalysisMessages.startDate)}
                    fullWidth
                    onChange={() => {
                      setDateHourPeriod(
                        dateFns.differenceInHours(
                          new Date(formRef?.current?.getFieldValue("endDate")),
                          new Date(formRef?.current?.getFieldValue("startDate"))
                        )
                      );
                    }}
                    disabled={!_.isEmpty(selectedTravelFilter)}
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T00:00")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    name="endDate"
                    label={t(CoordinateAnalysisMessages.finishDate)}
                    fullWidth
                    onChange={(event) => {
                      setDateHourPeriod(
                        dateFns.differenceInHours(
                          new Date(formRef?.current?.getFieldValue("endDate")),
                          new Date(formRef?.current?.getFieldValue("startDate"))
                        )
                      );
                    }}
                    disabled={!_.isEmpty(selectedTravelFilter)}
                    type="datetime-local"
                    defaultValue={dateFns.format(new Date(), "yyyy-MM-dd").concat("T23:59")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                  <ButtonLoading
                    className="button-loading"
                    type="submit"
                    loading={loading}
                    loadingContent={t(CoordinateAnalysisMessages.loadingContent)}
                  >{t(GlobalMessages.search)}
                  </ButtonLoading>
                </Grid>
              </Grid>
            </div>
          </Form>
        </ContainerFilters>
        <ContainerContent>
          <Grid container spacing={2} className="coordinate-analysis-details">
            <Grid item xs={12} sm={3} md={3} lg={3} xl={2} className="left-container-block">
              {statesContainer(travelAnalyticRecords)}
              {alertsContainer(travelAnalyticRecords)}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={7}>{mapContainer}</Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>{commandsContainer}</Grid>
          </Grid>
        </ContainerContent>
      </Container>
    </>
  );
};

export default CoordinateAnalysisMap;
