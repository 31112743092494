import React, { useCallback, useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";

// region Imports - Material UI
import CancelIcon from "@material-ui/icons/Cancel";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
// endregion Imports - Material UI
// region Imports - External libraries
import * as Yup from "yup";
// endregion Imports - External libraries
// region Imports - Unform
import { TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
// endregion Imports - Unform
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  PumpProgrammingImportsModuleMessages,
  ToastMessages,
  YupMessages
} from "@shared/languages/interfaces";
import { ReportStatusTypeMessages } from "@shared/languages/interfaces/scheduledReportMessages";
// endregion Imports - Languages
// region Imports - Shared
import { PumpProgrammingImport } from "@shared/entities/reg_pump_programming_imports.entity";
import { ReportStatusType } from "@shared/types/report_status_type.enum";
// endregion Imports - Shared
// region Imports - Atoms
import FileInput from "@atoms/FileInput";
// endregion Imports - Atoms
// region Imports - Components
import ButtonTable from "@components/Button/ButtonTable";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
import getValidationErrors from "@hooks/getValidationErrors";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import {
  Container,
  ContainerModalDetails,
  ContainerModalNewRegister
} from "./styles";
// endregion Imports - Styles

// region Interfaces
interface ImportFormData {
  description: string;
  file: File;
}
// endregion Interfaces

const PumpProgrammingImports: React.FC = () => {

  // region States
  const [pumpProgrammingImports, setPumpProgrammingImports] = useState<
    PumpProgrammingImport[]>([] as Array<PumpProgrammingImport>);

  const [pumpProgrammingImportDetails, setPumpProgrammingImportDetails] = useState<PumpProgrammingImport | undefined>(undefined);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const [openNewRegisterModal, setOpenNewRegisterModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  // endregion States
  // region Hooks
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t, i18n } = useTranslation();
  // endregion Hooks
  // region Constants
  const maxFileSizeInMb = 10;

  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }],
    order: []
  };

  const dataTableColumns: DataTableColumns[] = [
    { // Description
      title: t(PumpProgrammingImportsModuleMessages.columnDescription),
      data: (pumpProgrammingImport: PumpProgrammingImport) => (pumpProgrammingImport?.description),
      defaultContent: "",
      filterable: true,
      propertyName: "pumpProgrammingImport.filter.description"
    },
    { // Status
      title: t(PumpProgrammingImportsModuleMessages.columnStatus),
      data: (pumpProgrammingImport: PumpProgrammingImport) => {
        const text = t(ReportStatusTypeMessages[pumpProgrammingImport.status!]);
        const color = utils.getReportStatusTypeColor(pumpProgrammingImport.status!);

        return `<span style="color: ${color}; font-weight: bold;">${text}</span>`;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "pumpProgrammingImport.status"
    },
    { // Registration date
      title: t(PumpProgrammingImportsModuleMessages.columnRegistrationDate),
      data: (pumpProgrammingImport: PumpProgrammingImport) => utils.formatDateIfHave(pumpProgrammingImport.registration_date!, "fullDate"),
      defaultContent: "",
      filterable: true,
      propertyName: "pumpProgrammingImport.registration_date"
    },
    { // Processing date
      title: t(PumpProgrammingImportsModuleMessages.columnProcessingDate),
      data: (pumpProgrammingImport: PumpProgrammingImport) => utils.formatDateIfHave(pumpProgrammingImport.processing_date!, "fullDate"),
      defaultContent: "",
      filterable: true,
      propertyName: "pumpProgrammingImport.registration_date"
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: (pumpProgrammingImport: PumpProgrammingImport) => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          { pumpProgrammingImport.message_result && (
            <Grid item xs sm md lg xl>
              <ButtonTable className="action-button modules-pumpProgrammingImport-list-details"><FormatListBulletedIcon /></ButtonTable>
            </Grid>
          )}
          { pumpProgrammingImport.status === ReportStatusType.IN_QUEUE && (
            <Grid item xs sm md lg xl>
              <ButtonTable className="action-button modules-pumpProgrammingImport-list-cancel"><CancelIcon /></ButtonTable>
            </Grid>
          )}
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];

  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => handlePumpProgrammingImportNewRegister()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_programacoes_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-pumpProgrammingImport-list-details",
      callback: (rowData: PumpProgrammingImport) => handlePumpProgrammingImportDetails(rowData)
    },
    {
      ref: ".modules-pumpProgrammingImport-list-cancel",
      callback: (rowData: PumpProgrammingImport) => handlePumpProgrammingImportCancel(rowData)
    }
  ];
  // endregion Constants
  // region Form Validation
  const formRef = useRef<FormHandles>(null);
  // Validations of unique fields
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateForm: async (formData: ImportFormData) => {

      try {

        // Define the validation types
        const schema = Yup.object().shape({
          description: Yup.string().trim().required(t(YupMessages.fieldRequired)),
          file: Yup.mixed()
            .test("hasFile", t(YupMessages.fieldRequired), (file: File | null) => file !== null)
            .test("fileSize", t(YupMessages.largeFileSize).replace("FILE_SIZE", `${maxFileSizeInMb}MB`), (file: File | null) => {
              if (!file) return true;

              return file.size <= maxFileSizeInMb * 1024 * 1024;
            })
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update pump programming import (According action selected)
        await createPumpProgrammingImport(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };
  // endregion Form Validation
  // region Functions
  /**
   * Search pump programming imports
   */
  const readPumpProgrammingImports = useCallback(async () => {

    setLoading(true);

    try {

      // Get the pump programming imports
      const { data } = await api.get("pump-programming-imports/read");

      if (data.status === "success") {

        setPumpProgrammingImports([...data.result] as PumpProgrammingImport[]);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        setPumpProgrammingImports([]);
        if (table.length) table.clear().draw();
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, table, t]);

  /**
   * Create a new pump programming import
   * @param pumpProgrammingImport The pump programming import to create
   */
  const createPumpProgrammingImport = async (pumpProgrammingImport: ImportFormData) => {

    setLoading(true);

    try {

      const formData = new FormData();

      formData.append("description", pumpProgrammingImport.description);
      formData.append("file", pumpProgrammingImport.file);

      const { data } = await api.post("pump-programming-imports/create", formData,
        { headers: { "Content-Type": "multipart/form-data" } });

      if (data.status === "success") {

        await readPumpProgrammingImports();

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        handleClosePumpProgrammingImportNewRegister();

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }

    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle the pump programming import details
   */
  const handlePumpProgrammingImportDetails = async (pumpProgrammingImport: PumpProgrammingImport) => {
    setOpenDetailsModal(true);
    setPumpProgrammingImportDetails(pumpProgrammingImport);
  };

  /**
   * Handle close the pump programming import details
   */
  const handleClosePumpProgrammingImportDetails = () => {
    setOpenDetailsModal(false);
    setPumpProgrammingImportDetails(undefined);
  };

  /**
   * Handle the pump programming import new register
   */
  const handlePumpProgrammingImportNewRegister = async () => setOpenNewRegisterModal(true);

  /**
   * Handle close the pump programming import new register
   */
  const handleClosePumpProgrammingImportNewRegister = () => setOpenNewRegisterModal(false);

  /**
   * Handle the pump programming import cancel
   * @param pumpProgrammingImport The pump programming import to cancel
   */
  const handlePumpProgrammingImportCancel = async (pumpProgrammingImport: PumpProgrammingImport) => {

    setLoading(true);

    try {
      const { data } = await api.patch(`pump-programming-imports/cancel/${pumpProgrammingImport.id_pump_programming_import}`);

      if (data.status === "success") {
        await readPumpProgrammingImports();

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }
    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  // endregion Functions
  // region Effects

  // Refresh the pump programming imports when the language is changed
  useEffect(() => {
    readPumpProgrammingImports().then();
  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  // endregion Effects

  return (
    <>
      <Container platform={screen.platform as ScreenPlatform}>
        <Loading loading={loading} />
        { !loading && (
          <NewDataTable
            title={t(PumpProgrammingImportsModuleMessages.title)}
            data={pumpProgrammingImports}
            columns={dataTableColumns}
            settings={dataTableSettings}
            actions={dataTableActions}
            buttons={dataTableButtons}
            returnTable={(table) => setTable(table)}
          />
        )}
        <ContainerModalDetails id="modalPumpProgrammingImportDetails">
          <Dialog
            open={openDetailsModal}
            onClose={handleClosePumpProgrammingImportDetails}
            scroll="paper"
            container={document.getElementById("modalPumpProgrammingImportDetails")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {pumpProgrammingImportDetails?.description} - {t(PumpProgrammingImportsModuleMessages.importingResult)}
                </div>
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <DialogContentText tabIndex={-1} component="div">
                {pumpProgrammingImportDetails?.message_result?.split("\n").map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{line}</div>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mFooter">
              <Button disableRipple onClick={handleClosePumpProgrammingImportDetails} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalDetails>
        <ContainerModalNewRegister id="modalNewPumpProgrammingImport">
          <Dialog
            open={openNewRegisterModal}
            onClose={handleClosePumpProgrammingImportNewRegister}
            scroll="paper"
            container={document.getElementById("modalNewPumpProgrammingImport")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">
                  {t(PumpProgrammingImportsModuleMessages.newRegister)}
                </div>
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <DialogContentText tabIndex={-1} component="div">
                <Form className="form" ref={formRef} onSubmit={(formData) => validations.validateForm(formData)}>
                  <DialogContentText tabIndex={-1} component="div">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          className="description"
                          label={t(PumpProgrammingImportsModuleMessages.columnDescription)}
                          margin="dense"
                          variant="outlined"
                          name="description"
                          InputLabelProps={{
                            shrink: formRef.current?.getFieldValue("description").length > 0 ? true : undefined
                          }}
                          helperText={t(GlobalMessages.required)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FileInput
                          name="file"
                          helperText={t(GlobalMessages.required)}
                          accept=".csv"
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </Form>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mFooter">
              <Button disableRipple onClick={() => formRef.current?.submitForm()} color="primary">{t(GlobalMessages.save)}</Button>
              <Button disableRipple onClick={handleClosePumpProgrammingImportNewRegister} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalNewRegister>
      </Container>
    </>
  );
};

export default PumpProgrammingImports;
