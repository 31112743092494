import styled from "styled-components";

interface ColorSquareProps {
  color: string
}

export const Container = styled.div`

`;

export const ColorSquare = styled.div<ColorSquareProps>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;
