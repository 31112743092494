import { HardwareModuleMessages, TranslationHardwareModuleMessage } from "../interfaces";

export const messages: TranslationHardwareModuleMessage = {

  /* region Frontend messages */
  [HardwareModuleMessages.tabsHardware]: "Hardware",
  [HardwareModuleMessages.tabsHardwareInstallationHistory]: "Historial de instalación",
  [HardwareModuleMessages.tabsCommands]: "Comandos",
  [HardwareModuleMessages.tabsLogs]: "Logs",

  /* region Commands tab */
  [HardwareModuleMessages.commandStatusPending]: "Pendiente",
  [HardwareModuleMessages.commandStatusSent]: "Enviado",
  [HardwareModuleMessages.commandStatusProcessed]: "Procesado",
  [HardwareModuleMessages.commandStatusCancelled]: "Cancelado",
  [HardwareModuleMessages.commandFilterCreation]: "Creación",
  [HardwareModuleMessages.commandFilterSend]: "Envío",
  [HardwareModuleMessages.commandFilterProcessing]: "Procesamiento",
  [HardwareModuleMessages.command]: "Comando",
  [HardwareModuleMessages.commandRegistrationDate]: "Creación",
  [HardwareModuleMessages.commandSendDate]: "Envío",
  [HardwareModuleMessages.commandProcessedDate]: "Procesamiento",
  [HardwareModuleMessages.commandAttempts]: "Intentos",
  [HardwareModuleMessages.commandResponse]: "Respuesta",
  [HardwareModuleMessages.commandFormSelectVehicle]: "Seleccione los vehículos para enviar el comando",
  [HardwareModuleMessages.commandFormSelectCommand]: "Seleccione o escriba el comando a enviar",
  [HardwareModuleMessages.commandFormRequiredCommand]: "Este campo es obligatorio",
  [HardwareModuleMessages.commandFilterNoTracker]: "Sin rastreador",
  [HardwareModuleMessages.commandFilterDateRange]: "La fecha de finalización debe ser mayor que la de inicio",
  [HardwareModuleMessages.commandFilterNoLinkedTracker]: "El vehículo no tiene un rastreador vinculado",
  [HardwareModuleMessages.commandTitle]: "Listar comandos",
  [HardwareModuleMessages.vehicle]: "Vehículo",
  [HardwareModuleMessages.commandStatus]: "Status",
  [HardwareModuleMessages.commandFilterDateBy]: "Filtrar fecha por",
  [HardwareModuleMessages.startDate]: "Fecha de inicio",
  [HardwareModuleMessages.finishDate]: "Fecha de finalización",
  [HardwareModuleMessages.dataTableTitle]: "comandos de configuración",
  [HardwareModuleMessages.commandDeleteTitle]: "Confirmación de eliminación",
  [HardwareModuleMessages.commandDeleteText]: "¿Realmente desea cancelar el comando?",
  [HardwareModuleMessages.devId]: "Dev ID",
  [HardwareModuleMessages.commandCreateTitle]: "Registro de comandos de configuración",
  [HardwareModuleMessages.commandCreateDefinedCommand]: "Comando predefinido",
  [HardwareModuleMessages.commandCreateToSendCommand]: "Comando a enviar - Utilice la etiqueta <DEV_ID> para"
  + " relleno automático",
  [HardwareModuleMessages.commandCreateNoVehicle]: "Seleccione los vehículos para enviar los comandos anteriores",
  [HardwareModuleMessages.commandCopyAlert]: "Comando copiado al portapapeles",
  /* endregion Commands tab */
  /* region Hardware tab */
  [HardwareModuleMessages.hardwareFormTypeRequired]: "El tipo es obligatorio",
  [HardwareModuleMessages.hardwareFormBrandRequired]: "El proveedor es obligatorio",
  [HardwareModuleMessages.hardwareFormModelRequired]: "El modelo es obligatorio",
  [HardwareModuleMessages.hardwareFormDevIdRequired]: "El ID de dispositivo (DEV ID) es obligatorio",
  [HardwareModuleMessages.hardwareFormPurchaseDateRequired]: "La fecha de compra es obligatoria",
  [HardwareModuleMessages.hardwareFormDevIdAlreadyExists]: "ID de dispositivo (DEV ID) ya registrado",
  [HardwareModuleMessages.iButton]: "Ibutton",
  [HardwareModuleMessages.devId]: "ID de dispositivo (DEV ID)",
  [HardwareModuleMessages.type]: "Tipo",
  [HardwareModuleMessages.brand]: "Marca",
  [HardwareModuleMessages.model]: "Modelo",
  [HardwareModuleMessages.doBlock]: "Realizar bloqueo",
  [HardwareModuleMessages.registrationDate]: "Fecha de registro",
  [HardwareModuleMessages.hardwareDataTableNoLinkedVehicle]: "Sin vehículo vinculado",
  [HardwareModuleMessages.simCard]: "Tarjeta SIM",
  [HardwareModuleMessages.hardwareDataTableNoLinkedSimCard]: "Sin SIM Card vinculado",
  [HardwareModuleMessages.driver]: "Conductor",
  [HardwareModuleMessages.hardwareDataTableNoLinkedDriver]: "Sin conductor vinculado",
  [HardwareModuleMessages.hardwareDataTableTitle]: "hardwares",
  [HardwareModuleMessages.hardwareDeleteTitle]: "Confirmación de eliminación",
  [HardwareModuleMessages.hardwareDeleteText]: "¿Realmente desea eliminar el hardware?",
  [HardwareModuleMessages.hardwareCreateTitle]: "Registro de hardware",
  [HardwareModuleMessages.hardwareDetailsActive]: "Hardware activo",
  [HardwareModuleMessages.hardwareDetailsType]: "Tipo de hardware",
  [HardwareModuleMessages.hardwareDetailsPurchaseDate]: "Fecha de compra",
  /* endregion Hardware tab */
  /* region Logs tab */
  [HardwareModuleMessages.logFormModelRequired]: "Modelo es obligatorio",
  [HardwareModuleMessages.logNoTracker]: "Sin rastreador",
  [HardwareModuleMessages.logTitle]: "Log de comunicaciones",
  [HardwareModuleMessages.logAutoRefresh]: "Actualización automática",
  [HardwareModuleMessages.logSearching]: "Buscando logs...",
  /* endregion Logs tab */
  /* region Hardware Installation History tab */
  [HardwareModuleMessages.hardwareDevId]: "DEV ID de hardware",
  [HardwareModuleMessages.hardwareType]: "Tipo de hardware",
  [HardwareModuleMessages.occurrenceDate]: "Fecha de ocurrencia",
  [HardwareModuleMessages.importedData]: "Datos importados",
  /* endregion Hardware Installation History tab */

  /* endregion Frontend messages */
  /* region Backend messages */

  /* region Hardware Config Commands */
  [HardwareModuleMessages.createHardwareConfigCommandsSuccess]: "Comando(s) de configuración registrado(s) con éxito",
  [HardwareModuleMessages.createHardwareConfigCommandsError]: "Falla al registrar comando(s) de configuración",
  [HardwareModuleMessages.readHardwareConfigCommandsSuccess]: "Comando(s) devuelto(s) con éxito",
  [HardwareModuleMessages.readHardwareConfigCommandsError]: "Falla al buscar comando(s)",
  [HardwareModuleMessages.updateHardwareConfigCommandsSuccess]: "Comando modificado con éxito",
  [HardwareModuleMessages.updateHardwareConfigCommandsError]: "Falla al modificar comando(s)",
  [HardwareModuleMessages.readHardwareBlockCommandsSuccess]: "Bloqueo(s) devuelto(s) con éxito",
  [HardwareModuleMessages.readHardwareConfigCommandsContentSuccess]: "Comando(s) predefinido(s) devuelto(s) con éxito",
  [HardwareModuleMessages.readHardwareConfigCommandsContentError]: "Falla al buscar comando(s) predefinido(s)",
  [HardwareModuleMessages.noCommandFound]: "Ningún comando registrado",
  [HardwareModuleMessages.commandNotFound]: "Comando no registrado en nuestro sistema",
  [HardwareModuleMessages.noBlockFound]: "Ningún bloqueo registrado",
  [HardwareModuleMessages.readHardwareBlockCommandsError]: "Falla al buscar bloqueo(s)",
  /* endregion Hardware Config Commands */
  /* region Hardware logs */
  [HardwareModuleMessages.readHardwareLogsSuccess]: "Log(s) devuelto(s) con éxito",
  [HardwareModuleMessages.noLogFound]: "Ningún log registrado",
  [HardwareModuleMessages.readHardwareLogsError]: "Falla al buscar log(s)",
  /* endregion Hardware logs */
  /* region Hardware types */
  [HardwareModuleMessages.typeTracker]: "Rastreador",
  [HardwareModuleMessages.typeIButton]: "Ibutton",
  [HardwareModuleMessages.typeSensor]: "Sensor de tambor",
  [HardwareModuleMessages.createHardwareTypesSuccess]: "Tipo(s) de hardware registrado(s) con éxito",
  [HardwareModuleMessages.readHardwareTypesSuccess]: "Tipo(s) de hardware devuelto(s) con éxito",
  [HardwareModuleMessages.updateHardwareTypesSuccess]: "Tipo de hardware modificado(s) con éxito",
  [HardwareModuleMessages.deleteHardwareTypesSuccess]: "Tipo de hardware eliminado(s) con éxito",
  [HardwareModuleMessages.noHardwareTypeFound]: "Ningún tipo de hardware registrado",
  [HardwareModuleMessages.readHardwareTypesError]: "Falla al buscar tipo(s) de hardware",
  [HardwareModuleMessages.createHardwareTypeError]: "Falla al registrar tipo de hardware",
  [HardwareModuleMessages.updateHardwareTypeError]: "Falla al modificar tipo de hardware",
  [HardwareModuleMessages.hardwareTypeNotFound]: "Tipo de hardware no registrado en nuestro sistema",
  [HardwareModuleMessages.deleteHardwareTypeError]: "Falla al eliminar tipo de hardware",
  /* endregion Hardware types */
  /* region Hardware */
  [HardwareModuleMessages.createHardwareSuccess]: "Hardware registrado con éxito",
  [HardwareModuleMessages.readHardwareSuccess]: "Hardware(s) devuelto(s) con éxito",
  [HardwareModuleMessages.updateHardwareSuccess]: "Hardware modificado con éxito",
  [HardwareModuleMessages.deleteHardwareSuccess]: "Hardware eliminado con éxito",
  [HardwareModuleMessages.createHardwareError]: "Falla al registrar hardware",
  [HardwareModuleMessages.readHardwareError]: "Falla al buscar hardware",
  [HardwareModuleMessages.noHardwareFound]: "Ningún hardware registrado",
  [HardwareModuleMessages.updateHardwareError]: "Falla al modificar hardware",
  [HardwareModuleMessages.hardwareNotFound]: "Hardware no registrado en nuestro sistema",
  [HardwareModuleMessages.deleteHardwareError]: "Falla al eliminar hardware",
  /* endregion Hardware */
  /* region Hardware Installation History */
  [HardwareModuleMessages.readHardwareInstallationHistorySuccess]: "Historial de instalación de hardware devuelto con éxito",
  [HardwareModuleMessages.noHardwareInstallationHistoryFound]: "Ningún historial de instalación de hardware registrado",
  [HardwareModuleMessages.readHardwareInstallationHistoryError]: "Falla al buscar historial de instalación de hardware"
  /* endregion Backend messages */
};
