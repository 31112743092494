import { DriverModuleMessages, TranslationDriverModuleMessages } from "../interfaces";

export const messages: TranslationDriverModuleMessages = {

  /* region Frontend messages */
  [DriverModuleMessages.deleteConfirmation]: "Confirmación de eliminación",
  [DriverModuleMessages.deleteConfirmationText]: "¿Realmente desea eliminar el conductor",
  [DriverModuleMessages.deleteConfirmationHolder]: "Titular del",
  [DriverModuleMessages.detailsTitle]: "Registro de conductores",
  [DriverModuleMessages.detailsActive]: "Conductor activo",
  [DriverModuleMessages.document]: "CPF",
  [DriverModuleMessages.re]: "RE",
  [DriverModuleMessages.licenseNumber]: "CNH",
  [DriverModuleMessages.licenseExpiredDate]: "Fecha de vencimiento de la licencia",
  [DriverModuleMessages.detailsDocument]: "Licencia de conducir",
  [DriverModuleMessages.fullname]: "Nombre completo",
  [DriverModuleMessages.detailsAvaiableGroup]: "Disponibles",
  [DriverModuleMessages.detailsUsedGroup]: "Utilizados",
  [DriverModuleMessages.detailsIButtonCode]: "Código IButton",
  [DriverModuleMessages.iButton]: "IButton",
  [DriverModuleMessages.dataTableExpiredDocument]: "CNH",
  [DriverModuleMessages.invalidCPF]: "CPF inválido",
  [DriverModuleMessages.invalidUniqueCPF]: "CPF ya registrado",
  [DriverModuleMessages.invalidUniqueRE]: "RE ya registrado",
  [DriverModuleMessages.formRequiredName]: "El nombre es obligatorio",
  [DriverModuleMessages.formIncompleteDocument]: "CPF incompleto",
  [DriverModuleMessages.formRequiredDocument]: "El CPF es obligatorio",
  /* endregion Frontend messages */
  /* region Backend messages */
  [DriverModuleMessages.createDriverSuccess]: "Conductor registrado con éxito",
  [DriverModuleMessages.readDriverSuccess]: "Conductor(es) devuelto(s) con éxito",
  [DriverModuleMessages.updateDriverSuccess]: "Conductor modificado con éxito",
  [DriverModuleMessages.deleteDriverSuccess]: "Conductor eliminado con éxito",
  [DriverModuleMessages.createDriverError]: "Falla al registrar conductor",
  [DriverModuleMessages.readDriverError]: "Falla al buscar conductor(es)",
  [DriverModuleMessages.noDriverFound]: "Ningún conductor registrado",
  [DriverModuleMessages.driverNotFound]: "Conductor no registrado en nuestro sistema",
  [DriverModuleMessages.updateDriverError]: "Falla al editar conductor",
  [DriverModuleMessages.deleteDriverError]: "Falla al eliminar conductor",

  /* region Integration messages */
  [DriverModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) ya registrado(s)",
  [DriverModuleMessages.documentAlreadyRegistered]: "Documento(s) ya registrado(s)",
  [DriverModuleMessages.REAlreadyRegistered]: "RE(s) ya registrado(s)",
  [DriverModuleMessages.driverLinkedToATravel]: "Conductor vinculado a un viaje"
  /* endregion */

  /* endregion Backend messages */
};
