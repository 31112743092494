import BlurMap from "@assets/blur-map.jpg";
import styled from "styled-components";

export const ContentContainer = styled.div`
  .dialogContent-Alert {
    min-width: 400px;

    @media (max-width: 800px) { // Mobile
      min-width: 100%;
    }

    .firstContent {
      padding-bottom: 16px;

      .box {
        display: flex;
        align-items: center;

        .alertIcon {

          svg {
            width: 25px;
            height: 25px;
          }
          .disconnection {

            svg {
              fill: red;
            }
          }
          .connection {

            svg {
              fill: green;
            }
          }
          .maintenance {

            svg {
              fill: red;
            }
          }
        }
        .vehicleIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;

          div:first-child {
            height: 25px;
            width: 25px;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg {
            width: 20px;
            height: 20px;
            fill: white;
          }

          .iconBT, .iconBM {
            svg {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 18px;
            }
          }
          .iconPC {

            svg {
              position: relative;
              left: 2px;
            }
          }
          .iconVA {

            svg {
              position: relative;
              left: 2px;
              top: 2px;
            }
          }
          .iconCA {

            svg {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 18px;
            }
          }
        }

        .description {
          align-items: baseline;
          display: flex;
          padding-left: 15px;
        }
      }
    }
    .secondContent {
      max-width: 400px;
      padding-bottom: 8px;

      .box {
        display: flex;
        justify-content: space-between;

        .text {
          padding-left: 15px;
          font-weight: 500;
          text-align: right;
        }
      }

    }
    .thirdContent {

      .actions {
        margin: 5px 0;
        text-align: end;
        display: flex;
        justify-content: space-between;

        button:last-child {
          margin-left: auto;
        }
      }
      .hidden {
        display: none !important;
      }
      .staticMap {
        display: flex;
        justify-content: center;
        height: 250px;
        align-items: center;
        background-image: url(${BlurMap});
        background-repeat: no-repeat;
        background-size: cover;

        img {
          cursor: pointer;
          width: 40px;
          border-radius: 50%;
          font-weight: bold;
          background-color: white;

        }
      }
      .drawerMap {
        display: flex;
        justify-content: center;
        height: 250px;
        align-items: center;
        background-image: url(${BlurMap});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .fourContent {
      padding: 20px 0 0 0;

      form {

        .MuiTextField-root {
          width: 100%;

          .MuiFormHelperText-root {
            text-align: right;
            margin: 0;
          }
        }
      }
    }
  }
`;
