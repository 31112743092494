import { DualListboxMessages, TranslationDualListboxMessages } from "../interfaces";

export const messages: TranslationDualListboxMessages = {
  [DualListboxMessages.permittedVehiclesHeader]: "Vehículos permitidos",
  [DualListboxMessages.restrictedVehiclesHeader]: "Vehículos restringidos",
  [DualListboxMessages.availableFilterHeader]: "Disponible",
  [DualListboxMessages.moveAllLeft]: "Mover todos a la izquierda",
  [DualListboxMessages.moveAllRight]: "Mover todos a la derecha",
  [DualListboxMessages.moveLeft]: "Mover a la izquierda",
  [DualListboxMessages.moveRight]: "Mover a la derecha",
  [DualListboxMessages.moveBottom]: "Mover al final",
  [DualListboxMessages.moveDown]: "Mover hacia abajo",
  [DualListboxMessages.moveUp]: "Mover hacia arriba",
  [DualListboxMessages.moveTop]: "Mover al principio",
  [DualListboxMessages.noAvailableOptions]: "Sin opciones disponibles",
  [DualListboxMessages.noSelectedOptions]: "Sin opciones seleccionadas",
  [DualListboxMessages.selectedFilterHeader]: "Seleccionado",
  [DualListboxMessages.filterPlaceholder]: "Buscar..."
};
