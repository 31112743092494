import React from "react";

import TimeLineAggregateTrucks from "@components/TimeLines/TimeLineAggregateTrucks";
import { Container } from "./styles";

import Header from "../../components/Header";

const AggregateTrucksTimeline: React.FC = () => (
  <>
    <Header title="" />
    <Container className="page">
      <TimeLineAggregateTrucks />
    </Container>
  </>
);

export default AggregateTrucksTimeline;
