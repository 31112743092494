import { ScheduledReportMessages, TranslationScheduledReportMessages } from "../interfaces";

export const messages: TranslationScheduledReportMessages = {
  [ScheduledReportMessages.columnDescription]: "Descrição",
  [ScheduledReportMessages.columnStatus]: "Status",
  [ScheduledReportMessages.columnRegistrationDate]: "Data de cadastro",
  [ScheduledReportMessages.columnProcessingDate]: "Data de processamento",
  [ScheduledReportMessages.columnReportType]: "Tipo",
  [ScheduledReportMessages.columnFileSize]: "Tamanho do arquivo",

  [ScheduledReportMessages.statusInQueue]: "Na fila",
  [ScheduledReportMessages.statusProcessing]: "Processando...",
  [ScheduledReportMessages.statusProcessed]: "Processado",
  [ScheduledReportMessages.statusError]: "Erro",
  [ScheduledReportMessages.statusCanceled]: "Cancelado",

  [ScheduledReportMessages.emptyReport]: "Relatório vazio",

  [ScheduledReportMessages.modalDescriptionTitle]: "As opções de filtro escolhidas resultariam em um relatório muito"
  + " grande.",
  [ScheduledReportMessages.modalDescriptionTitleQuestion]: "Deseja de criar um relatório agendado para isso?",
  [ScheduledReportMessages.modalScheduleReportName]: "Nome do relatório agendado",
  [ScheduledReportMessages.modalButtonCancel]: "Cancelar",
  [ScheduledReportMessages.modalButtonClose]: "Fechar",
  [ScheduledReportMessages.modalButtonCreate]: "Criar",

  [ScheduledReportMessages.filterDetailsTitle]: "Filtro do relatório: ",

  [ScheduledReportMessages.readSuccess]: "Relatório agendados retornados com sucesso",
  [ScheduledReportMessages.readError]: "Não foi possível retornar os relatórios agendados",
  [ScheduledReportMessages.noScheduledReports]: "Nenhum relatório agendado encontrado",
  [ScheduledReportMessages.updateSuccess]: "Relatório agendado atualizado com sucesso",
  [ScheduledReportMessages.updateError]: "Não foi possível atualizar o relatório agendado",
  [ScheduledReportMessages.createSuccess]: "Relatório agendado criado com sucesso",
  [ScheduledReportMessages.createError]: "Não foi possível criar o relatório agendado",
  [ScheduledReportMessages.cancelSuccess]: "Relatório agendado cancelado com sucesso",
  [ScheduledReportMessages.cancelError]: "Não foi possível cancelar o relatório agendado",
  [ScheduledReportMessages.scheduledReportNotFound]: "Relatório agendado não encontrado",
  [ScheduledReportMessages.renewLinkSuccess]: "Link do relatório agendado renovado com sucesso",
  [ScheduledReportMessages.renewLinkError]: "Não foi possível renovar o link do relatório agendado"
};
