/* eslint-disable camelcase */
import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { Alert } from "./reg_alerts.entity";
import { Location } from "./reg_locations.entity";
import { Travel } from "./reg_travels.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { TpeVehicleState } from "./tpe_vehicles_states_types.entity";

@Entity("reg_vehicles_states")
export class VehicleState {

  @PrimaryGeneratedColumn("uuid")
    id_vehicle_state?: string;

  @Column({ type: "interval", nullable: true })
    duration?: Date;

  @Column({ type: "timestamp", nullable: true })
    finish_date?: Date;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => TpeVehicleState, (type) => type.vehicles_states, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_vehicle_state_type_fk" })
    status?: TpeVehicleState;

  @ManyToOne(() => Vehicle, (vehicle) => vehicle.states, { nullable: false, onDelete: "CASCADE" })
  @JoinColumn({ name: "id_vehicle_fk" })
    vehicle?: Vehicle;

  @ManyToOne(() => Travel, (travel) => travel.states, { nullable: true })
  @JoinColumn({ name: "id_travel_fk" })
    travel?: Travel;

  @OneToMany(() => Alert, (alerts) => alerts.vehicle_state)
    alerts?: Alert[];

  @ManyToOne(() => Location, (location) => location.vehicles_states, { nullable: true })
  @JoinColumn({ name: "id_location_fk" })
    location?: Location;

  @Column({ type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_odometer?: number;

  @Column({ type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    finish_odometer?: number;

  @Column({ type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    start_hour_meter?: number;

  @Column({ type: "numeric",
    default: 0,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    finish_hour_meter?: number;

  @Column({ type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    mixer_pressure?: number;

}
