import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;
  margin-top: ${(prop) => (prop.platform === "mobile" ? "5rem" : "0")};

  &.page {
    padding-bottom: 160px;
  }
`;
