import { SettingsMessages, TranslationSettingsMessages } from "../interfaces";

export const messages: TranslationSettingsMessages = {
  [SettingsMessages.settingsTitle]: "Configurações",
  [SettingsMessages.parameters]: "Parâmetros",
  [SettingsMessages.timeForUndueStopOptionDescription]: "Tempo necessário para a parada de um veículo ser considerada uma parada indevida",
  [SettingsMessages.functionalities]: "Funcionalidades",
  [SettingsMessages.enablePumpTimelineOptionDescription]: "Habilitar mapa de bombas",
  [SettingsMessages.minutes]: "Minutos"
};
