import styled from "styled-components";

export const ContainerCommandsList = styled.div`
  width: 100%;
  min-width: -webkit-fill-available;
  padding: 30px 20px 20px 20px;

  table tr td {
    text-align: center;
  }

  .copyToClipboard {
    position: relative;
    cursor: "copy";
  }
  .copyToClipboard:active:after {
      content:attr(title);
      position: absolute;
      padding:5px;
      border:1px solid #ccc;
      top:5px;
      right:10%;
      color: white;
      background: grey;
      z-index: 100;
      width: 350px;
      max-width: 100vw;
      overflow-wrap: break-word;
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: 800px) { // Mobile

    .title, .filterHeader, .filterFooter {
      text-align: center !important;
    }
  }

  .form {
    background: white;

    .filterContent {
      margin: 25px 0 0 0;
    }
    .filterFooter {
      text-align: end;

      button {
        width: fit-content;
        font-weight: bold;
        margin-top: 0px;
      }
    }

    .button-loading {
      margin-top: 0px !important;
      background: ${(props) => props.theme.colors.primary};
      transition: background-color 0.2s;

      &:hover {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export const ContainerModalFormCommand = styled.div`

  .MuiDialog-paper {
    width: 1000px;
    max-width: 1000px;

    .mHeader {

      .MuiTypography-root {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
          font-weight: 200;

          .subtitle {
            font-size: 1rem;
            color: #666666;
          }
        }
        .icon {
          display: flex;

          svg {
            font-size: 1.8em;
          }
        }
        .actions {

          button {
            background: #DDDDDD;

            svg {
              color: #707070;
            }
            &:hover {
              background-color:#FFFFFF;
            }
          }
        }
      }
    }
    .mContent {

      .vehicleCommandsList {
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 25px;
        margin: 4px;

        .vehicleCommandsItem {
          display: flex;
          align-items: center;

          .icon {
            background-color: #e0e0e0;
            padding: 10px;
            border-radius: 50%;
            height: 45px;
            display: flex;
            align-items: center;

            svg {
              width: 25px;
            }
          }
          .textfield {
            width: 100%;
            padding: 0 20px;
          }
          .removeVehicle {
            color: red;
            cursor: pointer;
            height: 55px;
          }
        }
        .noVehicleSelected {
          text-align: center;
        }
      }
      .divider {
        border-top: 1px solid rgba(0,0,0,0.12);
        width: 100%;
        margin: 20px 5px 0 5px;
      }
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiFormHelperText-root {
        text-align: right;
        margin: 0;
      }
      .checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .r-checkbox {
          display: flex;
          margin-left: 0;
          margin-right: 0;
          justify-content: flex-end;

          .MuiCheckbox-root {
            color: #707070;
          }
          .MuiFormControlLabel-label {
            font-size: 1.2em;
          }
          .MuiIconButton-root {
            position: relative;
            left: -10px;
          }
          .MuiFormControlLabel-label {
            position: relative;
            top: 1px;
          }
        }
      }

    }
    .mFooter {
      justify-content: space-between;
    }
  }
`;
