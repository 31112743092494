import styled from "styled-components";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";

interface StylesheetProps {
  platform: ScreenPlatform;
  selectedTab: number;
}

export const Container = styled.div<StylesheetProps>`
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: ${(props) => (props.selectedTab === 1 ? 120 : 64)}px;
  overflow-y: scroll;
  height: 100%;
  margin-top: ${(prop) => (prop.platform === "mobile" ? "5rem" : "0")};

  .MuiBox-root-8 {
    padding: 0;
  }

  .tabContainer {
    margin-top: -20px;
  }

  @media (max-width: 800px) { // Mobile
    padding-bottom: 0 !important;
  }

  .headerList {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    .headerTitle {

      .title {
        font-weight: 200;
        width: 100%;
        font-size: 2rem;
      }
      .subtitle {
        margin-top: 5px;
        font-size: 1.2rem;
        color: rgb(0 0 0 / 60%);
      }
    }
    .action {
      display: inline-flex;
      button {
        margin-left: 16px;
        width: 104px;
      }
    }
  }
`;
