import { TravelsModuleMessages, TranslationTravelsModuleMessages } from "../interfaces";

export const messages: TranslationTravelsModuleMessages = {
  [TravelsModuleMessages.createTravelSuccess]: "Viagem(ens) cadastrada(s) com sucesso",
  [TravelsModuleMessages.createTravelError]: "Falha ao cadastrar nova viagem",
  [TravelsModuleMessages.readTravelSuccess]: "Viagem(ens) retornada(s) com sucesso",
  [TravelsModuleMessages.noTravelFound]: "Nenhuma viagem encontrada",
  [TravelsModuleMessages.readTravelError]: "Falha ao buscar viagem(ens)",
  [TravelsModuleMessages.updateTravelSuccess]: "Viagem alterada com sucesso",
  [TravelsModuleMessages.travelNotFound]: "Viagem não cadastrada em nosso sistema",
  [TravelsModuleMessages.updateTravelError]: "Falha ao alterar viagem",
  [TravelsModuleMessages.deleteTravelSuccess]: "Viagem excluída com sucesso",
  [TravelsModuleMessages.deleteTravelError]: "Falha ao excluir viagem",
  [TravelsModuleMessages.swapTravelsSuccess]: "Viagens trocadas com sucesso",
  [TravelsModuleMessages.swapTravelsError]: "Falha ao trocar viagens",
  [TravelsModuleMessages.getTravelAnalyticsSuccess]: "Dados de análise retornados com sucesso. Viagens: ",
  [TravelsModuleMessages.getTravelAnalyticsError]: "Falha ao buscar dados de análise da viagem",
  [TravelsModuleMessages.getTravelAnalyticsVeryLargeTravel]: "Viagem muito extensa para visualização no mapa",
  [TravelsModuleMessages.getTravelFilterOptionsSuccess]: "Opções de filtro de viagem retornadas com sucesso",
  [TravelsModuleMessages.noTravelFilterOptionsFound]: "Nenhuma obra cadastrada para esse filtro de viagem",
  [TravelsModuleMessages.getTravelFilterOptionsError]: "Falha ao buscar obra(s)",
  [TravelsModuleMessages.getTravelCoordinatesSuccess]: "Coordenadas de viagem retornadas com sucesso",
  [TravelsModuleMessages.getTravelCoordinatesError]: "Falha ao buscar coordenadas de viagem",
  [TravelsModuleMessages.noTravelCoordinatesFound]: "Nenhuma coordenada de viagem encontrada",

  /* region Integration messages */
  [TravelsModuleMessages.vehicleCurrentInTravel]: "Veículo(s) já está em uma viagem atualmente. Dados da viagem em questão atualizados",
  [TravelsModuleMessages.originLocationNotRegistered]: "Local(is) de origem não cadastrado(s)",
  [TravelsModuleMessages.vehicleNotRegistered]: "Veículo(s) não cadastrado(s)",
  [TravelsModuleMessages.driverNotRegistered]: "Motorista(s) não cadastrado(s)",
  [TravelsModuleMessages.destinationLocationNotRegistered]: "Local(is) de destino não cadastrado(s)",
  [TravelsModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) já cadastrado",
  [TravelsModuleMessages.invalidDatePeriod]: "Período de data inválido",
  [TravelsModuleMessages.travelLinkedToAVehicle]: "Viagem já vinculada em algum veículo, sua exclusão não pode ser"
  + " feita",
  [TravelsModuleMessages.readPositionSuccess]: "Posicionamento retornado com sucesso",
  [TravelsModuleMessages.travelFinishedOrNonexistent]: "A viagem já foi encerrada ou não existe",
  [TravelsModuleMessages.needToInformIDOrExternalID]: "É necessário informar o ID ou o ID externo do veículo",
  [TravelsModuleMessages.maxSearchInterval]: "Intervalo máximo de busca é",
  [TravelsModuleMessages.days]: "dias",
  [TravelsModuleMessages.analyticVehicleData]: "Dados analíticos do veículo",
  [TravelsModuleMessages.returned]: "retornados com sucesso",
  [TravelsModuleMessages.travelPreviouslyCancelled]: "Viagem já foi cancelada anteriormente",
  [TravelsModuleMessages.cancelTravelSuccess]: "Viagem cancelada com sucesso"
  /* endregion */

};
