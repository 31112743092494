import React, { useEffect, useState } from "react";

// region Libraries
import Popper from "@material-ui/core/Popper";
import { CircularProgress, Fade, makeStyles, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, MenuMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Interfaces
import { Alert } from "@shared/interfaces/alert.interface";
// endregion Interfaces
// region Components
import AlertComponent from "@components/Alert/AlertList";
// endregion Components
// region Styles
import { ContainerPopperSpeedingAlerts } from "./styles";
// endregion Styles

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1101,
    "&[x-placement*=\"bottom\"] $arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    "&[x-placement*=\"top\"] $arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    "&[x-placement*=\"right\"] $arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    "&[x-placement*=\"left\"] $arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 12,
    width: "3em",
    height: "3em",
    "&::before": {
      content: "\"\"",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  }
}));

type PopperSpeedingAlertsProps = { alerts: Alert[], loading: boolean, open: boolean, anchorEl: any }
const PopperSpeedingAlerts: React.FC<PopperSpeedingAlertsProps> = (
  { alerts, loading, open, anchorEl }
) => {

  const style = useStyles();

  const [arrowRef, setArrowRef] = useState<any>(null);
  const [arrow, setArrow] = useState(false);

  const { t } = useTranslation();

  // Active arrow style in popper when popper is open
  useEffect(() => setArrow(open), [open]);

  return (
    <ContainerPopperSpeedingAlerts id="popperSpeedingAlerts">
      <Popper
        className={classNames(style.popper, "popper")}
        open={open}
        anchorEl={anchorEl}
        container={document.getElementById("popperSpeedingAlerts")}
        placement="bottom-start"
        transition
        modifiers={{
          arrow: {
            enabled: arrow,
            element: arrowRef
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ appear: 350, enter: 350, exit: 0 }}>
            <>
              {arrow ? <span className={style.arrow} ref={setArrowRef} /> : null}
              <Paper className="popperContent">
                <div className="title">{t(MenuMessages.speedingAlerts)}</div>
                <Typography component="div">
                  {
                    (loading)
                      ? <div className="loading"><CircularProgress /></div>
                      : (!alerts || alerts.length === 0)
                        ? <div className="noData">{t(GlobalMessages.noData)}</div>
                        : (<AlertComponent alerts={alerts} />)
                  }
                </Typography>
              </Paper>
            </>
          </Fade>
        )}
      </Popper>
    </ContainerPopperSpeedingAlerts>
  );
};

export default PopperSpeedingAlerts;
