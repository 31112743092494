/* eslint-disable no-restricted-globals */

import { createContext } from "react";
import { io, Socket } from "socket.io-client";

// Get URL from environment variables or from location
const getURL = () => (process.env.REACT_APP_SERVER_TEST === "ON"
  ? "http://dev-api.topconsuite.app.br"
  : `https://${location.hostname.split(".")[0]}-api.rastrin.app`);

// Token retrieval treatments
const getToken = () => localStorage.getItem("@Fleet:token") ?? "";

export const socket = io(getURL(), {
  path: "/socket.io",
  withCredentials: true,
  auth: { token: getToken(), acceptLanguage: localStorage.getItem("@Fleet:language") ?? navigator.language },
  transports: ["websocket"]
});

// Update token on socket auth upon socket disconnection, so that it can be used again on reconnection
socket.on("disconnect", () => {

  socket.auth = { token: getToken() };

});

export const WebSocketContext = createContext<Socket>(socket);

export const WebSocketProvider = WebSocketContext.Provider;
