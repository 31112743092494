export enum ParamNameTypes {
  START_DATE = "startDate",
  FINISH_DATE = "finishDate",
  END_DATE = "endDate",
  ID_VEHICLES = "idVehicles",
  ID_VEHICLE_TYPES = "idVehicleTypes",
  ID_DRIVERS = "idDrivers",
  ID_ALERT_TYPES = "idAlertTypes",
  ID_REGIONALS = "idRegionals",
  ID_GROUPS = "idGroups",
  ID_PLANTS = "idPlants",
  ID_TRAVELS = "idTravels",
  ID_VEHICLE_STATE_TYPES = "idVehicleStateTypes",
  REPORT_TYPE = "reportType",
  CONSTRUCTION_NAMES = "constructionNames",
  CLIENTS = "clients",
  UTC_OFFSET = "utcOffset"
}
