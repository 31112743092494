/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState } from "react";
import Loading from "react-fullscreen-loading";

// region Libraries
import * as _ from "lodash";
// endregion Libraries

// region Interfaces and types
import { AlertTypesID } from "@shared/constants/alert-types.enum";
import { Alert as AlertInterface } from "@shared/interfaces/alert.interface";
// endregion Interfaces and types

// region Languages
import useTranslation from "src/translations/useTranslation";
import { AlertMessages } from "@shared/languages/interfaces";
// endregion Languages

// region Utils
import utils from "../../../utils/useful-functions";
// endregion Utils

// region Components
import AlertDialogJustify from "../AlertDialogJustify";
// endregion Components

// region Styles
import { Container } from "./styles";
// endregion Styles

type ConcreteExpirationAlertListProps = { alerts: AlertInterface[] }
const ConcreteExpirationAlertList: React.FC<ConcreteExpirationAlertListProps> = (
  { alerts }
) => {

  const [selectedAlert, setSelectedAlert] = useState<AlertInterface>({} as AlertInterface);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  /** Open dialog confirm alert
   */
  const handleAlertClick = useCallback((alert: AlertInterface) => {
    setSelectedAlert(alert);

  }, []);

  /** Close dialog confirm alert
   */
  const handleAlertClose = useCallback(() => {
    setSelectedAlert({} as AlertInterface);
  }, []);

  return (
    <Container>
      <Loading loading={loading} />
      {alerts.map((alert) => (
        <div
          className="content"
          key={alert.id_alert}
          onClick={() => handleAlertClick(alert)}
        >
          <div className="boxIcon">
            {utils.getAlertIconAccordingType(alert.type.id_alert_type)}
          </div>
          <div className="boxDescription">
            <div className="heading alertC-title">
              <div className="txt2">
                <span className="icon">{utils.getVehicleIconAccordingTypeAndState(alert.vehicle, alert.vehicle_state)}</span>
                <span className="desc">{alert.vehicle?.code}</span>
              </div>
            </div>
            <div className="heading alertC-subtitle">
              <div
                className="txt1"
                style={alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO ? { color: "#FF0000" } : {}}
              >
                {alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO ? t(AlertMessages.expiresIn)
                  : t(AlertMessages.expiredIn) }
              </div>
              <div
                className="txt2"
                style={alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO ? { color: "#FF0000" } : {}}
              >
                {alert?.event?.finish_date
                && (utils.formatDateIfHave(alert.event.finish_date, "fullDateWithoutSeconds"))}
              </div>
            </div>
          </div>
        </div>
      ))}
      {!_.isEmpty(selectedAlert) && (
        <AlertDialogJustify selectedAlert={selectedAlert} actionType="update" closeCallback={handleAlertClose} setLoading={setLoading} />
      )}
    </Container>
  );
};

export {
  ConcreteExpirationAlertList as default
};
