/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { action } from "typesafe-actions";
import { VehiclesStatesTypes, VehiclesStatesState, VehiclesStatesObj } from "./vehicles-states.type";

const VehiclesStatesActions = {
  setVehiclesStates: (vehiclesStates: VehiclesStatesState | undefined) => action(VehiclesStatesTypes.SET_VEHICLES_STATES, vehiclesStates),
  setVehiclesStatesAll: (vehicleStatesAll: VehiclesStatesObj | undefined) => action(VehiclesStatesTypes.SET_VEHICLES_STATES_ALL, vehicleStatesAll),
  setVehiclesStatesFiltered: (vehicleStatesFiltered: VehiclesStatesObj | undefined) => action(VehiclesStatesTypes.SET_VEHICLES_STATES_FILTERED, vehicleStatesFiltered)
};

export { VehiclesStatesActions };
