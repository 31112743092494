/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import { Vehicle } from "@shared/interfaces/vehicle.interface";

// Action types
export enum VehiclesStatesTypes {
  SET_VEHICLES_STATES = "@vehiclesStates/SET_VEHICLES_STATES",
  SET_VEHICLES_STATES_ALL = "@vehiclesStates/SET_VEHICLES_STATES_ALL",
  SET_VEHICLES_STATES_FILTERED = "@vehiclesStates/SET_VEHICLES_STATES_FILTERED"
}

// Data Types
export interface VehicleStatesList {
  [key: string]: Vehicle[];
}
export interface VehiclesStatesObj {
  list: VehicleStatesList;
  loading: boolean;
}

// State type
export interface VehiclesStatesState {
  all: VehiclesStatesObj;
  filtered?: VehiclesStatesObj;
}

export interface SectionsMenuOptions {
  idSection: number;
  selectedIndex: number;
}
