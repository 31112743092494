/* eslint-disable camelcase */
import {
  Entity, Column, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn, ManyToOne, JoinColumn
} from "typeorm";
import { Filter } from "./reg_filters.entity";

@Entity("reg_filter_options")
export class FilterOption {

  @PrimaryGeneratedColumn("uuid")
    id_filter_option?: string;

  @ManyToOne(() => Filter, (filter) => filter.filter_options, { nullable: false })
  @JoinColumn({ name: "id_filter_fk" })
    filter?: Filter;

  @Column({ type: "varchar", nullable: false, length: 50 })
    param_name?: string;

  @Column({ type: "varchar", nullable: false, length: 255 })
    param_value?: string;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

}
