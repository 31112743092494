/* eslint-disable import/prefer-default-export */
import { ServiceOrderMessages, TranslationServiceOrderMessages } from "../interfaces";

export const messages: TranslationServiceOrderMessages = {

  /* region Frontend messages */
  [ServiceOrderMessages.newServiceOrder]: "Nova Ordem de Serviço",
  [ServiceOrderMessages.expectedDate]: "Data prevista",
  [ServiceOrderMessages.serviceOrderType]: "Tipo de O.S.",
  [ServiceOrderMessages.delivery]: "Entrega",
  [ServiceOrderMessages.pickup]: "Retirada",
  [ServiceOrderMessages.product]: "Produto",
  [ServiceOrderMessages.binder]: "Cimento",
  [ServiceOrderMessages.smallAggregate]: "Agregado miúdo",
  [ServiceOrderMessages.coarseAggregate]: "Agregado graúdo",
  [ServiceOrderMessages.unit]: "Unidade",
  [ServiceOrderMessages.transportDocument]: "Documento de transporte",
  [ServiceOrderMessages.productDocument]: "Documento do produto",
  [ServiceOrderMessages.originLocation]: "Local de origem",
  [ServiceOrderMessages.loadingLocation]: "Local de carregamento",
  [ServiceOrderMessages.unloadingLocation]: "Local de descarregamento",
  [ServiceOrderMessages.quantity]: "Quantidade",
  /* endregion Frontend messages */
  /* region Backend messages */
  [ServiceOrderMessages.createServiceOrderSuccess]: "Ordem de serviço cadastrada com sucesso",
  [ServiceOrderMessages.createServiceOrderError]: "Falha ao criar nova ordem de serviço"
  /* endregion Backend messages */
};
